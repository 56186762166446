import {
	ChipDirective,
	ChipListComponent,
	ChipsDirective,
} from "@syncfusion/ej2-react-buttons";
import CustomGrid from "../../../components/commons/CustomGrid";

export default function CareDxDropSiteList(props) {
	const { data = [], loading = false, isModalOpen } = props;
	return (
		<CustomGrid
			type="careDxDropSite"
			data={data}
			loading={loading}
			editModal={props.editModal}
			columns={[
				{
					field: "drop_site_name",
					headerText: "Drop Site Name",
					width: "220",
				},
				{
					field: "full_address",
					headerText: "Address",
					width: "220"
				},
				{
					field: "city_string",
					headerText: "City",
					width: "220",
				},
				{
					field: "state_name_string",
					headerText: "State",
					width: "220",
				},
				{
					field: "zip_string",
					headerText: "Zip",
					width: "168",
					visible: false
				},
				{
					field: "time_zone_name_string",
					headerText: "Timezone",
					width: "168",
					visible: false
				},
				{
					field: "site_contact_phone_number_string",
					headerText: "Drop Site Phone Number",
					width: "250"
				},
				{
					field: "site_contact_phone_number_ext_string",
					headerText: "Drop Site Phone Number Ext",
					width: "250"
				},
				{
					field: "latitude_string",
					headerText: "Latitude",
					width: "168",
					visible: false
				},
				{
					field: "longitude_string",
					headerText: "Longitude",
					width: "168",
					visible: false
				},
				{
					field: "timing_note_string",
					headerText: "Timing Notes",
					width: "250",
				},
				{
					field: "site_timings_string",
					headerText: "Drop Site Hours",
					width: "250",
					visible: false
				},
				{
					field: "notes_string",
					headerText: "Drop Site Appointment Information",
					width: "250",
					visible: false
				},
				{
					field: "status.status_name",
					headerText: "Status",
					width: "168",
					template: (props) => {
						const { status = {} } = props;
						return status && status.status_name === "Active" ? (
							<div className="user-status-wrapper">
								<ChipListComponent id="chip-default">
									<ChipsDirective>
										<ChipDirective
											text={status.status_name}
											cssClass="e-user-active"
										></ChipDirective>
									</ChipsDirective>
								</ChipListComponent>
							</div>
						) : status && status.status_name === "Invited" ? (
							<div className="user-status-wrapper">
								<ChipListComponent id="chip-default">
									<ChipsDirective>
										<ChipDirective
											text={status.status_name}
											cssClass="e-user-invited"
										></ChipDirective>
									</ChipsDirective>
								</ChipListComponent>
							</div>
						) : status && status.status_name === "Locked" ? (
							<div className="user-status-wrapper">
								<ChipListComponent id="chip-default">
									<ChipsDirective>
										<ChipDirective
											text={status.status_name}
											cssClass="e-user-locked"
										></ChipDirective>
									</ChipsDirective>
								</ChipListComponent>
							</div>
						) : (
							status &&
							status.status_name === "Inactive" && (
								<div className="user-status-wrapper">
									<ChipListComponent id="chip-default">
										<ChipsDirective>
											<ChipDirective
												text={status.status_name}
												cssClass="e-user-deactivated"
											></ChipDirective>
										</ChipsDirective>
									</ChipListComponent>
								</div>
							)
						);
					},
				}
			]}
			isModalOpen={isModalOpen}
		/>
	);
}
