import React from "react";
import {
  ChipDirective,
  ChipListComponent,
  ChipsDirective,
} from "@syncfusion/ej2-react-buttons";
import { phoneFormat } from "../../app/utils/testUtils";
import CustomGrid from "../../components/commons/CustomGrid";
import moment from "moment";

const chip = (props) => {
  return props && props.status === "Active" ? (
    <div className="user-status-wrapper">
      <ChipListComponent id="chip-default">
        <ChipsDirective>
          <ChipDirective
            text={props.status}
            cssClass="e-user-active"
          ></ChipDirective>
        </ChipsDirective>
      </ChipListComponent>
    </div>
  ) : props && props.status === "Invited" ? (
    <div className="user-status-wrapper">
      <ChipListComponent id="chip-default">
        <ChipsDirective>
          <ChipDirective
            text={props.status}
            cssClass="e-user-invited"
          ></ChipDirective>
        </ChipsDirective>
      </ChipListComponent>
    </div>
  ) : props && props.status === "Locked" ? (
    <div className="user-status-wrapper">
      <ChipListComponent id="chip-default">
        <ChipsDirective>
          <ChipDirective
            text={props.status}
            cssClass="e-user-locked"
          ></ChipDirective>
        </ChipsDirective>
      </ChipListComponent>
    </div>
  ) : (
    props &&
    props.status === "Inactive" && (
      <div className="user-status-wrapper">
        <ChipListComponent id="chip-default">
          <ChipsDirective>
            <ChipDirective
              text={props.status}
              cssClass="e-user-deactivated"
            ></ChipDirective>
          </ChipsDirective>
        </ChipListComponent>
      </div>
    )
  );
};
const accessGroup = (props) => {
  const { role_name_string = [] } = props;
  const role_name = role_name_string.split(",");
  return role_name && role_name && role_name.length > 1 ? (
    <span>
      <span className="access-group-text">
        <span className="access-group-icon"></span>
        <span className="access-title">{role_name[0]} </span>
      </span>
      +{props.roles.length - 1} More
    </span>
  ) : (
    role_name &&
    role_name.map((val, i) => {
      return (
        <span className="access-group-text" key={i}>
          <span className="access-group-icon"></span>
          <span className="access-title">{val} </span>
        </span>
      );
    })
  );
};
const name = (props) => {
  return (
    <span>
      {props.first_name} {props.middle_initial} {props.last_name}
    </span>
  );
};

const regionTemplate = (props) => {
  const { region_name_string = [] } = props;
  const region = region_name_string.split(",");
  return (
    <span>
      <span>
        <span className="access-title">{region[0]}</span>
        {region[1] && <span className="access-title">, {region[1]} </span>}
      </span>
      {region.length > 2 && (
        <span className="pal-8">+ {region.length - 2} More</span>
      )}
    </span>
  );
};
const territoryTemplate = (props) => {
  const { territory_name_string = [] } = props;
  const territory = territory_name_string.split(",");
  return (
    <span>
      <span>
        <span className="access-title">{territory[0]}</span>
        {territory[1] && (
          <span className="access-title">, {territory[1]} </span>
        )}
      </span>
      {territory.length > 2 && (
        <span className="pal-8">+ {territory.length - 2} More</span>
      )}
    </span>
  );
};

class Grid extends React.Component {
  state = {
    isClicked: false,
    propertyValue: "",
    filter: []
  }
  render() {
    const { data = [], loading = false, editModal, type, isModalOpen } = this.props;
    return (
      <React.Fragment>
        <CustomGrid
          type={type}
          data={data}
          loading={loading}
          editModal={editModal}
          columns={[
            {
              field: "full_name",
              headerText: "Employee Name",
              width: "210",
              template: name
            },
            {
              field: "first_name",
              headerText: "Employee First Name",
              width: "210",
              visible: false
            },
            {
              field: "last_name",
              headerText: "Employee Last Name",
              width: "210",
              visible: false
            },
            {
              field: "middle_initial_string",
              headerText: "Employee Middle Initial",
              width: "210",
              visible: false
            },
            {
              field: "cognito_username",
              headerText: "Username",
              width: "220",
            },
            {
              field: "employee_id",
              headerText: "Employee ID",
              width: "190",
            },
            {
              field: "job_title.job_title_name",
              headerText: "Job Title",
              width: "230"
            },
            {
              field: "region_name_string",
              headerText: "Region(s)",
              width: "220",
              template: regionTemplate
            },
            {
              field: "territory_name_string",
              headerText: "Territory(s)",
              width: "255",
              template: territoryTemplate
            },
            {
              field: "home_region_name_string",
              headerText: "Home Region",
              width: "210",
              visible: false
            },
            {
              field: "reporting_manager_name_string",
              headerText: "Reporting Manager",
              width: "250",
            },
            {
              field: "phone_number_string",
              headerText: "Company Phone",
              width: "210"
            },
            {
              field: "personal_phone_number_string",
              headerText: "Personal Phone Number",
              width: "210",
              visible: false
            },
            {
              field: "email_string",
              headerText: "Email",
              width: "330"
            },
            {
              field: "role_name_string",
              headerText: "Access Group(s)",
              width: "320",
              template: accessGroup
            },
            {
              field: "has_company_vehicle_string",
              headerText: "Has Company Vehicle",
              width: "320",
              visible: false
            },
            {
              field: "last_login_datetime",
              headerText: "Last Login",
              width: "200"
            },
            {
              field: "currently_logged_in",
              headerText: "Is Logged IN",
              width: "168",
            },
            {
              field: "status",
              headerText: "Status",
              width: "168",
              template: chip
            }
          ]}
          property="full_name"
          propertyValue={this.state.propertyValue}
          isClicked={this.state.isClicked}
          filter={this.state.filter}
          isModalOpen={isModalOpen}
        />
      </React.Fragment>
    );
  }
}
export default Grid;
