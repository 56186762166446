import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { phoneFormat } from "../../../../app/utils/testUtils";
import ahaApi from "../../../../app/services/aha-api";

const initialState = {
  value: [],
  loading: false,
};

export const getCareDxDropSites = createAsyncThunk(
  "caredxdropsite/getCareDxDropSites",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.getCareDxDropSites(data.flag)
      .then((res) => {
        if (data.ignoreFormatting) {
          return res;
        }
        let result = res ? (res.content || []) : [];
        result = result.map(re=> {
          return {
            ...re,
            drop_site_name: re.name,
            full_address: re.street_address1 + " "+ re.street_address2,
            city_string: re.city || "-",
            zip_string: re.zip || "-",
            state_name_string: re.state ? re.state.state_name || "-" : "-",
            time_zone_name_string: re.timezone ? re.timezone.timezone_name || "-" : "-",
            latitude_string: re.latitude ? re.latitude : "-",
            longitude_string: re.longitude ? re.longitude : "-",
            site_contact_phone_number_string: re.lab_contact_phone_number 
            ? `${phoneFormat(re.lab_contact_phone_number.toString())} ${re.lab_contact_phone_number_ext && re.lab_contact_phone_number_ext !== null ? ` Ext. ${re.lab_contact_phone_number_ext}` : ""}` : "-",
            site_contact_phone_number_ext_string: re.lab_contact_phone_number_ext || "-",
            notes_string: re.lab_notes || "-"
        }});
        res.content = result;
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const getCareDxDropSitesSlice = createSlice({
  name: "caredxdropsite",
  initialState,
  reducers: {
    updateGetCareDxDropSite(state, action) {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCareDxDropSites.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCareDxDropSites.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getCareDxDropSites.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const careDxDropSitesResponse = (state) => state.getCareDxDropSite.value;
export const careDxDropSitesLoader = (state) => state.getCareDxDropSite.loading;
export const { updateGetCareDxDropSite } = getCareDxDropSitesSlice.actions;

export default getCareDxDropSitesSlice.reducer;
