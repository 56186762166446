import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../../app/services/aha-api";
export const initialState = {
  value: [],
  loading: false
};

export const addHospitals = createAsyncThunk(
  "hospitals/addHospitals",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.addHospitals(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const addHospitalsSlice = createSlice({
  name: "addHospitals",
  initialState,
  reducers: {
    clearAddHospitalResponse(state, action) {
      state.value = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addHospitals.pending, (state) => {
        state.loading = true;
      })
      .addCase(addHospitals.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
        state.days = action.payload.days;
      })
      .addCase(addHospitals.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const addHospitalsList = (state) => state.addHospitals.value;
export const {clearAddHospitalResponse} = addHospitalsSlice.actions;
export default addHospitalsSlice.reducer;
