import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const assignFacilitiesToZone = createAsyncThunk(
  "zones/updateFacilityToZone",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.assignFacilitiesToZone(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const assignFacilitiesToZoneSlice = createSlice({
  name: "updateFacilityToZone",
  initialState,
  reducers: {
    clearUpdateFacilityToZoneVal(state, action) {
      state.value = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(assignFacilitiesToZone.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignFacilitiesToZone.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(assignFacilitiesToZone.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const assignFacilitiesToZoneVal = (state) => state.assignFacilitiesToZone.value;
export const assignFacilitiesToZoneLoading = (state) => state.assignFacilitiesToZone.loading;
export const {clearUpdateFacilityToZoneVal} = assignFacilitiesToZoneSlice.actions;
export default assignFacilitiesToZoneSlice.reducer;
