import React from "react";
import { OnCallRoutine } from "./OnCallRoutine";

export const OnCallTerritory = React.memo((props) => {
	const { territories = {}, userIndex, serviceIndex, sectionType, date, constructPyloadForCheckinCheckout, user_id, getScheduleBoardData,
		blockId, uniqueServiceId, handleDelete, searchText, searchType, users, userType, showDropArea } = props;
	return (
		<div className="service-zone sch-backlog-bodycontainer">
			{Object.keys(territories).map((territoryIndex) => {
				const { territory_name: territoryName, events = {} } = territories[territoryIndex];

				return (
					<div
						className="service-details"
						key={userIndex + "-" + serviceIndex + "-" + territoryIndex}
					>
						<div className="service-rtz">
							<p className="title">{territoryName}</p>
						</div>

						<OnCallRoutine
							date={date}
							events={events}
							userIndex={userIndex}
							serviceIndex={serviceIndex}
							territoryIndex={territoryIndex}
							sectionType={sectionType}
							handleDelete={handleDelete}
							constructPyloadForCheckinCheckout={constructPyloadForCheckinCheckout}
							user_id={user_id}
							getScheduleBoardData={getScheduleBoardData}
							uniqueServiceId={uniqueServiceId}
							blockId={blockId}
							searchText={searchText} 
							searchType={searchType}
							users={users}
							userType={userType}
							showDropArea={showDropArea}
						/>
					</div>
				);
			})}
		</div>
	);
});
