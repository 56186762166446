import {
	useState,
	useEffect,
} from 'react';
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { validSession, validSessionData } from '../../appSlice';
import { CARE_AT_HOME_SCHEDULE, HOMEHEALTH, logout, routes, ROUTINEREDRAW, SCHEDULE, STATORDER, TIMEDDRAW } from '../../app/utils/Constants';
import { useIdleTimer } from 'react-idle-timer';
import { isUm } from '../../mSlice';

let remainingInterval;

const SessionTimeout = (props) => {
	const events = ['load', 'mousedown'];
	const eventsForScheduleAndStat = ['load', 'mousedown', 
	// 'wheel'
];
	const promptTimeout = 1000 * 600000;  // 60 sec timer

	const userData = window.localStorage.getItem("userData") ?
		JSON.parse(window.localStorage.getItem("userData")) : {};

	const TIMEOUT_TIME = (userData.api_inactivity_timeout ? parseInt(userData.api_inactivity_timeout) : 45) * 60 * 1000;
	const PROMPT_TIME = 1 * 60 * 1000;

	const loggedIn =
		window.localStorage.getItem("userData") &&
			JSON.parse(window.localStorage.getItem("userData")).access_token
			? true
			: false;

	const [remaining, setRemaining] = useState(0);
	const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
	const validSessionResponse = useSelector(validSessionData);
	const dispatch = useDispatch();

	const um = useSelector(isUm);

	const handleContinue = () => {
		dispatch(validSession());
	};
	const onActive = (e) => {
		if (!timeoutModalOpen) {
			setRemaining(0);
		}
	};
	const onIdle = () => {
		if (loggedIn) {
			logout();
		}
	};

	useEffect(() => {
		const eve = window.location.pathname === SCHEDULE || window.location.pathname === CARE_AT_HOME_SCHEDULE || window.location.pathname === STATORDER ||
		window.location.pathname === ROUTINEREDRAW || window.location.pathname === TIMEDDRAW || window.location.pathname === HOMEHEALTH ? eventsForScheduleAndStat : events;

		const isModalOpen = document.getElementsByClassName("session-modal") && document.getElementsByClassName("session-modal")
		.length;
		eve.forEach((event) => {
			window.addEventListener(event, () => {
				if (loggedIn && !isModalOpen && um !== true) {
					dispatch(validSession())
				}
			});
		});
		document.addEventListener("keyup", function(event) {
			if ((window.location.pathname === routes.CHAT || window.location.pathname === routes.CHAT_ADMIN) && event.key === 'Enter') {
				if (loggedIn && !isModalOpen && um !== true) {
					dispatch(validSession())
				}
			}
		});
	}, [loggedIn])

	useEffect(() => {
		if (validSessionResponse && validSessionResponse.status) {
			if (validSessionResponse.status === "SUCCESS") {
				setRemaining(0);
				reset();
				setTimeoutModalOpen(false);
			} else if (validSessionResponse.status === "ERROR") {
				if (validSessionResponse.message_code && validSessionResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN") {
					setRemaining(0);
					reset();
					setTimeoutModalOpen(false);
					logout();
					// localStorage.removeItem("userData");
					// localStorage.removeItem("userDetail");
					// sessionStorage.removeItem('lastTimeStamp');
					// window.location.href = HOME;
				} else {
					setRemaining(0);
					reset();
					setTimeoutModalOpen(false);
				}
			}
		}
	}, [validSessionResponse]);

	const { getRemainingTime, isPrompted, reset, activate } = useIdleTimer({
		onActive: onActive,
		onIdle: onIdle,
		// debounce={250}
		timeout: TIMEOUT_TIME,
		events: window.location.pathname === SCHEDULE || window.location.pathname === CARE_AT_HOME_SCHEDULE || window.location.pathname === STATORDER ||
		window.location.pathname === ROUTINEREDRAW ||window.location.pathname === TIMEDDRAW || window.location.pathname === HOMEHEALTH ? eventsForScheduleAndStat : events,
		crossTab: true,
		promptBeforeIdle: PROMPT_TIME,
		onPrompt: e => { 
			if (loggedIn) {
				// setTimeoutModalOpen(true); 
				// 	setRemaining(60);
				if (um !== true) {
						setTimeoutModalOpen(true); 
					setRemaining(60);
				} else{
					setRemaining(0);
					activate();
				}
			}
		 }
	})

	useEffect(() => {
		remainingInterval = setInterval(() => {
			if (isPrompted()) {
				setRemaining(Math.ceil(getRemainingTime() / 1000));
			}
		}, 1000);
		return () => {
			clearInterval(remainingInterval);
		};
	}, [getRemainingTime, isPrompted]);

	return <>
		<Modal
			show={timeoutModalOpen}
			// onHide={() => dispatch(setOpen(true))}
			className="session-modal"
			backdrop="static"
			autoFocus
		>
			<Modal.Header>
				<Modal.Title className="headline4 text-center">
					{" "}
					<p>Session will expire in {remaining} seconds.</p>
					<p>Please click continue to stay signed in.</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Button
					data-testid="button-edit"
					className="primary-btn primary-btn-outline ad-progress-btn"
					onClick={() => handleContinue()}
				>
					Continue
				</Button>
			</Modal.Body>
		</Modal>
	</>;
};

export default SessionTimeout;