import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "../features/accounts/login/operations/accountSlice";
import inviteReducer from "../features/accounts/setPassword/operations/inviteSlice";
import setPasswordReducer from "../features/accounts/setPassword/operations/setPasswordSlice";
import forgotPasswordReducer from "../features/accounts/troubleLogin/operations/forgotPasswordSlice";
import forgotUserReducer from "../features/accounts/troubleLogin/operations/forgotUserSlice";
import resetPasswordReducer from "../features/accounts/troubleLogin/operations/resetPasswordSlice";
import logger from "redux-logger";
import verifyCodeReducer from "../features/accounts/login/operations/verifyCodeSlice";
import userReducer from "../features/landingPage/operations/userSlice";
import filterReducer from "../features/landingPage/addUser/operations/filterSlice";
import addUserReducer from "../features/landingPage/addUser/operations/addUserSlice";
import resendInviteSlice from "../features/accounts/setPassword/operations/resendInviteSlice";
import contactSlice from "../features/accounts/troubleLogin/operations/contactSlice";
import updateUserReducer from "../features/landingPage/updateUser/updateUserSlice";
import lostDeviceReducer from "../features/accounts/login/operations/lostDeviceSlice";
import confirmLostDeviceReducer from "../features/accounts/login/operations/confirmLostDeviceSlice";
import profileReducer from "../features/landingPage/profile/operations/profileSlice";
import facilitiesReducer from "../features/landingPage/addUser/operations/facilitiesSlice";
import addFacilitiesReducer from "../features/landingPage/facilityAddEdit/operations/addFacilitySlice";
import addHospitalsReducer from "../features/landingPage/hospitalAddEdit/operations/addHospitalSlice";
import addLabsReducer from "../features/landingPage/labAddEdit/operations/addLabSlice";
import addTerritoriesReducer from "../features/landingPage/territoryAddEdit/operations/addTerritorySlice";
import addZonesReducer from "../features/landingPage/zoneAddEdit/operations/addZoneSlice";
import updateFacilityReducer from "../features/landingPage/facilityAddEdit/operations/updateFacilitySlice";
import updateHospitalReducer from "../features/landingPage/hospitalAddEdit/operations/updateHospitalSlice";
import updateLabReducer from "../features/landingPage/labAddEdit/operations/updateLabSlice";
import updateTerritoryReducer from "../features/landingPage/territoryAddEdit/operations/updateTerritorySlice";
import updateZoneReducer from "../features/landingPage/zoneAddEdit/operations/updateZoneSlice";
import labDataReducer from "../features/landingPage/labAddEdit/operations/labDataSlice";
import scheduleListSlice from "../features/scheduler/operations/scheduleListSlice";
import scheduleFilterSlice from "../features/scheduler/operations/filterSlice";
import backlogSlice from "../features/scheduler/operations/backlogSlice";
import backlogNonServiceSlice from "../features/scheduler/operations/backlogNonServiceSlice";
import deleteEventSlice from "../features/scheduler/operations/deleteSlice";
import getManagerSlice from "../features/scheduler/operations/reportingSlice";
import updateEventSlice from "../features/scheduler/operations/eventSlice";
import deleteServiceSlice from "../features/scheduler/operations/deleteServiceSlice";
import filterOptionsSlice from "../features/tracker/operations/filterOptionsSlice";
import copyScheduleSlice from "../features/scheduler/operations/copyScheduleSlice";
import tokenSlice from "../features/accounts/login/operations/tokenSlice";
import checkinCheckoutSlice from "../features/scheduler/operations/checkinCheckoutSlice";
import getLabsSlice from "../features/scheduler/operations/getLabsSlice";
import getHospitalsSlice from "../features/scheduler/operations/getHospitalsSlice";
import downloadSlice from "../features/scheduler/operations/downloadSlice";
import markersSlice from "../features/tracker/operations/markersSlice";
import subSetUserReducer from "../features/landingPage/operations/subSetUserSlice";
import hospitalsReducer from "../features/landingPage/hospitalAddEdit/operations/hospitalsSlice";
import labsReducer from "../features/landingPage/labAddEdit/operations/labsSlice";
import territoriesReducer from "../features/landingPage/territoryAddEdit/operations/territoriesSlice";
import zonesReducer from "../features/landingPage/zoneAddEdit/operations/zonesSlice";
import appSlice from "../appSlice";
import appSliceForLogin from "../appSliceForLogin";
import sessionTimeoutSlice from "../features/sessionTimeout/operations/sessionTimeoutSlice";
import addStatOrdersReducer from "../features/statOrder/operations/addStatOrderSlice";
import updateStatOrdersReducer from "../features/statOrder/operations/updateStatOrderSlice";
import addRoutineRedrawsReducer from "../features/routineRedraw/operations/addRoutineRedrawSlice";
import updateRoutineRedrawsReducer from "../features/routineRedraw/operations/updateRoutineRedrawSlice";
import addTimedDrawsReducer from "../features/timedDraw/operations/addTimedDrawSlice";
import updateTimedDrawsReducer from "../features/timedDraw/operations/updateTimedDrawSlice";
import orderFilterListReducer from "../features/statOrder/operations/orderFilterListSlice";
import onCallBacklogSlice from "../features/scheduler/operations/onCallBacklogSlice";
import onCallScheduleListSlice from "../features/scheduler/operations/onCallScheduleListSlice";
import onCallDeleteServiceSlice from "../features/scheduler/operations/onCallDeleteServiceSlice";
import bulkCheckinCheckoutSlice from "../features/statOrder/operations/bulkCheckinCheckoutSlice";
import bulkCheckinCheckoutHomeHealthSlice from "../features/homeHealth/operations/bulkCheckinCheckoutHomeHealthSlice";
import acknowledgePartialOrderSlice from "../features/statOrder/operations/acknowledgePartialOrderSlice";
import unclaimOrderSlice from "../features/homeHealth/operations/unclaimOrderSlice";
import forceAcknowledgeOrderSlice from "../features/statOrder/operations/forceAcknowledgeOrderSlice";
import chatPermissionSlice from "../features/chat/operations/chatPermissionSlice";
import adminLayoutSlice from "../components/layouts/AdminLayoutSlice";
import mSlice from "../mSlice";
import surgeOrNonServiceFacilitiesSlice from "../features/scheduler/operations/getSurgeOrNonServiceFacilitiesSlice";
import updateReportingManagerSlice from "../features/landingPage/operations/assignReportingManagerSlice";
import addHomeHealthReducer from "../features/homeHealth/operations/addHomeHealthSlice";
import updateHomeHealthsReducer from "../features/homeHealth/operations/updateHomeHealthSlice";
import orderSlice from "../features/statOrder/operations/orderSlice";
import checkHomeHealthUserScheduleSlice from "../features/homeHealth/operations/homeHealthUserScheduleSlice";
import cancelHomeHealthReducer from "../features/homeHealth/operations/cancelHomeHealthSlice";
import uncancelHomeHealthReducer from "../features/homeHealth/operations/uncancelHomeHealthSlice";
import fetchDataByPartnerOrderIdReducer from "../features/homeHealth/operations/fetchDataByPartnerOrderIdSlice";
import userSliceReducer from "../features/landingPage/profile/operations/userSlice";
import facilitySliceReducer from "../features/landingPage/facilityAddEdit/operations/facilitySlice";
import hospitalSliceReducer from "../features/landingPage/hospitalAddEdit/operations/hospitalSlice";
import labSliceReducer from "../features/landingPage/labAddEdit/operations/labSlice";
import territorySliceReducer from "../features/landingPage/territoryAddEdit/operations/territorySlice";
import zoneSliceReducer from "../features/landingPage/zoneAddEdit/operations/zoneSlice";
import deleteRoutineActionReducer from "../features/scheduler/operations/deleteRoutineActionSlice";
import deleteStatActionReducer from "../features/statOrder/operations/deleteStatActionSlice";
import assignFacilitiesToZoneReducer from "../features/landingPage/operations/assignFacilitiesToZoneSlice";
import zonesListReducer from "../features/landingPage/operations/zonesSlice";
import activeZonesListReducer from "../features/landingPage/operations/activeZonesSlice";
import activeZonesListWithDateReducer from "../features/landingPage/operations/activeZonesWithDateSlice";
import cancelStatOrderReducer from "../features/statOrder/operations/cancelStatOrderSlice";
import cloneHomeHealthReducer from "../features/homeHealth/operations/cloneHomeHealthSlice";
import searchGlobalReducer from "../features/globalSearch/operations/globalSearchSlice";
import shiftTimingsReducer from "../features/scheduler/operations/shiftTimingsSlice";
import homeHealthScheduleFilterReducer from "../features/homeHealthScheduler/operations/filterSlice";
import homeHealthScheduleListReducer from "../features/homeHealthScheduler/operations/scheduleListSlice";
import getManagerReducer from "../features/homeHealthScheduler/operations/reportingSlice";
import getPhlebReducer from "../features/homeHealthScheduler/operations/phlebSlice";
import getRoutineManagerReducer from "../features/scheduler/operations/routineReportingSlice";
// import parentAccountNumberReducer from "../features/StatOrder/parentAccountNumberSlice";
import hospitalFilterReducer from "../features/landingPage/hospitalAddEdit/operations/hospitalFilterSlice";
import orderExportReducer from "../features/statOrder/operations/exportSlice";
import orderExportFileReducer from "../features/statOrder/operations/exportFileDataSlice";
import deleteExportFileReducer from "../features/statOrder/operations/deleteExportFileSlice";

// reducers for order pages
import { statReducer } from '../features/statOrder/operations/statOrderReducer';
import { routineRedrawReducer } from '../features/routineRedraw/operations/routineRedrawReducer';
import { timedDrawReducer } from '../features/timedDraw/operations/timedDrawReducer';
import { homeHealthReducer } from '../features/homeHealth/operations/homeHealthReducer';
import getCareDxDropSiteReducer from "../features/landingPage/careDxDropSite/operations/getCareDxDropSitesSlice";
import addCareDxDropSiteReducer from "../features/landingPage/careDxDropSite/operations/addCareDxDropSiteSlice";
import updateCareDxDropSiteReducer from "../features/landingPage/careDxDropSite/operations/updateCareDxDropSiteSlice";
import fetchCareDxDropSiteReducer from "../features/landingPage/careDxDropSite/operations/fetchCareDxDropSiteSlice";
import getCareDxDropSiteListReducer from "../features/landingPage/careDxDropSite/operations/getCareDxDropSiteListSlice";


export const store = configureStore({
  reducer: {
    account: accountReducer,
    invite: inviteReducer,
    verifyCode: verifyCodeReducer,
    setPass: setPasswordReducer,
    forgotPassword: forgotPasswordReducer,
    forgotUser: forgotUserReducer,
    resetPassword: resetPasswordReducer,
    users: userReducer,
    subSetUsers: subSetUserReducer,
    filter: filterReducer,
    addUser: addUserReducer,
    resendInvite: resendInviteSlice,
    contact: contactSlice,
    updateUser: updateUserReducer,
    lostDevice: lostDeviceReducer,
    confirmLostDevice: confirmLostDeviceReducer,
    profile: profileReducer,
    facilities: facilitiesReducer,
    addFacilities: addFacilitiesReducer,
    updateFacility: updateFacilityReducer,
    scheduleList: scheduleListSlice,
    scheduleFilter: scheduleFilterSlice,
    backlog: backlogSlice,
    backlognonservice: backlogNonServiceSlice,
    deleteEvent: deleteEventSlice,
    getManager: getManagerSlice,
    updateEvent: updateEventSlice,
    deleteService: deleteServiceSlice,
    mapFilterOptions: filterOptionsSlice,
    copySchedule: copyScheduleSlice,
    token: tokenSlice,
    checkinCheckout: checkinCheckoutSlice,
    labsList: getLabsSlice,
    hospitalsList: getHospitalsSlice,
    download: downloadSlice,
    markersFeatures: markersSlice,
    hospitals: hospitalsReducer,
    updateHospital: updateHospitalReducer,
    addHospitals: addHospitalsReducer,
    labs: labsReducer,
    updateLab: updateLabReducer,
    addLabs: addLabsReducer,
    labData: labDataReducer,
    territories: territoriesReducer,
    updateTerritory: updateTerritoryReducer,
    addTerritories: addTerritoriesReducer,
    zones: zonesReducer,
    updateZone: updateZoneReducer,
    addZones: addZonesReducer,
    appData: appSlice,
    appDataForLogin: appSliceForLogin,
    order: orderSlice,
    sessionTimeout: sessionTimeoutSlice,
    addStatOrders: addStatOrdersReducer,
    updateStatOrders: updateStatOrdersReducer,
    addRoutineRedraws: addRoutineRedrawsReducer,
    updateRoutineRedraws: updateRoutineRedrawsReducer,
    addTimedDraws: addTimedDrawsReducer,
    updateTimedDraws: updateTimedDrawsReducer,
    orderFilterList: orderFilterListReducer,
    onCallBacklog: onCallBacklogSlice,
    onCallScheduleList: onCallScheduleListSlice,
    onCallDeleteService: onCallDeleteServiceSlice,
    bulkCheckinCheckoutState: bulkCheckinCheckoutSlice,
    bulkCheckinCheckoutHomeHealthState: bulkCheckinCheckoutHomeHealthSlice,
    acknowledgePartialOrderState: acknowledgePartialOrderSlice,
    unclaimOrderState: unclaimOrderSlice,
    forceAcknowledgeOrderState: forceAcknowledgeOrderSlice,
    systemChatPermission: chatPermissionSlice,
    reports: adminLayoutSlice,
    md: mSlice,
    surgeOrNonServiceFacilities: surgeOrNonServiceFacilitiesSlice,
    updateReportingManager: updateReportingManagerSlice,
    addHomeHealth: addHomeHealthReducer,
    updateHomeHealths: updateHomeHealthsReducer,
    cloneHomeHealth: cloneHomeHealthReducer,
    checkHomeHealthUserSchedule: checkHomeHealthUserScheduleSlice,
    cancelHomeHealth: cancelHomeHealthReducer,
    dataByPartnerOrderId: fetchDataByPartnerOrderIdReducer,
    user: userSliceReducer,
    facility: facilitySliceReducer,
    hospital: hospitalSliceReducer,
    lab: labSliceReducer,
    territory: territorySliceReducer,
    zone: zoneSliceReducer,
    deleteRoutineAction: deleteRoutineActionReducer,
    deleteStatAction: deleteStatActionReducer,
    assignFacilitiesToZone: assignFacilitiesToZoneReducer,
    zonesList: zonesListReducer,
    activeZonesList: activeZonesListReducer,
    activeZonesListWithDate: activeZonesListWithDateReducer,
    cancelStatOrder: cancelStatOrderReducer,
    uncancelHomeHealth: uncancelHomeHealthReducer,
    globalSearch: searchGlobalReducer,
    shiftTimings: shiftTimingsReducer,
    homeHealthScheduleFilter: homeHealthScheduleFilterReducer,
    homeHealthScheduleList: homeHealthScheduleListReducer,
    getManagerHH: getManagerReducer,
    getPhlebHH: getPhlebReducer,
    getRoutineManager: getRoutineManagerReducer,
    statGridData: statReducer,
    routineGridData: routineRedrawReducer,
    timedDrawGridData: timedDrawReducer,
    homeHealthGridData: homeHealthReducer,
    hospitalFilter: hospitalFilterReducer,
    orderExport: orderExportReducer,
    orderExportFileData: orderExportFileReducer,
    deleteExportFileData: deleteExportFileReducer,
    getCareDxDropSite: getCareDxDropSiteReducer,
    addCareDxDropSite: addCareDxDropSiteReducer,
    updateCareDxDropSite: updateCareDxDropSiteReducer,
    fetchCareDxDropSite: fetchCareDxDropSiteReducer,
    getCareDxDropSiteList: getCareDxDropSiteListReducer
    // parentAccountNumber: parentAccountNumberReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(logger),
});
