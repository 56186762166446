import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAutoRefresh } from './hooks/useAutoRefresh';

// Create a context
const AutoRefreshContext = createContext();

export const AutoRefreshProvider = ({ children, callback, tabValue, loading, states }) => {
  const statesRef = useRef(states);
  
  useEffect(() => {
    statesRef.current = states;
  }, [states]);

  const {
    timer,
    manualRefresh,
    lastRefresh,
    isAutoRefreshOn,
    setIsAutoRefreshOn,
    resetAutoRefreshTimer
  } = useAutoRefresh(() => {
    callback(statesRef.current);
  }, loading);

  useEffect(() => {
    resetAutoRefreshTimer();
  }, [tabValue]);

  return (
    <AutoRefreshContext.Provider value={{
      timer,
      manualRefresh,
      lastRefresh,
      isAutoRefreshOn,
      setIsAutoRefreshOn,
      resetAutoRefreshTimer,
      statesRef
    }}>
      {children}
    </AutoRefreshContext.Provider>
  );
};

// Custom hook to use AutoRefresh context
export const useAutoRefreshContext = () => {
  const context = useContext(AutoRefreshContext);
  if (!context) {
    throw new Error("useAutoRefreshContext must be used within an AutoRefreshProvider");
  }
  return context;
};
