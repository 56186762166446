import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
  status: false,
};

export const postLogin = createAsyncThunk(
  "account/postLogin",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.login(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(postLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(postLogin.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const accountList = (state) => state.account.value;
export const loading = (state) => state.account.loading;

export default accountSlice.reducer;
