import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../../app/services/aha-api";

const initialState = {
  value: [],
  loading: false,
};

export const getTerritories = createAsyncThunk(
  "territories/getTerritories",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.fetchTerritories(data)
      .then((res) => {
        let { content = [] } = res;
        content = content.map(c => {
          const { territory_name, territory_code, territory_description, region = {}, latitude, longitude } = c;

          return {
            ...c,
            territory_name_string: territory_name || "-",
            territory_description_string: territory_description || "-",
            territory_code_string: territory_code || "-",
            latitude_string: latitude ? latitude : "-",
            longitude_string: longitude ? longitude : "-",
            region_code_string: region ? region.region_code || "-"  : "-",
          }
        })
        res.content = content;
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const territoriesSlice = createSlice({
  name: "territories",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getTerritories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTerritories.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getTerritories.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const territoriesList = (state) => state.territories.value;
export const territoriesLoader = (state) => state.territories.loading;

export default territoriesSlice.reducer;
