import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const getUser = createAsyncThunk(
  "user/getUser",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.getUser(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserList(state, action) {
      state.value = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getUser.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const user = (state) => state.user.value;
export const userObjLoader = (state) => state.user.loading;
export const { setUserList } = userSlice.actions;

export default userSlice.reducer;
