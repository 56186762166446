import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";


const initialState = {
  value: [],
  loading: false,
};

export const cloneHomeHealth = createAsyncThunk(
  "homeHealth/cloneHomeHealth",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.cloneHomeHealthOrder(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const cloneHomeHealthSlice = createSlice({
  name: "cloneHomeHealth",
  initialState,
  reducers: {
    clearCloneHomeHealthResponse(state, action) {
      state.value = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cloneHomeHealth.pending, (state) => {
        state.loading = true;
      })
      .addCase(cloneHomeHealth.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(cloneHomeHealth.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const cloneHomeHealthList = (state) => state.cloneHomeHealth.value;
export const cloneHomeHealthListLoading = (state) => state.cloneHomeHealth.loading;
export const { clearCloneHomeHealthResponse } = cloneHomeHealthSlice.actions;
export default cloneHomeHealthSlice.reducer;
