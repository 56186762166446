import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const postResendInvite = createAsyncThunk(
  "account/postResendInvite",
  async (inviteId, { rejectWithValue }) => {
    const response = await ahaApi.Account.resendInvite(inviteId)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const resendInviteSlice = createSlice({
  name: "resendInvite",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(postResendInvite.pending, (state) => {
        state.loading = true;
      })
      .addCase(postResendInvite.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(postResendInvite.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const resendInviteList = (state) =>
  state && state.resendInvite && state.resendInvite.value;

export default resendInviteSlice.reducer;
