import React from "react";
import {
  Aggregate,
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  Toolbar,
  Reorder,
  ExcelExport,
  Resize,
  InfiniteScroll
} from "@syncfusion/ej2-react-grids";

class CustomGrid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toolbar = ["Search", "ExcelExport", "ColumnChooser"];
    this.toolbarClick = (args) => {
      const { type } = this.props;
      if (
        this[`customGrid-${type}`] &&
        args.item.id === `customGrid-${this.props.type}_excelexport`
      ) {
        const excelExportProperties = {
          enableFilter: true,
          includeHiddenColumn: true,
          exportType: "All",
        };
        this[`customGrid-${type}`].excelExport(excelExportProperties);
      }
    };
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isModalOpen !== this.props.isModalOpen && !this.props.isModalOpen) {
      this.clearSelectedRow();
    }
  };
  componentWillUnmount() {
    if (this[`customGrid-${this.props.type}`]) {
      this[`customGrid-${this.props.type}`].destroy();
    }
  }
  clearSelectedRow = () => {
    let grid = this[`customGrid-${this.props.type}`];
    grid && grid.clearRowSelection()
  }
  clickHandler = () => {
    const { type } = this.props;
    this[`customGrid-${type}`].searchSettings.key = "";
    const element = this[`customGrid-${type}`].element.querySelector(
      ".e-input-group.e-search .e-input"
    );
    element.value = "";
    var grid2 = this[`customGrid-${type}`];
    if (
      grid2 &&
      grid2.element &&
      grid2.element
        .getElementsByClassName("e-search")[0]
        .classList.contains("clear")
    ) {
      const parent = document.getElementById(
        `customGrid-${this.props.type}clear`
      );
      parent && parent.parentNode && parent.parentNode.removeChild(parent);
      grid2.element
        .getElementsByClassName("clear")[0]
        .classList.remove("clear");
      grid2.element
        .getElementsByClassName("e-search-icon")[0]
        .classList.remove("hide");
    }
  };
  created = (args) => {
    const { type } = this.props;
    var gridElement = this[`customGrid-${type}`] && this[`customGrid-${type}`].element;

    if (this[`customGrid-${type}`]) {
      this[`customGrid-${type}`].searchModule.hasNonNumericCharacters = function (searchKey) {
        return true;
      };
    }

    if (gridElement) {
      var grid2 = this[`customGrid-${type}`];
      var emptyRow = grid2 && grid2.element.querySelector(".e-emptyrow");
      if (emptyRow) {
        emptyRow.cells[0].innerText = "No records to display.";
      }        

      document &&
        document.getElementById(gridElement.id + "_searchbar") &&
        document
          .getElementById(gridElement.id + "_searchbar")
          .addEventListener("keyup", (e) => {
            this.setDebounce(e.target.value);
            // this[`customGrid-${type}`].search(e.target.value);
          });

      // wrapper for drop area
      let dropArea = gridElement
        ? gridElement.getElementsByClassName("e-groupdroparea")
        : [];
      let dropWrapper = gridElement
        ? gridElement.getElementsByClassName("drag-column-wrapper")
        : [];
      if (dropArea.length > 0 && !dropWrapper.length) {
        dropArea = dropArea[0];

        let divWrapper = document.createElement("div");
        divWrapper.className = "drag-column-wrapper";

        dropArea.parentNode.insertBefore(divWrapper, dropArea);
        divWrapper.appendChild(dropArea);
      }

      if (
        document.getElementById(`${gridElement.id}_searchbar`) &&
        document.getElementById(`${gridElement.id}_searchbar`).value
      ) {
        document.getElementsByClassName("e-search-icon") &&
          document.getElementsByClassName("e-search-icon")[0] &&
          document
            .getElementsByClassName("e-search-icon")[0]
            .classList.add("hide");
      }
    }
  };

  setDebounce = this.debounce((value) => {
    // perform programmatic search
    this[`customGrid-${this.props.type}`].search(value);
  }, 1000); // set the debounce time, increase timeout value as per your need to avoid you mentioned flicker scenario.
  debounce(func, wait) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        func.apply(context, args);
      }, wait);
    };
  }
  dataBound = (args) => {
    const { type } = this.props;
    const gridElement = this[`customGrid-${type}`] && this[`customGrid-${type}`].element;
    if (gridElement) {
      let dropArea = gridElement
        ? gridElement.getElementsByClassName("e-groupdroparea")
        : [];
      let dropWrapper = gridElement
        ? gridElement.getElementsByClassName("drag-column-wrapper")
        : [];
      if (dropArea.length > 0 && !dropWrapper.length) {
        dropArea = dropArea[0];

        let divWrapper = document.createElement("div");
        divWrapper.className = "drag-column-wrapper";

        dropArea.parentNode.insertBefore(divWrapper, dropArea);
        divWrapper.appendChild(dropArea);
      }
    }
    const grid = this[`customGrid-${type}`];
    var grid2 = this[`customGrid-${type}`];
    document &&
      grid2 &&
      document.getElementById(grid2.element.id + "_searchbar") &&
      document
        .getElementById(grid2.element.id + "_searchbar")
        .addEventListener("keyup", (event) => {
          if (!event.target.value) {
            if (
              grid2 &&
              grid2.element &&
              grid2.element.getElementsByClassName("e-search") &&
              grid2.element.getElementsByClassName("e-search")[0] &&
              grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.contains("clear")
            ) {
              const span = document.getElementsByClassName("e-clear-icon");
              while (span && span.length > 0 && span[0]) {
                grid2.element
                  .getElementsByClassName("clear")[0]
                  .removeChild(span[0]);
              }
              grid2.element
                .getElementsByClassName("clear")[0]
                .classList.remove("clear");
              grid2.element
                .getElementsByClassName("e-search-icon")[0]
                .classList.remove("hide");
            }
          } else {
            //  checks whether the cancel icon is already present or not
            if (
              grid2 &&
              grid2.element &&
              !grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.contains("clear")
            ) {
              var gridElement = this[`customGrid-${type}`].element;
              if (
                grid2.element.getElementsByClassName(`e-sicon`) &&
                grid2.element.getElementsByClassName(`e-sicon`)[0]
              ) {
                grid2.element
                  .getElementsByClassName(`e-sicon`)[0]
                  .classList.add("hide");
              }
              var span = document.createElement("span");
              span.className = "e-clear-icon";
              span.id = gridElement.id + "clear";
              span.onclick = this.clickHandler.bind(this);
              grid2.element
                .getElementsByClassName("e-search")[0]
                .appendChild(span);
              grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.add("clear");
              grid2.element
                .getElementsByClassName("e-search-icon")[0]
                .classList.add("hide");
            }
          }
        });

    var emptyRow = grid2 && grid2.element.querySelector(".e-emptyrow");
    if (emptyRow) {
      emptyRow.cells[0].innerText = "No records to display";
    }
    // let cloned = grid.addOnPersist;
    // grid.addOnPersist = function (key) {
    //     key = key.filter((item) => item !== "columns");
    //     return cloned.call(this, key);
    // };
  };
  handleAction = (args) => {
    const { type } = this.props;
    if (args.requestType == "filterafteropen") {
      let grid =
        this[`customGrid-${type}`].element.getElementsByClassName(
          `e-popup-open`
        );
      if (grid && grid.length) {
        grid[0].ej2_instances[0].header = args.filterModel.options.displayName;
      }
    }
    if (args.requestType == 'filterchoicerequest') {
      // hide 'Add current selection to filter' option
      var addCurrent = args.filterModel.dlg.querySelector('.e-add-current');

      if (addCurrent) {
        addCurrent.closest('.e-ftrchk').style.display = 'none';
      }
    }
    if (args.requestType === 'searching') {
      let grid = this[`customGrid-${type}`];
      grid.toolbarModule.enableItems([grid.element.id + '_searchbar'], true);
    }
  };
  actionBegin = (args) => {
    const {type} = this.props;
    if (
      args.requestType === "filterchoicerequest" ||
      args.requestType === "filtersearchbegin"
    ) {
      args.filterChoiceCount = 100000;
    }
    if (args.requestType === 'searching') {
      let grid = this[`customGrid-${type}`];
      grid.toolbarModule.enableItems([grid.element.id + '_searchbar'], false);
    }
  };
  rowSelected = (e) => {
    if (e.isInteracted) {
      if (this.props.type === "user") {
        this.props.editModal(e);
      } else {
        this.props.editModal(true, e, "view");
      }
    }
  };

  render() {
    let {
      data = [],
      loading = false,
      columns = [],
      type,
    } = this.props;
    return (
      <div className={"ad-grid-wrapper grid-common-wrapper"}>
            <div
              className={
                "ad-loading-wrapper " + (loading ? "d-block" : "d-none")
              }
            >
              <span
                className={
                  "loader ad-loader " + (loading ? "d-block" : "d-none")
                }
              >
                <span className="visually-hidden">loader icon</span>
              </span>
            </div>
        <GridComponent
          ref={(comp) => {
            this[`customGrid-${type}`] = comp;
          }}
          id={"customGrid-" + type}
          dataSource={data}
          toolbar={this.toolbar}
          toolbarClick={this.toolbarClick}
          searchSettings={{ operator: 'contains' }}
          created={this.created}
          dataBound={this.dataBound}
          actionComplete={this.handleAction}
          actionBegin={this.actionBegin}
          rowSelected={this.rowSelected}
          groupSettings={{ showDropArea: true }}
          filterSettings={{ type: "CheckBox", enableCaseSensitivity: false }}
          allowSorting={true}
          showColumnChooser={true}
          allowFiltering={true}
          allowExcelExport={true}
          allowResizing={true}
          allowPaging={true}
          allowGrouping={false}
          allowReordering={false}
          delayUpdate
        >
          <Inject
            services={[ExcelExport,
              Reorder,
              Aggregate,
              Group,
              Sort,
              Filter,
              Toolbar,
              ColumnChooser,
              Resize,
              Page,
              InfiniteScroll]}
          />

          <ColumnsDirective>
            {columns.map((column, index) => {
              return (
                <ColumnDirective
                  field={column.field}
                  headerText={column.headerText}
                  width={column.width}
                  template={column.template || null}
                  visible={column.visible}
                  key={index}
                  filter={{ operators: "contains" }}
                />
              );
            })}
          </ColumnsDirective>
        </GridComponent>
      </div>
    );
  }
}
export default CustomGrid;
