import _ from 'lodash';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from '../../../app/services/aha-api';

const initialState = {
  value: [],
  loading: false,
  timezoneDataSet: {}
};

export const filterOptions = createAsyncThunk(
  "tracker/filterOptions",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.filterOptions(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    
    return response;
  }
);
export const filterOptionsSlice = createSlice({
  name: "filterOptions",
  initialState,
  reducers: {
    clearFilterOptionsResponse(state, action) {
      state.value = [];
    },
    setTimezoneDataSet(state, action) {
      state.timezoneDataSet = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(filterOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(filterOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(filterOptions.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const filterOptionsList = (state) => state.mapFilterOptions ? state.mapFilterOptions.value || [] : [];
export const filterOptionsLoader = (state) => state.mapFilterOptions ? state.mapFilterOptions.loading || false : false;
export const timezoneDataSet = (state) => state.mapFilterOptions ? state.mapFilterOptions.timezoneDataSet || {} : {};
export const {clearFilterOptionsResponse, setTimezoneDataSet} = filterOptionsSlice.actions;

export default filterOptionsSlice.reducer;
