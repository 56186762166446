import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React from "react";
import { Button, Modal, Tab, Table, Tabs } from "react-bootstrap";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { phoneFormat } from "../../../app/utils/testUtils";
import _ from "lodash";
import toast from "react-hot-toast";
import ToastContent from "../../../components/layouts/ToastContent";
import * as XLSX from 'xlsx';
import moment from "moment";
import { HOSPITALLIST, escapeRegExp } from "../../../app/utils/Constants";
import Select from "react-select";
import { customStyles, multiValueContainer, Option } from "../../scheduler/FacilityCoverageDropdown";
import PlacesApiLoader from "../../../components/commons/PlacesApiLoader";

const regexNumber = /^[0-9-()]*$/;
const alphanumeric = /^[\w-_.]*$/;
// const timeRegex = new RegExp(":", "g");
const notaplha = /^[0-9.-]*$/;

class HospitalAddEdit extends React.PureComponent {
	constructor(props) {
		super(props);
		this.searchRef = React.createRef('');
		this.state = {
			errors: {},
			data: {},
			initialData: {},
			mode: this.props.mode ? this.props.mode : "view",
			selectedDay: 0,
			isFormChanged: false,
			activeTab: 'info',
			searchText: '',
			clearMapboxSearchTextLabHospital: false
		};
	}

	componentDidMount = () => {
		const { hospitalData = {} } = this.props;
		this.updateHospitalData(hospitalData);
	};
	componentDidUpdate = (prevProps, prevState) => {
		const { hospitalData = {}, mode, updateHospitalList = {}, addHospitalList = {} } = this.props;
		if (prevProps.hospitalData !== hospitalData) {
			this.updateHospitalData(hospitalData);
		}
		if (prevProps.mode !== mode) {
			this.setState({ mode });
		}
		if (updateHospitalList !== prevProps.updateHospitalList && Object.keys(updateHospitalList).length !== 0) {
			if (updateHospitalList.status === "SUCCESS") {
				this.setData({});
				this.setState({ errors: {} });
			}
		}
		if (addHospitalList !== prevProps.addHospitalList && Object.keys(addHospitalList).length !== 0) {
			if (addHospitalList.status === "SUCCESS") {
				this.setData({});
				this.setState({ errors: {} });
			}
		}
		if (prevState.data !== this.state.data && this.state.initialData !== this.state.data) {
			this.setState({
				isFormChanged: this.validateEditFields(this.state.initialData)
			})
		}
	};

	validateEditFields = (initialData) => {
		let result = false;

		const {
			name = "",
			address1 = "",
			accountNumber = "",
			city = "",
			stateName = "",
			zip = "",
			timezone = "",
			phoneNumber = "",
			phoneNumberExt = "",
			notes = "",
			status = "",
			longitudeName = "",
			latitudeName = "",
			hospitalType = "",
			selectedPartners = []
		} = this.state.data;

		const {
			name: prevName = "",
			address1: prevAddress = "",
			accountNumber: prevAccountNumber = "",
			city: prevCity = "",
			stateName: prevStateName = "",
			zip: prevZip = "",
			timezone: prevTimezone = "",
			phoneNumber: prevPhoneNumber = "",
			phoneNumberExt: prevPhoneNumberExt = "",
			notes: prevNotes = "",
			status: prevStatus = "",
			longitudeName: prevLongitudeName = "",
			latitudeName: prevLatitudeName = "",
			hospitalType: prevHospitalType = "",
			selectedPartners: prevSelectedPartners = []
		} = initialData;

		result = !_.isEqual(name, prevName) || !_.isEqual(accountNumber, prevAccountNumber) || !_.isEqual(phoneNumber, prevPhoneNumber)
			|| !_.isEqual(phoneNumberExt, prevPhoneNumberExt) || !_.isEqual(timezone, prevTimezone) ||
			!_.isEqual(address1, prevAddress) || !_.isEqual(city, prevCity) || !_.isEqual(stateName, prevStateName) || !_.isEqual(zip, prevZip) ||
			!_.isEqual(latitudeName ? latitudeName.toString() : latitudeName, prevLatitudeName ? prevLatitudeName.toString() : prevLatitudeName) || !_.isEqual(longitudeName ? longitudeName.toString() : longitudeName, prevLongitudeName ? prevLongitudeName.toString() : prevLongitudeName) || !_.isEqual(notes, prevNotes) || !_.isEqual(status, prevStatus)
			|| (!_.isEqual(hospitalType, prevHospitalType) && !_.isEqual(hospitalType?.value || 0, prevHospitalType?.value || 0)) ||  !_.isEqual(selectedPartners, prevSelectedPartners);

		return result;
	}

	updateHospitalData = (hospitalData) => {
		const {
			hospital_name,
			account_number = "",
			timezone = {},
			hospital_contact_phone_number,
			street_address1,
			street_address2,
			city: selCity,
			state = {},
			zip: selZip,
			hospital_notes,
			id,
			status = {},
			hospital_contact_phone_number_ext,
			latitude,
			longitude,
			audit_histories,
			hospital_type = {},
			partners = []
		} = hospitalData;

		const finalObj = {
			name: hospital_name,
			accountNumber: account_number ? account_number.toString() : "",
			phoneNumber: hospital_contact_phone_number ? phoneFormat(hospital_contact_phone_number.toString()) : null,
			phoneNumberExt: hospital_contact_phone_number_ext || "",
			timezone: timezone ? (timezone.timezone_id ? timezone.timezone_id : "") : "",
			address1: street_address1,
			address2: street_address2,
			city: selCity,
			stateName: state ? (state.state_code ? state.state_code : "") : "",
			zip: selZip,
			notes: hospital_notes,
			id,
			status: status ? (status.id ? status.id : "") : "",
			latitudeName: latitude ? latitude : "",
			longitudeName: longitude ? longitude : "",
			hospitalType: hospital_type ? hospital_type.id ? {...hospital_type, label: hospital_type.type_label, value: hospital_type.id} : "" : "",
			selectedPartners: partners ? partners.length ? partners.map(pt => {return {...pt, label: pt.partner_name, value: pt.id}}) : [] : [],
			audit_histories
		};
		this.setState({
			data: finalObj,
			initialData: finalObj
		});
	};
	setData = (data, fieldName) => {
		const errors = this.state.errors;
		errors[fieldName] = false;
		this.setState({ data, errors });
	};

	handleSubmit = () => {
		const { data, mode = "" } = this.state;
		const { paramId, navigate } = this.props;
		const errors = this.validateFields(data) || {};
		if (Object.keys(errors).length === 0) {
			const {
				name = "",
				accountNumber = "",
				phoneNumberExt = "",
				phoneNumber = "",
				timezone = "",
				address1 = "",
				address2 = "",
				city = "",
				stateName = "",
				zip = "",
				notes = "",
				id,
				status = "",
				latitudeName,
				longitudeName,
				hospitalType = "",
				selectedPartners = []
			} = data;

			let phone;
			if (typeof phoneNumber === "string") {
				phone = phoneNumber.match(/\d/g);
				phone = phone.join("");
			} else {
				phone = phoneNumber;
			}
			const payload = {
				id,
				hospital_name: name,
				account_number: accountNumber,
				hospital_contact_phone_number: Number(phone),
				hospital_contact_phone_number_extension: phoneNumberExt ? Number(phoneNumberExt) : null,
				timezone_id: timezone,
				street_address1: address1,
				street_address2: address2,
				city,
				state: stateName,
				zip: Number(zip),
				hospital_notes: notes,
				status: status,
				latitude: latitudeName,
				longitude: longitudeName,
				hospital_type_id: hospitalType.value,
				partner_id: selectedPartners ? selectedPartners.map(p => p.value) : [],
				hospital_timings: [
					{
						day_id: 1,
						start_time: "00:00",
						end_time: "00:00",
					},
					{
						day_id: 2,
						start_time: "00:00",
						end_time: "00:00",
					},
					{
						day_id: 3,
						start_time: "00:00",
						end_time: "00:00",
					},
					{
						day_id: 4,
						start_time: "00:00",
						end_time: "00:00",
					},
					{
						day_id: 5,
						start_time: "00:00",
						end_time: "00:00",
					},
					{
						day_id: 6,
						start_time: "00:00",
						end_time: "00:00",
					},
					{
						day_id: 7,
						start_time: "00:00",
						end_time: "00:00",
					},
				],
			};
			if (mode === "create") {
				this.props.handleCreateHospital(payload);
			} else {
				this.props.handleUpdateHospital(payload);
			}
		} else {
			this.setState({ errors }, () => {
				toast(
					(t) => (
						<ToastContent type="error" message={`Check if all required fields have been filled in before you ${mode === "edit" ? "update" : "create"} a hospital`} close={() => toast.remove()} />
					),
					{
						duration: 4000,
					}
				);
				if (document.querySelector('.error-message')) {
					document.querySelector('.error-message').scrollIntoView({
						behavior: 'smooth'
					});
				} else if (document.querySelector('.error-message-text')) {
					document.querySelector('.error-message-text').scrollIntoView({
						behavior: 'smooth'
					});
				}
			});
		}
		if (paramId) {
			navigate(HOSPITALLIST, { replace: true });
		}
	};
	validateFields = (data) => {
		const errors = {};

		const {
			name = "",
			phoneNumber = "",
			timezone = "",
			address1 = "",
			city = "",
			stateName = "",
			status = "",
			zip = "",
			hospitalType = "",
			selectedPartners = []
		} = data;

		if (_.isEmpty(name)) {
			errors["name"] = true;
		}
		if (_.isEmpty(phoneNumber)) {
			errors["phoneNumber"] = true;
		}
		if (!timezone) {
			errors["timezone"] = true;
		}
		if (_.isEmpty(address1)) {
			errors["address1"] = true;
		}
		if (_.isEmpty(city)) {
			errors["city"] = true;
		}
		if (_.isEmpty(stateName)) {
			errors["stateName"] = true;
		}
		if (_.isEmpty(zip)) {
			errors["zip"] = true;
		}
		if (_.isEmpty(status)) {
			errors["status"] = true;
		}
		if (!hospitalType || !hospitalType.value) {
			errors["hospitalType"] = true;
		}
		if (hospitalType && hospitalType.value && !hospitalType.is_non_partner && _.isEmpty(selectedPartners) && !selectedPartners.length) {
			errors["selectedPartners"] = true;
		}
		return errors;
	};
	handleClose = (e) => {
		const { mode } = this.state;
		const { paramId, navigate } = this.props;
		const { activeTab } = this.state;
		if (activeTab === 'audit') {
			this.setState({ errors: {}, searchText: '', activeTab: 'info', selectedDay: 0, clearMapboxSearchTextLabHospital: true });
			this.props.handleHospitalError();
			this.props.toggleHospitalModal(false);
		} else {
			if (mode === "view" || mode === "create") {
				this.setState({ errors: {}, searchText: '', activeTab: 'info', selectedDay: 0, clearMapboxSearchTextLabHospital: true });
				this.props.handleHospitalError();
				this.props.toggleHospitalModal(false);
			} else {
				const { hospitalData = {} } = this.props;
				this.updateHospitalData(hospitalData);
				this.setState({ errors: {}, searchText: '', activeTab: 'info', selectedDay: 0, clearMapboxSearchTextLabHospital: true });
				this.props.handleHospitalError();
			}
		}
		if (paramId) {
			navigate(HOSPITALLIST, { replace: true });
		}
	};

	onFocus = (event) => {
		event.target.setAttribute("autocomplete", "none");
	};

	handleExcelExport = (e, data) => {
		e.preventDefault();
		const modifiedData = [...data];
		const modifiedHeaders = this.renameHeaders(modifiedData);
		const ws = XLSX.utils.json_to_sheet(modifiedHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
		XLSX.writeFile(wb, `${'Hospital-AuditLogs'}.xlsx`);
	}

	handleInputChange = (e) => {
		const userInput = e.target.value;
		const escapedInput = escapeRegExp(userInput);
		const regex = new RegExp(`(${escapedInput})`);
		this.setState({ searchText: escapedInput });
	};

	handleChangeTab = (val) => {
		this.setState({ searchText: '', activeTab: val });
	}

	renameHeaders = (originalData) => {
		return originalData.map((item) => ({
			'Date/Time': item.datetimeString,
			'Changed By': item.changedByString,
			'Change': item.activity,
		}));
	};

	clearSearchInput = () => {
		this.setState({ searchText: '' }, () => {
			if (this.searchRef && this.searchRef.current) {
				this.searchRef.current.value = "";
			}
		})
	}

	render() {
		const {
			error,
			timeZones = [],
			// states = [],
			isOpen = false,
			hospitalData = {},
			hospitalObjLoading,
			hospital_type = [],
			partners = []
		} = this.props;

		const permission =
			(window.localStorage.getItem("userDetail") &&
				JSON.parse(window.localStorage.getItem("userDetail")).permission &&
				JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
			[];
		const hospitalPermission = permission.filter((perm) => perm.page === "hospitals");
		const hospitalAccess = hospitalPermission.length === 1 ? hospitalPermission[0].access : [];
		const canUpdateHospital =
			hospitalAccess && hospitalAccess.find((item) => item === "update") === "update" ? true : false;

		const {
			errors,
			mode,
			data,
			isFormChanged,
			activeTab,
			searchText,
			clearMapboxSearchTextLabHospital
			// selectedDay
		} = this.state;
		const { setData, handleSubmit, handleClose, onFocus, handleInputChange, handleExcelExport, handleChangeTab } = this;

		const {
			name = "",
			address1 = "",
			// address2 = "",
			accountNumber = "",
			city = "",
			stateName = "",
			zip = "",
			timezone = "",
			phoneNumber = "",
			phoneNumberExt = "",
			notes = "",
			status = "",
			longitudeName = "",
			latitudeName = "",
			hospitalType = "",
			selectedPartners = []
		} = data;

		const { audit_histories = [] } = hospitalData;

		const headers = [
			{ label: "Date/ Time", key: "datetimeString" },
			{ label: "Changed By", key: "changedByString" },
			{ label: "Change", key: "activity" }
		];

		let auditLogs = [];
		audit_histories && audit_histories.length > 0 && audit_histories.forEach((history) => {
			const {
				user = {},
				activity,
				datetime = "",
				type,
			} = history;
			const datetimeString = moment(datetime).format("MM/DD/YYYY HH:mm:ss");
			const changedByString = `${user
				? `${user.name}${user.username ? ` (${user.username})` : ""
				}` || ""
				: ""}
			${type
					? ` (${type})`
					: activity === "Order Updated"
						? ` (DESKTOP)`
						: ""}`
			if ((!searchText || activity.toLowerCase().search(searchText.toLowerCase()) !== -1 ||
				datetimeString.toLowerCase().search(searchText.toLowerCase()) !== -1 || changedByString.toLowerCase().search(searchText.toLowerCase()) !== -1)) {
				auditLogs.push({
					...history,
					activity, datetimeString, changedByString
				})
			}
		});
		console.log(isFormChanged, 'isFormChanged');
		return (
			<div>
				<Modal
					show={isOpen}
					onHide={handleClose}
					className="ad-adduser-modal facility-filter"
					backdrop="static"
					centered
					enforceFocus={false}
					data-testid="modal-unique"
				>
					<div
						className={"ad-loading-wrapper " + (hospitalObjLoading ? "d-block" : "d-none")}
					>
						<span
							className={"loader ad-loader " + (hospitalObjLoading ? "d-block" : "d-none")}
						>
							<span className="visually-hidden">loader icon</span>
						</span>
					</div>
					<Modal.Header closeButton>
						{mode === "view" ? (
							<Modal.Title className="headline4" data-testid="view-hospital">
								Hospital
							</Modal.Title>
						) : mode === "create" ? (
							<React.Fragment>
								<Modal.Title className="headline4"> Add New Hospital</Modal.Title>
								{error && <p className="error-message-alert">{error}</p>}
							</React.Fragment>
						) : (
							<React.Fragment>
								<Modal.Title className="headline4"> Edit Hospital</Modal.Title>
								{error && <p className="error-message-alert">{error}</p>}
							</React.Fragment>
						)}
					</Modal.Header>
					<Modal.Body>
						{mode === "create" ? <p className="headline6">Account Information</p>
							: <div className="ad-headerbottom-wrapper">
								<Tabs className="ad-navtab help-nav-tab" activeKey={activeTab} onSelect={(k) => handleChangeTab(k)} >
									<Tab
										eventKey={'info'}
										title="Account Information"
										className="stat-order-wrapper"
									></Tab>
									<Tab
										eventKey={'audit'}
										title="Audit Logs"
										className="stat-order-wrapper"
									></Tab>
								</Tabs>
							</div>}
						{activeTab === 'info' && <form className="aha-modalform-wrapper ad-care-modalform-wrapper">
							<div className={"aha-modalform-group  flex-50 " + (errors.name ? "error-message" : "")}>
								<label className="caption" htmlFor="acf-name">
									Hospital Name
								</label>
								<input
									data-testid="field-accountName"
									type="text"
									id="acf-name"
									value={name}
									placeholder="Hospital Name"
									autoComplete="nope"
									onFocus={onFocus}
									required
									onChange={(e) => {
										setData(
											{
												...data,
												name: e.target.value,
											},
											"name"
										);
									}}
									disabled={mode === "view"}
								/>
								{errors.name && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div className={"aha-modalform-group " + (errors.accountNumber ? "error-message" : "")}>
								<label className="caption" htmlFor="acf-acno">
									Account Number
								</label>
								<input
									data-testid="field-accountNumber"
									// type="text"
									name="f-account"
									id="acf-acno"
									placeholder="Account Number"
									value={accountNumber}
									autoComplete="nope"
									onFocus={onFocus}
									required
									onChange={(e) => {
										alphanumeric.test(e.target.value) &&
											setData(
												{
													...data,
													accountNumber: e.target.value,
												},
												"accountNumber"
											);
									}}
									disabled={mode === "view"}
								/>
								{errors.accountNumber && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div className={"aha-modalform-group ch-form-group " + (errors.hospitalType ? "error-message" : "")}>
								<label className="caption" htmlFor="acf-type">
									Hospital Type
								</label>
								<Select
									id="acf-type"
									placeholder={"Hospital Type"}
									// className="order-info-phleb"
									// classNamePrefix="sch-statlisting-select"
									classNamePrefix="sch-schedule-select"
									value={hospitalType}
									onChange={(e) => {
										this.setData(
											{
												...this.state.data,
												hospitalType: e || "",
												selectedPartners: []
											},
											"hospitalType"
										);
									}}
									options={hospital_type}
									isDisabled={mode === "view"}
									isClearable
									backspaceRemovesValue
									isSearchable
								/>
								{errors.hospitalType && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							{hospitalType && !hospitalType.is_non_partner && <div className={"aha-modalform-group ch-form-group " + (errors.selectedPartners ? "error-message" : "")}>
								<label className="caption" htmlFor="acf-partner-name">
									Partner Name(s)
								</label>
								<Select
                                name="acf-partner-name"
                                id="acf-partner-name"
                                className="sch-copycal-dropdown sch-facility-dropdown"
                                classNamePrefix="sch-copycal-select sch-facility-select"
                                isDisabled={mode === "view"}
                                placeholder="Select Partner Name(s)"
                                options={partners || []}
                                value={selectedPartners || []}
								onChange={(e) => {
									this.setData(
										{
											...this.state.data,
											selectedPartners: e || "",
										},
										"selectedPartners"
									);
								}}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option: Option,
                                    MultiValueContainer: multiValueContainer,
                                }}
                                styles={customStyles}
                                isSearchable={false}
                            />
								{errors.selectedPartners && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>}
							<div className={"aha-modalform-group ch-form-group " + (errors.phoneNumber ? "error-message" : "")}>
								<div className="form-phext-wrapper">
									<div className="phno-field">
										<label className="caption" htmlFor="acf-facility-phno">
											Hospital Contact Phone Number
										</label>
										<input
											type="text"
											name="fphone"
											id="acf-facility-phno"
											className="form-phext-input"
											data-testid="field-phoneNumber"
											autoComplete="nope"
											onFocus={onFocus}
											placeholder="Hospital Contact Phone Number"
											required
											value={phoneNumber}
											onChange={(e) => {
												this.setData(
													{
														...this.state.data,
														phoneNumber: phoneFormat(e.target.value),
													},
													"phoneNumber"
												);
											}}
											disabled={mode === "view"}
										/>
									</div>
									<div className="extension-field">
										<label className="caption" htmlFor="acf-facility-phno-extension">
											<span className="visually-hidden">Extension (optional)</span>
										</label>
										<input
											// type="number"
											className="form-phext-input"
											autoComplete="nope"
											onFocus={onFocus}
											id="acf-facility-phno-extension"
											data-testid="field-facExt"
											placeholder="Ext"
											onChange={(e) => {
												regexNumber.test(e.target.value) &&
													setData({
														...data,
														phoneNumberExt: e.target.value,
													});
											}}
											value={phoneNumberExt}
											disabled={mode === "view"}
										/>
									</div>
								</div>
								{errors.phoneNumber && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div
								className={"aha-modalform-group mr-0 ch-form-group " + (errors.timezone ? "error-message" : "")}
							>
								<label className="caption" htmlFor="acf-timezone">
									Timezone
								</label>
								<DropDownListComponent
									id="acf-timezone"
									cssClass="modal-droprdown-input modal-popup-dropdown"
									placeholder="Please choose"
									dataSource={timeZones}
									data-testid="field-timezone"
									fields={{ text: "name", value: "id" }}
									required
									filterBarPlaceholder="Timezone"
									allowFiltering={true}
									enabled={mode !== "view"}
									value={timezone}
									change={(e) => {
										if (e.isInteracted) {
											const { itemData = {} } = e;
											setData(
												{
													...data,
													timezone: itemData.id,
												},
												"timezone"
											);
										}
									}}
								/>
								{errors.timezone && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div className={"aha-modalform-group flex-75 mr-3 " + (errors.isInvalid ? "error-message" : "")}>
								<label className="caption" htmlFor="ad-search-address">
									Search Address
								</label>
								<PlacesApiLoader
									id="ad-search-hospital-address"
									pageType="hospital"
									handleChange={(newData, isInvalid = false) => {
										errors["isInvalid"] = isInvalid;
										errors["address1"] = false;
										this.setState({
											data: {
												...data,
												...newData,
											},
											errors,
										});
									}}
									stateData={data}
									disabled={mode === "view"}
									clearMapboxSearchTextLabHospital={clearMapboxSearchTextLabHospital}
									setClearMapboxSearchTextLabHospital={(val) => this.setState({ clearMapboxSearchTextLabHospital: val })}
								/>
								{errors.isInvalid && (
									<p data-testid="error-text-id" className="error-message-text">
										Invalid Address
									</p>
								)}
							</div>
							<div
								className={
									"aha-modalform-group flex-75 aha-modalform-group flex-75 mr-3 " +
									(errors.address1 ? "error-message" : "")
								}
							>
								<label className="caption" htmlFor="ad-hospital-street-address">
									Street Address 1
								</label>
								<input
									type="text"
									id="ad-hospital-street-address"
									placeholder="Street Address 1"
									autoComplete="nope"
									onFocus={onFocus}
									value={address1}
									data-testid="field-address1"
									onChange={(e) => {
										setData(
											{
												...data,
												address1: e.target.value,
											},
											"address1"
										);
									}}
									// disabled={mode === "view"}
									disabled={true}
								/>
								{errors.address1 && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>

							<div className={"aha-modalform-group " + (errors.city ? "error-message" : "")}>
								<label className="caption" htmlFor="ad-city">
									City
								</label>
								<input
									type="text"
									id="ad-city"
									placeholder="City"
									data-testid="field-city"
									autoComplete="nope"
									onFocus={onFocus}
									value={city}
									onChange={(e) => {
										setData(
											{
												...data,
												city: e.target.value,
											},
											"city"
										);
									}}
									// disabled={mode === "view"}
									disabled={true}
								/>
								{errors.city && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div className={"aha-modalform-group " + (errors.stateName ? "error-message" : "")}>
								<label className="caption" htmlFor="ad-state">
									State
								</label>
								<input
									type="text"
									id="ad-state"
									placeholder="State"
									data-testid="field-state"
									autoComplete="nope"
									onFocus={onFocus}
									value={stateName}
									onChange={(e) => {
										setData(
											{
												...data,
												stateName: e.target.value,
											},
											"stateName"
										);
									}}
									// disabled={mode === "view"}
									disabled={true}
								/>
								{errors.stateName && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div className={"aha-modalform-group " + (errors.zip ? "error-message" : "")}>
								<label className="caption" htmlFor="ad-zip">
									Zip
								</label>
								<input
									type="text"
									name="zip"
									id="ad-zip"
									placeholder="Zip"
									data-testid="field-zip"
									autoComplete="nope"
									onFocus={onFocus}
									value={zip}
									onChange={(e) => {
										const val = e.target.value.replace(/\D/g, "");
										setData({ ...data, zip: val }, "zip");
									}}
									// disabled={mode === "view"}
									disabled={true}
								/>
								{errors.zip && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div className="aha-modalform-group">
								<label className="caption" htmlFor="acf-latitude">
									Latitude (Optional)
								</label>
								<input
									data-testid="field-Lattitude"
									id="acf-latitude"
									name="latitude"
									value={latitudeName}
									autoComplete="nope"
									onFocus={onFocus}
									placeholder="Latitude (Optional)"
									onChange={(e) => {
										if (
											(notaplha.test(e.target.value) &&
												(isNaN(parseFloat(e.target.value)) ||
													(parseFloat(e.target.value) <= 90 && parseFloat(e.target.value) >= -90))) ||
											e.target.value === ""
										) {
											setData({
												...data,
												latitudeName: e.target.value,
											});
										}
									}}
									disabled={mode === "view"}
								/>
							</div>
							<div className="aha-modalform-group">
								<label className="caption" htmlFor="acf-longitude">
									Longitude (Optional)
								</label>
								<input
									data-testid="field-longitude"
									id="acf-longitude"
									name="longitude"
									value={longitudeName}
									autoComplete="nope"
									onFocus={onFocus}
									placeholder="Longitude (Optional)"
									onChange={(e) => {
										if (
											(notaplha.test(e.target.value) &&
												(isNaN(parseFloat(e.target.value)) ||
													(parseFloat(e.target.value) <= 180 && parseFloat(e.target.value) >= -180))) ||
											e.target.value === ""
										) {
											setData({
												...data,
												longitudeName: e.target.value,
											});
										}
									}}
									disabled={mode === "view"}
								/>
							</div>
						</form>}
						{activeTab === 'info' && <p className="headline6">Service Information</p>}
						{activeTab === 'info' && <form className="aha-modalform-wrapper ad-care-modalform-wrapper">
							<div className="aha-modalform-group flex-50 mr-0">
								<label className="caption" htmlFor="acf-facility-note">
									Hospital Notes
								</label>
								<textarea
									placeholder="Hospital Notes"
									id="acf-facility-note"
									value={notes}
									data-testid="field-hospitalNotes"
									onChange={(e) => {
										setData({
											...data,
											notes: e.target.value,
										});
									}}
									disabled={mode === "view"}
								/>
								<div
									className={"aha-modalform-group facility-status-drop " + (errors.status ? "error-message" : "")}
								>
									<label htmlFor="status">Status</label>
									<DropDownListComponent
										id="status"
										cssClass="modal-droprdown-input modal-popup-dropdown"
										dataSource={[
											{ id: "ACTIVE", name: "Active" },
											{ id: "INACTIVE", name: "Inactive" },
										]}
										fields={{ text: "name", value: "id" }}
										data-testid="field-status"
										placeholder="Status"
										value={status}
										change={(e) => {
											if (e.isInteracted) {
												const { itemData = {} } = e;
												setData(
													{
														...data,
														status: itemData.id,
													},
													"status"
												);
											}
										}}
										enabled={mode !== "view"}
									/>
									{errors.status && (
										<p data-testid="error-text-id" className="error-message-text">
											Selection Required
										</p>
									)}
								</div>
							</div>
						</form>}
						{activeTab === 'audit' && <form className="auditlog-wrapper">
							<div className="auditlog-top">
								<div
									className={
										"search-box"
									}
								>
									<input
										type="text"
										id="ad-search"
										placeholder="Search"
										data-testid="search"
										onChange={(e) => handleInputChange(e)}
										// value={searchText}
										onFocus={onFocus}
										ref={this.searchRef}
									/>
									{searchText && <span className="default-clear-btn" onClick={e => this.clearSearchInput()}><span className="visually-hidden">Clear Icon</span></span>}
									<span className="search-icon"><span className="visually-hidden">Search Icon</span></span>
								</div>
								<div className="export-excel-btn">
									<button disabled={!auditLogs.length} onClick={(e) => handleExcelExport(e, auditLogs)}>
										<span className="e-excelexport"></span>Excel Export
									</button>
								</div>
							</div>
							<div
								className={
									"auditlog-list"
								}
							>
								{audit_histories && audit_histories.length > 0 &&
									<Table responsive borderless className="audit-log-table">
										<thead>
											<tr>
												{headers.map((header, index) => {
													return (
														<th key={index}>{header.label}</th>
													)
												})}
											</tr>
										</thead>
										<tbody>
											{auditLogs.map((history, index) => {
												const {
													changedByString = "",
													activity = "",
													datetimeString = "",
													abbreviation
												} = history;
												return ((!searchText || activity.toLowerCase().search(searchText.toLowerCase()) !== -1 ||
													datetimeString.toLowerCase().search(searchText.toLowerCase()) !== -1 || changedByString.toLowerCase().search(searchText.toLowerCase()) !== -1) &&
													<tr key={index}>
														<td>
															{datetimeString} {abbreviation || ""}
														</td>
														<td>
															{changedByString}
														</td>
														<td>{activity}</td>
													</tr>
												);
											})}
										</tbody>
									</Table>}
								{auditLogs.length == 0 && <p>No logs.</p>}
							</div>

						</form>}
					</Modal.Body>
					<Modal.Footer>
						<Button
							data-testid="button-cancel"
							className="primary-btn primary-btn-outline outline"
							onClick={() => {
								handleClose();
							}}
						>
							Cancel
						</Button>
						{activeTab === 'info' ? mode === "view" ? (
							canUpdateHospital && (
								<Button
									data-testid="button-edit"
									className="primary-btn primary-btn-outline outline"
									onClick={() => {
										this.setState({ isFormChanged: false }, () => {
											this.props.toggleHospitalModal(true, { data: hospitalData }, "edit");
										})
									}}
								>
									Edit Hospital
								</Button>
							)
						) : (
							<ProgressButtonComponent
								data-testid="button-submit"
								content={mode === "edit" ? "Save" : "Create Hospital"}
								enableProgress={true}
								spinSettings={{ position: "Center" }}
								animationSettings={{ effect: "SlideLeft" }}
								cssClass="primary-btn primary-btn-outline ad-progress-btn"
								onClick={() => handleSubmit()}
								disabled={mode === "edit" ? !isFormChanged : false}
							></ProgressButtonComponent>
						) : null}
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}
export default HospitalAddEdit;
