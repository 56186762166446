// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { fetchAuthSession } from 'aws-amplify/auth';
import { downloadData, getUrl, remove } from 'aws-amplify/storage';
import AWS from "aws-sdk";
import { appConfig } from '../Config';

/**
 * @class AttachmentService
 *
 * @description can be used to upload, download and remove attachments
 */
class AttachmentService {
  /**
   * userLevel : private|protected|public
   */
  static userLevel = 'protected';

  /**
   * userUploadDir : Default prefix of upload directory
   */
  static userUploadDir = 'uploadfiles';

  /**
   * Upload file object to AWS S3 bucket using 'userLevel' defined within this class.
   * @param {object} fileObj File to be put in bucket
   * @returns {Promise} promise resolves to object on success
   */
  static async upload(fileObj, profileId) {
    try {
      const {credentials} = await fetchAuthSession();
      const s3 = new AWS.S3({
        ...credentials,
        params: { Bucket: appConfig.attachments_s3_bucket_name },
      });

      const response = await s3.upload({
        Key: `${this.userLevel}/${profileId}/${this.userUploadDir}/${fileObj.name}`,
        Body: fileObj,
      }).promise()
      return response ? {
        ...response, userLevel: this.userLevel,
        userUploadDir: this.userUploadDir
      } : {};
    } catch (err) {
      throw new Error(`Failed to upload file! with error: ${err}`);
    }
  }

  /**
   * Get a presigned URL of the file or the object data
   *
   * @param {string} fileKey - key of the object
   * @param {string} userId - userId of the user who shared the attachment.
   * @return {Promise}- A promise resolves to either a presigned url or the object
   */
  static async download(fileKey, userId, userLevel, userUploadDir) {
    return getUrl({
      path:  `${userLevel || this.userLevel}/${userId}/${userUploadDir || this.userUploadDir}/${fileKey}`,
      options: {
        level: userLevel || this.userLevel,
        identityId: userId
      }
    });
  }

  /**
   * Remove the object for specified key
   * @param {string} fileKey - key of the object
   * @return - Promise resolves upon successful removal of the object
   */
  static delete(fileKey) {
    return remove(fileKey, { level: this.userLevel });
  }
}

export default AttachmentService;
