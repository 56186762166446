import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const getZones = createAsyncThunk("users/getZones", async (data) => {
  const response = await ahaApi.Account.getZones(data).then((res) => {
    return res;
  });

  return response;
});

export const zonesSlice = createSlice({
  name: "zone",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getZones.pending, (state) => {
        state.loading = true;
      })
      .addCase(getZones.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getZones.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const zonesList = (state) => state&&state.zonesList&&state.zonesList.value;

export default zonesSlice.reducer;
