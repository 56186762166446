import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../../app/services/aha-api";

const initialState = {
  value: [],
  loading: false,
};

export const updateFacility = createAsyncThunk(
  "users/updateFacility",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.updateFacility(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const updateFacilitySlice = createSlice({
  name: "updateFacility",
  initialState,
  reducers: {
    clearUpdateFacilityResponse(state, action) {
      state.value = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateFacility.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFacility.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(updateFacility.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const updateFacilityList = (state) => state.updateFacility.value;
export const updateFacilityLoader = (state) => state.updateFacility.loading;
export const {clearUpdateFacilityResponse} = updateFacilitySlice.actions;

export default updateFacilitySlice.reducer;
