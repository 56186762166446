import React from "react";
import {
  MeetingProvider,
  NotificationProvider,
  lightTheme,
  darkTheme,
} from "amazon-chime-sdk-component-library-react";
import { useDispatch, useSelector } from "react-redux";

import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import {
  USERLIST,
  SCHEDULE,
  GENERAL,
  CHAT,
  routes,
  TRACKER,
  STATORDER,
  HOME,
  ROUTINEREDRAW,
  FACILITYLIST,
  LABLIST,
  HOSPITALLIST,
  TIMEDDRAW,
  HOMEHEALTH,
  TERRITORYLIST,
  ZONELIST,
  USER_GUIDE,
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
  SEARCH,
  CARE_AT_HOME_SCHEDULE,
  CAREDXDROPSITESLIST,
} from "../../app/utils/Constants";
import Chat from "../../features/chat/Chat";
import Authenticated from "../../features/chat/components/Authenticated";
import Notifications from "../../features/chat/containers/Notifications";
import {
  AppStateProvider,
  useAppState,
} from "../../features/chat/providers/AppStateProvider";
import { AuthProvider } from "../../features/chat/providers/AuthProvider";
import { MessagingProvider } from "../../features/chat/providers/ChatMessagesProvider";
import IdentityProvider from "../../features/chat/providers/IdentityProvider";
import { NavigationProvider } from "../../features/chat/providers/NavigationProvider";
import { UserPermissionProvider } from "../../features/chat/providers/UserPermissionProvider";
import Channels from "../../features/chat/views/Channels";
import ChannelsAdmin from "../../features/chat/adminviews/Channels";
import Signin from "../../features/chat/views/Signin";
import Home from "../../features/landingPage/Home";
import { Scheduler } from "../../features/scheduler/Scheduler";
import { Tracker } from "../../features/tracker/Tracker";
import ScheduleComponent from "../commons/SchedulerComponent";
import AdminLayout from "../layouts/AdminLayout";
import {
  validSessionDataForLogin,
  validSessionForLogin,
} from "../../appSliceForLogin";
import StatOrder from "../../features/statOrder";
import { MobileView } from "../../features/mobile";
import { UserGuide } from "../../features/userGuide/UserGuide";
import { GlobalSearch } from "../../features/globalSearch/GlobalSearch";
import { HomeHealthCalendar } from "../../features/homeHealthScheduler/HomeHealthCalendar";

export default function PrivateRoute(props) {
  const { isMobile } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validSessionResponse = useSelector(validSessionDataForLogin);

  const hasUserData =
    window.localStorage.getItem("userData") &&
      JSON.parse(window.localStorage.getItem("userData")).access_token
      ? true
      : false;

  let roles =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).roles &&
      JSON.parse(window.localStorage.getItem("userDetail")).roles) ||
    [];

  let canViewAdminChat = roles && (roles.includes(ROLE_ADMIN) || roles.includes(ROLE_SUPER_ADMIN));

  React.useEffect(() => {
    hasUserData && dispatch(validSessionForLogin());
  }, []);

  React.useEffect(() => {
    if (validSessionResponse) {
      if (
        validSessionResponse.status &&
        validSessionResponse.status === "SUCCESS"
      ) {
        navigate(
          window.location.pathname === "/" || window.location.pathname === ""
            ? TRACKER
            : `${window.location.pathname}${window.location.search}`
        );
      }
    }
  }, [validSessionResponse]);

  return (
    <AppStateProvider>
      <Theme>
        <NotificationProvider>
          <Notifications />
          <MeetingProvider>
            <AuthProvider>
              {window.location.pathname &&
                window.location.pathname.includes("chats") && <Authenticated />}
              <IdentityProvider>
                <NavigationProvider>
                  <MessagingProvider>
                    <UserPermissionProvider>
                      <AdminLayout>
                        {isMobile ? (
                          <Routes>
                            <Route path={"/"} element={<MobileView />} />
                            <Route path="*" element={<Navigate to="/" replace />} />
                          </Routes>
                        ) : (
                          <Routes>
                            <Route path={USERLIST} element={<Home />} />
                            <Route path={FACILITYLIST} element={<Home />} />
                            <Route path={LABLIST} element={<Home />} />
                            <Route path={HOSPITALLIST} element={<Home />} />
                            <Route path={TERRITORYLIST} element={<Home />} />
                            <Route path={ZONELIST} element={<Home />} />
                            <Route path={CAREDXDROPSITESLIST} element={<Home />} />
                            <Route
                              path={SCHEDULE}
                              element={<Scheduler />}
                            />
                            <Route
                              path={CARE_AT_HOME_SCHEDULE}
                              element={<HomeHealthCalendar />}
                            />
                            <Route
                              path={GENERAL}
                              element={<ScheduleComponent />}
                            />
                            <Route path={CHAT} element={<Chat />} />
                            <Route
                              path={STATORDER}
                              element={<StatOrder />}
                            />
                            <Route
                              path={ROUTINEREDRAW}
                              element={<StatOrder />}
                            />
                            <Route
                              path={TIMEDDRAW}
                              element={<StatOrder />}
                            />
                            <Route
                              path={HOMEHEALTH}
                              element={<StatOrder />}
                            />
                            <Route
                              path={routes.SIGNIN}
                              element={<Signin />}
                            />
                            <Route path={routes.CHAT} element={<Channels />} />
                            {canViewAdminChat && <Route path={routes.CHAT_ADMIN} element={<ChannelsAdmin />} />}
                            <Route path={USER_GUIDE} element={<UserGuide />} />
                            <Route path={TRACKER} element={<Tracker />} />
                            <Route path={SEARCH} element={<GlobalSearch />} />
                          </Routes>
                        )}
                      </AdminLayout>
                    </UserPermissionProvider>
                  </MessagingProvider>
                </NavigationProvider>
              </IdentityProvider>
            </AuthProvider>
          </MeetingProvider>
        </NotificationProvider>
      </Theme>
    </AppStateProvider>
  );
}
const Theme = ({ children }) => {
  const { theme } = useAppState();

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      {/* <GlobalStyles /> */}
      {children}
    </ThemeProvider>
  );
};
