import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const getActiveZonesWithDate = createAsyncThunk("users/getActiveZonesWithDate", async (data) => {
  const response = await ahaApi.Account.getActiveZones(data).then((res) => {
    return res;
  });

  return response;
});

export const activeZonesWithDateSlice = createSlice({
  name: "zone",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getActiveZonesWithDate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActiveZonesWithDate.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getActiveZonesWithDate.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const activeZonesListWithDate = (state) => state&&state.activeZonesListWithDate&&state.activeZonesListWithDate.value;

export default activeZonesWithDateSlice.reducer;
