// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect, useState } from 'react';
import { MessageAttachment, useNotificationDispatch } from 'amazon-chime-sdk-component-library-react';
import AttachmentService from '../../services/AttachmentService';
import arnParser from '../../utilities/arnParser';
import { deleteChannelMessage } from '../../api/ChimeAPI';
import axios from 'axios';
import PreviewImage from "../../../../assets/images/pdf-thumbnail.png";
import toast from "react-hot-toast";
import ToastContent from '../../../../components/layouts/ToastContent';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";

/**
 * Attachment Processor which provides MessageAttachment component with downloadUrl
 * @param {string} fileKey S3 bucket file key
 * @param {string} name File name or title
 * @param {number} [size=0] File byte size
 * @param {string} senderId AWS Cognito userId of the provided fileKey
 * @param {string} type File byte size
 * @returns {MessageAttachment} MessageAttachment
 */
export const AttachmentProcessor = ({ fileKey, name, size = 0, senderId, type, channelArn, messageId, userId, setMessages, messages, userLevel, userUploadDir, setChatLoader }) => {
  const [url, setUrl] = useState('');
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const notificationDispatch = useNotificationDispatch();

  const isImage = type && type.split('/')[0] === "image";
  const isAudio = type && type.split('/')[0] === "audio";
  const isVideo = type && type.split('/')[0] === "video";
  const fileExt = name.slice((Math.max(0, name.lastIndexOf(".")) || Infinity) + 1);


  useEffect(() => {
    async function getUrl() {
      try {
        const data = await AttachmentService.download(
          name,
          `${arnParser(senderId)}`,
          userLevel,
          userUploadDir
        );
        console.log(data.url.href, 'fileeeee');
        
        setUrl(data.url.href);
      } catch (e) {
        console.log(e, 'file error');
        
      }
    }
    getUrl();
  }, [fileKey, senderId]);

  const open = async (url) => {
    try {
      setChatLoader(true);
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        headers: {
          "Content-Type": type,
          "Content-type": type,
          "Content-Disposition": "inline",
        },

      });
      const file = new Blob([response.data], { type });
      const fileURL = URL.createObjectURL(file);
      setChatLoader(false);
      window.open(fileURL, "_blank");
    } catch (err) {
      setChatLoader(false)
      console.log(err, 'err');
    }

  }

  const deleteImage = async () => {
    try {
      const data = await deleteChannelMessage(channelArn, messageId, userId);
      await AttachmentService.delete(fileKey);
      let tempMessages = messages ? [...messages] : [];
      tempMessages = tempMessages.filter(m => m.messageId !== messageId);
      setMessages(tempMessages);
    } catch (err) {

      toast(
        (t) => (
          <ToastContent type="error" message={err.message} close={() => toast.remove()} />
        ),
        {
          duration: 4000,
        }
      );
    }
  };
  return (
    <React.Fragment>
      {isImage ? <div>
        {isViewerOpen && <Lightbox
          open={isViewerOpen}
          close={() => setIsViewerOpen(false)}
          slides={[
            { src: url, title: name },
          ]}
          plugins={[Zoom, Captions]}
          render={{
            buttonNext: () => null,
            buttonPrev: () => null
          }}
        />}
        <div className='background-properties ch-img-preview' style={{ backgroundImage: `url(${url})`, width: 500, minHeight: 200 }} onClick={e => setIsViewerOpen(true)} />
        <p className='font-size-13 ch-attachment-name'>{name}</p>
        {/* <p className='font-size-13 ch-attachment-name'>{formatBytes(size, 3)}</p> */}
        {/* <a className='text-capitalize' onClick={deleteImage}>Delete</a> */}
      </div> :
        fileExt === "pdf" ?
          <div className='ch-img-preview'>
            <img className='pdf-thumbnail' alt="pdf thumbnail" src={PreviewImage} onClick={e => open(url)} />
            {/* <iframe src={`http://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`} frameborder="0"></iframe> */}
            {/* <iframe
              src={url + "#toolbar=0"}
              height={200}
              width={350}
            >
              </iframe> */}
            <p className='font-size-13'>{name}</p>
            {/* <p className='font-size-13'>{formatBytes(size, 3)}</p> */}
            {/* <a className='text-capitalize' onClick={deleteImage}>Delete</a> */}
          </div> :
          isAudio ? <div className='ch-img-preview'>
            <a href={url} id="play-fullscreen" target="_blank" >
              <audio controls>
                <source src={url} type={type} />
                Your browser does not support the audio element.</audio>
            </a>
            <p className='font-size-13 '>{name}</p>
            {/* <p className='font-size-13 '>{formatBytes(size, 3)}</p> */}
            {/* <a className='text-capitalize' onClick={deleteImage}>Delete</a> */}
          </div> :
            isVideo ? <div className='ch-img-preview'>
              <a href={url} id="play-fullscreen" target="_blank" >
                <video width="320" height="240" controls>
                  <source src={url} type={type} />
                  Your browser does not support the video tag.
                </video>
              </a>
              <p className='font-size-13 '>{name}</p>
              {/* <p className='font-size-13 '>{formatBytes(size, 3)}</p> */}
              {/* <a className='text-capitalize' onClick={deleteImage}>Delete</a> */}
            </div> : <></>
      }
    </React.Fragment>
  );
};

export default AttachmentProcessor;
