import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";


const initialState = {
  value: {},
  loading: false,
};

export const unclaimOrder = createAsyncThunk(
  "homeHealth/unclaimOrder",
  async (id, { rejectWithValue }) => {
    const response = await ahaApi.Account.unclaimOrder(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const unclaimOrderSlice = createSlice({
  name: "unclaimOrder",
  initialState,
  reducers: {
    clearUnclaimOrderResponse(state, action) {
      state.value = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(unclaimOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(unclaimOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(unclaimOrder.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const unclaimOrderResponse = (state) => state.unclaimOrderState.value;
export const unclaimOrderLoading = (state) => state.unclaimOrderState.loading;
export const { clearUnclaimOrderResponse } = unclaimOrderSlice.actions;
export default unclaimOrderSlice.reducer;
