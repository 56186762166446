import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { phoneFormat } from "../../../../app/utils/testUtils";
import ahaApi from "../../../../app/services/aha-api";

const initialState = {
  value: [],
  loading: false,
};

export const getLabs = createAsyncThunk(
  "labs/getLabs",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.labs(data)
      .then((res) => {
        let result = res ? (res.content || []) : [];
        result = result.map(re=> {return {
          ...re,
          fullAddress: re.street_address1 + " "+ re.street_address2,
          city_string: re.city || "-",
          zip_string: re.zip || "-",
          state_name_string: re.state ? re.state.state_name || "-" : "-",
          zone_name_string: re.zone ? re.zone.zone_name || "-" : "-",
          time_zone_name_string: re.timezone ? re.timezone.timezone_name || "-" : "-",
          latitude_string: re.latitude ? re.latitude : "-",
          longitude_string: re.longitude ? re.longitude : "-",
          lab_type_name_string: re.lab_type ? re.lab_type.lab_type_name || "-" : "-",
          lab_contact_phone_number_string: re.lab_contact_phone_number 
          ? `${phoneFormat(re.lab_contact_phone_number.toString())} ${re.lab_contact_phone_number_ext && re.lab_contact_phone_number_ext !== null ? ` Ext. ${re.lab_contact_phone_number_ext}` : ""}` : "-",
          lab_contact_phone_number_ext_string: re.lab_contact_phone_number_ext || "-",
          primary_contact_name_string: re.primary_contact_name || "-",
          primary_contact_office_phone_number_string: re.primary_contact_office_phone_number 
          ? `${phoneFormat(re.primary_contact_office_phone_number.toString())} ${re.primary_contact_office_phone_number_ext && re.primary_contact_office_phone_number_ext !== null ? ` Ext. ${re.primary_contact_office_phone_number_ext}` : ""}` : "-",
          primary_contact_office_phone_number_ext_string: re.primary_contact_office_phone_number_ext || "-",
          primary_contact_cell_phoneNumber_string: re.primary_contact_cell_phoneNumber ? phoneFormat(re.primary_contact_cell_phoneNumber.toString()) : "-",
          primary_contact_email_string: re.primary_contact_email || "-",
          lab_notes_string: re.lab_notes || "-"
        }});
        res.content = result;
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const labsSlice = createSlice({
  name: "labs",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getLabs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLabs.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getLabs.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const labsList = (state) => state.labs.value;
export const labsLoader = (state) => state.labs.loading;

export default labsSlice.reducer;
