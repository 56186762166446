import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false
};

export const addZones = createAsyncThunk(
  "zones/addZones",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.createZone(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const addZonesSlice = createSlice({
  name: "addZones",
  initialState,
  reducers: {
    clearAddZoneResponse(state, action) {
      state.value = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addZones.pending, (state) => {
        state.loading = true;
      })
      .addCase(addZones.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
        state.days = action.payload.days;
      })
      .addCase(addZones.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const addZonesList = (state) => state.addZones.value;
export const {clearAddZoneResponse} = addZonesSlice.actions;
export default addZonesSlice.reducer;
