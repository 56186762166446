import React from "react";
import { Accordion, Button, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import CustomDateRangePicker from "../../components/commons/DateRangePicker";
import { TIMED_DRAW_COLUMNS } from "../../app/utils/OrderConfig";
import { isStatusDisabled, STAT_ALL_STATUSES, STAT_CANCELLED_STATUSES, STAT_COMPLETED_STATUSES, STAT_COMPLETED_ORDERS_STATUSES, STAT_EXCLUDE_CHECK_IN_STATUSES } from "../../app/utils/Constants";
import OrdersGrid from "../../components/commons/OrdersGrid";
import { arraysEqual, ExportAllDataComponent } from "../statOrder/StatOrderList";
import RefreshPanel from "../statOrder/RefreshPanel";

const getSelectedFilterLabel = (data) => {
  let result = "";
  if (data && data.length > 0) {
    if (data.length === 1) {
      result = data[0].label;
    } else {
      result = `${data[0].label}, +${data.length - 1} more...`;
    }
  }
  return result;
}
class TimedDrawList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dateKey: new Date().getTime(),
      enableMainFilter: false
    };
  }

  componentDidMount() {
    this.setState({ dateKey: new Date().getTime() })
  }

  toggleMainFilter = () => {
    this.setState({ enableMainFilter: !this.state.enableMainFilter })
  }

  toggleSelectAll = (e, isSelectedAll, allOptions = [], callback) => {
    e.preventDefault();
    let temp = [];
    if (isSelectedAll) {
      temp = [];
    } else {
      temp = allOptions;
    }
    callback(temp);
  }

  applyChanges = (e, value, callback) => {
    e.preventDefault();
    callback(value);
  }

  handleChangeStatus = (e, order_status, status) => {
    e.preventDefault();
    const { setStatTempStatus, setHighlightStatusApply } = this.props;
    let temp = [...order_status];
    if (temp.some(t => t.value == status.value)) {
      temp = temp.filter(t => t.value != status.value);
    } else {
      temp.push(status);
    }
    // if (temp.length) {
    //   setResetFilters(true);
    // }
    setStatTempStatus(temp);
    setHighlightStatusApply(true);
  }

  handleChangeColor = (e, order_status_code, data) => {
    e.preventDefault();
    const { setStatTempColor, setHighlightColorApply } = this.props;
    let temp = [...order_status_code];
    if (temp.some(t => t.value == data.value)) {
      temp = temp.filter(t => t.value != data.value);
    } else {
      temp.push(data);
    }
    setStatTempColor(temp);
    setHighlightColorApply(true);
  }

  handleChangeRegion = (e, region, data) => {
    e.preventDefault();
    let { setStatTempRegion, setStatTempTerritory, setStatTempZone, setHighlightRegionApply } = this.props;

    let val = [...region];
    if (val.some(t => t.value == data.value)) {
      val = val.filter(t => t.value != data.value);
    } else {
      val.push(data);
    }
    // updateFilterInLocal({ orderRegion: val, orderTerr: tempTerr, orderZone: tempZone });
    // setOrderRegion(val);
    // setOrderTerr(tempTerr);
    // setOrderZone(tempZone);

    setStatTempRegion(val);
    setStatTempTerritory([]);
    setStatTempZone([]);
    setHighlightRegionApply(true);
  }

  handleChangeTerritory = (e, territory, data) => {
    e.preventDefault();
    let { setHighlightTerritoryApply, setStatTempTerritory, setStatTempZone } = this.props;

    let val = [...territory];
    if (val.some(t => t.value == data.value)) {
      val = val.filter(t => t.value != data.value);
    } else {
      val.push(data);
    }

    // updateFilterInLocal({ orderTerr: val, orderZone: tempZone });
    // setOrderTerr(val);
    // setOrderZone(tempZone);

    setStatTempTerritory(val);
    setStatTempZone([]);
    setHighlightTerritoryApply(true);
  }

  handleChangeZone = (e, zones, data) => {
    e.preventDefault();
    const { updateFilterInLocal, setOrderZone, setHighlightZoneApply, setStatTempZone } = this.props;
    let temp = [...zones];
    if (temp.some(t => t.value == data.value)) {
      temp = temp.filter(t => t.value != data.value);
    } else {
      temp.push(data);
    }
    setStatTempZone(temp);
    setHighlightZoneApply(true);
  }

  handleServerSideExport = () => {
    const { orderType, performExport } = this.props;
    performExport(orderType);
  }

  render() {
    const {
      data = [],
      editModal,
      type,
      order_status = [],
      order_status_code = [],
      region = [],
      territory = [],
      zone = [],
      orderColor,
      orderStatus,
      orderRegion,
      orderTerr,
      orderZone,
      setOrderZone,
      setOrderTerr,
      setOrderRegion,
      setOrderColor,
      setOrderStatus,
      orderStartDate,
      orderEndDate,
      setOrderStartDate,
      setOrderEndDate,
      setIsClicked,
      isClicked,
      updateFilterInLocal,
      openTimedDraws,
      dateRef,
      isModalOpen,
      orderDateType,
      setOrderDateType,
      setHelpModal,
      allOrders,
      cancelledOrders,
      droppedOffOrders,
      highlightStatusApply,
      highlightColorApply,
      highlightRegionApply,
      highlightTerritoryApply,
      highlightZoneApply,
      setHighlightStatusApply,
      setHighlightColorApply,
      setHighlightRegionApply,
      setHighlightTerritoryApply,
      setHighlightZoneApply,

      setStatTempStatus,
      statTempStatus,
      statTempColor,
      setStatTempColor,
      statTempRegion,
      setStatTempRegion,
      statTempTerritory,
      setStatTempTerritory,
      statTempZone,
      setStatTempZone,
      getInitialListingData,
      orderType,
      isExportDisabled,
      totalOrderCount
    } = this.props;

    const { dateKey, enableMainFilter } = this.state;

    const territories = orderRegion ? territory.filter(terr => orderRegion.some(reg => terr.region_id == reg.value)) : [];
    const zones = orderTerr ? zone.filter(z => orderTerr.some(terr => terr.value.split(":")[0] == z.territory_id)) : [];

    const orderStatusLabel = getSelectedFilterLabel(orderStatus);
    const orderColorLabel = getSelectedFilterLabel(orderColor);
    const orderRegionLabel = getSelectedFilterLabel(orderRegion);
    const orderTerritoryLabel = getSelectedFilterLabel(orderTerr);
    const orderZoneLabel = getSelectedFilterLabel(orderZone);

    let today = new Date();
    today.setDate(today.getDate() - 1);

    // first get all statuses list
    let allStatuses = [];
    if (order_status) {
      order_status.forEach(status => {
        let disableStatus = isStatusDisabled(status.label, openTimedDraws ? STAT_COMPLETED_ORDERS_STATUSES : orderDateType ? STAT_EXCLUDE_CHECK_IN_STATUSES : []);
        if (allOrders) {
          if (orderDateType) {
            disableStatus = isStatusDisabled(status.label, STAT_EXCLUDE_CHECK_IN_STATUSES);
          } else {
            disableStatus = isStatusDisabled(status.label, STAT_ALL_STATUSES, "negative");
          }
        } else if (cancelledOrders && droppedOffOrders) {
          disableStatus = isStatusDisabled(status.label, [...STAT_CANCELLED_STATUSES, ...STAT_COMPLETED_STATUSES], "negative");
        } else if (droppedOffOrders || cancelledOrders) {
          disableStatus = isStatusDisabled(status.label, cancelledOrders ? STAT_CANCELLED_STATUSES : droppedOffOrders ? STAT_COMPLETED_STATUSES : [], "negative");
        }

        // if disableStatus is false, then push to the new list
        if (!disableStatus) {
          allStatuses.push(status);
        }
      });
    }
    // check if all statuses are selected already
    let selectedStatuses = statTempStatus ? [...statTempStatus] : [];
    const isSelectedAllStatuses = arraysEqual(allStatuses, selectedStatuses);

    // check if all colors are selected already
    let selectedColors = statTempColor ? [...statTempColor] : [];
    const isSelectedAllColors = selectedColors && selectedColors.length && arraysEqual(order_status_code, selectedColors);

    // check if all regions are selected already
    let selectedRegions = statTempRegion ? [...statTempRegion] : [];
    const isSelectedAllRegions = selectedRegions && selectedRegions.length && arraysEqual(region, selectedRegions);

    // check if all territories are selected already
    let selectedTerr = statTempTerritory ? [...statTempTerritory] : [];
    const isSelectedAllTerr = selectedTerr && selectedTerr.length && arraysEqual(territories, selectedTerr);

    // check if all zones are selected already
    let selectedZones = statTempZone ? [...statTempZone] : [];
    const isSelectedAllZones = selectedZones && selectedZones.length && arraysEqual(zones, selectedZones);


    return (
      <React.Fragment>
        {enableMainFilter && <div className="st-left-filter-wrapper">
          <div className="d-flex justify-content-between st-left-top-wrapper">
            <span className="">
              <span className="filter-icon">
                <span className="visually-hidden">Filter icon</span>
              </span>
              <span className="st-filterby-text font-size-13">Filter By:</span>
            </span>
            <div className="filter-reset-btn">

              <Button
                className="primary-btn primary-btn-outline outline st-reset-all-btn"
                onClick={() => {
                  setIsClicked(true);
                }}
              >
                <span className="reset-all pe-1">
                  <span className="visually-hidden">reset-icon</span>
                </span>  Reset All
              </Button></div>
            {/* <span className="reset-icon">
                <span className="visually-hidden">Reset icon</span>
              </span>
            <span className="st-filterby-text font-size-13">Reset All</span>
            </span> */}
          </div>
          <div className="stat-filter-wrapper">
            <ExportAllDataComponent isExportDisabled={isExportDisabled || !data ||!data.length} handleServerSideExport={this.handleServerSideExport} />
            {!openTimedDraws && <div
              className={`ad-toggle-switch d-flex align-items-center up-ad-toggle-switch pe-0`}
            >
              <span className={`ad-switch font-size-12 roboto-regular me-2  ${!orderDateType ? `roboto-bold` : ""} `}>Collection Date</span>
              <label
                className={`switch position-relative `}
              >
                <span className="visually-hidden">
                  Collection Date
                </span>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    let currData = {
                      orderDateType: e.target.checked
                    }
                    if (e.target.checked) {
                      if (moment(new Date(orderStartDate)).isAfter(moment(new Date)) || moment(new Date(orderEndDate)).isAfter(moment(new Date))) {
                        console.log('after');
                        currData = {
                          ...currData,
                          orderStartDate: today,
                          orderEndDate: new Date(),
                        }
                        setOrderStartDate(today);
                        setOrderEndDate(new Date());
                        if (dateRef && dateRef.current) {
                          dateRef.current.setStartDate(today);
                          dateRef.current.setEndDate(new Date());
                        }
                      }
                      let tempStatus = orderStatus || [];
                      tempStatus = tempStatus.filter(st => st.label !== "Unassigned" && st.label !== "Unacknowledged" && st.label !== "Acknowledged");
                      currData.orderStatus = tempStatus;
                      setStatTempStatus(tempStatus);
                      setOrderStatus(tempStatus);
                    } else {
                      if (allOrders) {
                        let tempStatus = orderStatus || [];
                        tempStatus = [...tempStatus, ...STAT_EXCLUDE_CHECK_IN_STATUSES];
                        currData.orderStatus = tempStatus;
                        setStatTempStatus(tempStatus);
                        setOrderStatus(tempStatus);
                      }
                    }
                    updateFilterInLocal(currData);
                    setOrderDateType(e.target.checked);
                    this.setState({ dateKey: new Date().getTime() })
                  }}
                  value={orderDateType}
                  checked={orderDateType}
                />
                <span className="slider round"></span>
              </label>
              <span className={`ad-switch font-size-12 roboto-regular ms-2  ${orderDateType ? `roboto-bold` : ""}`}>Check-in Date</span>
            </div>}
            {!openTimedDraws && <InputGroup className="stat-input-group">
              <CustomDateRangePicker
                startDate={orderStartDate}
                endDate={orderEndDate}
                onApply={(e) => {
                  let value = e.target.value || "";
                  let start = value.split("-")[0];
                  let end = value.split("-")[1];
                  updateFilterInLocal({
                    orderStartDate: new Date(start),
                    orderEndDate: new Date(end),
                    dateChangedLastTime: new Date()
                  });
                  setOrderStartDate(new Date(start));
                  setOrderEndDate(new Date(end));
                }}
                dateRef={dateRef}
                // disabled={openTimedDraws}
                id="daterange"
                value={
                  orderStartDate && orderEndDate
                    ? `${moment(orderStartDate).format(
                      "MM/DD/yyyy"
                    )} - ${moment(orderEndDate).format("MM/DD/yyyy")}`
                    : ""
                }
                allowMaxDate={orderDateType}
                key={dateKey}
              />
            </InputGroup>}
            <div className="st-filter-accordions">
              <Accordion className="st-accordian-wrapper">
                <Accordion.Item eventKey="order_status">
                  <Accordion.Header className="routine-draws">
                    <span>Status</span>

                  </Accordion.Header>
                  <Accordion.Body className="mt-2" style={{ position: "static" }}>
                    <div className="filter-reset-btn d-flex justify-content-between mb-3">
                      <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.toggleSelectAll(e, isSelectedAllStatuses, allStatuses, (newValue) => {
                        setStatTempStatus(newValue)
                        setHighlightStatusApply(true);
                      })}>{isSelectedAllStatuses ? "Unselect All" : "Select All"}</button>
                      {highlightStatusApply && <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.applyChanges(e, statTempStatus, (newValue) => {
                        updateFilterInLocal({ orderStatus: newValue });
                        setOrderStatus(newValue);
                        setHighlightStatusApply(false);
                      })}>Apply</button>}
                    </div>
                    <div>
                      {order_status.map((status, index) => {
                        const { label, value } = status;
                        let disableStatus = isStatusDisabled(status.label, openTimedDraws ? STAT_COMPLETED_ORDERS_STATUSES : orderDateType ? STAT_EXCLUDE_CHECK_IN_STATUSES : []);
                        if (allOrders) {
                          if (orderDateType) {
                            disableStatus = isStatusDisabled(status.label, STAT_EXCLUDE_CHECK_IN_STATUSES);
                          } else {
                            disableStatus = isStatusDisabled(status.label, STAT_ALL_STATUSES, "negative");
                          }
                        } else if (cancelledOrders && droppedOffOrders) {
                          disableStatus = isStatusDisabled(status.label, [...STAT_CANCELLED_STATUSES, ...STAT_COMPLETED_STATUSES], "negative");
                        } else if (droppedOffOrders || cancelledOrders) {
                          disableStatus = isStatusDisabled(status.label, cancelledOrders ? STAT_CANCELLED_STATUSES : droppedOffOrders ? STAT_COMPLETED_STATUSES : [], "negative");
                        }
                        return (
                          <div key={status.value} className="ml-weekday st-filter-checkbox-wrapper" style={disableStatus ? { opacity: 0.5, pointerEvents: "none" } : {}}>
                            <label className="ml-label-container st-label-container"
                              htmlFor={`st-status-${status.value}`}
                              onClick={e => this.handleChangeStatus(e, statTempStatus, status)}
                            >
                              <input
                                style={{ display: "block !important" }}
                                type="checkbox"
                                id={`st-status-${status.value}`}
                                data-testid="field-timings"
                                checked={statTempStatus ? statTempStatus.some(st => st.value == value) : false}
                              />
                              <span className="ml-checkmark"></span>
                              <span className="st-checkbox-label">{label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="st-accordian-wrapper">
                <Accordion.Item eventKey="order_status_code">
                  <Accordion.Header className="routine-draws">
                    <span>Color</span>
                  </Accordion.Header>
                  <Accordion.Body style={{ position: "static" }}>
                    <div className="filter-reset-btn d-flex justify-content-between mb-3">
                      <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.toggleSelectAll(e, isSelectedAllColors, order_status_code, (newValue) => {
                        setStatTempColor(newValue)
                        setHighlightColorApply(true);
                      })}>{isSelectedAllColors ? "Unselect All" : "Select All"}</button>
                      {highlightColorApply && <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.applyChanges(e, statTempColor, (newValue) => {
                        updateFilterInLocal({ orderColor: newValue });
                        setOrderColor(newValue);
                        setHighlightColorApply(false);
                      })}>Apply</button>}
                    </div>
                    <div>
                      {order_status_code.map((data, index) => {
                        const { label, value } = data;
                        return (
                          <div key={value} className="ml-weekday st-filter-checkbox-wrapper" >
                            <label className="ml-label-container st-label-container"
                              htmlFor={`st-color-${value}`}
                              onClick={e => this.handleChangeColor(e, statTempColor, data)}
                            >
                              <input
                                style={{ display: "block !important" }}
                                type="checkbox"
                                id={`st-color-${value}`}
                                data-testid="field-timings"
                                checked={statTempColor ? statTempColor.some(st => st.value == value) : false}
                              />
                              <span className="ml-checkmark"></span>
                              <span className="st-checkbox-label">{label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="st-accordian-wrapper">
                <Accordion.Item eventKey="region">
                  <Accordion.Header className="routine-draws">
                    <span>Region</span>
                  </Accordion.Header>
                  <Accordion.Body style={{ position: "static" }}>
                    <div className="filter-reset-btn d-flex justify-content-between mb-3">
                      <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.toggleSelectAll(e, isSelectedAllRegions, region, (newValue) => {
                        setStatTempRegion(newValue);
                        setStatTempTerritory([]);
                        setStatTempZone([]);
                        setHighlightRegionApply(true);
                      })}>{isSelectedAllRegions ? "Unselect All" : "Select All"}</button>
                      {highlightRegionApply && <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.applyChanges(e, statTempRegion, (newValue) => {
                        updateFilterInLocal({ orderRegion: newValue, orderTerr: [], orderZone: [] });
                        setOrderRegion(newValue);
                        setOrderTerr([]);
                        setOrderZone([]);
                        setHighlightRegionApply(false);
                      })}>Apply</button>}
                    </div>
                    <div>
                      {region.map((data, index) => {
                        const { label, value } = data;
                        return (
                          <div key={value} className="ml-weekday st-filter-checkbox-wrapper" >
                            <label className="ml-label-container st-label-container"
                              htmlFor={`st-reg-${value}`}
                              onClick={e => this.handleChangeRegion(e, statTempRegion, data)}
                            >
                              <input
                                style={{ display: "block !important" }}
                                type="checkbox"
                                id={`st-reg-${value}`}
                                data-testid="field-timings"
                                checked={statTempRegion ? statTempRegion.some(st => st.value == value) : false}
                              />
                              <span className="ml-checkmark"></span>
                              <span className="st-checkbox-label">{label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className={`st-accordian-wrapper ${!orderRegion || !orderRegion.length ? "disabled" : ""}`}>
                <Accordion.Item eventKey="territory">
                  <Accordion.Header className="routine-draws">
                    <span>Territory</span>

                  </Accordion.Header>
                  <Accordion.Body style={{ position: "static" }}>
                    {orderRegion && orderRegion.length > 0 && <div className="filter-reset-btn d-flex justify-content-between mb-3">
                      <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.toggleSelectAll(e, isSelectedAllTerr, territories, (newValue) => {
                        setStatTempTerritory(newValue);
                        setStatTempZone([]);
                        setHighlightTerritoryApply(true);
                      })}>{isSelectedAllTerr ? "Unselect All" : "Select All"}</button>
                      {highlightTerritoryApply && <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.applyChanges(e, statTempTerritory, (newValue) => {
                        updateFilterInLocal({ orderTerr: newValue, orderZone: [] });
                        setOrderTerr(newValue);
                        setOrderZone([]);
                        setHighlightTerritoryApply(false);
                      })}>Apply</button>}
                    </div>}
                    <div>
                      {territories.map((data, index) => {
                        const { label, value } = data;
                        return (
                          <div key={value} className="ml-weekday st-filter-checkbox-wrapper" >
                            <label className="ml-label-container st-label-container"
                              htmlFor={`st-territory-${value}`}
                              onClick={e => this.handleChangeTerritory(e, statTempTerritory, data)}
                            >
                              <input
                                style={{ display: "block !important" }}
                                type="checkbox"
                                id={`st-territory-${value}`}
                                checked={statTempTerritory ? statTempTerritory.some(st => st.value == value) : false}
                              />
                              <span className="ml-checkmark"></span>
                              <span className="st-checkbox-label">{label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className={`st-accordian-wrapper ${!orderTerr || !orderTerr.length ? "disabled" : ""}`}>
                <Accordion.Item eventKey="zone">
                  <Accordion.Header className="routine-draws">
                    <span>Zone</span>

                  </Accordion.Header>
                  <Accordion.Body style={{ position: "static" }}>
                    {orderTerr && orderTerr.length > 0 && <div className="filter-reset-btn d-flex justify-content-between mb-3">
                      <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.toggleSelectAll(e, isSelectedAllZones, zones, (newValue) => {
                        setStatTempZone(newValue)
                        setHighlightZoneApply(true);
                      })}>{isSelectedAllZones ? "Unselect All" : "Select All"}</button>
                      {highlightZoneApply && <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.applyChanges(e, statTempZone, (newValue) => {
                        updateFilterInLocal({ orderZone: newValue });
                        setOrderZone(newValue);
                        setHighlightZoneApply(false);
                      })}>Apply</button>}
                    </div>}
                    <div>
                      {zones.map((data, index) => {
                        const { label, value } = data;
                        return (
                          <div key={value} className="ml-weekday st-filter-checkbox-wrapper" >
                            <label className="ml-label-container st-label-container"
                              htmlFor={`st-zone-${value}`}
                              onClick={e => this.handleChangeZone(e, statTempZone, data)}
                            >
                              <input
                                style={{ display: "block !important" }}
                                type="checkbox"
                                id={`st-zone-${value}`}
                                checked={statTempZone ? statTempZone.some(st => st.value == value) : false}
                              />
                              <span className="ml-checkmark"></span>
                              <span className="st-checkbox-label">{label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>}
        <div className={`stat-order-filter-wrapper position-relative main-stat-filter-wrapper ${enableMainFilter ? 'filter-expanded' : 'filter-collapsed'} ${!openTimedDraws && orderStartDate && orderEndDate && orderStatusLabel || orderColorLabel && orderRegionLabel && orderTerritoryLabel ? 'has-more-filter' : ''}`}>
          <div onClick={e => this.toggleMainFilter()} className="st-filter-expand">
            <div className=" d-flex align-items-center flex-column justify-content-center h-100 position-relative">
              <span className="filter-expand st-filter-expand-icon"><span className="visually-hidden">Filter-expand</span></span>
              <span className="st-filters-text">Filters</span>
            </div>
          </div>
          <div className="st-order-right-wrapper">
            <div className="st-filter-selected-wrapper d-flex align-items-center">
              <div className="d-flex flex-fill overflow-auto ">
                <div className="filter-reset-btn me-2">
                  <Button
                    className="primary-btn primary-btn-outline outline st-reset-all-btn"
                    onClick={() => setIsClicked(true)}
                  > <span className="reset-all pe-1">
                      <span className="visually-hidden">reset-icon</span>
                    </span> Reset All
                  </Button></div>
                <ul className={`st-filter-selected-info-wrapper d-flex align-items-center ${!openTimedDraws && orderStartDate && orderEndDate && orderStatusLabel || orderColorLabel && orderRegionLabel && orderTerritoryLabel ? 'has-more-filter' : ''}`}>
                  {!openTimedDraws && orderStartDate && orderEndDate && <li className="st-filter-selected-info"><span className="st-filter-title">{orderDateType ? "Check-in Date" : "Collection Date"}:</span><span className="st-selected-value">{orderStartDate && orderEndDate
                    ? `${moment(orderStartDate).format(
                      "MM/DD/yyyy"
                    )} - ${moment(orderEndDate).format("MM/DD/yyyy")}`
                    : ""}</span></li>}
                  {orderStatusLabel && <li className={`st-filter-selected-info ${orderStatusLabel ? "" : "value-empty"}`}><span className="st-filter-title">Status:</span> <span className={`st-selected-value `}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip
                          id="tooltip-disabled-status"
                          className="st-value-tooltip st-tooltip-btm"
                        >
                          <p>
                            {orderStatus ? orderStatus.map(val => val.label).join(", ") : ""}
                          </p>
                        </Tooltip>
                      }
                    ><span>{orderStatusLabel}</span></OverlayTrigger></span></li>}
                  {!openTimedDraws && orderColorLabel && <li className={`st-filter-selected-info ${orderColorLabel ? "" : "value-empty"}`}><span className="st-filter-title">Color:</span> <span className={`st-selected-value `}><OverlayTrigger
                    overlay={
                      <Tooltip
                        id="tooltip-disabled-status"
                        className="st-value-tooltip st-tooltip-btm"
                      >
                        <p>
                          {orderColor ? orderColor.map(val => val.label).join(", ") : ""}
                        </p>
                      </Tooltip>
                    }
                  ><span>{orderColorLabel}</span></OverlayTrigger></span></li>}
                  {!openTimedDraws && orderRegionLabel && <li className={`st-filter-selected-info ${orderRegionLabel ? "" : "value-empty"}`}><span className="st-filter-title">Region:</span> <span className={`st-selected-value `}><OverlayTrigger
                    overlay={
                      <Tooltip
                        id="tooltip-disabled-status"
                        className="st-value-tooltip st-tooltip-btm"
                      >
                        <div>
                          {orderRegion ? orderRegion.map(val => val.label).join(", ") : ""}
                        </div>
                      </Tooltip>
                    }
                  ><span>{orderRegionLabel}</span></OverlayTrigger></span></li>}
                  {!openTimedDraws && orderTerritoryLabel && <li className={`st-filter-selected-info ${orderTerritoryLabel ? "" : "value-empty"}`}><span className="st-filter-title">Territory:</span> <span className={`st-selected-value `}><OverlayTrigger
                    overlay={
                      <Tooltip
                        id="tooltip-disabled-status"
                        className="st-value-tooltip st-tooltip-btm"
                      >
                        <p>
                          {orderTerr ? orderTerr.map(val => val.label).join(", ") : ""}
                        </p>
                      </Tooltip>
                    }
                  ><span>{orderTerritoryLabel}</span></OverlayTrigger></span></li>}
                  {!openTimedDraws && orderZoneLabel && <li className={`st-filter-selected-info ${orderZoneLabel ? "" : "value-empty"}`}><span className="st-filter-title">Zone:</span> <span className={`st-selected-value `}><OverlayTrigger
                    overlay={
                      <Tooltip
                        id="tooltip-disabled-status"
                        className="st-value-tooltip st-tooltip-btm"
                      >
                        <p>
                          {orderZone ? orderZone.map(val => val.label).join(", ") : ""}
                        </p>
                      </Tooltip>
                    }
                  ><span>{orderZoneLabel}</span></OverlayTrigger></span></li>}
                </ul>
              </div>
              <RefreshPanel callback={getInitialListingData} />
            </div>
            <div
              className="st-help-container"
              onClick={(e) => setHelpModal(true)}
            >
              <span className="stat-help-icon">
                <span className="visually-hidden">Help icon</span>
              </span>
            </div>
            <OrdersGrid
              type={type}
              data={data}
              editModal={editModal}
              isModalOpen={isModalOpen}
              columns={TIMED_DRAW_COLUMNS}
              isClicked={isClicked}
              callback={(e) => {
                if (dateRef && dateRef.current) {
                  dateRef.current.setStartDate(today);
                  dateRef.current.setEndDate(new Date());
                }
              }}
              orderType={orderType}
              totalOrderCount={totalOrderCount}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default TimedDrawList;
