import React from "react";
// import { useAutoRefresh } from './useAutoRefresh';
import { Button } from "react-bootstrap";
import { useAutoRefreshContext } from "./AutoRefreshContext";

const RefreshPanel = ({ callback }) => {
  // auto/manual refresh
  const {
    isAutoRefreshOn,
    timer,
    manualRefresh,
    setIsAutoRefreshOn,
    lastRefresh,
  } = useAutoRefreshContext(callback);

  const handleManualRefresh = () => {
    if (callback) {
      callback();
    }
    manualRefresh();
  };

  return (
    <>
      <div className='ah-toggle-left auto-refresh-wrapper d-flex align-items-center'>
        <div className={`ad-toggle-switch d-flex align-items-center ${!isAutoRefreshOn ? "auto-refresh-disabled" : ""}`}>
          <span className='ad-switch font-size-12 roboto-regular'>
            Auto <br/> Refresh
          </span>
          <label className={`switch position-relative`}>
            <span className='visually-hidden'>Auto Refresh</span>
            <input
              type='checkbox'
              onChange={(e) => {
                setIsAutoRefreshOn(!isAutoRefreshOn);
              }}
              value={isAutoRefreshOn}
              checked={isAutoRefreshOn}
            />
            <span className='slider round'></span>
          </label>
        </div>
        <div className='last-refresh'>
          <span className="font-size-12 roboto-regular">Refreshed at: </span>
          <span className="font-size-12 roboto-regular">
            {lastRefresh
              ? lastRefresh.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })
              : "Not refreshed yet"}
          </span>
        </div>
        <div className={`countdown ${!isAutoRefreshOn ? "disabled" : ""}`}>
          <span className="font-size-12 roboto-regular">Next Refresh:</span>
          <span className="font-size-12 roboto-regular">{timer}</span>
        </div>
        <div className='auto-refresh-btn-wrapper'>
          <Button
            className='primary-btn roboto-medium font-size-14 st-refresh-button'
            onClick={handleManualRefresh}
          >
            <span className='add-stat-order-text'>REFRESH</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default RefreshPanel;
