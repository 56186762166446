import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../../app/services/aha-api";
const initialState = {
  value: [],
  status: false,
};

export const postSetPassword = createAsyncThunk(
  "account/postSetPassword",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.setPassword(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const setPasswordSlice = createSlice({
  name: "setPass",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(postSetPassword.pending, (state) => {
        state.status = true;
      })
      .addCase(postSetPassword.fulfilled, (state, action) => {
        state.status = false;
        state.value = action.payload;
      })
      .addCase(postSetPassword.rejected, (state, error) => {
        state.status = false;
        state.value = error.payload;
      });
  },
});

export const setPasswordList = (state) => state.setPass.value;

export default setPasswordSlice.reducer;
