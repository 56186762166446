import React from "react";
import CreatePassword from "../../features/accounts/setPassword/CreatePassword";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  HOME,
  SET_PASSWORD,
  TROUBLE_LOGIN,
  FORGOT_USER,
  FORGOT_PASSWORD,
  GOOGLE_AUTH,
  VERIFY_DEVICE,
  ACCESS_STATUS,
  RESET_PASSWORD,
  LINK_EXPIRED,
  INVALID_INVITE,
  CONTACT,
  LOST_DEVICE,
  DEVICE_LINK_EXPIRED,
  MAINTENANCE,
} from "../../app/utils/Constants";
import Login from "../../features/accounts/login/Login";
import TroubleLogin from "../../features/accounts/troubleLogin/TroubleLogin";
import Layout from "../layouts/LoginLayout";
import ForgotPassword from "../../features/accounts/troubleLogin/ForgotPassword";
import ForgotUser from "../../features/accounts/troubleLogin/ForgotUser";
import GoogleAuth from "../../features/accounts/setPassword/GoogleAuthentication";
import VerifyCode from "../../features/accounts/login/VerifyCode";
import AccessStatus from "../../features/accounts/login/AccessStatus";
import ResetPassword from "../../features/accounts/troubleLogin/ResetPassword";
import LinkExpired from "../../features/accounts/troubleLogin/LinkExpired";
import InvalidInvite from "../../features/accounts/login/Invalid";
import Contact from "../../features/accounts/troubleLogin/Contact";
import LostDevice from "../../features/accounts/login/LostDevice";
import DeviceLinkExpired from "../../features/accounts/login/DeviceLinkExpired";

export default function PublicRoute() {
  return (
    <Layout>
      <Routes>
        <Route path={DEVICE_LINK_EXPIRED} element={<DeviceLinkExpired />} />
        <Route path={LOST_DEVICE} element={<LostDevice />} />
        <Route path={TROUBLE_LOGIN} element={<TroubleLogin />} />
        <Route path={SET_PASSWORD} element={<CreatePassword />} />
        <Route path={HOME} element={<Login />} />
        <Route path={FORGOT_USER} element={<ForgotUser />} />
        <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={GOOGLE_AUTH} element={<GoogleAuth />} />
        <Route path={VERIFY_DEVICE} element={<VerifyCode />} />
        <Route path={ACCESS_STATUS} element={<AccessStatus />} />
        <Route path={RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={LINK_EXPIRED} element={<LinkExpired />} />
        <Route path={INVALID_INVITE} element={<InvalidInvite />} />
        <Route path={CONTACT} element={<Contact />} />
        <Route path="*" element={<Navigate to={HOME} replace />} />
      </Routes>
    </Layout>
  );
}
