import React from "react";
import { Button, Modal } from "react-bootstrap";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import _ from "lodash";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import AsyncSelect from "react-select/async";
import ahaApi, { refreshToken } from "../../app/services/aha-api";
import moment from "moment-timezone";
import StatusModal from "../../components/commons/StatusModal";
import toast from "react-hot-toast";
import ErrorImage from "../../assets/images/no-preview.jpeg";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import AWS from 'aws-sdk';
import { phoneFormat } from "../../app/utils/testUtils";
import ToastContent from "../../components/layouts/ToastContent";
import { ACKNOWLEDGED_STATUS, CANCELLED_STATUS, CHECK_IN_STATUS, CHECK_OUT_STATUS, DROPPED_OFF, DROPPED_OFF_STATUS, NOTIFIED_CLIENT_STATUS, OTHERS_TEXT, REDISPATCH_STATUS, ROUTINEREDRAW, TIMEZONE_MISMATCH, TYPE_ROUTINE, UNACKNOWLEDGED_STATUS, UNASSIGNED_STATUS, USER_TYPES_FOR_PHLEB, USER_TYPES_FOR_PHLEB_OBJ, areEqualDatesTwo, calculateMaxTime, calculateMaxTimeCheckOut, compareTestListItems, compareTimes, convertToMMDDYYYY, customToFixed, dropOffTypes, filterPassedTime, filterPassedTimeForCheckinCheckoutDropOff, findLastIndex, formatGroupLabel, getLabelByValue, getLabelValueObjectByValue, getLocalDateTime } from "../../app/utils/Constants";
import { updateAwsCredentialsFromCognito } from "../chat/api/ChimeAPI";
import TestCodeWarningModal from "../statOrder/TestCodeWarningModal";
import { CustomDateTimeInput, DropTestCodesTitle } from "../statOrder/StatOrderAddEdit";
import PlacesApiLoader from "../../components/commons/PlacesApiLoader";

class RoutineRedrawAddEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRefs = [];
  }
  state = {
    errors: {},
    data: {},
    initialData: {},
    mode: this.props.mode ? this.props.mode : "view",
    showIndicator: false,
    enableRegion: false,
    enableTerr: false,
    enaleZone: false,
    loading: false,
    fileModal: { show: false },
    statusModalType: { value: false },
    disableCancelledNote: true,
    menuIsOpen: false,
    menuIsOpenParentName: false,
    CURRENT_ORDER_TYPE: this.props.order_type ? this.props.order_type[0] || 4 : 4,
    isFormChanged: false,
    testCodeWarning: {
      show: false,
      removedTestCodes: [],
      isConfirmed: false
    },
    userType: USER_TYPES_FOR_PHLEB_OBJ
  };

  setData = (data, fieldName) => {
    const errors = this.state.errors;
    errors[fieldName] = false;
    this.setState({ data, errors });
  };

  componentDidMount = () => {
    const { routineRedrawData = {}, mode } = this.props;
    this.setState({ errors: {} });
    this.updateRoutineRedrawData(routineRedrawData, mode);
  };
  componentDidUpdate = async (prevProps, prevState) => {
    const {
      routineRedrawData = {},
      mode,
      addRoutineRedraw = {},
      note_type,
      bulkCheckinCheckoutData = {},
      updateRoutineRedrawResponse = {},
      updateStatOrderResponse = {},
      deleteStatActionResult = {},
      cancelStatOrder = {}
    } = this.props;
    if (prevProps.routineRedrawData !== routineRedrawData) {
      this.updateRoutineRedrawData(routineRedrawData, mode);
    }

    const { inputParentAccountNumber: prevNumber, inputParentAccountName: prevName, region: prevRegion = "" } = prevState.data || {};
    const { inputParentAccountNumber: newNumber, inputParentAccountName: newName, region: newRegion = "", } = this.state.data || {};

    const { showIndicator: prevIndicator } = prevState;
    const { showIndicator: newIndicator, data = {} } = this.state;
    const { hl7 = false } = data;

    if (!hl7 && (newNumber !== prevNumber || newIndicator !== prevIndicator) && newIndicator) {
      this.setState({
        data: {
          ...this.state.data,
          childAccountNumber: newNumber,
          childAccountName: newName,
        },
      });
    }
    if (!hl7 && (newName !== prevName || newIndicator !== prevIndicator) && newIndicator) {
      this.setState({
        data: {
          ...this.state.data,
          childAccountName: newName,
          childAccountNumber: newNumber,
        },
      });
    }

    if (prevProps.mode !== mode) {
      this.setState({ mode });
    }
    if ((mode === "edit" || mode === "create") && prevState.data !== this.state.data && prevRegion !== newRegion) {
      const { testList = [] } = this.state;
      // get test codes
      let { codes = [], descriptions = [] } = await this.handleGetTestCodes(newRegion ? newRegion.value || "" : "");
      // let tempList = [...testList];
      // tempList.forEach((item, idx) => {
      //   if (item.testCode && item.testCode.label && !codes.some(code => code.label == item.testCode.label)) {
      //     tempList = tempList.map((item2, idx2) => { return item2.testCode.label == item.testCode.label ? {...item2, deleted: true} : {...item2}});
      //   }
      // });
      // if (!tempList.length || tempList.every(item => item.deleted)) {
      //   tempList.unshift({
      //     testCode: "",
      //     name: "",
      //     collectionDate: new Date(),
      //     orderCollectionMethod: "",
      //   })
      // }
      // const finalList = tempList;
      this.setState(
        {
          testCodes: codes,
          testDescriptions: descriptions,
          // testList: finalList,
          // originalTestList: finalList
        }
      );

    }
    if (addRoutineRedraw !== prevProps.addRoutineRedraw && Object.keys(addRoutineRedraw).length !== 0) {
      if (addRoutineRedraw.status === "SUCCESS") {
        this.setData({});
        this.setState({
          errors: {},
          disableCancelledNote: true,
          showIndicator: false,
        });
      }
    }
    if (updateRoutineRedrawResponse !== prevProps.updateRoutineRedrawResponse && Object.keys(updateRoutineRedrawResponse).length !== 0) {
      if (updateRoutineRedrawResponse.status === "SUCCESS") {
        this.setData({});
        this.setState({
          errors: {},
          disableCancelledNote: true,
          showIndicator: false,
        });
      }
    }
    if (
      cancelStatOrder !== prevProps.cancelStatOrder &&
      Object.keys(cancelStatOrder).length !== 0
    ) {
      if (cancelStatOrder.status === "SUCCESS") {
        this.setData({});
        this.setState({
          errors: {},
          disableCancelledNote: true,
          showIndicator: false,
        });
      }
    }
    if (deleteStatActionResult !== prevProps.deleteStatActionResult && Object.keys(deleteStatActionResult).length !== 0) {
      if (deleteStatActionResult.status === "SUCCESS") {
        this.setData({});
        this.setState({
          errors: {},
          disableCancelledNote: true,
          showIndicator: false,
        });
      }
    }
    if (this.props.show) {
      if (bulkCheckinCheckoutData !== prevProps.bulkCheckinCheckoutData && Object.keys(bulkCheckinCheckoutData).length !== 0 &&
      bulkCheckinCheckoutData.status === "SUCCESS") {
        this.handleSubmit();
      } else if (bulkCheckinCheckoutData.status === "ERROR" && bulkCheckinCheckoutData.message_code === TIMEZONE_MISMATCH) {
        this.setState({
          statusModalType: {
            value: true,
            status: "confirmation",
            type: TIMEZONE_MISMATCH,
            title: bulkCheckinCheckoutData.message
          },
        }, () => this.props.clearBulkOrderActionResponse());
      }
    }
    if (prevProps.note_type !== note_type && note_type.length) {
      note_type.map((note) => {
        this.inputRefs.push(React.createRef());
      });
    }
    if (mode === "edit" && ((prevState.data !== this.state.data && this.state.initialData !== this.state.data) || (prevState.testList !== this.state.testList && this.state.originalTestList !== this.state.testList))) {
      this.setState({
        isFormChanged: this.validateEditFields(this.state.initialData, this.state.originalTestList)
      })
    }
  };

  updateRoutineRedrawData = async (routineRedrawData, mode, isLoad = true) => {
    isLoad && this.setState({ loading: true });
    const {
      id,
      order_number = "",
      order_status = {},
      order_creation_datetime_for_modal = getLocalDateTime(),
      gender = "",
      order_line_items = [],
      order_type = {},
      notes = [],
      order_frequency = {},
      order_creation_method = {},
      parent_account_number = "",
      parent_account_name = "",
      child_account_number = "",
      child_account_name = "",
      child_account_address1 = "",
      child_account_address2 = "",
      child_city = "",
      child_zip = "",
      child_state = {},
      patient_first_name = "",
      patient_dob = "",
      patient_last_name = "",
      patient_middle_initial = "",
      patient_location = "",
      ordering_physician = "",
      region = {},
      territory = {},
      zone = {},
      created_by = "",
      facility_id = 0,
      user = {},
      phleb_activity_informations = [],
      order_requisitions = [],
      collection_time = null,
      facility_address = {},
      hl7 = false,
      authorized_by = "",
      order_timezone = {},
      order_creation_timezone = {},
      child_account_latitude,
      child_account_longitude
    } = routineRedrawData;


    let tempList =
      mode === "create"
        ? [
          {
            testCode: "",
            name: "",
            collectionDate: !hl7 ? new Date() : "",
            orderCollectionMethod: "",
          },
        ]
        : [];
    if (order_line_items) {
      order_line_items.forEach((order) => {
        if (order.test_code || order.test_code_name) {
          tempList.push({
            ...order,
            testCode: order.test_code ? { label: order.test_code, value: order.id } : "",
            name: order.test_code_name ? { label: order.test_code_name, value: order.id } : "",
            orderCollectionMethod: order.order_collection_method ? { value: order.order_collection_method.id, label: order.order_collection_method.collection_type } : "",
            collectionDate: order.collection_date ? new Date(`${order.collection_date} 00:00:00`) : !hl7 ? new Date() : "",
            isHL7: true,
          });
        } else {
          tempList.push({
            ...order,
            testCode: order.test_code_id
              ? order.test_code_id.id
                ? { label: order.test_code_id.test_code, value: order.test_code_id.id }
                : ""
              : "",
            name: order.test_code_id
              ? order.test_code_id.id
                ? { label: order.test_code_id.test_code_name, value: order.test_code_id.id }
                : ""
              : "",
            orderCollectionMethod: order.order_collection_method ? { value: order.order_collection_method.id, label: order.order_collection_method.collection_type } : "",
            collectionDate: order.collection_date ? new Date(`${order.collection_date} 00:00:00`) : !hl7 ? new Date() : "",
          });
        }
      });
    }

    let stateNotes = {};
    notes.forEach((note) => {
      const { note_type = {} } = note;
      if (note_type) {
        if (stateNotes[note_type.id]) {
          const { list = [] } = stateNotes[note_type.id];
          stateNotes[note_type.id] = {
            ...stateNotes[note_type.id],
            list: [note, ...list],
          };
        } else {
          stateNotes[note_type.id] = {
            text: "",
            list: [note],
          };
        }
      }
    });
    let phlebRes = [];
    if (zone && zone.id) {
      const userResponse =
        (await this.loadOptions3(
          zone.id,
          order_type ? (order_type.id == 2 ? collection_time || null : null) : null
        )) || {};
      const { content = {} } = userResponse;
      phlebRes = this.fetchPhlebotomistOptions(content);
    } else {
      phlebRes = [{ label: "Others", options: [{ label: "Hold For Dispatch", value: 0, type: "Others" }] }];
    }
    let tempCheckIn = {};
    let tempCheckOut = {};
    let tempDropOff = {};
    let tempDropLoc = "";
    let tempDropType = "";

    let checkIns = phleb_activity_informations.filter((act) => act.action_type_id === 1) || [];
    let checkOuts = phleb_activity_informations.filter((act) => act.action_type_id === 2) || [];
    let dropOffs = phleb_activity_informations.filter((act) => act.action_type_id === 3) || [];
    let checkInObj = {};
    let checkOutObj = {};
    let dropOffObj = {};

    if (dropOffs.length) {
      let checkinIndex = findLastIndex(phleb_activity_informations, (act) => act.action_type_id == 1);

      checkInObj = phleb_activity_informations[checkinIndex] || {};

      let checkoutIndex = findLastIndex(phleb_activity_informations, (act) => act.action_type_id == 2);

      checkOutObj = phleb_activity_informations[checkoutIndex] || {};

      dropOffObj = dropOffs.length
        ? dropOffs.reduce((a, b) => {
          return new Date(a.action_datetime) > new Date(b.action_datetime) ? a : b;
        })
        : undefined;
    } else {
      if (checkIns.length > checkOuts.length) {
        checkInObj = phleb_activity_informations.length
          ? phleb_activity_informations[phleb_activity_informations.length - 1]
            ? phleb_activity_informations[phleb_activity_informations.length - 1].action_type_id === 1
              ? phleb_activity_informations[phleb_activity_informations.length - 1]
              : phleb_activity_informations[phleb_activity_informations.length - 2]
                ? phleb_activity_informations[phleb_activity_informations.length - 2].action_type_id === 1
                  ? phleb_activity_informations[phleb_activity_informations.length - 2]
                  : {}
                : {}
            : {}
          : {};

        checkOutObj = {};
      } else {
        let checkinIndex = findLastIndex(phleb_activity_informations, (act) => act.action_type_id == 1);

        checkInObj = phleb_activity_informations[checkinIndex] || {};

        let checkoutIndex = findLastIndex(phleb_activity_informations, (act) => act.action_type_id == 2);

        checkOutObj = phleb_activity_informations[checkoutIndex] || {};
      }

      dropOffObj = dropOffs.length
        ? dropOffs.reduce((a, b) => {
          return new Date(a.action_datetime) > new Date(b.action_datetime) ? a : b;
        })
        : undefined;
    }
    let deafultTimezoneForChecinCheckout = order_timezone ? order_timezone.timezone_id || "" : ""

    if (checkInObj && Object.keys(checkInObj).length) {
      tempCheckIn = {
        ...checkInObj,
        actionDateTime: checkInObj.action_datetime ? new Date(checkInObj.action_datetime) : "",
        latitudeName: checkInObj.latitude,
        longitudeName: checkInObj.longitude,
        checkInTimezone: checkInObj.timezone ? checkInObj.timezone.timezone_id || deafultTimezoneForChecinCheckout : deafultTimezoneForChecinCheckout
      };
    } else {
      tempCheckIn = {
        checkInTimezone: checkInObj.timezone ? checkInObj.timezone.timezone_id || deafultTimezoneForChecinCheckout : deafultTimezoneForChecinCheckout
      };
    }

    if (checkOutObj && Object.keys(checkOutObj).length) {
      tempCheckOut = {
        ...checkOutObj,
        actionDateTime: checkOutObj.action_datetime ? new Date(checkOutObj.action_datetime) : "",
        latitudeName: checkOutObj.latitude,
        longitudeName: checkOutObj.longitude,
        checkoutStatus: checkOutObj.order_cancel_type,
        checkoutNote: checkOutObj.order_cancel_notes,
        checkOutTimezone: checkOutObj.timezone ? checkOutObj.timezone.timezone_id || deafultTimezoneForChecinCheckout : deafultTimezoneForChecinCheckout
      };
    } else {
      tempCheckOut = {
        checkOutTimezone: checkOutObj.timezone ? checkOutObj.timezone.timezone_id || deafultTimezoneForChecinCheckout : deafultTimezoneForChecinCheckout
      };
    }

    if (dropOffObj) {
      tempDropOff = {
        ...dropOffObj,
        actionDateTime: dropOffObj.action_datetime ? new Date(dropOffObj.action_datetime) : "",
        latitudeName: dropOffObj.latitude,
        longitudeName: dropOffObj.longitude,
        dropOffTimezone: dropOffObj.timezone ? dropOffObj.timezone.timezone_id || "" : ""
      };
      tempDropType = tempDropOff.drop_point ? tempDropOff.drop_point.toLowerCase() : "";
      if (tempDropType === "courier") {
        tempDropLoc = tempDropOff.drop_location
          ? {
            label: tempDropOff.drop_location || "",
            value: {
              address1: tempDropOff.drop_location || "",
              latitudeName: dropOffObj.latitude,
              longitudeName: dropOffObj.longitude,
            },
          }
          : "";
      } else {
        tempDropLoc = tempDropOff.drop_point_id
          ? {
            ...tempDropOff,
            id: tempDropOff.drop_point_id,
            value: tempDropOff.drop_point_id,
            latitude: dropOffObj.latitude,
            longitude: dropOffObj.longitude,
            label: tempDropOff.drop_location || "",
          }
          : "";
      }
    }

    // get test codes
    let testCodes = [];
    let testDescriptions = [];

    if (region && region.id) {
      const { codes = [], descriptions = [] } = await this.handleGetTestCodes(region.id);
      testCodes = codes;
      testDescriptions = descriptions
    }
    // tempList.forEach((item, idx) => {
    //   if (item.testCode && item.testCode.label && !testCodes.some(code => code.label == item.testCode.label)) {
    //     tempList = tempList.map((item2, idx2) => { return item2.testCode.label == item.testCode.label ? { ...item2, deleted: true } : { ...item2 } });
    //   }
    // });
    // if (!tempList.length || tempList.every(item => item.deleted)) {
    //   tempList.unshift({
    //     testCode: "",
    //     name: "",
    //     collectionDate: new Date(),
    //     orderCollectionMethod: "",
    //   })
    // }

    const checkinObj = order_status && (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) && phleb_activity_informations.length > 0
      ? {}
      : tempCheckIn;

    const checkoutObj = order_status && (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) && phleb_activity_informations.length > 0
      ? {}
      : tempCheckOut;

      let convertedDateTime = "";
      if (order_timezone && order_timezone.timezone_code && (!order_creation_timezone || (order_timezone.timezone_code != order_creation_timezone.timezone_code))) {
        let parsedDateTime = moment.tz(order_creation_datetime_for_modal, "YYYY-MM-DD HH:mm:ss", order_creation_timezone.timezone_code);
        let tempDateTime = parsedDateTime.clone().tz(order_timezone.timezone_code);
        convertedDateTime = new Date(tempDateTime.format('YYYY-MM-DD HH:mm:ss'));
      } else {
        convertedDateTime = new Date(order_creation_datetime_for_modal);
      }

    const finalData = {
      hl7,
      id,
      orderNumber: order_number,
      status: order_status ? order_status.id : "",
      originalStatus: order_status ? order_status.id : "",
      originalStatusText: order_status ? order_status.order_status_name : "Unassigned",
      createDateTime: mode === "create" ? "" : convertedDateTime,
      createDateTimeTimezone: order_timezone,
      preferredCreateDateTime: mode === "create" ? "" : order_creation_datetime_for_modal ? new Date(order_creation_datetime_for_modal) : new Date(),
      preferredCreateDateTimeTimezone: mode === "create" ? {} : order_creation_timezone,
      collectionTime: mode === "create" ? null : new Date(collection_time),
      createdBy: created_by ? created_by.name : "",
      orderType: order_type ? order_type.id : 4,
      orderFrequency: order_frequency ? order_frequency.id : "",
      creationMethod: order_creation_method ? order_creation_method.id : "", //remove validate in method and user
      parentAccountNumber: parent_account_number,
      parentAccountName: parent_account_name,
      inputParentAccountNumber: parent_account_number,
      inputParentAccountName: parent_account_name,
      childAccountNumber: child_account_number,
      childAccountName: child_account_name,
      address1: child_account_address1,
      address2: child_account_address2,
      city: child_city,
      zip: child_zip,
      stateName: child_state ? child_state.state_code : "",
      firstName: patient_first_name,
      middleName: patient_middle_initial,
      lastName: patient_last_name,
      gender: gender ? gender.id : "",
      patientDob: patient_dob ? new Date(`${patient_dob} 00:00:00`) : "",
      roomBedWingFloor: patient_location,
      orderingPhysician: ordering_physician,
      region: region ? region.id ? { label: region.region_name, value: region.id } : "" : "",
      territory: territory.id && region.id ? `${territory.id}:${region.id}` : "",
      zone: zone.id && territory.id ? `${zone.id}:${territory.id}` : "",
      originalZone: { value: zone.id && territory.id ? `${zone.id}:${territory.id}` : "", label: zone.zone_name },
      notes: stateNotes,
      facilityId: facility_id,
      phlebs: phlebRes,
      phlebotomist: user
        ? user.id
          ? { label: user.name, value: user.id }
          : { label: "Hold For Dispatch", value: 0 }
        : { label: "Hold For Dispatch", value: 0 },
      originalPhleb: user
        ? user.id
          ? { label: user.name, value: user.id }
          : { label: "Hold For Dispatch", value: 0 }
        : { label: "Hold For Dispatch", value: 0 },
      checkInDateTime: checkinObj,
      checkOutDateTime: checkoutObj,
      originalCheckIn: checkinObj,
      originalCheckOut: checkoutObj,
      dropOffDateTime: tempDropOff,
      originalDropOff: tempDropOff,
      dropOffLocation: tempDropLoc,
      dropOffType: tempDropType,
      requisitions: order_requisitions,
      facility_address: facility_address || {},
      phleb_activity_informations,
      authorizedBy: authorized_by,
      user: user || {},
      latitudeName: child_account_latitude || "",
      longitudeName: child_account_longitude || ""
    };
    if (!tempList.length) {
      tempList.unshift({
        testCode: "",
        name: "",
        collectionDate: !hl7 ? new Date() : "",
        orderCollectionMethod: "",
      })
    }
    const temp1 = [...tempList];
    const temp2 = temp1.map(t => { return { ...t, cDate: t.collectionDate } });
    this.setState({
      data: finalData,
      initialData: finalData,
      enableRegion: !region || !region.id ? true : false,
      enableTerr: !territory || !territory.id ? true : false,
      enableZone: !zone || !zone.id ? true : false,
      loading: false,
      testCodes,
      testDescriptions,
      testList: temp1,
      originalTestList: temp2
    });
  };

  validateEditFields = (initialData, originalTestList = []) => {
    let result = false;

    const {
      parentAccountNumber = "",
      parentAccountName = "",
      childAccountNumber = "",
      childAccountName = "",
      address1 = "",
      address2 = "",
      city = "",
      stateName = "",
      zip = "",
      orderType = "",
      collectionTime = "",
      region = "",
      territory = "",
      zone = "",
      phlebotomist = "",
      firstName = "",
      middleName = "",
      lastName = "",
      gender = "",
      patientDob = new Date(),
      roomBedWingFloor = "",
      orderingPhysician = "",
      checkInDateTime = {},
      checkOutDateTime = {},
      authorizedBy = "",
      dropOffType = "",
      dropOffDateTime = {},
      dropOffLocation = "",
      selectedFiles = [],
      notify = false,
      notifiedPerson = "",
      notes = [],
      isClear = false,
      clearNote = ""
    } = this.state.data;
    const {
      testList = [],
    } = this.state;
    const {
      parentAccountNumber: prevParentAccountNumber = "",
      parentAccountName: prevParentAccountName = "",
      childAccountNumber: prevChildAccountNumber = "",
      childAccountName: prevChildAccountName = "",
      address1: prevAddress1 = "",
      address2: prevAddress2 = "",
      city: prevCity = "",
      stateName: prevStateName = "",
      zip: prevZip = "",
      orderType: prevOrderType = "",
      collectionTime: prevCollectionTime = "",
      region: prevRegion = "",
      territory: prevTerritory = "",
      zone: prevZone = "",
      phlebotomist: prevPhlebotomist = "",
      firstName: prevFirstName = "",
      middleName: prevMiddleName = "",
      lastName: prevLastName = "",
      gender: prevGender = "",
      patientDob: prevPatientDob = new Date(),
      roomBedWingFloor: prevRoomBedWingFloor = "",
      orderingPhysician: prevOrderingPhysician = "",
      checkInDateTime: prevCheckInDateTime = {},
      checkOutDateTime: prevCheckOutDateTime = {},
      authorizedBy: prevAuthorizedBy = "",
      dropOffType: prevDropOffType = "",
      dropOffDateTime: prevDropOffDateTime = {},
      dropOffLocation: prevDropOffLocation = "",
      selectedFiles: prevSelectedFiles = [],
      notify: prevNotify = false,
      notifiedPerson: prevNotifiedPerson = "",
      notes: prevNotes = [],
      isClear: prevIsClear = false,
      clearNote: prevClearNote = ""
    } = initialData;

    const allFieldsExceptPhlebFlag = !_.isEqual(parentAccountNumber.hasOwnProperty('label') ? parentAccountNumber.label : parentAccountNumber, prevParentAccountNumber) ||
      !_.isEqual(parentAccountName.hasOwnProperty('label') ? parentAccountName.label : parentAccountName, prevParentAccountName) ||
      !_.isEqual(childAccountNumber, prevChildAccountNumber) ||
      !_.isEqual(childAccountName, prevChildAccountName) ||
      !_.isEqual(address1, prevAddress1) ||
      !_.isEqual(address2, prevAddress2) ||
      !_.isEqual(city, prevCity) ||
      !_.isEqual(stateName, prevStateName) ||
      !_.isEqual(zip, prevZip) ||
      !_.isEqual(orderType, prevOrderType) ||
      !_.isEqual(collectionTime, prevCollectionTime) ||
      !_.isEqual(region, prevRegion) ||
      !_.isEqual(territory, prevTerritory) ||
      !_.isEqual(zone, prevZone) ||
      (!_.isEqual(phlebotomist, prevPhlebotomist) && !_.isEqual(phlebotomist.value || 0, prevPhlebotomist.value)) ||
      !_.isEqual(firstName, prevFirstName) ||
      !_.isEqual(middleName, prevMiddleName) ||
      !_.isEqual(lastName, prevLastName) ||
      !_.isEqual(gender.toString(), prevGender.toString()) ||
      !areEqualDatesTwo(patientDob, prevPatientDob) ||
      !_.isEqual(roomBedWingFloor, prevRoomBedWingFloor) ||
      !_.isEqual(orderingPhysician, prevOrderingPhysician) ||
      !_.isEqual(authorizedBy, prevAuthorizedBy) ||
      !_.isEqual(dropOffType, prevDropOffType) ||
      notify != prevNotify ||
      !_.isEqual(notifiedPerson, prevNotifiedPerson) ||
      !_.isEqual(checkInDateTime, prevCheckInDateTime) ||
      !_.isEqual(checkOutDateTime, prevCheckOutDateTime) ||
      !_.isEqual(dropOffDateTime, prevDropOffDateTime)
      || !_.isEqual(dropOffLocation, prevDropOffLocation) ||
      !_.isEqual(selectedFiles, prevSelectedFiles) || !_.isEqual(isClear, prevIsClear) || !_.isEqual(clearNote, prevClearNote)


    if (allFieldsExceptPhlebFlag) {
      result = allFieldsExceptPhlebFlag;
    } else {
      let noteLabels1 = notes ? Object.keys(notes).map(item => notes[item] ? notes[item].text || "" : "").sort() : [];
      noteLabels1 = noteLabels1.filter(n => n != "");
      let noteLabels2 = prevNotes ? Object.keys(prevNotes).map(item => prevNotes[item] ? prevNotes[item].text || "" : "").sort() : [];
      noteLabels2 = noteLabels2.filter(n => n != "");
      const areBothNoteListSame = JSON.stringify(noteLabels1) === JSON.stringify(noteLabels2);

      if (!areBothNoteListSame) {
        result = true
      } else {
        const labels1 = testList.map(item => `${item.testCode?.label || ''} ${item.id || ''} ${item.cDate || new Date()} ${item.orderCollectionMethod?.label || ''} ${item.name?.label || ''} ${item.deleted || ''}`).sort();
        const labels2 = originalTestList.map(item => `${item.testCode?.label || ''} ${item.id || ''} ${item.cDate || new Date()} ${item.orderCollectionMethod?.label || ''} ${item.name?.label || ''} ${item.deleted || ''}`).sort();
        const areBothListSame = JSON.stringify(labels1) === JSON.stringify(labels2);
        result = !areBothListSame;
      }
    }
    return result;
  }

  handleBulkAction = (isConfirmed = false, skipTzValidation = false) => {
    const { data, mode, testList, originalTestList, testCodes = [] } = this.state;
    const {
      hl7 = false,
      selectedFiles = [],
      phlebotomist = {},
      originalStatus,
      originalPhleb = {}
    } = data;
    const { paramOrderId, navigate } = this.props;
    const errors = this.validateFields(data) || {};
    let isTestListItemsEqual = compareTestListItems(testList, originalTestList);
    const actErrors = this.validatePhlebActFields(data) || {};
    if (Object.keys(actErrors).length === 0 && (isTestListItemsEqual && (hl7 || Object.keys(errors).length === 0))) {

      let removedTestCodes = [];
      let tempList = [...testList];
      if (!hl7) {
        tempList.forEach((item, idx) => {
          if (!item.isHL7 && item.testCode && item.testCode.label && !testCodes.some(code => code.label == item.testCode.label)) {
            !item.deleted && removedTestCodes.push(item);
            tempList = tempList.map((item2, idx2) => { return item2.testCode.label == item.testCode.label ? { ...item2, deleted: true } : { ...item2 } });
          }
        });
        console.log(removedTestCodes, 'removedTestCodes', tempList);
        if (!isConfirmed ? removedTestCodes && removedTestCodes.length : false) {
          this.setState({
            testCodeWarning: {
              show: true,
              removedTestCodes
            }
          })
          return;
        }
      }

      let lineItems = [];

      tempList.forEach((list) => {
        if (list.isHL7) {
          lineItems.push({
            id: list.id,
            test_code: list.testCode ? list.testCode.label || "" : "",
            test_code_name: list.name ? list.name.label || "" : "",
            order_collection_method_id: list.orderCollectionMethod ? Number(list.orderCollectionMethod.value) : "",
            order_collection_date: list.collectionDate ? moment(list.collectionDate).format("YYYY-MM-DD") : "",
            deleted: list.deleted || false
          });
        } else {
          if ((list.id || !list.deleted) && list.testCode && list.testCode.value) {
            lineItems.push({
              id: list.id,
              test_code_id: list.testCode ? list.testCode.value || "" : "",
              order_collection_method_id: list.orderCollectionMethod ? Number(list.orderCollectionMethod.value) : "",
              order_collection_date: list.collectionDate ? moment(list.collectionDate).format("YYYY-MM-DD") : "",
              deleted: list.deleted || (!list.testCode || !list.testCode.value ? true : false) || false
            });
          }
        }
      });

      if (!hl7) {
        if (!lineItems.length || lineItems.every(item => item.deleted)) {
          tempList.unshift({
            testCode: "",
            name: "",
            collectionDate: new Date(),
            orderCollectionMethod: "",
          })
          this.setState({
            testList: tempList,
            originalTestList: tempList,
            testCodeWarning: {
              show: false, removedTestCodes: []
            }
          }, () => {
            const errors = this.validateFields(data) || {};
            this.setState({ errors: errors }, () => {
              toast(
                (t) => (
                  <ToastContent type="error" message={`Check if all required fields have been filled in before you ${mode === "edit" ? "update" : "create"} an order`} close={() => toast.remove()} />
                ),
                {
                  duration: 4000,
                }
              );
              if (document.querySelector('.error-message')) {
                document.querySelector('.error-message').scrollIntoView({
                  behavior: 'smooth'
                });
              } else if (document.querySelector('.error-message-text')) {
                document.querySelector('.error-message-text').scrollIntoView({
                  behavior: 'smooth'
                });
              }
            });
          })
          return;
        }
      }

      const { actionData = [], errors = {} } = this.updatePhlebInfo(skipTzValidation) || {};
      if (actionData.length && phlebotomist && phlebotomist.value && originalStatus != UNASSIGNED_STATUS && (originalStatus == REDISPATCH_STATUS ? phlebotomist.value == originalPhleb.value : true)) {
        const formData = new FormData();
        formData.append("actions", JSON.stringify(actionData));
        selectedFiles.forEach((file) => {
          formData.append("files", file);
        });
        this.props.phlebCheckinCheckout(formData);
      } else {
        this.handleSubmit(isConfirmed, skipTzValidation);
      }
    } else {
      const finalErrors = hl7 ? { ...actErrors } : { ...errors, ...actErrors };
      this.setState({ errors: finalErrors }, () => {
        toast(
          (t) => (
            <ToastContent type="error" message={Object.keys(finalErrors).length === 0 && !isTestListItemsEqual ? 'Duplicate tests found, please check testing information section and review the tests.' : `Check if all required fields have been filled in before you ${mode === "edit" ? "update" : "create"} an order`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
        if (Object.keys(finalErrors).length === 0 && !isTestListItemsEqual) {
          if (document.querySelector('.testlist-comparison-class')) {
            document.querySelector('.testlist-comparison-class').scrollIntoView({
              behavior: 'smooth'
            });
          }
        } else {
          if (document.querySelector('.error-message')) {
            document.querySelector('.error-message').scrollIntoView({
              behavior: 'smooth'
            });
          } else if (document.querySelector('.error-message-text')) {
            document.querySelector('.error-message-text').scrollIntoView({
              behavior: 'smooth'
            });
          }
        }
      });
    }
    if (paramOrderId) {
      navigate(ROUTINEREDRAW, { replace: true });
    }
  }

  handleSubmit = (isConfirmed = false, skipTzValidation = false) => {
    const { data, mode = "", disableCancelledNote, testList = [], originalTestList = [], testCodeWarning, testCodes = [] } = this.state;
    const {
      id,
      status = UNASSIGNED_STATUS,
      orderType = 4,
      orderFrequency = 1,
      creationMethod = 4, //remove validate in method and user
      parentAccountNumber = {},
      parentAccountName = "",
      inputParentAccountName = "",
      inputParentAccountNumber = "",
      childAccountNumber = "",
      childAccountName = "",
      address1 = "",
      address2 = "",
      city = "",
      zip = "",
      stateName = "",
      latitudeName = null,
      longitudeName = null,
      zone = "",
      phlebotomist = { label: "Hold For Dispatch", value: 0 },
      firstName = "",
      middleName = "",
      lastName = "",
      gender = "",
      patientDob = "",
      roomBedWingFloor = "",
      orderingPhysician = "",
      notes = {},
      order_number,
      facilityId = 0,
      collectionTime = null,
      authorizedBy = "",
      notify = false,
      hl7 = false,
      checkOutDateTime = {}
    } = data;

    const { paramOrderId, navigate } = this.props;

    const errors = this.validateFields(data) || {};
    let isTestListItemsEqual = compareTestListItems(testList, originalTestList);
    if (isTestListItemsEqual && (hl7 || Object.keys(errors).length === 0)) {

      let removedTestCodes = [];
      let tempList = [...testList];
      if (!hl7) {
        tempList.forEach((item, idx) => {
          if (!item.isHL7 && item.testCode && item.testCode.label && !testCodes.some(code => code.label == item.testCode.label)) {
            !item.deleted && removedTestCodes.push(item);
            tempList = tempList.map((item2, idx2) => { return item2.testCode.label == item.testCode.label ? { ...item2, deleted: true } : { ...item2 } });
          }
        });
        console.log(removedTestCodes, 'removedTestCodes', tempList);
        if (!isConfirmed ? removedTestCodes && removedTestCodes.length : false) {
          this.setState({
            testCodeWarning: {
              show: true,
              removedTestCodes
            }
          })
          return;
        }
      }

      let lineItems = [];

      tempList.forEach((list) => {
        if (list.isHL7) {
          lineItems.push({
            id: list.id,
            test_code: list.testCode ? list.testCode.label || "" : "",
            test_code_name: list.name ? list.name.label || "" : "",
            order_collection_method_id: list.orderCollectionMethod ? Number(list.orderCollectionMethod.value) : "",
            order_collection_date: list.collectionDate ? moment(list.collectionDate).format("YYYY-MM-DD") : "",
            deleted: list.deleted || false
          });
        } else {
          if ((list.id || !list.deleted) && list.testCode && list.testCode.value) {
            lineItems.push({
              id: list.id,
              test_code_id: list.testCode ? list.testCode.value || "" : "",
              order_collection_method_id: list.orderCollectionMethod ? Number(list.orderCollectionMethod.value) : "",
              order_collection_date: list.collectionDate ? moment(list.collectionDate).format("YYYY-MM-DD") : "",
              deleted: list.deleted || (!list.testCode || !list.testCode.value ? true : false) || false
            });
          }
        }
      });

      if (!hl7) {
        if (!lineItems.length || lineItems.every(item => item.deleted)) {
          tempList.unshift({
            testCode: "",
            name: "",
            collectionDate: new Date(),
            orderCollectionMethod: "",
          })
          this.setState({
            testList: tempList,
            originalTestList: tempList,
            testCodeWarning: {
              show: false, removedTestCodes: []
            }
          }, () => {
            const errors = this.validateFields(data) || {};
            this.setState({ errors: errors }, () => {
              toast(
                (t) => (
                  <ToastContent type="error" message={`Check if all required fields have been filled in before you ${mode === "edit" ? "update" : "create"} an order`} close={() => toast.remove()} />
                ),
                {
                  duration: 4000,
                }
              );
              if (document.querySelector('.error-message')) {
                document.querySelector('.error-message').scrollIntoView({
                  behavior: 'smooth'
                });
              } else if (document.querySelector('.error-message-text')) {
                document.querySelector('.error-message-text').scrollIntoView({
                  behavior: 'smooth'
                });
              }
            });
          })
          return;
        }
      }

      let updatedUserId = phlebotomist ? Number(phlebotomist.value) || 0 : 0;
      if (checkOutDateTime && checkOutDateTime.checkoutStatus === "UTO") {
        updatedUserId = 0;
      }

      let finalNotes = [];
      Object.keys(notes).forEach((noteId) => {
        const { text = "" } = notes[noteId] || [];
        if (text) {
          finalNotes.push({
            notes_type_id: parseInt(noteId),
            notes: text,
          });
        }
      });

      if (!disableCancelledNote) {
        const payload = {
          id,
          order_status_id: 9,
          authorizedBy: authorizedBy,
          notes: finalNotes,
        };
        this.props.cancelStatOrderHandler(payload);
      } else {
        const payload = {
          id,
          order_number,
          parent_account_number: parentAccountNumber.label || inputParentAccountNumber,
          parent_account_name: parentAccountName.label || inputParentAccountName,
          facility_id: facilityId,
          child_account_number: childAccountNumber,
          child_account_name: childAccountName,
          child_account_address1: address1,
          child_account_address2: address2,
          child_city: city,
          child_state: stateName,
          child_zip: zip,
          child_account_latitude: Number(latitudeName) ? Number(latitudeName) : null,
          child_account_longitude: Number(longitudeName) ? Number(longitudeName) : null,
          zone_id: zone.includes(":")
            ? zone.split(":")[0]
              ? Number(zone.split(":")[0])
              : null
            : Number(zone) || null,
          order_type_id: orderType,
          order_status_id: !disableCancelledNote ? CANCELLED_STATUS : notify ? NOTIFIED_CLIENT_STATUS : status == NOTIFIED_CLIENT_STATUS ? "" : "",
          authorizedBy: !disableCancelledNote ? authorizedBy : "",
          order_frequency_id: orderFrequency,
          order_creation_method_id: creationMethod,
          patient_first_name: firstName,
          patient_last_name: lastName,
          patient_middle_initial: middleName,
          gender_id: gender,
          patient_dob: moment(patientDob).format("YYYY-MM-DD"),
          patient_location: roomBedWingFloor,
          ordering_physician: orderingPhysician,
          notes: finalNotes,
          order_line_items: lineItems,
          user_id: updatedUserId || null,
          collection_time:
            orderType == 2 ? (collectionTime ? moment(collectionTime).format("YYYY-MM-DD HH:mm:ss") : "") : "",
        };
        this.setState({
          testCodeWarning: {
            show: false,
            removedTestCodes: []
          }
        })
        if (mode === "create") {
          this.props.handleCreateOrder(payload);
        } else {
          this.props.handleUpdateOrder(payload);
        }
      }
    } else {
      this.setState({ errors }, () => {
        toast(
          (t) => (
            <ToastContent type="error" message={Object.keys(errors).length === 0 && !isTestListItemsEqual ? 'Duplicate tests found, please check testing information section and review the tests.' : `Check if all required fields have been filled in before you ${mode === "edit" ? "update" : "create"} an order`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
        if (Object.keys(errors).length === 0 && !isTestListItemsEqual) {
          if (document.querySelector('.testlist-comparison-class')) {
            document.querySelector('.testlist-comparison-class').scrollIntoView({
              behavior: 'smooth'
            });
          }
        } else {
          if (document.querySelector('.error-message')) {
            document.querySelector('.error-message').scrollIntoView({
              behavior: 'smooth'
            });
          } else if (document.querySelector('.error-message-text')) {
            document.querySelector('.error-message-text').scrollIntoView({
              behavior: 'smooth'
            });
          }
        }
      });
    }
    if (paramOrderId) {
      navigate(ROUTINEREDRAW, { replace: true });
    }
  };

  validateFields = (data) => {
    const errors = {};
    const { disableCancelledNote, testList = [
      {
        testCode: "",
        name: "",
        collectionDate: new Date(),
        orderCollectionMethod: "",
      },
    ], } = this.state;

    const {
      status = "Unassigned",
      orderType = 4,
      collectionTime = null,
      orderFrequency = "1",
      creationMethod = "Manual Entry", //remove validate in method and user
      parentAccountNumber = "",
      inputParentAccountNumber = "",
      inputParentAccountName = "",
      parentAccountName = "",
      childAccountNumber = "",
      childAccountName = "",
      address1 = "",
      city = "",
      zip = "",
      region = "",
      territory = "",
      zone = "",
      stateName = "",
      // phlebotomist = "",
      firstName = "",
      lastName = "",
      gender = "",
      patientDob = "",
      roomBedWingFloor = "",
      orderingPhysician = "",
      notes = {},
      authorizedBy = "",
    } = data;
    if (!status) {
      errors["status"] = true;
    }
    if (!orderType) {
      errors["orderType"] = true;
    } else {
      if (orderType == 2 && !collectionTime) {
        errors["collectionTime"] = true;
      }
    }
    if (!orderFrequency) {
      errors["orderFrequency"] = true;
    }
    if (!creationMethod) {
      errors["creationMethod"] = true;
    }
    if (_.isEmpty(parentAccountName) && _.isEmpty(inputParentAccountName)) {
      errors["parentAccountName"] = true;
    }
    if (_.isEmpty(parentAccountNumber) && _.isEmpty(inputParentAccountNumber)) {
      errors["parentAccountNumber"] = true;
    }
    if (_.isEmpty(childAccountNumber)) {
      errors["childAccountNumber"] = true;
    }
    if (_.isEmpty(childAccountName)) {
      errors["childAccountName"] = true;
    }
    if (_.isEmpty(address1)) {
      errors["address1"] = true;
    }
    if (_.isEmpty(city)) {
      errors["city"] = true;
    }
    if (_.isEmpty(stateName)) {
      errors["stateName"] = true;
    }
    if (!zip) {
      errors["zip"] = true;
    }
    if (!region || !region.value) {
      errors["region"] = true;
    }
    if (!territory) {
      errors["territory"] = true;
    }
    if (!zone) {
      errors["zone"] = true;
    }
    // if (!phlebotomist) {
    //   errors["phlebotomist"] = true;
    // }
    if (_.isEmpty(firstName)) {
      errors["firstName"] = true;
    }
    if (_.isEmpty(lastName)) {
      errors["lastName"] = true;
    }
    if (!gender) {
      errors["gender"] = true;
    }
    if (!patientDob) {
      errors["patientDob"] = true;
    }
    if (_.isEmpty(roomBedWingFloor)) {
      errors["roomBedWingFloor"] = true;
    }
    if (_.isEmpty(orderingPhysician)) {
      errors["orderingPhysician"] = true;
    }
    if (testList.length > 0) {
      testList.forEach((test, index) => {
        if (!test.deleted) {
          if (!test.testCode) {
            errors[`testList-testCode-${index}`] = true;
          }
          if (!test.orderCollectionMethod || !test.orderCollectionMethod.value) {
            errors[`testList-orderCollectionMethod-${index}`] = true;
          }
          if (!test.collectionDate) {
            errors[`testList-collectionDate-${index}`] = true;
          }
          if (!test.name) {
            errors[`testList-name-${index}`] = true;
          }
        }
      });
    }
    if (
      !disableCancelledNote &&
      (!Object.keys(notes).some((noteId) => noteId == 3) ||
        Object.keys(notes).some((noteId) => noteId == 3 && (!notes[noteId] || !notes[noteId].text)))
    ) {
      errors["notes-3"] = true;
    }
    if (!disableCancelledNote && !authorizedBy) {
      errors["authorizedBy"] = true;
    }
    return errors;
  };

  validatePhlebActFields = (data) => {
    const errors = {};
    const { mode } = this.state;
    const {
      dropOffType = "",
      dropOffDateTime = {},
      dropOffLocation = "",
      checkInDateTime = {},
      checkOutDateTime = {},
      authorizedBy = "",
      originalStatus,
      selectedFiles = [],
      requisitions = [],
    } = data;
    if (mode === "edit") {
      if (Object.keys(checkInDateTime).length > 0) {
        const {
          actionDateTime = "",
          checkInTimezone,
        } = checkInDateTime;
        if (actionDateTime && !checkInTimezone) {
          errors["checkInTimezone"] = true;
        }
      }
      if (Object.keys(checkOutDateTime).length > 0) {
        const { actionDateTime = "", id, checkoutStatus = "", checkoutNote, checkOutTimezone } = checkOutDateTime;
        if (id || originalStatus == REDISPATCH_STATUS) {
          if (actionDateTime) {
            if (!checkOutTimezone) {
              errors["checkOutTimezone"] = true;
            }
          } else {
            if (!actionDateTime) {
              errors["checkOutDateTime"] = true;
            }
            if (!checkOutTimezone) {
              errors["checkOutTimezone"] = true;
            }
          }
        } else {
          if (actionDateTime && checkoutStatus && checkoutStatus !== "SAMPLE_COLLECTED" && checkoutNote) {
            if (checkoutStatus === "CANCELLATION" && !authorizedBy) {
              errors["authorizedBy"] = true;
            }
            if (!checkOutTimezone) {
              errors["checkOutTimezone"] = true;
            }
          } else {
            if (actionDateTime) {
              if (!checkOutTimezone) {
                errors["checkOutTimezone"] = true;
              }
              if (!checkoutStatus) {
                errors["checkoutStatus"] = true;
              } else if (checkoutStatus === "CANCELLATION") {
                if (!checkoutNote) {
                  errors["checkoutNote"] = true;
                }
                if (!authorizedBy) {
                  errors["authorizedBy"] = true;
                }
              } else if (checkoutStatus !== "SAMPLE_COLLECTED" && checkoutStatus !== "CANCELLATION") {
                if (!checkoutNote) {
                  errors["checkoutNote"] = true;
                }
              }
            }
          }
        }
      }

      if (Object.keys(dropOffDateTime).length > 0 || dropOffType) {
        const { dropOffTimezone, actionDateTime } = dropOffDateTime;
        if (!dropOffType) {
          errors["dropOffType"] = true;
        } else if (!dropOffLocation || !dropOffLocation.value) {
          errors["dropOffLocation"] = true;
        }
        if (!actionDateTime) {
          errors["dropOffDateTime"] = true;
        }
        if (!dropOffTimezone) {
          errors["dropOffTimezone"] = true;
        }
        // if (dropOffType && dropOffType === "hospital" && !selectedFiles.length && !requisitions.length) {
        //   errors["selectedFiles"] = true;
        // }
      }
    }
    return errors;
  };

  disabledDate = () => {
    let today = new Date();
    let dd = today.getDate();
    if (dd < 10) {
      dd = "0" + dd;
    }
    let mm = today.getMonth() + 1;
    if (mm < 10) {
      mm = "0" + mm;
    }
    let yyyy = today.getUTCFullYear();
    let final = `${yyyy}-${mm}-${dd}`;
    return final;
  };

  onFocus = (event) => {
    event.target.setAttribute("autocomplete", "none");
  };

  addTestList = (e) => {
    e.preventDefault();
    const { data, testList = [
      {
        testCode: "",
        name: "",
        collectionDate: new Date(),
        orderCollectionMethod: "",
      },
    ], } = this.state;
    let tempList = [...testList];
    tempList.push({
      testCode: "",
      name: "",
      orderCollectionMethod: "",
      collectionDate: new Date(),
      deleted: false
    });
    this.setState({ testList: tempList });
  };

  removeTestList = (i) => {
    const { data, testList = [] } = this.state;
    let temp = [...testList];
    testList.forEach((ele, index) => {
      if (index == i) {
        if (!ele.id) {
          temp = temp.filter((t, index2) => index2 != index);
        } else {
          temp[index] = { ...ele, deleted: true }
        }
      }
    })
    this.setState({ testList: temp });
  };

  handleZip = (e) => {
    const val = e.target.value.replace(/\D/g, "");
    this.setData({ ...this.state.data, zip: val }, "zip");
  };

  loadOptions1 = async (text) => {
    const { data } = this.state;
    const { id, latitudeName, longitudeName, preferredCreateDateTime, preferredCreateDateTimeTimezone } = data;
    let paramString = `?account_number=${text}`;
    if (id) {
      paramString = `${paramString.length > 0 ? `${paramString}&` : ""}order_id=${id}`;
    }
    const response = await ahaApi.Account.parentAccountNumber(`${paramString}`)
      .then((res) => {
        const { content = [] } = res;
        return content.map((c) => {
          return { label: c.account_number, value: c };
        });
      })
      .catch((err) => {
        console.log(err, "error");
      });
    if (!response || !response.length) {
      this.setState({ showIndicator: true }, async () => {
        if (latitudeName && longitudeName) {
          let formData = {
            ...data
          }
          const response = await ahaApi.Account.getTimezone({
            coordinates: { latitude: latitudeName, longitude: longitudeName }
          });
          const { content = {} } = response;
          const { timezone_response = {} } = content;
          if (timezone_response && timezone_response.timezone_id) {
            const start = moment.tz(preferredCreateDateTime, preferredCreateDateTimeTimezone.timezone_code);
            let convertedDateTime = start.tz(timezone_response.timezone_code).toDate();
            console.log(preferredCreateDateTime, start, 'convertedDateTime2', convertedDateTime);
            formData.createDateTime = convertedDateTime
          }
        }
      });
    } else {
      this.setState({ showIndicator: false });
    }
    return response;
  };

  loadOptions2 = async (text) => {
    const { data } = this.state;
    const { id, latitudeName, longitudeName, preferredCreateDateTime, preferredCreateDateTimeTimezone } = data;
    let paramString = `?account_name=${text}`;
    if (id) {
      paramString = `${paramString.length > 0 ? `${paramString}&` : ""}order_id=${id}`;
    }
    const response = await ahaApi.Account.parentAccountName(paramString)
      .then((res) => {
        const { content = [] } = res;
        return content.map((c) => {
          return { label: c.account_name, value: c };
        });
      })
      .catch((err) => {
        console.log(err, "error");
      });
    if (!response || !response.length) {
      this.setState({ showIndicator: true }, async () => {
        if (latitudeName && longitudeName) {
          let formData = {
            ...data
          }
          const response = await ahaApi.Account.getTimezone({
            coordinates: { latitude: latitudeName, longitude: longitudeName }
          });
          const { content = {} } = response;
          const { timezone_response = {} } = content;
          if (timezone_response && timezone_response.timezone_id) {
            const start = moment.tz(preferredCreateDateTime, preferredCreateDateTimeTimezone.timezone_code);
            let convertedDateTime = start.tz(timezone_response.timezone_code).toDate();
            console.log(preferredCreateDateTime, start, 'convertedDateTime2', convertedDateTime);
            formData.createDateTime = convertedDateTime
          }
        }
      });
    } else {
      this.setState({ showIndicator: false });
    }
    return response;
  };

  filterTestCode = (inputValue) => {
    const { test_code } = this.props;
    return test_code.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  loadOptionsTestCode = async (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterTestCode(inputValue));
    }, 1000);
  };

  loadOptions3 = async (zoneId, collectionTime) => {
    const response = await ahaApi.Account.assignPhleb(collectionTime ? `zone_id=${zoneId}&create=true&collection_time=${collectionTime}` : `zone_id=${zoneId}&create=true`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return response;
  };

  handleParentAccountNumber = async (data = {}) => {
    const errors = this.state.errors;
    const { data: stateData, enableRegion,
      enableTerr,
      enableZone, } = this.state;
    let {
      childAccountNumber = "",
      childAccountName = "",
      address1 = "",
      city = "",
      stateName = "",
      zip = "",
      hl7 = false,
      region = "",
      territory = "",
      zone = "",
      facilityId = 0,
    } = stateData;
    if (data) {
      const { label, value = {} } = data;
      const {
        account_name,
        account_number,
        region = {},
        territory = {},
        zone = {},
        street_address1,
        city,
        zip,
        state,
        facility_id,
      } = value;

      let phlebRes = [];
      let assignedPhleb = { label: "Hold For Dispatch", value: 0 };
      const userResponse = (await this.loadOptions3(zone.id)) || {};
      const { content = {} } = userResponse;
      let { system_assigned_user = {} } = content;

      phlebRes = this.fetchPhlebotomistOptions(content);

      assignedPhleb = system_assigned_user
        ? { label: system_assigned_user.name, value: system_assigned_user.id } || {
          label: "Hold For Dispatch",
          value: 0,
        }
        : { label: "Hold For Dispatch", value: 0 };

      errors["parentAccountNumber"] = false;
      errors["parentAccountName"] = false;
      errors["region"] = false;
      errors["territory"] = false;
      errors["zone"] = false;
      errors["address1"] = false;
      errors["city"] = false;
      errors["stateName"] = false;
      errors["zip"] = false;
      errors["childAccountNumber"] = false;
      errors["childAccountName"] = false;
      // errors["phlebotomist"] = false;

      this.setState({
        ...this.state,
        data: {
          ...stateData,
          parentAccountNumber: data,
          inputParentAccountNumber: data.label,
          parentAccountName: account_name,
          inputParentAccountName: account_name,
          childAccountNumber: !hl7 ? account_number : childAccountNumber,
          childAccountName: !hl7 ? account_name : childAccountName,
          region: { label: region.region_name, value: region.id },
          territory: `${territory.id}:${region.id}`,
          zone: `${zone.id}:${territory.id}`,
          originalZone: { value: zone.id && territory.id ? `${zone.id}:${territory.id}` : "", label: zone.zone_name },
          address1: street_address1,
          city: city,
          stateName: state,
          zip: zip,
          facilityId: facility_id,
          phlebs: phlebRes,
          phlebotomist: assignedPhleb,
          // testCodes,
          // testDescriptions,
          status: system_assigned_user ? UNACKNOWLEDGED_STATUS : data.status || UNASSIGNED_STATUS,
          originalStatusText: system_assigned_user ? "Unacknowledged" : data.originalStatusText || "Unassigned",
        },
        enableRegion: !region || !region.id ? true : false,
        enableTerr: !territory || !territory.id ? true : false,
        enableZone: !zone || !zone.id ? true : false,
        errors,
        menuIsOpen: false,
        showIndicator: false,
      });
    } else {
      this.setState({
        data: {
          ...stateData,
          parentAccountNumber: "",
          parentAccountName: "",
          childAccountNumber: hl7 ? childAccountNumber : "",
          childAccountName: hl7 ? childAccountName : "",
          inputParentAccountNumber: "",
          inputParentAccountName: "",
          address1: hl7 ? address1 : "",
          city: hl7 ? city : "",
          stateName: hl7 ? stateName : "",
          zip: hl7 ? zip : "",
          region: hl7 ? region : null,
          territory: hl7 ? territory : "",
          zone: hl7 ? zone : "",
          facilityId: hl7 ? facilityId : 0,
          phlebotomist: { label: "Hold For Dispatch", value: 0 },
          status: UNASSIGNED_STATUS,
          originalStatusText: "Unassigned",
        },
        enableRegion: hl7 ? enableRegion : true,
        enableTerr: hl7 ? enableTerr : true,
        enableZone: hl7 ? enableZone : true,
      });
    }
  };

  handleParentAccountNameChange = async (data = {}) => {
    const { data: stateData, enableRegion,
      enableTerr,
      enableZone, } = this.state;
    let {
      childAccountNumber = "",
      childAccountName = "",
      address1 = "",
      city = "",
      stateName = "",
      zip = "",
      hl7 = false,
      region = "",
      territory = "",
      zone = "",
      facilityId = 0,
    } = stateData;
    const errors = this.state.errors;
    if (data) {
      const { value = {} } = data;
      const {
        account_number,
        account_name,
        region,
        territory,
        zone,
        street_address1,
        city,
        zip,
        state,
        facility_id,
      } = value;

      let phlebRes = [];
      let assignedPhleb = 0;
      const userResponse = (await this.loadOptions3(zone.id)) || {};
      const { content = {} } = userResponse;
      let { system_assigned_user = {} } = content;

      phlebRes = this.fetchPhlebotomistOptions(content);

      assignedPhleb = system_assigned_user
        ? { label: system_assigned_user.name, value: system_assigned_user.id } || {
          label: "Hold For Dispatch",
          value: 0,
        }
        : { label: "Hold For Dispatch", value: 0 };

      errors["parentAccountNumber"] = false;
      errors["parentAccountName"] = false;
      errors["region"] = false;
      errors["territory"] = false;
      errors["zone"] = false;
      errors["address1"] = false;
      errors["city"] = false;
      errors["stateName"] = false;
      errors["zip"] = false;
      errors["childAccountNumber"] = false;
      errors["childAccountName"] = false;
      // errors["phlebotomist"] = false;

      this.setState({
        data: {
          ...stateData,
          parentAccountName: data,
          inputParentAccountName: data.label,
          parentAccountNumber: account_number,
          childAccountName: !hl7 ? account_name : childAccountName,
          childAccountNumber: !hl7 ? account_number : childAccountNumber,
          inputParentAccountNumber: account_number,
          region: { label: region.region_name, value: region.id },
          territory: `${territory.id}:${region.id}`,
          zone: `${zone.id}:${territory.id}`,
          originalZone: { value: zone.id && territory.id ? `${zone.id}:${territory.id}` : "", label: zone.zone_name },
          address1: street_address1,
          city: city,
          stateName: state,
          zip: zip,
          facilityId: facility_id,
          phlebs: phlebRes,
          phlebotomist: assignedPhleb,
          // testCodes,
          // testDescriptions,
          status: system_assigned_user ? UNACKNOWLEDGED_STATUS : data.status || UNASSIGNED_STATUS,
          originalStatusText: system_assigned_user ? "Unacknowledged" : data.originalStatusText || "Unassigned",
        },
        enableRegion: !region || !region.id ? true : false,
        enableTerr: !territory || !territory.id ? true : false,
        enableZone: !zone || !zone.id ? true : false,
        errors,
        menuIsOpenParentName: false,
        showIndicator: false,
      });
    } else {
      this.setState({
        data: {
          ...stateData,
          parentAccountNumber: "",
          parentAccountName: "",
          childAccountName: hl7 ? childAccountName : "",
          childAccountNumber: hl7 ? childAccountNumber : "",
          inputParentAccountNumber: "",
          inputParentAccountName: "",
          address1: hl7 ? address1 : "",
          city: hl7 ? city : "",
          stateName: hl7 ? stateName : "",
          zip: hl7 ? zip : "",
          region: hl7 ? region : null,
          territory: hl7 ? territory : "",
          zone: hl7 ? zone : "",
          facilityId: hl7 ? facilityId : 0,
          phlebotomist: { label: "Hold For Dispatch", value: 0 },
          status: UNASSIGNED_STATUS,
          originalStatusText: "Unassigned",
        },
        enableRegion: hl7 ? enableRegion : true,
        enableTerr: hl7 ? enableTerr : true,
        enableZone: hl7 ? enableZone : true,
      });
    }
  };

  handleClose = () => {
    const { mode } = this.state;
    const { paramOrderId, navigate } = this.props;
    if (mode === "view") {
      this.setState({
        errors: {},
        showIndicator: false,
        disableCancelledNote: true,
        notify: false,
      });
      this.props.handleRoutineRedrawError();
      this.props.toggleRoutineRedrawModal(false);
    } else {
      this.setState({ statusModalType: { value: false } }, () => {
        if (mode === "create") {
          this.setState({
            errors: {},
            showIndicator: false,
            disableCancelledNote: true,
          });
          this.props.handleRoutineRedrawError();
          this.props.toggleRoutineRedrawModal(false);
        } else {
          const { routineRedrawData = {} } = this.props;
          this.updateRoutineRedrawData(routineRedrawData, mode, false);
          this.setState({
            errors: {},
            showIndicator: false,
            disableCancelledNote: true,
            notify: false,
          });
          this.props.handleRoutineRedrawError();
          this.props.toggleRoutineRedrawModal(false);
        }
      });
    }
    this.inputRefs = [];
    if (paramOrderId) {
      navigate(ROUTINEREDRAW, { replace: true });
    }
  };

  handleChangeCheckIn = (e, newTz) => {
    const {timezone_ids} = this.props;
    const { data, errors = {} } = this.state;
    const { checkInDateTime = {},
      checkOutDateTime = {}, } = data;
    if (e) {
      const checkinTz = checkInDateTime && checkInDateTime.checkInTimezone ? getLabelValueObjectByValue(timezone_ids, checkInDateTime.checkInTimezone) : "";
      const checkoutTz = checkOutDateTime && checkOutDateTime.checkOutTimezone ? getLabelValueObjectByValue(timezone_ids, checkOutDateTime.checkOutTimezone) : "";
      const hasError = checkOutDateTime && checkOutDateTime.actionDateTime && checkOutDateTime.checkOutTimezone ? compareTimes(null, moment(e).format("YYYY-MM-DD HH:mm:ss"), true, moment(checkOutDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, checkinTz, checkoutTz, "start") : false;
      if (hasError) {
        toast(
          (t) => (
            <ToastContent type="error" message={`Check in time can't be greater than check out`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
      } else if (!filterPassedTimeForCheckinCheckoutDropOff(moment(e).format("YYYY-MM-DD HH:mm:ss"), newTz)) {
        toast(
          (t) => (
            <ToastContent type="error" message={`Check in time cannot be later than today's date.`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
      } else {
        if (checkInDateTime.actionDateTime) {
          const checkinDate = moment(checkInDateTime.actionDateTime).format('YYYY-MM-DD');
          const selectedDate = moment(e).format('YYYY-MM-DD');
          if (moment(selectedDate).isSameOrBefore(checkinDate, 'day')) {
            this.setData(
              {
                ...data,
                checkInDateTime: {
                  ...checkInDateTime,
                  actionDateTime: e,
                },
              },
              "checkInDateTime"
            );
          } else {
            const currentTime = moment(new Date()).format("HH:mm");
            const selectedTime = moment(e).format("HH:mm");
            const isToday = moment(e).isSame(moment(), 'day');
            if (isToday && moment(selectedTime, "HH:mm").isSameOrAfter(moment(currentTime, "HH:mm"))) {
              const selectedDate = new Date(e);
              selectedDate.setHours(0, 0, 0, 0);
              this.setData(
                {
                  ...data,
                  checkInDateTime: {
                    ...checkInDateTime,
                    actionDateTime: selectedDate,
                  },
                },
                "checkInDateTime"
              );
            } else {
              this.setData(
                {
                  ...data,
                  checkInDateTime: {
                    ...checkInDateTime,
                    actionDateTime: e,
                  },
                },
                "checkInDateTime"
              );
            }
          }
        } else {
          this.setData(
            {
              ...data,
              checkInDateTime: {
                ...checkInDateTime,
                actionDateTime: e,
              },
            },
            "checkInDateTime"
          );
        }
      }
    } else {
      let tempErrors = { ...errors };
      // tempErrors["checkInDateTime"] = false;
      // tempErrors["checkOutDateTime"] = false;
      // tempErrors["checkoutStatus"] = false;
      // tempErrors["checkoutNote"] = false;
      // tempErrors["authorizedBy"] = false;
      // tempErrors["dropOffType"] = false;
      // tempErrors["dropOffDateTime"] = false;
      // tempErrors["dropOffLocation"] = false;
      // tempErrors["selectedFiles"] = false;
      this.setState({
        data: {
          ...data,
          checkInDateTime: {
            ...checkInDateTime,
            actionDateTime: "",
          },
        },
        errors: tempErrors
      });
    }
  }

  handleChangeCheckOut = (e, newTz) => {
    const {timezone_ids} = this.props;
    const { data, errors } = this.state;
    const { checkInDateTime = {},
      checkOutDateTime = {}, dropOffDateTime = {} } = data;
    if (e) {
      const checkinTz = getLabelValueObjectByValue(timezone_ids, checkInDateTime.checkInTimezone);
      const checkoutTz = getLabelValueObjectByValue(timezone_ids, checkOutDateTime.checkOutTimezone);
      const dropOffTz = getLabelValueObjectByValue(timezone_ids, dropOffDateTime.dropOffTimezone);

      const hasCheckoutError = checkInDateTime && checkInDateTime.actionDateTime ? compareTimes(null, moment(e).format("YYYY-MM-DD HH:mm:ss"), true, moment(checkInDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, checkoutTz, checkinTz, "end") : false;
      const hasDropOffError = dropOffDateTime && dropOffDateTime.actionDateTime ? compareTimes(null, moment(e).format("YYYY-MM-DD HH:mm:ss"), true, moment(dropOffDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, dropOffTz, checkoutTz, "start") : false;
      if (hasCheckoutError) {
        toast(
          (t) => (
            <ToastContent type="error" message={`Check in time can't be greater than check out`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
      } else if (hasDropOffError) {
        toast(
          (t) => (
            <ToastContent type="error" message={`Check out time can't be greater than drop off`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
      } else if (!filterPassedTimeForCheckinCheckoutDropOff(moment(e).format("YYYY-MM-DD HH:mm:ss"), newTz)) {
        toast(
          (t) => (
            <ToastContent type="error" message={`Check out time cannot be later than today's date.`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
      } else {
        this.setData(
          {
            ...data,
            checkOutDateTime: {
              ...checkOutDateTime,
              actionDateTime: e,
            },
          },
          "checkOutDateTime"
        );
      }
    } else {
      let tempErrors = { ...errors };
      // tempErrors["checkInDateTime"] = false;
      // tempErrors["checkOutDateTime"] = false;
      // tempErrors["checkoutStatus"] = false;
      // tempErrors["checkoutNote"] = false;
      // tempErrors["authorizedBy"] = false;
      // tempErrors["dropOffType"] = false;
      // tempErrors["dropOffDateTime"] = false;
      // tempErrors["dropOffLocation"] = false;
      // tempErrors["selectedFiles"] = false;
      this.setState({
        data: {
          ...data,
          checkOutDateTime: {
            ...checkOutDateTime,
            actionDateTime: "",
          },
        },
        errors: tempErrors
      });
    }
  }

  handleToggleClearDateTime = (value) => {
    const { data } = this.state;
    this.setData(
      {
        ...data,
        isClear: value,
      });
  }

  handleChangeClearDateTimeNote = (value) => {
    const { data } = this.state;
    this.setData(
      {
        ...data,
        clearNote: value,
      });
  }

  handleChangeDropOffTime = async(e, newTz) => {
    const {timezone_ids} = this.props;
    const { data, errors } = this.state;
    let { dropOffDateTime = {}, checkOutDateTime = {}, dropOffType, dropOffLocation } = data;
    if (e) {
      const dropOffTz = getLabelValueObjectByValue(timezone_ids, dropOffDateTime.dropOffTimezone);
      const checkoutTz = getLabelValueObjectByValue(timezone_ids, checkOutDateTime.checkOutTimezone);
      const hasError = checkOutDateTime && checkOutDateTime.actionDateTime ? compareTimes(null, moment(e).format("YYYY-MM-DD HH:mm:ss"), true, moment(checkOutDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, dropOffTz, checkoutTz, "end") : false;
      if (hasError) {
        toast(
          (t) => (
            <ToastContent type="error" message={`Drop off time can't be less than check in and check out`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
      } else if (!filterPassedTimeForCheckinCheckoutDropOff(moment(e).format("YYYY-MM-DD HH:mm:ss"), newTz)) {
        toast(
          (t) => (
            <ToastContent type="error" message={`Drop off time cannot be later than today's date.`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
      } else {
        if (dropOffType === "hospital") {
          if (dropOffLocation && dropOffLocation.value) {
            // call hospital search api
            const options = await this.loadOptionsDropOff(dropOffLocation.label, dropOffType, dropOffDateTime ? {...dropOffDateTime, actionDateTime: e} : {actionDateTime: e});
            if (options && options.length && options.some(opt => opt.value == dropOffLocation.value)) {
              // no need to do anything
            } else {
              dropOffLocation = "";
            }

          }
        }
        this.setData(
          {
            ...data,
            dropOffDateTime: {
              ...dropOffDateTime,
              actionDateTime: e,
            },
            dropOffLocation
          },
          "dropOffDateTime"
        );
      }
    } else {
      let tempErrors = { ...errors };
      // tempErrors["checkInDateTime"] = false;
      // tempErrors["checkOutDateTime"] = false;
      // tempErrors["checkoutStatus"] = false;
      // tempErrors["checkoutNote"] = false;
      // tempErrors["authorizedBy"] = false;
      // tempErrors["dropOffType"] = false;
      // tempErrors["dropOffDateTime"] = false;
      // tempErrors["dropOffLocation"] = false;
      // tempErrors["selectedFiles"] = false;
      this.setState({
        data: {
          ...data,
          dropOffDateTime: {},
        },
        errors: tempErrors
      });
    }
  }

  onMenuClose1 = () => {
    const { parentAccountNumber = "", inputParentAccountNumber = "" } = this.state.data;
    this.setData({
      ...this.state.data,
      parentAccountNumber: parentAccountNumber,
      inputParentAccountNumber: inputParentAccountNumber,
    });
  };

  onMenuClose2 = () => {
    const { parentAccountName = "", inputParentAccountName = "" } = this.state.data;
    this.setData({
      ...this.state.data,
      parentAccountName: parentAccountName,
      inputParentAccountName: inputParentAccountName,
    });
  };

  updatePhlebInfo = (skipTzValidation = false) => {
    const {
      checkInDateTime = {},
      checkOutDateTime = {},
      dropOffDateTime = {},
      dropOffType,
      dropOffLocation = {},
      facilityId,
      id: orderId,
      phlebotomist,
      errors,
      selectedFiles = [],
      requisitions = [],
      facility_address = {},
      originalStatus,
      authorizedBy = "",
    } = this.state.data;
    let tempErrors = { ...errors };
    let actionData = [];
    let checkinCheckoutErrors = { ...errors };

    if (Object.keys(checkInDateTime).length > 0) {
      const { actionDateTime = "", checkInTimezone = "" } = checkInDateTime;
      if (actionDateTime && !checkInTimezone) {
        checkinCheckoutErrors["checkInTimezone"] = true;
      }
    }

    if (Object.keys(checkOutDateTime).length > 0) {
      const { actionDateTime = "", id, checkoutStatus = "", checkoutNote, checkOutTimezone } = checkOutDateTime;
      if (id || originalStatus == REDISPATCH_STATUS) {
        if (actionDateTime) {
          if (!checkOutTimezone) {
            errors["checkOutTimezone"] = true;
          }
        } else {
          if (!actionDateTime) {
            checkinCheckoutErrors["checkOutDateTime"] = true;
          }
          if (!checkOutTimezone) {
            errors["checkOutTimezone"] = true;
          }
        }
      } else {
        if (actionDateTime && checkoutStatus && checkoutStatus !== "SAMPLE_COLLECTED" && checkoutNote) {
          if (checkoutStatus === "CANCELLATION" && !authorizedBy) {
            checkinCheckoutErrors["authorizedBy"] = true;
          }
          if (!checkOutTimezone) {
            errors["checkOutTimezone"] = true;
          }
        } else {
          if (actionDateTime) {
            if (!checkOutTimezone) {
              errors["checkOutTimezone"] = true;
            }
            if (!checkoutStatus) {
              checkinCheckoutErrors["checkoutStatus"] = true;
            } else if (checkoutStatus === "CANCELLATION") {
              if (!checkoutNote) {
                checkinCheckoutErrors["checkoutNote"] = true;
              }
              if (!authorizedBy) {
                checkinCheckoutErrors["authorizedBy"] = true;
              }
            } else if (checkoutStatus !== "SAMPLE_COLLECTED" && checkoutStatus !== "CANCELLATION") {
              if (!checkoutNote) {
                checkinCheckoutErrors["checkoutNote"] = true;
              }
            }
          }
        }
      }
    }

    if (Object.keys(checkinCheckoutErrors).length) {
      this.setState({ errors: checkinCheckoutErrors });
      return { errors: checkinCheckoutErrors, actionData: [] };
    } else {
      if (Object.keys(checkInDateTime).length > 0 && checkInDateTime.actionDateTime) {
        const { actionDateTime = "", id, checkInTimezone = "" } = checkInDateTime;
        actionData.push({
          id,
          action_type_id: 1,
          action_datetime: actionDateTime ? moment(actionDateTime).format("YYYY-MM-DD HH:mm:ss") : "",
          hospital_id: null,
          lab_id: null,
          is_courier_drop_off: dropOffType === "courier",
          facility_id: facilityId || 0,
          latitude: id ? (checkInDateTime.latitudeName || "") : (facility_address.latitude || null),
          longitude: id ? (checkInDateTime.longitudeName || "") : (facility_address.longitude || null),
          order_id: orderId,
          user_id: phlebotomist ? Number(phlebotomist.value) || null : null,
          timezone_id: checkInTimezone || ""
        });
      }

      if (Object.keys(checkOutDateTime).length > 0 && checkOutDateTime.actionDateTime) {
        const { actionDateTime = "", id, checkoutStatus = "", checkoutNote, checkOutTimezone } = checkOutDateTime;
        actionData.push({
          id,
          action_type_id: 2,
          action_datetime: actionDateTime ? moment(actionDateTime).format("YYYY-MM-DD HH:mm:ss") : "",
          hospital_id: null,
          lab_id: null,
          is_courier_drop_off: dropOffType === "courier",
          facility_id: facilityId || 0,
          latitude: id ? (checkOutDateTime.latitudeName || "") : (facility_address.latitude || null),
          longitude: id ? (checkOutDateTime.longitudeName || "") : (facility_address.longitude || null),
          order_id: orderId,
          user_id: phlebotomist ? Number(phlebotomist.value) || null : null,
          is_cancel_order: checkoutStatus === "SAMPLE_COLLECTED" ? false : true,
          authorized_by: checkoutStatus === "CANCELLATION" ? authorizedBy : "",
          order_cancel_type: checkoutStatus === "SAMPLE_COLLECTED" ? "" : checkoutStatus,
          order_cancel_notes: checkoutNote,
          timezone_id: checkOutTimezone || ""
        });
      }
    }

    if (Object.keys(dropOffDateTime).length > 0 || dropOffType) {
      const { actionDateTime = "", id, dropOffTimezone = "" } = dropOffDateTime;
      if (!dropOffType) {
        tempErrors["dropOffType"] = true;
      } else if (!dropOffLocation || !dropOffLocation.value) {
        tempErrors["dropOffLocation"] = true;
      }
      if (!actionDateTime) {
        tempErrors["dropOffDateTime"] = true;
      }
      if (!dropOffTimezone) {
        tempErrors["dropOffTimezone"] = true;
      }
      // if (dropOffType && dropOffType === "hospital" && !selectedFiles.length && !requisitions.length) {
      //   tempErrors["selectedFiles"] = true;
      // }

      if (Object.keys(tempErrors).length) {
        this.setState({ errors: tempErrors });
        return { errors: tempErrors, actionData: [] };
      } else {
        if (Object.keys(dropOffDateTime).length > 0 && dropOffDateTime.actionDateTime) {
          actionData.push({
            id,
            action_type_id: 3,
            action_datetime: actionDateTime ? moment(actionDateTime).format("YYYY-MM-DD HH:mm:ss") : "",
            hospital_id: dropOffType === "hospital" ? dropOffLocation.value || null : null,
            lab_id: dropOffType === "lab" ? dropOffLocation.value || null : null,
            is_courier_drop_off: dropOffType === "courier",
            facility_id: facilityId,
            latitude:
              dropOffType === "courier"
                ? dropOffLocation.value
                  ? dropOffLocation.value.latitudeName || null
                  : null
                : dropOffLocation.latitude,
            longitude:
              dropOffType === "courier"
                ? dropOffLocation.value
                  ? dropOffLocation.value.longitudeName || null
                  : null
                : dropOffLocation.longitude,
            order_id: orderId,
            user_id: phlebotomist ? Number(phlebotomist.value) || null : null,
            timezone_id: dropOffTimezone || "",
            skip_timezone_validation: skipTzValidation
          });
        }
      }
    }
    return { errors: checkinCheckoutErrors, actionData }
  };

  loadOptionsDropOff = async (text, type, dropOffDateTime = {}) => {
    const data = {
      search_text: text,
    };
    if (dropOffDateTime && dropOffDateTime.actionDateTime) {
      data.effective_datetime = moment(dropOffDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss");
    }
    if (dropOffDateTime && dropOffDateTime.dropOffTimezone) {
      data.timezone_id = dropOffDateTime.dropOffTimezone;
    }
    const endPoint = type === "hospital" ? ahaApi.Account.hospitalsList : ahaApi.Account.labsList;
    const response = await endPoint(data)
      .then((res) => {
        const { content = [] } = res;
        return content.map((c) => {
          return {
            ...c,
            label: type === "hospital" ? c.hospital_name : c.lab_name,
            value: c.id,
          };
        });
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return response;
  };

  saveChanges = (isConfirmed = false, skipTzValidation = false) => {
    const { mode, data } = this.state;
    const {
      isClear = false,
      clearNote = "",
      id
    } = data;
    const { paramOrderId, navigate } = this.props;
    if (mode === "edit") {
      if (isClear) {
        if (!clearNote) {
          toast(
            (t) => (
              <ToastContent type="error" message={`Check if all required fields have been filled in before you ${mode === "edit" ? "update" : "create"} an order`} close={() => toast.remove()} />
            ),
            {
              duration: 4000,
            }
          );
          if (document.querySelector('.clear-date-time-class-error')) {
            document.querySelector('.clear-date-time-class-error').scrollIntoView({
              behavior: 'smooth'
            });
          }
        } else {
          const payload = {
            order_id: id,
            delete_notes: clearNote
          }
          this.props.handleClearDateTime(payload)
        }
      } else {
        this.handleBulkAction(isConfirmed, skipTzValidation)
      }
    } else {
      this.handleSubmit(isConfirmed, skipTzValidation)
    }
    if (paramOrderId) {
      navigate(ROUTINEREDRAW, { replace: true });
    }
  };

  handleChangeZone = async (value, splitZone) => {
    const { data } = this.state;
    let phlebRes = [];
    let assignedPhleb = 0;
    const userResponse = (await this.loadOptions3(splitZone)) || {};
    const { content = {} } = userResponse;
    let { system_assigned_user = {} } = content;

    phlebRes = this.fetchPhlebotomistOptions(content);

    assignedPhleb = system_assigned_user
      ? { label: system_assigned_user.name, value: system_assigned_user.id } || {
        label: "Hold For Dispatch",
        value: 0,
      }
      : { label: "Hold For Dispatch", value: 0 };
    this.setData(
      {
        ...data,
        phlebs: phlebRes,
        phlebotomist: assignedPhleb,
        zone: value,
      },
      "zone"
    );
  };

  handleFileUpload = (e) => {
    if (e.target.files) {
      let files = Array.prototype.slice.call(e.target.files);
      this.setData({ ...this.state.data, selectedFiles: files }, 'selectedFiles');
    }
  };

  navigateToCancelNote = (name) => {
    const { errors, authorizedBy = "" } = this.state;
    let tempErrors = { ...errors };
    // if ((!Object.keys(notes).some((noteId) => noteId == 3) ||
    // Object.keys(notes).some(
    //   (noteId) => noteId == 3 && (!notes[noteId] || !notes[noteId].text)
    // ))) {
    tempErrors[`notes-${name}`] = true;
    // }
    if (!authorizedBy) {
      tempErrors["authorizedBy"] = true;
    }
    // this.noteRef && this.noteRef.current && this.noteRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    this.setState({ errors: tempErrors, disableCancelledNote: false }, () => {
      this.scrollToRef(this.inputRefs[name]);
    });
  };

  scrollToRef = (ref) => ref && ref.current && ref.current.scrollIntoView({ behavior: "smooth" });

  navigateToRespectiveNote = (id, notes = []) => {
    let name;
    if (notes) {
      notes.forEach(n => {
        if (n.label === id) {
          name = n.value;
        }
      })
    }
    name && this.scrollToRef(this.inputRefs[name]);
  };

  downloadFile = async (fileName) => {
    let sessionTokenTime =
      (window.localStorage.getItem("userData") &&
        JSON.parse(window.localStorage.getItem("userData")).session_token_expiration) || "";

    if (sessionTokenTime && Date.parse(new Date(sessionTokenTime)) <= Date.parse(new Date())) {
      const creds = await refreshToken();
      console.log(creds, 'credscreds');
      await this.getPresignedUrl(this.state.fileModal, creds, true);
      return;
    }

    var el = document.createElement("a");
    el.setAttribute("href", fileName);
    el.setAttribute("download", fileName);
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  addDefaultSrc = (ev) => {
    ev.target.src = ErrorImage;
  };

  onBlurParentAccountNumber = async (e) => {
    const { data: stateData, enableRegion,
      enableTerr,
      enableZone, showIndicator } = this.state;
    let {
      childAccountNumber = "",
      childAccountName = "",
      address1 = "",
      city = "",
      stateName = "",
      zip = "",
      hl7 = false,
      region = "",
      territory = "",
      zone = "",
      facilityId = 0,
      inputParentAccountNumber = "",
      id
    } = stateData;

    if (!showIndicator) {
      const text = inputParentAccountNumber;
      let paramString = `?account_number=${text}`;
      if (id) {
        paramString = `${paramString.length > 0 ? `${paramString}&` : ""}order_id=${id}`;
      }
      let response = (await ahaApi.Account.parentAccountNumber(`${paramString}`)
        .then((res) => {
          const { content = [] } = res;
          return content.map((c) => {
            return { label: c.account_number, value: c };
          });
        })
        .catch((err) => {
          console.log(err, "error");
        })) || [];

      response = response.filter((res) => res.label === text);

      if (!response || !response.length) {
        this.setState({
          data: {
            ...stateData,
            parentAccountNumber: "",
            parentAccountName: "",
            childAccountNumber: hl7 ? childAccountNumber : "",
            childAccountName: hl7 ? childAccountName : "",
            // inputParentAccountNumber: "",
            inputParentAccountName: "",
            address1: hl7 ? address1 : "",
            city: hl7 ? city : "",
            stateName: hl7 ? stateName : "",
            zip: hl7 ? zip : "",
            region: hl7 ? region : null,
            territory: hl7 ? territory : "",
            zone: hl7 ? zone : "",
            facilityId: hl7 ? facilityId : 0,
            phlebotomist: { label: "Hold For Dispatch", value: 0 },
            status: UNASSIGNED_STATUS,
            originalStatusText: "Unassigned",
          },
          enableRegion: hl7 ? enableRegion : true,
          enableTerr: hl7 ? enableTerr : true,
          enableZone: hl7 ? enableZone : true,
          showIndicator: true,
          menuIsOpen: false,
        });
      } else {
        this.setState({ showIndicator: false, menuIsOpen: false });
      }
    }
    this.setState({ menuIsOpen: false });
  };

  onBlurParentAccountName = async (e) => {
    const { data: stateData, enableRegion,
      enableTerr,
      enableZone, showIndicator } = this.state;
    let {
      childAccountNumber = "",
      childAccountName = "",
      address1 = "",
      city = "",
      stateName = "",
      zip = "",
      hl7 = false,
      region = "",
      territory = "",
      zone = "",
      facilityId = 0,
      inputParentAccountName = "",
      id
    } = stateData;

    if (!showIndicator) {
      const text = inputParentAccountName;
      let paramString = `?account_name=${text}`;
      if (id) {
        paramString = `${paramString.length > 0 ? `${paramString}&` : ""}order_id=${id}`;
      }
      let response = (await ahaApi.Account.parentAccountName(paramString)
        .then((res) => {
          const { content = [] } = res;
          return content.map((c) => {
            return { label: c.account_name, value: c };
          });
        })
        .catch((err) => {
          console.log(err, "error");
        })) || [];

      response = response.filter((res) => res.label === text);

      if (!response || !response.length) {
        this.setState({
          data: {
            ...stateData,
            parentAccountNumber: "",
            parentAccountName: "",
            childAccountNumber: hl7 ? childAccountNumber : "",
            childAccountName: hl7 ? childAccountName : "",
            inputParentAccountNumber: "",
            // inputParentAccountName: "",
            address1: hl7 ? address1 : "",
            city: hl7 ? city : "",
            stateName: hl7 ? stateName : "",
            zip: hl7 ? zip : "",
            region: hl7 ? region : null,
            territory: hl7 ? territory : "",
            zone: hl7 ? zone : "",
            facilityId: hl7 ? facilityId : 0,
            phlebotomist: { label: "Hold For Dispatch", value: 0 },
            status: UNASSIGNED_STATUS,
            originalStatusText: "Unassigned",
          },
          enableRegion: hl7 ? enableRegion : true,
          enableTerr: hl7 ? enableTerr : true,
          enableZone: hl7 ? enableZone : true,
          showIndicator: true,
          menuIsOpenParentName: false,
        });
      } else {
        this.setState({ showIndicator: false, menuIsOpenParentName: false });
      }
    }
    this.setState({ menuIsOpenParentName: false });
  };

  handleChangeOrderType = async (e) => {
    const { setData } = this;
    const { data, errors } = this.state;
    const { zone, collectionTime } = data;
    let tempErrors = { ...errors };
    let userResponse;

    if (zone) {
      const splitZone = zone.split(":")[0];
      if (e.target.value == 2 && collectionTime) {
        userResponse =
          (await this.loadOptions3(splitZone, moment(collectionTime).format("YYYY-MM-DD HH:mm:ss"))) || {};
      } else if (e.target.value != 2) {
        userResponse = (await this.loadOptions3(splitZone)) || {};
      }
    }

    if (userResponse && userResponse.content) {
      let phlebRes = [];
      let assignedPhleb = { label: "Hold For Dispatch", value: 0 };
      const { content = {} } = userResponse;
      let { system_assigned_user = {} } = content;

      phlebRes = this.fetchPhlebotomistOptions(content);

      assignedPhleb = system_assigned_user
        ? { label: system_assigned_user.name, value: system_assigned_user.id } || {
          label: "Hold For Dispatch",
          value: 0,
        }
        : { label: "Hold For Dispatch", value: 0 };

      tempErrors["orderType"] = false;
      this.setState({
        data: {
          ...data,
          orderType: String(e.target.value),
          phlebs: phlebRes,
          phlebotomist: assignedPhleb,
          status: system_assigned_user ? UNACKNOWLEDGED_STATUS : data.status || UNASSIGNED_STATUS,
          originalStatusText: system_assigned_user ? "Unacknowledged" : data.originalStatusText || "Unassigned",
        },
        errors: tempErrors
      });
    } else {
      setData(
        {
          ...data,
          orderType: String(e.target.value),
        },
        "orderType"
      );
    }
  };

  handleChangeCollectionTime = async (e) => {
    let newDate = e;
    let currentDate = new Date();

    const { setData } = this;
    const { data, errors } = this.state;
    const { zone } = data;
    let tempErrors = { ...errors };

    let userResponse;

    if (zone && currentDate.getTime() <= newDate.getTime()) {
      const splitZone = zone.split(":")[0];
      userResponse = (await this.loadOptions3(splitZone, moment(e).format("YYYY-MM-DD HH:mm:ss"))) || {};
    }

    if (userResponse && userResponse.content) {
      let phlebRes = [];
      let assignedPhleb = { label: "Hold For Dispatch", value: 0 };
      const { content = {} } = userResponse;
      let { system_assigned_user = {} } = content;

      phlebRes = this.fetchPhlebotomistOptions(content);

      assignedPhleb = system_assigned_user
        ? { label: system_assigned_user.name, value: system_assigned_user.id } || {
          label: "Hold For Dispatch",
          value: 0,
        }
        : { label: "Hold For Dispatch", value: 0 };

      tempErrors["collectionTime"] = false;
      this.setState({
        data: {
          ...data,
          collectionTime: currentDate.getTime() <= newDate.getTime() ? e : null,
          phlebs: phlebRes,
          phlebotomist: assignedPhleb,
          status: system_assigned_user ? UNACKNOWLEDGED_STATUS : data.status || UNASSIGNED_STATUS,
          originalStatusText: system_assigned_user ? "Unacknowledged" : data.originalStatusText || "Unassigned",
        },
        errors: tempErrors,
      });
    } else {
      let newDate = e;
      let currentDate = new Date();
      if (currentDate.getTime() <= newDate.getTime()) setData({ ...data, collectionTime: e }, "collectionTime");
      else setData({ ...data, collectionTime: null }, "collectionTime");
    }
  };

  getPresignedUrl = async (file, creds, reDownload = false) => {
    const myBucket = file.bucket_name;
    const myKey = file.file_url;
    const signedUrlExpireSeconds = 60 * 60 * 24;

    let s3 = new AWS.S3({
      accessKeyId: creds.accessKeyId,
      signatureVersion: 'v4',
      region: 'us-east-1',
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken
    });

    try {
      if (s3) {
        let sessionTokenTime =
          (window.localStorage.getItem("userData") &&
            JSON.parse(window.localStorage.getItem("userData")).session_token_expiration) || "";

        if (sessionTokenTime && Date.parse(new Date(sessionTokenTime)) <= Date.parse(new Date())) {
          const creds = await refreshToken();
          await this.getPresignedUrl(file, creds);
        } else {
          console.log(s3, 'iii2');
          const url = s3.getSignedUrl('getObject', {
            Bucket: myBucket,
            Key: myKey,
            Expires: signedUrlExpireSeconds
          });
          if (!reDownload) {
            this.setState({
              fileModal: { ...file, show: true },
            }, () => {
              const ele = document.getElementById("order-image")
              ele.src = url;
              this.setState({
                fileModal: { ...file, show: true, url },
              });
            })
          } else {
            var el = document.createElement("a");
            el.setAttribute("href", url);
            el.setAttribute("download", url);
            document.body.appendChild(el);
            el.click();
            el.remove();
          }
        }

      }
    } catch (err) {
      console.log(err, err.message, s3, '1112');
      const creds = await refreshToken();
      console.log(creds, 'credscreds2');
      await this.getPresignedUrl(this.state.fileModal, creds);
    }
  }

  viewAttachment = (file) => {
    let userData = window.localStorage.getItem("userData") || "";
    userData = userData ? JSON.parse(userData) : {};
    const { access_key_id, secret_key, session_token } = userData;

    const ACCESS_KEY_ID = access_key_id;
    const SECRET_ACCESS_KEY = secret_key;
    const SESSION_TOKEN = session_token;

    this.getPresignedUrl(file, { accessKeyId: ACCESS_KEY_ID, secretAccessKey: SECRET_ACCESS_KEY, sessionToken: SESSION_TOKEN });

  }

  handleChangeRegion = (e) => {
    const { data } = this.state;
    this.setData(
      {
        ...data,
        region: e,
        territory: "",
      }, "region"
    );
  };

  handleGetTestCodes = async (region) => {
    const { CURRENT_ORDER_TYPE, testCodes = [], testDescriptions = [] } = this.state;
    // get test codes
    let codes = testCodes;
    let descriptions = testDescriptions;

    const testCodePayload = {
      order_type_id: CURRENT_ORDER_TYPE.value,
      region_id: region || ""
    };
    const testCodeResponse = (await this.props.loadTestCodes(testCodePayload)) || {};
    const { content: content2 = [] } = testCodeResponse;
    if (content2) {
      codes = content2.map(c => { return { label: c.code, value: c.id } });
      descriptions = content2.map(c => { return { label: c.name, value: c.id } });
    }
    return { codes, descriptions }
  }

  handleChangeTestCode = (value, i, ele, testDescriptions) => {
    const { errors = {}, data, testList = [
      {
        testCode: "",
        name: "",
        collectionDate: !data.hl7 ? new Date() : "",
        orderCollectionMethod: "",
      },
    ], } = this.state;
    const {
      hl7 = false,
    } = data;
    let tempErrors = { ...errors };
    tempErrors[`testList-testCode-${i}`] = false;
    tempErrors[`testList-name-${i}`] = false;
    const tempList = [...testList];
    tempList[i] = {
      ...tempList[i],
      isHL7: value ? value.isHL7 || false : false,
      testCode: value,
      name: value
        ? value.value
          ? {
            label: getLabelByValue(
              hl7 && ele.test_code_name
                ? [
                  { label: ele.test_code_name, value: ele.id, isHL7: true },
                  ...testDescriptions,
                ]
                : testDescriptions,
              value.value || ""
            ),
            value: value.value,
          }
          : ""
        : "",
    };
    this.setState({
      testList: tempList,
      errors: tempErrors,
    });
  };

  handleChangeUserType = (e, type) => {
    this.setState({
      userType: {
        ...this.state.userType,
        [type.label]: e.target.checked
      }
    });
  }

  fetchPhlebotomistOptions = (content) => {
    let phlebRes = [];
    let { all_users = [], on_call_users = [], courier_users = [] } = content || {};

    on_call_users = on_call_users ? on_call_users.sort((a, b) => a.name.localeCompare(b.name)) : [];
    all_users = all_users ? all_users.sort((a, b) => a.name.localeCompare(b.name)) : [];
    courier_users = courier_users ? courier_users.sort((a, b) => a.name.localeCompare(b.name)) : [];


    if (on_call_users && on_call_users.length) {
      on_call_users = on_call_users
        ? on_call_users.map((c) => {
          return { label: c.name, value: c.id, type: "On Call" };
        })
        : [];

      phlebRes = [
        { label: "On Call", options: [...on_call_users] },
      ]
    }

    if (courier_users && courier_users.length) {
      courier_users = courier_users
        ? courier_users.map((c) => {
          return { label: c.name, value: c.id, type: "Courier" };
        })
        : [];

      phlebRes = [...phlebRes,
      { label: "Courier", options: [...courier_users] },
      ]
    }

    if (all_users && all_users.length) {
      all_users = all_users
        ? all_users.map((c) => {
          return { label: c.name, value: c.id, type: "Others" };
        })
        : [];

      phlebRes = [...phlebRes,
      { label: "Others", options: [{ label: "Hold For Dispatch", value: 0, type: "Others" }, ...all_users] },
      ]
    }
    return phlebRes;
  }
  handleChangeDateTimezone = async(e, parent, property) => {
    const {timezone_ids} = this.props;
    const { data, errors } = this.state;
    let { checkInDateTime = {},
    checkOutDateTime = {}, dropOffDateTime = {}, dropOffType, dropOffLocation} = data;
    if (parent === "checkInDateTime" && checkOutDateTime && checkOutDateTime.checkOutTimezone) {
    const checkinTz = getLabelValueObjectByValue(timezone_ids, e.itemData.value);
      const checkoutTz = getLabelValueObjectByValue(timezone_ids, checkOutDateTime.checkOutTimezone);
      const hasError = checkOutDateTime && checkOutDateTime.actionDateTime && checkInDateTime && checkInDateTime.actionDateTime ? compareTimes(null, moment(checkInDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, moment(checkOutDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, checkinTz, checkoutTz, "start") : false;
  
      if (hasError) {
        toast(
          (t) => (
            <ToastContent type="error" message={`Check in time can't be greater than check out`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
        e.cancel = true;
        return;
      }
    } else if (parent === "checkOutDateTime") {
      const checkinTz = getLabelValueObjectByValue(timezone_ids, checkInDateTime.checkInTimezone);
      const checkoutTz = getLabelValueObjectByValue(timezone_ids, e.itemData.value);
      const dropOffTz = getLabelValueObjectByValue(timezone_ids, dropOffDateTime.dropOffTimezone);
  
      const hasCheckoutError = checkInDateTime && checkInDateTime.actionDateTime && checkOutDateTime && checkOutDateTime.actionDateTime ? compareTimes(null, moment(checkOutDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, moment(checkInDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, checkoutTz, checkinTz, "end") : false;
      const hasDropOffError = dropOffDateTime && dropOffDateTime.actionDateTime && checkOutDateTime && checkOutDateTime.actionDateTime ? compareTimes(null, moment(checkOutDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, moment(dropOffDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, dropOffTz, checkoutTz, "start") : false;
  
      if (hasCheckoutError) {
        toast(
          (t) => (
            <ToastContent type="error" message={`Check in time can't be greater than check out`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
        e.cancel = true;
        return;
      } else if (hasDropOffError) {
        toast(
          (t) => (
            <ToastContent type="error" message={`Check out time can't be greater than drop off`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
        e.cancel = true;
        return;
      }
    } else if (parent === "dropOffDateTime") {
    const checkoutTz = getLabelValueObjectByValue(timezone_ids, checkOutDateTime.checkOutTimezone);
    const dropOffTz = getLabelValueObjectByValue(timezone_ids, e.itemData.value);

    const hasDropOffError = dropOffDateTime && dropOffDateTime.actionDateTime && checkOutDateTime && checkOutDateTime.actionDateTime ? compareTimes(null, moment(dropOffDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, moment(checkOutDateTime.actionDateTime).format("YYYY-MM-DD HH:mm:ss"), true, dropOffTz, checkoutTz, "end") : false;

    if (hasDropOffError) {
      toast(
        (t) => (
          <ToastContent type="error" message={`Drop off time can't be lesser than check out`} close={() => toast.remove()} />
        ),
        {
          duration: 4000,
        }
      );
      e.cancel = true;
      return;
    }
  }

  const dateTime = parent === "checkInDateTime" ? (checkInDateTime ? checkInDateTime.actionDateTime || "" : "") : parent === "checkOutDateTime" ? (checkOutDateTime ? checkOutDateTime.actionDateTime || "" : "") : (dropOffDateTime ? dropOffDateTime.actionDateTime || "" : "");
  const errorMsg = parent === "checkInDateTime" ? `Check in time cannot be later than today's date.` : parent === "checkOutDateTime" ? `Check out time cannot be later than today's date.` : `Drop off time cannot be later than today's date.`;
  if (dateTime && !filterPassedTimeForCheckinCheckoutDropOff(moment(dateTime).format("YYYY-MM-DD HH:mm:ss"), e.itemData.timezone_code)) {
    toast(
      (t) => (
        <ToastContent type="error" message={errorMsg} close={() => toast.remove()} />
      ),
      {
        duration: 4000,
      }
    );
    e.cancel = true;
    return;
  }

  if (parent === "dropOffDateTime" && dropOffType === "hospital") {
    if (dropOffLocation && dropOffLocation.value) {
      // call hospital search api
      const options = await this.loadOptionsDropOff(dropOffLocation.label, dropOffType, dropOffDateTime ? {...dropOffDateTime, dropOffTimezone: e.itemData.value} : {dropOffTimezone: e.itemData.value});
      if (options && options.length && options.some(opt => opt.value == dropOffLocation.value)) {
        // no need to do anything
      } else {
        dropOffLocation = "";
      }

    }
  }
  let newData ={
    ...data,
    [parent]: {
      ...data[parent],
      [property]: e.itemData.value,
    },
    dropOffLocation
  }
  let tempErrors = { ...errors };
  tempErrors[property] = false;
  this.setState({
    data: newData,
    errors: tempErrors
  })
  }

  showConfirmationModal = () => {
    this.setState({
      statusModalType: {
        value: true,
        status: "confirmation",
        type: TYPE_ROUTINE,
        title: <div>
        <div>Are you sure you want to cancel?</div>
        <div className="cancel-text-popup">All unsaved data will be lost</div>
      </div>,
      subtitle: "This action cannot be undone."
      },
    });
  }

  continueSaving = () => {
    const { testCodeWarning } = this.state;
    this.setState({
      statusModalType: { value: false }
    }, () => {
      const isConfirmed = testCodeWarning.show && testCodeWarning.removedTestCodes && testCodeWarning.removedTestCodes.length;
      this.saveChanges(isConfirmed, true);
    }
    )
  }

  handleChangeDropOffLocation = async(e) => {
    const { data } = this.state;
    const { dropOffDateTime = {}, dropOffType } = data;
    let newLocation = e;

    let newData = {
      ...data
    }

    if (e && e.timezone && e.timezone.timezone_id && dropOffType !== "hospital") {
      newData.dropOffDateTime = newData.dropOffDateTime ? { ...newData.dropOffDateTime, dropOffTimezone: e.timezone.timezone_id } : { dropOffTimezone: e.timezone.timezone_id };

      // if (dropOffType === "hospital") {
      //   if (e && e.value) {
      //     // call hospital search api
      //     const options = await this.loadOptionsDropOff(e.label, dropOffType, dropOffDateTime ? {...dropOffDateTime, dropOffTimezone: e.timezone.timezone_id} : {dropOffTimezone: e.timezone.timezone_id});
      //     if (options && options.length && options.some(opt => opt.value == e.value)) {
      //       // no need to do anything
      //     } else {
      //       newLocation = "";
      //     }

      //   }
      // }
    }

    newData.dropOffLocation = newLocation;

    this.setData(newData, "dropOffLocation");
  }

  render() {
    let {
      show,
      order_status = [],
      genders = [],
      regions = [],
      territories = [],
      zones = [],
      test_code = [],
      order_collection_method = [],
      order_creation_method = [],
      order_frequency = [],
      order_type = [],
      note_type = [],
      error = "",
      updateRoutineRedraw,
      routineRedrawData = {},
      checkout_status = [],
      submitAcknowledgePartialOrder,
      submitForceAcknowledgeOrder,
      acknowledgePartialOrderLoader,
      forceAcknowledgeOrderLoader,
      canDeleteCheckinCheckout,
      canCancelStatOrder,
      timezone_ids
    } = this.props;

    const { audit_history = [] } = routineRedrawData;

    const userDetail = window.localStorage.getItem("userDetail");
    const fullName = JSON.parse(userDetail);

    const {
      errors,
      data,
      mode,
      showIndicator,
      enableRegion,
      enableTerr,
      enableZone,
      loading,
      fileModal,
      statusModalType,
      disableCancelledNote,
      menuIsOpen,
      menuIsOpenParentName,
      testCodes = [],
      testDescriptions = [],
      testList = [
        {
          testCode: "",
          name: "",
          collectionDate: !data.hl7 ? new Date() : "",
          orderCollectionMethod: "",
        },
      ],
      isFormChanged,
      originalTestList,
      testCodeWarning,
      userType = {}
    } = this.state;

    const {
      setData,
      handleSubmit,
      onFocus,
      addTestList,
      removeTestList,
      handleParentAccountNumber,
      handleParentAccountNameChange,
      handlePhlebotomistChange,
      loadOptions1,
      loadOptions2,
      loadOptions3,
      loadOptions5,
      handleClose,
      onMenuClose1,
      onMenuClose2,
      updatePhlebInfo,
      loadOptionsDropOff,
      handleChangeZone,
      handleFileUpload,
      navigateToCancelNote,
      downloadFile,
      onBlurParentAccountNumber,
      onBlurParentAccountName,
      handleChangeOrderType,
      navigateToRespectiveNote,
      handleToggleClearDateTime,
      handleChangeClearDateTimeNote,
      continueSaving
    } = this;

    let {
      parentAccountNumber = "",
      parentAccountName = "",
      childAccountNumber = "",
      childAccountName = "",
      address1 = "",
      address2 = "",
      city = "",
      stateName = "",
      zip = "",
      latitudeName,
      longitudeName,
      createDateTime,
      createDateTimeTimezone = {},
      preferredCreateDateTime,
      preferredCreateDateTimeTimezone = {},
      status = Number(UNASSIGNED_STATUS),
      orderType = 4,
      collectionTime = null,
      orderFrequency = Number(1),
      creationMethod = Number(4),
      createdBy = "",
      region = "",
      territory = "",
      zone = "",
      originalZone = "",
      phlebotomist = { label: "Hold For Dispatch", value: 0 },
      firstName = "",
      middleName = "",
      lastName = "",
      gender = "",
      patientDob = "",
      roomBedWingFloor = "",
      orderingPhysician = "",
      checkInDateTime = {},
      checkOutDateTime = {},
      dropOffType = "",
      dropOffLocation = "",
      dropOffDateTime = {},
      notes = {},
      orderNumber = "",
      inputParentAccountNumber = "",
      inputParentAccountName = "",
      phlebs = [],
      originalCheckOut = {},
      originalCheckIn = {},
      originalDropOff = {},
      originalStatus = "",
      originalStatusText = "Unassigned",
      phleb_activity_informations = [],
      requisitions = [],
      facilityId = 0,
      hl7 = false,
      id,
      authorizedBy = "",
      notify = false,
      viewAllAudit = false,
      user = {},
      isClear = false,
      clearNote = ""
    } = data;

    if (mode === "create") {
      createdBy = _.isEmpty(createdBy) ? (fullName ? fullName.full_name : "") : createdBy;
    }

    territories = territories.filter((terr) => region && terr.region_id == region.value);
    zones = territory
      ? zones.filter(
        (val) => (territory.toString().includes(":") ? territory.split(":")[0] : territory) == val.territory_id
      )
      : [];

    if (originalZone && originalZone.value && !zones.some(z => z.id == originalZone.value)) {
      zones = [originalZone, ...zones];
    }

    let todaysDate = new Date();
    todaysDate.setDate(todaysDate.getDate() - 1);

    let hasMoreCheckinCheckoutInRedisptach = false;
    let checkinCount = 0;
    let checkoutCount = 0;
    phleb_activity_informations &&
      phleb_activity_informations.forEach((act) => {
        if (act.action_type_id === 1) {
          checkinCount++;
        }
        if (act.action_type_id === 2) {
          checkoutCount++;
        }
      });

    if (checkinCount > 1 || checkoutCount > 1) {
      hasMoreCheckinCheckoutInRedisptach = true;
    }

    const deviceTimezone = moment.tz.guess(true);
    let newTz = deviceTimezone;
    let newDropOffTz = deviceTimezone;
    let newCheckoutTz = deviceTimezone;

    if (checkInDateTime && checkInDateTime.checkInTimezone) {
      const tz = getLabelValueObjectByValue(timezone_ids, checkInDateTime.checkInTimezone);
      newTz = tz && tz.timezone_code ? tz.timezone_code : deviceTimezone;
    }
    if (checkOutDateTime && checkOutDateTime.checkOutTimezone) {
      const tz = getLabelValueObjectByValue(timezone_ids, checkOutDateTime.checkOutTimezone);
      newCheckoutTz = tz && tz.timezone_code ? tz.timezone_code : deviceTimezone;
    }

    const checkinNow = moment().tz(newTz);
    const checkoutNow = moment().tz(newCheckoutTz);

    if (dropOffDateTime && dropOffDateTime.dropOffTimezone) {
      const tz = getLabelValueObjectByValue(timezone_ids, dropOffDateTime.dropOffTimezone);
      newDropOffTz = tz && tz.timezone_code ? tz.timezone_code : deviceTimezone;
    }
    const dropOffNow = moment().tz(newDropOffTz);
    return (
      <>
        <Modal
          id="create-edit-routine-modal"
          show={show}
          onHide={() =>
            mode === "view"
              ? handleClose()
              : this.showConfirmationModal()
          }
          className="ad-adduser-modal create-order-modal"
          backdrop="static"
          centered
          enforceFocus={false}
          data-testid="modal-unique"
        >
          <div className={"ad-loading-wrapper " + (loading ? "d-block" : "d-none")}>
            <span className={"loader ad-loader " + (loading ? "d-block" : "d-none")}>
              <span className="visually-hidden">loader icon</span>
            </span>
          </div>
          <Modal.Header closeButton>
            <React.Fragment>
              {mode === "view" ? (
                <Modal.Title className="headline4">View Routine Redraw Order</Modal.Title>
              ) : mode === "create" ? (
                <React.Fragment>
                  <Modal.Title className="headline4"> Create Routine Redraw Order</Modal.Title>
                  {error && <p className="error-message-alert">{error}</p>}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Modal.Title className="headline4"> Edit Routine Redraw Order</Modal.Title>
                  {error && <p className="error-message-alert">{error}</p>}
                </React.Fragment>
              )}
            </React.Fragment>
          </Modal.Header>
          <Modal.Body>
            <form className="aha-modalform-wrapper mt-0 align-items-start">
              <div className="aha-modalform-group ">
                <label className="caption" htmlFor="routine-order-number">
                  Order #
                </label>
                <input
                  id="routine-order-number"
                  type="text"
                  data-testid="field-order-number"
                  value={orderNumber}
                  placeholder={mode === "create" ? "Auto Generated" : ""}
                  disabled
                />
              </div>
              {(inputParentAccountNumber || inputParentAccountName) && showIndicator && (
                <div className="error-message missing">
                  <p className="error-message-text">Missing parent account information - manually entered</p>
                </div>
              )}
              {mode !== "create" && (
                <div className="d-flex st-cancel-ack-wrapper">
                  {mode === "edit" ? (
                    status == CANCELLED_STATUS ? (
                      <span data-testid="button-edit" className="cancelOrder-btn">
                        Cancelled Order
                      </span>
                    ) : canCancelStatOrder && originalStatus != DROPPED_OFF_STATUS && originalStatus != NOTIFIED_CLIENT_STATUS && (
                      <span
                        data-testid="button-edit"
                        className="cancelOrder-btn"
                        onClick={() => navigateToCancelNote(3)}
                      >
                        Cancel Order
                      </span>
                    )
                  ) : null}
                  {((originalStatus == CHECK_OUT_STATUS && mode === "edit") || (originalStatus == DROPPED_OFF_STATUS && mode === "edit")) &&
                    originalStatusText === "Partial-Completion" && (
                      <span
                        data-testid="button-edit"
                        className="cancelOrder-btn ack-order-btn"
                        onClick={() => submitAcknowledgePartialOrder(id)}
                        style={acknowledgePartialOrderLoader ? { pointerEvents: "none" } : {}}
                      >
                        Acknowledge Reorder
                      </span>
                    )}
                  {(originalStatus == UNACKNOWLEDGED_STATUS && mode === "edit") && (
                    <span
                      data-testid="button-edit-force-acknowledge"
                      className="cancelOrder-btn ack-order-btn force-ack-order-btn"
                      onClick={() => submitForceAcknowledgeOrder(id)}
                      style={forceAcknowledgeOrderLoader ? { pointerEvents: "none" } : {}}
                    >
                      Force Acknowledge
                    </span>
                  )}
                </div>
              )}
            </form>
            <p className="headline6">Order Information</p>
            <form className="aha-modalform-wrapper ">
              <div
                className={
                  "aha-modalform-group " +
                  (errors.parentAccountNumber || errors.inputParentAccountNumber ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="routine-parent-account-number">
                  Parent Account Number {hl7 ? "(Optional)" : ""}
                </label>
                <AsyncSelect
                  cacheOptions
                  isSearchable
                  // isLoading={false}
                  isClearable
                  name=""
                  loadOptions={loadOptions1}
                  className="sch-schedule-dropdown"
                  classNamePrefix="sch-schedule-select"
                  id="routine-parent-account-number"
                  data-testid="field-parent-account-number"
                  placeholder={mode === "view" ? "" : "Parent Account Number"}
                  onChange={(e) => {
                    handleParentAccountNumber(e);
                  }}
                  value={parentAccountNumber}
                  inputValue={inputParentAccountNumber}
                  onInputChange={(inputValue, { action }) => {
                    if (action === "input-change") {
                      this.setState({
                        data: {
                          ...data,
                          parentAccountName: inputValue ? parentAccountName : "",
                          parentAccountNumber: inputValue ? parentAccountNumber : "",
                          inputParentAccountNumber: inputValue,
                          inputParentAccountName: inputValue ? inputParentAccountName : "",
                          address1: hl7 || inputValue ? address1 : "",
                          city: hl7 || inputValue ? city : "",
                          stateName: hl7 || inputValue ? stateName : "",
                          zip: hl7 || inputValue ? zip : "",
                          region: hl7 || inputValue ? region : null,
                          territory: hl7 || inputValue ? territory : "",
                          zone: hl7 || inputValue ? zone : "",
                          facilityId: hl7 || inputValue ? facilityId : 0,
                          phlebotomist: inputValue ? phlebotomist : { label: "Hold For Dispatch", value: 0 },
                          status: inputValue ? status : UNASSIGNED_STATUS,
                          originalStatusText: inputValue ? originalStatusText : "Unassigned",
                          // childAccountNumber: inputValue
                          childAccountNumber: hl7 || inputValue ? childAccountNumber : "",
                          childAccountName: hl7 || inputValue ? childAccountName : "",
                        },
                        enableRegion: hl7 || inputValue ? enableRegion : true,
                        enableTerr: hl7 || inputValue ? enableTerr : true,
                        enableZone: hl7 || inputValue ? enableZone : true,
                      });
                      return inputValue;
                    }
                    return inputParentAccountNumber;
                  }}
                  isDisabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  onMenuClose={onMenuClose1}
                  onMenuOpen={() => this.setState({ menuIsOpen: true })}
                  menuIsOpen={menuIsOpen}
                  onBlur={(e) => onBlurParentAccountNumber(e)}
                />
                {(errors.parentAccountNumber || errors.inputParentAccountNumber) && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " +
                  (errors.parentAccountName || errors.inputParentAccountName ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="routine-parent-account-name">
                  Parent Account Name {hl7 ? "(Optional)" : ""}
                </label>
                <AsyncSelect
                  cacheOptions
                  isSearchable
                  isClearable
                  name=""
                  loadOptions={loadOptions2}
                  className="sch-schedule-dropdown"
                  classNamePrefix="sch-schedule-select"
                  id="routine-parent-account-name"
                  data-testid="field-parent-account-name"
                  placeholder={mode === "view" ? "" : "Parent Account Name"}
                  onChange={(e) => {
                    handleParentAccountNameChange(e);
                  }}
                  value={parentAccountName}
                  inputValue={inputParentAccountName}
                  onInputChange={(inputValue, { action }) => {
                    if (action === "input-change") {
                      this.setState({
                        data: {
                          ...data,
                          parentAccountNumber: inputValue ? parentAccountNumber : "",
                          inputParentAccountName: inputValue,
                          inputParentAccountNumber: inputValue ? inputParentAccountNumber : "",
                          parentAccountName: !inputValue ? "" : parentAccountName,
                          address1: hl7 || inputValue ? address1 : "",
                          city: hl7 || inputValue ? city : "",
                          stateName: hl7 || inputValue ? stateName : "",
                          zip: hl7 || inputValue ? zip : "",
                          region: hl7 || inputValue ? region : null,
                          territory: hl7 || inputValue ? territory : "",
                          zone: hl7 || inputValue ? zone : "",
                          facilityId: hl7 || inputValue ? facilityId : 0,
                          phlebotomist: inputValue ? phlebotomist : { label: "Hold For Dispatch", value: 0 },
                          status: inputValue ? status : UNASSIGNED_STATUS,
                          originalStatusText: inputValue ? originalStatusText : "Unassigned",
                          // childAccountName: inputValue
                          childAccountNumber: hl7 || inputValue ? childAccountNumber : "",
                          childAccountName: hl7 || inputValue ? childAccountName : "",
                        },
                        enableRegion: hl7 || inputValue ? enableRegion : true,
                        enableTerr: hl7 || inputValue ? enableTerr : true,
                        enableZone: hl7 || inputValue ? enableZone : true,
                      });
                      return inputValue;
                    }
                    return inputParentAccountName;
                  }}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  isDisabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                  onMenuClose={onMenuClose2}
                  onMenuOpen={() => this.setState({ menuIsOpenParentName: true })}
                  menuIsOpen={menuIsOpenParentName}
                  onBlur={(e) => onBlurParentAccountName(e)}
                />
                {(errors.parentAccountName || errors.inputParentAccountName) && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.childAccountNumber ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-child-account-number">
                  Child Account Number {hl7 ? "(Optional)" : ""}
                </label>
                <input
                  id="routine-child-account-number"
                  type="text"
                  placeholder={mode === "view" ? "" : "Child Account Number"}
                  value={childAccountNumber}
                  onChange={(e) => {
                    setData({ ...data, childAccountNumber: e.target.value }, "childAccountNumber");
                  }}
                  data-testid="field-child-account-number"
                  disabled={hl7 ? mode !== "create" : mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                />
                {errors.childAccountNumber && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.childAccountName ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-child-account-name">
                  Child Account Name {hl7 ? "(Optional)" : ""}
                </label>
                <input
                  id="routine-child-account-name"
                  type="text"
                  placeholder={mode === "view" ? "" : "Child Account Name"}
                  value={childAccountName}
                  onChange={(e) => {
                    setData({ ...data, childAccountName: e.target.value }, "childAccountName");
                  }}
                  data-testid="field-child-account-name"
                  disabled={hl7 ? mode !== "create" : mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                />
                {errors.childAccountName && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.isInvalid ? "error-message" : "")}>
                <label className="caption" htmlFor="ad-search-addresss">
                  Account Address {hl7 ? "(Optional)" : ""}
                </label>
                <PlacesApiLoader
                  data-testid="field-child-account-address"
                  id="routine-child-account-address"
                  pageType="statorder"
                  handleChange={(newData, isInvalid = false) => {
                    errors["isInvalid"] = isInvalid;
                    errors["address1"] = false;
                    this.setState({
                      data: {
                        ...data,
                        ...newData,
                      },
                      errors,
                    });
                  }}
                  stateData={data}
                  disabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                />
                {errors.isInvalid && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Invalid Address
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group flex-75 mr-3 " + (errors.address1 ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-ad-street-street-address">
                  Address 1 {hl7 ? "(Optional)" : ""}
                </label>
                <input
                  type="text"
                  id="routine-ad-street-street-address"
                  placeholder={mode === "view" ? "" : "Address 1"}
                  autoComplete="nope"
                  onFocus={onFocus}
                  value={address1}
                  title={address1}
                  data-testid="field-address1"
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        address1: e.target.value,
                      },
                      "address1"
                    );
                  }}
                  disabled
                />
                {errors.address1 && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group flex-75 mr-3 "}>
                <label className="caption" htmlFor="routine-ad-street-street-address-2">
                  Wing/Floor/Unit (Optional)
                </label>
                <input
                  type="text"
                  id="routine-ad-street-street-address-2"
                  placeholder={mode === "view" ? "" : "Wing/Floor/Unit"}
                  value={address2}
                  title={address2}
                  data-testid="field-address2"
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        address2: e.target.value,
                      },
                      "address2"
                    );
                  }}
                  disabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                />
              </div>
              <div className={"aha-modalform-group " + (errors.city ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-ad-city">
                  City {hl7 ? "(Optional)" : ""}
                </label>
                <input
                  type="text"
                  id="routine-ad-city"
                  placeholder={mode === "view" ? "" : "City"}
                  data-testid="field-city"
                  autoComplete="nope"
                  onFocus={onFocus}
                  value={city}
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        city: e.target.value,
                      },
                      "city"
                    );
                  }}
                  disabled
                />
                {errors.city && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.stateName ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-ad-state">
                  State {hl7 ? "(Optional)" : ""}
                </label>
                <input
                  type="text"
                  id="routine-ad-state"
                  placeholder={mode === "view" ? "" : "State"}
                  data-testid="field-state"
                  autoComplete="nope"
                  onFocus={onFocus}
                  value={stateName}
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        stateName: e.target.value,
                      },
                      "stateName"
                    );
                  }}
                  // disabled={mode === "view"}
                  disabled
                />
                {errors.stateName && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.zip ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-ad-zip">
                  Zip {hl7 ? "(Optional)" : ""}
                </label>
                <input
                  type="text"
                  name="zip"
                  id="routine-ad-zip"
                  placeholder={mode === "view" ? "" : "Zip"}
                  data-testid="field-zip"
                  autoComplete="nope"
                  onFocus={onFocus}
                  value={zip}
                  onChange={this.handleZip}
                  // disabled={mode === "view"}
                  disabled
                />
                {errors.zip && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.status ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-status">
                  Status
                </label>
                <input
                  type="text"
                  name="stat-statuss"
                  id="routine-status"
                  data-testid="field-status"
                  autoComplete="nope"
                  placeholder={mode === "view" ? "" : "Status"}
                  value={originalStatusText}
                  onChange={(e) => {
                    setData({ ...data, status: String(e.target.value) }, "status");
                  }}
                  disabled
                />
                {errors.status && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.orderType ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-order-type">
                  Order Type {hl7 ? "(Optional)" : ""}
                </label>
                <DropDownListComponent
                  id="routine-order-type"
                  placeholder={mode === "view" ? "" : "Order Type"}
                  dataSource={order_type}
                  fields={{ text: "label", value: "value" }}
                  value={orderType}
                  onChange={(e) => handleChangeOrderType(e)}
                  enabled={false}
                  data-testid="field-order-type"
                />
                {errors.orderType && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              {orderType == 2 && (
                <div
                  className={
                    "aha-modalform-group datetime-picker-wrapper " + (errors.collectionTime ? "error-message" : "")
                  }
                >
                  <label className="caption" htmlFor="routine-collection-time">
                    Collection Date/Time {hl7 ? "(Optional)" : ""}
                  </label>
                  <DatePicker
                    selected={collectionTime}
                    showTimeSelect
                    dateFormat="MM/dd/yyyy HH:mm"
                    placeholderText={mode === "view" ? "" : "Collection Date/Time"}
                    name="collection-time"
                    id="routine-collection-time"
                    filterTime={filterPassedTime}
                    timeIntervals={15}
                    onChange={(e) => {
                      this.handleChangeCollectionTime(e);
                    }}
                    data-testid="field-collection-time"
                    disabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                    minDate={new Date()}
                    calendarClassName="st-react-datepicker-wrapper"
                    timeFormat="HH:mm"
                    autoComplete="off"
                    // excludeOutOfBoundsTimes
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    customInput={<CustomDateTimeInput />}
                  />
                  {/* <span className="datetime-icon">
                    <span className="visually-hidden">datetime-icon</span>
                  </span> */}
                  {errors.collectionTime && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
              )}
              <div className={"aha-modalform-group " + (errors.orderFrequency ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-order-frequency">
                  Order Frequency {hl7 ? "(Optional)" : ""}
                </label>
                <DropDownListComponent
                  id="routine-order-frequency"
                  placeholder={mode === "view" ? "" : "Order Frequency"}
                  dataSource={order_frequency}
                  fields={{ text: "label", value: "value" }}
                  value={orderFrequency}
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        orderFrequency: String(e.target.value),
                      },
                      "orderFrequency"
                    );
                  }}
                  data-testid="field-order-frequency"
                  enabled={mode === "create"}
                />
                {errors.orderFrequency && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.creationMethod ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-creation-method">
                  Creation Method
                </label>
                <DropDownListComponent
                  id="routine-creation-method"
                  type="text"
                  placeholder={mode === "view" ? "" : "Creation Method"}
                  dataSource={order_creation_method}
                  fields={{ text: "label", value: "value" }}
                  value={creationMethod}
                  onChange={(e) => {
                    setData({ ...data, creationMethod: String(e.target.value) }, "creationMethod");
                  }}
                  enabled={false}
                  data-testid="field-creation-method"
                />
                {errors.creationMethod && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.createdBy ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-created-by">
                  Created By
                </label>
                <input
                  id="routine-created-by"
                  type="text"
                  placeholder={mode === "view" ? "" : "Created By"}
                  value={createdBy}
                  onChange={(e) => {
                    setData({ ...data, createdBy: e.target.value }, "createdBy");
                  }}
                  disabled
                  data-testid="field-created-by"
                />
              </div>
              <div className={"aha-modalform-group " + (errors.region ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-region">
                  Region {hl7 ? "(Optional)" : ""}
                </label>
                <Select
                  id="routine-region"
                  placeholder={mode === "view" ? "" : "Region"}
                  // className="order-info-phleb"
                  // classNamePrefix="sch-statlisting-select"
                  classNamePrefix="sch-schedule-select"
                  value={region}
                  onChange={(val) => this.handleChangeRegion(val)}
                  options={regions}
                  data-testid="field-region"
                  isSearchable
                  isDisabled={!(!hl7 ? mode !== "view" && showIndicator && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : true) && originalStatus != NOTIFIED_CLIENT_STATUS : mode !== "view" && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : false) && originalStatus != NOTIFIED_CLIENT_STATUS)}
                />
                {/* <DropDownListComponent
                  id="routine-region"
                  dataSource={regions}
                  fields={{ text: "label", value: "value" }}
                  placeholder={mode === "view" ? "" : "Region"}
                  type="text"
                  value={region}
                  onChange={(e) => this.handleChangeRegion}
                  enabled={!hl7 ? mode !== "view" && showIndicator && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : true) && originalStatus != NOTIFIED_CLIENT_STATUS : mode !== "view" && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : false) && originalStatus != NOTIFIED_CLIENT_STATUS}
                  data-testid="field-region"
                  allowFiltering
                /> */}
                {errors.region && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group mt-4 " + (errors.territory ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-territory">
                  Territory {hl7 ? "(Optional)" : ""}
                </label>
                <DropDownListComponent
                  id="routine-territory"
                  dataSource={territories}
                  fields={{ text: "label", value: "value" }}
                  placeholder={mode === "view" ? "" : "Territory"}
                  value={territory}
                  onChange={(e) => {
                    setData({ ...data, territory: String(e.target.value), zone: "" }, "territory");
                  }}
                  enabled={!hl7 ? mode !== "view" && enableTerr && region && region.value && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : true) && originalStatus != NOTIFIED_CLIENT_STATUS : mode !== "view" && region && region.value && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : true) && originalStatus != NOTIFIED_CLIENT_STATUS}
                  allowFiltering
                />
                {errors.territory && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group mt-4 " + (errors.zone ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-zone">
                  Zone {hl7 ? "(Optional)" : ""}
                </label>
                <DropDownListComponent
                  id="routine-zone"
                  placeholder={mode === "view" ? "" : "Zone"}
                  dataSource={zones}
                  fields={{ text: "label", value: "value" }}
                  value={zone}
                  onChange={(e) => {
                    const splitZone = e.target.value.split(":")[0];
                    handleChangeZone(String(e.target.value), splitZone, "zone");
                  }}
                  enabled={!hl7 ? mode !== "view" && enableZone && territory && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : true) && originalStatus != NOTIFIED_CLIENT_STATUS :
                    mode !== "view" && territory && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : true) && originalStatus != NOTIFIED_CLIENT_STATUS}
                  data-testid="field-zone"
                  allowFiltering
                />
                {errors.zone && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group datetime-picker-wrapper mt-4 "} >
                <label className="caption" htmlFor="routine-order-date-time">
                  Order Create Date/ Time
                </label>
                <DatePicker
                  id="routine-order-date-time"
                  selected={createDateTime}
                  showTimeSelect
                  dateFormat="MM/dd/yyyy HH:mm"
                  disabled
                  placeholderText={mode === "view" ? "" : "Auto Generated"}
                  timeFormat="HH:mm"
                  customInput={<CustomDateTimeInput />}
                />
                {/* <span className="datetime-icon" style={{top: (createDateTime && preferredCreateDateTime && user && user.reporting_manager_name) ? '31%' : !user && !user.reporting_manager_name || !createDateTime ? '45%' : '43%' }}>  
                  <span className="visually-hidden">datetime-icon</span>
                </span> */}
                <span className="d-block roboto-bold form-timezone-text">
                  {createDateTime
                    ? convertToMMDDYYYY(createDateTime, createDateTimeTimezone, "Facility")
                    : ""}
                </span>
                <span className="d-block roboto-bold form-timezone-text">
                  {preferredCreateDateTime
                    ? convertToMMDDYYYY(preferredCreateDateTime, preferredCreateDateTimeTimezone, "Preferred")
                    : ""}
                </span>
              </div>
              <div
                className={
                  "aha-modalform-group "
                  // +
                  // (errors.phlebotomist ? "error-message" : "")
                }
                style={{ marginTop: '1.4rem' }}
              >
                <div className="sc-checkbox-wrapper st-notified-wrapper st-notify-checkbox-wrapper ut-phleb-wrapper">
                  {USER_TYPES_FOR_PHLEB.map(type => {
                    const { label, value } = type
                    return (
                      <div key={value} className={"form-check notify-client-wrapper align-items-center  "}>
                        <input
                          type="checkbox"
                          id={label}
                          value={userType[label]}
                          checked={userType[label]}
                          onChange={e => this.handleChangeUserType(e, type)}
                          className="form-check-input"
                          disabled={mode === "view"}
                        />
                        <label className="caption" htmlFor={label}>
                          {label}
                        </label>
                      </div>
                    )
                  })}
                </div>
                <label className="caption" htmlFor="routine-phlebotomist">
                  Phlebotomist (Optional)
                </label>
                <Select
                  id="routine-phlebotomist"
                  placeholder={mode === "view" ? "" : "Phlebotomist"}
                  // className="order-info-phleb"
                  // classNamePrefix="sch-statlisting-select"
                  classNamePrefix="sch-schedule-select"
                  value={phlebotomist}
                  onChange={(val) => {
                    setData({
                      ...data,
                      phlebotomist: val || "",
                      status: val ? (val.value == 0 ? status : UNACKNOWLEDGED_STATUS) : UNASSIGNED_STATUS,
                      originalStatusText: val
                        ? val.value == 0
                          ? originalStatusText
                          : "Unacknowledged"
                        : "Unassigned",
                    });
                  }}
                  options={phlebs ? phlebs.filter(type => userType[type.label] || (type.label === "Others" && userType["Phleb"])) : []}
                  components={
                    {
                      // Option
                    }
                  }
                  data-testid="field-phlebotomist"
                  isDisabled={mode === "view" || originalStatus == CHECK_IN_STATUS || originalStatus == CHECK_OUT_STATUS || originalStatus == DROPPED_OFF_STATUS || originalStatus == NOTIFIED_CLIENT_STATUS}
                  formatGroupLabel={formatGroupLabel}
                  isClearable
                  backspaceRemovesValue
                  isSearchable
                />
                {errors.phlebotomist && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
                <div className="phleb-info-wrap">
                  {user && user.phone_number && <p>Phleb Phone Number : <span>{phoneFormat(user.phone_number.toString())}</span></p>}
                  {user && user.reporting_manager_name && <p>Supervisor Name : <span>{user.reporting_manager_name}</span></p>}
                  {user && user.reporting_manager_phone_number && <p>Supervisor Phone Number : <span>{phoneFormat(user.reporting_manager_phone_number.toString())}</span></p>}
                </div>
              </div>
            </form>
            <p className="headline6">Patient Information</p>
            <form className="aha-modalform-wrapper">
              <div className={"aha-modalform-group " + (errors.firstName ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-first-name">
                  First Name {hl7 ? "(Optional)" : ""}
                </label>
                <input
                  id="routine-first-name"
                  type="text"
                  placeholder={mode === "view" ? "" : "First Name"}
                  value={firstName}
                  onChange={(e) => {
                    setData({ ...data, firstName: e.target.value }, "firstName");
                  }}
                  data-testid="field-first-name"
                  disabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                />
                {errors.firstName && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group middle-initial"}>
                <label className="caption" htmlFor="routine-middle-name">
                  Middle Initial/Name (Optional)
                </label>
                <input
                  id="routine-middle-name"
                  type="text"
                  placeholder={mode === "view" ? "" : "Middle Initial/Name"}
                  value={middleName}
                  onChange={(e) => {
                    setData({ ...data, middleName: e.target.value }, "middleName");
                  }}
                  data-testid="field-middle-name"
                  disabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                />
              </div>
              <div className={"aha-modalform-group " + (errors.lastName ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-last-name">
                  Last Name {hl7 ? "(Optional)" : ""}
                </label>
                <input
                  id="routine-last-name"
                  type="text"
                  placeholder={mode === "view" ? "" : "Last Name"}
                  value={lastName}
                  onChange={(e) => {
                    setData({ ...data, lastName: e.target.value }, "lastName");
                  }}
                  data-testid="field-last-name"
                  disabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                />
                {errors.lastName && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.gender ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-gender">
                  Gender {hl7 ? "(Optional)" : ""}
                </label>
                <DropDownListComponent
                  id="routine-gender"
                  placeholder={mode === "view" ? "" : "Gender"}
                  dataSource={genders}
                  fields={{ text: "label", value: "value" }}
                  value={gender}
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        gender: String(e.target.value),
                      },
                      "gender"
                    );
                  }}
                  data-testid="field-gender"
                  enabled={mode !== "view" && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText == "Partial-Completion" : true) && originalStatus != NOTIFIED_CLIENT_STATUS}
                />
                {errors.gender && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group datetime-picker-wrapper " + (errors.patientDob ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="routine-patient-dob">
                  Patient DOB {hl7 ? "(Optional)" : ""}
                </label>
                <DatePicker
                  selected={patientDob}
                  dateFormat="MM/dd/yyyy"
                  placeholderText={mode === "view" ? "" : "Patient DOB"}
                  name="stat-patient-dob"
                  id="routine-patient-dob"
                  onChange={(e) => {
                    setData({ ...data, patientDob: e }, "patientDob");
                  }}
                  data-testid="field-patient-dob"
                  disabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                  maxDate={new Date()}
                  calendarClassName="st-react-datepicker-wrapper"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                // onKeyDown={(e) => {
                //   e.preventDefault();
                // }}
                />
                <span className="date-icon">
                  <span className="visually-hidden">date-icon</span>
                </span>
                {errors.patientDob && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.roomBedWingFloor ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-room-bed-wing-floor">
                  Room/Bed {hl7 ? "(Optional)" : ""}
                </label>
                <input
                  id="routine-room-bed-wing-floor"
                  type="text"
                  placeholder={mode === "view" ? "" : "Room/Bed"}
                  value={roomBedWingFloor}
                  onChange={(e) => {
                    setData({ ...data, roomBedWingFloor: e.target.value }, "roomBedWingFloor");
                  }}
                  data-testid="field-room-bed-wing-floor"
                  disabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                />
                {errors.roomBedWingFloor && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.orderingPhysician ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-ordering-physician">
                  Ordering Physician {hl7 ? "(Optional)" : ""}
                </label>
                <input
                  id="routine-ordering-physician"
                  type="text"
                  placeholder={mode === "view" ? "" : "Ordering Physician"}
                  value={orderingPhysician}
                  onChange={(e) => {
                    setData({ ...data, orderingPhysician: e.target.value }, "orderingPhysician");
                  }}
                  data-testid="field-ordering-physician"
                  disabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                />
                {errors.orderingPhysician && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
            </form>
            <p className="headline6 phlebotomist-activities">
              Phlebotomist Activity Information
              {/* {mode === "edit" && (
                <span
                  data-testid="stat-phleb-activity-button"
                  disabled={
                    !latitudeName ||
                    !longitudeName ||
                    (!checkInDateTime.actionDateTime &&
                      !checkOutDateTime.actionDateTime &&
                      !dropOffDateTime.actionDateTime &&
                      !dropOffType &&
                      dropOffType != 3 &&
                      !dropOffLocation)
                  }
                  onClick={() => updatePhlebInfo()}
                >
                  Update Phleb Activity
                </span>
              )} */}
            </p>

            <form className="aha-modalform-wrapper phlebAct-form">
              <div className={"aha-modalform-group datetime-picker-wrapper "}>
                <label className="caption" htmlFor="routine-check-in-date-time">
                  Check-In Date/Time {hl7 ? "(Optional)" : ""}
                </label>
                <DatePicker
                  selected={checkInDateTime.actionDateTime || ""}
                  showTimeSelect
                  dateFormat="MM/dd/yyyy HH:mm"
                  timeIntervals={15}
                  id="routine-check-in-date-time"
                  placeholderText={mode === "view" ? "" : "Check-In Date/Time"}
                  onChange={e => this.handleChangeCheckIn(e, newTz)}
                  data-testid="field-check-in-date-time"
                  disabled={mode !== "edit" || originalStatus == NOTIFIED_CLIENT_STATUS || originalStatus == UNASSIGNED_STATUS}
                  maxDate={checkinNow.toDate()}
                  required
                  calendarClassName="st-react-datepicker-wrapper"
                  timeFormat="HH:mm"
                  autoComplete="off"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  filterTime={time => filterPassedTimeForCheckinCheckoutDropOff(time, newTz)}
                  customInput={<CustomDateTimeInput />}
                />
                {/* <span className="datetime-icon">
                  <span className="visually-hidden">datetime-icon</span>
                </span> */}
                <span className="d-block roboto-bold form-timezone-text">
                  {originalCheckIn.preferred_datetime
                    ? convertToMMDDYYYY(originalCheckIn.preferred_datetime, originalCheckIn.preferred_timezone, "Preferred")
                    : ""}
                </span>
                {errors.checkInDateTime && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.checkInTimezone ? "error-message" : "")}>
                <label className="caption" htmlFor="checkin-routine-timezone">
                  Check-In Timezone {hl7 ? "(Optional)" : ""}
                </label>
                <DropDownListComponent
                  id="checkin-routine-timezone"
                  dataSource={checkInDateTime.checkInTimezone && getLabelByValue(timezone_ids, checkInDateTime.checkInTimezone) === OTHERS_TEXT ? timezone_ids.filter(tz => tz.isVisible || tz.label === OTHERS_TEXT) : timezone_ids.filter(tz => tz.isVisible)}
                  fields={{ text: "label", value: "value" }}
                  placeholder={mode === "view" ? "" : "Check-In Timezone"}
                  value={checkInDateTime.checkInTimezone || ""}
                  select={e => this.handleChangeDateTimezone(e, "checkInDateTime", "checkInTimezone")}
                  enabled={
                    mode === "edit" && originalStatus != UNASSIGNED_STATUS && originalStatus != NOTIFIED_CLIENT_STATUS
                  }
                  data-testid="checkoutstatus"
                />
                {errors.checkInTimezone && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group datetime-picker-wrapper " + (errors.checkOutDateTime ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="routine-check-out-date-time">
                  Check-Out Date/Time {hl7 ? "(Optional)" : ""}
                </label>
                <DatePicker
                  selected={checkOutDateTime.actionDateTime || ""}
                  showTimeSelect
                  dateFormat="MM/dd/yyyy HH:mm"
                  id="routine-check-out-date-time"
                  timeIntervals={15}
                  placeholderText={mode === "view" ? "" : "Check-Out Date/Time"}
                  onChange={e => this.handleChangeCheckOut(e, newCheckoutTz)}
                  data-testid="field-check-out-date-time"
                  maxDate={checkoutNow.toDate()}
                  disabled={mode !== "edit" || originalStatus == NOTIFIED_CLIENT_STATUS || !checkInDateTime.actionDateTime ? true : originalStatus == UNASSIGNED_STATUS}
                  required
                  calendarClassName="st-react-datepicker-wrapper"
                  timeFormat="HH:mm"
                  autoComplete="off"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  filterTime={time => filterPassedTimeForCheckinCheckoutDropOff(time, newCheckoutTz)}
                  customInput={<CustomDateTimeInput />}
                />
                {/* <span className="datetime-icon">
                  <span className="visually-hidden">datetime-icon</span>
                </span> */}
                <span className="d-block roboto-bold form-timezone-text">
                  {originalCheckOut.preferred_datetime
                    ? convertToMMDDYYYY(originalCheckOut.preferred_datetime, originalCheckOut.preferred_timezone, "Preferred")
                    : ""}
                </span>
                {errors.checkOutDateTime && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group " + (errors.checkOutTimezone ? "error-message" : "")}>
                <label className="caption" htmlFor="checkin-routine-timezone">
                  Check-Out Timezone {hl7 ? "(Optional)" : ""}
                </label>
                <DropDownListComponent
                  id="checkin-routine-timezone"
                  dataSource={checkOutDateTime.checkOutTimezone && getLabelByValue(timezone_ids, checkOutDateTime.checkOutTimezone) === OTHERS_TEXT ? timezone_ids.filter(tz => tz.isVisible || tz.label === OTHERS_TEXT) : timezone_ids.filter(tz => tz.isVisible)}
                  fields={{ text: "label", value: "value" }}
                  placeholder={mode === "view" ? "" : "Check-Out Timezone"}
                  value={checkOutDateTime.checkOutTimezone || ""}
                  select={e => this.handleChangeDateTimezone(e, "checkOutDateTime", "checkOutTimezone")}
                  enabled={
                    mode === "edit" && originalStatus != UNASSIGNED_STATUS && originalStatus != NOTIFIED_CLIENT_STATUS
                  }
                  data-testid="checkoutstatus"
                />
                {errors.checkOutTimezone && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              {(!checkOutDateTime.id || (checkOutDateTime.checkout_status && checkOutDateTime.checkout_status.id)) && (
                <div className={"aha-modalform-group " + (errors.checkoutStatus ? "error-message" : "")}>
                  <label className="caption" htmlFor="routine-status" style={{minHeight: (dropOffType === "courier" && dropOffLocation
                      && dropOffLocation.value
                        && dropOffLocation.value.latitudeName &&
                          dropOffLocation.value.longitudeName) && !(canDeleteCheckinCheckout && ((originalCheckIn && originalCheckIn.action_datetime) || (originalCheckOut && originalCheckOut.action_datetime)) && originalStatus < DROPPED_OFF_STATUS)  ? 34 : 0 }}>
                    Check-Out Status {hl7 ? "(Optional)" : ""}
                  </label>
                  <DropDownListComponent
                    id="routine-status"
                    dataSource={checkout_status}
                    fields={{ text: "label", value: "value" }}
                    placeholder={mode === "view" ? "" : "Check-out Status"}
                    value={!checkOutDateTime.id ? (checkOutDateTime.checkoutStatus || "") : (checkOutDateTime.checkout_status && checkOutDateTime.checkout_status.id) ? checkOutDateTime.checkout_status.id : ""}
                    onChange={(e) => {
                      setData(
                        {
                          ...data,
                          checkOutDateTime: {
                            ...checkOutDateTime,
                            checkoutStatus: e.target.value,
                            checkoutNote: "",
                          },
                        },
                        "checkoutStatus"
                      );
                    }}
                    enabled={
                      mode === "edit" &&
                      !checkOutDateTime.id &&
                      checkInDateTime.actionDateTime &&
                      originalStatus != UNASSIGNED_STATUS && originalStatus != NOTIFIED_CLIENT_STATUS
                    }
                    data-testid="field-status"
                  />
                  {checkOutDateTime.checkout_status && checkOutDateTime.checkout_status.id && checkOutDateTime.checkout_status.name !== "SAMPLE_COLLECTED" && <span
                    className="btn popup-seenotes"
                    onClick={() => navigateToRespectiveNote(checkOutDateTime.checkout_status.id, note_type)}
                  >
                    See notes
                  </span>}
                  {errors.checkoutStatus && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
              )}
              {!checkOutDateTime.id &&
                checkOutDateTime.checkoutStatus &&
                checkOutDateTime.checkoutStatus !== "SAMPLE_COLLECTED" && (
                  <>
                  <div className="w-100 d-flex">
                    <div
                      className={
                        "aha-modalform-group st-form-group aha-st-notes-group " + (errors.checkoutNote ? "error-message" : "")
                      }
                    >
                      <textarea
                        id={"routine-checkout-notes"}
                        placeholder={
                          mode === "view"
                            ? ""
                            : `${getLabelByValue(checkout_status, checkOutDateTime.checkoutStatus)} notes`
                        }
                        value={checkOutDateTime.checkoutNote || ""}
                        onChange={(e) => {
                          setData(
                            {
                              ...data,
                              checkOutDateTime: {
                                ...checkOutDateTime,
                                checkoutNote: e.target.value,
                              },
                            },
                            "checkoutNote"
                          );
                        }}
                        disabled={mode !== "edit" || checkOutDateTime.id || originalStatus == NOTIFIED_CLIENT_STATUS}
                      ></textarea>
                      {errors.checkoutNote && (
                        <p data-testid="error-text-id" className="error-message-text">
                          Selection Required
                        </p>
                      )}
                    </div>
                    <div className={"aha-modalform-group st-form-group aha-st-notes-group "}></div>
                    </div>
                    {checkOutDateTime.checkoutStatus === "CANCELLATION" && (
                      <div className={"aha-modalform-group " + (errors.authorizedBy ? "error-message" : "")}>
                        <label className="caption" htmlFor="routine-authorized-by">
                          Authorized By {hl7 ? "(Optional)" : ""}
                        </label>
                        <input
                          id="routine-authorized-by"
                          type="text"
                          placeholder={mode === "view" ? "" : "Authorized by"}
                          data-testid="field-ordering-physician"
                          disabled={mode === "view" || originalStatus == NOTIFIED_CLIENT_STATUS}
                          value={authorizedBy}
                          onChange={(e) => {
                            setData(
                              {
                                ...data,
                                authorizedBy: e.target.value,
                              },
                              "authorizedBy"
                            );
                          }}
                        />
                        {errors.authorizedBy && (
                          <p data-testid="error-text-id" className="error-message-text">
                            Selection Required
                          </p>
                        )}
                      </div>
                    )}
                  </>
                )}
              
              {canDeleteCheckinCheckout && ((originalCheckIn && originalCheckIn.action_datetime) || (originalCheckOut && originalCheckOut.action_datetime)) && originalStatus < DROPPED_OFF_STATUS &&
                <>
                  <div className="form-check me-3 mb-3 clear-date-time-class-error w-100">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      disabled={mode !== "edit" || originalStatus == NOTIFIED_CLIENT_STATUS || originalStatus == UNASSIGNED_STATUS}
                      value={isClear}
                      checked={isClear}
                      onChange={(e) => {
                        handleToggleClearDateTime(e.target.checked);
                      }}
                      id="clear-date-time"
                    />
                    <label className="form-check-label" htmlFor="clear-date-time">
                      Clear Date & Time
                    </label>
                  </div>
                  <div
                    className={
                      "aha-modalform-group st-form-group mb-3 "
                    }
                    style={{ flexBasis: '47.5%' }}
                  >
                    <label className="caption" htmlFor="check-in-check-out-time">
                      Comment
                    </label>
                    <textarea
                      id={"check-in-check-out-time"}
                      placeholder={
                        mode === "view"
                          ? ""
                          : `Enter reason for clearing date & time`
                      }
                      value={clearNote}
                      onChange={e => this.handleChangeClearDateTimeNote(e.target.value)}
                      disabled={mode !== "edit" || originalStatus == NOTIFIED_CLIENT_STATUS || originalStatus == UNASSIGNED_STATUS}
                    ></textarea>
                    <p data-testid="error-text-id" className="error-message-text required">
                      Required<span className="error">*</span>
                    </p>
                  </div>
                  <div
                    style={{ flexBasis: '40%' }}
                  ></div>
                </>}
              {(originalStatus == UNACKNOWLEDGED_STATUS ||
                originalStatus == ACKNOWLEDGED_STATUS ||
                (originalStatus === REDISPATCH_STATUS && hasMoreCheckinCheckoutInRedisptach)) &&
                phleb_activity_informations &&
                phleb_activity_informations.length > 0 && (
                  <>
                    <div className={"aha-modalform-group st-form-group-full "}>
                      {phleb_activity_informations.map((activity, index) => {
                        const { action_type_id, action_datetime } = activity;
                        const label =
                          action_type_id === 1
                            ? "Check-in"
                            : action_type_id === 2
                              ? "Check-out"
                              : action_type_id === 3
                                ? "Drop-off"
                                : "";
                        const time = new Date(action_datetime);

                        const compareTime =
                          action_type_id === 1
                            ? checkInDateTime.actionDateTime
                            : action_type_id === 2
                              ? checkOutDateTime.actionDateTime
                              : action_type_id === 3
                                ? dropOffDateTime.actionDateTime
                                : "";
                        let checker = true;
                        checker =
                          originalStatus == REDISPATCH_STATUS
                            ? new Date(time).getTime() === new Date(compareTime).getTime()
                              ? false
                              : true
                            : true;
                        return (
                          checker && (
                            <div className="st-checkin-out-details" key={index}>
                              <em>
                                <span className="roboto-medium font-size-14">{label}: </span>
                                <span className="font-size-14">{moment(time).format("MM/DD/YYYY HH:mm:ss")}</span>
                              </em>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </>
                )}
              <div className={"aha-modalform-group " + (errors.dropOffType ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-drop-off-type" style={{minHeight: (dropOffType === "courier" && dropOffLocation
                      && dropOffLocation.value
                        && dropOffLocation.value.latitudeName &&
                          dropOffLocation.value.longitudeName) ? 34 : 0 }}>
                  Drop-Off Type {hl7 ? "(Optional)" : ""}
                </label>
                <DropDownListComponent
                  id="routine-drop-off-type"
                  placeholder={mode === "view" ? "" : "Drop-Off Type"}
                  dataSource={dropOffTypes}
                  fields={{ text: "label", value: "value" }}
                  value={dropOffType}
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        dropOffType: e.target.value ? String(e.target.value) : "",
                        dropOffLocation: "",
                      },
                      "dropOffType"
                    );
                  }}
                  data-testid="field-drop-off-type"
                  enabled={
                    mode === "edit" &&
                    (!checkOutDateTime.id && !phleb_activity_informations.some((act) => act.action_type_id === 2)
                      ? checkInDateTime.actionDateTime &&
                      checkOutDateTime.actionDateTime &&
                      checkOutDateTime.checkoutStatus === "SAMPLE_COLLECTED"
                      : checkInDateTime.actionDateTime && checkOutDateTime.actionDateTime)
                    && originalStatus != NOTIFIED_CLIENT_STATUS && originalStatus != UNASSIGNED_STATUS
                  }
                  showClearButton
                />
                {errors.dropOffType && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className={"aha-modalform-group datetime-picker-wrapper " + (errors.dropOffDateTime ? "error-message" : "")}>
                <label className="caption" htmlFor="routine-drop-off-date-time" style={{minHeight: (dropOffType === "courier" && dropOffLocation
                      && dropOffLocation.value
                        && dropOffLocation.value.latitudeName &&
                          dropOffLocation.value.longitudeName) ? 34 : 0 }}>
                  Drop-Off Date/Time {hl7 ? "(Optional)" : ""}
                </label>
                <DatePicker
                  selected={dropOffDateTime.actionDateTime || ""}
                  showTimeSelect
                  dateFormat="MM/dd/yyyy HH:mm"
                  id="routine-drop-off-date-time"
                  timeIntervals={15}
                  placeholderText={mode === "view" ? "" : "Drop-Off Date/Time"}
                  onChange={e => this.handleChangeDropOffTime(e, newDropOffTz)}
                  data-testid="field-drop-off-date-time"
                  disabled={
                    mode !== "edit" ||
                    originalStatus == NOTIFIED_CLIENT_STATUS || originalStatus == UNASSIGNED_STATUS ||
                    !checkInDateTime.actionDateTime ||
                    !checkOutDateTime.actionDateTime ||
                    (!checkOutDateTime.id &&
                      !phleb_activity_informations.some((act) => act.action_type_id === 2) &&
                      checkOutDateTime.checkoutStatus !== "SAMPLE_COLLECTED")
                  }
                  required
                  maxDate={dropOffNow.toDate()}
                  calendarClassName="st-react-datepicker-wrapper"
                  timeFormat="HH:mm"
                  autoComplete="off"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  filterTime={time => filterPassedTimeForCheckinCheckoutDropOff(time, newDropOffTz)}
                  isClearable={(mode === "edit" && dropOffDateTime.actionDateTime && !(originalStatus == NOTIFIED_CLIENT_STATUS || originalStatus == UNASSIGNED_STATUS ||
                    !checkInDateTime.actionDateTime ||
                    !checkOutDateTime.actionDateTime ||
                    (!checkOutDateTime.id &&
                      !phleb_activity_informations.some((act) => act.action_type_id === 2) &&
                      checkOutDateTime.checkoutStatus !== "SAMPLE_COLLECTED")))
                  }
                  customInput={<CustomDateTimeInput />}
                />
                {/* <span className="datetime-icon" style={{top: dropOffType === "hospital" ? 
                (dropOffLocation &&
                (dropOffLocation.hospital_contact_phone_number ||
                  parseInt(dropOffLocation.account_number) > 0)) ? "33%" : "46%"
                   : (dropOffType === "courier" && dropOffLocation
                    && dropOffLocation.value
                      && dropOffLocation.value.latitudeName &&
                        dropOffLocation.value.longitudeName) ? '60%' : "46%" 
                        }}>
                  <span className="visually-hidden">datetime-icon</span>
                </span> */}
                <span className="d-block roboto-bold form-timezone-text">
                  {originalDropOff.preferred_datetime
                    ? convertToMMDDYYYY(originalDropOff.preferred_datetime, originalDropOff.preferred_timezone, "Preferred")
                    : ""}
                </span>
                {errors.dropOffDateTime && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className="position-relative">
              {(dropOffType === "lab" || dropOffType === "hospital") && (
                <div className={"aha-modalform-group " + (errors.dropOffLocation ? "error-message" : "")}>
                  <label className="caption" htmlFor="drop-off-location">
                    {dropOffType === "lab" ? "Lab Name" : "Hospital Name"} {hl7 ? "(Optional)" : ""}
                    {/* {dropOffLocation
                      ? dropOffLocation.label
                        ? `(${dropOffLocation.label})`
                        : ""
                      : ""} */}
                  </label>
                  <AsyncSelect
                    loadOptions={(text) => loadOptionsDropOff(text, dropOffType, dropOffDateTime)}
                    isSearchable
                    onChange={this.handleChangeDropOffLocation}
                    // hideSelectedOptions
                    isLoading={false}
                    isClearable
                    value={dropOffLocation}
                    name=""
                    id="routine-dropoff-name"
                    className="sch-schedule-dropdown"
                    classNamePrefix="sch-schedule-select"
                    placeholder={mode === "view" ? "" : "Choose"}
                    isDisabled={mode === "view" || originalStatus == NOTIFIED_CLIENT_STATUS}
                  />
                  {errors.dropOffLocation && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
              )}
              {dropOffType === "courier" && (
                <div className={"aha-modalform-group " + (errors.dropOffLocation ? "error-message" : "")}>
                  <label className="caption" htmlFor="drop-off-location">
                    Drop-off location{" "} {hl7 ? "(Optional) " : ""}
                    {dropOffLocation
                      ? dropOffLocation.value
                        ? dropOffLocation.value.latitudeName && dropOffLocation.value.longitudeName
                          ? `(Latitude: ${customToFixed(dropOffLocation.value.latitudeName)} Longitude: ${customToFixed(dropOffLocation.value.longitudeName)})`
                          : ""
                        : ""
                      : ""}
                  </label>
                  <PlacesApiLoader
                    id="routine-dropOff-order"
                    handleChange={(newData) => {
                      setData({ ...data, ...newData }, "dropOffLocation");
                    }}
                    pageType={`dropOff-${TYPE_ROUTINE}`}
                    stateData={data}
                    disabled={mode === "view" || originalStatus == NOTIFIED_CLIENT_STATUS}
                  />
                  {errors.dropOffLocation && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
              )}
              {dropOffType === "hospital" && dropOffLocation && (dropOffLocation.hospital_contact_phone_number || parseInt(dropOffLocation.account_number) > 0) && (
                 <div
                 className={
                   "d-flex flex-column st-acc-ph-wrapper mb-3"
                 }
               >
                  {parseInt(dropOffLocation.account_number) > 0 && <p className="st-acc-ph-number ">Acct: #{dropOffLocation.account_number}</p>}
                  {dropOffLocation.hospital_contact_phone_number &&
                    <p className="st-acc-ph-number ">Tel: {phoneFormat(dropOffLocation.hospital_contact_phone_number.toString())} {dropOffLocation.hospital_contact_phone_number_ext
                      ? ` Ext. ${dropOffLocation.hospital_contact_phone_number_ext}`
                      : ""}</p>}
                </div>
              )}
              </div>
              {dropOffType === "hospital" && (
                <div className={"aha-modalform-group " + (errors[`selectedFiles`] ? "error-message" : "")}>
                  <React.Fragment>
                    <label className="caption" htmlFor="routine-choose-file">
                      Attachment (Optional)
                    </label>
                    <input
                      accept="image/*"
                      className="attachment-file"
                      id="routine-choose-file"
                      type="file"
                      onChange={handleFileUpload}
                      disabled={mode === "view" || originalStatus == NOTIFIED_CLIENT_STATUS}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      multiple
                    />
                  </React.Fragment>
                  {errors[`selectedFiles`] && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
              )}
              <div className={"aha-modalform-group " + (errors.dropOffTimezone ? "error-message" : "")}>
                <label className="caption" htmlFor="dropoff-routine-timezone" style={{minHeight: (dropOffType === "courier" && dropOffLocation
                      && dropOffLocation.value
                        && dropOffLocation.value.latitudeName &&
                          dropOffLocation.value.longitudeName) ? 34 : 0 }}>
                  Drop-Off Timezone {hl7 ? "(Optional)" : ""}
                </label>
                <DropDownListComponent
                  id="dropoff-routine-timezone"
                  dataSource={dropOffDateTime.dropOffTimezone && getLabelByValue(timezone_ids, dropOffDateTime.dropOffTimezone) === OTHERS_TEXT ? timezone_ids.filter(tz => tz.isVisible || tz.label === OTHERS_TEXT) : timezone_ids.filter(tz => tz.isVisible)}
                  fields={{ text: "label", value: "value" }}
                  placeholder={mode === "view" ? "" : "Drop-Off Timezone"}
                  value={dropOffDateTime.dropOffTimezone || ""}
                  select={e => this.handleChangeDateTimezone(e, "dropOffDateTime", "dropOffTimezone")}
                  enabled={
                    mode === "edit" && originalStatus != UNASSIGNED_STATUS && originalStatus != NOTIFIED_CLIENT_STATUS
                    && checkInDateTime.actionDateTime &&
                    checkOutDateTime.actionDateTime &&
                    !(!checkOutDateTime.id &&
                      !phleb_activity_informations.some((act) => act.action_type_id === 2) &&
                      checkOutDateTime.checkoutStatus !== "SAMPLE_COLLECTED")
                  }
                />
                {errors.dropOffTimezone && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
            </form>
            {requisitions.map((file, idx) => {
              return (
                <div className="view-attachment" key={idx}>
                  <p key={file.id}>
                    <span className="file-name">{file.file_name}</span>
                    <span
                      className="view-btn"
                      onClick={() =>
                        this.viewAttachment(file)
                      }
                    >
                      View Attachment
                    </span>
                  </p>
                </div>
              );
            })}
            {/* {originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF && (
              <div className="form-check notify-client-wrapper">
                <input
                  // className="form-check-input"
                  type="checkbox"
                  id="routine-notify"
                  value={notify}
                  checked={notify}
                  onChange={(e) => {
                    setData({ ...data, notify: e.target.checked });
                  }}
                  disabled={mode === "view"}
                />
                <label className="caption" htmlFor="routine-notify">
                  Client Notified order has been Dropped off
                </label>
              </div>
            )} */}
            {testList.length > 0 && <p className="headline6 testlist-comparison-class">Testing Information</p>}
            {testList.map((ele, i) => {
              const {
                testCode = "",
                name = "",
                orderCollectionMethod = "",
                collectionDate = !hl7 ? new Date() : "",
                deleted = false
              } = ele;
              return !deleted && (
                <form className="aha-modalform-wrapper testing-info" key={i}>
                  <div
                    className={"aha-modalform-group " + (errors[`testList-testCode-${i}`] ? "error-message" : "")}
                  >
                    <label className="caption" htmlFor={"routine-test-code" + i}>
                      Test Code {hl7 ? "(Optional)" : ""}
                    </label>
                    <Select
                      id={"routine-test-code" + i}
                      placeholder={mode === "view" ? "" : "Test Code"}
                      // className="order-info-phleb"
                      // classNamePrefix="sch-statlisting-select"
                      classNamePrefix="sch-schedule-select"
                      value={testCode}
                      onChange={(value) => this.handleChangeTestCode(
                        value,
                        i,
                        ele,
                        testDescriptions
                      )}
                      options={hl7 && ele.test_code ? [{ label: ele.test_code, value: ele.id, isHL7: true }, ...testCodes] : testCodes}
                      isDisabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                      data-testid="field-test-code"
                      isClearable
                      backspaceRemovesValue
                      isSearchable
                    />
                    {errors[`testList-testCode-${i}`] && (
                      <p data-testid="error-text-id" className="error-message-text">
                        Selection Required
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      "aha-modalform-group test-description " +
                      (errors[`testList-name-${i}`] ? "error-message" : "")
                    }
                  >
                    <label className="caption" htmlFor={"routine-test-name" + i}>
                      Test Name {hl7 ? "(Optional)" : ""}
                    </label>
                    <Select
                      id={"routine-test-name" + i}
                      placeholder={mode === "view" ? "" : "Test Name"}
                      // className="order-info-phleb"
                      // classNamePrefix="sch-statlisting-select"
                      classNamePrefix="sch-schedule-select"
                      value={name}
                      onChange={(value) => {
                        let tempErrors = { ...errors };
                        tempErrors[`testList-testCode-${i}`] = false;
                        tempErrors[`testList-name-${i}`] = false;
                        const tempList = [...testList];
                        tempList[i] = {
                          ...tempList[i],
                          isHL7: value ? value.isHL7 || false : false,
                          name: value,
                          testCode: value
                            ? value.value
                              ? {
                                label: getLabelByValue(hl7 && ele.test_code ? [{ label: ele.test_code, value: ele.id, isHL7: true }, ...testCodes] : testCodes, value.value || ""),
                                value: value.value
                              }

                              : ""
                            : "",
                        };
                        this.setState({
                          testList: tempList,
                          errors: tempErrors
                        });
                      }}
                      options={hl7 && ele.test_code_name ? [{ label: ele.test_code_name, value: ele.id, isHL7: true }, ...testDescriptions] : testDescriptions}
                      isDisabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                      data-testid="field-test-name"
                      isClearable
                      backspaceRemovesValue
                      isSearchable
                    />

                    {errors[`testList-name-${i}`] && (
                      <p data-testid="error-text-id" className="error-message-text">
                        Selection Required
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      "aha-modalform-group " +
                      (errors[`testList-orderCollectionMethod-${i}`] ? "error-message" : "")
                    }
                  >
                    <label className="caption" htmlFor={"routine-order-collection-method" + i}>
                      Order Collection Method {hl7 ? "(Optional)" : ""}
                    </label>
                    <Select
                      id={"routine-order-collection-method" + i}
                      placeholder={mode === "view" ? "" : "Order Collection Method"}
                      // className="order-info-phleb"
                      // classNamePrefix="sch-statlisting-select"
                      classNamePrefix="sch-schedule-select"
                      value={orderCollectionMethod}
                      onChange={(e) => {
                        const tempList = [...testList];
                        tempList[i] = {
                          ...tempList[i],
                          orderCollectionMethod: e,
                        };
                        let tempErrors = { ...errors };
                        tempErrors[`testList-orderCollectionMethod-${i}`] = false;
                        this.setState({
                          testList: tempList,
                          errors: tempErrors
                        });
                      }}
                      options={order_collection_method}
                      data-testid="field-order-collection-method"
                      isDisabled={!(mode !== "view" && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : true) && originalStatus != NOTIFIED_CLIENT_STATUS)}
                    />
                    {/* <DropDownListComponent
                      id={"routine-order-collection-method" + i}
                      placeholder={mode === "view" ? "" : "Order Collection Method"}
                      dataSource={order_collection_method}
                      fields={{ text: "label", value: "value" }}
                      value={orderCollectionMethod}
                      onChange={(e) => {
                        const tempList = [...testList];
                        tempList[i] = {
                          ...tempList[i],
                          orderCollectionMethod: String(e.target.value),
                        };
                        let tempErrors = { ...errors };
                        tempErrors[`testList-orderCollectionMethod-${i}`] = false;
                        this.setState({
                          testList: tempList,
                          errors: tempErrors
                        });
                      }}
                      data-testid="field-order-collection-method"
                      enabled={mode !== "view" && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : true) && originalStatus != NOTIFIED_CLIENT_STATUS}
                    /> */}
                    {errors[`testList-orderCollectionMethod-${i}`] && (
                      <p data-testid="error-text-id" className="error-message-text">
                        Selection Required
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      "aha-modalform-group datetime-picker-wrapper " +
                      (errors[`testList-collectionDate-${i}`] ? "error-message" : "")
                    }
                  >
                    <label className="caption" htmlFor={"routine-collection-date" + i}>
                      Collection Date {hl7 ? "(Optional)" : ""}
                    </label>
                    <DatePicker
                      selected={collectionDate}
                      dateFormat="MM/dd/yyyy"
                      placeholderText={mode === "view" ? "" : "Collection Date"}
                      id={"routine-collection-date" + i}
                      data-testid="field-collection-date"
                      onChange={(e) => {
                        const tempList = [...testList];
                        tempList.forEach((tl) => {
                          tl.collectionDate = e;
                        });
                        let tempErrors = { ...errors };
                        tempErrors[`collectionDate`] = false;
                        this.setState(
                          { testList: tempList, errors: tempErrors });
                      }}
                      disabled={mode === "view" || (originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF) || originalStatus == NOTIFIED_CLIENT_STATUS}
                      minDate={new Date()}
                      calendarClassName="st-react-datepicker-wrapper"
                      autoComplete="off"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                    {errors[`testList-collectionDate-${i}`] && (
                      <p data-testid="error-text-id" className="error-message-text">
                        Selection Required
                      </p>
                    )}
                    <span className="date-icon">
                      <span className="visually-hidden">date-icon</span>
                    </span>
                  </div>
                  {(mode === "create" || mode === "edit") && i > 0 && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : true) && originalStatus != NOTIFIED_CLIENT_STATUS && (
                    <span className="close-icon" onClick={() => removeTestList(i)}></span>
                  )}
                </form>
              );
            })}
            {mode !== "view" && (originalStatus == DROPPED_OFF_STATUS ? originalStatusText === "Partial-Completion" : true) && originalStatus != NOTIFIED_CLIENT_STATUS && (
              <div className="test-code-btn">
                <Button className="primary-btn primary-btn-outline outline" onClick={addTestList}>
                  Add Another Test Code
                </Button>
              </div>
            )}
            {note_type.map((ele, i) => {
              const { text = "", list = [] } = notes[ele.value] || {};

              let label = ele.label || "";
              label = label.replaceAll("_", " ");
              return (
                (ele.value == 1 ||
                  ele.value == 2 ||
                  (ele.value == 3 && !disableCancelledNote) ||
                  list.length > 0) && (
                  <div key={i} className="instruction-wrapper">
                    <p className="headline6">
                      <p className="headline6">
                        {label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()} {ele.value == 3 ? 'Reason' : 'Instructions'}{" "}
                        {label === "ORDER" && <span className="order-notes">(visible to Phlebotomist)</span>}
                        {label != 3 && <span className="order-notes"> (Optional)</span>}
                      </p>
                    </p>
                    <form className="aha-modalform-wrapper instruction-notes">
                      <div className={"aha-modalform-group " + (errors[`notes-${ele.value}`] ? ` error-message` : "")}>
                        <textarea
                          id={"routine-instructions" + "-" + ele.value}
                          placeholder={mode === "view" ? "" : "Additional Notes"}
                          value={text}
                          onChange={(e) => {
                            let tempNotes = { ...notes };
                            tempNotes = {
                              ...tempNotes,
                              [ele.value]: {
                                ...tempNotes[ele.value],
                                text: e.target.value,
                              },
                            };
                            setData(
                              {
                                ...data,
                                notes: tempNotes,
                              },
                              `notes-${ele.value}`
                            );
                          }}
                          data-testid="field-note"
                          // ref={this.noteRef}
                          ref={this.inputRefs[ele.value]}
                          disabled={
                            mode === "view" ||
                            (mode === "create" && ele.value == 3) ||
                            (ele.value == 3 && disableCancelledNote)
                          }
                        ></textarea>
                        {errors[`notes-${ele.value}`] && (
                          <p data-testid="error-text-id" className="error-message-text">
                            Selection Required
                          </p>
                        )}
                      </div>
                      <div className="aha-modalform-group st-notes-wrapper me-0">
                        {list.length === 0 ? (
                          mode !== "create" && (
                            <div className="instruction-notes-display">
                              <p>Notes will appear here</p>
                            </div>
                          )
                        ) : (
                          <div className="notes-display-wrapper">
                            <ul>
                              {list.map((val, i) => {
                                const { user = {}, created_date_time = "", timezone = {} } = val;
                                return (
                                  <li key={i} data-testid="field-notes-list" className="notes-list">
                                    {user.name && created_date_time && (
                                      <p className="title">
                                        {" "}
                                        {user.name} <span>{moment(created_date_time).format("LL HH:mm:ss")} {timezone.abbreviation || ""}</span>
                                      </p>
                                    )}
                                    <p className="description"> {val.notes || ""} </p>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                      {ele.value == 3 && (
                        <div
                          className={"aha-modalform-group mt-2 " + (errors.authorizedBy ? "error-message" : "")}
                        >
                          <label className="caption" htmlFor="stat-authorized-by">
                            Authorized By
                          </label>
                          <input
                            id="routine-authorized-by"
                            type="text"
                            placeholder="Authorized by"
                            data-testid="field-ordering-physician"
                            disabled={mode === "view"}
                            value={authorizedBy}
                            onChange={(e) => {
                              setData(
                                {
                                  ...data,
                                  authorizedBy: e.target.value,
                                },
                                "authorizedBy"
                              );
                            }}
                          />
                          {errors.authorizedBy && (
                            <p data-testid="error-text-id" className="error-message-text">
                              Selection Required
                            </p>
                          )}
                        </div>
                      )}
                    </form>
                  </div>
                )
              );
            })}
            {audit_history && audit_history.length > 0 && (
              <div className="audit-logs-wrapper">
                <h2 className="headline7 audit-log-title">Audit Logs</h2>
                <Table responsive borderless className="audit-log-table">
                  <thead>
                    <tr>
                      <th>Timestamp</th>
                      <th>User</th>
                      <th>Activity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {audit_history.map((history, index) => {
                      const { user = {}, activity, datetime = "", type, abbreviation } = history;
                      return (
                        index < 5 && (
                          <tr key={index}>
                            <td>{moment(datetime).format("MM/DD/YYYY HH:mm:ss")} {abbreviation || ""}</td>
                            <td>{user ? `${user.name}${user.username ? ` (${user.username})` : ""}` || "" : ""}{type ? ` (${type})` : activity === "Order Updated" ? ` (DESKTOP)` : ""}</td>
                            <td>{activity}</td>
                          </tr>
                        )
                      );
                    })}
                    {audit_history.length > 5 &&
                      viewAllAudit &&
                      audit_history.map((history, index) => {
                        const { user = {}, activity, datetime = "", type, abbreviation } = history;
                        return (
                          index >= 5 && (
                            <tr key={index}>
                              <td>{moment(datetime).format("MM/DD/YYYY HH:mm:ss")} {abbreviation || ""}</td>
                              <td>{user ? `${user.name}${user.username ? ` (${user.username})` : ""}` || "" : ""}{type ? ` (${type})` : activity === "Order Updated" ? ` (DESKTOP)` : ""}</td>
                              <td>{activity}</td>
                            </tr>
                          )
                        );
                      })}
                  </tbody>
                </Table>
                {audit_history.length > 5 && !viewAllAudit && (
                  <a
                    className="ad-log-viewmore text-center font-size-11 roboto-regular"
                    onClick={() => {
                      setData({
                        ...data,
                        viewAllAudit: !viewAllAudit,
                      });
                    }}
                  >
                    View All({audit_history.length - 5})
                  </a>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-testid="button-cancel"
              className="primary-btn primary-btn-outline outline"
              onClick={() => {
                mode === "view"
                  ? handleClose()
                  : this.showConfirmationModal()
              }}
            >
              Cancel
            </Button>
            {mode === "view" ? (
              updateRoutineRedraw &&
              status != CANCELLED_STATUS && (
                <Button
                  data-testid="button-edit"
                  className="primary-btn primary-btn-outline outline"
                  onClick={() => {
                    this.props.toggleRoutineRedrawModal(true, { data: routineRedrawData }, "edit");
                  }}
                >
                  Edit Routine Redraw Order
                </Button>
              )
            ) : (
              <ProgressButtonComponent
                data-testid="button-submit"
                content={mode === "edit" ? "Save Changes" : "Create Order"}
                enableProgress={true}
                spinSettings={{ position: "Center" }}
                animationSettings={{ effect: "SlideLeft" }}
                cssClass="primary-btn primary-btn-outline ad-progress-btn"
                onClick={() => this.saveChanges()}
                disabled={mode === "edit" ? !isFormChanged : false}
              ></ProgressButtonComponent>
            )}
            {mode === "view" && originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF && notify && (
              <ProgressButtonComponent
                data-testid="button-submit"
                content={"Save Changes"}
                enableProgress={true}
                spinSettings={{ position: "Center" }}
                animationSettings={{ effect: "SlideLeft" }}
                cssClass="primary-btn primary-btn-outline ad-progress-btn"
                onClick={() => handleSubmit()}
              ></ProgressButtonComponent>
            )}
          </Modal.Footer>
        </Modal>
        <Modal
          show={fileModal.show || false}
          onHide={() => this.setState({ fileModal: { show: false } })}
          className="ad-adduser-modal sc-copycal-modal view-atachment-modal"
          backdrop="static"
          enforceFocus={false}
          id="routine-attachments-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img loading="lazy" id={`order-image`} src={fileModal.file_url || ErrorImage} alt="image" onError={this.addDefaultSrc} />
            <p>{fileModal.file_name || ""}</p>
            <div className="d-flex justify-content-center mb-2 mt-2">
              <Button className="primary-btn primary-btn-outline" onClick={() => this.downloadFile(fileModal.url)}>
                Download
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <StatusModal
          modalType={statusModalType}
          show={statusModalType.value || false}
          hideModal={() => this.setState({ statusModalType: { value: false } })}
          title={statusModalType.title}
          subtitle={statusModalType.subtitle}
          confirmUpdate={() => statusModalType.type === TIMEZONE_MISMATCH ? continueSaving() : handleClose()}
        />

        <TestCodeWarningModal
          show={testCodeWarning.show || false}
          hideModal={() => {
            this.setState({
              testCodeWarning: {
                show: false,
                removeTestList: []
              }
            })
          }}
          title={<DropTestCodesTitle />}
          subtitle={testCodeWarning.removedTestCodes ? testCodeWarning.removedTestCodes.length > 0 ? <div>
            {testCodeWarning.removedTestCodes.map((code, idx) => {
              const { testCode = {} } = code;
              return (
                <div key={idx}>{testCode.label || ""}</div>
              )
            })}
          </div> : "" : ""}
          confirmUpdate={() => {
            this.saveChanges(true, testCodeWarning.skipTzValidation || false);
          }}
        />
      </>
    );
  }
}

export default RoutineRedrawAddEdit;
