import { STAT_CLEAR_DATA, STAT_GRID_FILTERING, STAT_GRID_LOADING, STAT_GRID_PAGING, STAT_GRID_SORTING, TOGGLE_HIDE_FILTER } from "../../../actions/action";
import moment from "moment";
import _ from "lodash";
import { ACKNOWLEDGED_STATUS, findLastIndex, UNACKNOWLEDGED_STATUS } from "../../../app/utils/Constants";
import ahaApi from "../../../app/services/aha-api";

const initialState = {
  data: [],
  loading: false,
  hideFilter: false
}

export const getStatData = (params) => async (dispatch) => {
  dispatch({ type: STAT_GRID_LOADING, payload: true });
  try {
    const res = await ahaApi.Account.getOrders(params);
    let { content = {} } = res;
    let { orders = [], count } = content;
    orders = orders.map(c => {
      const { patient_first_name = "", patient_last_name = "", notes = [], child_account_address1 = "", child_account_address2 = "", child_city = "",
        child_state = {}, child_zip, order_creation_datetime = "", user = {}, order_line_items = [], hl7 = false, patient_dob, created_by = {}, patient_location,
        notified_person = "", phleb_activity_informations = [], order_status = {}, last_updated_datetime, last_updated_timezone = {}, order_creation_timezone = {},
        order_creation_method = {}, order_type = {}, order_frequency = {} } = c;

      let tempNotes = notes ? notes.length ? notes.sort((a, b) => new Date(a.created_date_time) > new Date(b.created_date_time)
        ? -1
        : new Date(a.created_date_time) < new Date(b.created_date_time)
          ? 1
          : 0) : [] : []
      const cancellationNotes = tempNotes.filter(note => note.note_type && note.note_type.id == 3);
      const notesWithoutCancellation = tempNotes.filter(note => note.note_type && note.note_type.id != 3 && note.notes);

      let tempCheckIn = {};
      let tempCheckOut = {};
      let tempDropOff = {};

      let checkIns = phleb_activity_informations.filter((act) => act.action_type_id === 1) || [];
      let checkOuts = phleb_activity_informations.filter((act) => act.action_type_id === 2) || [];
      let dropOffs = phleb_activity_informations.filter((act) => act.action_type_id === 3) || [];
      let checkInObj = {};
      let checkOutObj = {};
      let dropOffObj = {};

      if (dropOffs.length) {
        let checkinIndex = findLastIndex(phleb_activity_informations, (act) => act.action_type_id == 1);

        checkInObj = phleb_activity_informations[checkinIndex] || {};

        let checkoutIndex = findLastIndex(phleb_activity_informations, (act) => act.action_type_id == 2);

        checkOutObj = phleb_activity_informations[checkoutIndex] || {};
        dropOffObj = dropOffs.length
          ? dropOffs.reduce((a, b) => {
            return new Date(a.action_datetime) > new Date(b.action_datetime) ? a : b;
          })
          : undefined;
      } else {
        if (checkIns.length > checkOuts.length) {
          checkInObj = phleb_activity_informations.length
            ? phleb_activity_informations[phleb_activity_informations.length - 1]
              ? phleb_activity_informations[phleb_activity_informations.length - 1].action_type_id === 1
                ? phleb_activity_informations[phleb_activity_informations.length - 1]
                : phleb_activity_informations[phleb_activity_informations.length - 2]
                  ? phleb_activity_informations[phleb_activity_informations.length - 2].action_type_id === 1
                    ? phleb_activity_informations[phleb_activity_informations.length - 2]
                    : {}
                  : {}
              : {}
            : {};

          checkOutObj = {};
        } else {
          let checkinIndex = findLastIndex(phleb_activity_informations, (act) => act.action_type_id == 1);

          checkInObj = phleb_activity_informations[checkinIndex] || {};

          let checkoutIndex = findLastIndex(phleb_activity_informations, (act) => act.action_type_id == 2);

          checkOutObj = phleb_activity_informations[checkoutIndex] || {};
        }
        dropOffObj = dropOffs.length
          ? dropOffs.reduce((a, b) => {
            return new Date(a.action_datetime) > new Date(b.action_datetime) ? a : b;
          })
          : undefined;
      }

      if (checkInObj && Object.keys(checkInObj).length) {
        tempCheckIn = {
          ...checkInObj,
          actionDateTime: checkInObj.action_datetime ? new Date(checkInObj.action_datetime) : ""
        };
      }

      if (checkOutObj && Object.keys(checkOutObj).length) {
        tempCheckOut = {
          ...checkOutObj,
          actionDateTime: checkOutObj.action_datetime ? new Date(checkOutObj.action_datetime) : ""
        };
      }

      if (dropOffObj) {
        tempDropOff = {
          ...dropOffObj,
          actionDateTime: dropOffObj.action_datetime ? new Date(dropOffObj.action_datetime) : "",
        };
      }

      const checkInDateTime = order_status && (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) && phleb_activity_informations.length > 0
        ? {}
        : tempCheckIn;

      const checkOutDateTime = order_status && (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) && phleb_activity_informations.length > 0
        ? {}
        : tempCheckOut;

      const dropOffDateTime = order_status && (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) && phleb_activity_informations.length > 0
        ? {}
        : tempDropOff;

      let collectionMethods = order_line_items ? order_line_items.length ? order_line_items.filter(item => item.order_collection_method && item.order_collection_method.collection_type) : [] : [];
      collectionMethods = collectionMethods ? collectionMethods.length ? collectionMethods.map(item => item.order_collection_method && item.order_collection_method.collection_type).join("; ") : "-" : "-"

      let collectionDates = order_line_items ? order_line_items.length ? order_line_items.filter(item => item.collection_date) : [] : [];
      collectionDates = collectionDates ? collectionDates.length ? collectionDates.map(item => moment(new Date(`${item.collection_date} 00:00:00`)).format("MM/DD/yyyy")).join("; ") : "-" : "-"

      let TestCodes = [];
      let TestDescriptions = [];

      if (order_line_items) {
        order_line_items.forEach((order) => {
          if (order.test_code || order.test_code_name) {
            TestCodes.push(order.test_code || "");
            order.test_code_name && TestDescriptions.push(order.test_code_name);
          } else {
            TestCodes.push(order.test_code_id ? order.test_code_id.test_code || "" : "");
            order.test_code_id && order.test_code_id.test_code_name && TestDescriptions.push(order.test_code_id.test_code_name);
          }
        });
      }

      return {
        ...c,
        OrderStatus: order_status.order_status_name,
        OrderNumber: c.order_number,
        OrderCreated: `${moment(new Date(order_creation_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${order_creation_timezone ? order_creation_timezone.abbreviation || "" : ""}`,
        OrderCreationMethod: order_creation_method.method_name,
        OrderType: order_type.order_type_name || "-",
        OrderFrequency: order_frequency.frequency || "-",
        ParentAccountName: c.parent_account_name || "-",
        ParentAccountNumber: c.parent_account_number || "-",
        ChildAccountNumber: c.child_account_number || "-",
        ChildAccountName: c.child_account_name || "-",
        ChildAccountAddress: `${child_account_address1} ${child_account_address2} ${child_city ? `, ${child_city}` : ""} ${child_state ? child_state.state_name ? `, ${child_state.state_name}` : "" : ""} ${child_zip ? `, ${child_zip}` : ""}`,
        OrderingPhysician: c.ordering_physician || "-",
        PatientName: `${patient_first_name} ${patient_last_name}`,
        Phlebotomist: user ? user.name ? user.name : "-" : "-",
        PhlebEmpID: user ? user.employee_id ? user.employee_id : "-" : "-",
        Region: c.region ? c.region.region_name || "-" : "-",
        Territory: c.territory ? c.territory.territory_name || "-" : "-",
        Zone: c.zone ? c.zone.zone_name || "-" : "-",
        PatientDOB: !_.isEmpty(patient_dob) ? moment(new Date(`${patient_dob} 00:00:00`)).format("MM/DD/yyyy") : "-",
        PatientGender: c.gender ? c.gender.type || "-" : "-",
        PatientLocation: c.patient_location || "-",
        DropOffType: c.drop_point || "-",
        DropLocationName: c.drop_point_location || "-",
        TestCodes: TestCodes ? TestCodes.length > 0 ? TestCodes.join("; ") : "-" : "-",
        CancellationReason: cancellationNotes.length ? cancellationNotes.map(note => note.notes).join(", ") : "-",
        Notes: notesWithoutCancellation.length ? notesWithoutCancellation.map(note => note.notes).join(", ") : "-",
        OrderCreationUser: created_by ? created_by.name || "-" : "-",
        PatientLocation: patient_location || "-",
        NotifiedPerson: !_.isEmpty(notified_person) ? notified_person : "-",
        SupervisorName: !_.isEmpty(user) ? user.reporting_manager_name ? user.reporting_manager_name : "-" : "-",
        OrderCollectionMethod: collectionMethods,
        CollectionDate: collectionDates,
        TestName: TestDescriptions ? TestDescriptions.length > 0 ? TestDescriptions.join("; ") : "-" : "-",
        CheckIn: checkInDateTime ? checkInDateTime.action_datetime ? `${moment(new Date(checkInDateTime.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${checkInDateTime.timezone ? checkInDateTime.timezone.abbreviation || "" : ""}` : "-" : "-",
        CheckOut: checkOutDateTime ? checkOutDateTime.action_datetime ? `${moment(new Date(checkOutDateTime.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${checkOutDateTime.timezone ? checkOutDateTime.timezone.abbreviation || "" : ""}` : "-" : "-",
        LastUpdated: last_updated_datetime ? `${moment(new Date(last_updated_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${last_updated_timezone ? last_updated_timezone.abbreviation || "" : ""}` : "-",
        DropOff: dropOffDateTime ? dropOffDateTime.action_datetime ? `${moment(new Date(dropOffDateTime.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${dropOffDateTime.timezone ? dropOffDateTime.timezone.abbreviation || "" : ""}` : "-" : "-",
        order_creation_datetime_for_modal: order_creation_datetime,
      }
    })
    res.content = { orders, count };
    dispatch({
      type: STAT_GRID_PAGING,
      payload: res
    })
  } catch (error) {
    dispatch({ type: STAT_GRID_LOADING, payload: false });
  }
  dispatch({ type: STAT_GRID_LOADING, payload: false });
}
export const setHideFilter = (value) => ({
  type: TOGGLE_HIDE_FILTER,
  payload: value,
});

export const setStatOrdersList = (value) => ({
  type: STAT_CLEAR_DATA,
  payload: value,
});

export const statReducer = (state = initialState, action, gquery) => {

  switch (action.type) {
    case STAT_GRID_PAGING: {
      return ({
        ...state,
        data: action.payload
      })
    }
    case STAT_GRID_FILTERING: {
      return ({
        data: { result: action.payload, count: action.tCount }
      })
    }
    case STAT_GRID_SORTING: {
      return ({
        data: { result: action.payload, count: action.tCount }
      })
    }
    case STAT_GRID_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case TOGGLE_HIDE_FILTER: {
      return {
        ...state,
        hideFilter: action.payload
      };
    }
    case STAT_CLEAR_DATA: {
      return {
        ...state,
        data: []
      };
    }
    default: {
      return state;
    }
  }
}