import React, { useEffect } from "react";
import {
  ScheduleComponent, Inject, Resize, TimelineViews, ViewsDirective, ViewDirective, ResourcesDirective,
  ResourceDirective
} from '@syncfusion/ej2-react-schedule';
import moment from 'moment';
import { useRef } from 'react';
import Avatar from 'react-avatar';
import { UNASSIGNED_ORDERS, getURLbyEntity } from "../../app/utils/Constants";
import { useNavigate } from "react-router-dom";

const group = { enableCompactView: false, resources: ['Users'] }
const timeScale = { enable: true, interval: 30 };
const fields = {
  id: "order_id",
  subject: { name: "patient_full_name" },
  description: { name: "patient_last_name" },
  startTime: { name: 'preferred_start_date_time' },
  endTime: { name: 'preferred_end_date_time' },
}

const getUserImage = (value) => {
  const { icon_color_code = "", employee_id = "", reporting_manager_name = "", name } = value.resourceData || {}

  return <Avatar
    color={icon_color_code ? `#${icon_color_code}` : "#333745"}
    name={name}
    maxInitials={2}
    size={40}
    round="50px"
    className='sch-avatar-wrapper'
    title={`Emp ID: ${employee_id}\n${reporting_manager_name ? reporting_manager_name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : ""}`}
  />
};

const resourceHeaderTemplate = (props) => {
  const image = getUserImage(props);
  const { name } = props.resourceData || {}
  const { job_title, user_id } = props.resourceData || {}
  return (
    <div className="template-wrap pe-2 cah-user-details-wrap">
      <div className="details d-flex align-items-center mb-1">
        {user_id !== UNASSIGNED_ORDERS && user_id !== 0 && image && <div>{image}</div>}
        <p className="name ms-2 cah-patient-info">{name}</p>
      </div>
      <p className="jobtitle cah-patient-info">
        {' '}
        {job_title}
      </p>
    </div>
  );
};

const eventTemplate = (props) => {
  const {
    icon_name = "",
    order_status,
    patient_city,
    patient_state,
    patient_last_name,
    patient_first_name,
  } = props;
  const { order_status_name = "" } = order_status;
  return (
    <div className='template-wrap d-flex align-items-start justify-content-between'>
      <div className='d-flex' style={{ width: '81.5%' }}>
        <span className={`partner-icon ${icon_name ? icon_name.toLowerCase() : ""}`}>
          <span className='visually-hidden'>Patner Icon</span>
        </span>
        <div className='px-2 cah-info-wrapper'>
          <p className='cah-patient-info d-flex'>
            <span> <span>{patient_city}</span>,&nbsp;<span>{patient_state.state_code}</span></span>
          </p>
          <p className='cah-patient-info d-flex'>
            <span><span>{patient_first_name}</span>&nbsp;<span>{patient_last_name}</span></span>
          </p>
        </div>
      </div>
      <div className='hh-order-status'>
        <span className={`icon ${order_status_name.toLowerCase()}`}>
          <span className='visually-hidden'>order status Icon</span>
        </span>
      </div>
    </div>
  );
};

const tooltipTemplate = (props) => {
  const { start_date_time, end_date_time, preferred_end_date_time, preferred_start_date_time, patient_city, patient_state = {}, patient_full_name, order_status = {},
  preferred_timezone = {}, scheduled_timezone = {}, partner_name } = props;
  const { order_status_name = "" } = order_status;
  return (
    <div className="content-area cah-info-wrapper d-flex justify-content-between" style={{ fontSize: 16 }}>
      <div className='me-3'>            <p className='cah-patient-info'><span>{patient_city}</span>,&nbsp;<span>{patient_state.state_code}</span></p>
        <p className='cah-patient-info'>{patient_full_name}</p>
        <p className='cah-patient-info' style={{textTransform: "capitalize"}}>Partner: {partner_name}</p>
        <p className='cah-patient-info'>Preferred Date/Time&nbsp;{moment(preferred_start_date_time).format("MM/DD/YYYY")} {moment(preferred_start_date_time).format("hh:mm:ss A")} {preferred_timezone ? preferred_timezone.abbreviation || "" : ""} - {moment(preferred_end_date_time).format("hh:mm:ss A")} {preferred_timezone ? preferred_timezone.abbreviation || "" : ""}</p>
        <p className='cah-patient-info'>Actual Date/Time&nbsp;{moment(start_date_time).format("MM/DD/YYYY")} {moment(start_date_time).format("hh:mm:ss A")} {scheduled_timezone ? scheduled_timezone.abbreviation || "" : ""} - {moment(end_date_time).format("hh:mm:ss A")} {scheduled_timezone ? scheduled_timezone.abbreviation || "" : ""}</p>
      </div>
      <div className='hh-order-status'>
        <span className={`icon ${order_status_name.toLowerCase()}`}>
          <span className='visually-hidden'>order status Icon</span>
        </span>
      </div>
    </div>
  );
};

const Calendar = React.memo((props) => {
  const { date, calendarData, handleChangeDate, searchText = "", loading, isDataChanged, setIsDataChanged} = props;
  const [showUnassigned, setShowUnassigned] = React.useState(true);
  const scheduleObj = React.useRef(null);
  const navigate = useNavigate();

  const timezone = window.localStorage.getItem(`tz`)
  ? JSON.parse(window.localStorage.getItem(`tz`)) || {}
  : {name: "Eastern Time", id: 1, abbreviation: "EST"};

  const { users = [], order_appointments = [] } = calendarData || {};


  let dataSource = users;

  if (!showUnassigned) {
    dataSource = dataSource.filter(item => item.user_id !== UNASSIGNED_ORDERS);
  }

  const onPopupOpen = (args) => {
    args.cancel = true;
  }
  const handlePrevDay = () => {
    const newDate = moment(date).subtract(1, 'day').toDate();
    handleChangeDate(newDate);
  };

  const handleNextDay = () => {
    const newDate = moment(date).add(1, 'day').toDate();
    handleChangeDate(newDate);
  };

  const onDataBound = () => {
    if (isDataChanged) {
      scheduleObj.current.scrollTo('08:00');
      setIsDataChanged(false);
    }
  };

  const headerIndentTemplate = () => {
    return (<div className='e-resource-text me-3'>
      <div
        className={`ad-toggle-switch d-flex align-items-center`}
      >
        <span className="ad-switch font-size-13 roboto-regular">
          Show Unassigned Orders &nbsp;
        </span>
        <label
          className={`switch position-relative`}
        >
          <span className="visually-hidden">Show Unassigned Orders</span>
          <input
            type="checkbox"
            onChange={(e) => setShowUnassigned(e.target.checked)}
            value={showUnassigned}
            checked={showUnassigned}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </div>);
  }

  const onEventClick = (args) => {
    const {order_id} = args.event;
    if (order_id) {
      const url = getURLbyEntity("Home Health", order_id);
      navigate(url)
    }
  }

  let temp = [...order_appointments];
  temp = temp.map(t => {
    return {
      ...t,
      user_id: !t.user_id ? UNASSIGNED_ORDERS : t.user_id,
      preferred_start_date_time: new Date(t.preferred_start_date_time),
      preferred_end_date_time: new Date(t.preferred_end_date_time),
      patient_full_name: `${t.patient_first_name} ${t.patient_last_name}`
    }
  })
  return (
    <div className='position-relative ch-home-scheduler-mainwrapper'>
      <div className='cah-header-bar-right cah-header-bar'>
        <div className="d-flex align-items-center">
        {headerIndentTemplate()}
        <span
          className='mob-back cah-previous'
          onClick={handlePrevDay}
        ></span>
        <span
          className='cah-today'
          onClick={(e) => {
            handleChangeDate(new Date());
          }}
        >
          Today
        </span>
        <span className='mob-back cah-next' onClick={handleNextDay}></span>
        </div>
      </div>
      <div className='cah-header-bar-left cah-header-bar'>
        <span >
          {moment(date).format("ddd, MMM D, YYYY")} {timezone && timezone.abbreviation ? timezone.abbreviation : ""}
        </span>
      </div>
      <ScheduleComponent
        width='100%'
        height={`${window.innerHeight}px`}
        showHeaderBar={false}
        allowResizing={false}
        selectedDate={date}
        group={group}
        eventSettings={{
          dataSource: temp,
          fields,
          enableTooltip: true,
          tooltipTemplate,

        }}
        timeScale={timeScale}
        resourceHeaderTemplate={resourceHeaderTemplate}
        popupOpen={onPopupOpen}
        className='ch-home-scheduler-wrapper'
        ref={scheduleObj}
        rowAutoHeight
        delayUpdate
        dataBound={onDataBound}
        eventClick={onEventClick}
      >
        <ViewsDirective>
          <ViewDirective option='TimelineDay' eventTemplate={eventTemplate} />
        </ViewsDirective>
        <ResourcesDirective>
          <ResourceDirective
            field='user_id'
            name='Users'
            dataSource={!searchText ? dataSource : dataSource.filter(user => user.name.toLowerCase().search(searchText.toLowerCase()) !== -1)}
            textField='name'
            idField='user_id'
            title='User'
          ></ResourceDirective>
        </ResourcesDirective>
        <Inject services={[TimelineViews, Resize]} />
      </ScheduleComponent>
      {/* {!loading && (!dataSource.some(user => (!searchText || user.name.toLowerCase().search(searchText.toLowerCase()) !== -1))) && (
        <div className='search-no-records no-records-board d-flex justify-content-center align-items-center position-absolute' style={{ top: 72 }}>
          <p className='no-result-text'>No results found</p>
        </div>
      )} */}
    </div>
  );

});
export default Calendar;