import * as React from "react";
import _ from "lodash";
import { LocationDetails } from "./LocationDetails";
import { tzFilters } from "../../app/utils/Constants";

export const MapBox = (props) => {
	const { mapContainer, selectedLocation = {}, refreshMap, selectedFilter, closeMarker,
		filterOptionsLoading, mapFilterType, handleChangeMapFilterType, selectedOption, toggleTimezoneMode, tzFilter } = props;
	return (
		<React.Fragment>
			{!_.isEmpty(selectedFilter) && <button data-testid="refresh-button" className={`map-refresh-btn ${filterOptionsLoading ? 'refreshing' : ''}`} onClick={refreshMap} aria-label="Refresh Map" title="Refresh Map">
				{filterOptionsLoading ? 'Refreshing' : 'Refresh'}</button>}
			<nav id="filter-group" className="filter-group timezone-nav">
				{tzFilters.map(tzItem => {
					return (
						<>
							<input id={tzItem.id} type="checkbox" label={tzItem.value}
								onChange={() => toggleTimezoneMode(tzItem.value, tzItem.id)}
								checked={tzItem.value === "All" ? tzFilters.every(item => tzFilter.includes(item.value)) :tzFilter.includes(tzItem.value)}
							/>
							<label htmlFor={tzItem.id} className={tzItem.value.toLowerCase()}>{tzItem.value}</label>
						</>
					)
				})}
			</nav>
			{!_.isEmpty(selectedLocation) && <LocationDetails closeMarker={closeMarker} selectedLocation={selectedLocation} />}
			<div ref={mapContainer} />
			{!_.isEmpty(selectedOption) && <nav id="filter-group" className="filter-group ch-filter-wrapper">

				<input id="ch-facility" type="checkbox" label="Facility"
					onChange={() => handleChangeMapFilterType("facility")}
					value={mapFilterType.includes("facility")} checked={mapFilterType.includes("facility")}
				>
				</input>
				<label htmlFor="ch-facility">Facility</label>

				<input id="ch-Hospital" type="checkbox" label="Hospital"
					onChange={() => handleChangeMapFilterType("hospital")}
					value={mapFilterType.includes("hospital")} checked={mapFilterType.includes("hospital")}
				>
				</input>
				<label htmlFor="ch-Hospital">Hospital</label>

				<input id="ch-Lab" type="checkbox" label="Lab"
					onChange={() => handleChangeMapFilterType("lab")}
					value={mapFilterType.includes("lab")} checked={mapFilterType.includes("lab")}
				>
				</input>
				<label htmlFor="ch-Lab">Lab</label>

				<input id="ch-Phlebotomist" type="checkbox" label="Phlebotomist"
					onChange={() => handleChangeMapFilterType("phlebotomist")}
					value={mapFilterType.includes("phlebotomist")} checked={mapFilterType.includes("phlebotomist")}
				>
				</input>
				<label htmlFor="ch-Phlebotomist">Phlebotomist</label>
			</nav>}
		</React.Fragment>
	);
};
