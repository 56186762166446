import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: true,
};

export const postInvite = createAsyncThunk(
  "account/postInvite",
  async (inviteId, { rejectWithValue }) => {
    const response = await ahaApi.Account.invite(inviteId)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const inviteSlice = createSlice({
  name: "invite",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(postInvite.pending, (state) => {
        state.loading = true;
      })
      .addCase(postInvite.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(postInvite.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const inviteList = (state) =>
  state && state.invite && state.invite.value;
export const loading = (state) => state && state.invite && state.invite.loading;

export default inviteSlice.reducer;
