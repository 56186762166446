// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import {Amplify} from "aws-amplify";
import { appConfig } from "../Config";

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
      userPoolId: appConfig.cognitoUserPoolId,
      userPoolClientId: appConfig.cognitoAppClientId,
      identityPoolId: appConfig.cognitoIdentityPoolId,
      // region: appConfig.region,
      // identityPoolRegion: appConfig.region,
      }
    },
    Storage: {
      S3: {
              // REQUIRED - S3 bucket name for chat attachments
      bucket: appConfig.attachments_s3_bucket_name,
      // REQUIRED - Amazon S3 Region
      region: appConfig.region,
      },
      // REQUIRED - S3 bucket name for chat attachments
      bucket: appConfig.attachments_s3_bucket_name,
      // REQUIRED - Amazon S3 Region
      region: appConfig.region,
      // REQUIRED Amazon Cognito Identity Pool ID
      identityPoolId: appConfig.cognitoIdentityPoolId,
    },
  });
};
