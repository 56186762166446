import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const deleteExportFile = createAsyncThunk(
  "order/deleteExport",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.deleteExportFile(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const deleteExportFileSlice = createSlice({
  name: "deleteExport",
  initialState,
  reducers: {
    setDeleteExportFileData(state, action) {
      state.value = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteExportFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteExportFile.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(deleteExportFile.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const deleteExportFileDataResponse = (state) => state.deleteExportFileData.value;
export const deleteExportFileDataLoader = (state) => state.deleteExportFileData.loading;
export const { setDeleteExportFileData } = deleteExportFileSlice.actions;

export default deleteExportFileSlice.reducer;

