import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";


const initialState = {
  value: [],
  loading: false,
};

export const checkHomeHealthUserSchedule = createAsyncThunk(
  "homeHealth/checkHomeHealthUserSchedule",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.checkHomeHealthUserSchedule(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const homeHealthUserScheduleSlice = createSlice({
  name: "checkHomeHealthUserSchedule",
  initialState,
  reducers: {
    clearHomeHealthUserScheduleResponse(state, action) {
      state.value = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkHomeHealthUserSchedule.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkHomeHealthUserSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(checkHomeHealthUserSchedule.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const homeHealthUserScheduleList = (state) => state.checkHomeHealthUserSchedule.value;
export const homeHealthUserScheduleLoading = (state) => state.checkHomeHealthUserSchedule.loading;
export const { clearHomeHealthUserScheduleResponse } = homeHealthUserScheduleSlice.actions;
export default homeHealthUserScheduleSlice.reducer;
