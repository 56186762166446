import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DropDownTreeComponent } from "@syncfusion/ej2-react-dropdowns";
import React from "react";
import { Button, Modal, Tab, Table, Tabs } from "react-bootstrap";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { phoneFormat } from "../../../app/utils/testUtils";
import _ from "lodash";
import StatusModal from "../../../components/commons/StatusModal";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import moment from "moment";
import { CustomValueContainer, FACILITYLIST, OTHERS_TEXT, areEqualDatesTwo, customToFixed, escapeRegExp } from "../../../app/utils/Constants";
import ToastContent from "../../../components/layouts/ToastContent";
import * as XLSX from 'xlsx';
import ahaApi from "../../../app/services/aha-api";
import Select, { components } from "react-select";
import PlacesApiLoader from "../../../components/commons/PlacesApiLoader";
import { replace } from "react-router-dom";

const regexNumber = /^[0-9-()]*$/;
const alphanumeric = /^[\w-_.]*$/;
const notaplha = /^[0-9.-]*$/;

let facilityPermission =
  (window.localStorage.getItem("userDetail") &&
    JSON.parse(window.localStorage.getItem("userDetail")).permission &&
    JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
  [];
facilityPermission = facilityPermission.filter(
  (perm) => perm.page === "facilities"
);
const facilityAccess =
  facilityPermission.length === 1 ? facilityPermission[0].access : [];
const facilityUpdate =
  facilityAccess &&
    facilityAccess.find((item) => item === "update") === "update"
    ? true
    : false;

class FacilityAddEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef('');
    this.state = {
      errors: {},
      data: {
        willCall: {},
      },
      initialData: {
        willCall: {},
      },
      errors: {},
      isFormChanged: false,
      activeTab: 'info',
      searchText: '',
      clearMapboxSearchTextFacility: false,
      // mode: this.props.mode ? this.props.mode : "create",
    };
  }
  componentDidMount = () => {
    const { facilityData = {} } = this.props;
    this.updateFacilityData(facilityData);
  };
  componentDidUpdate = (prevProps) => {
    const {
      facilityData = {},
      mode,
      updateFList = {},
      addFacility = {},
    } = this.props;
    if (prevProps.facilityData !== facilityData) {
      this.updateFacilityData(facilityData);
    }
    if (prevProps.mode !== mode) {
      if (mode === "edit") {
        const { service_type = {} } = facilityData;
        if (service_type && service_type.id === 1) {
          this.setData({
            ...this.state.data,
            willCall: {},
          });
        }
      }
      // this.setState({ mode });
    }
    if (
      updateFList !== prevProps.updateFList &&
      Object.keys(updateFList).length !== 0
    ) {
      if (updateFList.status === "SUCCESS") {
        this.setData({});
        this.setState({ errors: {} });
      }
    }
    if (
      addFacility !== prevProps.addFacility &&
      Object.keys(addFacility).length !== 0
    ) {
      if (addFacility.status === "SUCCESS") {
        this.setData({});
        this.setState({ errors: {} });
      }
    }
    if (this.state.initialData !== this.state.data) {
      this.setState({
        isFormChanged: this.validateEditFields(this.state.initialData)
      })
    }
  };

  updateFacilityData = (facilityData) => {
    const { days = [], mode } = this.props;
    const {
      account_name,
      account_number,
      account_manager_name,
      account_manager_email,
      account_manager_phone,
      timezone = {},
      emergency_contact_name,
      emergency_contact_role,
      emergency_contact_phone,
      emergency_contact_email,
      facility_phone,
      street_address1,
      street_address2,
      city: selCity,
      state = {},
      zip: selZip,
      latitude,
      longitude,
      territory: terr = {},
      zone: selZone = {},
      facility_type = {},
      service_type = {},
      facility_notes,
      service_stop_day = {},
      id,
      status = {},
      service_days = [],
      emergency_contact_phone_extension,
      facility_phone_extension,
      activation_date,
      audit_histories = []
    } = facilityData;

    const routineServiceType = service_type
      ? service_type.id
        ? service_type.id
        : 1
      : 1;
    const serviceDays = {};
    const formattedDays = {};
    days.forEach((day) => {
      formattedDays[day.id] = day.name;
    });
    if (service_days && service_days.length) {
      service_days.forEach((day) => {
        serviceDays[formattedDays[day.day]] = true;
      });
    }

    const finalObj = {
      accountName: account_name || "",
      accountNumber: account_number || "",
      accountManagerName: account_manager_name,
      accountManagerEmail: account_manager_email || "",
      accountManagerPhone: account_manager_phone
        ? phoneFormat(account_manager_phone.toString())
        : "",
      facilityPhoneNumber: facility_phone
        ? phoneFormat(facility_phone.toString())
        : "",
      facilityPhoneNumberExt: facility_phone_extension || "",
      timezone: timezone
        ? timezone.timezone_id
          ? timezone.timezone_id
          : ""
        : "",
      emerName: emergency_contact_name || "",
      emerRole: emergency_contact_role || "",
      emerPhone: emergency_contact_phone
        ? phoneFormat(emergency_contact_phone.toString())
        : "",
      emerEmail: emergency_contact_email || "",
      emerPhoneExt: emergency_contact_phone_extension || "",
      address1: street_address1 || "",
      address2: street_address2 || "",
      city: selCity || "",
      stateName: state ? (state.state_code ? state.state_code : "") : "",
      zip: selZip,
      latitudeName: latitude ? latitude : "",
      longitudeName: longitude ? longitude : "",
      selectedTerritory: terr ? (terr.id ? [terr.id] : []) : [],
      selectedZone: selZone ? (selZone.id ? { ...selZone, value: selZone.id, label: selZone.zone_name } : "") : "",
      facilityName: facility_type
        ? facility_type.id
          ? facility_type.id
          : ""
        : "",

      routineServiceType,
      facilityNotes: facility_notes,
      routineServiceTimes: service_stop_day
        ? service_stop_day.id
          ? service_stop_day.id
          : 1
        : 1,
      id,
      activationDate: activation_date ? new Date(`${activation_date} 00:00:00`) : new Date(),
      statusName: status ? (status.id ? status.id : "") : "",
      serviceDays: routineServiceType === 1 ? serviceDays : {},
      willCall:
        mode === "create"
          ? {}
          : routineServiceType === 2
            ? serviceDays
            : {},
      audit_histories
    }

    this.setState({
      data: finalObj,
      initialData: finalObj
    });
  };

  validateEditFields = (initialData) => {
    let result = false;

    const {
      accountName = "",
      accountNumber = "",
      accountManagerName = "",
      accountManagerEmail = "",
      accountManagerPhone = "",
      facilityPhoneNumber = "",
      timezone = "",
      emerName = "",
      emerRole = "",
      emerPhone = "",
      emerEmail = "",
      address1 = "",
      address2 = "",
      city = "",
      stateName = "",
      zip = "",
      latitudeName = "",
      longitudeName = "",
      selectedTerritory = [],
      selectedZone = "",
      facilityName = "",
      routineServiceType = 1,
      willCall = {},
      serviceDays = {},
      routineServiceTimes = 1,
      facilityNotes = "",
      facilityPhoneNumberExt = "",
      emerPhoneExt = "",
      statusName = "",
      activationDate = new Date()
    } = this.state.data;

    const {
      accountName: prevAccountName = "",
      accountNumber: prevAccountNumber = "",
      accountManagerName: prevAccountManagerName = "",
      accountManagerEmail: prevAccountManagerEmail = "",
      accountManagerPhone: prevAccountManagerPhone = "",
      facilityPhoneNumber: prevFacilityPhoneNumber = "",
      emerName: prevEmerName = "",
      emerRole: prevEmerRole = "",
      emerPhone: prevEmerPhone = "",
      emerEmail: prevEmerEmail = "",
      address1: prevAddress = "",
      address2: prevAddress2 = "",
      city: prevCity = "",
      stateName: prevStateName = "",
      zip: prevZip = "",
      timezone: prevTimezone = "",
      longitudeName: prevLongitudeName = "",
      latitudeName: prevLatitudeName = "",
      selectedTerritory: prevSelectedTerritory = [],
      selectedZone: prevSelectedZone = "",
      facilityName: prevFacilityName = "",
      routineServiceType: prevRoutineServiceType = 1,
      willCall: prevWillCall = {},
      serviceDays: prevServiceDays = {},
      routineServiceTimes: prevRoutineServiceTimes = 1,
      facilityNotes: prevFacilityNotes = "",
      facilityPhoneNumberExt: prevFacilityPhoneNumberExt = "",
      emerPhoneExt: prevEmerPhoneExt = "",
      statusName: prevStatusName = "",
      activationDate: prevActivationDate = new Date()
    } = initialData;

    const allFieldsExceptTimingsFlag = !_.isEqual(accountName, prevAccountName) ||
      !_.isEqual(accountNumber, prevAccountNumber) ||
      !_.isEqual(accountManagerName, prevAccountManagerName) ||
      !_.isEqual(accountManagerEmail, prevAccountManagerEmail) ||
      !_.isEqual(accountManagerPhone, prevAccountManagerPhone) ||
      !_.isEqual(facilityPhoneNumber, prevFacilityPhoneNumber) ||
      !_.isEqual(facilityPhoneNumberExt, prevFacilityPhoneNumberExt) ||
      !_.isEqual(timezone, prevTimezone) ||
      !_.isEqual(emerName, prevEmerName) ||
      !_.isEqual(emerRole, prevEmerRole) ||
      !_.isEqual(emerPhone, prevEmerPhone) ||
      !_.isEqual(emerPhoneExt, prevEmerPhoneExt) ||
      !_.isEqual(emerEmail, prevEmerEmail) ||
      !_.isEqual(address1, prevAddress) ||
      !_.isEqual(city, prevCity) ||
      !_.isEqual(stateName, prevStateName) ||
      !_.isEqual(zip, prevZip) ||
      !_.isEqual(latitudeName ? latitudeName.toString() : latitudeName, prevLatitudeName ? prevLatitudeName.toString() : prevLatitudeName) ||
      !_.isEqual(longitudeName ? longitudeName.toString() : longitudeName, prevLongitudeName ? prevLongitudeName.toString() : prevLongitudeName) ||
      !_.isEqual(selectedZone, prevSelectedZone) ||
      !_.isEqual(facilityName, prevFacilityName) ||
      !_.isEqual(routineServiceType, prevRoutineServiceType) ||
      !_.isEqual(routineServiceTimes, prevRoutineServiceTimes) ||
      !_.isEqual(facilityNotes, prevFacilityNotes) ||
      !_.isEqual(statusName, prevStatusName) ||
      !_.isEqual(selectedTerritory[0], prevSelectedTerritory[0]) || !areEqualDatesTwo(activationDate, prevActivationDate)

    if (allFieldsExceptTimingsFlag) {
      result = allFieldsExceptTimingsFlag;
    } else if (!_.isEqual(willCall, prevWillCall)) {
      const prevTemp = Object.keys(prevWillCall).filter(d => prevWillCall[d]);
      const temp = Object.keys(willCall).filter(d => willCall[d]);
      if (!_.isEqual(prevTemp, temp)) {
        result = true;
      } else {
        result = allFieldsExceptTimingsFlag;
      }
    } else if (!_.isEqual(serviceDays, prevServiceDays)) {
      const prevTemp = Object.keys(prevServiceDays).filter(d => prevServiceDays[d]);
      const temp = Object.keys(serviceDays).filter(d => serviceDays[d]);
      if (!_.isEqual(prevTemp, temp)) {
        result = true;
      } else {
        result = allFieldsExceptTimingsFlag;
      }
    }
    return result;
  }

  setData = (data, fieldName) => {
    const errors = this.state.errors;
    errors[fieldName] = false;
    this.setState({ data, errors });
  };

  getNameById = (data, id, property = "name") => {
    let name = "";
    data.forEach((d) => {
      if (d.id == id) {
        name = d[property];
      }
    });
    return name;
  };

  handleTerritory = (e) => {
    let selectedZone = [];
    const { bc = [] } = this.props;
    const { data } = this.state;
    const { selectedTerritory = [] } = data;
    const { itemData = {} } = e;
    if (e.isInteracted) {
      if (!itemData.hasChildren) {
        this.setData(
          {
            ...this.state.data,
            selectedTerritory: [e.itemData.id],
            selectedZone: "",
          },
          "selectedTerritory"
        );
        // if (this.simpleDropdown) {
        //   this.simpleDropdown.clear();
        // }
      } else {
        if (selectedTerritory[0]) {
          if (this.dropdown) {
            this.dropdown.currentText = this.getNameById(
              bc,
              selectedTerritory[0]
            );
            this.dropdown.currentValue = selectedTerritory;
            this.dropdown.value = selectedTerritory;
            this.setData(
              {
                ...this.state.data,
                selectedTerritory: selectedTerritory,
              },
              "selectedTerritory"
            );
          }
        } else {
          if (this.dropdown) {
            this.dropdown.currentText = "";
            this.dropdown.currentValue = [];
            this.dropdown.value = [];
            this.dropdown.clear();
          }
        }
      }
    }
  };

  handleSubmit = (isConfirmDeleteSchedule = false, allZones = []) => {
    const { territory = [], days = [], mode = "", paramId, navigate } = this.props;
    const { data } = this.state;
    const errors = this.validateFields(data) || {};
    if (Object.keys(errors).length === 0) {
      const {
        accountName = "",
        accountNumber = "",
        accountManagerName = "",
        accountManagerEmail = "",
        accountManagerPhone = "",
        facilityPhoneNumber = "",
        timezone = "",
        emerName = null,
        emerRole = null,
        emerPhone = null,
        emerEmail = null,
        address1 = "",
        address2 = "",
        city = "",
        stateName = "",
        zip = "",
        latitudeName = null,
        longitudeName = null,
        selectedTerritory = [],
        selectedZone = "",
        facilityName = "",
        routineServiceType = 1,
        willCall = {},
        serviceDays = {},
        routineServiceTimes = 1,
        facilityNotes = "",
        id,
        emerPhoneExt = "",
        facilityPhoneNumberExt = "",
        statusName = "",
        activationDate = new Date()
      } = data;

      if (!allZones.some(z => z.value == selectedZone.value)) {
        this.setState({
          data: {
            ...data,
            selectedZone: ""
          },
          errors: {
            "selectedZone": true
          }
        }, () => {
          toast(
            (t) => (
              <ToastContent type="error" message={`Check if all required fields have been filled in before you ${mode === "edit" ? "update" : "create"} a facility`} close={() => toast.remove()} />
            ),
            {
              duration: 4000,
            }
          );
          if (document.querySelector('.error-message')) {
            document.querySelector('.error-message').scrollIntoView({
              behavior: 'smooth'
            });
          } else if (document.querySelector('.error-message-text')) {
            document.querySelector('.error-message-text').scrollIntoView({
              behavior: 'smooth'
            });
          }
        });
        return;
      }

      let selectedServiceDays = [];
      if (routineServiceType == 2) {
        Object.keys(willCall).forEach((value) => {
          if (days.some((d) => d.name === value && willCall[value])) {
            const id = days.filter((d) => d.name === value)
              ? days.filter((d) => d.name === value)[0]
                ? days.filter((d) => d.name === value)[0].id
                : 0
              : 0;

            id && selectedServiceDays.push(id);
          }
        });
      } else {
        Object.keys(serviceDays).forEach((value) => {
          if (days.some((d) => d.name === value && serviceDays[value])) {
            const id = days.filter((d) => d.name === value)
              ? days.filter((d) => d.name === value)[0]
                ? days.filter((d) => d.name === value)[0].id
                : 0
              : 0;

            id && selectedServiceDays.push(id);
          }
        });
      }

      let regionId = this.getNameById(
        territory,
        selectedTerritory[0],
        "region_id"
      );
      regionId = parseInt(regionId);

      let finalTerr = selectedTerritory[0] || "";
      finalTerr = finalTerr.split(":");
      finalTerr = finalTerr[0] ? finalTerr[0] : "";

      let finalZone = selectedZone.value;
      finalZone = finalZone.split(":");
      finalZone = finalZone[0] ? finalZone[0] : "";

      let managerNumb;
      if (typeof accountManagerPhone === "string") {
        managerNumb = accountManagerPhone.match(/\d/g);
        managerNumb = managerNumb.join("");
      } else {
        managerNumb = accountManagerPhone;
      }

      let facilityPhone;
      if (typeof facilityPhoneNumber === "string") {
        facilityPhone = facilityPhoneNumber.match(/\d/g);
        facilityPhone = facilityPhone.join("");
      } else {
        facilityPhone = facilityPhoneNumber;
      }

      let emergencyPhone;
      if (typeof emerPhone === "string") {
        emergencyPhone = emerPhone.match(/\d/g);
        emergencyPhone = emergencyPhone ? emergencyPhone.join("") : null;
      } else {
        emergencyPhone = emerPhone;
      }

      const payload = {
        id,
        // account_name: accountName,
        // account_number: accountNumber,
        account_name: accountName,
        account_number: accountNumber,
        account_manager_name: accountManagerName,
        account_manager_email: accountManagerEmail || null,
        account_manager_phone_number: Number(managerNumb),
        facility_phone_number: Number(facilityPhone),
        facility_phone_number_extension: facilityPhoneNumberExt,
        timezone_id: timezone,
        emergency_contact_name: emerName,
        emergency_contact_role: emerRole,
        emergency_contact_phone_number: Number(emergencyPhone)
          ? Number(emergencyPhone)
          : null,
        emergency_contact_phone_number_extension: emerPhoneExt,
        emergency_contact_email: emerEmail || null,
        address1,
        address2,
        city,
        state: stateName,
        zip: zip,
        region_id: regionId,
        territory_id: Number(finalTerr),
        zone_id: Number(finalZone),
        facility_type_id: facilityName,
        facility_notes: facilityNotes,
        service_type_id: routineServiceType,
        service_stop_day: routineServiceTimes,
        status: statusName,
        service_days: selectedServiceDays,
        latitude: Number(latitudeName) ? Number(latitudeName) : null,
        longitude: Number(longitudeName) ? Number(longitudeName) : null,
        activation_date: activationDate ? moment(activationDate).format("YYYY-MM-DD") : ""
      };
      if (mode === "create") {
        this.props.handleCreateFacility(payload);
      } else {
        payload.delete_schedule = isConfirmDeleteSchedule;
        this.props.handleUpdateFacility(payload);
      }
    } else {
      this.setState({ errors }, () => {
        toast(
          (t) => (
            <ToastContent type="error" message={`Check if all required fields have been filled in before you ${mode === "edit" ? "update" : "create"} a facility`} close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
        if (document.querySelector('.error-message')) {
          document.querySelector('.error-message').scrollIntoView({
            behavior: 'smooth'
          });
        } else if (document.querySelector('.error-message-text')) {
          document.querySelector('.error-message-text').scrollIntoView({
            behavior: 'smooth'
          });
        }
      });
    }
    if (paramId) {
      navigate(FACILITYLIST, { replace: true });
    }
  };

  validateFields = (data) => {
    const errors = {};

    const {
      accountName = "",
      accountNumber = "",
      accountManagerName = "",
      accountManagerPhone = "",
      facilityPhoneNumber = "",
      timezone = "",
      address1 = "",
      city = "",
      stateName = "",
      statusName = "",
      zip = "",
      selectedTerritory = [],
      status = "",
      selectedZone = "",
      facilityName = "",
      willCall = {},
      serviceDays = {},
      routineServiceType = 1,
      activationDate = new Date()
    } = data;

    // if (_.isEmpty(accountName)) {
    //   errors["accountName"] = true;
    // }
    // if (_.isEmpty(accountNumber)) {
    //   errors["accountNumber"] = true;
    // }
    if (_.isEmpty(accountManagerName)) {
      errors["accountManagerName"] = true;
    }
    if (_.isEmpty(accountManagerPhone)) {
      errors["accountManagerPhone"] = true;
    }
    if (_.isEmpty(facilityPhoneNumber)) {
      errors["facilityPhoneNumber"] = true;
    }
    if (!timezone) {
      errors["timezone"] = true;
    }
    if (_.isEmpty(address1)) {
      errors["address1"] = true;
    }
    if (_.isEmpty(city)) {
      errors["city"] = true;
    }
    if (_.isEmpty(stateName)) {
      errors["stateName"] = true;
    }
    if (_.isEmpty(zip)) {
      errors["zip"] = true;
    }
    if (_.isEmpty(selectedTerritory[0])) {
      errors["selectedTerritory"] = true;
    }
    if (!selectedZone || !selectedZone.value) {
      errors["selectedZone"] = true;
    }
    if (facilityName === "") {
      errors["facilityName"] = true;
    }
    if (_.isEmpty(statusName)) {
      errors["statusName"] = true;
    }
    if (_.isEmpty(accountName)) {
      errors["accountName"] = true;
    }
    if (_.isEmpty(accountNumber)) {
      errors["accountNumber"] = true;
    }
    if (!activationDate) {
      errors["activationDate"] = true;
    }
    if (routineServiceType == 1) {
      if (
        !Object.keys(serviceDays).length ||
        Object.keys(serviceDays).every((day) => !serviceDays[day])
      ) {
        errors["serviceDays"] = true;
      }
    } else {
      if (
        !Object.keys(willCall).length ||
        Object.keys(willCall).every((day) => !willCall[day])
      ) {
        errors["willCall"] = true;
      }
    }
    return errors;
  };
  handleManagerPhone = (value) => {
    this.setData(
      {
        ...this.state.data,
        accountManagerPhone: phoneFormat(value),
      },
      "accountManagerPhone"
    );
  };
  handleFacilityPhone = (value) => {
    this.setData(
      {
        ...this.state.data,
        facilityPhoneNumber: phoneFormat(value),
      },
      "facilityPhoneNumber"
    );
  };
  handleEmergencyPhone = (value) => {
    this.setData(
      {
        ...this.state.data,
        emerPhone: phoneFormat(value),
      },
      "emerPhone"
    );
  };

  handleZip = (e) => {
    const val = e.target.value.replace(/\D/g, "");
    this.setData({ ...this.state.data, zip: val }, "zip");
  };

  handleClose = (e) => {
    const { mode, paramId, navigate } = this.props;
    const { activeTab } = this.state;
    if (activeTab === 'audit') {
      this.setState({ errors: {}, searchText: '', activeTab: 'info', clearMapboxSearchTextFacility: true });
      this.props.handleFacilityError("create");
      this.props.handleClose();
    } else {
      if (mode === "view" || mode === "create") {
        this.setState({ errors: {}, searchText: '', activeTab: 'info', clearMapboxSearchTextFacility: true });
        this.props.handleFacilityError("create");
        this.props.handleClose();
      } else {
        const { facilityData = {} } = this.props;
        this.updateFacilityData(facilityData);
        this.setState({ errors: {}, searchText: '', activeTab: 'info', clearMapboxSearchTextFacility: true });
        this.props.handleFacilityError("view");
      }
    }
    if (paramId) {
      navigate(FACILITYLIST, { replace: true });
    }
  };

  onFocus = (event) => {
    event.target.setAttribute('autocomplete', 'none');
  }

  handleExcelExport = (e, data) => {
    e.preventDefault();
    const modifiedData = [...data];
    const modifiedHeaders = this.renameHeaders(modifiedData);
    const ws = XLSX.utils.json_to_sheet(modifiedHeaders);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${'Facility-AuditLogs'}.xlsx`);
  }

  handleInputChange = (e) => {
    const userInput = e.target.value;
    const escapedInput = escapeRegExp(userInput);
    const regex = new RegExp(`(${escapedInput})`);
    this.setState({ searchText: escapedInput });
  };

  handleChangeTab = (val) => {
    this.setState({ searchText: '', activeTab: val });
  }

  renameHeaders = (originalData) => {
    return originalData.map((item) => ({
      'Date/Time': item.datetimeString,
      'Changed By': item.changedByString,
      'Change': item.activity,
    }));
  };

  clearSearchInput = () => {
    this.setState({ searchText: '' }, () => {
      if (this.searchRef && this.searchRef.current) {
        this.searchRef.current.value = "";
      }
    })
  }

  handleMapChange = async (newData, isInvalid = false) => {
    let { timeZones, setStatusModalType, mode } = this.props;
    const { data, errors } = this.state;

    errors["isInvalid"] = isInvalid;
    errors["address1"] = false;
    errors["city"] = false;
    errors["stateName"] = false;
    errors["zip"] = false;
    errors["latitudeName"] = false;
    errors["longitudeName"] = false;
    let formData = {
      ...data,
      ...newData
    }
    if (newData.latitudeName && newData.longitudeName) {
      const response = await ahaApi.Account.getTimezone({
        coordinates: { latitude: newData.latitudeName, longitude: newData.longitudeName }
      });
      const { content = {} } = response;
      const { timezone_response = {} } = content;
      if (timezone_response && timezone_response.timezone_id) {
        if (timezone_response.timezone_name === OTHERS_TEXT) {
          formData.timezone = ""
          formData.address1 = ""
          formData.city = ""
          formData.stateName = ""
          formData.zip = ""
          formData.latitudeName = ""
          formData.longitudeName = ""

          errors["isInvalid"] = true;
          this.setState({
            data: formData,
            errors
          }, () => {
            if (this.tzDropdown) {
              this.tzDropdown.value = "";
              this.tzDropdown.currentValue = "";
              this.tzDropdown.clear();
            }
            setStatusModalType({
              value: true,
              status: "confirmation",
              type: "invalidTimezone",
              title: "The entered address is located in a timezone we do not service.",
              subtitle: `Please enter an address within the ${timeZones.length} serviceable timezones: ${timeZones.map(tz => tz.name).join(", ")} to proceed with the profile ${mode === "create" ? "creation" : "updation"}.`
            })
          })
        } else {
          formData.timezone = timezone_response.timezone_id
        }
      } else {
        formData.timezone = "";
      }
    }
    this.setState({
      data: formData,
      errors,
    }, () => {
      if (!formData.timezone && this.tzDropdown) {
        this.tzDropdown.value = "";
        this.tzDropdown.currentValue = "";
        this.tzDropdown.clear();
      }
    });
  };

  render() {
    const {
      facilityModal = false,
      error,
      days = [],
      zone = [],
      timeZones = [],
      states = [],
      status = {},
      facilityTypes = [],
      facilityServiceType = [],
      bc = [],
      facilityData = {},
      serviceStopDays = [],
      statusModalType,
      setStatusModalType,
      mode,
      facilityObjLoading
    } = this.props;

    const { errors, data, isFormChanged, activeTab, searchText, clearMapboxSearchTextFacility } = this.state;
    const { setData, handleSubmit, handleClose, onFocus, handleInputChange, handleExcelExport, handleChangeTab } = this;

    const {
      accountName = "",
      accountNumber = "",
      accountManagerName = "",
      accountManagerEmail = "",
      accountManagerPhone = "",
      facilityPhoneNumber = "",
      timezone = "",
      emerName = "",
      emerRole = "",
      emerPhone = "",
      emerEmail = "",
      address1 = "",
      address2 = "",
      city = "",
      stateName = "",
      zip = "",
      latitudeName = "",
      longitudeName = "",
      selectedTerritory = [],
      selectedZone = "",
      facilityName = "",
      routineServiceType = 1,
      willCall = {},
      serviceDays = {},
      routineServiceTimes = 1,
      facilityNotes = "",
      facilityPhoneNumberExt = "",
      emerPhoneExt = "",
      statusName = "",
      activationDate = new Date(),
    } = data;

    const { audit_histories = [] } = facilityData;

    const headers = [
      { label: "Date/ Time", key: "datetimeString" },
      { label: "Changed By", key: "changedByString" },
      { label: "Change", key: "activity" }
    ];


    let zones = zone.filter((val) => {
      return val.territory_id === parseInt(selectedTerritory[0]);
    });
    zones = zones.map(z => { return { ...z, label: z.name, value: z.id } });

    // if (selectedTerritory[0]) {
    //   let terrId = selectedTerritory[0].split(":");
    //   terrId = terrId[0] ? terrId[0] : "";
    //   zone.forEach((z) => {
    //     let zoneRelatedTerrId = z.id.split(":");
    //     zoneRelatedTerrId = zoneRelatedTerrId[1] ? zoneRelatedTerrId[1] : "";
    //     if (zoneRelatedTerrId === terrId) {
    //       zones.push(z);
    //     }
    //   });
    // }

    const { territory = {} } = facilityData;
    const territoryForViewAndEdit = territory.id ? [territory.id] : [];

    const filteredTerr = bc.filter((b) => b.region_id);
    const disabledTerr = territory.id ? territory.id : "";

    let auditLogs = [];
    audit_histories && audit_histories.length > 0 && audit_histories.forEach((history) => {
      const {
        user = {},
        activity,
        datetime = "",
        type,
      } = history;
      const datetimeString = moment(datetime).format("MM/DD/YYYY HH:mm:ss");
      const changedByString = `${user
        ? `${user.name}${user.username ? ` (${user.username})` : ""
        }` || ""
        : ""}
      ${type
          ? ` (${type})`
          : activity === "Order Updated"
            ? ` (DESKTOP)`
            : ""}`
      if ((!searchText || activity.toLowerCase().search(searchText.toLowerCase()) !== -1 ||
        datetimeString.toLowerCase().search(searchText.toLowerCase()) !== -1 || changedByString.toLowerCase().search(searchText.toLowerCase()) !== -1)) {
        auditLogs.push({
          ...history,
          activity, datetimeString, changedByString
        })
      }
    });


    return (
      <div>
        <Modal
          show={facilityModal}
          onHide={handleClose}
          className="ad-adduser-modal"
          backdrop="static"
          centered
          enforceFocus={false}
          data-testid="modal-unique"
        >
          <div
            className={"ad-loading-wrapper " + (facilityObjLoading ? "d-block" : "d-none")}
          >
            <span
              className={"loader ad-loader " + (facilityObjLoading ? "d-block" : "d-none")}
            >
              <span className="visually-hidden">loader icon</span>
            </span>
          </div>
          <Modal.Header closeButton>
            {mode === "view" ? (
              <Modal.Title className="headline4">Care Facility</Modal.Title>
            ) : mode === "create" ? (
              <React.Fragment>
                <Modal.Title className="headline4">
                  {" "}
                  Add New Care Facility
                </Modal.Title>
                {error && <p className="error-message-alert">{error}</p>}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Modal.Title className="headline4">
                  {" "}
                  Edit Care Facility
                </Modal.Title>
                {error && <p className="error-message-alert">{error}</p>}
              </React.Fragment>
            )}
          </Modal.Header>
          <Modal.Body>
            {mode === "create" ? <p className="headline6">Account Information</p> : <div className="ad-headerbottom-wrapper">
              <Tabs className="ad-navtab help-nav-tab" activeKey={activeTab} onSelect={(k) => handleChangeTab(k)} >
                <Tab
                  eventKey={'info'}
                  title="Account Information"
                  className="stat-order-wrapper"
                ></Tab>
                <Tab
                  eventKey={'audit'}
                  title="Audit Logs"
                  className="stat-order-wrapper"
                ></Tab>
              </Tabs>
            </div>}
            {activeTab === 'info' && <form className="aha-modalform-wrapper ad-care-modalform-wrapper">
              {/* <div
                className={
                  "aha-modalform-group  flex-50 " +
                  (errors.accountName ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="acf-name">
                  Account Name
                </label>
                <input
                  data-testid="field-accountName"
                  type="text"
                  id="acf-name"
                  value={accountName}
                  placeholder="Account Name"
                  autoComplete="nope"
                  onFocus={onFocus}
                  required
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        accountName: e.target.value,
                      },
                      "accountName"
                    );
                  }}
                  disabled={mode === "view"}
                />
                {errors.accountName && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " +
                  (errors.accountNumber ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="acf-acno">
                  Account Number
                </label>
                <input
                  data-testid="field-accountNumber"
                  // type="text"
                  name="f-account"
                  id="acf-acno"
                  placeholder="Account Number"
                  value={accountNumber}
                  autoComplete="nope"
                  onFocus={onFocus}
                  required
                  onChange={(e) => {
                    alphanumeric.test(e.target.value) &&
                      setData(
                        {
                          ...data,
                          accountNumber: e.target.value,
                        },
                        "accountNumber"
                      );
                  }}
                  disabled={mode === "view"}
                />
                {errors.accountNumber && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div> */}
              <div
                className={
                  "aha-modalform-group  flex-50 " +
                  (errors.accountName ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="acf-pacname">
                  Parent Account Name
                </label>
                <input
                  data-testid="field-parentAccountName"
                  type="text"
                  id="acf-pacname"
                  autoComplete="nope"
                  onFocus={onFocus}
                  placeholder="Parent Account Name"
                  value={accountName}
                  required
                  onChange={(e) => {
                    setData({
                      ...data,
                      accountName: e.target.value,
                    },
                      "accountName"
                    );
                  }}
                  disabled={mode === "view"}
                />
                {errors.accountName && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <br />
              <div
                className={
                  "aha-modalform-group mr-3 " +
                  (errors.accountNumber ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="acf-pacno">
                  Parent Account Number
                </label>
                <input
                  type="text"
                  id="acf-pacno"
                  placeholder="Parent Account Number"
                  autoComplete="nope"
                  onFocus={onFocus}
                  data-testid="field-parentAccountNumber"
                  value={accountNumber}
                  required
                  onChange={(e) => {
                    alphanumeric.test(e.target.value) &&
                      setData({
                        ...data,
                        accountNumber: e.target.value,
                      },
                        "accountNumber"
                      );
                  }}
                  disabled={mode === "view"}
                />
                {errors.accountNumber && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className="aha-modalform-group3">
                <div
                  className={
                    "aha-modalform-group " +
                    (errors.accountManagerName ? "error-message" : "")
                  }
                >
                  <label className="caption" htmlFor="acf-accmanager">
                    Account Manager
                  </label>
                  <input
                    type="text"
                    id="acf-accmanager"
                    placeholder="Account Manager"
                    autoComplete="nope"
                    onFocus={onFocus}
                    data-testid="field-accountManagerName"
                    required
                    value={accountManagerName}
                    onChange={(e) => {
                      setData(
                        {
                          ...data,
                          accountManagerName: e.target.value,
                        },
                        "accountManagerName"
                      );
                    }}
                    disabled={mode === "view"}
                  />
                  {errors.accountManagerName && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
                <div className="aha-modalform-group">
                  <label className="caption" htmlFor="acf-accmanager-email">
                    Account Manager Email (Optional)
                  </label>
                  <input
                    type="text"
                    id="acf-accmanager-email"
                    placeholder="Account Manager Email (Optional)"
                    autoComplete="nope"
                    onFocus={onFocus}
                    data-testid="field-accountManagerEmail"
                    value={accountManagerEmail}
                    onChange={(e) => {
                      setData({
                        ...data,
                        accountManagerEmail: e.target.value,
                      });
                    }}
                    disabled={mode === "view"}
                  />
                </div>
                <div
                  className={
                    "aha-modalform-group mr-0 " +
                    (errors.accountManagerPhone ? "error-message" : "")
                  }
                >
                  <label className="caption" htmlFor="acf-accmanager-phone">
                    Account Manager Phone Number
                  </label>
                  <input
                    className="input"
                    type="text"
                    name="phone"
                    id="acf-accmanager-phone"
                    placeholder="Account Manager Phone Number"
                    autoComplete="nope"
                    onFocus={onFocus}
                    data-testid="field-accountManagerPhone"
                    required
                    value={data.accountManagerPhone}
                    onChange={(e) => {
                      this.handleManagerPhone(e.target.value);
                    }}
                    disabled={mode === "view"}
                  />
                  {errors.accountManagerPhone && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
                <div
                  className={
                    "aha-modalform-group " +
                    (errors.facilityPhoneNumber ? "error-message" : "")
                  }
                >
                  <div className="form-phext-wrapper">
                    <div className="phno-field">
                      <label className="caption" htmlFor="acf-facility-phno">
                        Facility Contact Phone Number
                      </label>
                      <input
                        type="text"
                        name="fphone"
                        id="acf-facility-phno"
                        className="form-phext-input"
                        data-testid="field-facilityPhoneNumber"
                        autoComplete="nope"
                        onFocus={onFocus}
                        placeholder="Facility Contact Phone Number"
                        required
                        value={facilityPhoneNumber}
                        onChange={(e) => {
                          this.handleFacilityPhone(e.target.value);
                        }}
                        disabled={mode === "view"}
                      />
                    </div>
                    <div className="extension-field">
                      <label
                        className="caption"
                        htmlFor="acf-facility-phno-extension"
                      >
                        <span className="visually-hidden">
                          Extension (optional)
                        </span>
                      </label>
                      <input
                        // type="number"
                        className="form-phext-input"
                        autoComplete="nope"
                        onFocus={onFocus}
                        id="acf-facility-phno-extension"
                        data-testid="field-facExt"
                        placeholder="Ext"
                        onChange={(e) => {
                          regexNumber.test(e.target.value) &&
                            setData({
                              ...data,
                              facilityPhoneNumberExt: e.target.value,
                            });
                        }}
                        value={facilityPhoneNumberExt}
                        disabled={mode === "view"}
                      />
                    </div>
                  </div>
                  {errors.facilityPhoneNumber && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
                <div className="aha-modalform-group">
                  <label className="caption" htmlFor="acf-secondary-contact">
                    Emergency Contact Name (Optional)
                  </label>
                  <input
                    type="text"
                    id="acf-secondary-contact"
                    placeholder="Emergency contact Name (Optional)"
                    autoComplete="nope"
                    onFocus={onFocus}
                    value={emerName}
                    data-testid="field-emerName"
                    onChange={(e) => {
                      setData({
                        ...data,
                        emerName: e.target.value,
                      });
                    }}
                    disabled={mode === "view"}
                  />
                </div>
                <div className="aha-modalform-group">
                  <label className="caption" htmlFor="acf-sec-contact-role">
                    Emergency Contact Role (Optional)
                  </label>
                  <input
                    type="text"
                    id="acf-sec-contact-role"
                    placeholder="Emergency Contact Role"
                    autoComplete="nope"
                    onFocus={onFocus}
                    value={emerRole}
                    data-testid="field-emerRole"
                    onChange={(e) => {
                      setData({
                        ...data,
                        emerRole: e.target.value,
                      });
                    }}
                    disabled={mode === "view"}
                  />
                </div>
                <div className="aha-modalform-group">
                  <div className="form-phext-wrapper">
                    <div className="phno-field">
                      <label className="caption" htmlFor="ad-contact-phone">
                        Emergency Contact Phone (Optional)
                      </label>
                      <input
                        type="text"
                        name="emphone"
                        id="ad-contact-phone"
                        className="form-phext-input"
                        autoComplete="nope"
                        onFocus={onFocus}
                        data-testid="field-emerPhone"
                        placeholder="Emergency Contact Phone"
                        value={data.emerPhone}
                        onChange={(e) => {
                          this.handleEmergencyPhone(e.target.value);
                        }}
                        disabled={mode === "view"}
                      />
                    </div>
                    <div className="extension-field">
                      <div className="extension-field">
                        <label
                          className="caption"
                          htmlFor="ad-contact-phone-extension"
                        >
                          <span className="visually-hidden">
                            Extension (optional)
                          </span>
                        </label>
                        <input
                          // type="number"
                          className="form-phext-input"
                          id="ad-contact-phone-extension"
                          autoComplete="nope"
                          onFocus={onFocus}
                          placeholder="Ext"
                          data-testid="field-eExt"
                          onChange={(e) => {
                            regexNumber.test(e.target.value) &&
                              setData({
                                ...data,
                                emerPhoneExt: e.target.value,
                              });
                          }}
                          value={emerPhoneExt}
                          disabled={mode === "view"}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="aha-modalform-group mr-0">
                  <label className="caption" htmlFor="ad-contact-email">
                    Emergency Contact Email (Optional)
                  </label>
                  <input
                    type="text"
                    id="ad-contact-email"
                    placeholder="Emergency Contact Email"
                    value={emerEmail}
                    data-testid="field-emerEmail"
                    autoComplete="nope"
                    onFocus={onFocus}
                    onChange={(e) => {
                      setData({
                        ...data,
                        emerEmail: e.target.value,
                      });
                    }}
                    disabled={mode === "view"}
                  />
                </div>
              </div>
              <div
                className={
                  "aha-modalform-group flex-75 mr-3 " +
                  (errors.isInvalid ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="ad-search-address">
                  Search Address
                </label>
                <PlacesApiLoader
                  id="ad-search-address"
                  pageType="facility"
                  handleChange={this.handleMapChange}
                  stateData={data}
                  disabled={mode === "view"}
                  clearMapboxSearchTextFacility={clearMapboxSearchTextFacility}
                  setClearMapboxSearchTextFacility={(val) => this.setState({ clearMapboxSearchTextFacility: val })}
                />
                {errors.isInvalid &&
                  <p data-testid="error-text-id" className="error-message-text">
                    Invalid Address
                  </p>}
              </div>
              <div className={
                "aha-modalform-group flex-75 aha-modalform-group flex-75 mr-3 " +
                (errors.address1 ? "error-message" : "")
              }>
                <label className="caption" htmlFor="ad-street-address2">
                  Street Address 1
                </label>
                <input
                  type="text"
                  id="ad-street-street-address"
                  placeholder="Street Address 1"
                  autoComplete="nope"
                  onFocus={onFocus}
                  value={address1}
                  data-testid="field-address1"
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        address1: e.target.value,
                      },
                      "address1"
                    );
                  }}
                  // disabled={mode === "view"}
                  disabled={true}
                />
                {errors.address1 && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " + (errors.city ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="ad-city">
                  City
                </label>
                <input
                  type="text"
                  id="ad-city"
                  placeholder="City"
                  data-testid="field-city"
                  autoComplete="nope"
                  onFocus={onFocus}
                  value={city}
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        city: e.target.value,
                      },
                      "city"
                    );
                  }}
                  // disabled={mode === "view"}
                  disabled={true}
                />
                {errors.city && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " +
                  (errors.stateName ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="ad-state">
                  State
                </label>
                <input
                  type="text"
                  id="ad-state"
                  placeholder="State"
                  data-testid="field-state"
                  autoComplete="nope"
                  onFocus={onFocus}
                  value={stateName}
                  onChange={(e) => {
                    setData(
                      {
                        ...data,
                        stateName: e.target.value,
                      },
                      "stateName"
                    );
                  }}
                  // disabled={mode === "view"}
                  disabled={true}
                />
                {errors.stateName && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group " + (errors.zip ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="ad-zip">
                  Zip
                </label>
                <input
                  type="text"
                  name="zip"
                  id="ad-zip"
                  placeholder="Zip"
                  data-testid="field-zip"
                  autoComplete="nope"
                  onFocus={onFocus}
                  value={data.zip}
                  onChange={this.handleZip}
                  // disabled={mode === "view"}
                  disabled={true}
                />
                {errors.zip && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div
                className={
                  "aha-modalform-group mr-0 " +
                  (errors.timezone ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="acf-timezone">
                  Timezone
                </label>
                <DropDownListComponent
                  id="acf-timezone"
                  cssClass="modal-droprdown-input modal-popup-dropdown"
                  placeholder="Please choose"
                  dataSource={timeZones}
                  data-testid="field-timezone"
                  fields={{ text: "name", value: "id" }}
                  required
                  filterBarPlaceholder="Timezone"
                  allowFiltering={true}
                  enabled={mode !== "view"}
                  value={timezone}
                  change={(e) => {
                    if (e.isInteracted) {
                      const { itemData = {} } = e;
                      setData(
                        {
                          ...data,
                          timezone: itemData.id,
                        },
                        "timezone"
                      );
                    }
                  }}
                  ref={(comp) => {
                    this.tzDropdown = comp;
                  }}
                />
                {errors.timezone && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
              <div className="aha-modalform-group">
                <label className="caption" htmlFor="acf-latitude">
                  Latitude (Optional)
                </label>
                <input
                  data-testid="field-Lattitude"
                  id="acf-lat"
                  name="latitude"
                  value={latitudeName}
                  autoComplete="nope"
                  onFocus={onFocus}
                  placeholder="Latitude (Optional)"
                  onChange={(e) => {
                    if (
                      (notaplha.test(e.target.value) &&
                        (isNaN(parseFloat(e.target.value)) ||
                          (parseFloat(e.target.value) <= 90 &&
                            parseFloat(e.target.value) >= -90))) ||
                      e.target.value === ""
                    ) {
                      setData({
                        ...data,
                        latitudeName: e.target.value,
                      });
                    }
                  }}
                  disabled={mode === "view"}
                />
              </div>
              <div className="aha-modalform-group">
                <label className="caption" htmlFor="acf-longitude">
                  Longitude (Optional)
                </label>
                <input
                  data-testid="field-longitude"
                  id="acf-longn"
                  name="longitude"
                  value={longitudeName}
                  autoComplete="nope"
                  onFocus={onFocus}
                  placeholder="Longitude (Optional)"
                  onChange={(e) => {
                    if (
                      (notaplha.test(e.target.value) &&
                        (isNaN(parseFloat(e.target.value)) ||
                          (parseFloat(e.target.value) <= 180 &&
                            parseFloat(e.target.value) >= -180))) ||
                      e.target.value === ""
                    ) {
                      setData({
                        ...data,
                        longitudeName: e.target.value,
                      });
                    }
                  }}
                  disabled={mode === "view"}
                />
              </div>
            </form>}
            {activeTab === 'info' && <p className="headline6">Service Information</p>}
            {activeTab === 'info' && <form className="aha-modalform-wrapper ad-care-modalform-wrapper">
              <div className="aha-modalform-group2">
                <div
                  className={
                    "aha-modalform-group rt-dropdown-wrapper " +
                    (errors.selectedTerritory ? "error-message" : "")
                  }
                >
                  <label className="caption" htmlFor="acf-region-territory">
                    Region/Territory
                  </label>
                  {mode === "view" ? (
                    <DropDownListComponent
                      id="acf-region-territory"
                      cssClass="modal-droprdown-input modal-popup-dropdown"
                      required
                      data-testid="field-disabledTerr"
                      placeholder="Search Regions/Territories"
                      dataSource={filteredTerr}
                      allowFiltering={true}
                      fields={{ value: "id", text: "name" }}
                      filterBarPlaceholder="Search Regions/Territories"
                      value={disabledTerr}
                      enabled={false}
                    />
                  ) : (
                    <DropDownTreeComponent
                      filterBarPlaceholder="Search Regions/Territories"
                      cssClass="rt-dropdown-dialog rt-facility-drop"
                      placeholder="Search Regions/Territories"
                      popupHeight="220px"
                      id="acf-region-territory"
                      data-testid="field-selectedTerritory"
                      required
                      fields={{
                        dataSource: bc,
                        value: "id",
                        parentValue: "region_id",
                        text: "name",
                        hasChildren: "hasTrue",
                      }}
                      select={(e) => {
                        this.handleTerritory(e);
                      }}
                      showClearButton={false}
                      ref={(comp) => {
                        this.dropdown = comp;
                      }}
                      enabled={mode !== "view"}
                      value={mode === "create" ? [] : territoryForViewAndEdit}
                    />
                  )}
                  {errors.selectedTerritory && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
                <div
                  className={
                    "aha-modalform-group " +
                    (errors.selectedZone ? "error-message" : "")
                  }
                >
                  <label className="caption" htmlFor="acf-zone">
                    Zone
                  </label>
                  <Select
                    placeholder="Zone"
                    id="acf-zone"
                    className="sch-schedule-dropdown territory-dropdown"
                    classNamePrefix="sch-schedule-select"
                    options={zones}
                    value={selectedZone}
                    components={{ ValueContainer: CustomValueContainer }}
                    onChange={(e) => {
                      setData(
                        {
                          ...data,
                          selectedZone: e,
                        },
                        "selectedZone"
                      );
                    }}
                    isDisabled={mode === "view"}
                    isSearchable
                  />
                  {errors.selectedZone && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
                <div
                  className={
                    "aha-modalform-group activation-date-field " +
                    (errors.activationDate ? "error-message" : "")
                  }
                >
                  <label className="caption" htmlFor="acf-activationDate">
                    Activation Date
                  </label>
                  <DatePicker
                    selected={activationDate}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Activation Date"
                    name="acf-activationDate"
                    id="acf-activationDate"
                    onChange={(e) => {
                      setData({ ...data, activationDate: e }, "activationDate");
                    }}
                    data-testid="field-patient-dob"
                    disabled={mode === "view"}
                    filterDate={(date) => {
                      return moment().subtract(1, 'days') < date;
                    }}
                    // minDate={new Date()}
                    calendarClassName="st-react-datepicker-wrapper"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  {errors.activationDate && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-50">
                <div
                  className={
                    "aha-modalform-group " +
                    (errors.facilityName ? "error-message" : "")
                  }
                >
                  <label className="caption" htmlFor="acf-facility-type">
                    Facility Type
                  </label>
                  <DropDownListComponent
                    id="acf-facility-type"
                    required
                    cssClass="modal-droprdown-input modal-popup-dropdown"
                    dataSource={facilityTypes}
                    fields={{ value: "id", text: "name" }}
                    allowFiltering={true}
                    data-testid="field-facilityType"
                    filterBarPlaceholder="Facility Type"
                    value={facilityName}
                    placeholder="Please choose"
                    enabled={mode !== "view"}
                    change={(e) => {
                      if (e.isInteracted) {
                        const { itemData = {} } = e;
                        setData(
                          {
                            ...data,
                            facilityName: itemData.id,
                          },
                          "facilityName"
                        );
                      }
                    }}
                  />
                  {errors.facilityName && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
                <div className="aha-form-group ad-radio-group">
                  <label className="mb-0 roboto-regular form-label" htmlFor="rsd">
                    Routine Service Type
                  </label>
                  <div className="d-flex mb-15" id="rsd">
                    {facilityServiceType.map((type) => {
                      return (
                        <div className="form-check" key={type.id}>
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`routine-service-days-${type.id}`}
                            value={routineServiceType === type.id}
                            checked={routineServiceType === type.id}
                            data-testid="field-routineServiceType"
                            onChange={(e) => {
                              setData({
                                ...data,
                                routineServiceType: type.id,
                              });
                            }}
                            disabled={mode === "view"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`routine-service-days-${type.id}`}
                          >
                            {type.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {routineServiceType == 2 ? (
                  <div className="aha-form-group ad-weekday-group">
                    <label className="caption mb-10">
                      Select any Will Call Days that should be visible in the
                      Schedule
                      <span className="info-icon modal-info-icon tooltip-modal">
                        <span className="visually-hidden">
                          Information Icon
                        </span>{" "}
                        <span className="tooltiptext-modal">
                          Select any Will Call Days that should be visible in
                          the Schedule
                        </span>
                      </span>
                    </label>
                    <div className="ac-weekdays-selector">
                      {days &&
                        days.map((val) => (
                          <React.Fragment key={val.id}>
                            <input
                              type="checkbox"
                              id={`ac-weekday-${val.name.toLowerCase()}`}
                              data-testid="field-willCall"
                              onChange={(e) =>
                                setData(
                                  {
                                    ...data,
                                    willCall: {
                                      ...willCall,
                                      [val.name]: e.target.checked,
                                    },
                                  },
                                  "willCall"
                                )
                              }
                              value={willCall[val.name] || false}
                              checked={willCall[val.name] || false}
                              disabled={mode === "view"}
                            />
                            <label
                              htmlFor={`ac-weekday-${val.name.toLowerCase()}`}
                            >
                              {val.name.substr(0, 1)}
                            </label>
                          </React.Fragment>
                        ))}
                    </div>
                    {errors.willCall && (
                      <p
                        data-testid="error-text-id"
                        className="error-message-text font-size-12 facility-service-error"
                      >
                        Selection Required
                      </p>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="aha-form-group ad-weekday-group mb-10">
                      <label className="caption mb-10">
                        Select Routine Service Days
                        <span className="info-icon modal-info-icon tooltip-modal">
                          <span className="visually-hidden">
                            Information Icon
                          </span>{" "}
                          <span className="tooltiptext-modal">
                            Select Routine Service Days
                          </span>
                        </span>
                      </label>

                      <div className="ac-weekdays-selector">
                        {days &&
                          days.map((val) => (
                            <React.Fragment key={val.id}>
                              <input
                                type="checkbox"
                                // id="ac-weekday-sun"
                                id={`ac-weekday-${val.name.toLowerCase()}`}
                                data-testid="field-serviceDays"
                                onChange={(e) =>
                                  setData(
                                    {
                                      ...data,
                                      serviceDays: {
                                        ...serviceDays,
                                        [val.name]: e.target.checked,
                                      },
                                      // nonServiceDays: {
                                      //   ...nonServiceDays,
                                      //   [val.name]: false,
                                      // },
                                    },
                                    "serviceDays"
                                  )
                                }
                                value={serviceDays[val.name] || false}
                                checked={serviceDays[val.name] || false}
                                disabled={mode === "view"}
                              />
                              <label
                                htmlFor={`ac-weekday-${val.name.toLowerCase()}`}
                              >
                                {val.name.substr(0, 1)}
                              </label>
                            </React.Fragment>
                          ))}
                      </div>
                      {errors.serviceDays && (
                        <p
                          data-testid="error-text-id"
                          className="error-message-text font-size-12 facility-service-error"
                        >
                          Selection Required
                        </p>
                      )}
                    </div>
                    <div className="aha-form-group ad-radio-group">
                      <label className="mb-0 roboto-regular form-label">
                        On this facility's Routine Service Days, how often are
                        we required to stop?
                      </label>
                      <div className="d-flex mb-10">
                        {serviceStopDays.map((day) => {
                          return (
                            <div className="form-check" key={day.id}>
                              <input
                                className="form-check-input"
                                type="radio"
                                id={`routine-service-${day.id}x`}
                                value={routineServiceTimes == day.id}
                                data-testid="field-routineServiceTimes"
                                checked={routineServiceTimes == day.id}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    routineServiceTimes: day.id,
                                  });
                                }}
                                disabled={mode === "view"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`routine-service-${day.id}x`}
                              >
                                {day.name} Day
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* <div className="aha-form-group ad-weekday-group">
                      <label className="caption mb-10">
                        Select any Non-Service Days that should be visible in
                        the Schedule
                        <span className="info-icon modal-info-icon tooltip-modal">
                          <span className="visually-hidden">
                            Information Icon
                          </span>{" "}
                          <span className="tooltiptext-modal">
                            Select any Non-Service Days that should be visible
                            in the Schedule
                          </span>
                        </span>
                      </label>
                      <div className="ac-weekdays-selector">
                        {days &&
                          days.map((val) => (
                            <React.Fragment key={val.id}>
                              <input
                                type="checkbox"
                                // id="ac-weekday-sun"
                                id={`${val.name.toLowerCase()}`}
                                data-testid="field-nonServiceDays"
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    nonServiceDays: {
                                      ...nonServiceDays,
                                      [val.name]: e.target.checked,
                                    },
                                  })
                                }
                                value={nonServiceDays[val.name] || false}
                                checked={nonServiceDays[val.name] || false}
                                disabled={
                                  mode === "view" || serviceDays[val.name]
                                }
                              />
                              <label for={`${val.name.toLowerCase()}`}>
                                {val.name.substr(0, 1)}
                              </label>
                            </React.Fragment>
                          ))}
                      </div>
                    </div> */}
                  </div>
                )}
              </div>

              <div className="aha-modalform-group flex-50 mr-0">
                <label className="caption" htmlFor="acf-facility-note">
                  Facility Notes
                </label>
                <textarea
                  placeholder="Facility Notes"
                  id="acf-facility-note"
                  value={facilityNotes}
                  data-testid="field-facilityNotes"
                  onChange={(e) => {
                    setData({
                      ...data,
                      facilityNotes: e.target.value,
                    });
                  }}
                  disabled={mode === "view"}
                />
                <div
                  className={
                    "aha-modalform-group facility-status-drop " +
                    (errors.statusName ? "error-message" : "")
                  }
                >
                  <label htmlFor="status">Status</label>
                  <DropDownListComponent
                    id="status"
                    cssClass="modal-droprdown-input modal-popup-dropdown"
                    dataSource={[
                      { id: "ACTIVE", name: "Active" },
                      { id: "INACTIVE", name: "Inactive" },
                    ]}
                    fields={{ text: "name", value: "id" }}
                    data-testid="field-status"
                    placeholder="Status"
                    value={statusName}
                    change={(e) => {
                      if (e.isInteracted) {
                        const { itemData = {} } = e;
                        setData(
                          {
                            ...data,
                            statusName: itemData.id,
                          },
                          "statusName"
                        );
                      }
                    }}
                    enabled={mode !== "view"}
                  />
                  {errors.statusName && (
                    <p data-testid="error-text-id" className="error-message-text">
                      Selection Required
                    </p>
                  )}
                </div>
              </div>
            </form>}
            {activeTab === 'audit' && <form className="auditlog-wrapper">
              <div className="auditlog-top">
                <div
                  className={
                    "search-box"
                  }
                >
                  <input
                    type="text"
                    id="ad-search"
                    placeholder="Search"
                    data-testid="search"
                    onChange={(e) => handleInputChange(e)}
                    // value={searchText}
                    onFocus={onFocus}
                    ref={this.searchRef}
                  />
                  {searchText && <span className="default-clear-btn" onClick={e => this.clearSearchInput()}><span className="visually-hidden">Clear Icon</span></span>}
                  <span className="search-icon"><span className="visually-hidden">Search Icon</span></span>
                </div>
                <div className="export-excel-btn">
                  <button disabled={!auditLogs.length} onClick={(e) => handleExcelExport(e, auditLogs)}>
                    <span className="e-excelexport"></span>Excel Export
                  </button>
                </div>
              </div>
              <div
                className={
                  "auditlog-list"
                }
              >
                {audit_histories && audit_histories.length > 0 &&
                  <Table responsive borderless className="audit-log-table">
                    <thead>
                      <tr>
                        {headers.map((header, index) => {
                          return (
                            <th key={index}>{header.label}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {auditLogs.map((history, index) => {
                        const {
                          changedByString = "",
                          activity = "",
                          datetimeString = "",
                          abbreviation
                        } = history;
                        return ((!searchText || activity.toLowerCase().search(searchText.toLowerCase()) !== -1 ||
                          datetimeString.toLowerCase().search(searchText.toLowerCase()) !== -1 || changedByString.toLowerCase().search(searchText.toLowerCase()) !== -1) &&
                          <tr key={index}>
                            <td>
                              {datetimeString} {abbreviation || ""}
                            </td>
                            <td>
                              {changedByString}
                            </td>
                            <td>{activity}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>}
                {auditLogs.length == 0 && <p>No logs.</p>}
              </div>

            </form>}
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-testid="button-cancel"
              className="primary-btn primary-btn-outline outline"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            {activeTab === 'info' ? mode === "view" ? (
              this.props.editFacility && (
                <Button
                  data-testid="button-edit"
                  className="primary-btn primary-btn-outline outline"
                  onClick={() => {
                    this.setState({ isFormChanged: false }, () => {
                      this.props.editFacilityModal(true, { data: facilityData }, "edit");
                    })
                  }}
                >
                  Edit Facility
                </Button>
              )
            ) : (
              <ProgressButtonComponent
                data-testid="button-submit"
                content={mode === "edit" ? "Save" : "Create Facility"}
                enableProgress={true}
                spinSettings={{ position: "Center" }}
                animationSettings={{ effect: "SlideLeft" }}
                cssClass="primary-btn primary-btn-outline ad-progress-btn"
                onClick={() => handleSubmit(false, zones)}
                disabled={mode === "edit" ? !isFormChanged : false}
              ></ProgressButtonComponent>
            ) : null}
          </Modal.Footer>
        </Modal>
        <StatusModal
          modalType={statusModalType}
          show={statusModalType.value || false}
          hideModal={() => setStatusModalType({ value: false })}
          title={statusModalType.title || ""}
          subtitle={statusModalType.subtitle || ""}
          confirmUpdate={() => handleSubmit(true, zones)}
        />
      </div>
    );
  }
}
export default FacilityAddEdit;
