import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const getLab = createAsyncThunk(
  "lab/getLab",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.getLab(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const labSlice = createSlice({
  name: "lab",
  initialState,
  reducers: {
    setLabList(state, action) {
      state.value = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLab.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLab.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getLab.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const lab = (state) => state.lab.value;
export const labObjLoader = (state) => state.lab.loading;
export const { setLabList } = labSlice.actions;

export default labSlice.reducer;
