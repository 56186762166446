import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import uuid from "react-uuid";
import { UNASSIGNED_ORDERS } from "../../../app/utils/Constants";
import ahaApi from "../../../app/services/aha-api";

const sortUsersToKeepUnassignedAtTop = (a, b) => {
  if (a.name === UNASSIGNED_ORDERS) {
    return -1; // a should come before b
  }
  else if (b.id === UNASSIGNED_ORDERS) {
    return 1; // b should come before a
  }
  else {
    return 0;
  }
};

const initialState = {
  value: [],
  loading: false,
};

export const homeHealthScheduleList = createAsyncThunk(
  "homeHealthSchedule/list",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.homeHealthScheduleList(data)
      .then((res) => {
        let {users=[]} = res.content || {}
        let tempUsers = [...users];
        let finalUsers = tempUsers.map(user => { return { ...user, name: !user.user_id ? UNASSIGNED_ORDERS : user.name, user_id: !user.user_id ? UNASSIGNED_ORDERS : user.user_id } });
        finalUsers = finalUsers.sort((a, b) => a.name.localeCompare(b.name))
        finalUsers.sort(sortUsersToKeepUnassignedAtTop);
        if (!finalUsers.length) {
          finalUsers = [{
            name: "No results found",
            user_id: 0}]
        }
        res.content.users = finalUsers
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);
export const homeHealthScheduleListSlice = createSlice({
  name: "homeHealthScheduleList",
  initialState,
  reducers: {
    updateScheduleListValue(state, action) {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(homeHealthScheduleList.pending, (state) => {
        state.loading = true;
      })
      .addCase(homeHealthScheduleList.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(homeHealthScheduleList.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const homeHealthScheduleListList = (state) => state.homeHealthScheduleList.value;
export const homeHealthScheduleListLoading = (state) => state.homeHealthScheduleList.loading;

export const { updateScheduleListValue } = homeHealthScheduleListSlice.actions;

export default homeHealthScheduleListSlice.reducer;
