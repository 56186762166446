import _ from 'lodash';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from '../../../app/services/aha-api';

const initialState = {
  value: [],
  loading: false,
};

export const markers = createAsyncThunk(
  "tracker/markers",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.getMarkers(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    
    return response;
  }
);
export const markersSlice = createSlice({
  name: "markers",
  initialState,
  reducers: {
    clearMarkers(state, action) {
      state.value = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(markers.pending, (state) => {
        state.loading = true;
      })
      .addCase(markers.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(markers.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const markersData = (state) => state.markersFeatures ? state.markersFeatures.value || [] : [];
export const markersLoader = (state) => state.markersFeatures ? state.markersFeatures.loading || false : false;
export const {clearMarkers} = markersSlice.actions;

export default markersSlice.reducer;
