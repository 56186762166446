/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect, useState } from "react";
import {
  Cell,
  Grid,
  Heading,
  useNotificationDispatch,
} from "amazon-chime-sdk-component-library-react";
import { useTheme } from "styled-components";

import ChannelsWrapper from "../../admincontainers/channels/ChannelsWrapper";
import ChannelPresence from "../../admincontainers/Presence/ChannelPresence";
import Messages from "../../admincontainers/messages/Messages";
import Input from "../../admincontainers/input/Input";
import TypingIndicator from "../../admincontainers/TypingIndicator";
import {
  useChatChannelState,
  useChatMessagingState,
} from "../../providers/ChatMessagesProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  MessageType,
  Persistence,
  createMemberArn,
  listAppInstanceUsers,
  listChannelMembershipsForAppInstanceUser,
  listChannels,
  sendChannelMessage,
} from "../../api/ChimeAPI";

import "../../../../assets/styles/pluginchat.scss";
import _ from "lodash";

import { Dropdown } from "react-bootstrap";
import Avatar from "react-avatar";
import { CommonHeader } from "../../../../components/CommonHeader";
import {
  chatClass,
  GROUP_CHANNEL_TYPE,
  INDIVIDUAL_CHANNEL_TYPE,
  GROUP_CHANNEL_TYPE_TWO,
  CHAT_TYPES,
  ALL_USERS_GROUP,
  routes,
  adminChatClass
} from "../../../../app/utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { subSetUsersList, subSetUsersLoader } from "../../../landingPage/operations/subSetUserSlice";
import { clearDeleteServiceResponse } from "../../../scheduler/operations/deleteServiceSlice";
import { postToken, tokenList } from "../../../accounts/login/operations/tokenSlice";
import { appConfig } from "../../Config";
import Select, { components } from "react-select";
import MultiContactPicker from "../../components/MultiContactPicker";
import { useIdentityService } from "../../providers/IdentityProvider";
import { Option, customStyles, multiValueContainer } from "../../../scheduler/Scheduler";
import mergeArrayOfObjects from "../../utilities/mergeArrays";
// var b = document.getElementsByTagName("body")[0];
// b.className += "chat-page";

const Channels = (props) => {
  const currentTheme = useTheme();
  const { activeChannelMemberships } = useChatChannelState();
  const { member, userSignOut, useCognitoIdp } = useAuthContext();
  const { userId } = useAuthContext().member;
  const identityClient = useIdentityService();
  const tokenData = useSelector(tokenList);
  const { messages, messagesRef, setMessages, onReceiveMessage } =
    useChatMessagingState();
  const notificationDispatch = useNotificationDispatch();

  const {
    setChannelMessageToken,
    setChannelList,
    activeChannel,
    activeChannelRef,
    channelList,
    hasMembership,
    readMarker,
    setActiveChannel
  } = useChatChannelState();
  const [cognitoUsers, setCognitoUsers] = useState([]);
  const dispatch = useDispatch();
  const subSetUsers = useSelector(subSetUsersList);
  const subSetUsersLoading = useSelector(subSetUsersLoader);
  const [chatLoader, setChatLoader] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [chatType, setChatType] = useState([CHAT_TYPES[0]]);
  const [searchResults, setSearchResults] = useState([]);

  const memberArns = activeChannelMemberships ? activeChannelMemberships.map((mem) => mem.Member.Arn) : [];

  useEffect(() => {
    if (subSetUsers && subSetUsers.status === "SUCCESS") {
      setCognitoUsers(subSetUsers.content || []);
    } else if (subSetUsers && subSetUsers && subSetUsers.status === "ERROR") {
    } else if (subSetUsers.status === "ERROR") {
    }
  }, [subSetUsers]);

  useEffect(() => {
    if (!identityClient) return;
    if (useCognitoIdp) {
      identityClient.setupClient();
    }
    // getAllUsers();
  }, [identityClient]);

  useEffect(() => {
    document.documentElement.classList.add(adminChatClass);
    document.documentElement.classList.add(chatClass);
    setChannelList([]);
    setActiveChannel({});
  }, []);
  React.useEffect(
    () => () => {
      //clear redux state
      document.documentElement.classList.remove(adminChatClass);
      document.documentElement.classList.remove(chatClass);
    },
    []
  );

  const loadOptions = async (searchText) => {
    let text = "given_name ^= " + '"' + searchText + '"';
    return await getAllUsers(text);
  };

  const getAllUsers = (searchText) => {
    // either approach works, but if you have an IDP it is likely other apps will use IDP to find users so why not reuse here
    if (useCognitoIdp) {
      return getAllUsersFromCognitoIdp(searchText);
    } else {
      return getAllUsersFromListAppInstanceUsers();
    }
  };

  const getAllUsersFromCognitoIdp = async (searchText) => {
    let users = await identityClient.getUsers(60, searchText);

    let list = users.map((user) => {
      if (getUserAttributeByName(user, "profile") !== "none") {
        const { Attributes = [], Username = "" } = user;
        const firstName = Attributes.filter((att) => att.Name === "given_name")
          ? Attributes.filter((att) => att.Name === "given_name")[0]
            ? Attributes.filter((att) => att.Name === "given_name")[0].Value
            : ""
          : "";
        const middleName = Attributes.filter(
          (att) => att.Name === "middle_name"
        )
          ? Attributes.filter((att) => att.Name === "middle_name")[0]
            ? Attributes.filter((att) => att.Name === "middle_name")[0].Value
            : ""
          : "";
        const lastName = Attributes.filter((att) => att.Name === "family_name")
          ? Attributes.filter((att) => att.Name === "family_name")[0]
            ? Attributes.filter((att) => att.Name === "family_name")[0].Value
            : ""
          : "";
        const iconColorCode = Attributes.filter(
          (att) => att.Name === "nickname"
        )
          ? Attributes.filter((att) => att.Name === "nickname")[0]
            ? Attributes.filter((att) => att.Name === "nickname")[0].Value
            : ""
          : "";
        // const fullName = firstName + `${_.isEmpty(middleName) ? " " : ` ${middleName} `}` + lastName;
        const fullName = firstName + " " + lastName;
        const fullNameForInitials = firstName + " " + lastName;
        return {
          label: user.Username,
          value: user.Attributes.filter((attr) => attr.Name === "profile")[0]
            .Value,
          fullName,
          fullNameForInitials,
          iconColorCode,
        };
      }
      return false;
    });
    list = list.filter((l) => l);
    list = list.filter((user) => {
      return memberArns.indexOf(createMemberArn(user.value)) === -1;
    });
    // setUsersList(list);
    return list;
  };

  const getAllUsersFromListAppInstanceUsers = async () => {
    let list = [];
    let users = await listAppInstanceUsers(appConfig.appInstanceArn, userId);
    list = users.map((user) => {
      return {
        label: user.Name,
        value: user.AppInstanceUserArn.split("/user/")[1],
      };
    });
    list = list.filter((user) => {
      return memberArns.indexOf(createMemberArn(user.value)) === -1;
    });
    // setUsersList(list)
    return list;
  };

  const getUserAttributeByName = (user, attribute) => {
    try {
      return user.Attributes.filter((attr) => attr.Name === attribute)[0].Value;
    } catch (err) {
      return "none";
      // throw new Error(`Failed at getUserAttributeByName() with error: ${err}`);
    }
  };

  const handlePickerChange = (change) => {
    setSelectedMembers(change);
  }

  const handleChangeChatType = (val) => {
    console.log(val);
    setChatType(val);
  }

  const fetchChannels = async (newUserId) => {
    const userChannelMemberships =
      await listChannelMembershipsForAppInstanceUser(userId, null, newUserId);

    const userChannelList = userChannelMemberships.map((channelMembership) => {
      const { AppInstanceUserMembershipSummary = {}, ChannelSummary } =
        channelMembership;
      return {
        ...ChannelSummary,
        ReadMarkerTimestamp:
          AppInstanceUserMembershipSummary.ReadMarkerTimestamp,
      };
    });
    // const publicChannels = await listChannels(appConfig.appInstanceArn, userId);
    const finalChannels = mergeArrayOfObjects(
      userChannelList,
      [],
      "ChannelArn"
    );
    return finalChannels;
  };

  const handleSearchResults = async () => {
    setActiveChannel({});
    if (!selectedMembers || !selectedMembers.length) {
      setChannelList([]);
    } else {
      try {
        setChatLoader(true);
        const userChannels = selectedMembers.map(async (member) => {
          const response = await fetchChannels(member.value);
          return response;
        })
        let allUserChannels = await Promise.all(userChannels);
        let mergedResult = [];
        for (let channels of allUserChannels) {
          mergedResult = mergedResult.concat(channels);
        }
        // let finalResult = [];
        // mergedResult.forEach(ch => {
        //   if (ch.Name.includes(INDIVIDUAL_CHANNEL_TYPE) && chatType && chatType.some(type => type.value == 1)) {
        //     finalResult.push(ch);
        //   }
        //   if ((ch.Name.includes(GROUP_CHANNEL_TYPE) || ch.Name.includes(GROUP_CHANNEL_TYPE_TWO)) && chatType && chatType.some(type => type.value == 2)) {
        //     finalResult.push(ch);
        //   }
        // });
        let finalResult = mergedResult.reduce((acc, ch) => {
          if (
              (ch.Name.includes(INDIVIDUAL_CHANNEL_TYPE) && chatType && chatType.some(type => type.value == 1)) ||
              ((ch.Name.includes(GROUP_CHANNEL_TYPE) || ch.Name.includes(GROUP_CHANNEL_TYPE_TWO)) && chatType && chatType.some(type => type.value == 2))
          ) {
              const isGroupChannelTypeTwo = ch.Name.startsWith(GROUP_CHANNEL_TYPE_TWO);
              const found = acc.some(channel => channel.Name === ch.Name);
              if (!found) {
                  acc.push(ch);
              }
          }
          return acc;
      }, []);
        setChannelList(finalResult);
        setChatLoader(false);
      } catch (err) {
        setChatLoader(false);
      }
    }
  }

  const showChannelMembers =
    !_.isEmpty(activeChannel) &&
    !JSON.parse(activeChannel.Metadata || "{}").isMeeting &&
    activeChannelMemberships.length > 1;
  let { Name = "" } = activeChannel || {};
  if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
    Name = Name.replace(GROUP_CHANNEL_TYPE, "");
    Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, "");
  } else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
    Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
    const channelMembers = activeChannelMemberships.map(
      (mem) => mem.Member.Name
    );
    let allUsers = Name.split(", ");
    if (allUsers.includes(member.username)) {
      allUsers = allUsers.filter((user) => user !== member.username);
    }
    allUsers.forEach((username) => {
      if (!channelMembers.includes(username)) {
        allUsers = allUsers.filter((user) => user !== username);
      }
    });
    Name = allUsers.join(", ");
  }

  let new_array = messages.map(function (ele) {
    return { ...ele, ReadMarkerTimestamp: readMarker };
  });

  let allCognitoUsers = cognitoUsers || [];
  allCognitoUsers = Object.keys(allCognitoUsers).map(id => {
    return {
      ...allCognitoUsers[id],
      username: id,
      label: allCognitoUsers[id].name,
      value: allCognitoUsers[id].id
    }
  });
  return (
    <>
      <CommonHeader title="Chat Audit" />
      <div
        className={"ad-loading-wrapper " + (subSetUsersLoading ? "d-block" : "d-none")}
      >
        <span
          className={"loader ad-loader " + (subSetUsersLoading ? "d-block" : "d-none")}
        >
          <span className="visually-hidden">loader icon</span>
        </span>
      </div>
      <div className="ac-top-wrapper stat-order-wrapper ">
        <div className="ac-top-select-people-wrapper">
        <MultiContactPicker
          onChange={handlePickerChange}
          loadOptions={loadOptions}
          className="sch-statlisting-dropdown ac-select-contact"
          classNamePrefix="sch-statlisting-select"
          placeholder="Add People"
        />
        </div>
        <div>
        <Select
          name="usertype"
          id="usertype"
          className="sch-statlisting-dropdown ac-chat-type-select"
          classNamePrefix="sch-statlisting-select"
          // className="sch-copycal-dropdown sch-facility-dropdown"
          // classNamePrefix="sch-copycal-select sch-facility-select sch-statlisting"
          placeholder="Select Chat Type"
          options={CHAT_TYPES}
          value={chatType}
          onChange={handleChangeChatType}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          components={{
            Option: Option,
            MultiValueContainer: multiValueContainer,
          }}
          allowSelectAll={true}
          styles={customStyles}
          isSearchable={false}
        />
        <button className="primary-btn" onClick={() => handleSearchResults()}>Search</button>
          </div>
      
        
      </div>
      <Grid
        className="aha-chat-wrapper"
      >
        <Cell gridArea="side" className="left-chat-wrapper border-chat">
          {/* SIDEPANEL CHANNELS LIST */}
          <ChannelsWrapper setChatLoader={setChatLoader} chatType={chatType} />
        </Cell>
        <Cell gridArea="main" className="right-chat-wrapper border-chat">
          {/* MAIN CHAT CONTENT WINDOW */}
          {chatLoader && <div className="ad-loading-wrapper">
            <span className="loader ad-loader d-block">
              <span className="visually-hidden">loader icon</span>
            </span>
          </div>} 
           {activeChannel.ChannelArn ? (
            <>
              <Messages
                messages={new_array}
                messagesRef={messagesRef}
                setMessages={setMessages}
                currentMember={member}
                onReceiveMessage={onReceiveMessage}
                setChannelList={setChannelList}
                channelList={channelList}
                channelArn={activeChannelRef.current.ChannelArn}
                setChannelMessageToken={setChannelMessageToken}
                activeChannelRef={activeChannelRef}
                channelName={Name}
                userId={member.userId}
                cognitoUsers={cognitoUsers}
                setChatLoader={setChatLoader}
                chatLoader={chatLoader}
              />
              {/* <TypingIndicator />
              <Input
                style={{
                  borderTop: `solid 1px ${currentTheme.colors.greys.grey40}`,
                }}
                activeChannelArn={activeChannel.ChannelArn}
                member={member}
                hasMembership={hasMembership}
              /> */}
            </>
          ) : (
            <div className="ch-placeholder d-flex justify-content-center align-items-center h-100 flex-wrap">
              <h2 className="text-center">
                Welcome!!<p>Select a conversation</p>
              </h2>
            </div>
          )}
        </Cell>
        {showChannelMembers && (
          <div className="channel-members-container border-chat">
            <ChannelPresence cognitoUsers={cognitoUsers} />
          </div>
        )}
      </Grid>
    </>
  );
};

export default Channels;
