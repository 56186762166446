import React, { useState } from "react";
import { BrowserRouter, Navigate } from "react-router-dom";

import SessionTimeout from "./features/sessionTimeout/SessionTimeout";
import { isMobileOnly, deviceDetect } from 'react-device-detect';
import { MobileView } from "./features/mobile";
import IndexRoute from "./components/routes/IndexRoute";
import { Modal } from "react-bootstrap";
import { FROM_URL, TO_URL } from "./app/utils/Constants";

function App() {
  const [appStatus, setAppStatus] = useState(true);
  const NetworkStatus = () => {
    window.addEventListener("load", () => {
      !navigator.onLine
        && setAppStatus(false)

      window.addEventListener("online", () => {
        setAppStatus(true)
      });

      window.addEventListener("offline", () => {
        setAppStatus(false)
      });
    });

    return <div></div>;
  };

  const hasUserData =
    window.localStorage.getItem("userData") &&
      JSON.parse(window.localStorage.getItem("userData")).access_token
      ? true
      : false;

  console.log(isMobileOnly, deviceDetect, 'isMobile, deviceDetect');

  if (isMobileOnly) {
    <>
        <MobileView />
        <Navigate to="/" replace />
      </>
  } else if (FROM_URL && window.location.host === FROM_URL) {
    window.location.replace(TO_URL);
  }
  return (
    <React.Fragment>
      <BrowserRouter>
        <IndexRoute isMobile={isMobileOnly} hasUserData={hasUserData} />
      </BrowserRouter>
      <SessionTimeout />
      <NetworkStatus />
      {!appStatus &&
        <Modal
          show={true}
          className="session-modal network-modal"
          backdrop="static"
          enforceFocus={false}
          backdropClassName="network-error-backdrop"
        >
        <Modal.Header className="p-0">
          <Modal.Title className="headline4 text-start d-flex justify-content-start">
            {" "}
            <span className="network-error-icon me-3 pt-1 d-flex"></span>
            <p className="network-error-text">Something is wrong with your network connection. Please make sure you are connected to the internet and then reload the page.</p>
          </Modal.Title>
        </Modal.Header>
      </Modal>}
    </React.Fragment>
  );
}

export default App;
