/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Logo from "../../../components/layouts/Logo";
import { useSelector, useDispatch } from "react-redux";
import ToastMessage from "../../../components/layouts/ToastMessage";
import {
  HOME,
  GOOGLE_AUTH,
  INVALID_INVITE,
  DEVICE_LINK_EXPIRED,
} from "../../../app/utils/Constants";
import {
  postConfirmLostDevice,
  confirmLostDeviceList,
  loading,
} from "./operations/confirmLostDeviceSlice";
import { useNavigate } from "react-router-dom";

export default function LostDevice(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirmDevice = useSelector(confirmLostDeviceList);
  const [status, setStatus] = React.useState("");
  const [toast, setToast] = React.useState(true);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(false);
  const loader = useSelector(loading);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const par = Object.fromEntries(urlSearchParams.entries());

  React.useEffect(() => {
    if (par && par.rl) {
      let value = {
        reset_link: par && par.rl,
      };
      dispatch(postConfirmLostDevice(value));
      setError(false);
    } else {
    }
  }, []);

  React.useEffect(() => {
    const secret_code =
      confirmDevice &&
      confirmDevice.content &&
      confirmDevice.content.secret_code;
    const session_id =
      confirmDevice &&
      confirmDevice.content &&
      confirmDevice.content.access_token;
    const challenge_name =
      confirmDevice &&
      confirmDevice.content &&
      confirmDevice.content.challenge_name;

    if (
      confirmDevice &&
      confirmDevice.status &&
      confirmDevice.status === "ERROR"
    ) {
      if (
        confirmDevice.message_code &&
        confirmDevice.message_code === "LOST_DEVICE_LINK_EXPIRED"
      ) {
        navigate(DEVICE_LINK_EXPIRED, {
          state: {
            path: "reset",
            uname: par && par.u,
            rl: par && par.rl,
          },
        });
        setError(true);
      } else if (
        confirmDevice.message_code &&
        confirmDevice.message_code === "LOST_DEVICE_LINK_INVALID"
      ) {
        navigate(INVALID_INVITE, {
          state: {
            path: "reset_link",
          },
        });
      } else {
        setStatus("error");
        setMessage(confirmDevice && confirmDevice.message);
        setToast(false);
        setError(true);
      }
    } else if (
      confirmDevice &&
      confirmDevice.content &&
      confirmDevice.content.challenge_name &&
      confirmDevice.content.challenge_name === "VERIFY_DEVICE"
    ) {
      navigate(GOOGLE_AUTH, {
        state: {
          challenge_name: challenge_name,
          secret_code: secret_code,
          session_id: session_id,
          reset: true,
        },
      });
    }
  }, [confirmDevice]);

  const toastHandler = () => {
    setToast(true);
  };
  return (
    <React.Fragment>
      <Logo />
      {loader ? (
        <span className={"loader ad-loader d-block"}>
          <span className="visually-hidden">loader icon</span>
        </span>
      ) : (
        <div>
          <div>
            <h1 className="mb-10 roboto-regular login-title">Link Sent</h1>
            <p className="mb-20 roboto-regular login-info">
              If your username is on file, then you will get an email to
              reconnect with Google Authenticator.
            </p>
          </div>
          <button
            className="primary-btn mb-12"
            type="submit"
            onClick={() => {
              window.location.href = HOME;
            }}
          >
            <span>Back to Login</span>
            <span className="loader">
              <span className="visually-hidden">loader icon</span>
            </span>
          </button>
        </div>
      )}

      <ToastMessage
        type={status}
        message={message}
        setToast={toastHandler}
        toast={toast}
      />
    </React.Fragment>
  );
}
