/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect, useState } from "react";
import {
  Cell,
  Grid,
  Heading,
  useNotificationDispatch,
} from "amazon-chime-sdk-component-library-react";
import { useTheme } from "styled-components";

import ChannelsWrapper from "../../containers/channels/ChannelsWrapper";
import ChannelPresence from "../../containers/Presence/ChannelPresence";
import Messages from "../../containers/messages/Messages";
import Input from "../../containers/input/Input";
import TypingIndicator from "../../containers/TypingIndicator";
import {
  useChatChannelState,
  useChatMessagingState,
} from "../../providers/ChatMessagesProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  PresenceAutoStatus,
  PresenceMode,
  toPresenceMap,
  toPresenceMessage,
} from "../../utilities/presence";
import {
  MessageType,
  Persistence,
  sendChannelMessage,
} from "../../api/ChimeAPI";

import "../../../../assets/styles/pluginchat.scss";
import _ from "lodash";

import { Dropdown } from "react-bootstrap";
import Avatar from "react-avatar";
import { CommonHeader } from "../../../../components/CommonHeader";
import {
  chatClass,
  GROUP_CHANNEL_TYPE,
  INDIVIDUAL_CHANNEL_TYPE,
  GROUP_CHANNEL_TYPE_TWO
} from "../../../../app/utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { subSetUsersList, subSetUsersLoader } from "../../../landingPage/operations/subSetUserSlice";
import { clearDeleteServiceResponse } from "../../../scheduler/operations/deleteServiceSlice";
import { postToken, tokenList } from "../../../accounts/login/operations/tokenSlice";
import { appConfig } from "../../Config";
// var b = document.getElementsByTagName("body")[0];
// b.className += "chat-page";

const Channels = (props) => {
  const currentTheme = useTheme();
  const { activeChannelMemberships } = useChatChannelState();
  const { member, userSignOut } = useAuthContext();
  const tokenData = useSelector(tokenList);
  const { messages, messagesRef, setMessages, onReceiveMessage } =
    useChatMessagingState();
  const notificationDispatch = useNotificationDispatch();

  const {
    setChannelMessageToken,
    setChannelList,
    activeChannel,
    activeChannelRef,
    channelList,
    hasMembership,
    readMarker,
  } = useChatChannelState();
  const [cognitoUsers, setCognitoUsers] = useState([]);
  const dispatch = useDispatch();
  const subSetUsers = useSelector(subSetUsersList);
  const subSetUsersLoading = useSelector(subSetUsersLoader);
  const [chatLoader, setChatLoader] = useState(false);

  useEffect(() => {
    if (subSetUsers && subSetUsers.status === "SUCCESS") {
      setCognitoUsers(subSetUsers.content || []);
    } else if (subSetUsers && subSetUsers && subSetUsers.status === "ERROR") {
    } else if (subSetUsers.status === "ERROR") {
    }
  }, [subSetUsers]);
  useEffect(() => {
    document.documentElement.classList.add(chatClass);
    setChannelList([]);
  }, []);
  React.useEffect(
    () => () => {
      //clear redux state
      document.documentElement.classList.remove(chatClass);
    },
    []
  );
  const handleUserNameCopyClick = (_e) => {
    // Create new element
    const el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = member.userId;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
    notificationDispatch({
      type: 0,
      payload: {
        message: "UserId copied to clipboard!",
        severity: "info",
        autoClose: true,
        autoCloseDelay: 1000,
      },
    });
  };

  async function publishOfflineStatus() {
    if (activeChannel && activeChannel.ChannelArn) {
      const presenceMap = toPresenceMap(activeChannel.Metadata);
      const customPresenceExists = presenceMap && presenceMap[member.userId];
      if (!customPresenceExists) {
          await sendChannelMessage(
            activeChannel.ChannelArn,
            toPresenceMessage(
              PresenceMode.Auto,
              PresenceAutoStatus.Offline,
              true
            ),
            Persistence.NON_PERSISTENT,
            MessageType.CONTROL,
            member
          );
      }
    }
  }

  function handleLogout() {
    return async () => {
      await publishOfflineStatus();
      userSignOut();
    };
  }

  const showChannelMembers =
    !_.isEmpty(activeChannel) &&
    !JSON.parse(activeChannel.Metadata || "{}").isMeeting &&
    activeChannelMemberships.length > 1;
  let { Name = "" } = activeChannel || {};
  if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
    Name = Name.replace(GROUP_CHANNEL_TYPE, "");
    Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, "");
  } else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
    Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
    const channelMembers = activeChannelMemberships.map(
      (mem) => mem.Member.Name
    );
    let allUsers = Name.split(", ");
    if (allUsers.includes(member.username)) {
      allUsers = allUsers.filter((user) => user !== member.username);
    }
    allUsers.forEach((username) => {
      if (!channelMembers.includes(username)) {
        allUsers = allUsers.filter((user) => user !== username);
      }
    });
    Name = allUsers.join(", ");
  }

  let new_array = messages.map(function (ele) {
    return { ...ele, ReadMarkerTimestamp: readMarker };
  });
  return (
    <>
      <CommonHeader />
      <div
        className={"ad-loading-wrapper " + (subSetUsersLoading ? "d-block" : "d-none")}
      >
        <span
          className={"loader ad-loader " + (subSetUsersLoading ? "d-block" : "d-none")}
        >
          <span className="visually-hidden">loader icon</span>
        </span>
      </div>
      <Grid
        className="aha-chat-wrapper"
      // gridTemplateColumns="1fr 10fr"
      // gridTemplateRows="3rem 101%"
      // // style={{ width: '100vw', height: '100vh' }}
      // gridTemplateAreas='
      // "heading heading"
      // "side main"
      // '
      >
        <Cell gridArea="side" className="left-chat-wrapper border-chat">
          {/* SIDEPANEL CHANNELS LIST */}
          <ChannelsWrapper setChatLoader={setChatLoader} />
        </Cell>
        <Cell gridArea="main" className="right-chat-wrapper border-chat">
          {/* MAIN CHAT CONTENT WINDOW */}
          {chatLoader ? <div className="ad-loading-wrapper">
            <span className="loader ad-loader d-block">
              <span className="visually-hidden">loader icon</span>
            </span>
          </div> : activeChannel.ChannelArn ? (
            <>
              <Messages
                messages={new_array}
                messagesRef={messagesRef}
                setMessages={setMessages}
                currentMember={member}
                onReceiveMessage={onReceiveMessage}
                setChannelList={setChannelList}
                channelList={channelList}
                channelArn={activeChannelRef.current.ChannelArn}
                setChannelMessageToken={setChannelMessageToken}
                activeChannelRef={activeChannelRef}
                channelName={Name}
                userId={member.userId}
                cognitoUsers={cognitoUsers}
                setChatLoader={setChatLoader}
              />
              <TypingIndicator />
              <Input
                style={{
                  borderTop: `solid 1px ${currentTheme.colors.greys.grey40}`,
                }}
                activeChannelArn={activeChannel.ChannelArn}
                member={member}
                hasMembership={hasMembership}
              />
            </>
          ) : (
            <div className="ch-placeholder d-flex justify-content-center align-items-center h-100 flex-wrap">
              <h2 className="text-center">
                Welcome!!<p>Select a conversation</p>
              </h2>
            </div>
          )}
        </Cell>
        {showChannelMembers && (
          <div className="channel-members-container border-chat">
            <ChannelPresence cognitoUsers={cognitoUsers} />
          </div>
        )}
      </Grid>
    </>
  );
};

export default Channels;
