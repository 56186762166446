import { useState, useEffect, useRef } from 'react';
import { REFRESH_LISTING_TIMER, USER_IDLE_TIME } from '../../../app/utils/Constants';

// Helper function to format time (mm:ss)
const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

export const useAutoRefresh = (callback, loading) => {
  const [isAutoRefreshOn, setIsAutoRefreshOn] = useState(true);
  const [timer, setTimer] = useState(REFRESH_LISTING_TIMER); // Default 5 minutes
  const [lastRefresh, setLastRefresh] = useState(null);
  
  const intervalRef = useRef(null); // Store the interval reference
  const lastInteractionRef = useRef(Date.now()); // Track last interaction time

  // Manual Refresh
  const manualRefresh = () => {
    setLastRefresh(new Date());
    if (isAutoRefreshOn) {
      setTimer(REFRESH_LISTING_TIMER);
    }
  };

  // Auto-Refresh logic
  const handleAutoRefresh = () => {
    if (callback) {
        callback();
    }
    setLastRefresh(new Date());
    setTimer(REFRESH_LISTING_TIMER);
    // Start the timer only if it’s not already running
    if (!intervalRef.current) {
        startAutoRefreshTimer(); 
      } 
  };

  const resetAutoRefreshTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setIsAutoRefreshOn(true);
    setTimer(REFRESH_LISTING_TIMER);
    setLastRefresh(new Date());
    startAutoRefreshTimer();
  };

  const initializeRefreshTime = () => {
    if (!lastRefresh) {
      setLastRefresh(new Date());
    }
  };

  const handleUserActivity = () => {
    setTimer((prevTime) => {
      // If the remaining time is less than 2 minutes, reset to 2 minutes
      if (prevTime <= USER_IDLE_TIME) {
        return USER_IDLE_TIME; // Reset the timer to 2 minutes if under 2 minutes remaining
      } else {
        lastInteractionRef.current = Date.now(); // Update last interaction time for 5-minute countdown
        return prevTime; // Otherwise, do not change the timer
      }
    });
  };

  // Function to start the auto-refresh timer
  const startAutoRefreshTimer = () => {
    // Clear any existing intervals
    if (intervalRef.current) {
      clearInterval(intervalRef.current); 
    }

    intervalRef.current = setInterval(() => {
      setTimer((prevTime) => {
        if (loading) {
          return prevTime; // Pause the countdown if loading
        }
        if (prevTime <= 1) {
          // Clear interval when time reaches 0
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          handleAutoRefresh(); // Trigger auto-refresh
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000); // Decrement timer every second
  };

  // Use effect to handle the auto-refresh logic
  useEffect(() => {
    initializeRefreshTime(); // Set the last refresh time on component mount
    if (!isAutoRefreshOn) return;

    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);
    window.addEventListener('wheel', handleUserActivity); 
    window.addEventListener('click', handleUserActivity);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // Cleanup the auto-refresh interval
      }
      // window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
      window.removeEventListener('wheel', handleUserActivity); // Clean up wheel listener
      window.removeEventListener('click', handleUserActivity);
    };
  }, [isAutoRefreshOn]);

  // Use effect to handle reset of auto-refresh on toggle change
  useEffect(() => {
    if (!isAutoRefreshOn) {
      // Stop the interval and reset the timer when auto-refresh is off
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      setTimer(null); // Disable the countdown when auto-refresh is off
    } else {
      // Start the interval when auto-refresh is on
      setTimer(REFRESH_LISTING_TIMER);
      if (!intervalRef.current) {
        startAutoRefreshTimer();
      }
    }
  }, [isAutoRefreshOn]);

  return {
    isAutoRefreshOn,
    timer: formatTime(timer),
    manualRefresh,
    setIsAutoRefreshOn,
    lastRefresh,
    resetAutoRefreshTimer
  };
};
