import * as React from "react";
import { Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { MultiSelect } from "../../components/commons/MultiSelect";

class Filter extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const { date, handleStatesOnBlur, handleStates, handleManager, manager, reportingOptions,
            handleManagerOnBlur, handlePhleb, phlebs, phlebOptions, handlePhlebOnBlur,
            onDateChange, states, stateOptions, searchText, setSearchText, phlebListLoader, managerListLoader, stateLoader } = this.props;

        const DateButton = React.forwardRef(({ value, onClick }, ref) => (
            <button className="sch-date-picker" onClick={onClick} ref={ref}>
                {value}
            </button>
        ));
        const handleChangeDate = (date) => {
            onDateChange(date);
        };
        console.log(date, 'render-datepicker');
        return (
            <div className="scheduler-header">
            <div className="scheduler-filters ">
                <div className="facility-filter d-flex align-items-center">
                <div className="day-filter filter d-flex position-relative align-items-center">
                    <DatePicker
                        selected={date}
                        onChange={(date) => {
                            handleChangeDate(date);
                        }}
                        onCalendarClose={() => { }}
                        onCalendarOpen={() => { }}
                        customInput={<DateButton />}
                        dateFormat="eeee, MMM dd yyyy"
                        className="sch-date-picker"
                    />
                </div>
                    <InputGroup className="me-3">
                        <MultiSelect
                            name="destination-week"
                            id="facility-staff-rpmanage"
                            className="sch-copycal-dropdown sch-facility-dropdown rv-dropdown"
                            classNamePrefix="sch-copycal-select sch-virtual-select sch-facility-select"
                            onChange={handleManager}
                            value={manager || []}
                            options={reportingOptions || []}
                            isDisabled={false}
                            placeholder="Select Reporting Manager"
                            onBlur={handleManagerOnBlur}
                            loading={managerListLoader}
                        />
                    </InputGroup>
                    <InputGroup className="me-3">
                        <MultiSelect
                            name="hh-staff-phleb"
                            id="hh-staff-phleb"
                            className="sch-copycal-dropdown sch-facility-dropdown rv-dropdown"
                            classNamePrefix="sch-copycal-select sch-virtual-select sch-facility-select"
                            onChange={handlePhleb}
                            value={phlebs || []}
                            options={phlebOptions || []}
                            isDisabled={false}
                            placeholder="Select Phlebotomist"
                            onBlur={handlePhlebOnBlur}
                            loading={phlebListLoader}
                        />
                    </InputGroup>
                    <InputGroup className="me-3">
                        <MultiSelect
                            name="facility-state"
                            id="facility-state"
                            className="sch-copycal-dropdown sch-facility-dropdown rv-dropdown"
                            classNamePrefix="sch-copycal-select sch-virtual-select sch-facility-select"
                            isDisabled={false}
                            placeholder="Select State(s)"
                            options={stateOptions || []}
                            value={states || []}
                            onChange={handleStates}
                            onBlur={handleStatesOnBlur}
                            loading={stateLoader}
                        />
                    </InputGroup>
                    <div className="position-relative">
                <input
                  type="text"
                  className="search-box"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  id="search-care-at-home"
                />
                <span className="search-icon">
                  <span className="visually-hidden">Search Icon</span>
                </span>
              </div>
            </div>
            </div>
            </div>
        );
    }
};
export default Filter;
