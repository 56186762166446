import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";


const initialState = {
  value: [],
  loading: false,
  globalSearchError: {
    value: false,
    message: ""
  }
};

export const searchGlobally = createAsyncThunk(
  "global/searchGlobally",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.globalSearch(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const searchGlobalSlice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {
    clearGlobalSearchResponse(state, action) {
      state.value = {};
    },
    setGlobalSearchError(state, action) {
      state.globalSearchError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchGlobally.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchGlobally.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(searchGlobally.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const globalSearchResults = (state) => state.globalSearch.value;
export const globalSearchLoading = (state) => state.globalSearch.loading;
export const globalSearchErrorVal = (state) => state.globalSearch.globalSearchError;
export const { clearGlobalSearchResponse, setGlobalSearchError } = searchGlobalSlice.actions;
export default searchGlobalSlice.reducer;
