import React from "react";
import {
  render as rtlRender,
  getByText,
  findByText,
  fireEvent,
  waitForElement,
} from "@testing-library/react";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
// Import your own reducer
import accountReducer from "../../features/accounts/login/operations/accountSlice";
import forgotPasswordReducer from "../../features/accounts/troubleLogin/operations/forgotPasswordSlice";
import forgotUserReducer from "../../features/accounts/troubleLogin/operations/forgotUserSlice";
import resetPasswordReducer from "../../features/accounts/troubleLogin/operations/resetPasswordSlice";
import setPasswordReducer from "../../features/accounts/setPassword/operations/setPasswordSlice";
import userReducer from "../../features/landingPage/operations/userSlice";
import addUserReducer from "../../features/landingPage/addUser/operations/addUserSlice";
import contactSlice from "../../features/accounts/troubleLogin/operations/contactSlice";
import updateUserReducer from "../../features/landingPage/updateUser/updateUserSlice";
import addFacilitiesReducer from "../../features/landingPage/facilityAddEdit/operations/addFacilitySlice";
import updateFacilityReducer from "../../features/landingPage/facilityAddEdit/operations/updateFacilitySlice";
import addHospitalsReducer from "../../features/landingPage/hospitalAddEdit/operations/addHospitalSlice";
import addLabsReducer from "../../features/landingPage/labAddEdit/operations/addLabSlice";
import updateHospitalReducer from "../../features/landingPage/hospitalAddEdit/operations/updateHospitalSlice";
import updateLabReducer from "../../features/landingPage/labAddEdit/operations/updateLabSlice";
import addStatOrdersReducer from "../../features/statOrder/operations/addStatOrderSlice";
import updateStatOrdersReducer from "../../features/statOrder/operations/updateStatOrderSlice";

function render(
  ui,
  {
    preloadedState,
    store = configureStore({
      reducer: {
        account: accountReducer,
        forgotPassword: forgotPasswordReducer,
        forgotUser: forgotUserReducer,
        resetPassword: resetPasswordReducer,
        setPass: setPasswordReducer,
        users: userReducer,
        addUser: addUserReducer,
        updateUser: updateUserReducer,
        contact: contactSlice,
        addFacilities: addFacilitiesReducer,
        updateFacility: updateFacilityReducer,
        updateHospital: updateHospitalReducer,
        addHospitals: addHospitalsReducer,
        updateLab: updateLabReducer,
        addLabs: addLabsReducer,
        addStatOrders: addStatOrdersReducer,
        updateStatOrders: updateStatOrdersReducer,
      },
      preloadedState,
    }),
    ...renderOptions
  } = {}
) {
  function Wrapper({ children }) {
    return <Provider store={store}>{children}</Provider>;
  }
  return rtlRender(ui, { wrapper: Wrapper, ...renderOptions });
}

// re-export everything
export * from "@testing-library/react";
// override render method
export { render };

export function phoneFormat(input) {
  input = input.replace(/\D/g, "").substring(0, 10); //Strip everything but 1st 10 digits
  var size = input.length;
  if (size > 0) {
    input = "(" + input;
  }
  if (size > 3) {
    input = input.slice(0, 4) + ") " + input.slice(4);
  }
  if (size > 6) {
    input = input.slice(0, 9) + "-" + input.slice(9);
  }

  return input;
}
