import * as React from "react";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import _ from "lodash";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { scheduleListLoading } from "../operations/scheduleListSlice";
import {
  onCallScheduleListLoading,
  openPopover,
  setOpenPopover,
} from "../operations/onCallScheduleListSlice";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { BACKLOG_SECTION_TYPE, SCHEDULE_SERVICE_TYPE, SURGE_TYPE, showNoCoverageInfoIcon } from "../../../app/utils/Constants";

export const OnCallZone = React.memo((props) => {
  const {
    zones,
    userIndex,
    serviceIndex,
    territoryIndex,
    routineIndex,
    sectionType,
    date,
    blockId,
    uniqueServiceId,
    handleDelete,
    searchText,
    searchType,
    users: onCallUsers = [],
    showDropArea,
    lastZoneId,
    userType,
    eventType
  } = props;
  let droppableId = "";
  console.log(userType, 'userTypeuserType', SCHEDULE_SERVICE_TYPE);
  const scheduleListLoader = useSelector(scheduleListLoading);
  const onCallScheduleListLoader = useSelector(onCallScheduleListLoading);
  const openPopoverVal = useSelector(openPopover);
  const dispatch = useDispatch();
  const [isReadMore, setIsReadMore] = React.useState(false);

  const readMore = (e, val) => {
    e.preventDefault();
    setIsReadMore(val)
  }

  if (userIndex === undefined) {
    droppableId =
      BACKLOG_SECTION_TYPE +
      "|" +
      serviceIndex +
      "|" +
      territoryIndex +
      "|" +
      routineIndex;
  } else {
    droppableId =
      `user/${blockId}/${uniqueServiceId}` +
      "|" +
      userIndex +
      "|" +
      serviceIndex +
      "|" +
      territoryIndex +
      "|" +
      routineIndex;
  }

  const selectedDate = new Date(date);
  selectedDate.setHours(0, 0, 0, 0);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const showNoResults = !zones.some(
    (zone) =>
      !searchText ||
      zone.zone_name.toLowerCase().search(searchText.toLowerCase()) !== -1
  );

  let permission =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
    [];
  permission = permission.filter((perm) => perm.page === "events");
  const eventAccess = permission.length === 1 ? permission[0].access : [];
  const canDeleteZone =
    eventAccess &&
      eventAccess.find((item) => item === "relation-delete") === "relation-delete"
      ? true
      : false;
  const canDeleteSurgeZone = eventAccess && eventAccess.find((item) => item === "relation-delete-surge-on-call") === "relation-delete-surge-on-call" ? true : false;


  let boardLoading = scheduleListLoader || onCallScheduleListLoader;
  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} 
        className={sectionType !== BACKLOG_SECTION_TYPE ? `${snapshot.isDraggingOver ? "droppable-area-active " : showDropArea ?  "droppable-area-inactive droppable-area-border " : " " }` : ""}
        // style={sectionType !== BACKLOG_SECTION_TYPE ? { opacity: showDropArea ? 0.8 : 1, backgroundColor: snapshot.isDraggingOver ? "#ebf6f4" : "" } : {}} {...provided.droppableProps}
        >
          {zones.map((zone, zoneIndex) => {
            const {
              zone_name: zoneName,
              zone_id,
              count = 0,
              users = [],
              no_coverage = [],
              missing_coverage = [],
              timezone = {}
            } = zone;
            let draggableId = "";

            if (userIndex === undefined) {
              draggableId =
                BACKLOG_SECTION_TYPE +
                "|" +
                serviceIndex +
                "|" +
                territoryIndex +
                "|" +
                routineIndex +
                "|" +
                zoneIndex;
            } else {
              draggableId =
                `user/${blockId}/${uniqueServiceId}` +
                "|" +
                userIndex +
                "|" +
                serviceIndex +
                "|" +
                territoryIndex +
                "|" +
                routineIndex +
                "|" +
                zoneIndex;
            }

            const isDragDisabled = eventType === SURGE_TYPE;

            const showInfoIcon = showNoCoverageInfoIcon(onCallUsers, zone_id);
            return (
              (!searchText ||
                zoneName.toLowerCase().search(searchText.toLowerCase()) !==
                -1) && (
                <Draggable
                  key={
                    userIndex +
                    "|" +
                    serviceIndex +
                    "|" +
                    territoryIndex +
                    "|" +
                    routineIndex +
                    "|" +
                    zoneIndex
                  }
                  draggableId={draggableId}
                  index={zoneIndex}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided, snapshot2) => (
                    <Popover
                      id={"zone-popover" + zone_id}
                      containerClassName="oncall-count-popover-container"
                      reposition={true}
                      onClickOutside={(e) => {
                        e.preventDefault();
                      }}
                      content={({ position, childRect, popoverRect }) =>
                        sectionType === "scheduleBoard" ? (
                          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                            position={position}
                            childRect={childRect}
                            popoverRect={popoverRect}
                            arrowColor={"white"}
                            arrowSize={10}
                            arrowStyle={{ opacity: 1 }}
                            className="popover-arrow-container"
                            arrowClassName="popover-arrow"
                            id={"zone-arrow-container" + zone_id}
                          >
                            <div
                              title={zoneName}
                              className="sch-popover"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <span
                                className="service-icon2 popoverclose"
                                onClick={() => document.body.click()}
                              >
                                <span className="visually-hidden">
                                  Close Icon
                                </span>
                              </span>
                              <div className="scheduler-wrapper popover-wrapper">
                                {props.event_id && (
                                  <div className="sub-serivce-type">
                                    <span className="service-icon"></span>
                                    <div>
                                      <h3 className="title roboto-regular">
                                        {zoneName}
                                      </h3>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </ArrowContainer>
                        ) : (
                          <div />
                        )
                      }
                      key={zone_id}
                    >
                      <div>
                        <div
                          className="e-card2"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div
                            className={`service-locations ${userIndex === undefined
                              ? "oncall-unassigned-state"
                              : eventType === SURGE_TYPE ? "surge-facility-default" : "oncall-assigned-state"
                              } `}
                          >
                            <span className="bg-user-icon">
                              <span className="visually-hidden">
                                Schedule 2x user icon
                              </span>
                            </span>
                            {(sectionType !== "scheduleBoard" && (showInfoIcon || count > 0)) && (
                              <span>
                                <Popover
                                  id={`oncall-count-users-popover-${zone_id}`}
                                  containerClassName="oncall-count-popover-container"
                                  isOpen={openPopoverVal == zone_id}
                                  reposition={true}
                                  onClickOutside={(e) => {
                                    e.preventDefault();
                                    dispatch(setOpenPopover(""))
                                  }}
                                  content={({
                                    position,
                                    childRect,
                                    popoverRect,
                                  }) => (
                                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                      position={position}
                                      childRect={childRect}
                                      popoverRect={popoverRect}
                                      arrowColor={"white"}
                                      arrowSize={10}
                                      arrowStyle={{ opacity: 1 }}
                                      className="popover-arrow-container"
                                      arrowClassName="popover-arrow"
                                    >
                                      <div
                                        title="On Call Coverage"
                                        className="sch-popover sch-countlist-popover"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <span
                                          className="service-icon2 popoverclose"
                                          onClick={() => {
                                            document.body.click();
                                            dispatch(setOpenPopover(""));
                                          }}
                                        >
                                          <span className="visually-hidden">
                                            Close Icon
                                          </span>
                                        </span>
                                        {users && users.length > 0 && (
                                          <div className="count-userlist-wrapper">
                                            <p className="sch-no-coverage-title color-green pb-1">On Call Coverage</p>
                                            {users.map((user, idx) => {
                                              const {
                                                icon_color_code,
                                                name,
                                                event_start_date_time,
                                                event_end_date_time,
                                                timezone = {}
                                              } = user;

                                              return (
                                                <div
                                                  className="d-flex align-items-center sch-th-left-wrapper"
                                                  key={idx}
                                                >
                                                  <Avatar
                                                    color={
                                                      icon_color_code
                                                        ? `#${icon_color_code}`
                                                        : "#333745"
                                                    }
                                                    name={name}
                                                    maxInitials={2}
                                                    size={30}
                                                    round="50px"
                                                    className="sch-avatar-wrapper"
                                                  />
                                                  <div className="sch-user-wrapper">
                                                    <h2 className="sch-user-name font-size-14">
                                                      {name}{" "}
                                                    </h2>
                                                    <span className="font-size-12">
                                                      {event_start_date_time
                                                        .substring(5)
                                                        .replace("-", "/")
                                                        .replace(
                                                          " ",
                                                          ", "
                                                        )}{timezone && timezone.abbreviation ? ` ${timezone.abbreviation}` : ""}{" "}
                                                      -{" "}
                                                      {event_end_date_time
                                                        .substring(5)
                                                        .replace("-", "/")
                                                        .replace(" ", ", ")}{timezone && timezone.abbreviation ? ` ${timezone.abbreviation}` : ""}
                                                    </span>
                                                    <br/>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        )}
                                        {no_coverage && no_coverage.length > 0 && (
                                          <div className="count-userlist-wrapper">
                                            <p className="sch-no-coverage-title color-red">No Coverage</p>
                                            {no_coverage.map((item, idx) => {
                                              const {
                                                event_description,
                                                event_start_date_time,
                                                event_end_date_time,
                                                timezone = {}
                                              } = item;
                                              return (
                                                <div
                                                  className="d-flex align-items-center sch-noc-left-wrapper"
                                                  key={idx}
                                                >
                                                  <div className="sch-user-wrapper">
                                                    <span className="font-size-12">
                                                      {event_start_date_time
                                                        .substring(5)
                                                        .replace("-", "/")
                                                        .replace(
                                                          " ",
                                                          ", "
                                                        )}{timezone && timezone.abbreviation ? ` ${timezone.abbreviation}` : ""}{" "}
                                                      -{" "}
                                                      {event_end_date_time
                                                        .substring(5)
                                                        .replace("-", "/")
                                                        .replace(" ", ", ")}{timezone && timezone.abbreviation ? ` ${timezone.abbreviation}` : ""}
                                                    </span>
                                                    <br/>
                                                    <p className="sch-noc-desc font-size-12">
                                                      {event_description ? event_description.length > 70 ? isReadMore ? event_description : `${event_description.slice(0, 70)}...` : event_description : ""}
                                                    </p>
                                                    {event_description && event_description.length > 70 && !isReadMore && <div style={{ textAlign: 'right' }}>
                                                      <a href="#" onClick={e => readMore(e, true)} className="d-inline-block roboto-medium font-size-14 add-dropoff-title px-3 py-2"
                                                      >Read More</a></div>}

                                                    {event_description && event_description.length > 70 && isReadMore && <div style={{ textAlign: 'right' }}>
                                                      <a href="#" onClick={e => readMore(e, false)} className="d-inline-block roboto-medium font-size-14 add-dropoff-title px-3 py-2"
                                                      >Read Less</a></div>}
                                                  </div>
                                                </div>
                                              );
                                            })}

                                          </div>
                                        )}
                                        {missing_coverage && missing_coverage.length > 0 && (
                                          <div className="count-userlist-wrapper">
                                            <p className="sch-no-coverage-title color-yellow">Coverage Missing</p>
                                            {missing_coverage.map((item, idx) => {
                                              const {
                                                event_description,
                                                event_start_date_time,
                                                event_end_date_time,
                                                timezone = {}
                                              } = item;

                                              return (
                                                <div
                                                  className="d-flex align-items-center sch-noc-left-wrapper"
                                                  key={idx}
                                                >
                                                  <div className="sch-user-wrapper">
                                                    <span className="font-size-12">
                                                      {event_start_date_time
                                                        .substring(5)
                                                        .replace("-", "/")
                                                        .replace(
                                                          " ",
                                                          ", "
                                                        )}{timezone && timezone.abbreviation ? ` ${timezone.abbreviation}` : ""}{" "}
                                                      -{" "}
                                                      {event_end_date_time
                                                        .substring(5)
                                                        .replace("-", "/")
                                                        .replace(" ", ", ")}{timezone && timezone.abbreviation ? ` ${timezone.abbreviation}` : ""}
                                                    </span>
                                                    <br/>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        )}
                                      </div>
                                    </ArrowContainer>
                                  )}
                                >
                                  <span
                                    className={showInfoIcon ? "oncall-info-wrapper" : "assign-count"}
                                    onClick={() =>
                                      dispatch(setOpenPopover(zone_id))
                                    }
                                  >
                                    {showInfoIcon ? <span className="info-icon  oncall-info-icon"></span> : count}
                                  </span>
                                </Popover>
                              </span>
                            )}

                            {sectionType === "scheduleBoard" &&
                              selectedDate >= currentDate &&
                              (eventType === SURGE_TYPE ? canDeleteSurgeZone : canDeleteZone) && (
                                <span
                                  className="delete-icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    !boardLoading &&
                                      handleDelete(
                                        userIndex,
                                        uniqueServiceId,
                                        territoryIndex,
                                        routineIndex,
                                        zoneIndex,
                                        zone_id,
                                        eventType
                                      );
                                  }}
                                  style={
                                    boardLoading
                                      ? { pointerEvents: "none" }
                                      : {}
                                  }
                                >
                                  <span className="visually-hidden">
                                    Schedule Board
                                  </span>
                                </span>
                              )}
                            <p className="title">
                              <span 
                                className="d-inline-block ah-sch-account-name"
                                title= {`${zoneName} ${sectionType !== "scheduleBoard" && timezone && timezone.abbreviation ? `(${timezone.abbreviation})` : "" }`}
                              >
                                {zoneName} {sectionType !== "scheduleBoard" && timezone && timezone.abbreviation ? `(${timezone.abbreviation})` : "" }
                              </span>
                            </p>
                            {eventType !== SURGE_TYPE && <span className="service-icon drag-icon">
                              <span className="visually-hidden">Icon Drag</span>
                            </span>}
                          </div>
                        </div>
                        {sectionType !== BACKLOG_SECTION_TYPE && lastZoneId == zone_id && showDropArea && <div>
                          <p className="drop-facility-zone-text">
                            Drag zones here from the{" "}
                            <span className="roboto-medium">Task Bar</span> to add to {userType === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? "no coverage's schedule" : "a person’s schedule"}
                          </p>
                        </div>}
                      </div>
                    </Popover>
                  )}
                </Draggable>
              )
            );
          })}
          {searchText && showNoResults && (
            <div className="search-no-records backlog-search-no-records d-flex justify-content-center align-items-center">
              <p className="no-result-text"> No results found</p>
            </div>
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
});
