import React from "react";
import { createRoot } from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./assets/styles/main.scss";

import { configureAmplify } from "./features/chat/services/servicesConfig";
import { registerLicense } from "@syncfusion/ej2-base";
import reportWebVitals from "./reportWebVitals";
import { SYNCFUSION_KEY } from "./app/utils/Constants";

// Registering Syncfusion license key
registerLicense(SYNCFUSION_KEY);

configureAmplify();
const root = createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <App />
    </Provider>
  </React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
