import React, { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { Badge, Button, Dropdown, Form, Modal, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  HOME_HEALTH_ALL_STATUSES,
  HOME_HEALTH_CANCELLED_STATUSES,
  HOME_HEALTH_COMPLETED_STATUSES,
  HOMEHEALTH,
  logout,
  ORDER_TYPE_ROUTINE_REDRAW,
  ORDER_TYPE_TIMED_DRAW,
  ROUTINEREDRAW,
  ROUTINE_REDRAW_COMPLETED_STATUSES,
  startDateForOpenOrders,
  STAT_ALL_STATUSES,
  STAT_CANCELLED_STATUSES,
  STAT_COMPLETED_STATUSES,
  STATORDER,
  statOrderClass,
  STAT_ALL_OPEN_STATUSES,
  HOME_HEALTH_OPEN_ORDER_STATUSES,
  ROUTINE_REDRAW_ALL_OPEN_STATUSES,
  HOME_HEALTH_UNSCHEDULED_ORER_STATUSES,
  TIMEDDRAW,
  TYPE_HOMEHEALTH,
  TYPE_ROUTINE,
  TYPE_STAT,
  TYPE_TIMEDDRAW,
  DEFAULT_ORDER_SIZE,
  checkAppVersion,
  TIMEZONE_MISMATCH,
  ORDER_TYPE_STAT,
  ORDER_TYPE_HOME_HEALTH,
  CAREDX_LABEL,
  careAtHomeOrderClass,
} from "../../app/utils/Constants";
import { filterList, getFilter } from "../landingPage/addUser/operations/filterSlice";
import {
  getProfile,
  profileList,
  profileLoader,
} from "../landingPage/profile/operations/profileSlice";
import StatOrderList from "./StatOrderList";
import StatOrderAddEdit from "./StatOrderAddEdit";
import {
  orderFilterList,
  orderFilterListResponse,
} from "./operations/orderFilterListSlice";
import moment from "moment";
import {
  addStatOrders,
  addStatOrdersList,
  clearAddStatOrderResponse,
} from "./operations/addStatOrderSlice";
import {
  updateStatOrders,
  updateStatOrdersList,
  clearUpdateStatOrdersResponse,
} from "./operations/updateStatOrderSlice";
import LottieAnimation from "../../components/layouts/LottieAnimation";
import {
  bulkCheckinCheckoutResponse,
  doBulkCheckinCheckout,
  clearBulkCheckinCheckoutResponse,
} from "./operations/bulkCheckinCheckoutSlice";
import {
  bulkCheckinCheckoutHomeHealthResponse,
  doBulkCheckinCheckoutHomeHealth,
  clearBulkCheckinCheckoutHomeHealthResponse,
  bulkCheckinCheckoutHomeHealthLoading,
} from "../homeHealth/operations/bulkCheckinCheckoutHomeHealthSlice";
import {
  acknowledgePartialOrderResponse,
  clearAcknowledgePartialResponse,
  acknowledgePartialOrder,
  acknowledgePartialOrderLoading,
} from "./operations/acknowledgePartialOrderSlice";
import RoutineRedrawList from "../routineRedraw/RoutineRedrawList";
import {
  addRoutineRedraws,
  addRoutineRedrawsList,
  clearAddRoutineRedrawResponse,
} from "../routineRedraw/operations/addRoutineRedrawSlice";
import {
  clearUpdateRoutineRedrawsResponse,
  updateRoutineRedraws,
  updateRoutineRedrawsList,
} from "../routineRedraw/operations/updateRoutineRedrawSlice";
import RoutineRedrawAddEdit from "../routineRedraw/RoutineRedrawAddEdit";
import { useNavigate, useLocation } from "react-router-dom";
import ahaApi from "../../app/services/aha-api";
import {
  forceAcknowledgeOrder,
  forceAcknowledgeOrderResponse,
  clearForceAcknowledgeResponse,
  forceAcknowledgeOrderLoading,
} from "./operations/forceAcknowledgeOrderSlice";
import ToastMessageNew from "../../components/layouts/ToastMessageNew";
import { CommonHeaderRightSection } from "../../components/commons/CommonHeaderRightSection";
import TimedDrawAddEdit from "../timedDraw/TimedDrawAddEdit";
import TimedDrawList from "../timedDraw/TimedDrawList";
import {
  addTimedDraws,
  addTimedDrawsList,
  clearAddTimedDrawResponse,
} from "../timedDraw/operations/addTimedDrawSlice";
import {
  addHomeHealth,
  addHomeHealthList,
  addHomeHealthListLoading,
  clearAddHomeHealthResponse,
} from "../homeHealth/operations/addHomeHealthSlice";
import {
  clearUpdateTimedDrawsResponse,
  updateTimedDraws,
  updateTimedDrawsList,
} from "../timedDraw/operations/updateTimedDrawSlice";
import HomeHealthList from "../homeHealth/HomeHealthList";
import HomeHealthAddEdit from "../homeHealth/HomeHealthAddEdit";
import { clearUpdateHomeHealthResponse, updateHomeHealthList, updateHomeHealthLoader, updateHomeHealths } from "../homeHealth/operations/updateHomeHealthSlice";
import { getOrder, order, orderLoader, setOrderList } from "./operations/orderSlice";
import { clearUnclaimOrderResponse, unclaimOrder, unclaimOrderLoading, unclaimOrderResponse } from "../homeHealth/operations/unclaimOrderSlice";
import { checkHomeHealthUserSchedule, homeHealthUserScheduleList, homeHealthUserScheduleLoading } from "../homeHealth/operations/homeHealthUserScheduleSlice";
import { cancelHomeHealth, cancelHomeHealthList, clearCancelHomeHealthResponse } from "../homeHealth/operations/cancelHomeHealthSlice";
import { clearDataByPartnerOrderd, dataByPartnerOrderId, dataByPartnerOrderIdLoader, fetchDataByPartnerOrderId } from "../homeHealth/operations/fetchDataByPartnerOrderIdSlice";
import { clearDeleteStatAction, deleteStatActionApiResponse, deleteStatActionResponse } from "./operations/deleteStatActionSlice";
import { activeZonesList, getActiveZones } from "../landingPage/operations/activeZonesSlice";
import { activeZonesListWithDate, getActiveZonesWithDate } from "../landingPage/operations/activeZonesWithDateSlice";
import { cancelStatOrderList, clearCancelStatOrderResponse, handleCancelStatOrder } from "./operations/cancelStatOrderSlice";
import { clearUncancelHomeHealthResponse, uncancelHomeHealth, uncancelHomeHealthList } from "../homeHealth/operations/uncancelHomeHealthSlice";
import { clearCloneHomeHealthResponse, cloneHomeHealth, cloneHomeHealthList, cloneHomeHealthListLoading } from "../homeHealth/operations/cloneHomeHealthSlice";
import ToastContent from "../../components/layouts/ToastContent";
import {toast as hotToast} from "react-hot-toast";
import { getStatData, setStatOrdersList } from "./operations/statOrderReducer";
import { getHomeHealth, setHomeHealthList } from "../homeHealth/operations/homeHealthReducer";
import { getRoutineRedraws, setRoutineRedrawsList } from "../routineRedraw/operations/routineRedrawReducer";
import { getTimedDraws, setTimedDrawsList } from "../timedDraw/operations/timedDrawReducer";
import { AutoRefreshProvider } from "./AutoRefreshContext";
import { doOrderExport, exportDataLoader, exportDataResponse, isExportDisabledHomeHealth, isExportDisabledRoutine, isExportDisabledStat, isExportDisabledTimed, setExportData, setIsExportDisabledHomeHealth, setIsExportDisabledRoutine, setIsExportDisabledStat, setIsExportDisabledTimed } from "./operations/exportSlice";
import { getExportFileData } from "./operations/exportFileDataSlice";
import { careDxDropSitesResponse, getCareDxDropSites, updateGetCareDxDropSite } from "../landingPage/careDxDropSite/operations/getCareDxDropSitesSlice";

function CustomTabWithBadge({ title, notificationCount, className, onClick, tabValue, currentTab }) {
  return (
    <div className={className} onClick={onClick}>
      <p>{title}</p>
    </div>
  );
}

export default function StatOrder(props) {
  let permission =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
    [];

  let loggedInUser =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).full_name &&
      JSON.parse(window.localStorage.getItem("userDetail")).full_name) ||
    "";
  console.log(loggedInUser);
  // stat-order-permission
  const statOrdersPermission = permission.filter(
    (perm) => perm.page === "orders"
  );
  const access =
    statOrdersPermission.length === 1 ? statOrdersPermission[0].access : [];
  const createStatOrder =
    access && access.find((item) => item === "create") === "create"
      ? true
      : false;
  const updateStatOrder =
    access && access.find((item) => item === "update") === "update"
      ? true
      : false;
  const listStatOrder =
    access && access.find((item) => item === "list") === "list" ? true : false;

  const eventPermission = permission.filter(
    (perm) => perm.page === "events"
  );
  const eventAccess =
    eventPermission.length === 1 ? eventPermission[0].access : [];
  const canDeleteCheckinCheckout = eventAccess && eventAccess.find((item) => item === "delete-stat-action") === "delete-stat-action" ? true : false;

  const canCancelStatOrder =
    access && access.find((item) => item === "cancel") === "cancel" ? true : false;

  const deleteStatActionResult = useSelector(deleteStatActionResponse);

  // home-health-permission
  const homeHealthOrdersPermission = permission.filter(
    (perm) => perm.page === "home-health-orders"
  );
  const homeHealthAccess =
    homeHealthOrdersPermission.length === 1 ? homeHealthOrdersPermission[0].access : [];
  const createHomeHealthOrder =
    homeHealthAccess && homeHealthAccess.find((item) => item === "create") === "create"
      ? true
      : false;
  const updateHomeHealthOrder =
    homeHealthAccess && homeHealthAccess.find((item) => item === "update") === "update"
      ? true
      : false;
  const listHomeHealthOrder =
    homeHealthAccess && homeHealthAccess.find((item) => item === "list") === "list" ? true : false;

  const canUnclaimHomeHealthOrder =
    homeHealthAccess && homeHealthAccess.find((item) => item === "unclaim") === "unclaim" ? true : false;

  const canCancelHomeHealthOrder =
    homeHealthAccess && homeHealthAccess.find((item) => item === "cancel") === "cancel" ? true : false;

  const canUnCancelHomeHealthOrder =
    homeHealthAccess && homeHealthAccess.find((item) => item === "uncancel") === "uncancel" ? true : false;

  const canCloneHomeHealthOrder =
    homeHealthAccess && homeHealthAccess.find((item) => item === "clone") === "clone" ? true : false;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(profileList);
  const profileLoading = useSelector(profileLoader);
  const filter = useSelector(filterList);
  const activeZones = useSelector(activeZonesList);
  const activeZonesWithDate = useSelector(activeZonesListWithDate);

  let tabValue =
    window.location.pathname === ROUTINEREDRAW
      ? "routine"
      : window.location.pathname === TIMEDDRAW
        ? TYPE_TIMEDDRAW
        : window.location.pathname === HOMEHEALTH
          ? TYPE_HOMEHEALTH
          : "stats";
  let tabRef = React.useRef({});
  tabRef.current = tabValue;
  const [lottie, setLottie] = React.useState(false);
  const [name, setName] = React.useState("");
  const [color, setColor] = React.useState("");
  const [reRender, setReRender] = React.useState(false);
  const [isClicked, setIsClicked] = React.useState(false);


  const [toastVal, setToastVal] = React.useState(true);
  const [toastMessage, setToastMessage] = React.useState("");

  const [toastMessageWarning, setToastMessageWarning] = React.useState("");
  const [toast, setToast] = React.useState(true);

  // stat-order
  const [statOrderData, setStatOrderData] = React.useState({ value: false, mode: "view" });
  const statOrdersResponse = useSelector((state) => state.statGridData?.data);
  const statOrdersLoading = useSelector((state) => state.statGridData?.loading);
  const orderLoading = useSelector(orderLoader);
  const orderResponse = useSelector(order);
  const orderFilterListData = useSelector(orderFilterListResponse);
  const addStatOrder = useSelector(addStatOrdersList);
  const updateStatOrderResponse = useSelector(updateStatOrdersList);
  const bulkCheckinCheckoutData = useSelector(bulkCheckinCheckoutResponse);
  const acknowledgePartialOrderData = useSelector(
    acknowledgePartialOrderResponse
  );
  const acknowledgePartialOrderLoader = useSelector(
    acknowledgePartialOrderLoading
  );
  const unclaimOrderData = useSelector(
    unclaimOrderResponse
  );
  const unclaimOrderLoader = useSelector(
    unclaimOrderLoading
  );
  const forceAcknowledgeOrderData = useSelector(forceAcknowledgeOrderResponse);
  const forceAcknowledgeOrderLoader = useSelector(forceAcknowledgeOrderLoading);

  const cancelStatOrder = useSelector(cancelStatOrderList);

  const orderDateRangeRef = useRef();

  const statOrderFilter = window.localStorage.getItem(`statOrderFilter`)
    ? JSON.parse(window.localStorage.getItem(`statOrderFilter`)) || {}
    : {};
  let today = new Date();
  today.setDate(today.getDate() - 1);
  const [orderStartDate, setOrderStartDate] = useState(
    statOrderFilter.orderStartDate
      ? new Date(statOrderFilter.orderStartDate)
      : today
  );
  const [orderEndDate, setOrderEndDate] = useState(
    statOrderFilter.orderEndDate
      ? new Date(statOrderFilter.orderEndDate)
      : statOrderFilter.orderStartDate
        ? null
        : new Date()
  );
  const isMounted = useRef(false);
  const [openOrders, setOpenOrders] = useState(
    statOrderFilter.openOrders || false
  );

  const [orderDateTypeStat, setOrderDateTypeStat] = useState(
    statOrderFilter.orderDateType || false
  );

  const [droppedOffOrders, setDroppedOffOrders] = useState(
    statOrderFilter.droppedOffOrders || false
  );

  const [cancelledOrders, setCancelledOrders] = useState(
    statOrderFilter.cancelledOrders || false
  );

  const [allOrders, setAllOrders] = useState(
    statOrderFilter.allOrders || false
  );

  const [orderStatus, setOrderStatus] = useState(
    statOrderFilter.orderStatus || ""
  );
  const [orderColor, setOrderColor] = useState(
    statOrderFilter.orderColor || ""
  );
  const [orderRegion, setOrderRegion] = useState(
    statOrderFilter.orderRegion || ""
  );
  const [orderTerr, setOrderTerr] = useState(statOrderFilter.orderTerr || "");
  const [orderZone, setOrderZone] = useState(statOrderFilter.orderZone || "");

  const hideFilter = useSelector((state) => state.statGridData.hideFilter);
  const [highlightStatusApply, setHighlightStatusApply] = useState(false);
  const [highlightColorApply, setHighlightColorApply] = useState(false);
  const [highlightRegionApply, setHighlightRegionApply] = useState(false);
  const [highlightTerritoryApply, setHighlightTerritoryApply] = useState(false);
  const [highlightZoneApply, setHighlightZoneApply] = useState(false);

  const [statTempStatus, setStatTempStatus] = useState(statOrderFilter.orderStatus || []);
  const [statTempColor, setStatTempColor] = useState(statOrderFilter.orderColor || []);
  const [statTempRegion, setStatTempRegion] = useState(statOrderFilter.orderRegion || []);
  const [statTempTerritory, setStatTempTerritory] = useState(statOrderFilter.orderTerr || []);
  const [statTempZone, setStatTempZone] = useState(statOrderFilter.orderZone || []);



  // routine-redraw
  const [routineRedrawData, setRoutineRedrawData] = React.useState({
    value: false,
    mode: "view",
  });
  const routineRedrawsResponse = useSelector((state) => state.routineGridData?.data);
  const routineRedrawsLoading = useSelector((state) => state.routineGridData?.loading);
  const addRoutineRedraw = useSelector(addRoutineRedrawsList);
  const updateRoutineRedrawResponse = useSelector(updateRoutineRedrawsList);

  const routineDateRangeRef = useRef();

  const routineRedrawFilter = window.localStorage.getItem(`routineRedrawFilter`)
    ? JSON.parse(window.localStorage.getItem(`routineRedrawFilter`)) || {}
    : {};
  let todayTwo = new Date();
  todayTwo.setDate(todayTwo.getDate() - 1);
  const [routineRedrawStartDate, setRoutineRedrawStartDate] = useState(
    routineRedrawFilter.orderStartDate
      ? new Date(routineRedrawFilter.orderStartDate)
      : todayTwo
  );
  const [routineRedrawEndDate, setRoutineRedrawEndDate] = useState(
    routineRedrawFilter.orderEndDate
      ? new Date(routineRedrawFilter.orderEndDate)
      : routineRedrawFilter.orderStartDate
        ? null
        : new Date()
  );

  const [openRoutineRedraws, setOpenRoutineRedraws] = useState(
    routineRedrawFilter.openRoutineRedraws || false
  );

  const [orderDateTypeRoutine, setOrderDateTypeRoutine] = useState(
    routineRedrawFilter.orderDateType || false
  );

  const [droppedOffRoutineRedraws, setDroppedOffRoutineRedraws] = useState(
    routineRedrawFilter.droppedOffRoutineRedraws || false
  );

  const [cancelledRoutineRedraws, setCancelledRoutineRedraws] = useState(
    routineRedrawFilter.cancelledRoutineRedraws || false
  );

  const [allOrdersRoutineRedraws, setAllOrdersRoutineRedraws] = useState(
    routineRedrawFilter.allOrdersRoutineRedraws || false
  );

  const [routineRedrawStatus, setRoutineRedrawStatus] = useState(
    routineRedrawFilter.orderStatus || ""
  );
  const [routineRedrawColor, setRoutineRedrawColor] = useState(
    routineRedrawFilter.orderColor || ""
  );
  const [routineRedrawRegion, setRoutineRedrawRegion] = useState(
    routineRedrawFilter.orderRegion || ""
  );
  const [routineRedrawTerr, setRoutineRedrawTerr] = useState(
    routineRedrawFilter.orderTerr || ""
  );
  const [routineRedrawZone, setRoutineRedrawZone] = useState(
    routineRedrawFilter.orderZone || ""
  );

  const [isClickedRoutine, setIsClickedRoutine] = React.useState(false);

  const [highlightStatusApplyRoutine, setHighlightStatusApplyRoutine] = useState(false);
  const [highlightColorApplyRoutine, setHighlightColorApplyRoutine] = useState(false);
  const [highlightRegionApplyRoutine, setHighlightRegionApplyRoutine] = useState(false);
  const [highlightTerritoryApplyRoutine, setHighlightTerritoryApplyRoutine] = useState(false);
  const [highlightZoneApplyRoutine, setHighlightZoneApplyRoutine] = useState(false);

  const [statTempRoutineStatus, setStatTempRoutineStatus] = useState(routineRedrawFilter.orderStatus || []);
  const [statTempRoutineColor, setStatTempRoutineColor] = useState(routineRedrawFilter.orderColor || []);
  const [statTempRoutineRegion, setStatTempRoutineRegion] = useState(routineRedrawFilter.orderRegion || []);
  const [statTempRoutineTerritory, setStatTempRoutineTerritory] = useState(routineRedrawFilter.orderTerr || []);
  const [statTempRoutineZone, setStatTempRoutineZone] = useState(routineRedrawFilter.orderZone || []);


  // timed-draw
  const [timedDrawData, setTimedDrawData] = React.useState({
    value: false,
    mode: "view",
  });
  const timedDrawsResponse = useSelector((state) => state.timedDrawGridData?.data);
  const timedDrawsLoading = useSelector((state) => state.timedDrawGridData?.loading);
  const addTimedDraw = useSelector(addTimedDrawsList);
  const updateTimedDrawResponse = useSelector(updateTimedDrawsList);

  const timedDateRangeRef = useRef();

  const timedDrawFilter = window.localStorage.getItem(`timedDrawFilter`)
    ? JSON.parse(window.localStorage.getItem(`timedDrawFilter`)) || {}
    : {};
  const [timedDrawStartDate, setTimedDrawStartDate] = useState(
    timedDrawFilter.orderStartDate
      ? new Date(timedDrawFilter.orderStartDate)
      : todayTwo
  );
  const [timedDrawEndDate, setTimedDrawEndDate] = useState(
    timedDrawFilter.orderEndDate
      ? new Date(timedDrawFilter.orderEndDate)
      : timedDrawFilter.orderStartDate
        ? null
        : new Date()
  );

  const [openTimedDraws, setOpenTimedDraws] = useState(
    timedDrawFilter.openTimedDraws || false
  );

  const [orderDateTypeTimedDraw, setOrderDateTypeTimedDraw] = useState(
    timedDrawFilter.orderDateType || false
  );

  const [droppedOffTimedDraws, setDroppedOffTimedDraws] = useState(
    timedDrawFilter.droppedOffTimedDraws || false
  );

  const [cancelledTimedDraws, setCancelledTimedDraws] = useState(
    timedDrawFilter.cancelledTimedDraws || false
  );

  const [allOrdersTimedDraws, setAllOrdersTimedDraws] = useState(
    timedDrawFilter.allOrdersTimedDraws || false
  );

  const [timedDrawStatus, setTimedDrawStatus] = useState(
    timedDrawFilter.orderStatus || ""
  );
  const [timedDrawColor, setTimedDrawColor] = useState(
    timedDrawFilter.orderColor || ""
  );
  const [timedDrawRegion, setTimedDrawRegion] = useState(
    timedDrawFilter.orderRegion || ""
  );
  const [timedDrawTerr, setTimedDrawTerr] = useState(
    timedDrawFilter.orderTerr || ""
  );
  const [timedDrawZone, setTimedDrawZone] = useState(
    timedDrawFilter.orderZone || ""
  );

  const [isClickedTimed, setIsClickedTimed] = React.useState(false);

  const [highlightStatusApplyTimed, setHighlightStatusApplyTimed] = useState(false);
  const [highlightColorApplyTimed, setHighlightColorApplyTimed] = useState(false);
  const [highlightRegionApplyTimed, setHighlightRegionApplyTimed] = useState(false);
  const [highlightTerritoryApplyTimed, setHighlightTerritoryApplyTimed] = useState(false);
  const [highlightZoneApplyTimed, setHighlightZoneApplyTimed] = useState(false);

  const [statTempTimedStatus, setStatTempTimedStatus] = useState(timedDrawFilter.orderStatus || []);
  const [statTempTimedColor, setStatTempTimedColor] = useState(timedDrawFilter.orderColor || []);
  const [statTempTimedRegion, setStatTempTimedRegion] = useState(timedDrawFilter.orderRegion || []);
  const [statTempTimedTerritory, setStatTempTimedTerritory] = useState(timedDrawFilter.orderTerr || []);
  const [statTempTimedZone, setStatTempTimedZone] = useState(timedDrawFilter.orderZone || []);

  // home-health
  const [homeHealthData, setHomeHealthData] = React.useState({
    value: false,
    mode: "view",
  });
  const homeHealthResponse = useSelector((state) => state.homeHealthGridData?.data);
  const homeHealthLoading = useSelector((state) => state.homeHealthGridData?.loading);

  const addHomeHealths = useSelector(addHomeHealthList);
  const updateHomeHealthsResponse = useSelector(updateHomeHealthList);
  const cloneHomeHealths = useSelector(cloneHomeHealthList);
  const cloneHomeHealthListLoader = useSelector(cloneHomeHealthListLoading);
  const bulkCheckinCheckoutHomeHealthData = useSelector(bulkCheckinCheckoutHomeHealthResponse);
  const bulkCheckinCheckoutHomeHealthLoader = useSelector(bulkCheckinCheckoutHomeHealthLoading);
  const updateHomeHealthLoading = useSelector(updateHomeHealthLoader);
  const homeHealthUserScheduleLoader = useSelector(homeHealthUserScheduleLoading);
  const addHomeHealthListLoader = useSelector(addHomeHealthListLoading);
  const homeHealthUserScheduleListResponse = useSelector(homeHealthUserScheduleList);
  const cancelHomeHealths = useSelector(cancelHomeHealthList);
  const dataByPartnerOrderIdLoading = useSelector(dataByPartnerOrderIdLoader);
  const dataByPartnerOrderIdResponse = useSelector(dataByPartnerOrderId);
  const uncancelHomeHealths = useSelector(uncancelHomeHealthList);

  const homeDateRangeRef = useRef();
  const homeDateRangeRef2 = useRef();

  const homeHealthFilter = window.localStorage.getItem(`homeHealthFilter`)
    ? JSON.parse(window.localStorage.getItem(`homeHealthFilter`)) || {}
    : {};
  const [homeHealthStartDate, setHomeHealthStartDate] = useState(
    homeHealthFilter.orderStartDate
      ? new Date(homeHealthFilter.orderStartDate)
      : todayTwo
  );
  const [homeHealthEndDate, setHomeHealthEndDate] = useState(
    homeHealthFilter.orderEndDate
      ? new Date(homeHealthFilter.orderEndDate)
      : homeHealthFilter.orderStartDate
        ? null
        : new Date()
  );

  const [openHomeHealth, setOpenHomeHealth] = useState(
    homeHealthFilter.openHomeHealth || false
  );

  const [droppedOffHomeHealth, setDroppedOffHomeHealth] = useState(
    homeHealthFilter.droppedOffHomeHealth || false
  );

  const [unscheduledHomeHealth, setUnscheduledHomeHealth] = useState(
    homeHealthFilter.unscheduledHomeHealth || false
  );

  const [cancelledHomeHealth, setCancelledHomeHealth] = useState(
    homeHealthFilter.cancelledHomeHealth || false
  );

  const [allOrdersHomeHealth, setAllOrdersHomeHealth] = useState(
    homeHealthFilter.allOrdersHomeHealth || false
  );

  const [homeHealthStatus, setHomeHealthStatus] = useState(
    homeHealthFilter.orderStatus || ""
  );

  const [homeHealthRegion, setHomeHealthRegion] = useState(
    homeHealthFilter.orderRegion || ""
  );
  const [homeHealthState, setHomeHealthState] = useState(
    homeHealthFilter.orderState || ""
  );
  const [homeHealthPartner, setHomeHealthPartner] = useState(
    homeHealthFilter.orderPartner || ""
  );

  const [orderDateType, setOrderDateType] = useState(
    homeHealthFilter.orderDateType === "" || homeHealthFilter.orderDateType === undefined ? true : homeHealthFilter.orderDateType
  );

  const [isClickedHome, setIsClickedHome] = React.useState(false);

  const [highlightStatusApplyHome, setHighlightStatusApplyHome] = useState(false);
  const [highlightRegionApplyHome, setHighlightRegionApplyHome] = useState(false);
  const [highlightStateApplyHome, setHighlightStateApplyHome] = useState(false);
  const [highlightPartnerApplyHome, setHighlightPartnerApplyHome] = useState(false);

  const [homeHealthTempStatus, setHomeHealthTempStatus] = useState(homeHealthFilter.orderStatus || []);
  const [homeHealthTempRegion, setHomeHealthTempRegion] = useState(homeHealthFilter.orderRegion || []);
  const [homeHealthTempState, setHomeHealthTempState] = useState(homeHealthFilter.orderState || []);
  const [homeHealthTempPartner, setHomeHealthTempPartner] = useState(homeHealthFilter.orderPartner || []);

  const [error, setError] = React.useState("");

  const [helpModal, setHelpModal] = React.useState(false);

  const [previousPhlebInfo, setPreviousPhlebInfo] = React.useState([]);
  const [ignoreUserAvailability, setIgnoreUserAvailability] = useState(false);
  const [currentPath, setCurrentPath] = useState(window.location.pathname)
  const [resetFilters, setResetFilters] = useState(false);
  const [resetStatusFilters, setResetStatusFilters] = useState(false);

  const orderExportData = useSelector(exportDataResponse);
  const exportDataLoading = useSelector(exportDataLoader);
  const isExportDisabledStatVal = useSelector(isExportDisabledStat);
  const isExportDisabledRoutineVal = useSelector(isExportDisabledRoutine);
  const isExportDisabledTimedVal = useSelector(isExportDisabledTimed);
  const isExportDisabledHomeHealthVal = useSelector(isExportDisabledHomeHealth);

  const careDxDropSites = useSelector(careDxDropSitesResponse);
  let dropSites = careDxDropSites?.content || [];
  dropSites = dropSites ? dropSites.map(site => {return {...site, label: site.name, value: site.id}}) : [];

  const { search } = useLocation();
  const location = useLocation();
  const searchParams = new URLSearchParams(search);
  const orderId = searchParams.get("id");

  React.useEffect(() => {
    document.documentElement.classList.add(statOrderClass);
    if (tabValue === TYPE_HOMEHEALTH) {
      document.documentElement.classList.add(careAtHomeOrderClass);
    }
  }, []);

  React.useEffect(() => {
    dispatch(getProfile());
    dispatch(orderFilterList());
  }, [window.location.pathname]);

  React.useEffect(() => {
      setCurrentPath(location.pathname);
  }, [location]);

  React.useEffect(() => {
    if (activeZonesWithDate && activeZonesWithDate.status === "SUCCESS") {
      if (activeZonesWithDate && activeZonesWithDate.content) {
        let zone = activeZonesWithDate.content || [];
        if (tabValue === TYPE_TIMEDDRAW) {
          if (timedDrawZone && timedDrawZone.length) {
            let tempZone = [...timedDrawZone];
            timedDrawZone.forEach(z => {
              if (!zone.some(z2 => z2.id == z.id)) {
                tempZone = tempZone.filter(z3 => z3.id != z.id);
              }
            });
            updateFilterTimedDrawInLocal({ orderZone: tempZone });
            setTimedDrawZone(tempZone);
            setStatTempTimedZone(tempZone);
          }
        } else if (tabValue === TYPE_HOMEHEALTH) {
        } else if (tabValue === "routine") {
          if (routineRedrawZone && routineRedrawZone.length) {
            let tempZone = [...routineRedrawZone];
            routineRedrawZone.forEach(z => {
              if (!zone.some(z2 => z2.id == z.id)) {
                tempZone = tempZone.filter(z3 => z3.id != z.id);
              }
            });
            updateFilterRoutineRedrawInLocal({ orderZone: tempZone });
            setRoutineRedrawZone(tempZone);
            setStatTempRoutineZone(tempZone);
          }
        } else {
          if (orderZone && orderZone.length) {
            let tempZone = [...orderZone];
            orderZone.forEach(z => {
              if (!zone.some(z2 => z2.id == z.id)) {
                tempZone = tempZone.filter(z3 => z3.id != z.id);
              }
            });
            updateFilterInLocal({ orderZone: tempZone });
            setOrderZone(tempZone);
            setStatTempZone(tempZone);
          }
        }
      }
    } else if (profile && profile.status === "ERROR") {
    }
  }, [activeZonesWithDate]);

  useEffect(() => {
    console.log(currentPath, 'currentPath');
    if (currentPath == HOMEHEALTH) {
      tabValue = TYPE_HOMEHEALTH
    } else if (currentPath == TIMEDDRAW) {
      tabValue = TYPE_TIMEDDRAW;

    } else if (currentPath == ROUTINEREDRAW) {
      tabValue = "routine"

    } else if (currentPath == STATORDER) {
      tabValue = "stats";

    }
  }, [currentPath]);

  React.useEffect(() => {
    if (profile && profile.status === "SUCCESS") {
      if (profile && profile.content) {
        const userPermission =
          profile &&
          profile.content &&
          profile.content.permission &&
          profile.content.permission.filter((val, i) => {
            return val.page === "users";
          });
        const uPer =
          userPermission && userPermission[0] && userPermission[0].access;

        const filter_list =
          uPer && uPer.find((item) => item === "filter-list") === "filter-list"
            ? true
            : false;
        localStorage.setItem(
          "userDetail",
          JSON.stringify(profile && profile.content)
        );
        if (filter_list) {
          dispatch(getFilter());
          console.log(statOrderFilter, orderStartDate, 'ooooooooo');
          dispatch(getActiveZones());
        }
        setName(profile && profile.content && profile.content.name);
        setColor(profile && profile.content && profile.content.icon_color_code);
      }
    } else if (profile && profile.status === "ERROR") {
    }
  }, [profile]);

  React.useEffect(() => {
    if (tabValue === TYPE_TIMEDDRAW) {
      let timedDrawFilter = localStorage.getItem("timedDrawFilter") || "";
      timedDrawFilter = timedDrawFilter ? JSON.parse(timedDrawFilter) : {};

      if (timedDrawFilter && Object.keys(timedDrawFilter).length) {
        const { orderStartDate, orderEndDate, dateChangedLastTime } =
          timedDrawFilter;

        let newStartDay = orderStartDate;
        let newEndDate = orderEndDate;
        const start = moment(orderStartDate, "YYYY-MM-DD");
        const end = moment(orderEndDate).startOf("day");

        const diff = Math.abs(
          moment(start, "YYYY-MM-DD")
            .startOf("day")
            .diff(moment(end, "YYYY-MM-DD").startOf("day"), "days")
        );

        const dateFilterDiff = dateChangedLastTime
          ? moment(new Date()).diff(
            moment(new Date(dateChangedLastTime)),
            "hours"
          )
          : 0;
        const currDate = new Date();

        if (diff > 7) {
          updateFilterTimedDrawInLocal({
            // orderStatus: [],
            orderStartDate: today,
            orderEndDate: currDate,
            dateChangedLastTime: currDate,
            orderDateType: false
          });
          if (timedDateRangeRef && timedDateRangeRef.current) {
            timedDateRangeRef.current.setStartDate(today);
            timedDateRangeRef.current.setEndDate(currDate);
          }
          // setTimedDrawStatus([]);
          setTimedDrawStartDate(today);
          setTimedDrawEndDate(currDate);
          setOrderDateTypeTimedDraw(false);
          newStartDay = today;
          newEndDate = currDate;
        }
        if (!dateChangedLastTime || dateFilterDiff >= 24) {
          updateFilterTimedDrawInLocal({
            orderStartDate: today,
            orderEndDate: currDate,
            dateChangedLastTime: currDate,
            orderDateType: false
          });
          if (timedDateRangeRef && timedDateRangeRef.current) {
            timedDateRangeRef.current.setStartDate(today);
            timedDateRangeRef.current.setEndDate(currDate);
          }
          setTimedDrawStartDate(today);
          setTimedDrawEndDate(currDate);
          setOrderDateTypeTimedDraw(false);
          newStartDay = today;
          newEndDate = currDate;
        } else {
          getInitialTimedOrders();
        }
        handleGetActiveZones(newStartDay, newEndDate);
      } else {
        getInitialTimedOrders();
      }

      const isExportDisabledTimed = localStorage.getItem(`export-${ORDER_TYPE_TIMED_DRAW}`) === "true" ? true : false;
      dispatch(setIsExportDisabledTimed(isExportDisabledTimed));
    } else if (tabValue === TYPE_HOMEHEALTH) {
      let homeHealthFilter = localStorage.getItem("homeHealthFilter") || "";
      homeHealthFilter = homeHealthFilter ? JSON.parse(homeHealthFilter) : {};

      if (homeHealthFilter && Object.keys(homeHealthFilter).length) {
        const {
          orderStartDate,
          orderEndDate,
          dateChangedLastTime
        } = homeHealthFilter;

        const start = moment(orderStartDate, "YYYY-MM-DD");
        const end = moment(orderEndDate).startOf("day");

        const diff = Math.abs(
          moment(start, "YYYY-MM-DD")
            .startOf("day")
            .diff(moment(end, "YYYY-MM-DD").startOf("day"), "days")
        );

        const dateFilterDiff = dateChangedLastTime
          ? moment(new Date()).diff(
            moment(new Date(dateChangedLastTime)),
            "hours"
          )
          : 0;

        if (diff > 7) {
          updateFilterHomeHealthInLocal({
            // orderStatus: [],
            orderStartDate: today,
            orderEndDate: new Date(),
            dateChangedLastTime: new Date(),
            orderDateType: true
          });

          if (homeDateRangeRef && homeDateRangeRef.current) {
            homeDateRangeRef.current.setStartDate(today);
            homeDateRangeRef.current.setEndDate(new Date());
          }
          // setHomeHealthStatus([]);
          setHomeHealthStartDate(today);
          setHomeHealthEndDate(new Date());
          setOrderDateType(true);
        }

        if (!dateChangedLastTime || dateFilterDiff >= 24) {
          updateFilterHomeHealthInLocal({
            orderStartDate: today,
            orderEndDate: new Date(),
            dateChangedLastTime: new Date(),
            orderDateType: true
          });
          if (homeDateRangeRef && homeDateRangeRef.current) {
            homeDateRangeRef.current.setStartDate(today);
            homeDateRangeRef.current.setEndDate(new Date());
          }
          setHomeHealthStartDate(today);
          setHomeHealthEndDate(new Date());
          setOrderDateType(true);
        } else {
          getInitialHomeHealthOrders();
        }
      } else {
        getInitialHomeHealthOrders();
      }
      const isExportDisabledHomeHealth = localStorage.getItem(`export-${ORDER_TYPE_HOME_HEALTH}`) === "true" ? true : false;
      dispatch(setIsExportDisabledHomeHealth(isExportDisabledHomeHealth));
    } else if (tabValue === "routine") {
      let routineRedrawFilter =
        localStorage.getItem("routineRedrawFilter") || "";
      routineRedrawFilter = routineRedrawFilter
        ? JSON.parse(routineRedrawFilter)
        : {};

      if (routineRedrawFilter && Object.keys(routineRedrawFilter).length) {
        const { orderStartDate, orderEndDate, dateChangedLastTime } =
          routineRedrawFilter;
        const start = moment(orderStartDate, "YYYY-MM-DD");
        const end = moment(orderEndDate).startOf("day");

        let newStartDay = orderStartDate;
        let newEndDate = orderEndDate;

        const diff = Math.abs(
          moment(start, "YYYY-MM-DD")
            .startOf("day")
            .diff(moment(end, "YYYY-MM-DD").startOf("day"), "days")
        );

        const dateFilterDiff = dateChangedLastTime
          ? moment(new Date()).diff(
            moment(new Date(dateChangedLastTime)),
            "hours"
          )
          : 0;
        const currDate = new Date();
        if (diff > 7) {
          updateFilterRoutineRedrawInLocal({
            // orderStatus: [],
            orderStartDate: today,
            orderEndDate: currDate,
            dateChangedLastTime: currDate,
            orderDateType: false
          });
          if (routineDateRangeRef && routineDateRangeRef.current) {
            routineDateRangeRef.current.setStartDate(today);
            routineDateRangeRef.current.setEndDate(currDate);
          }
          // setRoutineRedrawStatus([]);
          setRoutineRedrawStartDate(today);
          setRoutineRedrawEndDate(currDate);
          setOrderDateTypeRoutine(false);
          newStartDay = today;
          newEndDate = currDate;
        }
        if (!dateChangedLastTime || dateFilterDiff >= 24) {
          updateFilterRoutineRedrawInLocal({
            orderStartDate: today,
            orderEndDate: currDate,
            dateChangedLastTime: currDate,
            orderDateType: false
          });
          if (routineDateRangeRef && routineDateRangeRef.current) {
            routineDateRangeRef.current.setStartDate(today);
            routineDateRangeRef.current.setEndDate(currDate);
          }
          setRoutineRedrawStartDate(today);
          setRoutineRedrawEndDate(currDate);
          setOrderDateTypeRoutine(false);
          newStartDay = today;
          newEndDate = currDate;
        } else {
          getInitialRoutineOrders();
        }
        handleGetActiveZones(newStartDay, newEndDate);
      } else {
        getInitialRoutineOrders();
      }
      const isExportDisabledRoutine = localStorage.getItem(`export-${ORDER_TYPE_ROUTINE_REDRAW}`) === "true" ? true : false;
      dispatch(setIsExportDisabledRoutine(isExportDisabledRoutine));
    } else {
      let statOrderFilter = localStorage.getItem("statOrderFilter") || "";
      statOrderFilter = statOrderFilter ? JSON.parse(statOrderFilter) : {};

      if (statOrderFilter && Object.keys(statOrderFilter).length) {
        const { orderStartDate, orderEndDate, dateChangedLastTime } =
          statOrderFilter;
        const start = moment(new Date(orderStartDate), "YYYY-MM-DD");
        const end = moment(new Date(orderEndDate)).startOf("day");

        let newStartDay = orderStartDate;
        let newEndDate = orderEndDate;

        const diff = Math.abs(
          moment(start, "YYYY-MM-DD")
            .startOf("day")
            .diff(moment(end, "YYYY-MM-DD").startOf("day"), "days")
        );

        const dateFilterDiff = dateChangedLastTime
          ? moment(new Date()).diff(
            moment(new Date(dateChangedLastTime)),
            "hours"
          )
          : 0;
        const currDate = new Date();
        if (diff > 7) {
          updateFilterInLocal({
            // orderStatus: [],
            orderStartDate: today,
            orderEndDate: currDate,
            dateChangedLastTime: currDate,
            orderDateType: false
          });
          if (orderDateRangeRef && orderDateRangeRef.current) {
            orderDateRangeRef.current.setStartDate(today);
            orderDateRangeRef.current.setEndDate(currDate);
          }
          // setOrderStatus([]);
          setOrderStartDate(today);
          setOrderEndDate(currDate);
          setOrderDateTypeStat(false);
          newStartDay = today;
          newEndDate = currDate;
        }
        if (!dateChangedLastTime || dateFilterDiff >= 24) {
          updateFilterInLocal({
            orderStartDate: today,
            orderEndDate: currDate,
            dateChangedLastTime: currDate,
            orderDateType: false
          });
          if (orderDateRangeRef && orderDateRangeRef.current) {
            orderDateRangeRef.current.setStartDate(today);
            orderDateRangeRef.current.setEndDate(currDate);
          }
          setOrderStartDate(today);
          setOrderEndDate(currDate);
          setOrderDateTypeStat(false);
          newStartDay = today;
          newEndDate = currDate;
        } else {
          getInitialStatOrders();
        }
        handleGetActiveZones(newStartDay, newEndDate);
      } else {
        getInitialStatOrders();
      }
      const isExportDisabledStat = localStorage.getItem(`export-${ORDER_TYPE_STAT}`) === "true" ? true : false;
      dispatch(setIsExportDisabledStat(isExportDisabledStat));
    }
  }, [tabValue]);

  React.useEffect(() => {
    if (tabValue === TYPE_HOMEHEALTH) {
      if (isMounted.current) {
        getInitialHomeHealthOrders();
      } else {
        isMounted.current = true;
      }
    }
  }, [
    homeHealthEndDate,
    homeHealthStatus,
    homeHealthRegion,
    homeHealthState,
    homeHealthPartner,
    orderDateType,
    cancelledHomeHealth,
    droppedOffHomeHealth,
    unscheduledHomeHealth,
    allOrdersHomeHealth
  ]);

  React.useEffect(() => {
    tabValue === TYPE_TIMEDDRAW && handleGetActiveZones(timedDrawStartDate, timedDrawEndDate);
  }, [
    timedDrawEndDate
  ]);

  React.useEffect(() => {
    tabValue === "routine" && handleGetActiveZones(routineRedrawStartDate, routineRedrawEndDate);
  }, [
    routineRedrawEndDate
  ]);

  React.useEffect(() => {
    tabValue === "stats" && handleGetActiveZones(orderStartDate, orderEndDate);
  }, [
    orderEndDate
  ]);

  React.useEffect(() => {
    if (tabValue === TYPE_TIMEDDRAW) {
      if (isMounted.current) {
        getInitialTimedOrders();
      } else {
        isMounted.current = true;
      }
    }
  }, [
    timedDrawEndDate,
    timedDrawStatus,
    orderDateTypeTimedDraw,
    timedDrawRegion,
    timedDrawTerr,
    timedDrawZone,
    cancelledTimedDraws,
    droppedOffTimedDraws,
    allOrdersTimedDraws
  ]);

  React.useEffect(() => {
    if (tabValue === "routine") {
      if (isMounted.current) {
        getInitialRoutineOrders();
      } else {
        isMounted.current = true;
      }
    }
  }, [
    // orderStartDate,
    routineRedrawEndDate,
    routineRedrawStatus,
    orderDateTypeRoutine,
    routineRedrawRegion,
    routineRedrawTerr,
    routineRedrawZone,
    cancelledRoutineRedraws,
    droppedOffRoutineRedraws,
    allOrdersRoutineRedraws
  ]);

  React.useEffect(() => {
    if (tabValue === "stats") {
      if (isMounted.current) {
        getInitialStatOrders();
      } else {
        isMounted.current = true;
      }
    }
  }, [
    // orderStartDate,
    orderEndDate,
    orderStatus,
    orderDateTypeStat,
    orderRegion,
    orderTerr,
    orderZone,
    cancelledOrders,
    droppedOffOrders,
    allOrders
  ]);
  React.useEffect(() => {
    if (orderResponse && orderResponse.status === "SUCCESS" && orderResponse.content) {
      if (tabValue === TYPE_TIMEDDRAW) {
        setTimedDrawData({ show: true, mode: "view", data: orderResponse.content || {} });
      } else if (tabValue === TYPE_HOMEHEALTH) {
        const {partner = {}} = orderResponse.content || {};
        if (partner.partner_name === CAREDX_LABEL) {
          handleGetCareDxDropSites();
        }
        setHomeHealthData({ show: true, mode: "view", data: orderResponse.content || {}, isGlobalSearch: orderResponse.content?.isGlobalSearch });
      } else if (tabValue === "routine") {
        setRoutineRedrawData({ show: true, mode: "view", data: orderResponse.content || {} });
      } else {
        setStatOrderData({ show: true, mode: "view", data: orderResponse.content || {} });
        setReRender(!reRender);
      }
    } else if (orderResponse && orderResponse.status === "ERROR" && orderResponse.message) {

      if (tabValue === TYPE_TIMEDDRAW) {
        handleTimedDrawError();
        toggleTimedDrawModal(false);
        if (orderId) {
          navigate(TIMEDDRAW, { replace: true });
        }
      } else if (tabValue === TYPE_HOMEHEALTH) {
        toggleHomeHealthModal(false);
        if (orderId) {
          navigate(HOMEHEALTH, { replace: true });
        }
      } else if (tabValue === "routine") {
        handleRoutineRedrawError();
        toggleRoutineRedrawModal(false);
        if (orderId) {
          navigate(ROUTINEREDRAW, { replace: true });
        }
      } else {
        handleStatOrderError();
        toggleStatOrderModal(false);
        if (orderId) {
          navigate(STATORDER, { replace: true });
        }
      }
      setTimeout(() => {
        hotToast(
          (t) => (
            <ToastContent type="error" message={orderResponse.message} close={() => hotToast.remove()} />
          ),
          {
            duration: Infinity
          }
        );
      }, 1)
    }
  }, [orderResponse, tabValue]);

  React.useEffect(() => {
    if (homeHealthResponse && orderId) {
      if (tabValue === TYPE_HOMEHEALTH && homeHealthResponse.status === "SUCCESS") {
        dispatch(getOrder({ id: orderId, type: TYPE_HOMEHEALTH, isGlobalSearch: true }));
        // setHomeHealthData({ show: true, mode: "view", data: {} });
      }
    }
  }, [homeHealthResponse]);

  React.useEffect(() => {
    if (timedDrawsResponse && orderId) {
      if (tabValue === TYPE_TIMEDDRAW && timedDrawsResponse.status === "SUCCESS") {
        dispatch(getOrder({ id: orderId, type: TYPE_TIMEDDRAW }));
        // setTimedDrawData({ show: true, mode: "view", data: {} });
      }
    }
  }, [timedDrawsResponse]);

  React.useEffect(() => {
    if (routineRedrawsResponse && orderId) {
      if (tabValue === "routine" && routineRedrawsResponse.status === "SUCCESS") {
        dispatch(getOrder({ id: orderId, type: TYPE_ROUTINE }));
        // setRoutineRedrawData({ show: true, mode: "view", data: {} });
      }
    }
  }, [routineRedrawsResponse]);

  React.useEffect(() => {
    if (statOrdersResponse && orderId) {
      if (tabValue === "stats" && statOrdersResponse.status === "SUCCESS") {
        dispatch(getOrder({ id: orderId, type: "stats" }));
        // setStatOrderData({ show: true, mode: "view", data: {} });
      }
    }
  }, [statOrdersResponse]);

  React.useEffect(() => {
    if (orderExportData && orderExportData.status === "SUCCESS") {
      dispatch(setExportData({}));
      let entity;
      if (tabValue === TYPE_TIMEDDRAW) {
        entity = ORDER_TYPE_TIMED_DRAW;
      } else if (tabValue === TYPE_HOMEHEALTH) {
        entity = ORDER_TYPE_HOME_HEALTH;
      } else if (tabValue === "routine") {
        entity = ORDER_TYPE_ROUTINE_REDRAW;
      } else {
        entity = ORDER_TYPE_STAT;
      }
      hotToast((t) =>
        <div className="file-toaster">
          <div className="toaster-close">
            <span
              className="close-icon"
              data-testid="toast-header"
              onClick={() => {
                hotToast.dismiss(t.id);
              }}
              aria-label="Close"
            >
              <span className="visually-hidden">Close Icon</span>
            </span>
          </div>
          <div>
            <div className="toast-info-details d-flex align-items-center">
              <span className={`exclamation-with-circle pr-10`}> <span className="visually-hidden">
              exclamation-with-circle
              </span></span>
              <p className="font-size-12 custom-toast-msg-text">Your file is being prepared for download.</p>
            </div>
          </div></div>
        , {
          position: "bottom-right",
          duration: 10000,
          className: "export-file-toaster-wrapper export-file-info-wrapper"
        })
      dispatch(getExportFileData({ entity: "Order", entity_type: entity }));
    }
  }, [orderExportData]);

  React.useEffect(() => {
    if (addStatOrder && addStatOrder.status === "SUCCESS") {
      const { content = {} } = addStatOrder;
      toggleStatOrderModal(false, {}, "view");

      setTimeout(() => {
        setLottie(true);
        // dispatch(getOrder({ id: content.id, type: "stats", addRow: true }));
        getInitialStatOrders();
        setError("");
      }, 1);
      setTimeout(() => {
        setLottie(false);
        setToastVal(false);
        setToastMessage(`Order #${content.order_number} has been created`);
      }, 2000);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 6000);
    } else if (addStatOrder && addStatOrder.status === "ERROR") {
      if (
        addStatOrder.message_code &&
        addStatOrder.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (addStatOrder.message_code === "SESSION_EXPIRED_LOGIN_AGAIN") {
      } else {
        setError(addStatOrder && addStatOrder.message);
      }
    }
  }, [addStatOrder]);

  React.useEffect(() => {
    if (addRoutineRedraw && addRoutineRedraw.status === "SUCCESS") {
      const { content = {} } = addRoutineRedraw;
      toggleRoutineRedrawModal(false, {}, "view");
      setTimeout(() => {
        setLottie(true);
        getInitialRoutineOrders();
        setError("");
      }, 1);
      setTimeout(() => {
        setLottie(false);
        setToastVal(false);
        setToastMessage(
          `Routine-Redraw #${content.order_number} has been created`
        );
      }, 2000);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 6000);
    } else if (addRoutineRedraw && addRoutineRedraw.status === "ERROR") {
      if (
        addRoutineRedraw.message_code &&
        addRoutineRedraw.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        addRoutineRedraw.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(addRoutineRedraw && addRoutineRedraw.message);
      }
    }
  }, [addRoutineRedraw]);

  React.useEffect(() => {
    if (addTimedDraw && addTimedDraw.status === "SUCCESS") {
      const { content = {} } = addTimedDraw;
      toggleTimedDrawModal(false, {}, "view");
      setTimeout(() => {
        setLottie(true);
        getInitialTimedOrders();
        setError("");
      }, 1);
      setTimeout(() => {
        setLottie(false);
        setToastVal(false);
        setToastMessage(`Timed-Draw #${content.order_number} has been created`);
      }, 2000);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 6000);
    } else if (addTimedDraw && addTimedDraw.status === "ERROR") {
      if (
        addTimedDraw.message_code &&
        addTimedDraw.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (addTimedDraw.message_code === "SESSION_EXPIRED_LOGIN_AGAIN") {
      } else {
        setError(addTimedDraw && addTimedDraw.message);
      }
    }
  }, [addTimedDraw]);

  React.useEffect(() => {
    if (cancelHomeHealths && cancelHomeHealths.status === "SUCCESS") {
      const { content = {} } = cancelHomeHealths;
      toggleHomeHealthModal(false, {}, "view");
      setTimeout(() => {
        setLottie(true);
        getInitialHomeHealthOrders();
        setError("");
      }, 1);
      setTimeout(() => {
        setLottie(false);
        setToastVal(false);
        setToastMessage(
          `Care-at-Home #${content.order_number} has been updated`
        );
      }, 2000);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 6000);
      setIgnoreUserAvailability(false);
    } else if (cancelHomeHealths && cancelHomeHealths.status === "ERROR") {
      if (
        cancelHomeHealths.message_code &&
        cancelHomeHealths.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        cancelHomeHealths.message_code === "SESSION_EXPIRED_LOGIN_AGAIN" || cancelHomeHealths.message_code === "HOME_HEALTH_USER_BUSY_WARN"
      ) {
      } else {
        setError(cancelHomeHealths && cancelHomeHealths.message);
      }
    }
  }, [cancelHomeHealths]);

  React.useEffect(() => {
    if (uncancelHomeHealths && uncancelHomeHealths.status === "SUCCESS") {
      const { content = {} } = uncancelHomeHealths;
      toggleHomeHealthModal(false, {}, "view");
      setTimeout(() => {
        setLottie(true);
        getInitialHomeHealthOrders();
        setError("");
      }, 1);
      setTimeout(() => {
        setLottie(false);
        setToastVal(false);
        setToastMessage(
          `Care-at-Home #${content.order_number} has been updated`
        );
      }, 2000);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 6000);
      setIgnoreUserAvailability(false);
    } else if (uncancelHomeHealths && uncancelHomeHealths.status === "ERROR") {
      if (
        uncancelHomeHealths.message_code &&
        uncancelHomeHealths.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        uncancelHomeHealths.message_code === "SESSION_EXPIRED_LOGIN_AGAIN" || uncancelHomeHealths.message_code === "HOME_HEALTH_USER_BUSY_WARN"
      ) {
      } else {
        setError(uncancelHomeHealths && uncancelHomeHealths.message);
      }
    }
  }, [uncancelHomeHealths]);

  React.useEffect(() => {
    if (addHomeHealths && addHomeHealths.status === "SUCCESS") {
      const { content = {} } = addHomeHealths;
      toggleHomeHealthModal(false, {}, "view");
      setTimeout(() => {
        setLottie(true);
        getInitialHomeHealthOrders();
        setError("");
      }, 1);
      setTimeout(() => {
        setLottie(false);
        setToastVal(false);
        setToastMessage(
          `Care-at-Home #${content.order_number} has been created`
        );
      }, 2000);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 6000);
      setIgnoreUserAvailability(false);
      handleClearDataByPartnerOrderId();
    } else if (addHomeHealths && addHomeHealths.status === "ERROR") {
      if (
        addHomeHealths.message_code &&
        addHomeHealths.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        addHomeHealths.message_code === "SESSION_EXPIRED_LOGIN_AGAIN" || addHomeHealths.message_code === "HOME_HEALTH_USER_BUSY_WARN"
      ) {
      } else {
        setError(addHomeHealths && addHomeHealths.message);
      }
    }
  }, [addHomeHealths]);

  React.useEffect(() => {
    if (cloneHomeHealths && cloneHomeHealths.status === "SUCCESS") {
      const { content = {} } = cloneHomeHealths;
      toggleHomeHealthModal(false, {}, "view");
      setTimeout(() => {
        setLottie(true);
        getInitialHomeHealthOrders();
        setError("");
      }, 1);
      setTimeout(() => {
        setLottie(false);
        setToastVal(false);
        setToastMessage(
          `Care-at-Home #${content.order_number} has been created`
        );
      }, 2000);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 6000);
      setIgnoreUserAvailability(false);
      handleClearDataByPartnerOrderId();
    } else if (cloneHomeHealths && cloneHomeHealths.status === "ERROR") {
      if (
        cloneHomeHealths.message_code &&
        cloneHomeHealths.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        cloneHomeHealths.message_code === "SESSION_EXPIRED_LOGIN_AGAIN" || cloneHomeHealths.message_code === "HOME_HEALTH_USER_BUSY_WARN"
      ) {
      } else {
        setError(cloneHomeHealths && cloneHomeHealths.message);
      }
    }
  }, [cloneHomeHealths]);

  React.useEffect(() => {
    if (cancelStatOrder && cancelStatOrder.status === "SUCCESS") {
      const { content = {} } = cancelStatOrder;
      if (tabValue === "stats") {
        toggleStatOrderModal(false, {}, "view");
        setTimeout(() => {
          setLottie(true);
          // dispatch(getOrder({ id: content.id, type: "stats", updateRow: true }));
          getInitialStatOrders();
          setError("");
        }, 1);
        setTimeout(() => {
          setLottie(false);
          setToastVal(false);
          setToastMessage(`Order #${content.order_number} has been updated`);
        }, 2000);
        setTimeout(() => {
          setToastVal(true);
          setToastMessage(``);
        }, 6000);
      } else if (tabValue === "routine") {
        toggleRoutineRedrawModal(false, {}, "view");
        setTimeout(() => {
          setLottie(true);
          // setResetGridInternalFiltersRoutine(true);
          getInitialRoutineOrders();
          setError("");
        }, 1);
        setTimeout(() => {
          setLottie(false);
          setToastVal(false);
          setToastMessage(
            `Routine-redraw #${content.order_number} has been updated`
          );
        }, 2000);
        setTimeout(() => {
          setToastVal(true);
          setToastMessage(``);
        }, 6000);
      } else if (tabValue === TYPE_TIMEDDRAW) {
        toggleTimedDrawModal(false, {}, "view");
        setTimeout(() => {
          setLottie(true);
          getInitialTimedOrders();
          setError("");
        }, 1);
        setTimeout(() => {
          setLottie(false);
          setToastVal(false);
          setToastMessage(`Timed-Draw #${content.order_number} has been updated`);
        }, 2000);
        setTimeout(() => {
          setToastVal(true);
          setToastMessage(``);
        }, 6000);
      }
    } else if (cancelStatOrder && cancelStatOrder.status === "ERROR") {
      if (
        cancelStatOrder.message_code &&
        cancelStatOrder.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        cancelStatOrder.message_code === "SESSION_EXPIRED_LOGIN_AGAIN" || cancelStatOrder.message_code === "HOME_HEALTH_USER_BUSY_WARN"
      ) {
      } else {
        setError(cancelStatOrder && cancelStatOrder.message);
      }
    }
  }, [cancelStatOrder]);

  React.useEffect(() => {
    if (
      updateStatOrderResponse &&
      updateStatOrderResponse.status === "SUCCESS"
    ) {
      const { content = {} } = updateStatOrderResponse;
      toggleStatOrderModal(false, {}, "view");
      setTimeout(() => {
        setLottie(true);
        // dispatch(getOrder({ id: content.id, type: "stats", updateRow: true }));
        getInitialStatOrders();
        setError("");
      }, 1);
      setTimeout(() => {
        setLottie(false);
        setToastVal(false);
        setToastMessage(`Order #${content.order_number} has been updated`);
      }, 2000);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 6000);
    } else if (
      updateStatOrderResponse &&
      updateStatOrderResponse.status === "ERROR"
    ) {
      if (
        updateStatOrderResponse.message_code &&
        updateStatOrderResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        updateStatOrderResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(updateStatOrderResponse && updateStatOrderResponse.message);
      }
    }
  }, [updateStatOrderResponse]);

  React.useEffect(() => {
    if (
      deleteStatActionResult &&
      deleteStatActionResult.status === "SUCCESS"
    ) {
      const { content = {} } = deleteStatActionResult;
      if (tabValue === "stats") {
        toggleStatOrderModal(false, {}, "view");
        setTimeout(() => {
          setLottie(true);
          // dispatch(getOrder({ id: content.id, type: "stats", updateRow: true }));
          getInitialStatOrders();
          setError("");
        }, 1);
        setTimeout(() => {
          setLottie(false);
          setToastVal(false);
          setToastMessage(`Order #${content.order_number} has been updated`);
        }, 2000);
        setTimeout(() => {
          setToastVal(true);
          setToastMessage(``);
        }, 6000);
      } else if (tabValue === "routine") {
        toggleRoutineRedrawModal(false, {}, "view");
        setTimeout(() => {
          setLottie(true);
          getInitialRoutineOrders();
          setError("");
        }, 1);
        setTimeout(() => {
          setLottie(false);
          setToastVal(false);
          setToastMessage(
            `Routine-redraw #${content.order_number} has been updated`
          );
        }, 2000);
        setTimeout(() => {
          setToastVal(true);
          setToastMessage(``);
        }, 6000);
      } else if (tabValue === TYPE_TIMEDDRAW) {
        toggleTimedDrawModal(false, {}, "view");
        setTimeout(() => {
          setLottie(true);
          getInitialTimedOrders();
          setError("");
        }, 1);
        setTimeout(() => {
          setLottie(false);
          setToastVal(false);
          setToastMessage(`Timed-Draw #${content.order_number} has been updated`);
        }, 2000);
        setTimeout(() => {
          setToastVal(true);
          setToastMessage(``);
        }, 6000);
      }
    } else if (
      deleteStatActionResult &&
      deleteStatActionResult.status === "ERROR"
    ) {
      if (
        deleteStatActionResult.message_code &&
        deleteStatActionResult.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        deleteStatActionResult.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(deleteStatActionResult && deleteStatActionResult.message);
      }
    }
  }, [deleteStatActionResult]);

  React.useEffect(() => {
    if (
      updateRoutineRedrawResponse &&
      updateRoutineRedrawResponse.status === "SUCCESS"
    ) {
      const { content = {} } = updateRoutineRedrawResponse;
      toggleRoutineRedrawModal(false, {}, "view");
      setTimeout(() => {
        setLottie(true);
        getInitialRoutineOrders();
        setError("");
      }, 1);
      setTimeout(() => {
        setLottie(false);
        setToastVal(false);
        setToastMessage(
          `Routine-redraw #${content.order_number} has been updated`
        );
      }, 2000);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 6000);
    } else if (
      updateRoutineRedrawResponse &&
      updateRoutineRedrawResponse.status === "ERROR"
    ) {
      if (
        updateRoutineRedrawResponse.message_code &&
        updateRoutineRedrawResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        updateRoutineRedrawResponse.message_code ===
        "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(
          updateRoutineRedrawResponse && updateRoutineRedrawResponse.message
        );
      }
    }
  }, [updateRoutineRedrawResponse]);

  React.useEffect(() => {
    if (
      updateTimedDrawResponse &&
      updateTimedDrawResponse.status === "SUCCESS"
    ) {
      const { content = {} } = updateTimedDrawResponse;
      toggleTimedDrawModal(false, {}, "view");
      setTimeout(() => {
        setLottie(true);
        getInitialTimedOrders();
        setError("");
      }, 1);
      setTimeout(() => {
        setLottie(false);
        setToastVal(false);
        setToastMessage(`Timed-Draw #${content.order_number} has been updated`);
      }, 2000);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 6000);
    } else if (
      updateTimedDrawResponse &&
      updateTimedDrawResponse.status === "ERROR"
    ) {
      if (
        updateTimedDrawResponse.message_code &&
        updateTimedDrawResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        updateTimedDrawResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(updateTimedDrawResponse && updateTimedDrawResponse.message);
      }
    }
  }, [updateTimedDrawResponse]);

  React.useEffect(() => {
    if (
      updateHomeHealthsResponse &&
      updateHomeHealthsResponse.status === "SUCCESS"
    ) {
      const { content = {} } = updateHomeHealthsResponse;
      toggleHomeHealthModal(false, {}, "view");
      setTimeout(() => {
        setLottie(true);
        getInitialHomeHealthOrders();
        setError("");
      }, 1);
      setTimeout(() => {
        setLottie(false);
        setToastVal(false);
        setToastMessage(`Care-At-Home #${content.order_number} has been updated`);
      }, 2000);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 6000);
      setIgnoreUserAvailability(false);
    } else if (
      updateHomeHealthsResponse &&
      updateHomeHealthsResponse.status === "ERROR"
    ) {
      if (
        updateHomeHealthsResponse.message_code &&
        updateHomeHealthsResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        updateHomeHealthsResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN" || updateHomeHealthsResponse.message_code === "HOME_HEALTH_USER_BUSY_WARN"
      ) {
      } else {
        setError(updateHomeHealthsResponse && updateHomeHealthsResponse.message);
      }
    }
  }, [updateHomeHealthsResponse]);

  React.useEffect(() => {
    if (
      dataByPartnerOrderIdResponse &&
      dataByPartnerOrderIdResponse.status === "SUCCESS"
    ) {
      const { content = {} } = dataByPartnerOrderIdResponse;
      setHomeHealthData({
        ...homeHealthData,
        data: { ...content, disable_validation: true, clearAllFields: true },
      });
      // setError("");
    } else if (
      dataByPartnerOrderIdResponse &&
      dataByPartnerOrderIdResponse.status === "ERROR"
    ) {
      if (
        dataByPartnerOrderIdResponse.message_code &&
        dataByPartnerOrderIdResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        dataByPartnerOrderIdResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        // setError(dataByPartnerOrderIdResponse && dataByPartnerOrderIdResponse.message);
      }
    }
  }, [dataByPartnerOrderIdResponse]);


  React.useEffect(() => {
    if (
      bulkCheckinCheckoutData &&
      bulkCheckinCheckoutData.status === "SUCCESS"
    ) {
      const { content = [] } = bulkCheckinCheckoutData;
      // updatePhlebActivityInfoInLocal(content);
      // setError("");
      // ();
      // toast.success("Phleb Activity Information updated", {
      //   duration: 4000,
      //   id: "successinfo"
      // })
      // setTimeout(() => setLottie(false), 2000);
    } else if (
      bulkCheckinCheckoutData &&
      bulkCheckinCheckoutData.status === "ERROR"
    ) {
      if (
        bulkCheckinCheckoutData.message_code &&
        (bulkCheckinCheckoutData.message_code === "ACCESS_TOKEN_EXPIRED" ||
          bulkCheckinCheckoutData.message_code === "SESSION_EXPIRED_LOGIN_AGAIN" ||
          bulkCheckinCheckoutData.message_code === TIMEZONE_MISMATCH
        )
      ) {
      } else {
        setError(bulkCheckinCheckoutData && bulkCheckinCheckoutData.message);
      }
    }
  }, [bulkCheckinCheckoutData]);

  React.useEffect(() => {
    if (
      bulkCheckinCheckoutHomeHealthData &&
      bulkCheckinCheckoutHomeHealthData.status === "SUCCESS"
    ) {

    } else if (
      bulkCheckinCheckoutHomeHealthData &&
      bulkCheckinCheckoutHomeHealthData.status === "ERROR"
    ) {
      if (
        bulkCheckinCheckoutHomeHealthData.message_code &&
        (bulkCheckinCheckoutHomeHealthData.message_code === "ACCESS_TOKEN_EXPIRED" ||
          bulkCheckinCheckoutHomeHealthData.message_code === "SESSION_EXPIRED_LOGIN_AGAIN" ||
          bulkCheckinCheckoutHomeHealthData.message_code === TIMEZONE_MISMATCH
        )
      ) {
      } else {
        setError(bulkCheckinCheckoutHomeHealthData && bulkCheckinCheckoutHomeHealthData.message);
      }
    }
  }, [bulkCheckinCheckoutHomeHealthData]);

  React.useEffect(() => {
    if (
      acknowledgePartialOrderData &&
      acknowledgePartialOrderData.status === "SUCCESS"
    ) {
      const { content = {} } = acknowledgePartialOrderData;
      const { id, order_status_name } = content;
      if (tabValue === "stats") {
        const { data = {} } = statOrderData;
        setStatOrderData({
          ...statOrderData,
          data: { ...data, order_status: { id, order_status_name } },
        });
        // dispatch(getOrder({ id, type: "stats", updateRow: true }));
        getInitialStatOrders();
        statOrderData && statOrderData.data && dispatch(getOrder({ id: statOrderData.data.id, type: "stats" }));
      } else if (tabValue === "routine") {
        const { data = {} } = routineRedrawData;
        setRoutineRedrawData({
          ...routineRedrawData,
          data: { ...data, order_status: { id, order_status_name } },
        });
        getInitialRoutineOrders();
        routineRedrawData && routineRedrawData.data && dispatch(getOrder({ id: routineRedrawData.data.id, type: TYPE_ROUTINE }));
      } else if (tabValue === TYPE_TIMEDDRAW) {
        const { data = {} } = timedDrawData;
        setTimedDrawData({
          ...timedDrawData,
          data: { ...data, order_status: { id, order_status_name } },
        });
        getInitialTimedOrders();
        timedDrawData && timedDrawData.data && dispatch(getOrder({ id: timedDrawData.data.id, type: TYPE_TIMEDDRAW }));
      } else if (tabValue === TYPE_HOMEHEALTH) {
        const { data = {} } = homeHealthData;
        setHomeHealthData({
          ...homeHealthData,
          data: { ...data, order_status: { id, order_status_name } },
        });
        getInitialHomeHealthOrders();
        homeHealthData && homeHealthData.data && dispatch(getOrder({ id: homeHealthData.data.id, type: TYPE_HOMEHEALTH }));
      }
      setError("");
      setToastVal(false);
      setToastMessage(`Acknowledged`);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 4000);
    } else if (
      acknowledgePartialOrderData &&
      acknowledgePartialOrderData.status === "ERROR"
    ) {
      if (
        acknowledgePartialOrderData.message_code &&
        acknowledgePartialOrderData.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        acknowledgePartialOrderData.message_code ===
        "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(
          acknowledgePartialOrderData && acknowledgePartialOrderData.message
        );
      }
    }
  }, [acknowledgePartialOrderData]);

  React.useEffect(() => {
    if (
      forceAcknowledgeOrderData &&
      forceAcknowledgeOrderData.status === "SUCCESS"
    ) {
      const { content = {} } = forceAcknowledgeOrderData;
      const { id, order_status_name } = content;
      if (tabValue === "stats") {
        const { data = {} } = statOrderData;
        setStatOrderData({
          ...statOrderData,
          data: { ...data, order_status: { id, order_status_name } },
        });
        // dispatch(getOrder({ id, type: "stats", updateRow: true }));
        getInitialStatOrders();
        statOrderData && statOrderData.data && dispatch(getOrder({ id: statOrderData.data.id, type: "stats" }));
      } else if (tabValue === "routine") {
        const { data = {} } = routineRedrawData;
        setRoutineRedrawData({
          ...routineRedrawData,
          data: { ...data, order_status: { id, order_status_name } },
        });
        getInitialRoutineOrders();
        routineRedrawData && routineRedrawData.data && dispatch(getOrder({ id: routineRedrawData.data.id, type: TYPE_ROUTINE }));
      } else if (tabValue === TYPE_TIMEDDRAW) {
        const { data = {} } = timedDrawData;
        setTimedDrawData({
          ...timedDrawData,
          data: { ...data, order_status: { id, order_status_name } },
        });
        getInitialTimedOrders();
        timedDrawData && timedDrawData.data && dispatch(getOrder({ id: timedDrawData.data.id, type: TYPE_TIMEDDRAW }));
      }
      setToastVal(false);
      setToastMessage(`Acknowledged`);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 4000);
    } else if (
      forceAcknowledgeOrderData &&
      forceAcknowledgeOrderData.status === "ERROR"
    ) {
      if (
        forceAcknowledgeOrderData.message_code &&
        forceAcknowledgeOrderData.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        forceAcknowledgeOrderData.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(
          forceAcknowledgeOrderData && forceAcknowledgeOrderData.message
        );
      }
    }
  }, [forceAcknowledgeOrderData]);

  React.useEffect(() => {
    if (
      unclaimOrderData &&
      unclaimOrderData.status === "SUCCESS"
    ) {
      const { content = {} } = unclaimOrderData;
      const { id, order_status_name } = content;
      const { data = {} } = homeHealthData;
      setHomeHealthData({
        ...homeHealthData,
        data: { ...data, order_status: { id, order_status_name } },
      });
      getInitialHomeHealthOrders();
      homeHealthData && homeHealthData.data && dispatch(getOrder({ id: homeHealthData.data.id, type: TYPE_HOMEHEALTH }));
      setError("");
      setToastVal(false);
      setToastMessage(`Unclaim successful`);
      setTimeout(() => {
        setToastVal(true);
        setToastMessage(``);
      }, 4000);
    } else if (
      unclaimOrderData &&
      unclaimOrderData.status === "ERROR"
    ) {
      if (
        unclaimOrderData.message_code &&
        unclaimOrderData.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        unclaimOrderData.message_code ===
        "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setError(
          unclaimOrderData && unclaimOrderData.message
        );
      }
    }
  }, [unclaimOrderData]);

  React.useEffect(() => {
    if (homeHealthUserScheduleListResponse && homeHealthUserScheduleListResponse.status === "SUCCESS") {
    } else if (homeHealthUserScheduleListResponse && homeHealthUserScheduleListResponse.status === "ERROR") {
      if (
        homeHealthUserScheduleListResponse.message_code &&
        homeHealthUserScheduleListResponse.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        homeHealthUserScheduleListResponse.message_code === "SESSION_EXPIRED_LOGIN_AGAIN" || homeHealthUserScheduleListResponse.message_code === "HOME_HEALTH_USER_BUSY_WARN"
      ) {
      } else {
        setError(homeHealthUserScheduleListResponse && homeHealthUserScheduleListResponse.message);
      }
    }
  }, [homeHealthUserScheduleListResponse]);

  React.useEffect(() => {
    if (isClicked) {
      setOrderColor("");
      setOrderStartDate(today);
      setOrderStatus([]);
      setOrderEndDate(new Date());
      setOrderDateTypeStat(false);
      setOrderRegion("");
      setOrderTerr("");
      setOrderZone("");
      setIsClicked(false);
      setOpenOrders(false);
      setCancelledOrders(false);
      setDroppedOffOrders(false);
      setAllOrders(false);

      setStatTempStatus([]);
      setStatTempColor([]);
      setStatTempRegion([]);
      setStatTempTerritory([]);
      setStatTempZone([]);

      setHighlightStatusApply(false);
      setHighlightColorApply(false);
      setHighlightRegionApply(false);
      setHighlightTerritoryApply(false);
      setHighlightZoneApply(false);
      localStorage.removeItem("statOrderFilter");
    }
  }, [isClicked]);

  React.useEffect(() => {
    if (isClickedRoutine) {
      setRoutineRedrawColor("");
      setRoutineRedrawStartDate(today);
      setRoutineRedrawStatus([]);
      setRoutineRedrawEndDate(new Date());
      setOrderDateTypeRoutine(false);
      setRoutineRedrawRegion("");
      setRoutineRedrawTerr("");
      setRoutineRedrawZone("");
      setIsClickedRoutine(false);
      setOpenRoutineRedraws(false);
      setCancelledRoutineRedraws(false);
      setDroppedOffRoutineRedraws(false);
      setAllOrdersRoutineRedraws(false);

      setStatTempRoutineStatus([]);
      setStatTempRoutineColor([]);
      setStatTempRoutineRegion([]);
      setStatTempRoutineTerritory([]);
      setStatTempRoutineZone([]);

      setHighlightStatusApplyRoutine(false);
      setHighlightColorApplyRoutine(false);
      setHighlightRegionApplyRoutine(false);
      setHighlightTerritoryApplyRoutine(false);
      setHighlightZoneApplyRoutine(false);

      localStorage.removeItem("routineRedrawFilter");
    }
  }, [isClickedRoutine]);

  React.useEffect(() => {
    if (isClickedTimed) {
      setTimedDrawColor("");
      setTimedDrawStartDate(today);
      setTimedDrawStatus([]);
      setTimedDrawEndDate(new Date());
      setOrderDateTypeTimedDraw(false);
      setTimedDrawRegion("");
      setTimedDrawTerr("");
      setTimedDrawZone("");
      setIsClickedTimed(false);
      setOpenTimedDraws(false);
      setCancelledTimedDraws(false);
      setDroppedOffTimedDraws(false);
      setAllOrdersTimedDraws(false);

      setStatTempTimedStatus([]);
      setStatTempTimedColor([]);
      setStatTempTimedRegion([]);
      setStatTempTimedTerritory([]);
      setStatTempTimedZone([]);

      setHighlightStatusApplyTimed(false);
      setHighlightColorApplyTimed(false);
      setHighlightRegionApplyTimed(false);
      setHighlightTerritoryApplyTimed(false);
      setHighlightZoneApplyTimed(false);
      localStorage.removeItem("timedDrawFilter");
    }
  }, [isClickedTimed]);

  React.useEffect(() => {
    if (isClickedHome) {
      console.log("heree");
      setHomeHealthStartDate(today);
      setHomeHealthEndDate(new Date());
      setHomeHealthStatus([]);
      setHomeHealthPartner("");
      setOrderDateType(true);
      setHomeHealthRegion("");
      setHomeHealthState("");
      setIsClickedHome(false);
      setOpenHomeHealth(false);
      setCancelledHomeHealth(false);
      setDroppedOffHomeHealth(false);
      setUnscheduledHomeHealth(false);
      setAllOrdersHomeHealth(false);

      setHomeHealthTempStatus([]);
      setHomeHealthTempRegion([]);
      setHomeHealthTempState([]);
      setHomeHealthTempPartner([]);

      setHighlightStatusApplyHome(false);
      setHighlightRegionApplyHome(false);
      setHighlightStateApplyHome(false);
      setHighlightPartnerApplyHome(false);

      localStorage.removeItem("homeHealthFilter");
    }
  }, [isClickedHome]);

  React.useEffect(
    () => () => {
      //clear redux state
      dispatch(setStatOrdersList({}));
      dispatch(setRoutineRedrawsList({}));
      dispatch(setTimedDrawsList({}));
      dispatch(setHomeHealthList({}));
      dispatch(clearAddStatOrderResponse());
      dispatch(clearAddRoutineRedrawResponse());
      dispatch(clearAddTimedDrawResponse());
      dispatch(clearAddHomeHealthResponse());
      dispatch(clearCancelHomeHealthResponse());
      dispatch(clearUpdateHomeHealthResponse());
      dispatch(clearBulkCheckinCheckoutResponse());
      dispatch(clearBulkCheckinCheckoutHomeHealthResponse());
      dispatch(clearUpdateStatOrdersResponse());
      dispatch(clearUpdateRoutineRedrawsResponse());
      dispatch(clearUpdateTimedDrawsResponse());
      dispatch(clearAcknowledgePartialResponse());
      dispatch(clearForceAcknowledgeResponse());
      dispatch(clearUnclaimOrderResponse());
      dispatch(setOrderList({}));
      dispatch(clearDataByPartnerOrderd({}));
      dispatch(clearDeleteStatAction());
      dispatch(clearCancelStatOrderResponse());
      dispatch(clearUncancelHomeHealthResponse());
      dispatch(clearCloneHomeHealthResponse({}));
      dispatch(setExportData({}));
      dispatch(updateGetCareDxDropSite([]));
      document.documentElement.classList.remove(statOrderClass);
    },
    []
  );

  const handleGetActiveZones = (startDate, endDate) => {
    dispatch(getActiveZonesWithDate(`start_create_datetime=${moment(new Date(startDate)).format("YYYY-MM-DD")}&end_create_dateTime=${moment(new Date(endDate)).format("YYYY-MM-DD")}`));
  }

  const getInitialStatOrders = () => {
    stateDispatch(getStatData, { skip: 0, take: DEFAULT_ORDER_SIZE });
  }

  const getInitialRoutineOrders = () => {
    stateDispatch(getRoutineRedraws, { skip: 0, take: DEFAULT_ORDER_SIZE });
  }

  const getInitialTimedOrders = () => {
    stateDispatch(getTimedDraws, { skip: 0, take: DEFAULT_ORDER_SIZE });
  }

  const getInitialHomeHealthOrders = () => {
    stateDispatch(getHomeHealth, { skip: 0, take: DEFAULT_ORDER_SIZE });
  }

  const getFilteredStatOrders = (openOrders, orderDateTypeStat, orderStartDate, orderEndDate, cancelledOrders, droppedOffOrders, 
    allOrders, orderStatus, orderColor, orderRegion, orderTerr, orderZone, paginationData) => {
    let params = `all_order=${false}&include_completed=${false}&include_cancel=${false}`;
    if (openOrders) {
      params = `${params.length > 0 ? `${params}&` : ""
        }start_create_datetime=${moment(new Date(startDateForOpenOrders)).format(
          "YYYY-MM-DD"
        )}`;
      params = `${params.length > 0 ? `${params}&` : ""
        }end_create_dateTime=${moment(new Date()).format(
          "YYYY-MM-DD"
        )}`;
      // if (STAT_ALL_OPEN_STATUSES && STAT_ALL_OPEN_STATUSES.length > 0) {
      //   params = `${params.length > 0 ? `${params}&` : ""}status=${STAT_ALL_OPEN_STATUSES
      //     .map((val) => val.label)
      //     .join(",")}`;
      // }
    } else {
      if (orderDateTypeStat) {
        if (orderStartDate && orderEndDate) {
          params = `${params.length > 0 ? `${params}&` : ""
            }start_create_datetime=${moment(new Date(orderStartDate)).format(
              "YYYY-MM-DD"
            )}`;
          params = `${params.length > 0 ? `${params}&` : ""
            }end_create_dateTime=${moment(new Date(orderEndDate)).format(
              "YYYY-MM-DD"
            )}`;
          params = `${params.length > 0 ? `${params}&` : ""
            }filter_checked_in=${true}`;
        }
      } else {
        if (orderStartDate && orderEndDate) {
          params = `${params.length > 0 ? `${params}&` : ""
            }start_create_datetime=${moment(new Date(orderStartDate)).format(
              "YYYY-MM-DD"
            )}`;
          params = `${params.length > 0 ? `${params}&` : ""
            }end_create_dateTime=${moment(new Date(orderEndDate)).format(
              "YYYY-MM-DD"
            )}`;
        }
      }
    }
    if ((!openOrders && !cancelledOrders && !droppedOffOrders && !allOrders && (!orderStatus || !orderStatus.length))) {
      params = `${params.length > 0 ? `${params}&` : ""}status=${STAT_ALL_OPEN_STATUSES
        .map((val) => val.label)
        .join(",")}`;
    } else {
      if (orderStatus && orderStatus.length > 0) {
        params = `${params.length > 0 ? `${params}&` : ""}status=${orderStatus
          .map((val) => val.label)
          .join(",")}`;
      }
    }
    // if (orderColor && orderColor.length > 0) {
    //   params = `${params.length > 0 ? `${params}&` : ""}color=${orderColor
    //     .map((val) => val.label)
    //     .join(",")}`;
    // }
    if (orderRegion && orderRegion.length > 0) {
      params = `${params.length > 0 ? `${params}&` : ""}region_id=${orderRegion
        .map((val) => val.value)
        .join(",")}`;
    }
    if (orderTerr && orderTerr.length > 0) {
      let finalTerr = orderTerr
        .map((val) => {
          let tempTerr = val.value || "";
          tempTerr = tempTerr.split(":");
          tempTerr = tempTerr[0] ? tempTerr[0] : "";
          return tempTerr;
        })
        .join(",");

      params = `${params.length > 0 ? `${params}&` : ""
        }territory_id=${finalTerr}`;
    }
    if (orderZone && orderZone.length > 0) {
      let finalZone = orderZone
        .map((val) => {
          let tempZone = val.value || "";
          tempZone = tempZone.split(":");
          tempZone = tempZone[0] ? tempZone[0] : "";
          return tempZone;
        })
        .join(",");
      params = `${params.length > 0 ? `${params}&` : ""}zone_id=${finalZone}`;
    }
    if (paginationData) {
      const pageQuery = `skip=${paginationData.skip}&limit=${paginationData.take}`;
      let sortQuery = '';
      sortQuery = `&order_by=OrderCreated&sort_desc=true`;
      // if ((paginationData.sorted || []).length) {
      //   sortQuery =
      //     `&order_by=` +
      //     paginationData.sorted
      //       .map((obj) => {
      //         return obj.name;
      //       })
      //       .reverse()
      //       .join(',');

      //   if (paginationData.sorted[0].direction.toLowerCase() === 'descending') {
      //     sortQuery = `${sortQuery.length > 0 ? `${sortQuery}&sort_desc=true` : ""}`;
      //   }
      // }
      params = `${params.length > 0 ? `${params}&` : ""}${pageQuery}${sortQuery}`;
    }
    return params;
  };

  const getFilteredStatOrdersWithOpenOrders = (openOrders) => {
    if (openOrders) {
      updateFilterInLocal({
        orderStatus: STAT_ALL_OPEN_STATUSES,
        orderStartDate: new Date(startDateForOpenOrders),
        orderEndDate: new Date(),
        orderDateType: false,
        openOrders
      });
      if (orderDateRangeRef && orderDateRangeRef.current) {
        orderDateRangeRef.current.setStartDate(
          new Date(startDateForOpenOrders)
        );
        orderDateRangeRef.current.setEndDate(new Date());
      }
      setStatTempStatus(STAT_ALL_OPEN_STATUSES);
      setOrderStatus(STAT_ALL_OPEN_STATUSES);
      setOrderStartDate(new Date(startDateForOpenOrders));
      setOrderEndDate(new Date());
      setOrderDateTypeStat(false);
    } else {
      updateFilterInLocal({
        orderStatus: [],
        orderStartDate: today,
        orderEndDate: new Date(),
        openOrders
      });
      if (orderDateRangeRef && orderDateRangeRef.current) {
        orderDateRangeRef.current.setStartDate(today);
        orderDateRangeRef.current.setEndDate(new Date());
      }
      setStatTempStatus([]);
      setOrderStatus([]);
      setOrderStartDate(today);
      setOrderEndDate(new Date());
    }
    setOpenOrders(openOrders);
  };

  const getFilteredRoutineRedraws = (openRoutineRedraws, orderDateTypeRoutine, routineRedrawStartDate, routineRedrawEndDate, cancelledRoutineRedraws, 
    droppedOffRoutineRedraws, allOrdersRoutineRedraws, routineRedrawStatus, routineRedrawColor, routineRedrawRegion, routineRedrawTerr, routineRedrawZone, paginationData) => {
    let params = `all_order=${false}&include_completed=${false}&include_cancel=${false}`;
    if (openRoutineRedraws) {
      params = `${params.length > 0 ? `${params}&` : ""
        }start_create_datetime=${moment(new Date(startDateForOpenOrders)).format(
          "YYYY-MM-DD"
        )}`;
      params = `${params.length > 0 ? `${params}&` : ""
        }end_create_dateTime=${moment(new Date()).format(
          "YYYY-MM-DD"
        )}`;
      // if (STAT_ALL_OPEN_STATUSES && STAT_ALL_OPEN_STATUSES.length > 0) {
      //   params = `${params.length > 0 ? `${params}&` : ""
      //     }status=${STAT_ALL_OPEN_STATUSES.map((val) => val.label).join(",")}`;
      // }
    } else {
      if (orderDateTypeRoutine) {
        if (routineRedrawStartDate && routineRedrawEndDate) {
          params = `${params.length > 0 ? `${params}&` : ""
            }start_create_datetime=${moment(new Date(routineRedrawStartDate)).format(
              "YYYY-MM-DD"
            )}`;
          params = `${params.length > 0 ? `${params}&` : ""
            }end_create_dateTime=${moment(new Date(routineRedrawEndDate)).format(
              "YYYY-MM-DD"
            )}`;
          params = `${params.length > 0 ? `${params}&` : ""
            }filter_checked_in=${true}`;
        }
      } else {
        if (routineRedrawStartDate && routineRedrawEndDate) {
          params = `${params.length > 0 ? `${params}&` : ""
            }start_create_datetime=${moment(new Date(routineRedrawStartDate)).format(
              "YYYY-MM-DD"
            )}`;
          params = `${params.length > 0 ? `${params}&` : ""
            }end_create_dateTime=${moment(new Date(routineRedrawEndDate)).format(
              "YYYY-MM-DD"
            )}`;
        }
      }
    }
    if ((!openRoutineRedraws && !cancelledRoutineRedraws && !droppedOffRoutineRedraws && !allOrdersRoutineRedraws && (!routineRedrawStatus || !routineRedrawStatus.length))) {
      params = `${params.length > 0 ? `${params}&` : ""}status=${ROUTINE_REDRAW_ALL_OPEN_STATUSES
        .map((val) => val.label)
        .join(",")}`;
    } else {
      if (routineRedrawStatus && routineRedrawStatus.length > 0) {
        params = `${params.length > 0 ? `${params}&` : ""
          }status=${routineRedrawStatus.map((val) => val.label).join(",")}`;
      }
    }
    // if (routineRedrawColor && routineRedrawColor.length > 0) {
    //   params = `${params.length > 0 ? `${params}&` : ""}color=${routineRedrawColor
    //     .map((val) => val.label)
    //     .join(",")}`;
    // }
    if (routineRedrawRegion && routineRedrawRegion.length > 0) {
      params = `${params.length > 0 ? `${params}&` : ""
        }region_id=${routineRedrawRegion.map((val) => val.value).join(",")}`;
    }
    if (routineRedrawTerr && routineRedrawTerr.length > 0) {
      let finalTerr = routineRedrawTerr
        .map((val) => {
          let tempTerr = val.value || "";
          tempTerr = tempTerr.split(":");
          tempTerr = tempTerr[0] ? tempTerr[0] : "";
          return tempTerr;
        })
        .join(",");

      params = `${params.length > 0 ? `${params}&` : ""
        }territory_id=${finalTerr}`;
    }
    if (routineRedrawZone && routineRedrawZone.length > 0) {
      let finalZone = routineRedrawZone
        .map((val) => {
          let tempZone = val.value || "";
          tempZone = tempZone.split(":");
          tempZone = tempZone[0] ? tempZone[0] : "";
          return tempZone;
        })
        .join(",");
      params = `${params.length > 0 ? `${params}&` : ""}zone_id=${finalZone}`;
    }
    params = `${params.length > 0 ? `${params}&` : ""}order_type=RoutineRedraw`;
    if (paginationData) {
      const pageQuery = `skip=${paginationData.skip}&limit=${paginationData.take}`;
      let sortQuery = '';
      sortQuery = `&order_by=OrderCreated&sort_desc=true`;
      // if ((paginationData.sorted || []).length) {
      //   sortQuery =
      //     `&order_by=` +
      //     paginationData.sorted
      //       .map((obj) => {
      //         return obj.name;
      //       })
      //       .reverse()
      //       .join(',');

      //   if (paginationData.sorted[0].direction.toLowerCase() === 'descending') {
      //     sortQuery = `${sortQuery.length > 0 ? `${sortQuery}&sort_desc=true` : ""}`;
      //   }
      // }
      params = `${params.length > 0 ? `${params}&` : ""}${pageQuery}${sortQuery}`;
    }
    return params;
  };

  const getFilteredRoutineRedrawsWithOpenOrders = (openRoutineRedraws) => {
    if (openRoutineRedraws) {
      updateFilterRoutineRedrawInLocal({
        orderStatus: ROUTINE_REDRAW_ALL_OPEN_STATUSES,
        orderStartDate: new Date(startDateForOpenOrders),
        orderEndDate: new Date(),
        orderDateType: false,
        openRoutineRedraws
      });
      if (routineDateRangeRef && routineDateRangeRef.current) {
        routineDateRangeRef.current.setStartDate(
          new Date(startDateForOpenOrders)
        );
        routineDateRangeRef.current.setEndDate(new Date());
      }
      setStatTempRoutineStatus(ROUTINE_REDRAW_ALL_OPEN_STATUSES);
      setRoutineRedrawStatus(ROUTINE_REDRAW_ALL_OPEN_STATUSES);
      setRoutineRedrawStartDate(new Date(startDateForOpenOrders));
      setRoutineRedrawEndDate(new Date());
      setOrderDateTypeRoutine(false);
    } else {
      updateFilterRoutineRedrawInLocal({
        orderStatus: [],
        orderStartDate: today,
        orderEndDate: new Date(),
        openRoutineRedraws
      });
      if (routineDateRangeRef && routineDateRangeRef.current) {
        routineDateRangeRef.current.setStartDate(today);
        routineDateRangeRef.current.setEndDate(new Date());
      }
      setStatTempRoutineStatus([]);
      setRoutineRedrawStatus([]);
      setRoutineRedrawStartDate(today);
      setRoutineRedrawEndDate(new Date());
    }
    setOpenRoutineRedraws(openRoutineRedraws);
  };

  const getFilteredTimedDraws = (openTimedDraws, orderDateTypeTimedDraw, timedDrawStartDate, timedDrawEndDate, cancelledTimedDraws,
    droppedOffTimedDraws, allOrdersTimedDraws, timedDrawStatus, timedDrawColor, timedDrawRegion, timedDrawTerr, timedDrawZone, paginationData) => {
    let params = `all_order=${false}&include_completed=${false}&include_cancel=${false}`;
    if (openTimedDraws) {
      params = `${params.length > 0 ? `${params}&` : ""
        }start_create_datetime=${moment(new Date(startDateForOpenOrders)).format(
          "YYYY-MM-DD"
        )}`;
      params = `${params.length > 0 ? `${params}&` : ""
        }end_create_dateTime=${moment(new Date()).format(
          "YYYY-MM-DD"
        )}`;
      // if (STAT_ALL_OPEN_STATUSES && STAT_ALL_OPEN_STATUSES.length > 0) {
      //   params = `${params.length > 0 ? `${params}&` : ""}status=${STAT_ALL_OPEN_STATUSES
      //     .map((val) => val.label)
      //     .join(",")}`;
      // }
    } else {
      if (orderDateTypeTimedDraw) {
        if (timedDrawStartDate && timedDrawEndDate) {
          params = `${params.length > 0 ? `${params}&` : ""
            }start_create_datetime=${moment(new Date(timedDrawStartDate)).format(
              "YYYY-MM-DD"
            )}`;
          params = `${params.length > 0 ? `${params}&` : ""
            }end_create_dateTime=${moment(new Date(timedDrawEndDate)).format(
              "YYYY-MM-DD"
            )}`;
          params = `${params.length > 0 ? `${params}&` : ""
            }filter_checked_in=${true}`;
        }
      } else {
        if (timedDrawStartDate && timedDrawEndDate) {
          params = `${params.length > 0 ? `${params}&` : ""
            }start_create_datetime=${moment(new Date(timedDrawStartDate)).format(
              "YYYY-MM-DD"
            )}`;
          params = `${params.length > 0 ? `${params}&` : ""
            }end_create_dateTime=${moment(new Date(timedDrawEndDate)).format(
              "YYYY-MM-DD"
            )}`;
        }
      }
    }
    if ((!openTimedDraws && !cancelledTimedDraws && !droppedOffTimedDraws && !allOrdersTimedDraws && (!timedDrawStatus || !timedDrawStatus.length))) {
      params = `${params.length > 0 ? `${params}&` : ""}status=${STAT_ALL_OPEN_STATUSES
        .map((val) => val.label)
        .join(",")}`;
    } else {
      if (timedDrawStatus && timedDrawStatus.length > 0) {
        params = `${params.length > 0 ? `${params}&` : ""}status=${timedDrawStatus
          .map((val) => val.label)
          .join(",")}`;
      }
    }
    // if (timedDrawColor && timedDrawColor.length > 0) {
    //   params = `${params.length > 0 ? `${params}&` : ""}color=${timedDrawColor
    //     .map((val) => val.label)
    //     .join(",")}`;
    // }
    if (timedDrawRegion && timedDrawRegion.length > 0) {
      params = `${params.length > 0 ? `${params}&` : ""
        }region_id=${timedDrawRegion.map((val) => val.value).join(",")}`;
    }
    if (timedDrawTerr && timedDrawTerr.length > 0) {
      let finalTerr = timedDrawTerr
        .map((val) => {
          let tempTerr = val.value || "";
          tempTerr = tempTerr.split(":");
          tempTerr = tempTerr[0] ? tempTerr[0] : "";
          return tempTerr;
        })
        .join(",");

      params = `${params.length > 0 ? `${params}&` : ""
        }territory_id=${finalTerr}`;
    }
    if (timedDrawZone && timedDrawZone.length > 0) {
      let finalZone = timedDrawZone
        .map((val) => {
          let tempZone = val.value || "";
          tempZone = tempZone.split(":");
          tempZone = tempZone[0] ? tempZone[0] : "";
          return tempZone;
        })
        .join(",");
      params = `${params.length > 0 ? `${params}&` : ""}zone_id=${finalZone}`;
    }
    params = `${params.length > 0 ? `${params}&` : ""}order_type=TIMED`;
    if (paginationData) {
      const pageQuery = `skip=${paginationData.skip}&limit=${paginationData.take}`;
      let sortQuery = '';
      sortQuery = `&order_by=OrderCreated&sort_desc=true`;
      // if ((paginationData.sorted || []).length) {
      //   sortQuery =
      //     `&order_by=` +
      //     paginationData.sorted
      //       .map((obj) => {
      //         return obj.name;
      //       })
      //       .reverse()
      //       .join(',');

      //   if (paginationData.sorted[0].direction.toLowerCase() === 'descending') {
      //     sortQuery = `${sortQuery.length > 0 ? `${sortQuery}&sort_desc=true` : ""}`;
      //   }
      // }
      params = `${params.length > 0 ? `${params}&` : ""}${pageQuery}${sortQuery}`;
    }
    return params;
  };

  const getFilteredTimedDrawsWithOpenOrders = (openTimedDraws) => {
    if (openTimedDraws) {
      updateFilterTimedDrawInLocal({
        orderStatus: STAT_ALL_OPEN_STATUSES,
        orderStartDate: new Date(startDateForOpenOrders),
        orderEndDate: new Date(),
        orderDateType: false,
        openTimedDraws
      });
      if (timedDateRangeRef && timedDateRangeRef.current) {
        timedDateRangeRef.current.setStartDate(
          new Date(startDateForOpenOrders)
        );
        timedDateRangeRef.current.setEndDate(new Date());
      }
      setStatTempTimedStatus(STAT_ALL_OPEN_STATUSES);
      setTimedDrawStatus(STAT_ALL_OPEN_STATUSES);
      setTimedDrawStartDate(new Date(startDateForOpenOrders));
      setTimedDrawEndDate(new Date());
      setOrderDateTypeTimedDraw(false);
    } else {
      updateFilterTimedDrawInLocal({
        orderStatus: [],
        orderStartDate: today,
        orderEndDate: new Date(),
        openTimedDraws
      });
      if (timedDateRangeRef && timedDateRangeRef.current) {
        timedDateRangeRef.current.setStartDate(today);
        timedDateRangeRef.current.setEndDate(new Date());
      }
      setStatTempTimedStatus([]);
      setTimedDrawStatus([]);
      setTimedDrawStartDate(today);
      setTimedDrawEndDate(new Date());
    }
    setOpenTimedDraws(openTimedDraws);
  };

  const getFilteredHomeHealth = (openHomeHealth, unscheduledHomeHealth, orderDateType, homeHealthStartDate, homeHealthEndDate, cancelledHomeHealth, 
    droppedOffHomeHealth, allOrdersHomeHealth, homeHealthStatus, homeHealthRegion, homeHealthState, homeHealthPartner, paginationData) => {
    let params = `all_order=${false}&include_completed=${false}&include_cancel=${false}`;
    if (openHomeHealth) {
      params = `${params.length > 0 ? `${params}&` : ""
        }start_create_datetime=${moment(new Date(startDateForOpenOrders)).format(
          "YYYY-MM-DD"
        )}`;
      params = `${params.length > 0 ? `${params}&` : ""
        }end_create_dateTime=${moment(new Date()).format(
          "YYYY-MM-DD"
        )}`;
      // if (HOME_HEALTH_OPEN_ORDER_STATUSES && HOME_HEALTH_OPEN_ORDER_STATUSES.length > 0) {
      //   params = `${params.length > 0 ? `${params}&` : ""}status=${HOME_HEALTH_OPEN_ORDER_STATUSES
      //     .map((val) => val.label)
      //     .join(",")}`;
      // }
    } else {
      if (unscheduledHomeHealth) {
        params = `${params.length > 0 ? `${params}&` : ""
          }unscheduled_order=${unscheduledHomeHealth}`;

        params = `${params.length > 0 ? `${params}&` : ""
          }start_create_datetime=${moment(new Date(startDateForOpenOrders)).format(
            "YYYY-MM-DD"
          )}`;
        params = `${params.length > 0 ? `${params}&` : ""
          }end_create_dateTime=${moment(new Date()).format(
            "YYYY-MM-DD"
          )}`;
      } else {
        if (orderDateType) {
          if (homeHealthStartDate && homeHealthEndDate) {
            params = `${params.length > 0 ? `${params}&` : ""
              }schedule_start_date=${moment(new Date(homeHealthStartDate)).format(
                "YYYY-MM-DD"
              )}`;
            params = `${params.length > 0 ? `${params}&` : ""
              }schedule_end_date=${moment(new Date(homeHealthEndDate)).format(
                "YYYY-MM-DD"
              )}`;
          }
        } else {
          if (homeHealthStartDate && homeHealthEndDate) {
            params = `${params.length > 0 ? `${params}&` : ""
              }start_create_datetime=${moment(new Date(homeHealthStartDate)).format(
                "YYYY-MM-DD"
              )}`;
            params = `${params.length > 0 ? `${params}&` : ""
              }end_create_dateTime=${moment(new Date(homeHealthEndDate)).format(
                "YYYY-MM-DD"
              )}`;
          }
        }
      }
    }
    if ((!openHomeHealth && !cancelledHomeHealth && !droppedOffHomeHealth && !allOrdersHomeHealth && !unscheduledHomeHealth && (!homeHealthStatus || !homeHealthStatus.length))) {
      params = `${params.length > 0 ? `${params}&` : ""}status=${HOME_HEALTH_OPEN_ORDER_STATUSES
        .map((val) => val.label)
        .join(",")}`;
    } else {
      if (homeHealthStatus && homeHealthStatus.length > 0) {
        params = `${params.length > 0 ? `${params}&` : ""}status=${homeHealthStatus
          .map((val) => val.label)
          .join(",")}`;
      }
    }
    if (homeHealthRegion && homeHealthRegion.length > 0) {
      params = `${params.length > 0 ? `${params}&` : ""
        }region_id=${homeHealthRegion.map((val) => val.value).join(",")}`;
    }
    if (homeHealthState && homeHealthState.length > 0) {
      let finalTerr = homeHealthState
        .map((val) => {
          let tempTerr = val.value || "";
          tempTerr = tempTerr.split(":");
          tempTerr = tempTerr[0] ? tempTerr[0] : "";
          return tempTerr;
        })
        .join(",");

      params = `${params.length > 0 ? `${params}&` : ""
        }state=${finalTerr}`;
    }
    if (homeHealthPartner && homeHealthPartner.length > 0) {
      let finalPartner = homeHealthPartner
        .map((val) => {
          let tempPartner = val.value || "";
          return tempPartner;
        })
        .join(",");
      params = `${params.length > 0 ? `${params}&` : ""}partner_id=${finalPartner}`;
    }
    params = `${params.length > 0 ? `${params}&` : ""}order_type=HomeHealth`;
    if (paginationData) {
      const pageQuery = `skip=${paginationData.skip}&limit=${paginationData.take}`;
      let sortQuery = '';
      sortQuery = `&order_by=OrderCreated&sort_desc=true`;
      // if ((paginationData.sorted || []).length) {
      //   sortQuery =
      //     `&order_by=` +
      //     paginationData.sorted
      //       .map((obj) => {
      //         return obj.name;
      //       })
      //       .reverse()
      //       .join(',');

      //   if (paginationData.sorted[0].direction.toLowerCase() === 'descending') {
      //     sortQuery = `${sortQuery.length > 0 ? `${sortQuery}&sort_desc=true` : ""}`;
      //   }
      // }
      params = `${params.length > 0 ? `${params}&` : ""}${pageQuery}${sortQuery}`;
    }
    return params;
  };

  const getFilteredHomeHealthWithOpenOrders = (openHomeHealth) => {
    if (openHomeHealth) {
      updateFilterHomeHealthInLocal({
        orderStatus: HOME_HEALTH_OPEN_ORDER_STATUSES,
        orderStartDate: new Date(startDateForOpenOrders),
        orderEndDate: new Date(),
        orderDateType: true,
        openHomeHealth
      });
      if (homeDateRangeRef && homeDateRangeRef.current) {
        homeDateRangeRef.current.setStartDate(new Date(startDateForOpenOrders));
        homeDateRangeRef.current.setEndDate(new Date());
      }
      setHomeHealthTempStatus(HOME_HEALTH_OPEN_ORDER_STATUSES);
      setHomeHealthStatus(HOME_HEALTH_OPEN_ORDER_STATUSES);
      setHomeHealthStartDate(new Date(startDateForOpenOrders));
      setHomeHealthEndDate(new Date());
      setOrderDateType(true);
    } else {
      updateFilterHomeHealthInLocal({
        orderStatus: [],
        orderStartDate: today,
        orderEndDate: new Date(),
        openHomeHealth
      });
      if (homeDateRangeRef && homeDateRangeRef.current) {
        homeDateRangeRef.current.setStartDate(today);
        homeDateRangeRef.current.setEndDate(new Date());
      }
      setHomeHealthTempStatus([]);
      setHomeHealthStatus([]);
      setHomeHealthStartDate(today);
      setHomeHealthEndDate(new Date());
    }
    setOpenHomeHealth(openHomeHealth);
  };

  const getFilteredHomeHealthWithUnscheduledOrders = (unscheduledHomeHealth) => {
    if (unscheduledHomeHealth) {
      updateFilterHomeHealthInLocal({
        orderStatus: HOME_HEALTH_UNSCHEDULED_ORER_STATUSES,
        orderStartDate: new Date(startDateForOpenOrders),
        orderEndDate: new Date(),
        orderDateType: true,
        unscheduledHomeHealth
      });
      if (homeDateRangeRef && homeDateRangeRef.current) {
        homeDateRangeRef.current.setStartDate(new Date(startDateForOpenOrders));
        homeDateRangeRef.current.setEndDate(new Date());
      }
      setHomeHealthTempStatus(HOME_HEALTH_UNSCHEDULED_ORER_STATUSES);
      setHomeHealthStatus(HOME_HEALTH_UNSCHEDULED_ORER_STATUSES);
      setHomeHealthStartDate(new Date(startDateForOpenOrders));
      setHomeHealthEndDate(new Date());
      setOrderDateType(true);
    } else {
      updateFilterHomeHealthInLocal({
        orderStatus: [],
        orderStartDate: today,
        orderEndDate: new Date(),
        unscheduledHomeHealth
      });
      if (homeDateRangeRef && homeDateRangeRef.current) {
        homeDateRangeRef.current.setStartDate(today);
        homeDateRangeRef.current.setEndDate(new Date());
      }
      setHomeHealthTempStatus([]);
      setHomeHealthStatus([]);
      setHomeHealthStartDate(today);
      setHomeHealthEndDate(new Date());
    }
    setUnscheduledHomeHealth(unscheduledHomeHealth);
  };

  // const updatePhlebActivityInfoInLocal = (response) => {
  //   const { data = {}, mode } = statOrderData;
  //   let tempData = { ...data };
  //   let notes = tempData.notes || [];
  //   const { phleb_activity_informations = [], order_requisitions = [], order_status= {} } = tempData;
  //   let tempInfo = [...phleb_activity_informations];
  //   let tempPreviousPhlebInfo = [...phleb_activity_informations];

  //   const currentObj = response.length > 0 ? response[response.length - 1] : {};
  //   const currentOrderStatus = currentObj.order_status || {};
  //   const audit_history = currentObj.audit_history || [];

  //   console.log(currentOrderStatus, 'currentOrderStatus');

  //   if ((order_status.id == 2 || order_status.id == 3 || currentOrderStatus.id == 6) && tempPreviousPhlebInfo && tempPreviousPhlebInfo.length > 0) {
  //     //redispatch
  //     let req = [];

  //     if (response.some(res => res.action_type_id === 1)) {

  //       let currentAction = {};
  //       response.forEach(res => {
  //         if (res.action_type_id === 1) {
  //           currentAction = { ...res, order_requisitions: res.orderRequisitions || [] };
  //         }
  //       });

  //       if (!tempPreviousPhlebInfo.some(act=> act.id ==currentAction.id)) {
  //         tempInfo.push(currentAction);
  //       } else {
  //         tempInfo = tempInfo.map(info => {
  //           if(info.id === currentAction.id) {
  //             return {...info, action_datetime: currentAction.action_datetime}
  //           }
  //           return info;
  //         })
  //       }

  //     }
  //     if (response.some(res => res.action_type_id === 2)) {
  //       let currentAction = {};
  //       response.forEach(res => {
  //         if (res.action_type_id === 2) {
  //           currentAction = { ...res, order_requisitions: res.orderRequisitions || [] };
  //         }
  //       });

  //       if (!tempPreviousPhlebInfo.some(act=> act.id ==currentAction.id)) {
  //         tempInfo.push(currentAction);
  //       } else {
  //         tempInfo = tempInfo.map(info => {
  //           if(info.id === currentAction.id) {
  //             return {...info, action_datetime: currentAction.action_datetime}
  //           }
  //           return info;
  //         })
  //       }
  //     }
  //     if (response.some(res => res.action_type_id === 3)) {
  //       let currentAction = {};
  //       response.forEach(res => {
  //         if (res.action_type_id === 3) {
  //           currentAction = { ...res, order_requisitions: res.orderRequisitions || [] };
  //           req = res.orderRequisitions ? [...order_requisitions, ...res.orderRequisitions] : [...order_requisitions];
  //         }
  //       });

  //       if (!tempPreviousPhlebInfo.some(act=> act.id ==currentAction.id)) {
  //         tempInfo.push(currentAction);
  //       } else {
  //         tempInfo = tempInfo.map(info => {
  //           if(info.id === currentAction.id) {
  //             return {...info, action_datetime: currentAction.action_datetime}
  //           }
  //           return info;
  //         })
  //       }
  //     }
  //     setStatOrderData({ ...statOrderData,
  //       data: { ...data, phleb_activity_informations: tempInfo, order_requisitions: req,
  //         order_status: currentOrderStatus.id ? currentOrderStatus : data.order_status, audit_history },
  //         mode: currentOrderStatus.id ? currentOrderStatus.id == 9 || currentOrderStatus.id == 8 ? "view": mode : mode,
  //      });
  //   } else {
  //     let req = [];
  //     if (response.some(res => res.action_type_id === 1)) {

  //       let currentAction = {};
  //       response.forEach(res => {
  //         if (res.action_type_id === 1) {
  //           currentAction = { ...res, order_requisitions: res.orderRequisitions || [] };
  //         }
  //       });

  //       if (!tempInfo.some(act=> act.id ==currentAction.id)) {
  //         tempInfo.push(currentAction);
  //       } else {
  //         tempInfo = tempInfo.map(info => {
  //           if(info.id === currentAction.id) {
  //             return {...info, ...currentAction}
  //           }
  //           return info;
  //         })
  //       }

  //     }
  //     if (response.some(res => res.action_type_id === 2)) {
  //       let currentAction = {};
  //       response.forEach(res => {
  //         if (res.action_type_id === 2) {
  //           currentAction = { ...res, order_requisitions: res.orderRequisitions || [] };
  //         }
  //       });

  //       if (!tempInfo.some(act=> act.id ==currentAction.id)) {
  //         tempInfo.push(currentAction);
  //       } else {
  //         tempInfo = tempInfo.map(info => {
  //           if(info.id === currentAction.id) {
  //             return {...info, ...currentAction}
  //           }
  //           return info;
  //         })
  //       }

  //     }
  //     if (response.some(res => res.action_type_id === 3)) {
  //       let currentAction = {};
  //       response.forEach(res => {
  //         if (res.action_type_id === 3) {
  //           currentAction = { ...res, order_requisitions: res.orderRequisitions || [] };
  //           req = res.orderRequisitions ? [...order_requisitions, ...res.orderRequisitions] : [...order_requisitions];
  //         }
  //       });

  //       if (!tempInfo.some(act=> act.id ==currentAction.id)) {
  //         tempInfo.push(currentAction);
  //       } else {
  //         tempInfo = tempInfo.map(info => {
  //           if(info.id === currentAction.id) {
  //             return {...info, ...currentAction}
  //           }
  //           return info;
  //         })
  //       }
  //     }
  //     console.log(tempInfo, 'phleb_activity_informationsphleb_activity_informations');
  //     setStatOrderData({ ...statOrderData,
  //       data: { ...data, phleb_activity_informations: tempInfo, order_requisitions: req,
  //         order_status: currentOrderStatus.id ? currentOrderStatus : data.order_status, audit_history },
  //         mode: currentOrderStatus.id ? currentOrderStatus.id == 9 || currentOrderStatus.id == 8 ? "view": mode : mode,
  //      });
  //   }

  //   //  setPreviousPhlebInfo([]);
  // };
  const handleChangeTab = (value) => {
    console.log(value);
    // if (value === TYPE_HOMEHEALTH ? listHomeHealthOrder : listStatOrder) {
    if (value === "stats" && tabValue !== "stats") {
      navigate(STATORDER);
    } else if (value === "routine" && tabValue !== "routine") {
      navigate(ROUTINEREDRAW);
    } else if (value === TYPE_TIMEDDRAW && tabValue !== TYPE_TIMEDDRAW) {
      navigate(TIMEDDRAW);
    } else if (value === TYPE_HOMEHEALTH && tabValue !== TYPE_HOMEHEALTH) {
      navigate(HOMEHEALTH);
    }
    checkAppVersion();
    // }
  };

  const stateDispatch = (action, params) => {
    if (tabValue === "stats") {
      dispatch(action(getFilteredStatOrders(openOrders, orderDateTypeStat, orderStartDate, orderEndDate, cancelledOrders, droppedOffOrders, 
        allOrders, orderStatus, orderColor, orderRegion, orderTerr, orderZone, params)));
    } else if (tabValue === TYPE_TIMEDDRAW) {
      dispatch(action(getFilteredTimedDraws(openTimedDraws, orderDateTypeTimedDraw, timedDrawStartDate, timedDrawEndDate, cancelledTimedDraws,
        droppedOffTimedDraws, allOrdersTimedDraws, timedDrawStatus, timedDrawColor, timedDrawRegion, timedDrawTerr, timedDrawZone, params)));
    } else if (tabValue === TYPE_HOMEHEALTH) {
      dispatch(action(getFilteredHomeHealth(openHomeHealth, unscheduledHomeHealth, orderDateType, homeHealthStartDate, homeHealthEndDate, cancelledHomeHealth, 
        droppedOffHomeHealth, allOrdersHomeHealth, homeHealthStatus, homeHealthRegion, homeHealthState, homeHealthPartner, params)));
    } else {
      dispatch(action(getFilteredRoutineRedraws(openRoutineRedraws, orderDateTypeRoutine, routineRedrawStartDate, routineRedrawEndDate, cancelledRoutineRedraws, 
        droppedOffRoutineRedraws, allOrdersRoutineRedraws, routineRedrawStatus, routineRedrawColor, routineRedrawRegion, routineRedrawTerr, routineRedrawZone, params)));
    }
  };
  const toggleStatOrderModal = (value, data = {}, mode = "view") => {
    if (value && mode === "view" && data) {
      setStatOrderData({ show: value, mode, data: data.data || {} });
      data.data && dispatch(getOrder({ id: data.data.id, type: "stats" }));
    } else {
      setStatOrderData({ show: value, mode, data: data.data || {} });
      dispatch(setOrderList({}));
    }

  };

  const toggleRoutineRedrawModal = (value, data = {}, mode = "view") => {
    if (value && mode === "view" && data) {
      setRoutineRedrawData({ show: value, mode, data: data.data || {} });
      data.data && dispatch(getOrder({ id: data.data.id, type: TYPE_ROUTINE }));
    } else {
      setRoutineRedrawData({ show: value, mode, data: data.data || {} });
      dispatch(setOrderList({}));
    }
  };

  const toggleTimedDrawModal = (value, data = {}, mode = "view") => {
    if (value && mode === "view" && data) {
      setTimedDrawData({ show: value, mode, data: data.data || {} });
      data.data && dispatch(getOrder({ id: data.data.id, type: TYPE_TIMEDDRAW }));
    } else {
      setTimedDrawData({ show: value, mode, data: data.data || {} });
      dispatch(setOrderList({}));
    }
  };

  const toggleHomeHealthModal = (value, data = {}, mode = "view") => {
    if (value && mode === "view" && data) {
      setHomeHealthData({ show: value, mode, data: data.data || {} });
      data.data && dispatch(getOrder({ id: data.data.id, type: TYPE_HOMEHEALTH }));
    } else {
      setHomeHealthData({ show: value, mode, data: data.data || {} });
      dispatch(setOrderList({}));
    }
  };

  const handleCreateOrder = (data) => {
    dispatch(addStatOrders(data));
  };

  const handleCreateRoutineRedraw = (data) => {
    dispatch(addRoutineRedraws(data));
  };

  const handleUpdateRoutineRedraw = (data) => {
    dispatch(updateRoutineRedraws(data));
  };

  const handleCreateTimedDraw = (data) => {
    dispatch(addTimedDraws(data));
  };

  const cancelStatOrderHandler = (data) => {
    dispatch(handleCancelStatOrder(data));
  };

  const handleCancelHomeHealth = (data) => {
    dispatch(cancelHomeHealth(data));
  };

  const handleUnCancelHomeHealth = (data) => {
    dispatch(uncancelHomeHealth(data));
  }

  const handleCreateHomeHealth = (data) => {
    dispatch(addHomeHealth(data));
  };

  const handleUpdateHomeHealth = (data) => {
    dispatch(updateHomeHealths(data));
  };

  const handleCloneHomeHealth = (data) => {
    dispatch(cloneHomeHealth(data));
  };

  const handleUpdateTimedDraw = (data) => {
    dispatch(updateTimedDraws(data));
  };

  const handleUpdateOrder = (data) => {
    dispatch(updateStatOrders(data));
  };

  const handleClearDateTime = (data) => {
    dispatch(deleteStatActionApiResponse(data));
  };

  const handleCheckUserSchedule = (data) => {
    dispatch(checkHomeHealthUserSchedule(data));
  };

  const handleFetchDataByPartnerOrderId = (data) => {
    dispatch(fetchDataByPartnerOrderId(data));
  };

  const handleClearDataByPartnerOrderId = (data) => {
    dispatch(clearDataByPartnerOrderd({}));
  };

  const handleStatOrderError = () => {
    setError("");
    setStatOrderData({ ...statOrderData, mode: "view" });
  };

  const handleRoutineRedrawError = () => {
    setError("");
    setRoutineRedrawData({ ...routineRedrawData, mode: "view" });
  };

  const handleTimedDrawError = () => {
    setError("");
    setTimedDrawData({ ...timedDrawData, mode: "view" });
  };

  const phlebCheckinCheckout = (data) => {
    setPreviousPhlebInfo(data);
    dispatch(doBulkCheckinCheckout(data));
  };

  const phlebCheckinCheckoutHomeHealth = (data) => {
    // setIgnoreUserAvailability(ignoreUserAvailability);
    dispatch(doBulkCheckinCheckoutHomeHealth(data));
  };

  const submitAcknowledgePartialOrder = (id) => {
    dispatch(acknowledgePartialOrder(id));
  };

  const unclaimOrderHandler = (id) => {
    dispatch(unclaimOrder(id));
  };

  const submitForceAcknowledgeOrder = (id) => {
    dispatch(forceAcknowledgeOrder(id));
  };

  const loadOptions3 = async (zoneId, collectionTime, collectionTimezone) => {
    const response = await ahaApi.Account.assignPhleb(
      collectionTime
        ? `zone_id=${zoneId}&create=true&collection_time=${collectionTime}&collection_timezone_id=${collectionTimezone}`
        : `zone_id=${zoneId}&create=true`
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return response;
  };

  const fetchUsersForHomeHealth = async (regionId) => {
    const response = await ahaApi.Account.fetchPhlebs(
      `region_id=${regionId}`
    )
      .then((res) => {
        const { content = {} } = res;
        const { users = [] } = content;
        let coordinators = users.filter(user => user.roles.includes("COORDINATOR"));
        let phlebs = users.filter(user => user.roles.includes("PHLEB"));
        return { coordinators, phlebs };
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return response;
  };

  const loadTestCodes = async (data) => {
    const response = await ahaApi.Account.fetchTestCodes(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return response;
  };

  const updateFilterInLocal = (data) => {
    let statOrderFilter = window.localStorage.getItem("statOrderFilter")
      ? JSON.parse(window.localStorage.getItem("statOrderFilter")) || {}
      : {};
    localStorage.setItem(
      "statOrderFilter",
      JSON.stringify({ ...statOrderFilter, ...data })
    );
  };

  const updateFilterRoutineRedrawInLocal = (data) => {
    let routineRedrawFilter = window.localStorage.getItem("routineRedrawFilter")
      ? JSON.parse(window.localStorage.getItem("routineRedrawFilter")) || {}
      : {};
    localStorage.setItem(
      "routineRedrawFilter",
      JSON.stringify({ ...routineRedrawFilter, ...data })
    );
  };

  const updateFilterTimedDrawInLocal = (data) => {
    let timedDrawFilter = window.localStorage.getItem("timedDrawFilter")
      ? JSON.parse(window.localStorage.getItem("timedDrawFilter")) || {}
      : {};
    localStorage.setItem(
      "timedDrawFilter",
      JSON.stringify({ ...timedDrawFilter, ...data })
    );
  };

  const updateFilterHomeHealthInLocal = (data) => {
    let homeHealthFilter = window.localStorage.getItem("homeHealthFilter")
      ? JSON.parse(window.localStorage.getItem("homeHealthFilter")) || {}
      : {};
    localStorage.setItem(
      "homeHealthFilter",
      JSON.stringify({ ...homeHealthFilter, ...data })
    );
  };

  const toggleCompletedOrders = (e, setResetStatusFilters) => {
    const value = e.target.checked;
    // setResetStatusFilters && setResetStatusFilters(true);
    if (tabValue === "stats") {
      let finalStatus = cancelledOrders ? [...STAT_CANCELLED_STATUSES] : [];
      finalStatus = value ? [...finalStatus, ...STAT_COMPLETED_STATUSES] : finalStatus;
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterInLocal({
        droppedOffOrders: value,
        orderStatus: finalStatus
      });
      setStatTempStatus(finalStatus);
      setOrderStatus(finalStatus);
      setDroppedOffOrders(value)
    } else if (tabValue === TYPE_TIMEDDRAW) {
      let finalStatus = cancelledTimedDraws ? [...STAT_CANCELLED_STATUSES] : [];
      finalStatus = value ? [...finalStatus, ...STAT_COMPLETED_STATUSES] : finalStatus;
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterTimedDrawInLocal({
        droppedOffTimedDraws: value,
        orderStatus: finalStatus
      });
      setStatTempTimedStatus(finalStatus);
      setTimedDrawStatus(finalStatus);
      setDroppedOffTimedDraws(value)
    } else if (tabValue === TYPE_HOMEHEALTH) {
      let finalStatus = cancelledHomeHealth ? [...HOME_HEALTH_CANCELLED_STATUSES] : [];
      finalStatus = value ? [...finalStatus, ...HOME_HEALTH_COMPLETED_STATUSES] : finalStatus;
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterHomeHealthInLocal({
        droppedOffHomeHealth: value,
        orderStatus: finalStatus
      });
      setHomeHealthTempStatus(finalStatus);
      setHomeHealthStatus(finalStatus);
      setDroppedOffHomeHealth(value)
    } else {
      let finalStatus = cancelledRoutineRedraws ? [...STAT_CANCELLED_STATUSES] : [];
      finalStatus = value ? [...finalStatus, ...ROUTINE_REDRAW_COMPLETED_STATUSES] : finalStatus;
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterRoutineRedrawInLocal({
        droppedOffRoutineRedraws: value,
        orderStatus: finalStatus
      });
      setStatTempRoutineStatus(finalStatus);
      setRoutineRedrawStatus(finalStatus);
      setDroppedOffRoutineRedraws(value)
    }
  }

  const toggleCancelledOrders = (e, setResetStatusFilters) => {
    const value = e.target.checked;
    // setResetStatusFilters && setResetStatusFilters(true);
    if (tabValue === "stats") {
      let finalStatus = droppedOffOrders ? [...STAT_COMPLETED_STATUSES] : [];
      finalStatus = value ? [...finalStatus, ...STAT_CANCELLED_STATUSES] : finalStatus;
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterInLocal({
        cancelledOrders: value,
        orderStatus: finalStatus
      });
      setStatTempStatus(finalStatus);
      setOrderStatus(finalStatus);
      setCancelledOrders(value)
    } else if (tabValue === TYPE_TIMEDDRAW) {
      let finalStatus = droppedOffTimedDraws ? [...STAT_COMPLETED_STATUSES] : [];
      finalStatus = value ? [...finalStatus, ...STAT_CANCELLED_STATUSES] : finalStatus;
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterTimedDrawInLocal({
        cancelledTimedDraws: value,
        orderStatus: finalStatus
      });
      setStatTempTimedStatus(finalStatus);
      setTimedDrawStatus(finalStatus);
      setCancelledTimedDraws(value)
    } else if (tabValue === TYPE_HOMEHEALTH) {
      let finalStatus = droppedOffHomeHealth ? [...HOME_HEALTH_COMPLETED_STATUSES] : [];
      finalStatus = value ? [...finalStatus, ...HOME_HEALTH_CANCELLED_STATUSES] : finalStatus;
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterHomeHealthInLocal({
        cancelledHomeHealth: value,
        orderStatus: finalStatus
      });
      setHomeHealthTempStatus(finalStatus);
      setHomeHealthStatus(finalStatus);
      setCancelledHomeHealth(value)
    } else {
      let finalStatus = droppedOffRoutineRedraws ? [...ROUTINE_REDRAW_COMPLETED_STATUSES] : [];
      finalStatus = value ? [...finalStatus, ...STAT_CANCELLED_STATUSES] : finalStatus;
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterRoutineRedrawInLocal({
        cancelledRoutineRedraws: value,
        orderStatus: finalStatus
      });
      setStatTempRoutineStatus(finalStatus);
      setRoutineRedrawStatus(finalStatus);
      setCancelledRoutineRedraws(value)
    }
  }

  const toggleAllOrders = (e, setResetStatusFilters) => {
    const value = e.target.checked;
    // setResetStatusFilters && setResetStatusFilters(true);
    if (tabValue === "stats") {
      let finalStatus = value ? STAT_ALL_STATUSES : [];
      if (orderDateTypeStat) {
        finalStatus = finalStatus.filter(st => st.label !== "Unassigned" && st.label !== "Unacknowledged" && st.label !== "Acknowledged");
      }
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterInLocal({
        allOrders: value,
        orderStatus: finalStatus
      });
      setStatTempStatus(finalStatus);
      setOrderStatus(finalStatus);
      setAllOrders(value)
    } else if (tabValue === TYPE_TIMEDDRAW) {
      let finalStatus = value ? STAT_ALL_STATUSES : [];
      if (orderDateTypeTimedDraw) {
        finalStatus = finalStatus.filter(st => st.label !== "Unassigned" && st.label !== "Unacknowledged" && st.label !== "Acknowledged");
      }
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterTimedDrawInLocal({
        allOrdersTimedDraws: value,
        orderStatus: finalStatus
      });
      setStatTempTimedStatus(finalStatus);
      setTimedDrawStatus(finalStatus);
      setAllOrdersTimedDraws(value)
    } else if (tabValue === TYPE_HOMEHEALTH) {
      let finalStatus = value ? HOME_HEALTH_ALL_STATUSES : [];
      if (orderDateType) {
        finalStatus = finalStatus.filter(st => st.label !== "Unscheduled" && st.label !== "Assigned-To-Coordinator");
      }
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterHomeHealthInLocal({
        allOrdersHomeHealth: value,
        orderStatus: finalStatus
      });
      setHomeHealthTempStatus(finalStatus);
      setHomeHealthStatus(finalStatus);
      setAllOrdersHomeHealth(value)
    } else {
      let finalStatus = value ? STAT_ALL_STATUSES : [];
      finalStatus = finalStatus.filter(st => st.label !== "Notified-Client");
      if (orderDateTypeRoutine) {
        finalStatus = finalStatus.filter(st => st.label !== "Unassigned" && st.label !== "Unacknowledged" && st.label !== "Acknowledged");
      }
      finalStatus = finalStatus.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.label === obj.label
        ))
      );
      updateFilterRoutineRedrawInLocal({
        allOrdersRoutineRedraws: value,
        orderStatus: finalStatus
      });
      setStatTempRoutineStatus(finalStatus);
      setRoutineRedrawStatus(finalStatus);
      setAllOrdersRoutineRedraws(value)
    }
  }

  const clearBulkOrderActionResponse = () => {
    dispatch(clearBulkCheckinCheckoutResponse());
  }

  const clearBulkOrderActionHomeHealthResponse = () => {
    dispatch(clearBulkCheckinCheckoutHomeHealthResponse());
  }

  const updateExcelExportButtonStatus = (type, value) => {
    if (type === ORDER_TYPE_STAT) {
      dispatch(setIsExportDisabledStat(value));
    } else if (type === ORDER_TYPE_ROUTINE_REDRAW) {
      dispatch(setIsExportDisabledRoutine(value));
    } else if (type === ORDER_TYPE_TIMED_DRAW) {
      dispatch(setIsExportDisabledTimed(value));
    } else if (type === ORDER_TYPE_HOME_HEALTH) {
      dispatch(setIsExportDisabledHomeHealth(value));
    }
  }

  const performExport = (type) => {
    const params = type === ORDER_TYPE_STAT ? getFilteredStatOrders(openOrders, orderDateTypeStat, orderStartDate, orderEndDate, cancelledOrders, droppedOffOrders, 
      allOrders, orderStatus, orderColor, orderRegion, orderTerr, orderZone) 
        : type === ORDER_TYPE_ROUTINE_REDRAW ? getFilteredRoutineRedraws(openRoutineRedraws, orderDateTypeRoutine, routineRedrawStartDate, routineRedrawEndDate, cancelledRoutineRedraws, 
          droppedOffRoutineRedraws, allOrdersRoutineRedraws, routineRedrawStatus, routineRedrawColor, routineRedrawRegion, routineRedrawTerr, routineRedrawZone)
        : type === ORDER_TYPE_TIMED_DRAW ? getFilteredTimedDraws(openTimedDraws, orderDateTypeTimedDraw, timedDrawStartDate, timedDrawEndDate, cancelledTimedDraws,
          droppedOffTimedDraws, allOrdersTimedDraws, timedDrawStatus, timedDrawColor, timedDrawRegion, timedDrawTerr, timedDrawZone) : getFilteredHomeHealth(openHomeHealth, unscheduledHomeHealth, orderDateType, homeHealthStartDate, homeHealthEndDate, cancelledHomeHealth, 
          droppedOffHomeHealth, allOrdersHomeHealth, homeHealthStatus, homeHealthRegion, homeHealthState, homeHealthPartner);
    localStorage.setItem(`export-${type}`, true);
    updateExcelExportButtonStatus(type, true);
    setTimeout(() => {
      dispatch(doOrderExport({ type, params }));
    }, 1000)
  }

  function getInitialListingData() {
    if (tabValue === "stats") {
      setTimeout(() => {
        getInitialStatOrders();
      }, 1);
    } else if (tabValue === "routine") {
      setTimeout(() => {
        getInitialRoutineOrders();
      }, 1);
    } else if (tabValue === TYPE_TIMEDDRAW) {
      setTimeout(() => {
        getInitialTimedOrders();
      }, 1);
    } else {
      setTimeout(() => {
        getInitialHomeHealthOrders();
      }, 1);
    }
  }

  function getInitialListingDataForAutoRefreshOnly(data) {
    if (tabValue === "stats") {
      // getting states from the ref
      const {openOrders = false, orderDateType = false, orderStartDate, orderEndDate, cancelledOrders = false, droppedOffOrders = false, 
        allOrders = false, orderStatus = "", orderColor = "", orderRegion = "", orderTerr = "", orderZone = ""} = data;
      setTimeout(() => {
        dispatch(getStatData(getFilteredStatOrders(openOrders, orderDateType, (orderStartDate ? new Date(orderStartDate) : today), (orderEndDate ? new Date(orderEndDate) : orderStartDate ? null : new Date()), cancelledOrders, droppedOffOrders, 
          allOrders, orderStatus, orderColor, orderRegion, orderTerr, orderZone, { skip: 0, take: DEFAULT_ORDER_SIZE })));
      }, 1);
    } else if (tabValue === "routine") {
      const {openRoutineRedraws = false, orderDateType = false, orderStartDate, orderEndDate, cancelledRoutineRedraws = false, 
        droppedOffRoutineRedraws = false, allOrdersRoutineRedraws = false, orderStatus = "", orderColor = "", orderRegion = "", orderTerr = "", orderZone = ""} = data;
      setTimeout(() => {
        dispatch(getRoutineRedraws(getFilteredRoutineRedraws(openRoutineRedraws, orderDateType, (orderStartDate ? new Date(orderStartDate) : todayTwo), (orderEndDate ? new Date(orderEndDate) : orderStartDate ? null : new Date()), cancelledRoutineRedraws, 
          droppedOffRoutineRedraws, allOrdersRoutineRedraws, orderStatus, orderColor, orderRegion, orderTerr, orderZone, { skip: 0, take: DEFAULT_ORDER_SIZE })));
      }, 1);
    } else if (tabValue === TYPE_TIMEDDRAW) {
      const {openTimedDraws = false, orderDateType = false, orderStartDate, orderEndDate, cancelledTimedDraws = false,
        droppedOffTimedDraws = false, allOrdersTimedDraws = false, orderStatus = "", orderColor = "", orderRegion = "", orderTerr = "", orderZone = ""} = data;
      setTimeout(() => {
        dispatch(getTimedDraws(getFilteredTimedDraws(openTimedDraws, orderDateType, (orderStartDate ? new Date(orderStartDate) : todayTwo), (orderEndDate ? new Date(orderEndDate) : orderStartDate ? null : new Date()), cancelledTimedDraws,
          droppedOffTimedDraws, allOrdersTimedDraws, orderStatus, orderColor, orderRegion, orderTerr, orderZone, { skip: 0, take: DEFAULT_ORDER_SIZE })));
      }, 1);
    } else {
      const {openHomeHealth = false, unscheduledHomeHealth = false, orderDateType, orderStartDate, orderEndDate, cancelledHomeHealth = false, 
        droppedOffHomeHealth = false, allOrdersHomeHealth = false, orderStatus = "", orderRegion = "", orderState = "", orderPartner = ""} = data;
      setTimeout(() => {
        dispatch(getHomeHealth(getFilteredHomeHealth(openHomeHealth, unscheduledHomeHealth, (orderDateType === "" || orderDateType === undefined ? true : orderDateType), (orderStartDate ? new Date(orderStartDate) : todayTwo), (orderEndDate ? new Date(orderEndDate) : orderStartDate ? null : new Date()), cancelledHomeHealth, 
          droppedOffHomeHealth, allOrdersHomeHealth, orderStatus, orderRegion, orderState, orderPartner, { skip: 0, take: DEFAULT_ORDER_SIZE })));
      }, 1);
    }
  }

  const handleGetCareDxDropSites = () => {
    dispatch(getCareDxDropSites({flag: false, ignoreFormatting: true}));
  }

  let statOrdersList = statOrdersResponse
    ? statOrdersResponse.content ? statOrdersResponse.content.orders || [] : []
    : [];
    let statOrdersListCount = statOrdersResponse
    ? statOrdersResponse.content ? statOrdersResponse.content.count || 0 : 0
    : 0;
    let routineRedrawList = routineRedrawsResponse
    ? routineRedrawsResponse.content ? routineRedrawsResponse.content.orders || [] : []
    : [];
  let routineRedrawListCount = routineRedrawsResponse
    ? routineRedrawsResponse.content ? routineRedrawsResponse.content.count || 0 : 0
    : 0;

  let timedDrawList = timedDrawsResponse
    ? timedDrawsResponse.content ? timedDrawsResponse.content.orders || [] : []
    : [];
  let timedDrawListCount = timedDrawsResponse
    ? timedDrawsResponse.content ? timedDrawsResponse.content.count || 0 : 0
    : 0;

  let homeHealthList = homeHealthResponse
    ? homeHealthResponse.content ? homeHealthResponse.content.listingOrders || [] : []
    : [];
  let homeHealthListForModal = homeHealthResponse
    ? homeHealthResponse.content ? homeHealthResponse.content.modalDataList || [] : []
    : [];
  let homeHealthListCount = homeHealthResponse
    ? homeHealthResponse.content ? homeHealthResponse.content.count || 0 : 0
    : 0;

  const { content = {} } = orderFilterListData || {};
  let {
    order_status = [],
    home_health_order_status = [],
    gender_type = [],
    test_code = [],
    order_collection_method = [],
    order_creation_method = [],
    order_frequency = [],
    order_type = [],
    note_type = [],
    order_status_code = [],
    order_routine_status_code = [],
    checkout_status = {},
    home_health_checkout_status = {},
    home_health_cancel_status = {},
    order_type_routine_redraw = [],
    order_status_routine_redraw = [],
    order_status_timed_draw = [],
    order_type_timed = [],
    order_service_type = [],
    partner = [],
    address_type = [],
    patient_has_partner_kit = [],
  } = content;

  order_status = order_status.map((status) => {
    return { label: status.name, value: status.id };
  });
  home_health_order_status = home_health_order_status.map((status) => {
    return { label: status.name, value: status.id };
  });
  address_type = address_type.map((status) => {
    return { label: status.name, value: status.id };
  });
  let partner_names = partner.map((part) => {
    return { ...part, label: part.partner_name, value: part.id };
  });
  let partner_ids = partner.map((part) => {
    return { ...part, label: part.external_partner_id, value: part.id };
  });
  order_service_type = order_service_type.map((type) => {
    return { label: type.name, value: type.id };
  });
  order_status_routine_redraw = order_status_routine_redraw.map((status) => {
    return { label: status.name, value: status.id };
  });

  order_status_timed_draw = order_status_timed_draw.map((status) => {
    return { label: status.name, value: status.id };
  });

  order_status_code = order_status_code.map((color) => {
    return { label: color.name, value: color.id };
  });

  order_routine_status_code = order_routine_status_code.map((color) => {
    return { label: color.name, value: color.id };
  });

  gender_type = gender_type.map((gender) => {
    return { label: gender.name, value: gender.id };
  });

  order_collection_method = order_collection_method.map((order) => {
    return { label: order.name, value: order.id };
  });

  order_creation_method = order_creation_method.map((create) => {
    return { label: create.name, value: create.id };
  });

  order_frequency = order_frequency.map((freq) => {
    return { label: freq.name, value: freq.id };
  });

  order_type = order_type.map((order) => {
    return { label: order.name, value: order.id };
  });

  order_type_routine_redraw = order_type_routine_redraw.map((order) => {
    return { label: order.name, value: order.id };
  });

  order_type_timed = order_type_timed.map((order) => {
    return { label: order.name, value: order.id };
  });

  note_type = note_type.map((note) => {
    return { label: note.name, value: note.id };
  });
  patient_has_partner_kit = patient_has_partner_kit.map((status) => {
    return { ...status, label: status.name, value: status.id };
  });


  const { content: filterContent = {} } = filter || {};
  let { region = [], territory = [], states = [], timezone_ids = [] } = filterContent;
  let { content: zone = [] } = activeZones || {};
  let { content: zoneWithDate = [] } = activeZonesWithDate || {};

  region = region.map((data) => {
    return { label: data.name, value: data.id };
  });
  states = states.map((data) => {
    return { label: data.name, value: data.id };
  });
  //   console.log(region)
  territory = territory.map((data) => {
    return { ...data, label: data.name, value: data.id };
  });

  zone = zone.map((data) => {
    return { ...data, label: data.name, value: data.id };
  });

  zoneWithDate = zoneWithDate.map((data) => {
    return { ...data, label: data.name, value: data.id };
  });

  let checkout_status_routine_redraw = checkout_status[ORDER_TYPE_ROUTINE_REDRAW] ? checkout_status[ORDER_TYPE_ROUTINE_REDRAW].map((data) => {
    return { ...data, label: data.name, value: data.id };
  }) : [];

  let checkout_status_timed_draw = checkout_status[ORDER_TYPE_TIMED_DRAW] ? checkout_status[ORDER_TYPE_TIMED_DRAW].map((data) => {
    return { ...data, label: data.name, value: data.id };
  }) : [];

  timezone_ids = timezone_ids.map((tz) => {
    return { ...tz, label: tz.name, value: tz.id };
  });

  // timezone_ids = timezone_ids.filter(tz => tz.isVisible);

  let finalStatOrderList = [];
  if (orderColor && orderColor.length > 0) {
    let colors = orderColor.map((co) => co.label.toLowerCase());
    statOrdersList.forEach((order, index) => {
      if (
        order.status_color_code &&
        order.status_color_code.color_name &&
        colors.includes(order.status_color_code.color_name.toLowerCase())
      ) {
        finalStatOrderList.push(order);
      }
    });
  } else {
    finalStatOrderList = statOrdersList;
  }

  let finalRoutineRedrawList = [];
  if (routineRedrawColor && routineRedrawColor.length > 0) {
    let colors = routineRedrawColor.map((co) => co.label.toLowerCase());
    routineRedrawList.forEach((order, index) => {
      if (
        order.status_color_code &&
        order.status_color_code.color_name &&
        colors.includes(order.status_color_code.color_name.toLowerCase())
      ) {
        finalRoutineRedrawList.push(order);
      }
    });
  } else {
    finalRoutineRedrawList = routineRedrawList;
  }

  let finalTimedDrawList = [];
  if (timedDrawColor && timedDrawColor.length > 0) {
    let colors = timedDrawColor.map((co) => co.label.toLowerCase());
    timedDrawList.forEach((order, index) => {
      if (
        order.status_color_code &&
        order.status_color_code.color_name &&
        colors.includes(order.status_color_code.color_name.toLowerCase())
      ) {
        finalTimedDrawList.push(order);
      }
    });
  } else {
    finalTimedDrawList = timedDrawList;
  }

  let timedDrawOpenOrders = finalTimedDrawList.filter(order => STAT_ALL_OPEN_STATUSES.some(sOrder => order && order.order_status && sOrder.value == order.order_status.id));
  let statOpenOrders = finalStatOrderList.filter(order => STAT_ALL_OPEN_STATUSES.some(sOrder => order && order.order_status && sOrder.value == order.order_status.id));
  let routineRedrawOpenOrders = finalRoutineRedrawList.filter(order => ROUTINE_REDRAW_ALL_OPEN_STATUSES.some(sOrder => order && order.order_status && sOrder.value == order.order_status.id));

  const gridLoader = 
  tabValue === "stats"
    ? statOrdersLoading
    : tabValue === TYPE_TIMEDDRAW
      ? timedDrawsLoading
      : tabValue === TYPE_HOMEHEALTH ? homeHealthLoading : routineRedrawsLoading;

  return (
    <React.Fragment>
      <AutoRefreshProvider callback={data => getInitialListingDataForAutoRefreshOnly(data)} tabValue={tabValue} loading={gridLoader} states={tabValue === "stats" ? statOrderFilter : tabValue === "routine" ? routineRedrawFilter : tabValue === TYPE_TIMEDDRAW ? timedDrawFilter : homeHealthFilter}>

        <div
          className={
            "ad-loading-wrapper " +
            ((
              (  tabValue === "stats"
                ? statOrdersLoading
                : tabValue === TYPE_TIMEDDRAW
                  ? timedDrawsLoading
                  : tabValue === TYPE_HOMEHEALTH ? homeHealthLoading : routineRedrawsLoading) || orderLoading || exportDataLoading
            )
              ? "d-block"
              : "d-none")
          }
        >
          <span
            className={
              "loader ad-loader " +
              ((
                (  tabValue === "stats"
                  ? statOrdersLoading
                  : tabValue === TYPE_TIMEDDRAW
                    ? timedDrawsLoading
                    : tabValue === TYPE_HOMEHEALTH ? homeHealthLoading : routineRedrawsLoading) || orderLoading || exportDataLoading
              )
                ? "d-block"
                : "d-none")
            }
          >
            <span className="visually-hidden">loader icon</span>
          </span>
        </div>
        <CommonHeaderRightSection
          logout={logout}
          color={color}
          name={name}
          title={
            window.location.pathname === ROUTINEREDRAW
              ? "Routine Redraw"
              : window.location.pathname === TIMEDDRAW
                ? "Timed Draw"
                : window.location.pathname === HOMEHEALTH
                  ? "Care at Home"
                  : "Stat Order"
          }
          className="d-flex"
          callbackToRefreshThePage={() => getInitialListingData()}
        />
        <div className="ad-headerbottom-wrapper">
          <div className="new-stat-order-container">
            {(tabValue === TYPE_HOMEHEALTH ? createHomeHealthOrder : createStatOrder) && !hideFilter && (
              <div className="new-stat-order-wrapper">
                <Button
                  className="primary-btn roboto-medium font-size-14 new-stat-order"
                  onClick={() =>
                    tabValue === "stats"
                      ? toggleStatOrderModal(true, {}, "create")
                      : tabValue === TYPE_TIMEDDRAW
                        ? toggleTimedDrawModal(true, {}, "create")
                        : tabValue === TYPE_HOMEHEALTH
                          ? toggleHomeHealthModal(true, {}, "create")
                          : toggleRoutineRedrawModal(true, {}, "create")
                  }
                >
                  <span className="add-order-new">
                    <span className="visually-hidden">Add stat order icon</span>
                  </span>
                  <span className="add-stat-order-text">New Order</span>
                </Button>
              </div>
            )}
          </div>
          {(tabValue === TYPE_HOMEHEALTH ? listHomeHealthOrder : listStatOrder) && (
            <Form className="adduser-row">
              {!hideFilter && <div className="stat-order-toggle d-flex ms-5">
                <div className="ad-toggle-switch d-flex align-items-center">
                  <div className="ah-toggle-left ah-divider">
                    <p className="toggle-title-wrapper"><span className="toggle-title-text">Date Insensitive Filters</span>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip
                            id="tooltip-date-insensitive"
                            className="st-value-tooltip"
                          >
                            <p>
                              These filters aren't influenced by date ranges. They list orders without considering their created/ check-in date.
                            </p>
                          </Tooltip>
                        }
                      ><span className="info-icon"><span className="visually-hidden">Info Icon</span></span></OverlayTrigger>
                    </p>
                    <div className="d-flex">
                      <div
                        className={`ad-toggle-switch d-flex align-items-center ${(
                          tabValue === "stats"
                            ? droppedOffOrders || cancelledOrders || allOrders
                            : tabValue === TYPE_TIMEDDRAW ?
                              droppedOffTimedDraws || cancelledTimedDraws || allOrdersTimedDraws
                              : tabValue === TYPE_HOMEHEALTH
                                ? droppedOffHomeHealth || cancelledHomeHealth || unscheduledHomeHealth || allOrdersHomeHealth
                                : droppedOffRoutineRedraws || cancelledRoutineRedraws || allOrdersRoutineRedraws
                        )
                          ? "radio-disabled"
                          : ""
                          }`}
                      >
                        <span className="ad-switch font-size-13 roboto-regular">
                          All Open
                        </span>
                        <label
                          className={`switch position-relative ${(
                            tabValue === "stats"
                              ? droppedOffOrders || cancelledOrders || allOrders
                              : tabValue === TYPE_TIMEDDRAW
                                ? droppedOffTimedDraws || cancelledTimedDraws || allOrdersTimedDraws
                                : tabValue === TYPE_HOMEHEALTH
                                  ? droppedOffHomeHealth || cancelledHomeHealth || unscheduledHomeHealth || allOrdersHomeHealth
                                  : droppedOffRoutineRedraws || cancelledRoutineRedraws || allOrdersRoutineRedraws
                          )
                            ? "radio-disabled"
                            : ""
                            }`}
                        >
                          <span className="visually-hidden">All Open</span>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              if (tabValue === "stats") {
                                getFilteredStatOrdersWithOpenOrders(e.target.checked);
                              } else if (tabValue === TYPE_TIMEDDRAW) {
                                getFilteredTimedDrawsWithOpenOrders(e.target.checked);
                              } else if (tabValue === TYPE_HOMEHEALTH) {
                                getFilteredHomeHealthWithOpenOrders(e.target.checked);
                              } else {
                                getFilteredRoutineRedrawsWithOpenOrders(
                                  e.target.checked
                                );
                              }
                            }}
                            value={
                              tabValue === "stats"
                                ? openOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? openTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? openHomeHealth
                                    : openRoutineRedraws
                            }
                            checked={
                              tabValue === "stats"
                                ? openOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? openTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? openHomeHealth
                                    : openRoutineRedraws
                            }
                            disabled={
                              tabValue === "stats"
                                ? droppedOffOrders || cancelledOrders || allOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? droppedOffTimedDraws || cancelledTimedDraws || allOrdersTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? droppedOffHomeHealth || cancelledHomeHealth || unscheduledHomeHealth || allOrdersHomeHealth
                                    : droppedOffRoutineRedraws || cancelledRoutineRedraws || allOrdersRoutineRedraws
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      {tabValue === TYPE_HOMEHEALTH && <div
                        className={`ad-toggle-switch d-flex align-items-center ${(droppedOffHomeHealth || cancelledHomeHealth || openHomeHealth || allOrdersHomeHealth)
                          ? "radio-disabled"
                          : ""
                          }`}
                      >
                        <span className="ad-switch font-size-13 roboto-regular">
                          Unscheduled
                        </span>
                        <label
                          className={`switch position-relative ${(droppedOffHomeHealth || cancelledHomeHealth || openHomeHealth || allOrdersHomeHealth)
                            ? "radio-disabled"
                            : ""
                            }`}
                        >
                          <span className="visually-hidden">Unscheduled</span>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              getFilteredHomeHealthWithUnscheduledOrders(e.target.checked)
                            }}
                            value={unscheduledHomeHealth}
                            checked={unscheduledHomeHealth}
                            disabled={droppedOffHomeHealth || cancelledHomeHealth || openHomeHealth || allOrdersHomeHealth}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>}
                    </div>
                  </div>
                  <div className="ps-2 ms-1">
                    <p className="toggle-title-wrapper"><span className="toggle-title-text">Date Sensitive Filters</span>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip
                            id="tooltip-date-sensitive"
                            className="st-value-tooltip"
                          >
                            <p>
                              The filters show orders based on the selected date range. The order list will only include orders that fall within this range.
                            </p>
                          </Tooltip>
                        }
                      ><span className="info-icon"><span className="visually-hidden">Info Icon</span></span></OverlayTrigger>
                    </p>
                    <div className="d-flex">
                      <div
                        className={`ad-toggle-switch d-flex align-items-center ${(tabValue === "stats"
                          ? openOrders || droppedOffOrders || cancelledOrders
                          : tabValue === TYPE_TIMEDDRAW
                            ? openTimedDraws || droppedOffTimedDraws || cancelledTimedDraws
                            : tabValue === TYPE_HOMEHEALTH
                              ? openHomeHealth || droppedOffHomeHealth || cancelledHomeHealth || unscheduledHomeHealth
                              : openRoutineRedraws || droppedOffRoutineRedraws || cancelledRoutineRedraws)
                          ? "radio-disabled"
                          : ""
                          }`}
                      >
                        <span className="ad-switch font-size-13 roboto-regular">
                          All
                        </span>
                        <label
                          className={`switch position-relative ${(
                            tabValue === "stats"
                              ? openOrders || droppedOffOrders || cancelledOrders
                              : tabValue === TYPE_TIMEDDRAW
                                ? openTimedDraws || droppedOffTimedDraws || cancelledTimedDraws
                                : tabValue === TYPE_HOMEHEALTH
                                  ? openHomeHealth || droppedOffHomeHealth || cancelledHomeHealth || unscheduledHomeHealth
                                  : openRoutineRedraws || droppedOffRoutineRedraws || cancelledRoutineRedraws
                          )
                            ? "radio-disabled"
                            : ""
                            }`}
                        >
                          <span className="visually-hidden">All</span>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              toggleAllOrders(e, setResetStatusFilters)
                            }}
                            value={
                              tabValue === "stats"
                                ? allOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? allOrdersTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? allOrdersHomeHealth
                                    : allOrdersRoutineRedraws
                            }
                            checked={
                              tabValue === "stats"
                                ? allOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? allOrdersTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? allOrdersHomeHealth
                                    : allOrdersRoutineRedraws
                            }
                            disabled={
                              tabValue === "stats"
                                ? openOrders || droppedOffOrders || cancelledOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? openTimedDraws || droppedOffTimedDraws || cancelledTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? openHomeHealth || droppedOffHomeHealth || cancelledHomeHealth || unscheduledHomeHealth
                                    : openRoutineRedraws || droppedOffRoutineRedraws || cancelledRoutineRedraws
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div
                        className={`ad-toggle-switch d-flex align-items-center ${(tabValue === "stats"
                          ? openOrders || allOrders
                          : tabValue === TYPE_TIMEDDRAW
                            ? openTimedDraws || allOrdersTimedDraws
                            : tabValue === TYPE_HOMEHEALTH
                              ? openHomeHealth || unscheduledHomeHealth || allOrdersHomeHealth
                              : openRoutineRedraws || allOrdersRoutineRedraws)
                          ? "radio-disabled"
                          : ""
                          }`}
                      >
                        <span className="ad-switch font-size-13 roboto-regular ">
                          Completed
                        </span>
                        <label
                          className={`switch position-relative ${(
                            tabValue === "stats"
                              ? openOrders || allOrders
                              : tabValue === TYPE_TIMEDDRAW
                                ? openTimedDraws || allOrdersTimedDraws
                                : tabValue === TYPE_HOMEHEALTH
                                  ? openHomeHealth || unscheduledHomeHealth || allOrdersHomeHealth
                                  : openRoutineRedraws || allOrdersRoutineRedraws
                          )
                            ? "radio-disabled"
                            : ""
                            }`}
                        >
                          <span className="visually-hidden">
                            Completed
                          </span>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              toggleCompletedOrders(e, setResetStatusFilters)
                            }}
                            value={
                              tabValue === "stats"
                                ? droppedOffOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? droppedOffTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? droppedOffHomeHealth
                                    : droppedOffRoutineRedraws
                            }
                            checked={
                              tabValue === "stats"
                                ? droppedOffOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? droppedOffTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? droppedOffHomeHealth
                                    : droppedOffRoutineRedraws
                            }
                            disabled={
                              tabValue === "stats"
                                ? openOrders || allOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? openTimedDraws || allOrdersTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? openHomeHealth || unscheduledHomeHealth || allOrdersHomeHealth
                                    : openRoutineRedraws || allOrdersRoutineRedraws
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div
                        className={`ad-toggle-switch d-flex align-items-center ${(tabValue === "stats"
                          ? openOrders || allOrders
                          : tabValue === TYPE_TIMEDDRAW
                            ? openTimedDraws || allOrdersTimedDraws
                            : tabValue === TYPE_HOMEHEALTH
                              ? openHomeHealth || unscheduledHomeHealth || allOrdersHomeHealth
                              : openRoutineRedraws || allOrdersRoutineRedraws)
                          ? "radio-disabled"
                          : ""
                          }`}
                      >
                        <span className="ad-switch font-size-13 roboto-regular ">
                          Cancelled
                        </span>
                        <label
                          className={`switch position-relative ${(
                            tabValue === "stats"
                              ? openOrders || allOrders
                              : tabValue === TYPE_TIMEDDRAW
                                ? openTimedDraws || allOrdersTimedDraws
                                : tabValue === TYPE_HOMEHEALTH
                                  ? openHomeHealth || unscheduledHomeHealth || allOrdersHomeHealth
                                  : openRoutineRedraws || allOrdersRoutineRedraws
                          )
                            ? "radio-disabled"
                            : ""
                            }`}
                        >
                          <span className="visually-hidden">
                            Cancelled
                          </span>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              toggleCancelledOrders(e, setResetStatusFilters)
                            }}
                            value={
                              tabValue === "stats"
                                ? cancelledOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? cancelledTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? cancelledHomeHealth
                                    : cancelledRoutineRedraws
                            }
                            checked={
                              tabValue === "stats"
                                ? cancelledOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? cancelledTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? cancelledHomeHealth
                                    : cancelledRoutineRedraws
                            }
                            disabled={
                              tabValue === "stats"
                                ? openOrders || allOrders
                                : tabValue === TYPE_TIMEDDRAW
                                  ? openTimedDraws || allOrdersTimedDraws
                                  : tabValue === TYPE_HOMEHEALTH
                                    ? openHomeHealth || unscheduledHomeHealth || allOrdersHomeHealth
                                    : openRoutineRedraws || allOrdersRoutineRedraws
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div> </div>}
              {statOrderData.show && <StatOrderAddEdit
                show={statOrderData.show || false}
                handleClose={() => toggleStatOrderModal(false)}
                toggleStatOrderModal={toggleStatOrderModal}
                statOrderData={statOrderData.data || {}}
                handleStatOrderError={handleStatOrderError}
                mode={statOrderData.mode || "view"}
                error={error}
                order_status={order_status}
                regions={region}
                territories={territory}
                zones={zone}
                genders={gender_type}
                order_collection_method={order_collection_method}
                order_creation_method={order_creation_method}
                order_frequency={order_frequency}
                order_type={order_type}
                note_type={note_type}
                test_code={test_code}
                handleCreateOrder={handleCreateOrder}
                handleUpdateOrder={handleUpdateOrder}
                handleClearDateTime={handleClearDateTime}
                deleteStatActionResult={deleteStatActionResult}
                addStatOrder={addStatOrder}
                updateStatOrderResponse={updateStatOrderResponse}
                updateStatOrder={updateStatOrder}
                phlebCheckinCheckout={phlebCheckinCheckout}
                checkout_status={checkout_status}
                submitAcknowledgePartialOrder={submitAcknowledgePartialOrder}
                submitForceAcknowledgeOrder={submitForceAcknowledgeOrder}
                bulkCheckinCheckoutData={bulkCheckinCheckoutData}
                loadOptions3={loadOptions3}
                loadTestCodes={loadTestCodes}
                acknowledgePartialOrderLoader={acknowledgePartialOrderLoader}
                forceAcknowledgeOrderLoader={forceAcknowledgeOrderLoader}
                reRender={reRender}
                canDeleteCheckinCheckout={canDeleteCheckinCheckout}
                canCancelStatOrder={canCancelStatOrder}
                cancelStatOrderHandler={cancelStatOrderHandler}
                cancelStatOrder={cancelStatOrder}
                paramOrderId={orderId}
                navigate={navigate}
                timezone_ids={timezone_ids}
                clearBulkOrderActionResponse={clearBulkOrderActionResponse}
              />}
              {routineRedrawData.show && <RoutineRedrawAddEdit
                show={routineRedrawData.show || false}
                handleClose={() => toggleRoutineRedrawModal(false)}
                toggleRoutineRedrawModal={toggleRoutineRedrawModal}
                routineRedrawData={routineRedrawData.data || {}}
                handleRoutineRedrawError={handleRoutineRedrawError}
                mode={routineRedrawData.mode || "view"}
                error={error}
                order_status={order_status_routine_redraw}
                regions={region}
                territories={territory}
                zones={zone}
                genders={gender_type}
                order_collection_method={order_collection_method}
                order_creation_method={order_creation_method}
                order_frequency={order_frequency}
                order_type={order_type_routine_redraw}
                note_type={note_type}
                test_code={test_code}
                handleCreateOrder={handleCreateRoutineRedraw}
                handleUpdateOrder={handleUpdateRoutineRedraw}
                handleClearDateTime={handleClearDateTime}
                deleteStatActionResult={deleteStatActionResult}
                addRoutineRedraw={addRoutineRedraw}
                updateRoutineRedrawResponse={updateRoutineRedrawResponse}
                updateRoutineRedraw={updateStatOrder}
                phlebCheckinCheckout={phlebCheckinCheckout}
                checkout_status={checkout_status_routine_redraw}
                submitAcknowledgePartialOrder={submitAcknowledgePartialOrder}
                submitForceAcknowledgeOrder={submitForceAcknowledgeOrder}
                bulkCheckinCheckoutData={bulkCheckinCheckoutData}
                loadOptions3={loadOptions3}
                loadTestCodes={loadTestCodes}
                acknowledgePartialOrderLoader={acknowledgePartialOrderLoader}
                forceAcknowledgeOrderLoader={forceAcknowledgeOrderLoader}
                canDeleteCheckinCheckout={canDeleteCheckinCheckout}
                canCancelStatOrder={canCancelStatOrder}
                cancelStatOrderHandler={cancelStatOrderHandler}
                cancelStatOrder={cancelStatOrder}
                paramOrderId={orderId}
                navigate={navigate}
                timezone_ids={timezone_ids}
                clearBulkOrderActionResponse={clearBulkOrderActionResponse}
              />}
              {timedDrawData.show && <TimedDrawAddEdit
                show={timedDrawData.show || false}
                handleClose={() => toggleTimedDrawModal(false)}
                toggleTimedDrawModal={toggleTimedDrawModal}
                timedDrawData={timedDrawData.data || {}}
                handleTimedDrawError={handleTimedDrawError}
                mode={timedDrawData.mode || "view"}
                error={error}
                order_status={order_status_timed_draw}
                regions={region}
                territories={territory}
                zones={zone}
                genders={gender_type}
                order_collection_method={order_collection_method}
                order_creation_method={order_creation_method}
                order_frequency={order_frequency}
                order_type={order_type_timed}
                note_type={note_type}
                test_code={test_code}
                handleCreateOrder={handleCreateTimedDraw}
                handleUpdateOrder={handleUpdateTimedDraw}
                handleClearDateTime={handleClearDateTime}
                deleteStatActionResult={deleteStatActionResult}
                addTimedDraw={addTimedDraw}
                updateTimedDraw={updateStatOrder}
                phlebCheckinCheckout={phlebCheckinCheckout}
                checkout_status={checkout_status_timed_draw}
                submitAcknowledgePartialOrder={submitAcknowledgePartialOrder}
                submitForceAcknowledgeOrder={submitForceAcknowledgeOrder}
                bulkCheckinCheckoutData={bulkCheckinCheckoutData}
                loadOptions3={loadOptions3}
                loadTestCodes={loadTestCodes}
                acknowledgePartialOrderLoader={acknowledgePartialOrderLoader}
                forceAcknowledgeOrderLoader={forceAcknowledgeOrderLoader}
                canDeleteCheckinCheckout={canDeleteCheckinCheckout}
                canCancelStatOrder={canCancelStatOrder}
                cancelStatOrderHandler={cancelStatOrderHandler}
                cancelStatOrder={cancelStatOrder}
                paramOrderId={orderId}
                navigate={navigate}
                timezone_ids={timezone_ids}
                clearBulkOrderActionResponse={clearBulkOrderActionResponse}
              />}
              {homeHealthData.show && <HomeHealthAddEdit
                show={homeHealthData.show || false}
                handleClose={() => toggleHomeHealthModal(false)}
                toggleHomeHealthModal={toggleHomeHealthModal}
                homeHealthData={homeHealthData.data || {}}
                isGlobalSearch={homeHealthData.isGlobalSearch || false}
                setHomeHealthData={setHomeHealthData}
                handleTimedDrawError={handleTimedDrawError}
                mode={homeHealthData.mode || "view"}
                error={error}
                home_health_order_status={home_health_order_status}
                regions={region}
                territories={territory}
                zones={zone}
                genders={gender_type}
                order_collection_method={order_collection_method}
                order_creation_method={order_creation_method}
                order_frequency={order_frequency}
                order_type={order_type}
                note_type={note_type}
                test_code={test_code}
                handleCreateOrder={handleCreateHomeHealth}
                handleUpdateOrder={handleUpdateHomeHealth}
                handleCloneHomeHealth={handleCloneHomeHealth}
                addHomeHealths={addHomeHealths}
                cloneHomeHealths={cloneHomeHealths}
                cancelHomeHealths={cancelHomeHealths}
                updateHomeHealthsResponse={updateHomeHealthsResponse}
                updateHomeHealthOrder={updateHomeHealthOrder}
                phlebCheckinCheckout={phlebCheckinCheckoutHomeHealth}
                checkout_status={home_health_checkout_status}
                home_health_cancel_status={home_health_cancel_status}
                submitAcknowledgePartialOrder={submitAcknowledgePartialOrder}
                submitForceAcknowledgeOrder={submitForceAcknowledgeOrder}
                bulkCheckinCheckoutData={bulkCheckinCheckoutHomeHealthData}
                loadOptions3={loadOptions3}
                loadOptions={fetchUsersForHomeHealth}
                loadTestCodes={loadTestCodes}
                acknowledgePartialOrderLoader={acknowledgePartialOrderLoader}
                forceAcknowledgeOrderLoader={forceAcknowledgeOrderLoader}
                loggedInUser={loggedInUser}
                order_service_type={order_service_type}
                partner_names={partner_names}
                partner_ids={partner_ids}
                address_type={address_type}
                patient_has_partner_kit={patient_has_partner_kit}
                timezone_ids={timezone_ids}
                unclaimOrderHandler={unclaimOrderHandler}
                unclaimOrderLoader={unclaimOrderLoader}
                ignoreUserAvailability={ignoreUserAvailability}
                setIgnoreUserAvailability={setIgnoreUserAvailability}
                handleCheckUserSchedule={handleCheckUserSchedule}
                homeHealthUserScheduleLoader={homeHealthUserScheduleLoader}
                homeHealthUserScheduleListResponse={homeHealthUserScheduleListResponse}
                handleCancelHomeHealth={handleCancelHomeHealth}
                canCancelHomeHealthOrder={canCancelHomeHealthOrder}
                canUnclaimHomeHealthOrder={canUnclaimHomeHealthOrder}
                handleFetchDataByPartnerOrderId={handleFetchDataByPartnerOrderId}
                dataByPartnerOrderIdLoading={dataByPartnerOrderIdLoading}
                dataByPartnerOrderIdResponse={dataByPartnerOrderIdResponse}
                handleClearDataByPartnerOrderId={handleClearDataByPartnerOrderId}
                handleUnCancelHomeHealth={handleUnCancelHomeHealth}
                uncancelHomeHealths={uncancelHomeHealths}
                canUnCancelHomeHealthOrder={canUnCancelHomeHealthOrder}
                bulkCheckinCheckoutHomeHealthLoader={bulkCheckinCheckoutHomeHealthLoader}
                updateHomeHealthLoading={updateHomeHealthLoading}
                addHomeHealthListLoader={addHomeHealthListLoader}
                cloneHomeHealthListLoader={cloneHomeHealthListLoader}
                canCloneHomeHealthOrder={canCloneHomeHealthOrder}
                paramOrderId={orderId}
                navigate={navigate}
                clearBulkOrderActionResponse={clearBulkOrderActionHomeHealthResponse}
                dropSites={dropSites}
                handleGetCareDxDropSites={handleGetCareDxDropSites}
              />}
            </Form>
          )}
          {window.location.pathname === HOMEHEALTH ? false : !hideFilter && <ul className="ad-navtab nav-tabs" role="tablist">
            {listStatOrder && <li className="nav-item" role="presentation">
              <CustomTabWithBadge title="Stats" notificationCount={statOpenOrders ? statOpenOrders.length : 0} className={"nav-link " + (tabValue === "stats" ? "active" : "")} onClick={() => handleChangeTab("stats")} currentTab="stats" tabValue={tabValue} />
            </li>}
            {listStatOrder && <li className="nav-item" role="presentation">
              <CustomTabWithBadge
                onClick={() => handleChangeTab("routine")}
                className={
                  "nav-link " + (tabValue === "routine" ? "active" : "")
                }
                title="Routine Redraw"
                notificationCount={routineRedrawOpenOrders ? routineRedrawOpenOrders.length : 0}
                currentTab="routine"
                tabValue={tabValue}
              />
            </li>}
            {listStatOrder && <li className="nav-item" role="presentation">
              <CustomTabWithBadge
                onClick={() => handleChangeTab(TYPE_TIMEDDRAW)}
                className={
                  "nav-link " + (tabValue === TYPE_TIMEDDRAW ? "active" : "")
                }
                title="Timed Draw"
                notificationCount={timedDrawOpenOrders ? timedDrawOpenOrders.length : 0}
                currentTab={TYPE_TIMEDDRAW}
                tabValue={tabValue}
              />
            </li>}
          </ul>}
          {tabValue === "stats" && (
            <div className={`stat-order-wrapper d-flex ${openOrders ? 'open-order-enabled' : ''}`} >
              {listStatOrder && !hideFilter && (
                <></>
              )}
              {listStatOrder ? (
                <>
                  <StatOrderList
                    data={finalStatOrderList}
                    totalOrderCount={statOrdersListCount}
                    editModal={toggleStatOrderModal}
                    type={TYPE_STAT}
                    orderType={ORDER_TYPE_STAT}
                    order_status={order_status}
                    region={region}
                    territory={territory}
                    zone={zoneWithDate}
                    orderDateType={orderDateTypeStat}
                    setOrderDateType={setOrderDateTypeStat}
                    orderStartDate={orderStartDate}
                    orderEndDate={orderEndDate}
                    setOrderStartDate={setOrderStartDate}
                    setOrderEndDate={setOrderEndDate}
                    orderColor={orderColor}
                    setOrderColor={setOrderColor}
                    orderStatus={orderStatus}
                    setOrderStatus={setOrderStatus}
                    orderRegion={orderRegion}
                    setOrderRegion={setOrderRegion}
                    orderTerr={orderTerr}
                    setOrderTerr={setOrderTerr}
                    orderZone={orderZone}
                    setOrderZone={setOrderZone}
                    isClicked={isClicked}
                    setIsClicked={setIsClicked}
                    order_status_code={order_status_code}
                    openOrders={openOrders}
                    updateFilterInLocal={updateFilterInLocal}
                    dateRef={orderDateRangeRef}
                    isModalOpen={statOrderData.show}
                    setHelpModal={setHelpModal}
                    setResetFilters={setResetFilters}
                    allOrders={allOrders}
                    cancelledOrders={cancelledOrders}
                    droppedOffOrders={droppedOffOrders}
                    highlightStatusApply={highlightStatusApply}
                    highlightColorApply={highlightColorApply}
                    highlightRegionApply={highlightRegionApply}
                    highlightTerritoryApply={highlightTerritoryApply}
                    highlightZoneApply={highlightZoneApply}

                    setHighlightStatusApply={setHighlightStatusApply}
                    setHighlightColorApply={setHighlightColorApply}
                    setHighlightRegionApply={setHighlightRegionApply}
                    setHighlightTerritoryApply={setHighlightTerritoryApply}
                    setHighlightZoneApply={setHighlightZoneApply}

                    statTempStatus={statTempStatus}
                    setStatTempStatus={setStatTempStatus}
                    statTempColor={statTempColor}
                    setStatTempColor={setStatTempColor}
                    statTempRegion={statTempRegion}
                    setStatTempRegion={setStatTempRegion}
                    statTempTerritory={statTempTerritory}
                    setStatTempTerritory={setStatTempTerritory}
                    statTempZone={statTempZone}
                    setStatTempZone={setStatTempZone}
                    
                    getInitialListingData={getInitialListingData}
                    performExport={performExport}
                    isExportDisabled={isExportDisabledStatVal}
                  />
                </>
              ) : (
                <p className="black-color my-3">
                  You do not have access to view this page
                </p>
              )}
            </div>
          )}

          {tabValue === "routine" && (
            <div className={`stat-order-wrapper d-flex ${openRoutineRedraws ? 'open-order-enabled' : ''}`}>
              {listStatOrder ? (
                <>
                  <RoutineRedrawList
                    data={finalRoutineRedrawList}
                    totalOrderCount={routineRedrawListCount}
                    editModal={toggleRoutineRedrawModal}
                    type={TYPE_ROUTINE}
                    orderType={ORDER_TYPE_ROUTINE_REDRAW}
                    order_status={order_status_routine_redraw}
                    region={region}
                    territory={territory}
                    zone={zoneWithDate}
                    orderDateType={orderDateTypeRoutine}
                    setOrderDateType={setOrderDateTypeRoutine}
                    orderStartDate={routineRedrawStartDate}
                    orderEndDate={routineRedrawEndDate}
                    setOrderStartDate={setRoutineRedrawStartDate}
                    setOrderEndDate={setRoutineRedrawEndDate}
                    orderColor={routineRedrawColor}
                    setOrderColor={setRoutineRedrawColor}
                    orderStatus={routineRedrawStatus}
                    setOrderStatus={setRoutineRedrawStatus}
                    orderRegion={routineRedrawRegion}
                    setOrderRegion={setRoutineRedrawRegion}
                    orderTerr={routineRedrawTerr}
                    setOrderTerr={setRoutineRedrawTerr}
                    orderZone={routineRedrawZone}
                    setOrderZone={setRoutineRedrawZone}
                    isClicked={isClickedRoutine}
                    setIsClicked={setIsClickedRoutine}
                    order_status_code={order_routine_status_code}
                    openRoutineRedraws={openRoutineRedraws}
                    updateFilterInLocal={updateFilterRoutineRedrawInLocal}
                    dateRef={routineDateRangeRef}
                    hideFilter={hideFilter}
                    isModalOpen={routineRedrawData.show}
                    setHelpModal={setHelpModal}
                    setResetFilters={setResetFilters}
                    allOrders={allOrdersRoutineRedraws}
                    cancelledOrders={cancelledRoutineRedraws}
                    droppedOffOrders={droppedOffRoutineRedraws}
                    highlightStatusApply={highlightStatusApplyRoutine}
                    highlightColorApply={highlightColorApplyRoutine}
                    highlightRegionApply={highlightRegionApplyRoutine}
                    highlightTerritoryApply={highlightTerritoryApplyRoutine}
                    highlightZoneApply={highlightZoneApplyRoutine}

                    setHighlightStatusApply={setHighlightStatusApplyRoutine}
                    setHighlightColorApply={setHighlightColorApplyRoutine}
                    setHighlightRegionApply={setHighlightRegionApplyRoutine}
                    setHighlightTerritoryApply={setHighlightTerritoryApplyRoutine}
                    setHighlightZoneApply={setHighlightZoneApplyRoutine}

                    statTempStatus={statTempRoutineStatus}
                    setStatTempStatus={setStatTempRoutineStatus}
                    statTempColor={statTempRoutineColor}
                    setStatTempColor={setStatTempRoutineColor}
                    statTempRegion={statTempRoutineRegion}
                    setStatTempRegion={setStatTempRoutineRegion}
                    statTempTerritory={statTempRoutineTerritory}
                    setStatTempTerritory={setStatTempRoutineTerritory}
                    statTempZone={statTempRoutineZone}
                    setStatTempZone={setStatTempRoutineZone}

                    getInitialListingData={getInitialListingData}
                    performExport={performExport}
                    isExportDisabled={isExportDisabledRoutineVal}
                  />
                </>
              ) : (
                <p className="black-color my-3">
                  You do not have access to view this page
                </p>
              )}
            </div>
          )}

          {tabValue === TYPE_TIMEDDRAW && (
            <div className={`stat-order-wrapper d-flex ${openTimedDraws ? 'open-order-enabled' : ''}`}>
              {listStatOrder && !hideFilter && (
                <></>
              )}
              {listStatOrder ? (
                <>
                  <TimedDrawList
                    data={finalTimedDrawList}
                    totalOrderCount={timedDrawListCount}
                    editModal={toggleTimedDrawModal}
                    type={TYPE_TIMEDDRAW}
                    orderType={ORDER_TYPE_TIMED_DRAW}
                    order_status={order_status_timed_draw}
                    region={region}
                    territory={territory}
                    zone={zoneWithDate}
                    orderDateType={orderDateTypeTimedDraw}
                    setOrderDateType={setOrderDateTypeTimedDraw}
                    orderStartDate={timedDrawStartDate}
                    orderEndDate={timedDrawEndDate}
                    setOrderStartDate={setTimedDrawStartDate}
                    setOrderEndDate={setTimedDrawEndDate}
                    orderColor={timedDrawColor}
                    setOrderColor={setTimedDrawColor}
                    orderStatus={timedDrawStatus}
                    setOrderStatus={setTimedDrawStatus}
                    orderRegion={timedDrawRegion}
                    setOrderRegion={setTimedDrawRegion}
                    orderTerr={timedDrawTerr}
                    setOrderTerr={setTimedDrawTerr}
                    orderZone={timedDrawZone}
                    setOrderZone={setTimedDrawZone}
                    isClicked={isClickedTimed}
                    setIsClicked={setIsClickedTimed}
                    order_status_code={order_status_code}
                    openTimedDraws={openTimedDraws}
                    updateFilterInLocal={updateFilterTimedDrawInLocal}
                    dateRef={timedDateRangeRef}
                    hideFilter={hideFilter}
                    isModalOpen={timedDrawData.show}
                    setHelpModal={setHelpModal}
                    setResetFilters={setResetFilters}
                    allOrders={allOrdersTimedDraws}
                    cancelledOrders={cancelledTimedDraws}
                    droppedOffOrders={droppedOffTimedDraws}
                    highlightStatusApply={highlightStatusApplyTimed}
                    highlightColorApply={highlightColorApplyTimed}
                    highlightRegionApply={highlightRegionApplyTimed}
                    highlightTerritoryApply={highlightTerritoryApplyTimed}
                    highlightZoneApply={highlightZoneApplyTimed}

                    setHighlightStatusApply={setHighlightStatusApplyTimed}
                    setHighlightColorApply={setHighlightColorApplyTimed}
                    setHighlightRegionApply={setHighlightRegionApplyTimed}
                    setHighlightTerritoryApply={setHighlightTerritoryApplyTimed}
                    setHighlightZoneApply={setHighlightZoneApplyTimed}

                    statTempStatus={statTempTimedStatus}
                    setStatTempStatus={setStatTempTimedStatus}
                    statTempColor={statTempTimedColor}
                    setStatTempColor={setStatTempTimedColor}
                    statTempRegion={statTempTimedRegion}
                    setStatTempRegion={setStatTempTimedRegion}
                    statTempTerritory={statTempTimedTerritory}
                    setStatTempTerritory={setStatTempTimedTerritory}
                    statTempZone={statTempTimedZone}
                    setStatTempZone={setStatTempTimedZone}

                    getInitialListingData={getInitialListingData}
                    performExport={performExport}
                    isExportDisabled={isExportDisabledTimedVal}
                  />
                </>
              ) : (
                <p className="black-color my-3">
                  You do not have access to view this page
                </p>
              )}
            </div>
          )}

          {tabValue === TYPE_HOMEHEALTH && (
            <div className={`stat-order-wrapper d-flex ${openHomeHealth || unscheduledHomeHealth ? 'open-order-enabled' : ''}`}>
              {listHomeHealthOrder && !hideFilter && (
                <></>
              )}
              {listHomeHealthOrder ? (
                <>
                  <HomeHealthList
                    data={homeHealthList}
                    totalOrderCount={homeHealthListCount}
                    homeHealthListForModal={homeHealthListForModal}
                    type={TYPE_HOMEHEALTH}
                    orderType={ORDER_TYPE_HOME_HEALTH}
                    editModal={toggleHomeHealthModal}
                    order_status={home_health_order_status}
                    region={region}
                    states={states}
                    partner={partner_names}
                    orderDateType={orderDateType}
                    setOrderDateType={setOrderDateType}
                    orderStartDate={homeHealthStartDate}
                    orderEndDate={homeHealthEndDate}
                    setOrderStartDate={setHomeHealthStartDate}
                    setOrderEndDate={setHomeHealthEndDate}
                    orderStatus={homeHealthStatus}
                    setOrderStatus={setHomeHealthStatus}
                    orderRegion={homeHealthRegion}
                    setOrderRegion={setHomeHealthRegion}
                    orderState={homeHealthState}
                    setHomeHealthState={setHomeHealthState}
                    orderPartner={homeHealthPartner}
                    setOrderPartner={setHomeHealthPartner}
                    isClicked={isClickedHome}
                    setIsClicked={setIsClickedHome}
                    order_status_code={order_status_code}
                    openHomeHealth={openHomeHealth}
                    unscheduledHomeHealth={unscheduledHomeHealth}
                    updateFilterInLocal={updateFilterHomeHealthInLocal}
                    dateRef={homeDateRangeRef}
                    dateRef2={homeDateRangeRef2}
                    hideFilter={hideFilter}
                    isModalOpen={homeHealthData.show}
                    setHelpModal={setHelpModal}
                    setResetFilters={setResetFilters}
                    allOrders={allOrdersHomeHealth}
                    cancelledOrders={cancelledHomeHealth}
                    droppedOffOrders={droppedOffHomeHealth}
                    highlightStatusApply={highlightStatusApplyHome}
                    highlightRegionApply={highlightRegionApplyHome}
                    highlightStateApply={highlightStateApplyHome}
                    highlightPartnerApply={highlightPartnerApplyHome}

                    setHighlightStatusApply={setHighlightStatusApplyHome}
                    setHighlightRegionApply={setHighlightRegionApplyHome}
                    setHighlightStateApply={setHighlightStateApplyHome}
                    setHighlightPartnerApply={setHighlightPartnerApplyHome}

                    homeHealthTempStatus={homeHealthTempStatus}
                    setHomeHealthTempStatus={setHomeHealthTempStatus}
                    homeHealthTempRegion={homeHealthTempRegion}
                    setHomeHealthTempRegion={setHomeHealthTempRegion}
                    homeHealthTempState={homeHealthTempState}
                    setHomeHealthTempState={setHomeHealthTempState}
                    homeHealthTempPartner={homeHealthTempPartner}
                    setHomeHealthTempPartner={setHomeHealthTempPartner}

                    getInitialListingData={getInitialListingData}
                    performExport={performExport}
                    isExportDisabled={isExportDisabledHomeHealthVal}
                  />
                </>
              ) : (
                <p className="black-color my-3">
                  You do not have access to view this page
                </p>
              )}
            </div>
          )}
        </div>
        {lottie && <LottieAnimation />}
        <ToastMessageNew
          type={"success"}
          message={toastMessage}
          setToast={setToastVal}
          toast={toastVal}
        />
        <Modal
          show={helpModal}
          onHide={() => setHelpModal(false)}
          className="ad-adduser-modal sc-copycal-modal stat-info-modal"
          backdrop="static"
          enforceFocus={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="">
            <div className="ad-headerbottom-wrapper">
              <Tabs className="ad-navtab help-nav-tab" activeKey={tabValue}>
                {tabValue === "stats" && (
                  <Tab
                    eventKey="stats"
                    title="Stats and Redraw Order"
                    className="stat-order-wrapper"
                  ></Tab>
                )}
                {tabValue === TYPE_TIMEDDRAW && (
                  <Tab
                    eventKey={TYPE_TIMEDDRAW}
                    title="Timed Draw Stat Orders"
                    className="stat-order-wrapper"
                  ></Tab>
                )}
                {tabValue === TYPE_HOMEHEALTH && (
                  <Tab
                    eventKey={TYPE_HOMEHEALTH}
                    title="Care at Home Stat Orders"
                    className="stat-order-wrapper"
                  ></Tab>
                )}
              </Tabs>
            </div>
            {tabValue === "stats" && (
              <React.Fragment>
                <div className="d-flex st-help-wrapper">
                  <span className="stat-help-icon stat-help-modal">
                    <span className="visually-hidden">Help icon</span>
                  </span>
                  <div className="ps-2">
                    <p className="st-help-title">
                      Color Key{" "}
                      <span className="st-help-text ps-3">
                        Time based on the creation time in SLA
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <p className="black-color font-size-11 ps-2">Pre Drop-Off</p>
                  <ul className="st-bordered">
                    <li className="color-box-container">
                      <span className="color-box st-box-green"></span>
                      <span className="color-info-text">
                        {" "}
                        Order created less than<strong> 120 mins</strong> ago
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-orange"></span>
                      <span className="color-info-text">
                        {" "}
                        More than <strong>120 mins</strong> however less than{" "}
                        <strong>180 mins</strong> since order created date/time
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-red"></span>
                      <span className="color-info-text">
                        {" "}
                        More than <strong>180 mins</strong> since order created
                        date/time
                      </span>
                    </li>
                  </ul>
                  <ul className="st-bordered border-0 pt-1">
                    <li className="color-box-container">
                      <span className="color-box st-box-yellow"></span>
                      <span className="color-info-text">
                        {" "}
                        Order in Redispatch or Unassigned status AND order
                        created less than <strong>120 mins </strong>ago
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-purple"></span>
                      <span className="color-info-text">
                        Order Declined and/or Partial Reorder Status, UTO or
                        incomplete for any reason
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-grey"></span>
                      <span className="color-info-text">
                        Order Notified Client or Cancelled or Unclaimed Orders
                      </span>
                    </li>
                  </ul>
                  <p className="black-color font-size-11 ps-2">Post Drop-Off</p>
                  <ul className="st-bordered">
                    <li className="color-box-container">
                      <span className="color-box st-box-green"></span>
                      <span className="color-info-text">
                        {" "}
                        Order created less than<strong> 270 mins</strong> ago
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-orange"></span>
                      <span className="color-info-text">
                        {" "}
                        More than <strong>240 mins</strong> however less than{" "}
                        <strong>270 mins</strong> since order created date/time
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-red"></span>
                      <span className="color-info-text">
                        {" "}
                        More than <strong>270 mins</strong> since order created
                        date/time
                      </span>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            )}
            {tabValue === TYPE_TIMEDDRAW && (
              <>
                {" "}
                <div className="d-flex st-help-wrapper">
                  <span className="stat-help-icon stat-help-modal">
                    <span className="visually-hidden">Help icon</span>
                  </span>
                  <div className="ps-2">
                    <p className="st-help-title">
                      Color Key{" "}
                      <span className="st-help-text ps-3">
                        Time based on the time scheduled in mobileAHA
                      </span>
                    </p>
                    {/* <p className="st-help-text"></p> */}
                  </div>
                </div>
                <div>
                  <p className="black-color font-size-11 ps-2">Pre Drop-Off</p>
                  <ul className="st-bordered">
                    <li className="color-box-container">
                      <span className="color-box st-box-green"></span>
                      <span className="color-info-text">
                        {" "}
                        More than <strong>15+ mins</strong> to the collection
                        date/time{" "}
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-orange"></span>
                      <span className="color-info-text">
                        {" "}
                        Less than <strong>15 mins</strong> to the collection
                        date/time{" "}
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-red"></span>
                      <span className="color-info-text">
                        {" "}
                        Past the collection date/time
                      </span>
                    </li>
                  </ul>
                  <ul className="st-bordered border-0 pt-1">
                    <li className="color-box-container">
                      <span className="color-box st-box-yellow"></span>
                      <span className="color-info-text">
                        {" "}
                        Order in redispatch or unassigned status AND more than{" "}
                        <strong>15 mins</strong> to the collection date/time
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-purple"></span>
                      <span className="color-info-text">
                        Order Declined and/or Partial Reorder Status, UTO or
                        incomplete for any reason
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-grey"></span>
                      <span className="color-info-text">
                        Order Notified Client or Cancelled or Unclaimed Orders
                      </span>
                    </li>
                  </ul>
                  <p className="black-color font-size-11 ps-2">Post Drop-Off</p>
                  <ul className="st-bordered">
                    <li className="color-box-container">
                      <span className="color-box st-box-green"></span>
                      <span className="color-info-text">
                        {" "}
                        Less than <strong>240 mins</strong> to the collection
                        date/time{" "}
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-orange"></span>
                      <span className="color-info-text">
                        {" "}
                        More than <strong>240 mins</strong> however less than{" "}
                        <strong>270 mins</strong> to the collection date/time{" "}
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-red"></span>
                      <span className="color-info-text">
                        {" "}
                        Past <strong>270 mins</strong> the collection date/time
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            )}
            {tabValue === TYPE_HOMEHEALTH && (
              <>
                {" "}
                <div className="d-flex st-help-wrapper">
                  <span className="stat-help-icon stat-help-modal">
                    <span className="visually-hidden">Help icon</span>
                  </span>
                  <div className="ps-2">
                    <p className="st-help-title">
                      Color Key{" "}
                      <span className="st-help-text ps-3">
                        Time based on the time scheduled in mobileAHA
                      </span>
                    </p>
                    {/* <p className="st-help-text"></p> */}
                  </div>
                </div>
                <div>
                  <p className="black-color font-size-11 ps-2">Pre Drop-Off</p>
                  <ul className="st-bordered">
                    <li className="color-box-container">
                      <span className="color-box st-box-green"></span>
                      <span className="color-info-text">
                        {" "}
                        More than <strong>15+ mins</strong> to the collection
                        date/time{" "}
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-orange"></span>
                      <span className="color-info-text">
                        {" "}
                        Less than <strong>15 mins</strong> to the collection
                        date/time{" "}
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-red"></span>
                      <span className="color-info-text">
                        {" "}
                        Past the collection date/time
                      </span>
                    </li>
                  </ul>
                  <ul className="st-bordered border-0 pt-1">
                    <li className="color-box-container">
                      <span className="color-box st-box-yellow"></span>
                      <span className="color-info-text">
                        {" "}
                        Order in redispatch or unassigned status AND more than{" "}
                        <strong>15 mins</strong> to the collection date/time
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-purple"></span>
                      <span className="color-info-text">
                        Order Declined and/or Partial Reorder Status, UTO or
                        incomplete for any reason
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-grey"></span>
                      <span className="color-info-text">
                        Order Notified Client - Final or Cancelled or Unclaimed Orders or Non-Draw Complete
                      </span>
                    </li>
                  </ul>
                  <p className="black-color font-size-11 ps-2">Post Drop-Off</p>
                  <ul className="st-bordered">
                    <li className="color-box-container">
                      <span className="color-box st-box-green"></span>
                      <span className="color-info-text">
                        {" "}
                        Less than <strong>240 mins</strong> to the collection
                        date/time{" "}
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-orange"></span>
                      <span className="color-info-text">
                        {" "}
                        More than <strong>240 mins</strong> however less than{" "}
                        <strong>270 mins</strong> to the collection date/time{" "}
                      </span>
                    </li>
                    <li className="color-box-container">
                      <span className="color-box st-box-red"></span>
                      <span className="color-info-text">
                        {" "}
                        Past <strong>270 mins</strong> the collection date/time
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>

        <ToastMessageNew
          type={"error"}
          message={toastMessageWarning}
          setToast={setToast}
          toast={toast}
        />
      </AutoRefreshProvider>

    </React.Fragment>
  );
}
