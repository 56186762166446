import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const forgotPassword = createAsyncThunk(
  "account/forgotPassword",
  async (username, { rejectWithValue }) => {
    const response = await ahaApi.Account.forgotPassword(username)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const forgotPasswordList = (state) => state.forgotPassword.value;

export default forgotPasswordSlice.reducer;
