/* eslint-disable no-console */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import {
  PopOverItem,
  PopOverSeparator,
  PopOverSubMenu,
  IconButton,
  Dots,
  useNotificationDispatch,
  useMeetingManager,
  ChannelList,
  ChannelItem,
} from "amazon-chime-sdk-component-library-react";
import {
  Persistence,
  MessageType,
  associateChannelFlow,
  createMemberArn,
  createChannelMembership,
  createChannel,
  updateChannel,
  listChannelMessages,
  sendChannelMessage,
  listChannels,
  listChannelMembershipsForAppInstanceUser,
  deleteChannel,
  describeChannel,
  listChannelMemberships,
  deleteChannelMembership,
  listChannelModerators,
  listChannelBans,
  createChannelBan,
  deleteChannelBan,
  createMeeting,
  createAttendee,
  endMeeting,
  createGetAttendeeCallback,
  describeChannelFlow,
  disassociateChannelFlow,
  updateChannelReadMarker,
  describeChannelMembershipForAppInstanceUser,
  createChannelModerator,
} from "../../api/ChimeAPI";
import { appConfig } from "../../Config";

import { useUserPermission } from "../../providers/UserPermissionProvider";
import mergeArrayOfObjects from "../../utilities/mergeArrays";
import {
  useChatChannelState,
  useChatMessagingState,
} from "../../providers/ChatMessagesProvider";
import { useAppState } from "../../providers/AppStateProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import ModalManager from "./ModalManager";
import {
  GROUP_CHANNEL_TYPE,
  routes,
  INDIVIDUAL_CHANNEL_TYPE,
  setGenerateRandomColor,
  GROUP_CHANNEL_TYPE_TWO,
  ALL_USERS_GROUP,
} from "../../../../app/utils/Constants";
import Avatar from "react-avatar";
import _ from "lodash";

import {
  PresenceAutoStatus,
  PresenceMode,
  PresenceStatusPrefix,
  PUBLISH_INTERVAL,
  toPresenceMap,
  toPresenceMessage,
} from "../../utilities/presence";

import "../../../../assets/styles/pluginchat.scss";
import { useIdentityService } from "../../providers/IdentityProvider";
import { getSubSetUsers, subSetUsersList, subSetUsersLoader } from "../../../landingPage/operations/subSetUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { postToken, tokenList } from "../../../accounts/login/operations/tokenSlice";
import { SetLocalDescriptionTask } from "amazon-chime-sdk-js";
import { getSystemChatPermission, systemChatPermission } from "../../operations/chatPermissionSlice";
import { useNavigate } from "react-router-dom";

const ChannelsWrapper = (props) => {
  const { setChatLoader } = props;
  const navigate = useNavigate();
  const meetingManager = useMeetingManager();
  const dispatch = useNotificationDispatch();
  const dispatchTwo = useDispatch();
  const subSetUsers = useSelector(subSetUsersList);
  const tokenData = useSelector(tokenList);
  const systemChatPermissionResponse = useSelector(systemChatPermission);
  const subSetUsersLoading = useSelector(subSetUsersLoader);
  const [modal, setModal] = useState("");
  const [modalType, setModalType] = useState(GROUP_CHANNEL_TYPE);
  const [selectedMembers, setSelectedMembers] = useState([]); // TODO change to an empty array when using batch api
  const [selectedMultiMembers, setSelectedMultiMembers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [membersForDelete, setMembersForDelete] = useState({});
  const [activeChannelModerators, setActiveChannelModerators] = useState([]);
  const [newMessages, setNewMessages] = useState([]);
  const [cognitoUsers, setCognitoUsers] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [banList, setBanList] = useState([]);
  const [userSearchText, setUserSearchText] = useState("");
  const [roomSearchText, setRoomSearchText] = useState("");
  const { userId, username } = useAuthContext().member;
  const { member, isAuthenticated, pauseTimer, setPauseTimer } = useAuthContext();
  const pauseTimerRef = useRef(pauseTimer);
  const identityClient = useIdentityService();
  const { useCognitoIdp } = useAuthContext();
  const userPermission = useUserPermission();
  const isAuthenticatedRef = useRef(isAuthenticated);
  const messagingUserArn = `${appConfig.appInstanceArn}/user/${userId}`
  const {
    activeChannelRef,
    channelList,
    channelListRef,
    setChannelList,
    setActiveChannel,
    activeChannel,
    activeChannelMemberships,
    setActiveChannelMemberships,
    setAllUserGroupMemberships,
    setChannelMessageToken,
    unreadChannels,
    setUnreadChannels,
    setReadMarker,
    setActiveReadMarker,
    hasMembership,
    meetingInfo,
    setMeetingInfo,
    activeChannelFlow,
    setActiveChannelFlow,
    setSidePanel,
  } = useChatChannelState();
  const { setMessages } = useChatMessagingState();
  const { setAppMeetingInfo } = useAppState();

  useEffect(() => {
    dispatchTwo(getSubSetUsers(false));
    dispatchTwo(getSystemChatPermission());
  }, []);

  useEffect(() => {
    if (subSetUsers && subSetUsers.status === "SUCCESS") {
      setCognitoUsers(subSetUsers.content || []);
    } else if (subSetUsers && subSetUsers && subSetUsers.status === "ERROR") {
    } else if (subSetUsers.status === "ERROR") {
    }
  }, [subSetUsers]);

  useEffect(() => {
    isAuthenticatedRef.current = isAuthenticated;
    pauseTimerRef.current = pauseTimer;
  });
  const fetchChannels = async () => {
    setChatLoader(true);
    const userChannelMemberships =
      await listChannelMembershipsForAppInstanceUser(userId);

    const userChannelList = userChannelMemberships.map((channelMembership) => {
      const { AppInstanceUserMembershipSummary = {}, ChannelSummary } =
        channelMembership;
      return {
        ...ChannelSummary,
        ReadMarkerTimestamp:
          AppInstanceUserMembershipSummary.ReadMarkerTimestamp,
      };
    });
    // const publicChannels = await listChannels(appConfig.appInstanceArn, userId);
    const finalChannels = mergeArrayOfObjects(
      userChannelList,
      [],
      "ChannelArn"
    );
    setChannelList(finalChannels);
    await publishStatusToAllChannels();
    setChatLoader(false);
  };

  // get all channels
  useEffect(() => {
    if (!userId) return;

    fetchChannels();
  }, [userId]);

  // get channel memberships
  useEffect(() => {
    if (activeChannel.ChannelArn) {
      activeChannelRef.current = activeChannel;
      fetchMemberships();

      publishStatusToAllChannels();
    }
  }, [activeChannel.ChannelArn]);

  // track channel presence

  useEffect(() => {
    if (channelList.length > 0) {
      channelListRef.current = channelList;
      startPublishStatusWithInterval();
      fetchAllUsersGroupMemberships(channelList);
    }
  }, [channelList]);

  // get meeting id
  useEffect(() => {
    if (meetingInfo) {
      setModal("JoinMeeting");
    }
  }, [meetingInfo]);

  useEffect(() => {
    if (activeChannel.ChannelArn && modal === "Ban") {
      getBanList();
    }
  }, [activeChannel.ChannelArn, modal]);

  useEffect(() => {
    setIsRestricted(activeChannel.Mode === "RESTRICTED");
  }, [activeChannel]);

  useEffect(() => {
    if (!identityClient) return;
    if (useCognitoIdp) {
      identityClient.setupClient();
    }
  }, [identityClient]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userDetail"));
    const chatPer =
      user &&
      user.permission &&
      user.permission.filter((val) => {
        return val.page === "chats";
      });
    if (chatPer && chatPer.length > 0) {
      setAdmin(true);
    } else {
      setAdmin(false);
    }
  }, []);

  function startPublishStatusWithInterval() {
    let publishTimeout;
    (async function publishStatusWithInterval() {
      if (!isAuthenticatedRef.current) {
        clearTimeout(publishTimeout);
        return;
      }
      if (!pauseTimerRef.current) {
      await publishStatusToAllChannels();
      }
      publishTimeout = setTimeout(publishStatusWithInterval, PUBLISH_INTERVAL);
    })();
  }

  function computeAutoStatusForAChannel(channel) {
    let persistedPresence;
    try {
      persistedPresence = JSON.parse(channel.Metadata || "{}").Presence || [];
    } catch (e) {
      persistedPresence = [];
    }

    const isCustomStatus =
      persistedPresence && persistedPresence.filter((p) => p.u === userId)[0];
    if (isCustomStatus) {
      return null;
    }

    if (window.location.pathname.includes(routes.MEETING)) {
      return PresenceAutoStatus.Busy;
    } else if (activeChannelRef.current && activeChannelRef.current.ChannelArn) {
      return PresenceAutoStatus.Online;
    } else {
      return PresenceAutoStatus.Idle;
    }
  }

  async function publishStatusToAllChannels() {
    const channel = channelListRef.current.filter(ch => ch.Name === ALL_USERS_GROUP) ?
      channelListRef.current.filter(ch => ch.Name === ALL_USERS_GROUP)[0] || {} : {};
    if (channel && Object.keys(channel).length > 0) {
      const status = computeAutoStatusForAChannel(channel);
      if (status) {
        try {
          const response = await sendChannelMessage(
            channel.ChannelArn,
            toPresenceMessage(PresenceMode.Auto, status, true),
            Persistence.NON_PERSISTENT,
            MessageType.CONTROL,
            member, null, (val) => setPauseTimer(val)
          )
        } catch (err) {
          setPauseTimer(true);
          console.log(err, 'sendChannelMessage');
        }
      }
    }
  }

  const getBanList = async () => {
    const banListResponse = await listChannelBans(
      activeChannel.ChannelArn,
      50,
      null,
      userId
    );
    setBanList(banListResponse.ChannelBans.map((m) => m.Member.Arn));
  };

  const banUser = async (memberArn) => {
    try {
      await createChannelBan(activeChannel.ChannelArn, memberArn, userId);
      const newBanList = banList.concat(...banList, memberArn);
      setBanList(newBanList);
      dispatch({
        type: 0,
        payload: {
          message: "Successfully banned user.",
          severity: "success",
        },
      });
    } catch {
      dispatch({
        type: 0,
        payload: {
          message: "Error, unable to perform this action.",
          severity: "error",
        },
      });
    }
  };

  const unbanUser = async (memberArn) => {
    await deleteChannelBan(activeChannel.ChannelArn, memberArn, userId);
    await getBanList();
  };

  const onCreateChannel = async (e, newName, mode, privacy, type = "group") => {
    e.preventDefault();
    type !== "chat" && setLoader(true);
    let errors = [];
    let currentChannelRes = {};
    if (!newName) {
      dispatch({
        type: 0,
        payload: {
          message: "Error, room name cannot be blank.",
          severity: "error",
        },
      });
    } else {
      const gname = `${modalType}${newName}`;
      const channelArn = await createChannel(
        appConfig.appInstanceArn,
        null,
        gname,
        mode,
        privacy,
        userId
      );
      if (channelArn) {
        const channel = await describeChannel(channelArn, userId);
        type !== "chat" && setModal("");
        type !== "chat" && setModalType(GROUP_CHANNEL_TYPE);
        if (channel) {
          setChannelList([...channelList, channel]);
          type !== "chat" &&
            dispatch({
              type: 0,
              payload: {
                message: "Successfully created room.",
                severity: "success",
                autoClose: true,
              },
            });
          currentChannelRes = channel;
          setActiveChannel(channel);
          channelIdChangeHandler(channel.ChannelArn);
          fetchChannels();
        } else {
          type !== "chat"
            ? dispatch({
              type: 0,
              payload: {
                message: "Error, could not retrieve room information.",
                severity: "error",
                autoClose: false,
              },
            })
            : errors.push("Error, could not retrieve room information.");
        }
      } else {
        type !== "chat"
          ? dispatch({
            type: 0,
            payload: {
              message: "Error, could not create new room.",
              severity: "error",
              autoClose: false,
            },
          })
          : errors.push("Error, could not retrieve room information.");
      }
    }
    type !== "chat" && setLoader(false);
    return type === "chat" ? { currentChannelRes, errors } : {};
  };
  const onUpdateChannelReadMarker = async (channelArn) => {
    await updateChannelReadMarker(appConfig.appInstanceArn, channelArn, userId);
    await onDescribeChannelMembershipForAppInstanceUser(channelArn);
  };

  const onDescribeChannelMembershipForAppInstanceUser = async (channelArn) => {
    let channels = channelList;
    let channelIndex = channels.findIndex((ch) => ch.ChannelArn === channelArn);

    const a =
      (await describeChannelMembershipForAppInstanceUser(
        appConfig.appInstanceArn,
        channelArn,
        userId
      )) || {};
    const { AppInstanceUserMembershipSummary = {}, ChannelSummary = {} } = a;
    const { ReadMarkerTimestamp } = AppInstanceUserMembershipSummary;
    const { LastMessageTimestamp } = ChannelSummary;
    channels[channelIndex] = {
      ...channels[channelIndex],
      ReadMarkerTimestamp,
      LastMessageTimestamp,
    };
    setReadMarker(ReadMarkerTimestamp);
    setChannelList(channels);
    // let index = temp.findIndex((val) => val === a.ChannelSummary.ChannelArn);
    // if (index > 0) {
    //   temp.splice(index, 1);
    //   setNewMessages(temp);
    // }
  };
  // const joinMeeting = async (e) => {
  //   e.preventDefault();

  //   if (activeChannel.Metadata) {
  //     let metadata = JSON.parse(activeChannel.Metadata);
  //     let meeting = metadata.meeting;

  //     // Create own attendee and join meeting
  //     meetingManager.getAttendee = createGetAttendeeCallback();
  //     const { JoinInfo } = await createAttendee(
  //       member.username,
  //       member.userId,
  //       activeChannel.ChannelArn,
  //       meeting
  //     );
  //     await meetingManager.join({
  //       meetingInfo: JoinInfo.Meeting,
  //       attendeeInfo: JoinInfo.Attendee,
  //     });

  //     setAppMeetingInfo(JoinInfo.Meeting.MeetingId, member.username);
  //     navigate(routes.DEVICE);
  //   }
  // };

  // const startMeeting = async (e) => {
  //   e.preventDefault();

  //   let { Name = "" } = activeChannel || {};
  //   if (Name.includes(GROUP_CHANNEL_TYPE)) {
  //     Name = Name.replace(GROUP_CHANNEL_TYPE, "");
  //   } else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
  //     Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
  //     let allUsers = Name.split(", ");
  //     if (allUsers.includes(username)) {
  //       allUsers = allUsers.filter((user) => user !== username);
  //       Name = allUsers.join(", ");
  //     }
  //   }

  //   let meetingName = `${Name} Instant Meeting`;

  //   // Create Meeting Channel and Memberships from existing Channel
  //   const meetingChannelArn = await createChannel(
  //     appConfig.appInstanceArn,
  //     null,
  //     meetingName,
  //     "RESTRICTED",
  //     "PRIVATE",
  //     userId
  //   );
  //   channelIdChangeHandler(meetingChannelArn);

  //   const memberships = activeChannelMemberships;
  //   memberships.forEach((membership) =>
  //     createChannelMembership(meetingChannelArn, membership.Member.Arn, userId)
  //   );

  //   // Create meeting and attendee for self
  //   meetingManager.getAttendee = createGetAttendeeCallback();
  //   const { JoinInfo } = await createMeeting(
  //     member.username,
  //     member.userId,
  //     meetingChannelArn
  //   );
  //   await meetingManager.join({
  //     meetingInfo: JoinInfo.Meeting,
  //     attendeeInfo: JoinInfo.Attendee,
  //   });

  //   const meetingId = JoinInfo.Meeting.MeetingId;
  //   const meeting = JSON.stringify(JoinInfo.Meeting);

  //   // Update meeting channel metadata with meeting info
  //   let meetingChannelmetadata = {
  //     isMeeting: true,
  //     meeting: meeting,
  //   };

  //   await updateChannel(
  //     meetingChannelArn,
  //     meetingName,
  //     "RESTRICTED",
  //     JSON.stringify(meetingChannelmetadata),
  //     userId
  //   );

  //   // Send the meeting info as a chat message in the existing channel
  //   const options = {};
  //   options.Metadata = `{"isMeetingInfo":true}`;
  //   let meetingInfoMessage = {
  //     meeting: meeting,
  //     channelArn: meetingChannelArn,
  //     channelName: meetingName,
  //     inviter: member.username,
  //   };
  //   await sendChannelMessage(
  //     activeChannel.ChannelArn,
  //     JSON.stringify(meetingInfoMessage),
  //     Persistence.NON_PERSISTENT,
  //     MessageType.STANDARD,
  //     member,
  //     options
  //   );
  //   setAppMeetingInfo(meetingId, member.username);
  //   navigate(routes.DEVICE);
  // };

  const setCustomStatus = async (e, status) => {
    e.preventDefault();
    await changeStatus(PresenceMode.Custom, status);
  };

  const changeStatus = async (type, status) => {
    await sendChannelMessage(
      activeChannel.ChannelArn,
      toPresenceMessage(type, status, true),
      Persistence.NON_PERSISTENT,
      MessageType.CONTROL,
      member
    );

    const isAutomatic = type === PresenceMode.Auto;
    const persistedPresenceMap = toPresenceMap(activeChannel.Metadata);
    const customPresenceExists =
      persistedPresenceMap && persistedPresenceMap[userId];
    if (!isAutomatic || customPresenceExists) {
      if (!activeChannel.ChannelFlowArn) {
        dispatch({
          type: 0,
          payload: {
            message: "Error, enable presence room flow first.",
            severity: "error",
          },
        });
        return;
      }

      // persist presence using standard message and channel flows
      const options = {};
      options.Metadata = JSON.stringify({
        IsPresenceInfo: true,
        Status: toPresenceMessage(type, status, false),
      });
      await sendChannelMessage(
        activeChannel.ChannelArn,
        `changed status to ${status}`,
        Persistence.PERSISTENT,
        MessageType.STANDARD,
        member,
        options
      );
    }
  };

  const joinChannel = async (e) => {
    e.preventDefault();
    const membership = await createChannelMembership(
      activeChannel.ChannelArn,
      `${appConfig.appInstanceArn}/user/${userId}`,
      userId
    );
    if (membership) {
      const memberships = activeChannelMemberships;
      memberships.push({ Member: membership });
      setActiveChannelMemberships(memberships);
      channelIdChangeHandler(activeChannel.ChannelArn);
      let { Name = "" } = activeChannel || {};
      if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
        Name = Name.replace(GROUP_CHANNEL_TYPE, "");
        Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, '');
      } else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
        Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
        let allUsers = Name.split(", ");
        if (allUsers.includes(username)) {
          allUsers = allUsers.filter((user) => user !== username);
          Name = allUsers.join(", ");
        }
      }
      dispatch({
        type: 0,
        payload: {
          message: `Successfully joined`,
          severity: "success",
          autoClose: true,
        },
      });
    } else {
      dispatch({
        type: 0,
        payload: {
          message: "Error occurred. Unable to join room.",
          severity: "error",
          autoClose: true,
        },
      });
    }
  };

  const onAddMember = async (e, type = "group") => {
    e && e.preventDefault();
    setLoader(true);
    if (type === "chat") {
      if (!selectedMultiMembers.length) {
        dispatch({
          type: 0,
          payload: {
            message: "Error, user name or room name cannot be blank.",
            severity: "error",
          },
        });
      } else {
        // check if chat with selected member is there or not
        if (selectedMultiMembers.length === 1) {
          const selectedUser = selectedMultiMembers[0] || {};
          const chatList = channelList.filter((val) =>
            val.Name.includes(INDIVIDUAL_CHANNEL_TYPE)
          );
          let listOfChatWithSingleUser = [];
          chatList.forEach((ch) => {
            let { Name = "" } = ch;
            Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
            let allUsers = Name.split(", ");
            if (allUsers.includes(username)) {
              allUsers = allUsers.filter((user) => user !== username);
            }
            if (
              allUsers.length === 1 &&
              allUsers.includes(selectedUser.label)
            ) {
              listOfChatWithSingleUser.push(ch);
            }
          });
          if (listOfChatWithSingleUser.length === 0) {
            await onAddMemberChat(e, type);
          } else if (listOfChatWithSingleUser.length === 1) {
            const currentChannel = listOfChatWithSingleUser[0] || {};
            channelIdChangeHandler(currentChannel.ChannelArn);
            onUpdateChannelReadMarker(currentChannel.ChannelArn);
            onDescribeChannelMembershipForAppInstanceUser(
              currentChannel.ChannelArn
            );
            setModal("");
          }
        } else {
          await onAddMemberChat(e, type);
        }
      }
    } else {
      if (!selectedMembers.length) {
        dispatch({
          type: 0,
          payload: {
            message: "Error, user name cannot be blank.",
            severity: "error",
          },
        });
      } else {
        await onAddMemberGroup();
      }
    }
    setLoader(false);
  };

  const onAddMembertoExisting = async (e) => {
    e.preventDefault();
    try {
      const tempChannelNameOne = activeChannel.Name || "";
      const tempChannelNameTwo = selectedMultiMembers
        .map((member) => member.label)
        .join(", ");
      let channelName = tempChannelNameOne + ", " + tempChannelNameTwo;
      if (channelName.length <= 256) {
        let membersResult =
          (await addMultipleMembers(
            selectedMultiMembers,
            activeChannel,
            appConfig,
            userId,
            "chat"
          )) || [];
        membersResult = membersResult.map((res) => {
          return { Member: res };
        });
        let memberships = activeChannelMemberships;
        memberships = [...memberships, ...membersResult];
        setActiveChannelMemberships(memberships);
        channelIdChangeHandler(activeChannel.ChannelArn);
        //rename channel name
        updateChannel(
          activeChannel.ChannelArn,
          channelName,
          activeChannel.Mode,
          activeChannel.Metadata,
          userId
        );

        dispatch({
          type: 0,
          payload: {
            message: `Members added successfully`,
            severity: "success",
            autoClose: true,
          },
        });
        setModal("");
      } else {
        dispatch({
          type: 0,
          payload: {
            message: `Cannot add these many members, please remove some of them`,
            severity: "error",
            autoClose: true,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: 0,
        payload: {
          message: "Error occurred. Member not added.",
          severity: "error",
          autoClose: true,
        },
      });
    }
  };

  const onAddMemberChat = async (e, type = "group") => {
    let currentChannel = {};
    try {
      let tempChannelNameOne = member.username;
      let tempChannelNameTwo = selectedMultiMembers
        .map((member) => member.label)
        .join(", ");
      let channelName = tempChannelNameOne + ", " + tempChannelNameTwo;
      if (channelName.length <= 256) {
        const channelRes =
          (await onCreateChannel(
            e,
            channelName,
            "RESTRICTED",
            "PRIVATE",
            type
          )) || {};
        const { errors = [], currentChannelRes = {} } = channelRes;
        currentChannel = currentChannelRes;
        if (errors.length > 0) {
          dispatch({
            type: 0,
            payload: {
              message: `Something went wrong`,
              severity: "success",
              autoClose: true,
            },
          });
          return;
        }
        const membersResult = await addMultipleMembers(
          selectedMultiMembers,
          currentChannel,
          appConfig,
          userId,
          type
        );
        let memberships = [];
        try {
          memberships = (await fetchMemberships("chat", currentChannel)) || [];
        } catch {
          memberships = activeChannelMemberships;
        }
        setActiveChannelMemberships(memberships);
        channelIdChangeHandler(currentChannel.ChannelArn);
        dispatch({
          type: 0,
          payload: {
            message: `Members added successfully`,
            severity: "success",
            autoClose: true,
          },
        });
      } else {
        dispatch({
          type: 0,
          payload: {
            message: `Cannot add these many members, please remove some of them`,
            severity: "error",
            autoClose: true,
          },
        });
      }
      setModal("");
    } catch (err) {
      !_.isEmpty(currentChannel) &&
        handleChannelDeletion(e, currentChannel.ChannelArn, "chat");
      dispatch({
        type: 0,
        payload: {
          message: err.message,
          severity: "error",
          autoClose: true,
        },
      });
    }
  };

  const onAddMemberGroup = async () => {
    try {
      const membersResult =
        (await addMultipleMembers(
          selectedMembers,
          activeChannel,
          appConfig,
          userId,
          "group"
        )) || [];
      // const membership = await createChannelMembership(
      //   activeChannel.ChannelArn,
      //   `${appConfig.appInstanceArn}/user/${selectedMembers.value}`,
      //   userId
      // );
      let memberships = activeChannelMemberships;
      membersResult.forEach((mem) => memberships.push({ Member: mem }));
      // memberships.push({ Member: membership });
      setActiveChannelMemberships(memberships);
      channelIdChangeHandler(activeChannel.ChannelArn);
      dispatch({
        type: 0,
        payload: {
          message: `New members added successfully`,
          severity: "success",
          autoClose: true,
        },
      });
      setModal("");
    } catch (err) {
      dispatch({
        type: 0,
        payload: {
          message: "Error occurred. Member not added.",
          severity: "error",
          autoClose: true,
        },
      });
    }
  };

  const addMultipleMembers = async (
    members,
    currentChannel,
    appConfig,
    userId,
    type
  ) => {
    const allDataPromises = members.map(async (member) => {
      const result = await createChannelMembership(
        currentChannel.ChannelArn,
        `${appConfig.appInstanceArn}/user/${member.value}`,
        userId
      );
      if (type === "chat") {
        createChannelModerator(currentChannel.ChannelArn, userId, member.value);
      }
      return result;
    });
    const allData = await Promise.all(allDataPromises);
    return allData;
  };

  const onManageChannelFlow = async () => {
    if (!membersForDelete) {
      dispatch({
        type: 0,
        payload: {
          message: "Error, room flow name cannot be blank.",
          severity: "error",
        },
      });
      return;
    }

    if (membersForDelete.value === "None") {
      try {
        await disassociateChannelFlow(
          activeChannel.ChannelArn,
          activeChannelFlow.ChannelFlowArn,
          userId
        );
        setActiveChannelFlow({});
        let { Name = "" } = activeChannel || {};
        if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
          Name = Name.replace(GROUP_CHANNEL_TYPE, "");
          Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, "");
        } else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
          Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
          let allUsers = Name.split(", ");
          if (allUsers.includes(username)) {
            allUsers = allUsers.filter((user) => user !== username);
            Name = allUsers.join(", ");
          }
        }
        dispatch({
          type: 0,
          payload: {
            message: `Room flow successfully removed from ${Name}`,
            severity: "success",
            autoClose: true,
          },
        });
        setModal("");
      } catch {
        let { Name = "" } = activeChannel || {};
        if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
          Name = Name.replace(GROUP_CHANNEL_TYPE, "");
          Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, '');
        } else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
          Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
          let allUsers = Name.split(", ");
          if (allUsers.includes(username)) {
            allUsers = allUsers.filter((user) => user !== username);
            Name = allUsers.join(", ");
          }
        }
        dispatch({
          type: 0,
          payload: {
            message: `Error occurred. Room flow could not removed from ${Name}`,
            severity: "error",
            autoClose: true,
          },
        });

      }
      return;
    }

    try {
      await associateChannelFlow(
        activeChannel.ChannelArn,
        membersForDelete.value,
        userId
      );

      let flow = {
        Name: membersForDelete.label,
        ChannelFlowArn: membersForDelete.value,
      };
      setActiveChannelFlow(flow);

      let { Name = "" } = activeChannel || {};
      if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
        Name = Name.replace(GROUP_CHANNEL_TYPE, "");
        Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, "");
      } else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
        Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
        let allUsers = Name.split(", ");
        if (allUsers.includes(username)) {
          allUsers = allUsers.filter((user) => user !== username);
          Name = allUsers.join(", ");
        }
      }
      dispatch({
        type: 0,
        payload: {
          message: `Room flow ${membersForDelete.label} successfully associated with ${Name}`,
          severity: "success",
          autoClose: true,
        },
      });
      setModal("");
    } catch {
      let { Name = "" } = activeChannel || {};
      if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
        Name = Name.replace(GROUP_CHANNEL_TYPE, "");
        Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, "");
      } else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
        Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
        let allUsers = Name.split(", ");
        if (allUsers.includes(username)) {
          allUsers = allUsers.filter((user) => user !== username);
          Name = allUsers.join(", ");
        }
      }
      dispatch({
        type: 0,
        payload: {
          message: `Error occurred. Room flow not associated with room ${Name}`,
          severity: "error",
          autoClose: true,
        },
      });
    }
  };

  const loadChannelFlow = async (channel) => {
    if (channel.ChannelFlowArn == null) {
      setActiveChannelFlow({});
    } else {
      let flow = await describeChannelFlow(channel.ChannelFlowArn);
      setActiveChannelFlow(flow);
    }
  };

  const channelIdChangeHandler = async (channelArn) => {
    if (activeChannel.ChannelArn === channelArn) return;
    let mods = [];
    setActiveChannelModerators([]);
    mods = await listChannelModerators(channelArn, userId);
    setActiveChannelModerators(mods);

    const isModerator =
      mods?.find((moderator) => moderator.Moderator.Arn === messagingUserArn) ||
      false;

    // Assessing user role for given channel
    userPermission.setRole(isModerator ? "moderator" : "user");

    let newMessages = {};
    let channel = {};
    newMessages = await listChannelMessages(channelArn, userId);
    setMessages(newMessages.Messages);
    setChannelMessageToken(newMessages.NextToken);

    channel = await describeChannel(channelArn, userId);
    setActiveChannel(channel);
    await loadChannelFlow(channel);
    setUnreadChannels(unreadChannels.filter((c) => c !== channelArn));
  };

  const handleChannelDeletion = async (
    e,
    channelArn,
    channelMetadata,
    type = "group"
  ) => {
    e.preventDefault();

    const response = await deleteChannel(channelArn, userId);
    const newChannelList = channelList.filter(
      (channel) => channel.ChannelArn !== channelArn
    );
    setChannelList(newChannelList);
    setActiveChannel("");
    setMessages([]);
    setModal("");
    dispatch({
      type: 0,
      payload: {
        message: `${type === "chat" ? "Chat" : "Room"} successfully deleted.`,
        severity: "success",
        autoClose: true,
      },
    });

    // If the channel was a meeting channel, end the associated meeting
    if (channelMetadata) {
      const metadata = JSON.parse(channelMetadata);
      if (metadata.isMeeting) {
        const meeting = JSON.parse(metadata.meeting);
        await endMeeting(meeting.MeetingId);
      }
    }
  };

  const formatMemberships = (memArr) =>
    memArr.flatMap((m) =>
      m.Member.Arn !== messagingUserArn
        ? [{ value: m.Member.Arn, label: m.Member.Name }]
        : []
    );

  const fetchMemberships = async (type = "group", currentChannel = {}) => {
    let memberships = await listChannelMemberships(
      type === "chat" ? currentChannel.ChannelArn : activeChannel.ChannelArn,
      userId
    );
    setActiveChannelMemberships(memberships);
    return memberships;
  };

  const fetchAllUsersGroupMemberships = async (channels = []) => {
    const currentChannel = channels.filter(ch => ch.Name === ALL_USERS_GROUP) ?
      channels.filter(ch => ch.Name === ALL_USERS_GROUP)[0] || {} : {};
    let memberships = [];
    if (currentChannel.ChannelArn) {
      memberships = await listChannelMemberships(currentChannel.ChannelArn, userId);
      setAllUserGroupMemberships(memberships);
    }
    return memberships;
  };

  const handlePickerChange = (changes) => {
    setSelectedMembers(changes);
  };

  const handleDeletePickerChange = (changes) => {
    setMembersForDelete(changes);
  };

  const handleMultiPickerChange = (changes) => {
    setSelectedMultiMembers(changes);
  };

  const handleJoinMeeting = async (e, meeting, meetingChannelArn) => {
    e.preventDefault();

    await channelIdChangeHandler(meetingChannelArn);

    meetingManager.getAttendee = createGetAttendeeCallback();
    const { JoinInfo } = await createAttendee(
      member.username,
      member.userId,
      meetingChannelArn,
      meeting
    );
    await meetingManager.join({
      meetingInfo: JoinInfo.Meeting,
      attendeeInfo: JoinInfo.Attendee,
    });

    setAppMeetingInfo(JoinInfo.Meeting.MeetingId, member.username);

    setModal("");
    setMeetingInfo(null);

    navigate(routes.DEVICE);
  };

  const handleMessageAll = async (e, meetingChannelArn) => {
    e.preventDefault();

    setModal("");
    setMeetingInfo(null);

    await channelIdChangeHandler(meetingChannelArn);
  };

  const handleDeleteMemberships = async (type = "group") => {
    try {
      deleteChannelMembership(
        activeChannel.ChannelArn,
        membersForDelete.value,
        userId
      );
      if (type === "chat") {
        //rename channel name
        let tempChannelNameOne = activeChannel.Name || "";
        if (tempChannelNameOne.includes(membersForDelete.label)) {
          tempChannelNameOne = tempChannelNameOne.replace(
            `, ${membersForDelete.label}`,
            ""
          );
        }
        updateChannel(
          activeChannel.ChannelArn,
          tempChannelNameOne,
          activeChannel.Mode,
          activeChannel.Metadata,
          userId
        );
      }
      dispatch({
        type: 0,
        payload: {
          message: `Successfully removed members from the ${type === "chat" ? "chat" : "room"
            }.`,
          severity: "success",
          autoClose: true,
        },
      });
      setMembersForDelete({});
    } catch (err) {
      dispatch({
        type: 0,
        payload: {
          message: "Error, unable to remove members.",
          severity: "error",
        },
      });
    }
  };

  const handleLeaveChannel = async (type = "group") => {
    try {
      await deleteChannelMembership(
        activeChannel.ChannelArn,
        createMemberArn(userId),
        userId
      );
      if (type === "chat") {
        //rename channel name
        let tempChannelNameOne = activeChannel.Name || "";
        tempChannelNameOne = tempChannelNameOne.replace(INDIVIDUAL_CHANNEL_TYPE, "");
        let allUsers = tempChannelNameOne.split(", ");
        if (allUsers.includes(username)) {
          allUsers = allUsers.filter((user) => user !== username);
        }
        tempChannelNameOne = INDIVIDUAL_CHANNEL_TYPE + allUsers.join(", ");
        updateChannel(
          activeChannel.ChannelArn,
          tempChannelNameOne,
          activeChannel.Mode,
          activeChannel.Metadata,
          userId
        );
      }
      dispatch({
        type: 0,
        payload: {
          message: `Successfully left ${type === "chat" ? "chat" : "room"}.`,
          severity: "success",
          autoClose: true,
        },
      });
      setMembersForDelete({});
    } catch (err) {
      dispatch({
        type: 0,
        payload: {
          message: `Error, unable to leave the ${type === "chat" ? "chat" : "room"
            }.`,
          severity: "error",
        },
      });
    }
  };

  const [isRestricted, setIsRestricted] = useState(
    activeChannel.Mode === "RESTRICTED"
  );

  const loadUserActions = (role, channel, type = "group") => {
    let map;
    let status;

    let hasChatAccess = true;
    try {
      map =
        channel.Metadata &&
        JSON.parse(channel.Metadata).Presence &&
        Object.fromEntries(
          JSON.parse(channel.Metadata).Presence?.map((entry) => [
            entry.u,
            entry.s,
          ])
        );
      const { Name: channelName = "", ChannelArn } = channel || {};
      const { content = {} } = systemChatPermissionResponse || {};
      const { send = [] } = content || {};
      if (channelName.includes(GROUP_CHANNEL_TYPE_TWO)) {
        hasChatAccess = false;
      }
    } catch (err) {
      map = null;
    }
    status = (map && map[member.userId]) || PresenceStatusPrefix.Auto;
    const presenceStatusOption = (
      <PopOverSubMenu
        className={"ch-sts-popover-toggle"}
        as="button"
        key="presence_status"
        text={"Change status"}
      >
        <PopOverItem
          as="button"
          onClick={() => changeStatus(PresenceMode.Auto, "Online")}
          checked={status.startsWith(PresenceStatusPrefix.Auto)}
          children={<span>Automatic</span>}
        />
        <PopOverItem
          as="button"
          onClick={() => changeStatus(PresenceMode.Wfh, "Working from Home")}
          checked={status.startsWith(PresenceStatusPrefix.Wfh)}
          children={<span>Working from Home</span>}
        />
        <PopOverItem
          as="button"
          onClick={() => setModal("CustomStatus")}
          checked={status.startsWith(PresenceStatusPrefix.Custom)}
          children={
            <span>
              Custom{" "}
              {status.startsWith(PresenceStatusPrefix.Custom)
                ? "(" + status.substr(status.indexOf("|") + 1) + ")"
                : ""}
            </span>
          }
        />
      </PopOverSubMenu>
    );

    const joinChannelOption = (
      <PopOverItem key="join_channel" as="button" onClick={joinChannel}>
        <span>Join {type === "chat" ? "Chat" : "Room"}</span>
      </PopOverItem>
    );
    const viewDetailsOption = (
      <PopOverItem
        key="view_channel_details"
        as="button"
        onClick={() => setModal("ViewDetails")}
      >
        <span>View {type === "chat" ? "chat" : "room"} details</span>
      </PopOverItem>
    );
    const manageChannelFlowOption = (
      <PopOverItem
        key="manage_channel_flow"
        as="button"
        onClick={() => setModal("ManageChannelFlow")}
      >
        <span>Manage channel flow</span>
      </PopOverItem>
    );
    const editChannelOption = (
      <PopOverItem
        key="edit_channel"
        as="button"
        onClick={() => setModal("EditChannel")}
      >
        <span>Edit {type === "chat" ? "chat" : "room"}</span>
      </PopOverItem>
    );
    const viewMembersOption = (
      <PopOverItem
        key="view_members"
        as="button"
        onClick={() => setModal("ViewMembers")}
      >
        <span>View members</span>
      </PopOverItem>
    );
    const addMembersOption = (
      <PopOverItem
        key="add_member"
        as="button"
        onClick={() => {
          setModal(type === "chat" ? "AddMemberToExisting" : "AddMembers");
          setSelectedMultiMembers([]);
        }
        }
      >
        <span>Add members</span>
      </PopOverItem>
    );
    const manageMembersOption = (
      <PopOverItem
        key="manage_members"
        as="button"
        onClick={() =>
          setModal(type === "chat" ? "ChatManageMembers" : "ManageMembers")
        }
      >
        <span>Manage members</span>
      </PopOverItem>
    );
    const banOrAllowOption = (
      <PopOverItem key="ban_allow" as="button" onClick={() => setModal("Ban")}>
        <span>Ban/Allow members</span>
      </PopOverItem>
    );
    // const startMeetingOption = (
    //   <PopOverItem key="start_meeting" as="button" onClick={startMeeting}>
    //     <span>Start meeting</span>
    //   </PopOverItem>
    // );
    // const joinMeetingOption = (
    //   <PopOverItem key="join_meeting" as="button" onClick={joinMeeting}>
    //     <span>Join meeting</span>
    //   </PopOverItem>
    // );
    const leaveChannelOption = (
      <PopOverItem
        key="leave_channel"
        as="button"
        onClick={() =>
          setModal(type === "chat" ? "ChatLeaveChannel" : "LeaveChannel")
        }
      >
        <span>Leave {type === "chat" ? "chat" : "room"}</span>
      </PopOverItem>
    );
    const deleteChannelOption = (
      <PopOverItem
        key="delete_channel"
        as="button"
        onClick={() =>
          setModal(type === "chat" ? "ChatDeleteChannel" : "DeleteChannel")
        }
      >
        <span>Delete {type === "chat" ? "chat" : "room"}</span>
      </PopOverItem>
    );

    const meetingModeratorActions = [
      // viewDetailsOption,
      // <PopOverSeparator key="separator1" className="separator" />,
      addMembersOption,
      manageMembersOption,
      <PopOverSeparator key="separator2" className="separator" />,
      // joinMeetingOption,
      // <PopOverSeparator key="separator3" className="separator" />,
      leaveChannelOption,
      deleteChannelOption,
    ];
    const meetingMemberActions = [
      // viewDetailsOption,
      // <PopOverSeparator key="separator1" className="separator" />,
      viewMembersOption,
      // <PopOverSeparator key="separator2" className="separator" />,
      // joinMeetingOption,
      <PopOverSeparator key="separator3" className="separator" />,
      hasChatAccess && leaveChannelOption,
    ];
    const moderatorActions = [
      // presenceStatusOption,
      // <PopOverSeparator key="separator1" className="separator" />,
      // viewDetailsOption,
      admin && editChannelOption,
      <PopOverSeparator key="separator2" className="separator" />,
      admin && addMembersOption,
      admin && manageMembersOption,
      // banOrAllowOption,
      <PopOverSeparator key="separator3" className="separator" />,
      // manageChannelFlowOption,
      // <PopOverSeparator key="separator4" className="separator" />,
      // startMeetingOption,
      // <PopOverSeparator key="separator5" className="separator" />,
      !admin && viewMembersOption,
      leaveChannelOption,
      admin && deleteChannelOption,
    ];
    const moderatorActionsForChat = [
      // presenceStatusOption,
      // <PopOverSeparator key="separator1" className="separator" />,
      // viewDetailsOption,
      // editChannelOption,
      // <PopOverSeparator key="separator2" className="separator" />,
      addMembersOption,
      manageMembersOption,
      // banOrAllowOption,
      <PopOverSeparator key="separator3" className="separator" />,
      // manageChannelFlowOption,
      // <PopOverSeparator key="separator4" className="separator" />,
      // startMeetingOption,
      // <PopOverSeparator key="separator5" className="separator" />,
      leaveChannelOption,
      deleteChannelOption,
    ];
    const moderatorActionsForChatTwo = [
      addMembersOption,
      <PopOverSeparator key="separator3" className="separator" />,
      // leaveChannelOption,
      deleteChannelOption,
    ];
    const restrictedMemberActions = [
      // presenceStatusOption,
      // <PopOverSeparator key="separator1" className="separator" />,
      // viewDetailsOption,
      // <PopOverSeparator key="separator2" className="separator" />,
      viewMembersOption,
      // <PopOverSeparator key="separator3" className="separator" />,
      // startMeetingOption,
      // <PopOverSeparator key="separator4" className="separator" />,
      hasChatAccess && deleteChannelOption,
    ];
    const unrestrictedMemberActions = [
      // presenceStatusOption,
      // <PopOverSeparator key="separator1" className="separator" />,
      // viewDetailsOption,
      <PopOverSeparator key="separator2" className="separator" />,
      viewMembersOption,
      addMembersOption,
      <PopOverSeparator key="separator3" className="separator" />,
      deleteChannelOption,
    ];
    const noMeetingModeratorActions = [
      // viewDetailsOption,
      editChannelOption,
      <PopOverSeparator key="separator1" className="separator" />,
      addMembersOption,
      manageMembersOption,
      banOrAllowOption,
      // <PopOverSeparator key="separator2" className="separator" />,
      // manageChannelFlowOption,
      <PopOverSeparator key="separator3" className="separator" />,
      leaveChannelOption,
      deleteChannelOption,
    ];
    const noMeetingRestrictedMemberActions = [
      // viewDetailsOption,
      // <PopOverSeparator key="separator1" className="separator" />,
      viewMembersOption,
      <PopOverSeparator key="separator2" className="separator" />,
      hasChatAccess && leaveChannelOption,
    ];
    const noMeetingUnrestrictedMemberActions = [
      // viewDetailsOption,
      // <PopOverSeparator key="separator1" className="separator" />,
      viewMembersOption,
      addMembersOption,
      // <PopOverSeparator key="separator2" className="separator" />,
      // startMeetingOption,
      <PopOverSeparator key="separator3" className="separator" />,
      leaveChannelOption,
    ];
    const nonMemberActions = [
      joinChannelOption,
      // viewDetailsOption,
      viewMembersOption,
    ];

    if (!hasMembership) {
      return nonMemberActions;
    }

    if (appConfig.apiGatewayInvokeUrl) {
      if (channel.Metadata) {
        let metadata = JSON.parse(channel.Metadata);
        if (metadata.isMeeting) {
          return role === "moderator"
            ? meetingModeratorActions
            : meetingMemberActions;
        }
      }

      let { Name = "" } = channel;
      let allUsers = [];
      if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
        Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
        allUsers = Name.split(", ");
        if (allUsers.includes(username)) {
          allUsers = allUsers.filter((user) => user !== username);
        }
      }
      if (role === "moderator") {
        return type === "chat"
          ? allUsers.length > 1
            ? moderatorActionsForChat
            : moderatorActionsForChatTwo
          : moderatorActions;
      } else {
        return isRestricted ? restrictedMemberActions : unrestrictedMemberActions;
      }
    }

    if (role === "moderator") {
      return noMeetingModeratorActions;
    }
    return isRestricted
      ? noMeetingRestrictedMemberActions
      : noMeetingUnrestrictedMemberActions;
  };
  const handleClickChannel = async (e, channel, unread) => {
    e.stopPropagation();
    try {
    setChatLoader(true);
    await channelIdChangeHandler(channel.ChannelArn);
    await onUpdateChannelReadMarker(channel.ChannelArn);
    setActiveReadMarker(channel.ReadMarkerTimestamp);
    if (unread) {
      unread = false;
      setSidePanel(false);
    }
    setChatLoader(false);
  } catch(e) {
    setChatLoader(false);
  }
  }

  let chat = [];
  let channels = [];
  channelList.forEach(ch => {
    if (ch.Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
      chat.push({
        ...ch,
        unread: ((ch.LastMessageTimestamp !== null && ch.ReadMarkerTimestamp === null) ||
          ch.LastMessageTimestamp !== null &&
          ch.ReadMarkerTimestamp !== null &&
          ch.ReadMarkerTimestamp < ch.LastMessageTimestamp)
      });
    }
    if (ch.Name.includes(GROUP_CHANNEL_TYPE) || ch.Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
      channels.push({
        ...ch,
        unread: ((ch.LastMessageTimestamp !== null && ch.ReadMarkerTimestamp === null) ||
          ch.LastMessageTimestamp !== null &&
          ch.ReadMarkerTimestamp !== null &&
          ch.ReadMarkerTimestamp < ch.LastMessageTimestamp)
      });
    }
  });

  chat.sort(function (a, b) {
    return new Date(b.LastMessageTimestamp) - new Date(a.LastMessageTimestamp);
  });
  chat.sort(function (x, y) { return y.unread - x.unread });
  channels.sort(function (a, b) {
    return new Date(b.LastMessageTimestamp) - new Date(a.LastMessageTimestamp);
  });
  channels.sort(function (x, y) { return y.unread - x.unread });

  return (
    <>
      <ModalManager
        modal={modal}
        setModal={setModal}
        activeChannel={activeChannel}
        meetingInfo={meetingInfo}
        userId={userId}
        onAddMember={onAddMember}
        onAddMembertoExisting={onAddMembertoExisting}
        onManageChannelFlow={onManageChannelFlow}
        handleChannelDeletion={handleChannelDeletion}
        handleDeleteMemberships={handleDeleteMemberships}
        handleJoinMeeting={handleJoinMeeting}
        handleMessageAll={handleMessageAll}
        handlePickerChange={handlePickerChange}
        handleDeletePickerChange={handleDeletePickerChange}
        handleMultiPickerChange={handleMultiPickerChange}
        formatMemberships={formatMemberships}
        activeChannelMemberships={activeChannelMemberships}
        selectedMembers={selectedMembers}
        selectedMultiMembers={selectedMultiMembers}
        membersForDelete={membersForDelete}
        onCreateChannel={onCreateChannel}
        activeChannelModerators={activeChannelModerators}
        handleLeaveChannel={handleLeaveChannel}
        banList={banList}
        banUser={banUser}
        unbanUser={unbanUser}
        activeChannelFlow={activeChannelFlow}
        setCustomStatus={setCustomStatus}
        loader={loader}
      />
      <div className="channel-list-wrapper chat-individual">
        <div className="channel-list-header">
          <div className="channel-list-header-title">
          <div className='position-relative scheduler-tabs-wrapper ad-ch-input-wrapper ch-input-search-new'>
              <span className='search-icon'>
                <span className='visually-hidden'>Search Icon</span>
              </span>
              <input
                type='text'
                className='search-box form-control keyword-search'
                placeholder='Chat'
                value={userSearchText}
                onChange={(e) => setUserSearchText(e.target.value)}
                id='search-users-in-chat'
              />
              <span
                className='close-icon ch-keyword-close'
                data-testid='toast-header'
                aria-label='Close'
                onClick={(e) => setUserSearchText("")}
              >
                <span className='visually-hidden' >Close Icon</span>
              </span>
            </div>
            </div>

          <IconButton
            className="create-channel-button channel-options"
            onClick={() => {
              setModal("ChatNewMember");
              setSelectedMultiMembers([]);
              setModalType(INDIVIDUAL_CHANNEL_TYPE);
            }}
            icon={
              <span className="plus-icon">
                <span className="visually-hidden">Plus/add icon</span>
              </span>
            }
          />
        </div>
        {!subSetUsersLoading && <ChannelList className="ch-channel-list-wrapper">
          {chat.map((channel) => {
            const initialName = channel.Name;
            let { Name = "", unread } = channel || {};
            Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
            let allUsers = Name.split(", ");
            if (allUsers.includes(username)) {
              allUsers = allUsers.filter((user) => user !== username);
            }

            let tempUsers = [];
            allUsers.forEach((user) => {
              const currentUser = cognitoUsers[user] || {};
              if (!_.isEmpty(currentUser)) {
                const {
                  name = "",
                  icon_color_code,
                } = currentUser;
                tempUsers.push({
                  fullName: name,
                  icon_color_code,
                });
                // tempUsers.push({ fullName: user });
              }
            });
            Name = tempUsers
              .map((user) => {
                return user.fullName;
              })
              .join(", ");
            let rgbColorArray = setGenerateRandomColor(initialName) || [];
            const getAvatar = () => {
              if (tempUsers.length === 1) {
                return (
                  <Avatar
                    color={
                      tempUsers[0].icon_color_code
                        ? `#${tempUsers[0].icon_color_code}`
                        : "#333745"
                    }
                    name={tempUsers[0].fullName}
                    maxInitials={2}
                    size={30}
                    round="40px"
                  />
                );
              } else {
                return (
                  <div
                    className="chat-group-icon"
                    style={{
                      background: `rgb(${rgbColorArray[0]}, ${rgbColorArray[1]}, ${rgbColorArray[2]})`,
                    }}
                  ></div>
                );
              }
            };
            const searchText = userSearchText.replace(/\\/g, "\\\\");
            return (
              (!searchText ||
                Name.toLowerCase().search(searchText.toLowerCase()) !==
                  -1) &&
              <ChannelItem
                cssClass="list-item"
                key={channel.ChannelArn}
                name={
                  <div className="user-name-wrapper">
                    {getAvatar()} <div className="username-label">{Name}</div>
                  </div>
                }
                actions={loadUserActions(userPermission.role, channel, "chat")}
                isSelected={channel.ChannelArn === activeChannel.ChannelArn}
                onClick={(e) => {
                  handleClickChannel(e, channel, unread)
                }}
                unread={unread}
                unreadBadgeLabel="New"
              />
            );
          })}
        </ChannelList>}
      </div>
      <div className="channel-list-wrapper chat-group">
        <div className="channel-list-header">
          <div className="channel-list-header-title">
          <div className='position-relative scheduler-tabs-wrapper ad-ch-input-wrapper ch-input-search-new'>
              <input
                type='text'
                className=' search-box form-control keyword-search'
                placeholder='Rooms'
                value={roomSearchText}
                onChange={(e) => setRoomSearchText(e.target.value)}
                id='search-rooms-in-chat'
              />
              <span className='search-icon '>
                <span className='visually-hidden'>Search Icon</span>
              </span>
              <span
                className='close-icon ch-keyword-close'
                data-testid='toast-header'
                aria-label='Close'
                onClick={(e) => setRoomSearchText("")}
              >
                <span className='visually-hidden' >Close Icon</span>
              </span>
            </div>
          </div>
          {admin && (
            <IconButton
              className="create-channel-button channel-options"
              onClick={() => {
                setModal("NewChannel");
                setModalType(GROUP_CHANNEL_TYPE);
              }}
              icon={
                <span className="plus-icon">
                  <span className="visually-hidden">Plus/add icon</span>
                </span>
              }
            />
          )}
        </div>
        <ChannelList
          className="ch-channel-list-wrapper"
        // style={{
        //   padding: '8px',
        // }}
        >
          {channels.map((channel) => {
            const initialName = channel.Name;
            let { Name = "", unread } = channel || {};
            if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
              Name = Name.replace(GROUP_CHANNEL_TYPE, "");
              Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, "");
            }
            let rgbColorArray = setGenerateRandomColor(initialName) || [];
            const searchText = roomSearchText.replace(/\\/g, "\\\\");
            return (
              (!searchText ||
                Name.toLowerCase().search(searchText.toLowerCase()) !== -1) &&
              <ChannelItem
                key={channel.ChannelArn}
                name={
                  <div className="user-name-wrapper">
                    <div
                      className="chat-room-icon"
                      style={{
                        background: `rgba(${rgbColorArray[0]}, ${rgbColorArray[1]}, ${rgbColorArray[2]}, 0.20)`,
                        color: `rgb(${rgbColorArray[0]}, ${rgbColorArray[1]}, ${rgbColorArray[2]})`,
                      }}
                    >
                      {Name.split("")[0]}
                    </div>{" "}
                    <div className="username-label"> {Name}</div>
                  </div>
                }
                actions={loadUserActions(userPermission.role, channel)}
                isSelected={channel.ChannelArn === activeChannel.ChannelArn}
                onClick={(e) => {
                  handleClickChannel(e, channel, unread)
                }}
                unread={unread}
                unreadBadgeLabel="New"
              />
            );
          })}
        </ChannelList>
      </div>
    </>
  );
};

export default ChannelsWrapper;
