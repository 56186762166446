import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";


const initialState = {
  value: [],
  loading: false,
};

export const cancelHomeHealth = createAsyncThunk(
  "homeHealth/cancelHomeHealth",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.cancelHomeHealthOrder(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const cancelHomeHealthSlice = createSlice({
  name: "cancelHomeHealth",
  initialState,
  reducers: {
    clearCancelHomeHealthResponse(state, action) {
      state.value = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelHomeHealth.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelHomeHealth.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(cancelHomeHealth.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const cancelHomeHealthList = (state) => state.cancelHomeHealth.value;
export const { clearCancelHomeHealthResponse } = cancelHomeHealthSlice.actions;
export default cancelHomeHealthSlice.reducer;
