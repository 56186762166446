/* eslint-disable import/no-extraneous-dependencies */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import AWS from "aws-sdk";
import { appConfig } from "../Config";
import { fetchAuthSession } from "aws-amplify/auth";

/**
 * @class IdentityService
 */
export class IdentityService {
  /**
   * @param {region}  region AWS region.
   * @param {userPoolId} userPoolId Cognito User Pool Id.
   */
  constructor(region, userPoolId) {
    this._userPoolId = userPoolId;
    this._region = region;
  }

  async getUsers(limit = 60, Filter = "") {
    try {
      console.log(this._identityClient, 'this._identityClient');
      
      const users = await this._identityClient
        .listUsers({
          Limit: limit,
          UserPoolId: this._userPoolId,
          Filter,
        })
        .promise();

      return users.Users;
    } catch (err) {
      console.log(err, err.message, 'err2');
      // if (true) {
        return await this.updatedCreds(limit, Filter, this, this.callbackFn);
      // }
    }
  }

  callbackFn = async (obj, limit, Filter, essentialCreds) => {
    obj._identityClient = new AWS.CognitoIdentityServiceProvider({
      region: appConfig.region,
      credentials: essentialCreds,
    });

    const users = await obj._identityClient
      .listUsers({
        Limit: limit,
        UserPoolId: obj._userPoolId,
        Filter,
      })
      .promise();
    return users.Users;
  }

  updatedCreds = async (limit = 60, Filter = "", obj, callbackFn) => {
    let creds;
    try {
      creds = await fetchAuthSession();
    } catch (e) {
      console.log(e, 'err3');
    }
    if (!creds || !creds.credentials) return;
    return callbackFn(obj, limit, Filter, creds.credentials);
  }

  async searchByName(name) {
    try {
      const list = await this._identityClient
        .listUsers({
          Filter: `given_name ^= "${name}"`,
          Limit: 10,
          UserPoolId: this._userPoolId,
        })
        .promise();

      return list.Users;
    } catch (err) {
      console.log(err, err.message, 'err21');
      // if (true) {
        let creds;
        try {
          creds = await fetchAuthSession();
        } catch (e) {
          console.log(e, 'err6');
        }
        if (!creds || !creds.credentials) return;
        return this.callBackSearchByName(this, name, creds.credentials);
      // }
    }
  }

  callBackSearchByName = async (obj, name, essentialCreds) => {
    obj._identityClient = new AWS.CognitoIdentityServiceProvider({
      region: appConfig.region,
      credentials: essentialCreds,
    });

    const list = await obj._identityClient
      .listUsers({
        Filter: `given_name ^= "${name}"`,
        Limit: 10,
        UserPoolId: this._userPoolId,
      })
      .promise();

    return list.Users;
  }

  async setupClient() {
    let creds;
    try {
      creds = await fetchAuthSession();
    } catch (e) {
      console.log(e, 'err4');
    }
    if (!creds || !creds.credentials) return;

    this._identityClient = new AWS.CognitoIdentityServiceProvider({
      region: this._region,
      credentials: creds.credentials,
    });
  }
}

export default IdentityService;
