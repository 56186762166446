import * as React from "react";
import { Droppable } from "@hello-pangea/dnd";
import { Zone } from "./Zone";
import { serviceTypes } from "../../app/utils/Constants";
import { Form, InputGroup, Accordion, Button } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import { DropOffOverlay } from "./DropOffOverlay";
import ahaApi from "../../app/services/aha-api";
import uuid from 'react-uuid'
import { useDispatch, useSelector } from "react-redux";
import { setSurgeOrNonServiceModal, surgeOrNonServiceFacilitiesList, surgeOrNonServiceModalData } from "./operations/getSurgeOrNonServiceFacilitiesSlice";
import { setDisableSaveForDropOff } from "./operations/scheduleListSlice";

const timeRegex = new RegExp(':', 'g');

const uuid1 = uuid();
const uuid2 = uuid();
const uuid3 = uuid();
const uuid4 = uuid();

const constructTimeOptions = () => {
  let options = [];
  for (let i = 0; i < 24; i++) {
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:00`,
      value: `${i < 10 ? "0" : ""}${i}:00`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:15`,
      value: `${i < 10 ? "0" : ""}${i}:15`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:30`,
      value: `${i < 10 ? "0" : ""}${i}:30`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:45`,
      value: `${i < 10 ? "0" : ""}${i}:45`,
    });
  }
  options = options.map((opt, index) => {
    return { ...opt, id: index };
  });
  return options;
};

const constructEndTimeOptions = (startTime) => {
  let options = [];
  for (let i = 0; i < 24; i++) {
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:00`,
      value: `${i < 10 ? "0" : ""}${i}:00`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:15`,
      value: `${i < 10 ? "0" : ""}${i}:15`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:30`,
      value: `${i < 10 ? "0" : ""}${i}:30`,
    });
    options.push({
      label: `${i < 10 ? "0" : ""}${i}:45`,
      value: `${i < 10 ? "0" : ""}${i}:45`,
    });
  }
  options = options.map((opt, index) => {
    return { ...opt, id: index, label: startTime.id >= index ? `${opt.label} +1` : opt.label };
  });
  return options;
};

const getEmptyDroppable = (userIndex, serviceIndex, blockId = null, uniqueServiceId, showBorder = false, droppableId) => {
  const droppableNewId = droppableId || `emptyUser/${blockId}/${uniqueServiceId}` + "|" + userIndex + "|" + serviceIndex + "|" + "inner";
  return (
    <Droppable droppableId={droppableNewId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={`${snapshot.isDraggingOver ? "droppable-area-active " : showBorder ? "droppable-area-inactive " : " "} ${showBorder ? "droppable-area-border " : " "}`}
          // style={{ opacity: snapshot.isDraggingOver ? 0.8 : 1, backgroundColor: snapshot.isDraggingOver ? "#ebf6f4" : "", border: showBorder ? '5px dotted green' : "none" }}
          {...provided.droppableProps}
        >
          <p className="drop-facility-zone-text">
            Drag facilities here from the{" "}
            <span className="roboto-medium">Task Bar</span> to add to a person’s
            schedule
          </p>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const getFormattedServices = (services = []) => {
  let formattedServices = [];
  if (services.some((service) => service.service_name === "Routine Draws")) {
    services.forEach((service, index) => {
      if (service.service_name === "Routine Draws") {
        formattedServices.push({
          ...service,
          class: "routine-draws",
          isPresent: true,
          // id: index,
          // uuid: uuid1
        });
      }
    });
  } else {
    formattedServices.push({
      service_type: 1,
      service_name: serviceTypes[1].name,
      start_time: null,
      end_time: null,
      zones: [],
      class: "routine-draws",
      isPresent: false,
      uniqueServiceId: uuid1
    });
  }

  // if (services.some((service) => service.service_type == 4)) {
  //   services.forEach((service, index) => {
  //     if (service.service_name === "Home Health") {
  //       formattedServices.push({
  //         ...service,
  //         class: "home-health",
  //         isPresent: true,
  //         // id: index,
  //         // uniqueServiceId: uuid2
  //       });
  //     }
  //   });
  // } else {
  //   formattedServices.push({
  //     service_type: 4,
  //     service_name: serviceTypes[4].name,
  //     start_time: null,
  //     end_time: null,
  //     class: "home-health",
  //     isPresent: false,
  //     uniqueServiceId: uuid2
  //   });
  // }

  // if (services.some((service) => service.service_type == 3)) {
  //   services.forEach((service, index) => {
  //     if (service.service_name === "On Call") {
  //       formattedServices.push({
  //         ...service,
  //         class: "on-call",
  //         isPresent: true,
  //         // id: index,
  //         // uniqueServiceId: uuid3
  //       });
  //     }
  //   });

  // } else {
  //   formattedServices.push({
  //     service_type: 3,
  //     service_name: serviceTypes[3].name,
  //     start_time: null,
  //     end_time: null,
  //     class: "on-call",
  //     isPresent: false,
  //     uniqueServiceId: uuid3
  //   });
  // }

  if (services.some((service) => service.drop_off)) {
    let serviceData = {};
    services.forEach((service) => {
      if (service.drop_off) {
        serviceData = service;
      }
    });
    formattedServices.push({
      ...serviceData,
      class: "drop-off",
      service_type: 5,
      isPresent: true,
      // uniqueServiceId: uuid4
    });
  } else {
    formattedServices.push({
      service_type: 5,
      service_name: serviceTypes[5].name,
      start_time: null,
      end_time: null,
      class: "drop-off",
      isPresent: false,
      uniqueServiceId: uuid4
    });
  }
  return formattedServices;
};

export const FacilityService = (props) => {
  const {
    services = [],
    sectionType,
    userIndex,
    user_id,
    setBoardData,
    date,
    updateBoardData,
    handleDeleteService,
    constructPyloadForCheckinCheckout,
    constructPayloadToClearCheckinCheckoutSpin,
    getScheduleBoardData,
    deleteServiceData,
    setDeleteServiceData,
    searchText = "",
    searchType,
    tempCourierAddress, setTempCourierAddress,
    isPastDate,
    facilitiesForSurge = [],
    facilitiesForNonService = [],
    addFacilityToSurgeOrNonService,
    showDropArea,
    timezone_ids,
    showInactiveFac,
    viewAttachment
  } = props;
  const [serviceModal, setServiceModal] = React.useState({});
  const [routineServices, setRoutineServices] = React.useState([]);
  const [onCalls, setOnCalls] = React.useState([]);
  const [newDropOff, setNewDropOff] = React.useState({});
  const [showInvalidHosError, setShowInvalidHosError] = React.useState(false);
  const surgeOrNonServiceModal = useSelector(surgeOrNonServiceModalData);
  const dispatch = useDispatch();

  const listRefs = React.useRef([]);

  const { dropOffLat = "", dropOffLng = "", dropOffType = "lab", time = "", name = "", selectedFiles = [], note = "", timezone = "" } = newDropOff;

  // React.useEffect(() => {
  // 	const onClickOutside = (e) => {
  // 		const menuOpen = document.getElementById("facilities-list-wrapper")
  // 		if (e.target.contains(menuOpen) && menuOpen
  // 		) {
  //       dispatch(setSurgeOrNonServiceModal({}))
  // 		}
  // 	}
  // 	document.addEventListener("mousedown", onClickOutside);
  // 	return () => {
  // 		document.removeEventListener("mousedown", onClickOutside);
  // 	}
  // }, [])

  React.useEffect(() => {
    if (deleteServiceData) {
      let tempRoutineServices = [...routineServices];
      tempRoutineServices = tempRoutineServices.filter((service, index) => service.uniqueServiceId !== deleteServiceData);
      setRoutineServices(tempRoutineServices);
      setDeleteServiceData(null);
    }

  }, [deleteServiceData])

  const handleServiceModalChange = (
    property,
    value,
    userId,
    serviceType = 1,
    serviceIndex,
    sTime,
    eTime,
    description,
    timezone = {}
  ) => {
    if (
      (property === "selectedAcc" && value === null) ||
      property === "isEdit"
    ) {
      setServiceModal({
        ...serviceModal,
        [`${userId}|${serviceType}|${serviceIndex}`]: {
          ...serviceModal[`${userId}|${serviceType}|${serviceIndex}`],
          sTime: value
            ? sTime
              ? { label: sTime, value: sTime, id: getId(sTime) }
              : ""
            : "",
          eTime: value
            ? eTime
              ? { label: eTime, value: eTime, id: getId(eTime) }
              : ""
            : "",
          [property]: value,
          isEdit: property === "isEdit" ? value : false,
          description: value ? description : "",
          timezone: value ? timezone ? { ...timezone, label: timezone.timezone_name, value: timezone.timezone_id } : "" : "",
        },
      });
    } else {
      setServiceModal({
        ...serviceModal,
        [`${userId}|${serviceType}|${serviceIndex}`]: {
          ...serviceModal[`${userId}|${serviceType}|${serviceIndex}`],
          [property]: value,
        },
      });
    }
  };

  const checkOption = (sTime, eTime, option, type = "start") => {
    return type === "start"
      ? Object.keys(eTime).length === 0
        ? false
        : option.id >= (eTime.id || 0)
      : Object.keys(sTime).length === 0
        ? false
        : option.id <= (sTime.id || 0);
  };

  const getId = (time) => {
    let result = 0;
    const options = constructTimeOptions();
    options.forEach((op) => {
      if (op.value === time) {
        result = op.id;
      }
    });
    return result;
  };

  const addFacilityToSchedule = (
    sTime = {},
    eTime = {},
    description,
    userId,
    serviceType,
    uniqueServiceId,
    userIndex,
    serviceName,
    blockId,
    serviceIndex,
    timezone = {}
  ) => {
    if (Object.keys(sTime).length > 0 && Object.keys(eTime).length > 0) {
      const payload = {
        service_type_id: serviceType || 1,
        start_time: sTime.value,
        end_time: eTime.value,
        status: "ACTIVE",
        facility_ids: [],
        person_id: user_id,
        current_person_id: null,
        event_date: moment(date).format("YYYY-MM-DD"),
        current_event_date: null,
        description,
        block_id: blockId,
        service_index: serviceIndex,
        uniqueServiceId: uniqueServiceId,
        timezone_id: timezone.value || "",
        timezone_label: timezone.label || "",
        timezoneData: timezone
      };
      updateBoardData(userIndex, payload, serviceName);
      let tempServiceModal = { ...serviceModal };
      delete tempServiceModal[`${userId}|${serviceType}|${uniqueServiceId}`];

      if (serviceName === "On Call" && onCalls.some(service => service.uniqueServiceId === uniqueServiceId)) {
        const tempOnCalls = onCalls.filter(service => service.uniqueServiceId !== uniqueServiceId);
        setOnCalls(tempOnCalls);
      } else if (serviceName === "Routine Draws" && routineServices.some(service => service.uniqueServiceId === uniqueServiceId)) {
        const tempRoutineServices = routineServices.filter(service => service.uniqueServiceId !== uniqueServiceId);
        setRoutineServices(tempRoutineServices);
      }
      setServiceModal(tempServiceModal);
    }
  };

  const handleChangeDropOff = async(property, value, dropOffType, overlayType, existingFormData) => {
    // setNewDropOff({ ...newDropOff, [property]: value });
    console.log(dropOffType, 'dropOffTypedropOffTypedropOffType', overlayType, existingFormData);
    
    if (property === "dropOffType") {
      setTempCourierAddress({});
      setNewDropOff({ [property]: value });
    } else {
      let newData = { ...newDropOff, [property]: value };
      if (property === "timezone" && dropOffType === "hospital") {
        const dropLocation = overlayType === "add" ? (newDropOff && newDropOff.name && newDropOff.name.label) : (existingFormData && existingFormData.drop_location);
        const dropLocationValue = overlayType === "add" ? (newDropOff && newDropOff.name && newDropOff.name.value) : (existingFormData && existingFormData.drop_point_id);
        const newTime = overlayType === "add" ? (newDropOff && newDropOff.time) : (existingFormData && {value: existingFormData.time});

        if (dropLocation) {
          // call hospital search api
          const options = await loadOptionsHos(dropLocation, newTime, value);
          if (options && options.length && options.some(opt => opt.value == dropLocationValue)) {
            // no need to do anything
          } else {
            newData.name = "";
            if (overlayType !== "add") {
              setShowInvalidHosError(true);
            }
          }
        }
      } else if (property === "time" && dropOffType === "hospital") {
        const dropLocation = overlayType === "add" ? (newDropOff && newDropOff.name && newDropOff.name.label) : (existingFormData && existingFormData.drop_location);
        const dropLocationValue = overlayType === "add" ? (newDropOff && newDropOff.name && newDropOff.name.value) : (existingFormData && existingFormData.drop_point_id);
        const newTimezone = overlayType === "add" ? (newDropOff && newDropOff.timezone) : (existingFormData && existingFormData.timezone.timezone_id && {value: existingFormData.timezone.timezone_id});
        if (dropLocation) {
          // call hospital search api
          const options = await loadOptionsHos(dropLocation, value, newTimezone);
          if (options && options.length && options.some(opt => opt.value == dropLocationValue)) {
            // no need to do anything
          } else {
            newData.name = "";
            if (overlayType !== "add") {
              setShowInvalidHosError(true);
            }
          }
        }
        if (overlayType !== "add") {
          newData.name = "";
        }
      }
      if (property === "name" && dropOffType !== "hospital") {
        let newTz = value ? value.timezone && value.timezone !== null ? value.timezone.timezone_id ? { ...value.timezone, label: value.timezone.timezone_name, value: value.timezone.timezone_id } : "" : "" : "";
        newData.timezone = newTz;
      } else if (property === "name" && dropOffType === "hospital") {
        setShowInvalidHosError(false);
      }
      setNewDropOff(newData);
    }
  }

  const handleChangeDropOffLatLng = (data) => {
    setNewDropOff({ ...newDropOff, ...data });
  }

  const addNewDroppOff = (dropOffId, overlayType, dropOffData = {}, userIndex, serviceIndex, uniqueServiceId, zoneIndex = -1, routineIndex = -1, facilityIndex = -1) => {
    let tempType = dropOffId ? overlayType : dropOffType;
    tempType = tempType.toLowerCase();

    let actionData = [];
    actionData.push({
      id: dropOffId,
      action_type_id: 3,
      action_datetime: `${moment(date).format("YYYY-MM-DD")} ${time.value || dropOffData.time}`,
      hospital_id:
        tempType === "hospital" ? name.value || dropOffData.drop_point_id : null,
      lab_id: tempType === "lab" ? name.value || dropOffData.drop_point_id : null,
      is_courier_drop_off: tempType === "courier",
      facility_id: 0,
      latitude:
        tempType === "courier"
          ? parseFloat(dropOffLat) || dropOffData.latitude
          : name.latitude || dropOffData.latitude,
      longitude:
        tempType === "courier"
          ? parseFloat(dropOffLng) || dropOffData.longitude
          : name.longitude || dropOffData.longitude,
      order_id: 0,
      user_id: props.user_id,
      notes: note,
      timezone_id: timezone.value || "",
      skip_timezone_validation: false
    });

    if (actionData.length) {
      const formData = new FormData();
      formData.append("actions", JSON.stringify(actionData));
      selectedFiles.forEach((file) => {
        formData.append("files", file);
      });
      dispatch(setDisableSaveForDropOff(true));
      constructPyloadForCheckinCheckout(formData, userIndex, serviceIndex, uniqueServiceId, zoneIndex, routineIndex, facilityIndex, dropOffId)
      // if (dropOffType === "courier") {
      //   setTempCourierAddress({});
      // }
    }
  }

  const loadOptions = async (text) => {
    if (!text) {
      return;
    }
    const data = {
      search_text: text
    }
    const endPoint = ahaApi.Account.labsList;
    const response = await endPoint(data)
      .then((res) => {
        const { content = [] } = res;
        return content.map(c => { return { ...c, label: c.lab_name, value: c.id } });
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return response;
  }

  const loadOptionsHos = async (text, time, timezone) => {
    if (!text) {
      return ;
    }
    const data = {
      search_text: text
    }
    if (time && time.value) {
      data.effective_datetime = `${moment(date).format("YYYY-MM-DD")} ${time.value}`;
    }
    if (timezone && timezone.value) {
      data.timezone_id = timezone.value;
    }
    const endPoint = ahaApi.Account.hospitalsList;
    const response = await endPoint(data)
      .then((res) => {
        const { content = [] } = res;
        return content.map(c => { return { ...c, label: c.hospital_name, value: c.id } });
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return response;
  }

  const mergeServices = (services, routineServices, onCalls = []) => {
    const routineIndex = services.map(function (service) { return service.service_name; }).lastIndexOf("Routine Draws");

    const tempRoutineServices = [];
    routineServices.forEach(tmpSer => {
      if (!services.some(service => tmpSer.uniqueServiceId === service.uniqueServiceId)) {
        tempRoutineServices.push(tmpSer);
      }
    })

    let result = services.splice(routineIndex + 1, 0, ...tempRoutineServices) && services;

    // const onCallIndex = result.map(function(service) {return service.service_name; }).lastIndexOf("On Call");

    // result = result.splice(onCallIndex + 1, 0, ...onCalls) && result;
    return result;
  }

  const addNewRoutineService = (userId) => {
    let tempRoutineServices = [...routineServices];
    const uniqueServiceId = uuid();
    tempRoutineServices.push({
      service_type: 1,
      service_name: "Routine Draws",
      start_time: null,
      end_time: null,
      zones: [],
      class: "routine-draws",
      isPresent: false,
      isTemp: true,
      uniqueServiceId
    });
    // tempRoutineServices = tempRoutineServices.map((service, index) => {return {...service, uniqueServiceId: index+1}})
    setRoutineServices(tempRoutineServices);
    handleServiceModalChange(
      "selectedAcc",
      "routine-draws",
      userId,
      1,
      uniqueServiceId
    )
  }

  const deleteRoutineService = (uniqueServiceId, userId) => {
    let tempRoutineServices = [...routineServices];
    tempRoutineServices = tempRoutineServices.filter((service, index) => service.uniqueServiceId !== uniqueServiceId);
    // tempRoutineServices = tempRoutineServices.map((service, index) => {return {...service, uniqueServiceId: index+1}})
    setRoutineServices(tempRoutineServices);
    const tempServiceModal = { ...serviceModal };
    if (tempServiceModal[`${userId}|${1}|${uniqueServiceId}`]) {
      delete tempServiceModal[`${userId}|${1}|${uniqueServiceId}`];
    }
    setServiceModal(tempServiceModal)
  }

  const addNewOnCallService = (userId) => {
    let tempOnCalls = [...onCalls];
    const uniqueServiceId = uuid();
    tempOnCalls.push({
      service_type: 3,
      service_name: "On Call",
      start_time: null,
      end_time: null,
      zones: [],
      class: "on-call",
      isPresent: false,
      isTemp: true,
      uniqueServiceId
    });
    setOnCalls(tempOnCalls);
    handleServiceModalChange(
      "selectedAcc",
      "on-call",
      userId,
      3,
      uniqueServiceId
    )
  }

  const deleteOnCallService = (uniqueServiceId, userId) => {
    let tempOnCalls = [...onCalls];
    tempOnCalls = tempOnCalls.filter((service, index) => service.uniqueServiceId !== uniqueServiceId);
    setOnCalls(tempOnCalls);
    const tempServiceModal = { ...serviceModal };
    if (tempServiceModal[`${userId}|${3}|${uniqueServiceId}`]) {
      delete tempServiceModal[`${userId}|${3}|${uniqueServiceId}`];
    }
    setServiceModal(tempServiceModal)
  }

  const getDateTime = (startTime = "", endTime = "") => {
    const tempDate = new Date(date);
    tempDate.setDate(tempDate.getDate() + 1);
    const dateAndTime = parseInt(startTime.replace(timeRegex, ''), 10) >= parseInt(endTime.replace(timeRegex, ''), 10) ?
      moment(tempDate).format("MMM DD").toUpperCase()
      : moment(date).format("MMM DD").toUpperCase()
    return dateAndTime;
  }

  const toggleSurgeOrNonService = (userId, type, uniqueServiceId, modalType, newType) => {
    dispatch(setSurgeOrNonServiceModal({
      [`${userId}|${type}|${uniqueServiceId}`]: {
        ...surgeOrNonServiceModal[`${userId}|${type}|${uniqueServiceId}`],
        modalType: modalType === newType ? "" : newType,
        searchVal: ""
      }
    }))
  }

  const selectFacility = (facility, userIndex, uniqueServiceId, serviceType, type) => {
    addFacilityToSurgeOrNonService(userIndex, uniqueServiceId, serviceType, facility, type)
  }

  // routine draws facilities for backlog
  const { zones = {}, service_name: serviceName } = services[0] || {};

  let finalServices = getFormattedServices(services);
  // merge local services with board services
  finalServices = mergeServices(finalServices, routineServices);
  // finalServices = mergeServices(finalServices, routineServices, onCalls);

  // get last index of Routine Draws
  const routineDrawsLastIndex = finalServices.map(function (service) { return service.service_name; }).lastIndexOf("Routine Draws");
  const onCallsLastIndex = finalServices.map(function (service) { return service.service_name; }).lastIndexOf("On Call");

  const hasRouteStarted = services && services.some(ser => ser.service_name === "Start Route");

  let permission =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
    [];
  permission = permission.filter((perm) => perm.page === "events");
  const eventAccess = permission.length === 1 ? permission[0].access : [];
  const canDeleteBlock = eventAccess && eventAccess.find((item) => item === "delete") === "delete" ? true : false;
  const canAddSurge = eventAccess && eventAccess.find((item) => item === "surge") === "surge" ? true : false;
  const canAddNonService = eventAccess && eventAccess.find((item) => item === "non-service") === "non-service" ? true : false;
  const canUpdatePastDate = eventAccess && eventAccess.find((item) => item === "upsert-past-date") === "upsert-past-date" ? true : false;

  return (
    <React.Fragment>
      {sectionType === "scheduleBoard" ? (
        finalServices.map((service, serviceIndex) => {
          const {
            start_time: startTime,
            end_time: endTime,
            zones = {},
            details,
            service_name: name,
            service_type: type = 1,
            isPresent,
            drop_off = [],
            description: desc = "",
            block_id = null,
            isTemp = false,
            uniqueServiceId,
            start_day,
            end_day,
            timezone: timezoneData = {}
          } = service;
          const {
            selectedAcc = "",
            sTime = "",
            eTime = "",
            description = "",
            isEdit = false,
            timezone = ""
          } = serviceModal[`${user_id}|${type}|${uniqueServiceId}`] || {};

          const { modalType = "", searchVal = "", isOpen = false } = surgeOrNonServiceModal[`${user_id}|${type}|${block_id}`] || {};


          const droppableId =
            `emptyUser/${block_id}/${uniqueServiceId}` + "|" + userIndex + "|" + serviceIndex;

          const addEditTimeBlock = type !== 5 && (isEdit || !isPresent);

          const activeKey = isPresent || type === 5 ? service.class : selectedAcc;

          const allFacilities = [];

          Object.keys(zones).forEach(z => {
            if (zones[z].events) {
              Object.keys(zones[z].events).forEach(eId => {
                if (zones[z].events[eId].facilities) {
                  zones[z].events[eId].facilities.forEach(fac => {
                    allFacilities.push(fac);
                  })
                }
              })
            }
          });

          const isFacilityCheckedInOrOut = allFacilities.some(fac => fac.status === "Check In" || fac.status === "Check Out");

          const surgeOrNonServiceFacilitiesData = modalType === "surge" ? facilitiesForSurge : modalType === "nonService" ? facilitiesForNonService : []
          const showNoResults = !surgeOrNonServiceFacilitiesData.some(result => (!searchVal || result.account_name.toLowerCase().search(searchVal.toLowerCase()) !== -1))
          const isDropDisabled = name !== "Routine Draws" || (name == "Routine Draws" && Object.keys(zones).length !== 0)

          return (
            // (type != 5 || (type == 5 && isPresent)) && (
            <Droppable
              droppableId={droppableId}
              isDropDisabled={isDropDisabled}
              key={uniqueServiceId}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className={`${snapshot.isDraggingOver ? "droppable-area-active " : showDropArea ? "droppable-area-inactive " : " "}`}
                  // style={{ opacity: snapshot.isDraggingOver ? 0.8 : 1, backgroundColor: snapshot.isDraggingOver ? "#ebf6f4" : "" }}
                  {...provided.droppableProps}
                >
                  <Accordion
                    activeKey={activeKey}
                    className="schedule-accordian-wrapper"
                    onSelect={(val) =>
                      type !== 5 &&
                      (isPresent ?
                        handleServiceModalChange(
                          "isEdit",
                          !isEdit,
                          user_id,
                          type,
                          uniqueServiceId,
                          startTime,
                          endTime,
                          desc,
                          timezoneData
                        )
                        :

                        handleServiceModalChange(
                          "selectedAcc",
                          val,
                          user_id,
                          type,
                          uniqueServiceId
                        )
                      )
                    }
                  >
                    <Accordion.Item eventKey={service.class}>
                      <div className="schedule-options">
                        {/* {isPresent && type != 5 && (
                            <span
                              className="schedule-edit"
                              onClick={() =>
                                handleServiceModalChange(
                                  "isEdit",
                                  !isEdit,
                                  user_id,
                                  type,
                                  startTime,
                                  endTime,
                                  desc
                                )
                              }
                            ></span>
                          )} */}

                        {type != 5 && (!isPresent ? isTemp : true) && !isFacilityCheckedInOrOut && canDeleteBlock && (
                          <>
                            {/* <div>
                              <span
                              className={`schedule-add-new`} title="Add More"
                              ><span className="visually-hidden">Schedule Add Icon</span></span>
                              <span
                              className={`schedule-remove`} title="Remove"
                              ><span className="visually-hidden">Schedule Remove Icon</span></span>
                            </div> */}
                            <span
                              className={`schedule-delete active`}
                              onClick={(e) => {
                                e.preventDefault();
                                if (isTemp)
                                  type === 3 ? deleteOnCallService(uniqueServiceId, user_id) : deleteRoutineService(uniqueServiceId, user_id, allFacilities)
                                else handleDeleteService(
                                  user_id,
                                  type,
                                  userIndex,
                                  service,
                                  allFacilities
                                )
                              }}
                            ><span className="visually-hidden">Schedule Delete Icon</span></span>
                          </>
                        )}
                        {type != 5 && selectedAcc && (!isPresent ? !isTemp : false) && (
                          <span className={`schedule-delete disable`}></span>
                        )}
                      </div>
                      {!isPresent && type != 5 && !(addEditTimeBlock && activeKey === service.class) && showDropArea
                        ? getEmptyDroppable(userIndex, serviceIndex, block_id, uniqueServiceId, showDropArea)
                        : <Accordion.Header className={`${service.class} ${addEditTimeBlock && activeKey === service.class ? "time-block-open" : ""}`}>
                          <span className="service-icon"><span className="visually-hidden">Service Type Icon</span></span>
                          <div className="accordian-head">
                            <p className={service.class === "drop-off" ? "sub-title" : "title"}>{name}</p>
                            <p className="sub-title">
                              {sectionType === "scheduleBoard" && type != 5 && <p>
                                {startTime && endTime
                                  ? `${start_day ? start_day : `${moment(date).format("MMM DD").toUpperCase()}, `} ${startTime} ${timezoneData ? timezoneData.abbreviation || "" : ""} - ${end_day ? end_day : startTime && endTime ? `${getDateTime(startTime, endTime)}, ` : ""} ${endTime} ${timezoneData ? timezoneData.abbreviation || "" : ""}`
                                  : "Add To Schedule"}
                              </p>}
                            </p>
                          </div>
                        </Accordion.Header>}
                      <Accordion.Body>
                        {addEditTimeBlock && (
                          <React.Fragment>
                            <div className="choose-shift-wrapper">
                              <div className="schedule-start-time">
                                <InputGroup className="mb-3">
                                  <Form.Label htmlFor="schedule-timezone">
                                    Timezone
                                  </Form.Label>
                                  <Select
                                    name="schedule-timezone"
                                    id="schedule-timezone"
                                    className="sch-schedule-dropdown"
                                    classNamePrefix="sch-schedule-select"
                                    value={timezone}
                                    options={timezone_ids || []}
                                    // isDisabled={true}
                                    onChange={(e) =>
                                      handleServiceModalChange(
                                        "timezone",
                                        e,
                                        user_id,
                                        type,
                                        uniqueServiceId,
                                        startTime,
                                        endTime
                                      )
                                    }
                                  />
                                </InputGroup>
                              </div>
                            </div>
                            <div className="choose-time-wrapper">
                              <div className="schedule-start-time">
                                <InputGroup className="mb-3">
                                  <Form.Label htmlFor="schedule-start-time">
                                    Start Time
                                  </Form.Label>
                                  <Select
                                    name="schedule-start-time"
                                    id="schedule-start-time"
                                    className="sch-schedule-dropdown"
                                    classNamePrefix="sch-schedule-select"
                                    value={sTime}
                                    options={constructTimeOptions()}
                                    onChange={(e) =>
                                      handleServiceModalChange(
                                        "sTime",
                                        e,
                                        user_id,
                                        type,
                                        uniqueServiceId
                                      )
                                    }
                                    isOptionDisabled={(option) =>
                                      (isPastDate && !canUpdatePastDate) ? true : (type !== 3 && checkOption(
                                        sTime,
                                        eTime || 0,
                                        option,
                                        "start"
                                      ))
                                    }
                                  />
                                </InputGroup>
                              </div>
                              <div className="seperator">
                                <span className="service-icon"><span className="visually-hidden">Seperator</span></span>
                              </div>
                              <div className="schedule-end-time">
                                <InputGroup className="mb-3">
                                  <Form.Label htmlFor="schedule-end-time">
                                    End Time
                                  </Form.Label>
                                  <Select
                                    name="schedule-end-time"
                                    id="schedule-end-time"
                                    className="sch-schedule-dropdown"
                                    classNamePrefix="sch-schedule-select"
                                    value={eTime}
                                    options={type == 3 ? sTime ? constructEndTimeOptions(sTime) : constructTimeOptions() : constructTimeOptions()}
                                    onChange={(e) =>
                                      handleServiceModalChange(
                                        "eTime",
                                        e,
                                        user_id,
                                        type,
                                        uniqueServiceId
                                      )
                                    }
                                    isOptionDisabled={(option) =>
                                      (isPastDate && !canUpdatePastDate) ? true : (type !== 3 && checkOption(
                                        sTime || {},
                                        eTime || {},
                                        option,
                                        "end"
                                      ))
                                    }
                                  />
                                </InputGroup>
                              </div>
                            </div>
                            {(type == 3 || type == 4) && (
                              <Form.Group className="sch-choosetime-description">
                                <Form.Label htmlFor="sch-choosetime-description">
                                  <Form.Control as="textarea" id="sch-choosetime-description" rows={3} placeholder="Description" value={description} onChange={(e) => {
                                    handleServiceModalChange(
                                      "description",
                                      e.target.value,
                                      user_id,
                                      type,
                                      uniqueServiceId
                                    );
                                  }} />
                                </Form.Label>
                              </Form.Group>
                            )}
                            <div className="scheduler-button-wrapper">
                              <button
                                className="btn btn-cancel"
                                onClick={() =>
                                  handleServiceModalChange(
                                    "selectedAcc",
                                    null,
                                    user_id,
                                    type,
                                    uniqueServiceId
                                  )
                                }
                              >
                                Cancel
                              </button>
                              <button
                                className="btn btn-add"
                                onClick={() =>
                                  addFacilityToSchedule(
                                    sTime,
                                    eTime,
                                    description,
                                    user_id,
                                    type,
                                    uniqueServiceId,
                                    userIndex,
                                    name,
                                    block_id,
                                    serviceIndex,
                                    timezone
                                  )
                                }
                                disabled={
                                  !(
                                    Object.keys(sTime).length > 0 &&
                                    Object.keys(eTime).length
                                    && timezone && timezone.value)
                                }
                              >
                                {startTime && endTime
                                  ? "Save Changes"
                                  : "Add To Schedule"}
                              </button>
                            </div>
                          </React.Fragment>
                        )}
                        {!isPastDate && !addEditTimeBlock && (type == null || type == 1) && (
                          <>
                            {(canAddSurge || canAddNonService) && <div className="d-flex justify-content-between py-2 surge-event-wrapper m-auto">
                              {canAddSurge && <Button
                                className={`add-user-btn roboto-medium font-size-14 d-flex align-items-center add-surge-btn ${modalType === "surge" ? "active" : ""}`}
                                onClick={() => toggleSurgeOrNonService(user_id, type, block_id, modalType, "surge")}
                              >
                                <span className="icon-add-user">
                                  <span className="visually-hidden">Add Surge Facility</span>
                                </span>
                                <span className="add-surge-label">Add Surge Facility</span>
                              </Button>}
                              {canAddNonService && <Button
                                className={`add-user-btn roboto-medium font-size-14 d-flex align-items-center add-surge-btn ${modalType === "nonService" ? "active" : ""}`}
                                onClick={() => toggleSurgeOrNonService(user_id, type, block_id, modalType, "nonService")}
                              >
                                <span className="icon-non_service">
                                  <span className="visually-hidden">Add Non-Service Day</span>
                                </span>
                                <span className="add-surge-label"> Add Non-Service Day</span>
                              </Button>}

                            </div>}
                            <div id="surge-unique-div">
                              {modalType && (canAddSurge || canAddNonService) && <div className="tracker-search scheduler-tabs-wrapper surge-non-service-search-div">
                                <div className="search-input position-relative">
                                  <label htmlFor="tracker-search" className="visually-hidden">
                                    Search
                                  </label>
                                  <input
                                    id="tracker-search"
                                    type="text"
                                    placeholder="Search"
                                    className="form-control search-box w-100"
                                    onChange={e => {
                                      dispatch(setSurgeOrNonServiceModal({
                                        [`${user_id}|${type}|${block_id}`]: {
                                          ...surgeOrNonServiceModal[`${user_id}|${type}|${block_id}`],
                                          searchVal: e.target.value,
                                          isOpen: e.target.value ? true : false
                                        }
                                      }))
                                    }}
                                    value={searchVal}
                                    autoComplete="off"
                                  />
                                  <span className="search-icon"><span className="visually-hidden">Search Icon</span></span>
                                </div>
                              </div>}
                              {modalType && searchVal && (canAddSurge || canAddNonService) &&
                                <div className="tracker-wrapper" id="facilities-list-wrapper" ref={el => listRefs.current[serviceIndex] = el}>
                                  <div className="tracker-result sr-dropdown-container">
                                    {!showNoResults ? <ul>
                                      {surgeOrNonServiceFacilitiesData.map((result, index) => {
                                        return ((!searchVal || result.account_name.toLowerCase().search(searchVal.toLowerCase()) !== -1) &&
                                          <li
                                            key={index}
                                            onClick={() => selectFacility(result, userIndex, uniqueServiceId, modalType === "surge" ? 5 : 6, type)}
                                            className="me-0"
                                          >
                                            {result.account_name}
                                          </li>
                                        );
                                      })}
                                    </ul> : <p className="light-black-color font-size-12 ps-2">	No results found</p>
                                    }
                                  </div>
                                </div>}
                            </div>
                          </>
                        )}
                        {isPresent && (
                          <React.Fragment>
                            {type == null || type == 1 ? (
                              Object.keys(zones).length > 0 ? (
                                <Zone
                                  zones={zones}
                                  date={date}
                                  serviceIndex={serviceIndex}
                                  userIndex={userIndex}
                                  sectionType={sectionType}
                                  handleDelete={props.handleDelete}
                                  deleteIndex={props.deleteIndex}
                                  constructPyloadForCheckinCheckout={constructPyloadForCheckinCheckout}
                                  constructPayloadToClearCheckinCheckoutSpin={constructPayloadToClearCheckinCheckoutSpin}
                                  user_id={user_id}
                                  getScheduleBoardData={getScheduleBoardData}
                                  uniqueServiceId={uniqueServiceId}
                                  blockId={block_id || null}
                                  searchText={searchText}
                                  searchType={searchType}
                                  hasRouteStarted={hasRouteStarted}
                                  showDropArea={showDropArea}
                                  timezone_ids={timezone_ids}
                                  showInactiveFac={showInactiveFac}
                                  viewAttachment={viewAttachment}
                                />
                              ) : sectionType === "scheduleBoard" &&
                                !isEdit ? (
                                getEmptyDroppable(userIndex, serviceIndex, block_id, uniqueServiceId, showDropArea)
                              ) : (
                                ""
                              )
                            ) : (type == 3 || type == 4) && !isEdit && !_.isEmpty(desc) ? (
                              <p className="drop-facility-zone-text">
                                {desc}
                              </p>
                            ) : drop_off.map((data, index) => {
                              const { drop_point, time, drop_location, id, timezone = {} } = data;
                              return (
                                <React.Fragment key={index}>
                                  <DropOffOverlay loadOptions={loadOptions} loadOptionsHos={loadOptionsHos} overlayType={drop_point} handleChangeDropOffLatLng={handleChangeDropOffLatLng} handleChangeDropOff={handleChangeDropOff} addNewDroppOff={(overlayType, id) => addNewDroppOff(overlayType, id, data, userIndex, serviceIndex, uniqueServiceId)} setNewDropOff={setNewDropOff} newDropOff={newDropOff}
                                    children={
                                      <div className={`service-locations drop-off-service ${drop_point === "LAB" ? "labname" : drop_point.toLowerCase()}`}>
                                        <p className="title"><span>{time} {timezone ? timezone.abbreviation || "" : ""}</span> <span className="popover-dropoff-name">{drop_point === "COURIER" ? "Courier" : drop_location}</span></p>
                                        <span className="service-icon icon"><span className="visually-hidden">Drop Off Icons</span></span>
                                      </div>}
                                    dropOffData={data}
                                    dropOffId={id}
                                    tempCourierAddress={tempCourierAddress}
                                    setTempCourierAddress={setTempCourierAddress}
                                    hasRouteStarted={hasRouteStarted}
                                    timezone_ids={timezone_ids}
                                    showInvalidHosError={showInvalidHosError}
                                    setShowInvalidHosError={setShowInvalidHosError}
                                  />
                                </React.Fragment>
                              )
                            })}
                          </React.Fragment>
                        )}
                        {sectionType === "scheduleBoard" && (type == null || type == 1) && !isPresent && showDropArea ? (
                          getEmptyDroppable(userIndex, serviceIndex, block_id, uniqueServiceId, showDropArea, droppableId)
                        ) : (
                          ""
                        )}
                        {type === 5 ?
                          <DropOffOverlay
                            tempCourierAddress={tempCourierAddress}
                            setTempCourierAddress={setTempCourierAddress}
                            dropOffId={"newDropOff" + userIndex} loadOptions={loadOptions} loadOptionsHos={loadOptionsHos} overlayType="add" handleChangeDropOffLatLng={handleChangeDropOffLatLng} handleChangeDropOff={handleChangeDropOff} addNewDroppOff={(overlayType, id, lat, lng, typeId) => addNewDroppOff(overlayType, id, {}, userIndex, serviceIndex, uniqueServiceId)} setNewDropOff={setNewDropOff} newDropOff={newDropOff}
                            children={<a className="d-inline-block roboto-medium font-size-14 add-dropoff-title px-3 py-2">+ add drop off</a>}
                            hasRouteStarted={hasRouteStarted}
                            timezone_ids={timezone_ids}
                            showInvalidHosError={showInvalidHosError}
                            setShowInvalidHosError={setShowInvalidHosError}
                          />
                          : <></>
                        }
                      </Accordion.Body>
                      {(type === 1 || type === null) && routineDrawsLastIndex === serviceIndex &&
                        <>
                          <a className="d-inline-block roboto-medium font-size-14 add-dropoff-title px-3 py-2"
                            onClick={() => addNewRoutineService(user_id)}>+ add Routine Draws</a>
                          {/* <p className="roboto-medium font-size-14 add-dropoff-title px-3 py-2">- remove Routine Draws</p> */}
                        </>
                      }
                      {type === 3 && onCallsLastIndex === serviceIndex &&
                        <>
                          <a className="d-inline-block roboto-medium font-size-14 add-dropoff-title px-3 py-2"
                            onClick={() => addNewOnCallService(user_id)}>+ add on Call</a>
                          {/* <p className="roboto-medium font-size-14 add-dropoff-title px-3 py-2">- remove on Call</p> */}
                        </>
                      }
                    </Accordion.Item>
                  </Accordion>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            // )
          );
        })
      ) : Object.keys(zones).length > 0 ? (
        <React.Fragment>
          <div className="service-type routine-draws">
            <span className="service-icon"><span className="visually-hidden">Routine Draws</span></span>
            <p className="title">{serviceName}</p>
          </div>
          <Zone
            zones={zones}
            date={date}
            serviceIndex={0}
            sectionType={sectionType}
            handleDelete={props.handleDelete}
            deleteIndex={props.deleteIndex}
            constructPyloadForCheckinCheckout={constructPyloadForCheckinCheckout}
            constructPayloadToClearCheckinCheckoutSpin={constructPayloadToClearCheckinCheckoutSpin}
            user_id={user_id}
            getScheduleBoardData={getScheduleBoardData}
            searchText={searchText}
            searchType={searchType}
            hasRouteStarted={hasRouteStarted}
            showDropArea={showDropArea}
            timezone_ids={timezone_ids}
            showInactiveFac={showInactiveFac}
            viewAttachment={viewAttachment}
          />
        </React.Fragment>

      ) : (
        <p className="no-routine-alert">
          No Routine draws for the selected region, territory & zone combination
        </p>
      )}
    </React.Fragment>
  );
};
