import { useLoadScript } from "@react-google-maps/api";
import { AutoCompleteAddress } from "./AutoCompleteAddress";
import { GOOGLE_API_KEY } from "../../app/utils/Constants";

const libraries = ['places'];

export default function PlacesApiLoader(props) {

    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: GOOGLE_API_KEY,
      libraries,
    });
    return !isLoaded || loadError ? null : <AutoCompleteAddress {...props} />;
  }
  