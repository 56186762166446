import * as React from "react";
import { Droppable } from "@hello-pangea/dnd";
import { SCHEDULE_SERVICE_TYPE, getTimeByFormat, isOnCallScheduleDateAfterDeployment, serviceTypes } from "../../../app/utils/Constants";
import { Form, InputGroup, Accordion, Button } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import { DropOffOverlay } from "../DropOffOverlay";
import uuid from 'react-uuid'
import { OnCallTerritory } from "./onCallTerritory";
import { onCallSurgeModalData, setOnCallSurgeModalData } from "../operations/getSurgeOrNonServiceFacilitiesSlice";
import { useDispatch, useSelector } from "react-redux";
import ahaApi from "../../../app/services/aha-api";

const timeRegex = new RegExp(':', 'g');

const uuid1 = uuid();
const uuid2 = uuid();

const constructTimeOptions = () => {
	let options = [];
	for (let i = 0; i < 24; i++) {
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:00`,
			value: `${i < 10 ? "0" : ""}${i}:00`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:15`,
			value: `${i < 10 ? "0" : ""}${i}:15`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:30`,
			value: `${i < 10 ? "0" : ""}${i}:30`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:45`,
			value: `${i < 10 ? "0" : ""}${i}:45`,
		});
	}
	options = options.map((opt, index) => {
		return { ...opt, id: index };
	});
	return options;
};

const constructEndTimeOptions = (startTime) => {
	let options = [];
	for (let i = 0; i < 24; i++) {
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:00`,
			value: `${i < 10 ? "0" : ""}${i}:00`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:15`,
			value: `${i < 10 ? "0" : ""}${i}:15`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:30`,
			value: `${i < 10 ? "0" : ""}${i}:30`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:45`,
			value: `${i < 10 ? "0" : ""}${i}:45`,
		});
	}
	options = options.map((opt, index) => {
		return { ...opt, id: index, label: startTime.id >= index ? `${opt.label} +1` : opt.label };
	});
	return options;
};

const getEmptyDroppable = (userIndex, serviceIndex, blockId = null, uniqueServiceId, userType, showBorder) => {
	const droppableId =
		`emptyUser/${blockId}/${uniqueServiceId}` + "|" + userIndex + "|" + serviceIndex + "|" + "inner";
	return (
		<Droppable droppableId={droppableId}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					className={`${snapshot.isDraggingOver ? "droppable-area-active " : showBorder ? "droppable-area-inactive " : " "} ${showBorder ? "droppable-area-border " : " "}`}
					// style={{ opacity: snapshot.isDraggingOver ? 0.8 : 1, backgroundColor: snapshot.isDraggingOver ? "#ebf6f4" : "", border: showBorder ? '5px dotted green' : "none" }}
					{...provided.droppableProps}
				>
					<p className="drop-facility-zone-text">
						Drag zones here from the{" "}
						<span className="roboto-medium">Task Bar</span> to add to {userType === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? "no coverage's schedule" : "a person’s schedule"}
					</p>
          {provided.placeholder}
				</div>
			)}
		</Droppable>
	);
};

const getFormattedServices = (services = [], userType) => {
	let formattedServices = [];
	if (userType === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID) {
		if (services.some((service) => service.service_name === "No Coverage")) {
			services.forEach((service, index) => {
				if (service.service_name === "No Coverage") {
					formattedServices.push({
						...service,
						class: "on-call",
						isPresent: true,
						// id: index,
						// uniqueServiceId: uuid3
					});
				}
			});

		} else {
			formattedServices.push({
				service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID,
				service_name: serviceTypes[SCHEDULE_SERVICE_TYPE.ON_CALL.ID].name,
				start_time: null,
				end_time: null,
				class: "on-call",
				isPresent: false,
				uniqueServiceId: uuid1
			});
		}
	} else {
		if (services.some((service) => service.service_name === "On Call")) {
			services.forEach((service, index) => {
				if (service.service_name === "On Call") {
					formattedServices.push({
						...service,
						class: "on-call",
						isPresent: true,
						// id: index,
						// uniqueServiceId: uuid3
					});
				}
			});

		} else {
			formattedServices.push({
				service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID,
				service_name: serviceTypes[SCHEDULE_SERVICE_TYPE.ON_CALL.ID].name,
				start_time: null,
				end_time: null,
				class: "on-call",
				isPresent: false,
				uniqueServiceId: uuid1
			});
		}
	}

	// if (services.some((service) => service.drop_off)) {
	// 	let serviceData = {};
	// 	services.forEach((service) => {
	// 		if (service.drop_off) {
	// 			serviceData = service;
	// 		}
	// 	});
	// 	formattedServices.push({
	// 		...serviceData,
	// 		class: "drop-off",
	// 		service_type: 5,
	// 		isPresent: true,
	// 		// uniqueServiceId: uuid4
	// 	});
	// } else {
	// 	formattedServices.push({
	// 		service_type: 5,
	// 		service_name: serviceTypes[5].name,
	// 		start_time: null,
	// 		end_time: null,
	// 		class: "drop-off",
	// 		isPresent: false,
	// 		uniqueServiceId: uuid2
	// 	});
	// }
	return formattedServices;
};

export const OnCallService = React.memo((props) => {
	const {
		services = [],
		sectionType,
		userIndex,
		user_id,
		date,
		updateBoardData,
		handleDeleteService,
		constructPyloadForCheckinCheckout,
		getScheduleBoardData,
		deleteServiceData,
		setDeleteServiceData,
		searchText,
		searchType,
		isPastDate,
		userType,
		users,
		showDropArea,
		shiftTimingsList,
		timezone_ids,
		zonesForSurge = [],
		addZoneToSurge,
	} = props;
	const [serviceModal, setServiceModal] = React.useState({});
	const [onCalls, setOnCalls] = React.useState([]);
	const [noCoverages, setNoCoverages] = React.useState([]);
	const [show, setShow] = React.useState({});
	const [showNew, setShowNew] = React.useState({});
	const [newDropOff, setNewDropOff] = React.useState({});

	const onCallSurgeModal = useSelector(onCallSurgeModalData);
	const dispatch = useDispatch();

	const listRefs = React.useRef([]);

	const { dropOffLat = "", dropOffLng = "", dropOffType = "lab", time = "", name = "" } = newDropOff;

	React.useEffect(() => {
		if (deleteServiceData) {
			setDeleteServiceData(null);
		}

	}, [deleteServiceData])

	const handleServiceModalChange = (
		property,
		value,
		userId,
		serviceType = 1,
		serviceIndex,
		sTime,
		eTime,
		description,
		shift,
		timezone
	) => {
		if (
			(property === "selectedAcc" && value === null) ||
			property === "isEdit"
		) {
			// property === "selectedAcc" && value === null -> closing the modal
			//property === "isEdit" -> editing the modal
			setServiceModal({
				...serviceModal,
				[`${userId}|${serviceType}|${serviceIndex}`]: {
					...serviceModal[`${userId}|${serviceType}|${serviceIndex}`],
					sTime: value
						? sTime
							? { label: sTime, value: sTime, id: getId(sTime) }
							: ""
						: "",
					eTime: value
						? eTime
							? { label: eTime, value: eTime, id: getId(eTime) }
							: ""
						: "",
					[property]: value,
					isEdit: property === "isEdit" ? value : false,
					description: value ? description : "",
					shift: value ? (shift && Object.keys(shift).length) ?  { ...shift, label: `${shift.shift_name || ""} ${getTimeByFormat(sTime, shift.suffix)} to ${getTimeByFormat(eTime, shift.suffix)}`, value: shift.shift_allocation_id} : "" : "",
					timezone: value ? timezone ? { ...timezone, label: timezone.timezone_name, value: timezone.timezone_id } : "" : "",
				},
			});
		} else {
			if (property === "shift") {
				setServiceModal({
					...serviceModal,
					[`${userId}|${serviceType}|${serviceIndex}`]: {
						...serviceModal[`${userId}|${serviceType}|${serviceIndex}`],
						[property]: value,
						sTime: sTime
							? { label: sTime, value: sTime, id: getId(sTime) }
							: "",
						eTime: eTime
							? { label: eTime, value: eTime, id: getId(eTime) }
							: "",
              isCustomTime: false
					}
				});
			} else {
        const isCustomTime = serviceModal[`${userId}|${serviceType}|${serviceIndex}`] ? serviceModal[`${userId}|${serviceType}|${serviceIndex}`].isCustomTime : false
				setServiceModal({
					...serviceModal,
					[`${userId}|${serviceType}|${serviceIndex}`]: {
						...serviceModal[`${userId}|${serviceType}|${serviceIndex}`],
						[property]: value,
						isCustomTime: property === "sTime" || property === "eTime" ? true: isCustomTime
					},
				});
			}
		}
	};

	const checkOption = (sTime, eTime, option, type = "start") => {
		return type === "start"
			? Object.keys(eTime).length === 0
				? false
				: option.id >= (eTime.id || 0)
			: Object.keys(sTime).length === 0
				? false
				: option.id <= (sTime.id || 0);
	};

	const getId = (time) => {
		let result = 0;
		const options = constructTimeOptions();
		options.forEach((op) => {
			if (op.value === time) {
				result = op.id;
			}
		});
		return result;
	};

	const addFacilityToSchedule = (
		sTime = {},
		eTime = {},
		description,
		userId,
		serviceType,
		uniqueServiceId,
		userIndex,
		serviceName,
		blockId,
		serviceIndex,
		userType,
		shift = {},
		timezone = {},
		isCustomTime,
    territories
	) => {
		if (Object.keys(sTime).length > 0 && Object.keys(eTime).length > 0) {
      // let zoneIds = [];
      // territories && Object.keys(territories).length && Object.keys(territories).forEach(terr => {
      //   territories[terr] && territories[terr].events && Object.keys(territories[terr].events).length && 
      //   Object.keys(territories[terr].events).forEach(eve => {
      //     territories[terr].events[eve] && territories[terr].events[eve].zones &&
      //     territories[terr].events[eve].zones.forEach(z => {
      //       zoneIds.push(z.zone_id)
      //     });
      //   });
      // });
			const payload = {
				service_type_id: serviceType || 1,
				start_time: sTime.value,
				end_time: eTime.value,
				status: "ACTIVE",
				facility_ids: [],
				person_id: user_id,
				current_person_id: null,
				event_date: moment(date).format("YYYY-MM-DD"),
				current_event_date: null,
				description,
				block_id: blockId,
				service_index: serviceIndex,
				uniqueServiceId: uniqueServiceId,
				shift_name: !isCustomTime ? shift.shift_name : "",
				shift_allocation_id: !isCustomTime ? shift.value || "" : "",
				timezone_id: timezone.value,
				timezone_label: timezone.label,
        timezoneData: timezone,
        // zone_ids: zoneIds
			};
			if (userType === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID) {
				payload.current_service_type_id = null;
				payload.service_type_id = SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID;
			}
			updateBoardData(userIndex, payload, serviceName, "onCall");
			let tempServiceModal = { ...serviceModal };
			delete tempServiceModal[`${userId}|${serviceType}|${uniqueServiceId}`];

			if (serviceName === "On Call" && onCalls.some(service => service.uniqueServiceId === uniqueServiceId)) {
				const tempOnCalls = onCalls.filter(service => service.uniqueServiceId !== uniqueServiceId);
				setOnCalls(tempOnCalls);
			} else if (serviceName === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME && noCoverages.some(service => service.uniqueServiceId === uniqueServiceId)) {
				const tempNoCoverages = noCoverages.filter(service => service.uniqueServiceId !== uniqueServiceId);
				setNoCoverages(tempNoCoverages);
			}
			setServiceModal(tempServiceModal);
		}
	};

	const handleChangeDropOff = (property, value) => {
		setNewDropOff({ ...newDropOff, [property]: value });
	}

	const handleChangeDropOffLatLng = (data) => {
		setNewDropOff({ ...newDropOff, ...data });
	}

	const addNewDroppOff = (dropOffId, overlayType, userIndex, serviceIndex, uniqueServiceId, zoneIndex = -1, routineIndex = -1, facilityIndex = -1) => {
		let payload = {
			event_id: 0,
			user_id: props.user_id,
			drop_off: {
				id: dropOffId,
				action_time: time.value,
			}
		};
		let tempType = dropOffId ? overlayType : dropOffType;
		tempType = tempType.toLowerCase();
		if (tempType === "lab") {
			payload.drop_off = {
				...payload.drop_off,
				lab_id: name.value,
				latitude: name.latitude,
				longitude: name.longitude
			}
		} else if (tempType === "hospital") {
			payload.drop_off = {
				...payload.drop_off,
				hospital_id: name.value,
				latitude: name.latitude,
				longitude: name.longitude
			}
		} else {
			payload.drop_off = {
				...payload.drop_off,
				is_courier_drop_off: true,
				latitude: parseFloat(dropOffLat),
				longitude: parseFloat(dropOffLng)
			}
		}
		constructPyloadForCheckinCheckout(payload, userIndex, serviceIndex, uniqueServiceId, zoneIndex, routineIndex, facilityIndex)
	}

	const loadOptions = async (text, callback) => {
		if (!text) {
			return callback([]);
		}
		const data = {
			search_text: text
		}
		const endPoint = ahaApi.Account.labsList;
		const response = await endPoint(data)
			.then((res) => {
				const { content = [] } = res;
				return content.map(c => { return { ...c, label: c.lab_name, value: c.id } });
			})
			.catch((err) => {
				console.log(err, "error");
			});
		return response;
	}

	const loadOptionsHos = async (text, callback) => {
		if (!text) {
			return callback([]);
		}
		const data = {
			search_text: text
		}
		const endPoint = ahaApi.Account.hospitalsList;
		const response = await endPoint(data)
			.then((res) => {
				const { content = [] } = res;
				return content.map(c => { return { ...c, label: c.hospital_name, value: c.id } });
			})
			.catch((err) => {
				console.log(err, "error");
			});
		return response;
	}

	const mergeServices = (services, userType, onCalls, noCoverages) => {
		let result;
		if (userType === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID) {
			const noCoverageIndex = services.map(function (service) { return service.service_name; }).lastIndexOf(SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME);

			const tempNoCoverages = [];
			noCoverages.forEach(tmpSer => {
				if (!services.some(service => tmpSer.uniqueServiceId === service.uniqueServiceId)) {
					tempNoCoverages.push(tmpSer);
				}
			})
			result = services.splice(noCoverageIndex + 1, 0, ...tempNoCoverages) && services;
		} else {
			const onCallIndex = services.map(function (service) { return service.service_name; }).lastIndexOf(SCHEDULE_SERVICE_TYPE.ON_CALL.NAME);

			const tempOnCalls = [];
			onCalls.forEach(tmpSer => {
				if (!services.some(service => tmpSer.uniqueServiceId === service.uniqueServiceId)) {
					tempOnCalls.push(tmpSer);
				}
			})
			result = services.splice(onCallIndex + 1, 0, ...tempOnCalls) && services;
		}
		return result;
	}

	const addNewOnCallService = (userId, userType, serviceName) => {
		const uniqueServiceId = uuid();
		let tempOnCalls = [...onCalls];
		let tempNoCoverages = [...noCoverages];
		if (userType == SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID) {
			tempNoCoverages.push({
				service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID,
				service_name: serviceName,
				start_time: null,
				end_time: null,
				territories: [],
				class: "on-call",
				isPresent: false,
				isTemp: true,
				uniqueServiceId
			});
			setNoCoverages(tempNoCoverages);
		} else {
			tempOnCalls.push({
				service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID,
				service_name: serviceName,
				start_time: null,
				end_time: null,
				territories: [],
				class: "on-call",
				isPresent: false,
				isTemp: true,
				uniqueServiceId
			});
			setOnCalls(tempOnCalls);
		}
		handleServiceModalChange(
			"selectedAcc",
			"on-call",
			userId,
			SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID : SCHEDULE_SERVICE_TYPE.ON_CALL.ID,
			uniqueServiceId
		)
	}

	const deleteOnCallService = (uniqueServiceId, userId, userType) => {
		if (userType === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID) {
			let tempNoCoverages = [...noCoverages];
			tempNoCoverages = tempNoCoverages.filter((service, index) => service.uniqueServiceId !== uniqueServiceId);
			setNoCoverages(tempNoCoverages);
			const tempServiceModal = { ...serviceModal };
			if (tempServiceModal[`${userId}|${SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID}|${uniqueServiceId}`]) {
				delete tempServiceModal[`${userId}|${SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID}|${uniqueServiceId}`];
			}
			setServiceModal(tempServiceModal)
		} else {
			let tempOnCalls = [...onCalls];
			tempOnCalls = tempOnCalls.filter((service, index) => service.uniqueServiceId !== uniqueServiceId);
			setOnCalls(tempOnCalls);
			const tempServiceModal = { ...serviceModal };
			if (tempServiceModal[`${userId}|${SCHEDULE_SERVICE_TYPE.ON_CALL.ID}|${uniqueServiceId}`]) {
				delete tempServiceModal[`${userId}|${SCHEDULE_SERVICE_TYPE.ON_CALL.ID}|${uniqueServiceId}`];
			}
			setServiceModal(tempServiceModal)
		}
	}

	const getDateTime = (startTime = "", endTime = "") => {
		const tempDate = new Date(date);
		tempDate.setDate(tempDate.getDate() + 1);
		const dateAndTime = parseInt(startTime.replace(timeRegex, ''), 10) >= parseInt(endTime.replace(timeRegex, ''), 10) ?
			moment(tempDate).format("MMM DD").toUpperCase()
			: moment(date).format("MMM DD").toUpperCase()
		return dateAndTime;
	}

	const toggleSurgeOrNonService = (userId, type, uniqueServiceId, modalType, newType) => {
		dispatch(setOnCallSurgeModalData({
			[`${userId}|${type}|${uniqueServiceId}`]: {
				...onCallSurgeModal[`${userId}|${type}|${uniqueServiceId}`],
				modalType: modalType === newType ? "" : newType,
				searchVal: ""
			}
		}))
	}

	const selectZone = (zone, userIndex, uniqueServiceId, serviceType, type) => {
		addZoneToSurge(userIndex, uniqueServiceId, serviceType, zone, type)
	}

	// routine draws facilities for backlog
	const { territories = {}, service_name: serviceName } = services[0] || {};

	let finalServices = getFormattedServices(services, userType);
	// merge local services with board services
	finalServices = mergeServices(finalServices, userType, onCalls, noCoverages);
	// get last index of Routine Draws
	const onCallsLastIndex = finalServices.map(function (service) { return service.service_name; }).lastIndexOf(SCHEDULE_SERVICE_TYPE.ON_CALL.NAME);
	const noCoverageLastIndex = finalServices.map(function (service) { return service.service_name; }).lastIndexOf(SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME);


	let permission =
		(window.localStorage.getItem("userDetail") &&
			JSON.parse(window.localStorage.getItem("userDetail")).permission &&
			JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
		[];
	permission = permission.filter((perm) => perm.page === "events");
	const eventAccess = permission.length === 1 ? permission[0].access : [];
	const canDeleteBlock = eventAccess && eventAccess.find((item) => item === "delete") === "delete" ? true : false;
	const canAddSurge = eventAccess && eventAccess.find((item) => item === "surge-on-call") === "surge-on-call" ? true : false;
	const canUpdatePastDate = eventAccess && eventAccess.find((item) => item === "upsert-past-date") === "upsert-past-date" ? true : false;
	return (
		<React.Fragment>
			{sectionType === "scheduleBoard" ? (
				finalServices.map((service, serviceIndex) => {
					const {
						start_time: startTime,
						end_time: endTime,
						territories = {},
						details,
						service_name: name,
						service_type: type = SCHEDULE_SERVICE_TYPE.ON_CALL.ID,
						isPresent,
						drop_off = [],
						description: desc = "",
						block_id = null,
						isTemp = false,
						uniqueServiceId,
						start_day,
						end_day,
						shift_timings = {},
						timezone: timezoneData = {},
            delete_event = false
					} = service;
					const {
						selectedAcc = "",
						sTime = "",
						eTime = "",
						description = "",
						isEdit = false,
						shift = "",
						timezone = "",
						isCustomTime = false
					} = serviceModal[`${user_id}|${type}|${uniqueServiceId}`] || {};

					const { modalType = "", searchVal = "", isOpen = false } = onCallSurgeModal[`${user_id}|${type}|${block_id}`] || {};

					const droppableId =
						`emptyUser/${block_id}/${uniqueServiceId}` + "|" + userIndex + "|" + serviceIndex;

            const allZones = [];

            territories && Object.keys(territories).forEach(tt => {
              if (territories[tt].events) {
                Object.keys(territories[tt].events).forEach(eId => {
                  if (territories[tt].events[eId].zones) {
                    territories[tt].events[eId].zones.forEach(zn => {
                      allZones.push(zn);
                    })
                  }
                })
              }
            });

					const addEditTimeBlock = isEdit || !isPresent;

					const activeKey = isPresent ? service.class : selectedAcc;

					const onCallSurgeFacilities = modalType === "surge" ? zonesForSurge : [];
					const showNoResults = !onCallSurgeFacilities.some(result => (!searchVal || result.zone_name.toLowerCase().search(searchVal.toLowerCase()) !== -1))

					return (
            <Droppable
              droppableId={droppableId}
              isDropDisabled={
                (name !== "On Call" && name !== "No Coverage") ||
                ((name == "On Call" || name == "No Coverage") &&
                  Object.keys(territories).length !== 0)
              }
              key={uniqueServiceId}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className={`${
                    snapshot.isDraggingOver
                      ? "droppable-area-active "
                      : showDropArea
                      ? "droppable-area-inactive "
                      : " "
                  }`}
                  // style={{ opacity: snapshot.isDraggingOver ? 0.5 : 1 }}
                  {...provided.droppableProps}
                >
                  <Accordion
                    activeKey={activeKey}
                    className='schedule-accordian-wrapper'
                    onSelect={(val) =>
                      isPresent
                        ? handleServiceModalChange(
                            "isEdit",
                            !isEdit,
                            user_id,
                            type,
                            uniqueServiceId,
                            startTime,
                            endTime,
                            desc,
                            shift_timings,
                            timezoneData
                          )
                        : handleServiceModalChange(
                            "selectedAcc",
                            val,
                            user_id,
                            type,
                            uniqueServiceId
                          )
                    }
                  >
                    <Accordion.Item eventKey={service.class}>
                      <div className='schedule-options'>
                        {(!isPresent ? isTemp : true) && (name == "No Coverage" ? delete_event : true) &&
                          canDeleteBlock && (
                            <>
                              <span
                                className={`schedule-delete active`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (isTemp) {
                                    deleteOnCallService(
                                      uniqueServiceId,
                                      user_id,
                                      type
                                    )
                                  } else {
                                    handleDeleteService(
                                      user_id,
                                      type,
                                      userIndex,
                                      service,
                                      allZones
                                    );
                                  }
                                }}
                              >
                                <span className='visually-hidden'>
                                  Schedule Delete Icon
                                </span>
                              </span>
                            </>
                          )}
                        {selectedAcc &&
                          (!isPresent ? !isTemp : false) && (
                            <span className={`schedule-delete disable`}></span>
                          )}
                      </div>
                      <Accordion.Header
                        className={`${service.class} ${
                          addEditTimeBlock && activeKey === service.class
                            ? "time-block-open"
                            : ""
                        }`}
                      >
                        <span className='service-icon'>
                          <span className='visually-hidden'>
                            Service Type Icon
                          </span>
                        </span>
                        <div className='accordian-head'>
                          {/* Displaying Service Name as On Call */}
                          <p
                            className={
                              service.class === "drop-off"
                                ? "sub-title"
                                : "title"
                            }
                          >
                            {SCHEDULE_SERVICE_TYPE.ON_CALL.NAME}
                          </p>
                          <p className='sub-title'>
                            {sectionType === "scheduleBoard" &&
                              <>
                                <p className="sch-date-time-header">
                                  {startTime && endTime
                                    ? `${start_day
                                      ? start_day
                                      : `${moment(date)
                                        .format("MMM DD")
                                        .toUpperCase()}, `
                                    } ${startTime} ${timezoneData
                                      ? timezoneData.abbreviation || ""
                                      : ""
                                    } - ${end_day
                                      ? end_day
                                      : startTime && endTime
                                        ? `${getDateTime(
                                          startTime,
                                          endTime
                                        )}, `
                                        : ""
                                    } ${endTime} ${timezoneData
                                      ? timezoneData.abbreviation || ""
                                      : ""
                                    }`
                                    : "Add To Schedule"}
                                </p>
                                <p className="sch-date-timezone-header">
                                  {shift_timings
                                    ? shift_timings.shift_name || ""
                                    : ""}
                                </p>
                              </>
                              }
                          </p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {addEditTimeBlock && (
                          <React.Fragment>
                            <div className='choose-shift-wrapper'>
                              <div className='schedule-start-time'>
                                <InputGroup className='mb-3'>
                                  <Form.Label htmlFor='schedule-shift-name'>
                                    Shift Name
                                  </Form.Label>
                                  <Select
                                    name='schedule-shift-name'
                                    id='schedule-shift-name'
                                    className='sch-schedule-dropdown'
                                    classNamePrefix='sch-schedule-select'
                                    value={shift}
                                    options={
                                      shiftTimingsList
                                        ? shiftTimingsList.map((item) => {
                                            return {
                                              ...item,
                                              label: `${
                                                item.shift_name
                                              } ${getTimeByFormat(
                                                item.start_time,
                                                item.suffix
                                              )} to ${getTimeByFormat(
                                                item.end_time,
                                                item.suffix
                                              )}`,
                                              value: item.shift_allocation_id,
                                            };
                                          })
                                        : []
                                    }
                                    onChange={(e) =>
                                      handleServiceModalChange(
                                        "shift",
                                        e,
                                        user_id,
                                        type,
                                        uniqueServiceId,
                                        e.start_time,
                                        e.end_time
                                      )
                                    }
                                    // isDisabled={block_id && (!shift || !shift.shift_allocation_id)}
                                  />
                                </InputGroup>
                              </div>
                            </div>
                            <div className='choose-shift-wrapper'>
                              <div className='schedule-start-time'>
                                <InputGroup className='mb-3'>
                                  <Form.Label htmlFor='schedule-timezone'>
                                    Timezone
                                  </Form.Label>
                                  <Select
                                    name='schedule-timezone'
                                    id='schedule-timezone'
                                    className='sch-schedule-dropdown'
                                    classNamePrefix='sch-schedule-select'
                                    value={timezone}
                                    options={timezone_ids || []}
                                    // isDisabled={true}
                                    onChange={(e) =>
                                      handleServiceModalChange(
                                        "timezone",
                                        e,
                                        user_id,
                                        type,
                                        uniqueServiceId,
                                        e.start_time,
                                        e.end_time
                                      )
                                    }
                                  />
                                </InputGroup>
                              </div>
                            </div>
                            {!isOnCallScheduleDateAfterDeployment(date) && <div className='choose-time-wrapper'>
                              <div className='schedule-start-time'>
                                <InputGroup className='mb-3'>
                                  <Form.Label htmlFor='schedule-start-time'>
                                    Start Time
                                  </Form.Label>
                                  <Select
                                    name='schedule-start-time'
                                    id='schedule-start-time'
                                    className='sch-schedule-dropdown'
                                    classNamePrefix='sch-schedule-select'
                                    value={sTime}
                                    options={constructTimeOptions()}
                                    onChange={(e) =>
                                      handleServiceModalChange(
                                        "sTime",
                                        e,
                                        user_id,
                                        type,
                                        uniqueServiceId
                                      )
                                    }
                                    // isOptionDisabled={(option) =>
                                    // 	(isPastDate && !canUpdatePastDate) ? true : (type !== SCHEDULE_SERVICE_TYPE.ON_CALL.ID && type !== SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID) && checkOption(
                                    // 		sTime,
                                    // 		eTime || 0,
                                    // 		option,
                                    // 		"start"
                                    // 	)
                                    // }
                                  />
                                </InputGroup>
                              </div>
                              <div className='seperator'>
                                <span className='service-icon'>
                                  <span className='visually-hidden'>
                                    Seperator
                                  </span>
                                </span>
                              </div>
                              <div className='schedule-end-time'>
                                <InputGroup className='mb-3'>
                                  <Form.Label htmlFor='schedule-end-time'>
                                    End Time
                                  </Form.Label>
                                  <Select
                                    name='schedule-end-time'
                                    id='schedule-end-time'
                                    className='sch-schedule-dropdown'
                                    classNamePrefix='sch-schedule-select'
                                    value={eTime}
                                    options={
                                        sTime ? constructEndTimeOptions(sTime) : constructTimeOptions()
                                    }
                                    onChange={(e) =>
                                      handleServiceModalChange(
                                        "eTime",
                                        e,
                                        user_id,
                                        type,
                                        uniqueServiceId
                                      )
                                    }
                                    // isOptionDisabled={(option) =>
                                    // 	(isPastDate && !canUpdatePastDate) ? true : (type !== SCHEDULE_SERVICE_TYPE.ON_CALL.ID && type !== SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID) && checkOption(
                                    // 		sTime || {},
                                    // 		eTime || {},
                                    // 		option,
                                    // 		"end"
                                    // 	)
                                    // }
                                  />
                                </InputGroup>
                              </div>
                            </div>}
                            <Form.Group className='sch-choosetime-description'>
                                <Form.Label htmlFor='sch-choosetime-description'>
                                  <Form.Control
                                    as='textarea'
                                    id='sch-choosetime-description'
                                    rows={3}
                                    placeholder='Description'
                                    value={description}
                                    onChange={(e) => {
                                      handleServiceModalChange(
                                        "description",
                                        e.target.value,
                                        user_id,
                                        type,
                                        uniqueServiceId
                                      );
                                    }}
                                  />
                                </Form.Label>
                              </Form.Group>
                            <div className='scheduler-button-wrapper'>
                              <button
                                className='btn btn-cancel'
                                onClick={() =>
                                  handleServiceModalChange(
                                    "selectedAcc",
                                    null,
                                    user_id,
                                    type,
                                    uniqueServiceId
                                  )
                                }
                              >
                                Cancel
                              </button>
                              <button
                                className='btn btn-add'
                                onClick={() => {
                                  addFacilityToSchedule(
                                    sTime,
                                    eTime,
                                    description,
                                    user_id,
                                    type,
                                    uniqueServiceId,
                                    userIndex,
                                    userType ===
                                      SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                                      ? SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME
                                      : name,
                                    block_id,
                                    serviceIndex,
                                    userType,
                                    shift,
                                    timezone,
                                    isCustomTime,
                                    territories
                                  );
                                }}
                                disabled={
                                  !(
                                    Object.keys(sTime).length > 0 &&
                                    Object.keys(eTime).length && timezone && timezone.value
                                  )
                                }
                              >
                                {startTime && endTime
                                  ? "Save Changes"
                                  : "Add To Schedule"}
                              </button>
                            </div>
                          </React.Fragment>
                        )}
                        {!isPastDate && !addEditTimeBlock && userType != SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID && (
                          <>
                            {canAddSurge && (
                              <div className='d-flex justify-content-between py-2 surge-event-wrapper m-auto'>
                                {canAddSurge && (
                                  <Button
                                    className={`add-user-btn roboto-medium font-size-14 d-flex align-items-center add-surge-btn ${
                                      modalType === "surge" ? "active" : ""
                                    }`}
                                    onClick={() =>
                                      toggleSurgeOrNonService(
                                        user_id,
                                        type,
                                        block_id,
                                        modalType,
                                        "surge"
                                      )
                                    }
                                  >
                                    <span className='icon-add-user'>
                                      <span className='visually-hidden'>
                                        Add Surge Zone
                                      </span>
                                    </span>
                                    <span className='add-surge-label'>
                                      Add Surge Zone
                                    </span>
                                  </Button>
                                )}
                              </div>
                            )}
                            <div id="surge-unique-div" onClick={(e) => e.stopPropagation()} >
                              {modalType && canAddSurge && (
                                <div className='tracker-search scheduler-tabs-wrapper surge-non-service-search-div'>
                                  <div className='search-input position-relative'>
                                    <label
                                      htmlFor='tracker-search'
                                      className='visually-hidden'
                                    >
                                      Search
                                    </label>
                                    <input
                                      id='tracker-search'
                                      type='text'
                                      placeholder='Search'
                                      className='form-control search-box w-100'
                                      onClick={(e) => e.stopPropagation()}
                                      onChange={(e) => {
                                        dispatch(
                                          setOnCallSurgeModalData({
                                            [`${user_id}|${type}|${block_id}`]: {
                                              ...onCallSurgeModal[
                                              `${user_id}|${type}|${block_id}`
                                              ],
                                              searchVal: e.target.value,
                                              isOpen: e.target.value
                                                ? true
                                                : false,
                                            },
                                          })
                                        );
                                      }}
                                      value={searchVal}
                                      autoComplete='off'
                                    />
                                    <span className='search-icon'>
                                      <span className='visually-hidden'>
                                        Search Icon
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              )}
                              {modalType && searchVal && canAddSurge && (
                                <div
                                  className='tracker-wrapper'
                                  id='facilities-list-wrapper'
                                  ref={(el) =>
                                    (listRefs.current[block_id] = el)
                                  }
                                >
                                  <div className='tracker-result sr-dropdown-container'>
                                    {!showNoResults ? (
                                      <ul>
                                        {onCallSurgeFacilities.map(
                                          (result, index) => {
                                            return (
                                              (!searchVal ||
                                                result.zone_name
                                                  .toLowerCase()
                                                  .search(
                                                    searchVal.toLowerCase()
                                                  ) !== -1) && (
                                                <li
                                                  key={index}
                                                  onClick={() =>
                                                    selectZone(
                                                      result,
                                                      userIndex,
                                                      uniqueServiceId,
                                                      modalType === "surge"
                                                        ? SCHEDULE_SERVICE_TYPE
                                                          .ON_CALL_SURGE.ID
                                                        : 0,
                                                      type
                                                    )
                                                  }
                                                  className='me-0'
                                                >
                                                  {result.zone_name}
                                                </li>
                                              )
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      <p className='light-black-color font-size-12 ps-2'>
                                        {" "}
                                        No results found
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                        {isPresent && (
                          <React.Fragment>
                            {!isEdit &&
                              !_.isEmpty(desc) && (
                                <p className='drop-facility-zone-text'>
                                  {desc}
                                </p>
                              )}
                            {Object.keys(territories).length > 0 ? (
                                <OnCallTerritory
                                  territories={territories}
                                  date={date}
                                  serviceIndex={serviceIndex}
                                  userIndex={userIndex}
                                  sectionType={sectionType}
                                  handleDelete={props.handleDelete}
                                  deleteIndex={props.deleteIndex}
                                  constructPyloadForCheckinCheckout={
                                    constructPyloadForCheckinCheckout
                                  }
                                  user_id={user_id}
                                  getScheduleBoardData={getScheduleBoardData}
                                  uniqueServiceId={uniqueServiceId}
                                  blockId={block_id || null}
                                  users={users}
                                  userType={userType}
                                  showDropArea={showDropArea}
                                />
                              ) : sectionType === "scheduleBoard" && !isEdit ? (
                                getEmptyDroppable(
                                  userIndex,
                                  serviceIndex,
                                  block_id,
                                  uniqueServiceId,
                                  userType,
                                  showDropArea
                                )
                              ) : (
                                ""
                              )
                            }
                          </React.Fragment>
                        )}
                      </Accordion.Body>
                      {(((type === SCHEDULE_SERVICE_TYPE.ON_CALL.ID || type === SCHEDULE_SERVICE_TYPE.ON_CALL_SURGE.ID) &&
                        onCallsLastIndex === serviceIndex) ||
                        (type == SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID &&
                          (noCoverageLastIndex === -1 ||
                            noCoverageLastIndex == serviceIndex))) && (
                        <>
                          <a
                            className='d-inline-block roboto-medium font-size-14 add-dropoff-title px-3 py-2'
                            onClick={() =>
                              addNewOnCallService(
                                user_id,
                                type,
                                type === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                                  ? SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME
                                  : name
                              )
                            }
                          >
                            + add on Call
                          </a>
                          {/* <p className="roboto-medium font-size-14 add-dropoff-title px-3 py-2">- remove on Call</p> */}
                        </>
                      )}
                    </Accordion.Item>
                  </Accordion>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            // )
          );
				})
			) : Object.keys(territories).length > 0 ? (
				<React.Fragment>
					<div className="service-type on-call">
						<span className="service-icon"><span className="visually-hidden">Routine Draws</span></span>
						<p className="title">{serviceName}</p>
					</div>
					<OnCallTerritory
						territories={territories}
						date={date}
						serviceIndex={0}
						sectionType={sectionType}
						handleDelete={props.handleDelete}
						deleteIndex={props.deleteIndex}
						constructPyloadForCheckinCheckout={constructPyloadForCheckinCheckout}
						user_id={user_id}
						getScheduleBoardData={getScheduleBoardData}
						searchText={searchText}
						searchType={searchType}
						users={users}
						userType={userType}
						showDropArea={showDropArea}
					/>
				</React.Fragment>
			) : (
				<p className="no-routine-alert">
					No On Calls for the selected region, territory & zone combination
				</p>
			)}
		</React.Fragment>
	);
});
