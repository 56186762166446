import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: {},
  loading: false,
  activeFacilityId: "",
  activeFacility: {},
  menuIsOpenValue: {
    checkIn: false,
    checkOut: false,
    stopDay: false
  },
  scrollPos: 0,
  auditKey: "",
  auditKeyDropOff: "",
  menuOpenStopDay: false,
  disableSaveButton: false
};

export const deleteStatActionApiResponse = createAsyncThunk(
  "order/deleteStatAction",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.deleteStatAction(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);
export const deleteStatActionSlice = createSlice({
  name: "deleteStatAction",
  initialState,
  reducers: {
    clearDeleteStatAction(state, action) {
      state.value = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteStatActionApiResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteStatActionApiResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(deleteStatActionApiResponse.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const deleteStatActionResponse = (state) => state.deleteStatAction.value;

export const {clearDeleteStatAction} = deleteStatActionSlice.actions;

export default deleteStatActionSlice.reducer;
