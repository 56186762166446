import * as React from "react";
import { FacilityService } from "./FacilityService";
import { HomeHealthService } from "./homeHealth/HomeHealthService";
import { OnCallService } from "./onCall/onCallService";
import { SCHEDULE_SERVICE_TYPE, TYPE_HOMEHEALTH } from "../../app/utils/Constants";

export const UserData = (props) => {
  const {
    users = [],
    setBoardData,
    date,
    updateBoardData,
    handleDeleteService,
    constructPyloadForCheckinCheckout,
    constructPayloadToClearCheckinCheckoutSpin,
    getScheduleBoardData,
    deleteServiceData,
    setDeleteServiceData,
    tabValue,
    searchText = "",
    searchType,
    tempCourierAddress,
    setTempCourierAddress,
    filterType,
    surgeOrNonServiceFacilitiesData,
    facilitiesForSurge,
    facilitiesForNonService,
    addFacilityToSurgeOrNonService,
    showDropArea,
    shiftTimingsList,
    timezone_ids,
    zonesForSurge,
    addZoneToSurge,
    viewAttachment
  } = props;

  const showByFilter = (services = [], userType) => {
    return (userType === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? filterType.nocoverage : filterType.scheduled && filterType.unscheduled ?
      true : filterType.scheduled ?
        tabValue === "onCall" ?
          services.length > 0 : tabValue === "routine" ?
            services.some(ser => ser.service_name === "Routine Draws" || ser.service_name === "Drop Off") : services.some(ser => ser.service_name === "Home Health") : filterType.unscheduled ?
          tabValue === "onCall" ? services.length === 0 : tabValue === "routine" ?
            (!services.length || !services.some(ser => ser.service_name === "Routine Draws" || ser.service_name === "Drop Off")) : (!services.length || !services.some(ser => ser.service_name === "Home Health")) : false)
  }

  const selectedDate = new Date(date);
  selectedDate.setHours(0, 0, 0, 0);

  var now = new Date();
  now.setHours(0, 0, 0, 0);

  const isPastDate = selectedDate < now;

  return (
    <React.Fragment>
      {users.map((user, userIndex) => {
        const { services = [], user_id, name = "", type_id } = user;
				let zoneCount = 0;
        if (searchType === "zone" && searchText) {
          if (tabValue === "onCall") {
						services && services.forEach(ser => {
							const { territories = {} } = ser || {};
							if (territories) {
								Object.keys(territories).forEach(terr => {
									const { events = {} } = territories[terr];
									if (events.oncall) {
										const { zones = [] } = events.oncall;
										if (zones && zones.some(z => z.zone_name.toLowerCase().search(searchText.toLowerCase()) !== -1)) {
											zoneCount++;
										}
									}
								})
							}
						})
          } else if (tabValue === TYPE_HOMEHEALTH) {
  
          } else {
						let onlyRoutineServices = services ? services.filter(ser => ser.service_name === "Routine Draws") : [];
						onlyRoutineServices && onlyRoutineServices.forEach(ser => {
							const { zones = {} } = ser || {};
							if (zones && Object.keys(zones).some(z => zones[z].zone.toLowerCase().search(searchText.toLowerCase()) !== -1)) {
								zoneCount++;
							}
						})
          }
        }

        return ((user.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? filterType.nocoverage : searchType === "phlebotomist" ? (!searchText
          || name.toLowerCase()
            .search(searchText.toLowerCase()) !== -1) : (!searchText || zoneCount > 0)) && (!isPastDate ? user.status != "INACTIVE" : true) &&
          (showByFilter(services, user.type_id)) &&
          <td key={`${userIndex}-${tabValue}`} className={user.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? "on-call-header" : ""}>
            <div className="facility-td-wrapper">
              {tabValue === "onCall" ?
                <OnCallService
                  sectionType="scheduleBoard"
                  services={services}
                  userIndex={userIndex}
                  type="board"
                  handleDelete={props.handleDelete}
                  user_id={user_id}
                  userType={type_id}
                  date={date}
                  updateBoardData={updateBoardData}
                  deleteIndex={props.deleteIndex}
                  handleDeleteService={handleDeleteService}
                  constructPyloadForCheckinCheckout={constructPyloadForCheckinCheckout}
                  getScheduleBoardData={getScheduleBoardData}
                  deleteServiceData={deleteServiceData}
                  setDeleteServiceData={setDeleteServiceData}
                  tempCourierAddress={tempCourierAddress}
                  setTempCourierAddress={setTempCourierAddress}
                  isPastDate={isPastDate}
                  users={users}
                  showDropArea={showDropArea}
                  shiftTimingsList={shiftTimingsList}
                  timezone_ids={timezone_ids}
                  zonesForSurge={zonesForSurge}
                  addZoneToSurge={addZoneToSurge}
                />
                : tabValue === TYPE_HOMEHEALTH ?
                  <HomeHealthService
                    sectionType="scheduleBoard"
                    services={services}
                    userIndex={userIndex}
                    type="board"
                    handleDelete={props.handleDelete}
                    user_id={user_id}
                    setBoardData={setBoardData}
                    date={date}
                    updateBoardData={updateBoardData}
                    deleteIndex={props.deleteIndex}
                    handleDeleteService={handleDeleteService}
                    constructPyloadForCheckinCheckout={constructPyloadForCheckinCheckout}
                    getScheduleBoardData={getScheduleBoardData}
                    deleteServiceData={deleteServiceData}
                    setDeleteServiceData={setDeleteServiceData}
                    tempCourierAddress={tempCourierAddress}
                    setTempCourierAddress={setTempCourierAddress}
                    isPastDate={isPastDate}
                    showDropArea={showDropArea}
                    timezone_ids={timezone_ids}
                  />
                  :
                  <FacilityService
                    sectionType="scheduleBoard"
                    services={services}
                    userIndex={userIndex}
                    type="board"
                    handleDelete={props.handleDelete}
                    user_id={user_id}
                    setBoardData={setBoardData}
                    date={date}
                    updateBoardData={updateBoardData}
                    deleteIndex={props.deleteIndex}
                    handleDeleteService={handleDeleteService}
                    constructPyloadForCheckinCheckout={constructPyloadForCheckinCheckout}
                    constructPayloadToClearCheckinCheckoutSpin={constructPayloadToClearCheckinCheckoutSpin}
                    getScheduleBoardData={getScheduleBoardData}
                    deleteServiceData={deleteServiceData}
                    setDeleteServiceData={setDeleteServiceData}
                    tempCourierAddress={tempCourierAddress}
                    setTempCourierAddress={setTempCourierAddress}
                    isPastDate={isPastDate}
                    surgeOrNonServiceFacilitiesData={surgeOrNonServiceFacilitiesData}
                    facilitiesForSurge={facilitiesForSurge}
                    facilitiesForNonService={facilitiesForNonService}
                    addFacilityToSurgeOrNonService={addFacilityToSurgeOrNonService}
                    showDropArea={showDropArea}
                    timezone_ids={timezone_ids}
                    viewAttachment={viewAttachment}
                  />}
            </div>
          </td>
        );
      })}
    </React.Fragment>
  );
};
