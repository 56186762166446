import * as React from "react";
import _ from "lodash";
import Avatar from "react-avatar";
import { phoneFormat } from "../../app/utils/testUtils";
import { filterList } from "../landingPage/addUser/operations/filterSlice";
import { useSelector } from "react-redux";
import { WILL_CALL_SERVICE_TYPE } from "../../app/utils/Constants";

export const LocationDetails = ({selectedLocation, closeMarker}) => {
  let {
    type,
    timezone = "",
    name,

    zip,
    phone_number = "",
    address1,
    address2,
    region,
    zone,
    emergency_contact_name,
    emergency_phone_number,
    emergency_contact_email,
    icon_initial = "",
    icon_color_code,
    email,
    territory = "",
    emergency_contact_role,
    emergency_phone_number_ext,
    account_manager_name,
    account_id = "",
    state,
    city,
    lab_type = "",
    primary_contact_cell_phone_number = "",
    service_type,
    service_days = [],
    service_stop_day = {}
  } = selectedLocation;

  const filter = useSelector(filterList);
  let days = filter && filter && filter.content && filter.content.days;

  if (icon_initial.length > 1) {
    const temp = icon_initial.split("");
    icon_initial = temp[0] + " " + temp[1];
  }
  let filterType = "";
  if (type === "hospital") {
    filterType = "hospital";
  } else if (type === "lab" || type === "facility" || type === "phleb") {
    filterType = type;
  } else {
    filterType = "default";
  }
  let serviceDays = [];
  if (days && service_days && service_days.length) {
    const tempServiceDays = typeof service_days === "string"  ? JSON.parse(service_days) : service_days;
    days.forEach(day => {
      if (tempServiceDays.some(sDay => sDay.day == day.id)) {
        serviceDays.push(day.name)
      }
    })
  }
  return (
    <div>
      <div className="mapbox-overlay">
        <div className="overlay-type">
          {filterType !== "" &&
            filterType !== "default" &&
            filterType !== "phleb" && (
              <div data-testid="service-icon-testid" className={`service-icon icon ${filterType}`}></div>
            )}
          {filterType === "phleb" && (
            <Avatar
              color={icon_color_code ? `#${icon_color_code}` : "#333745"}
              name={icon_initial}
              maxInitials={2}
              size={40}
              round="50px"
              data-testid="location-details-avatar"
            />
          )}
          <div data-testid="close-marker-id" className="close-icon" onClick={closeMarker}></div>
        </div>
        <div className="facilty-header mb-20">
          {filterType === "facility" ? (
            <h2 className="facility-name">{name}</h2>
          ) : (
            <h2 className="facility-name">{name}</h2>
          )}
          {filterType !== "" && filterType !== "default" && (
            <p
              data-testid="user-data-testid"
              className="facility-id"
              title={
                filterType === "phleb"
                  ? email || ""
                  : `${account_id
                    ? parseInt(account_id)
                      ? `#${account_id}`
                      : account_id
                    : ""
                  }`
              }
            >
              {filterType === "phleb"
                ? email || ""
                : `${account_id
                  ? parseInt(account_id)
                    ? `#${account_id}`
                    : account_id
                  : ""
                }`}
            </p>
          )}
        </div>
        {filterType !== "" && filterType !== "default" && (
          <div className="facilty-details-wrapper">
            {filterType === "facility" && region && (
              <div data-testid="facility-regionid" className="facilty-details mb-20">
                <h2 className="detail-title">Region:</h2>
                <p className="detail-subtile">{region}</p>
              </div>
            )}
            {filterType === "facility" && zone && (
              <div data-testid="facility-zoneid" className="facilty-details mb-20">
                <h2 className="detail-title">Zone:</h2>
                <p className="detail-subtile">{zone}</p>
              </div>
            )}
            {filterType === "facility" && territory && (
              <div data-testid="facility-territoryid" className="facilty-details mb-20">
                <h2 className="detail-title">Territory:</h2>
                <p className="detail-subtile">{territory}</p>
              </div>
            )}
            {filterType === "lab" && lab_type && (
              <div data-testid="lab-typeid" className="facilty-details mb-20">
                <h2 className="detail-title">Lab Type:</h2>
                <p className="detail-subtile">{lab_type}</p>
              </div>
            )}
            {filterType === "hospital" && parseInt(account_id) > 0 && (
              <div data-testid="hospital-accid" className="facilty-details mb-20">
                <h2 className="detail-title">Account Number:</h2>
                <p className="detail-subtile">{account_id}</p>
              </div>
            )}
            {phone_number && filterType !== "lab" && (
              <div data-testid="phone-numberid" className="facilty-details mb-20">
                <h2 className="detail-title">Phone:</h2>
                <p className="detail-subtile">
                  {phoneFormat(phone_number.toString())}
                </p>
              </div>
            )}
            {primary_contact_cell_phone_number && filterType === "lab" && (
              <div data-testid="primary-contact-cell-phone-number-id" className="facilty-details mb-20">
                <h2 className="detail-title">Primary Contact Cell Phone Number:</h2>
                <p className="detail-subtile">
                  {phoneFormat(primary_contact_cell_phone_number.toString())}
                </p>
              </div>
            )}
            {filterType === "phleb" && account_manager_name && (
              <div data-testid="phleb-account-managerid" className="facilty-details mb-20">
                <h2 className="detail-title">Reports to:</h2>
                <p className="detail-subtile">{account_manager_name}</p>
              </div>
            )}
            {filterType !== "phleb" && (
              <>
                <div data-testid="address-id" className="facilty-details mb-20">
                  <h2 className="detail-title">Address:</h2>
                  <p className="detail-subtile">
                    {address1 || ""}
                    {address2 && <br />}
                    {address2 || ""}
                    <br />
                    {city ? `${city}` : ""}, {state || ""} {zip || ""}
                  </p>
                </div>
                {timezone && filterType !== "hospital" && filterType !== "lab" && <div className="facilty-details mb-20">
                  <h2 className="detail-title">Timezone:</h2>
                  <p className="detail-subtile" data-testid="inner-timezoneid">{timezone}</p>
                </div>}
              </>
            )}
            {filterType === "facility" && <>
            <div className="facilty-details mb-20">
                  <h2 className="detail-title">Routine Service Type:</h2>
                  <p className="detail-subtile" data-testid="inner-timezoneid">{service_type}</p>
                </div>
                {serviceDays && serviceDays.length > 0 && <div className="facilty-details mb-20">
                  <h2 className="detail-title">{service_type === WILL_CALL_SERVICE_TYPE ? "Will Call Days" : "Routine Service Days"}:</h2>
                  <p className="detail-subtile" data-testid="inner-timezoneid">{serviceDays.join(", ")}</p>
                </div>}
                {service_type !== WILL_CALL_SERVICE_TYPE && service_stop_day && <div className="facilty-details mb-20">
                  <h2 className="detail-title">Number of service days:</h2>
                  <p className="detail-subtile" data-testid="inner-timezoneid"> {typeof service_stop_day === "string" ?  JSON.parse(service_stop_day).stop_day_name : service_stop_day.stop_day_name} Day</p>
                </div>}
            </>}
          </div>
        )}
        {type === "facility" && (
          <div className="emergency-contact" data-testid="facility-emergencyid">
            <h2 className="mb-20">Emergency Contact</h2>
            <div className="emergency-contact-box">
              <div className="facilty-details mb-15">
                <h2 className="detail-title">Name:</h2>
                <p className="detail-subtile">{emergency_contact_name}</p>
              </div>
              <div className="facilty-details mb-15">
                <h2 className="detail-title">Role:</h2>
                <p className="detail-subtile">{emergency_contact_role}</p>
              </div>
              {emergency_phone_number ? (
                <div className="facilty-details mb-15">
                  <h2 className="detail-title">Phone:</h2>
                  <p className="detail-subtile">
                    {phoneFormat(emergency_phone_number.toString())}
                  </p>
                </div>
              ) : (
                ""
              )}
              {emergency_phone_number_ext ? (
                <div className="facilty-details mb-15">
                  <h2 className="detail-title">Extension:</h2>
                  <p className="detail-subtile">
                    +{emergency_phone_number_ext}
                  </p>
                </div>
              ) : (
                ""
              )}
              {emergency_contact_email && (
                <div className="facilty-details mb-15">
                  <h2 className="detail-title">Email:</h2>
                  <p
                    className="detail-subtile email"
                    title={emergency_contact_email}
                  >
                    {emergency_contact_email}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
