import React from "react";
import Avatar from "react-avatar";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../app/utils/Constants";
import { getProfile, profileList } from "../landingPage/profile/operations/profileSlice";

export const MobileView = () => {
	const [name, setName] = React.useState("");
	const [color, setColor] = React.useState("");
	const profile = useSelector(profileList);

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(getProfile());
	}, [])

	React.useEffect(() => {
		if (profile && profile.status === "SUCCESS") {
		  if (profile && profile.content) {
			localStorage.setItem(
			  "userDetail",
			  JSON.stringify(profile && profile.content)
			);
			setName(profile && profile.content && profile.content.name);
			setColor(profile && profile.content && profile.content.icon_color_code);
		  }
		}
	  }, [profile]);

	return (
		<div>
      <div className="ad-headertop-wrapper scheduler-header">
        <div className="header-left">
          {/* <h1 className="headline4 pab-5">Schedule</h1> */}
        </div>
        <Dropdown className="aha-user-dropdown">
          <Dropdown.Toggle id="dropdown-basic" className="profile-dropdown">
            <span className="visually-hidden">profile dropdown</span>
            <Avatar
              color={color ? `#${color}` : "#333745"}
              name={name}
              maxInitials={2}
              size={40}
              round="50px"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="aha-logout-dropmenu">
            <Dropdown.Item onClick={() => logout()}>Log Out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
			<div>Thank you. 
			<div>Please use the mobile app to review your schedules. For any further assistance, contact your administrator</div>
			</div>
		</div>
	)
}