// import { useDispatch, useSelector } from "react-redux";
import {
  ChipDirective,
  ChipListComponent,
  ChipsDirective,
} from "@syncfusion/ej2-react-buttons";
import {
  DropDownListComponent,
  DropDownTreeComponent,
} from "@syncfusion/ej2-react-dropdowns";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, OverlayTrigger, Tab, Table, Tabs, Tooltip } from "react-bootstrap";
import { updateLoader, updateUserList } from "./updateUser/updateUserSlice";
import { useSelector } from "react-redux";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { phoneFormat } from "../../app/utils/testUtils";
import StatusModal from "../../components/commons/StatusModal";
import toast from "react-hot-toast";
import _ from 'lodash';
import UpdateReporteesWarningModal from "./UpdateReporteesWarningModal";
import { updateReportingManagerLoading } from "./operations/assignReportingManagerSlice";
import ToastContent from "../../components/layouts/ToastContent";
import { ROLE_COURIER, USERLIST, escapeRegExp, hasCompanyVehicleOptions } from "../../app/utils/Constants";
import moment from "moment";
import * as XLSX from 'xlsx';

var dropDownTree = {};

export default function Profile(props) {
  // const dispatch = useDispatch();
  const { setPage, page, editData = {}, bc = [], statusModalType, setStatusModalType, handleAssignReportingManager, userReporteesState, setUserReporteesState, userObjLoading,
    paramId, navigate } = props;
  let { territory = [], status: propsStatus, audit_histories = [] } = editData;

  const [executive, setExecutive] = React.useState(false);
  const updateLoading = useSelector(updateLoader);
  const [statusData, setStatus] = React.useState([]);
  const [hasCompanyVehicle, setHasCompanyVehicle] = React.useState(0);
  const [initialHasCompanyVehicle, setInitialHasCompanyVehicle] = React.useState(0);
  let [data, setData] = React.useState({});
  let [initialData, setInitialData] = React.useState({});
  const [chipData, setChip] = React.useState([]);
  const [initialChipData, setInitialChipData] = React.useState([]);
  const [toggleStateForChip, setToggleStateForChip] = React.useState(false);
  const [initialToggleStateForChip, setInitialToggleStateForChip] = React.useState(false);
  const [err_first, setErrFirst] = React.useState(false);
  const [err_last, setErrLast] = React.useState(false);
  const [err_emp, setErrEmp] = React.useState(false);
  const [err_username, setErrUsername] = React.useState(false);
  const [err_job, setErrJob] = React.useState(false);
  const [err_manager, setErrManager] = React.useState(false);
  const [err_regionOrTerri, setErrRegionOrTerri] = React.useState(false);
  const [err_homeRegion, setErr_homeRegion] = React.useState(false);
  const [err_email, setErrEmail] = React.useState(false);
  const [err_phone, setErrPhone] = React.useState(false);
  const [accessGroupError, setAccessGroupError] = React.useState(false);
  const [type, setType] = React.useState("");
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [treeData, setTreeData] = React.useState(false);
  const updateList = useSelector(updateUserList);
  const updateReportingManagerLoader = useSelector(updateReportingManagerLoading);
  const [activeTab, setActiveTab] = useState('info');
  const [searchText, setSearchText] = useState('');
  const searchRef = useRef('');

  let status = [];
  if (props.editData && props.editData.status === "Invited") {
    status = [
      {
        id: 1,
        text: "Deactivate",
        status: "DEACTIVATE",
      },
      {
        id: 2,
        text: "Resend Invite",
        status: "RESEND_INVITE",
      },
    ];
  } else if (props.editData && props.editData.status === "Active") {
    status = [
      {
        id: 1,
        text: "Deactivate",
        status: "DEACTIVATE",
      },
      {
        id: 2,
        text: "Lock",
        status: "LOCK",
      },
    ];
  } else if (props.editData && props.editData.status === "Inactive") {
    status = [
      {
        id: 1,
        text: "Activate",
        status: "ACTIVATE",
      },
    ];
  } else if (props.editData && props.editData.status === "Locked") {
    status = [
      {
        id: 1,
        text: "Unlock",
        status: "UNLOCK",
      },
      {
        id: 2,
        text: "Deactivate",
        status: "DEACTIVATE",
      },
    ];
  }
  let permission =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
    [];
  permission = permission.filter((perm) => perm.page === "users");
  const access = permission.length === 1 ? permission[0].access : [];
  const update =
    access && access.find((item) => item === "update") === "update"
      ? true
      : false;

  let { selectedRegionTerritory = [], first_name, last_name, middle_initial, employee_id, user_name,
    email_id, personal_phone_number, phone_number, job = {}, reporting_manager, home_region = {} } = data;

  let resTe =
    props.editData &&
    props.editData.territory &&
    props.editData.territory.map((val) => val.id);
  let tempReT = resTe;

  let uniqueArray =
    (tempReT &&
      tempReT.filter(function (item, pos) {
        return tempReT.indexOf(item) == pos;
      })) || [];

  React.useEffect(() => {
    const { editData = {} } = props || {};
    const {
      first_name,
      middle_initial = "",
      last_name,
      employee_id = "",
      cognito_username,
      email,
      personal_phone_number = "",
      phone_number = "",
      reporting_manager_id,
      reporting_manager_name,
      job_title = {},
      territory = [],
      roles = [],
      home_region = {},
      has_company_vehicle = false
    } = editData;

    let selectedRegionTerritory = [];
    if (territory) {
      const territorries = territory.map((terr) => {
        return terr.id;
      });

      selectedRegionTerritory = territorries;
    }
    const tempType = roles.find((x) => x.type === "both") !== undefined
      ? "both"
      : roles.find((x) => x.type === "mobile") !== undefined && roles.find((x) => x.type === "desktop") !== undefined
        ? "both"
        : roles.find((x) => x.type === "mobile") !== undefined
          ? "mobile"
          : roles.find((x) => x.type === "desktop") !== undefined
            ? "desktop"
            : "";

    const finalObj = {
      first_name,
      middle_initial: middle_initial ? middle_initial : "",
      last_name,
      employee_id: typeof employee_id === "number" ? employee_id.toString() : employee_id,
      user_name: cognito_username,
      email_id: email,
      personal_phone_number: personal_phone_number
        ? phoneFormat(personal_phone_number.toString())
        : "",
      phone_number: phone_number ? phoneFormat(phone_number.toString()) : "",
      reporting_manager: {
        id: reporting_manager_id,
        value: reporting_manager_name,
      },
      job: {
        id: job_title.id,
        value: job_title.job_title_name,
      },
      selectedRegionTerritory,
      home_region: home_region ? home_region.id ? {
        id: home_region.id,
        name: home_region.region_name
      } : {} : {}
    }
    const flag = !toggleStateForChip;
    const flag2 = !treeData;
    setData(finalObj);
    setInitialData(finalObj);
    setTreeData(flag2);
    setChip(roles.map((val) => { return { text: val.role_name, value: val.id, type: val.type }; }));
    setInitialChipData(roles.map((val) => { return { text: val.role_name, value: val.id, type: val.type }; }));
    setToggleStateForChip(flag);
    setInitialToggleStateForChip(flag)
    setType(tempType);
    setExecutive(job_title.id === 1 ? true : false);
    setHasCompanyVehicle(has_company_vehicle ? 1 : 0);
    setInitialHasCompanyVehicle(has_company_vehicle ? 1 : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editData, page]);

  useEffect(() => {
    setIsFormChanged(validateEditFields());

  }, [first_name, last_name, middle_initial, employee_id, user_name,
    email_id, personal_phone_number, phone_number, job, reporting_manager,
    treeData, toggleStateForChip, statusData, home_region, hasCompanyVehicle
  ])

  useEffect(() => {
    if (updateList && updateList.status === "SUCCESS") {
      setStatus({});
    }
  }, [updateList])

  const validateEditFields = () => {
    let result = false;
    let allFieldFlagValue = !_.isEqual(initialData.first_name, first_name) || !_.isEqual(initialData.last_name, last_name) || !_.isEqual(initialData.middle_initial, middle_initial)
      || !_.isEqual(initialData.employee_id, employee_id) || !_.isEqual(initialData.user_name, user_name) || !_.isEqual(initialData.email_id, email_id)
      || !_.isEqual(initialData.personal_phone_number, personal_phone_number) || !_.isEqual(initialData.phone_number, phone_number)
      || !_.isEqual(initialData.job, job) || !_.isEqual(initialData.home_region, home_region) || !_.isEqual(initialData.reporting_manager, reporting_manager) || !_.isEqual(initialHasCompanyVehicle, hasCompanyVehicle);
    if (allFieldFlagValue) {
      result = true;
    } else if (!_.isEmpty(statusData)) {
      result = true;
    } else {
      if (chipData.length === initialChipData.length) {
        let counter = 0;
        initialChipData.forEach(dd => {
          if (chipData.some(dd2 => dd2.value == dd.value)) {
            counter++;
          }
        });
        if (counter === initialChipData.length) {
          result = allFieldFlagValue || validateRegionTerritories(allFieldFlagValue, result);
        } else {
          result = true;
        }
      } else {
        result = true;
      }
    }

    return result;
  }

  const validateRegionTerritories = (allFieldFlagValue, val) => {
    let result = val;
    uniqueArray = uniqueArray.filter(d => d.toString().includes(":"));
    selectedRegionTerritory = selectedRegionTerritory.filter(d => d.toString().includes(":"));
    if (uniqueArray.length === selectedRegionTerritory.length) {
      let counter = 0;

      uniqueArray.forEach(data1 => {
        if (selectedRegionTerritory.includes(data1)) {
          counter++;
        }
      });
      if (counter === selectedRegionTerritory.length) {
        result = allFieldFlagValue
      } else {
        result = true;
      }
    } else {
      result = true;
    }
    return result;
  }

  const handleClose = () => {
    if (activeTab === 'audit') {
      setErrFirst(false);
      setErrLast(false);
      setErrEmp(false);
      setErrUsername(false);
      setErrJob(false);
      setErrManager(false);
      setErrRegionOrTerri(false);
      setErr_homeRegion(false);
      setPage("profile");
      setErrEmail(false);
      setErrPhone(false);
      setStatus({});
      setHasCompanyVehicle(0);
      setActiveTab('info');
      setSearchText('');
      props.handleError();
      props.handleClose();
    } else {
      if (page === "edit") {
        setErrFirst(false);
        setErrLast(false);
        setErrEmp(false);
        setErrUsername(false);
        setErrJob(false);
        setErrManager(false);
        setErrRegionOrTerri(false);
        setErr_homeRegion(false);
        setPage("profile");
        setErrEmail(false);
        setErrPhone(false);
        setStatus({});
        setHasCompanyVehicle(0);
        props.handleError();
      } else {
        setActiveTab('info');
        setSearchText('');
        props.handleClose();
      }
    }
    if (paramId) {
      navigate(USERLIST, { replace: true });
    }
  };
  const handleShow = () => setPage("edit");

  const handleList = (args) => {
    let dataArray = chipData;
    const { selected, data } = args;
    if (selected) {
      if ((data.text === ROLE_COURIER && dataArray.some(item => item.name !== ROLE_COURIER) || data.text !== ROLE_COURIER && dataArray.some(item => item.name === ROLE_COURIER || item.text === ROLE_COURIER))) {
        var instance = document.getElementById('chip-default-edituser').ej2_instances[0];
        instance.select(args.index);
        setTimeout(() => {
          toast(
            (t) => (
              <ToastContent type="error" message="The Courier role cannot be assigned in combination with any other role." close={() => toast.remove()} />
            ),
            {
              duration: 4000,
            }
          );
          if (document.querySelector('.chipsetcomponent-wrapper')) {
            document.querySelector('.chipsetcomponent-wrapper').scrollIntoView({
              behavior: 'smooth'
            });
          }
        }, 1)
        return;
      }
      dataArray.push({
        value: data.value.value,
        name: data.text,
        index: args.index,
        type: data.value.type,
      });
      setToggleStateForChip(!toggleStateForChip)
      if (dataArray.find((x) => x.type === "both") !== undefined) {
        setType("both");
      } else if (
        dataArray.find((x) => x.type === "mobile") !== undefined &&
        dataArray.find((x) => x.type === "desktop") !== undefined
      ) {
        setType("both");
      } else if (dataArray.find((x) => x.type === "mobile") !== undefined) {
        setType("mobile");
      } else if (dataArray.find((x) => x.type === "desktop") !== undefined) {
        setType("desktop");
      }
    } else {
      const index = dataArray.findIndex(
        (item) => item.value === data.value.value
      );
      if (index !== -1) {
        dataArray.splice(index, 1);
        setToggleStateForChip(!toggleStateForChip);
        if (dataArray.find((x) => x.type === "both") !== undefined) {
          setType("both");
        } else if (
          dataArray.find((x) => x.type === "mobile") !== undefined &&
          dataArray.find((x) => x.type === "desktop") !== undefined
        ) {
          setType("both");
        } else if (dataArray.find((x) => x.type === "desktop") !== undefined) {
          setType("desktop");
        } else if (dataArray.find((x) => x.type === "mobile") !== undefined) {
          setType("mobile");
        } else {
          setType("");
        }
      }
    }
    setChip(dataArray);
    setAccessGroupError(false);
  };
  const handleSave = (isConfirmDeleteSchedule = false) => {
    let {
      first_name,
      last_name,
      employee_id,
      job,
      reporting_manager,
      selectedRegionTerritory = [],
      phone_number,
      email_id,
      middle_initial,
      user_name,
      personal_phone_number,
      home_region = {}
    } = data;
    if (
      type !== "" &&
      type !== null &&
      first_name &&
      last_name &&
      employee_id &&
      user_name &&
      job &&
      home_region &&
      home_region.id &&
      reporting_manager &&
      selectedRegionTerritory.length !== 0
    ) {
      let regionsWithTerritories = {};
      const rtOptions = props.bc || [];
      rtOptions.forEach((option) => {
        selectedRegionTerritory.forEach((rt) => {
          if (option.id === rt) {
            if (option.region_id) {
              if (!regionsWithTerritories[option.region_id]) {
                regionsWithTerritories[option.region_id] = [option.id];
              } else {
                regionsWithTerritories[option.region_id].push(option.id);
              }
            }
          }
        });
      });

      let regTer = Object.keys(regionsWithTerritories).map((region) => {
        return {
          region_id: parseInt(region),
          territory_id: regionsWithTerritories[region],
        };
      });
      const statusPayload = statusData.status;
      const roles = chipData.map((val) => val.value);

      let phonenumber;
      if (typeof phone_number === "string") {
        phonenumber = phone_number.match(/\d/g);
        phonenumber = phonenumber ? phonenumber.join("") : null;
        // phonenumber = phonenumber.join("");
      } else {
        phonenumber = phone_number;
      }

      let phone;
      if (typeof personal_phone_number === "string") {
        phone = personal_phone_number.match(/\d/g);
        phone = phone ? phone.join("") : null;
        // phone = phone.join("");
      } else {
        phone = personal_phone_number;
      }

      if (data) {
        let errors = {};
        const payload = {
          id: props && props.editData && props.editData.id,
          first_name,
          middle_initial,
          last_name,
          user_name,
          employee_id,
          reporting_manager_id: executive ? null : reporting_manager.id,
          job_title_id: job.id,
          home_region_id: home_region.id,
          role_id: roles,
          region_territory: regTer,
          phone_number: phonenumber,
          personal_phone_number: phone,
          email_id,
          status: statusPayload,
          has_company_vehicle: hasCompanyVehicle ? true : false
        };

        if (type === "both" && data.email_id && data.phone_number) {
          payload.delete_schedule = isConfirmDeleteSchedule;
          props.handleEdit(payload);
        } else if (type === "desktop" && data.email_id) {
          payload.delete_schedule = isConfirmDeleteSchedule;
          props.handleEdit(payload);
        } else if (type === "mobile" && data.phone_number) {
          payload.delete_schedule = isConfirmDeleteSchedule;
          props.handleEdit(payload);
        }
        if (type === "both" && !data.email_id && !data.phone_number) {
          errors["phone"] = true;
          errors["email"] = true;
          setErrEmail(true);
          setErrPhone(true);
        }
        if (type === "both" && data.email_id && !data.phone_number) {
          errors["phone"] = true;
          setErrEmail(false);
          setErrPhone(true);
        }
        if (type === "both" && !data.email_id && data.phone_number) {
          errors["email"] = true;
          setErrEmail(true);
          setErrPhone(false);
        }
        if (type === "desktop" && !data.email_id) {
          errors["email"] = true;
          setErrEmail(true);
          setErrPhone(false);
        }
        if (type === "mobile" && !data.phone_number) {
          errors["phone"] = true;
          setErrEmail(false);
          setErrPhone(true);
        }

        if (errors && Object.keys(errors).length) {
          setTimeout(() => {
            toast(
              (t) => (
                <ToastContent type="error" message="Check if all required fields have been filled in before you update an user" close={() => toast.remove()} />
              ),
              {
                duration: 4000,
              }
            );
            if (document.querySelector('.error-message')) {
              document.querySelector('.error-message').scrollIntoView({
                behavior: 'smooth'
              });
            } else if (document.querySelector('.error-message-text')) {
              document.querySelector('.error-message-text').scrollIntoView({
                behavior: 'smooth'
              });
            }
          }, 1)
        }
        // setTe([]);
      }
    } else {
      let errors = {};
      if (!data) {
        setErrFirst(true);
        setErrLast(true);
        setErrEmp(true);
        setErrUsername(true);
        setErrJob(true);
        setErrManager(true);
        errors = {
          firstName: true,
          lastName: true,
          emp: true,
          username: true.valueOf,
          job: true,
          manager: true,
          err_homeRegion: true
        }
        // setErrRegionOrTerri(true);
      }
      if (!first_name) {
        errors["firstName"] = true;
        setErrFirst(true);
      }
      if (!last_name) {
        errors["lastName"] = true;
        setErrLast(true);
      }
      if (!employee_id) {
        errors["emp"] = true;
        setErrEmp(true);
      }
      if (!user_name) {
        errors["username"] = true;
        setErrUsername(true);
      }
      if (!job) {
        errors["job"] = true;
        setErrJob(true);
      }
      if (!home_region || !home_region.id) {
        errors["err_homeRegion"] = true;
        setErr_homeRegion(true);
      }
      if (!reporting_manager) {
        errors["manager"] = true;
        setErrManager(true);
      }
      if (selectedRegionTerritory.length === 0) {
        errors["regionTerr"] = true;
        setErrRegionOrTerri(true);
      }
      if (type === "both" && !email_id && !phone_number) {
        errors["email"] = true;
        errors["phone"] = true;
        setErrEmail(true);
        setErrPhone(true);
      }
      if (type === "desktop" && !email_id) {
        errors["email"] = true;
        setErrEmail(true);
        setErrPhone(false);
      }
      if (type === "mobile" && !phone_number) {
        errors["phone"] = true;
        setErrEmail(false);
        setErrPhone(true);
      }
      if (type === "" || type === null) {
        errors["accessGroup"] = true;
        setAccessGroupError(true);
      }
      if (errors && Object.keys(errors).length) {
        setTimeout(() => {
          toast(
            (t) => (
              <ToastContent type="error" message="Check if all required fields have been filled in before you update an user" close={() => toast.remove()} />
            ),
            {
              duration: 4000,
            }
          );
          if (document.querySelector('.error-message')) {
            document.querySelector('.error-message').scrollIntoView({
              behavior: 'smooth'
            });
          } else if (document.querySelector('.error-message-text')) {
            document.querySelector('.error-message-text').scrollIntoView({
              behavior: 'smooth'
            });
          }
        }, 1)
      }
    }
    if (paramId) {
      navigate(USERLIST, { replace: true });
    }
  };

  const handleRT = (args, options) => {
    let temp = [...selectedRegionTerritory];
    const { itemData = {} } = args;
    if (args.isInteracted) {
      const dropDownTreeData = options || [];
      if (itemData.checked) {
        if (itemData.checked === "true") {
          temp = [];

          let regionTerritoryObject = {};

          dropDownTreeData.forEach((data) => {
            if (data.region_id) {
              if (!regionTerritoryObject[data.region_id]) {
                regionTerritoryObject[data.region_id] = [data.id];
              } else {
                regionTerritoryObject[data.region_id].push(data.id);
              }
            }
          });

          Object.keys(regionTerritoryObject).forEach((region) => {
            temp.push(parseInt(region));
            regionTerritoryObject[region].forEach((terr) => {
              temp.push(terr);
            });
          });
        } else {
          // temp = [];
          if (itemData.hasChildren) {
            //if item is a parent
            const children = dropDownTreeData.filter(
              (data) => data.region_id == itemData.id
            );

            temp = temp.filter(
              (rt) => rt != itemData.id
            );
            //removing all its children
            children.forEach((child) => {
              temp = temp.filter(
                (rt) => rt != child.id
              );
            });
          } else {
            // if item is a child
            //check if this child's parent is already selected or not
            dropDownTreeData.forEach((data) => {
              if (
                data.id == itemData.id && data.region_id &&
                temp.includes(data.region_id.toString())
              ) {
                temp = temp.filter(
                  (rt) => rt !== data.region_id.toString()
                );
              }
            });
            temp = temp.filter(
              (rt) => rt !== itemData.id
            );
          }
        }
      } else {
        if (itemData.hasChildren) {
          //if item is a parent
          const children = dropDownTreeData.filter(
            (data) => data.region_id == itemData.id
          );

          if (itemData.isChecked === "true") {
            !temp.includes(itemData.id) &&
              temp.push(itemData.id);
            //pushing all its children
            children.forEach((child) => {
              if (!temp.includes(child.id)) {
                temp.push(child.id);
              }
            });
          } else {
            temp = temp.filter(
              (rt) => rt != itemData.id
            );
            //removing all its children
            children.forEach((child) => {
              temp = temp.filter(
                (rt) => rt != child.id
              );
            });
          }
        } else {
          // if item is a child
          if (itemData.isChecked === "true") {
            !temp.includes(itemData.id) &&
              temp.push(itemData.id);
          } else {
            //check if this child's parent is already selected or not
            dropDownTreeData.forEach((data) => {
              if (
                data.id == itemData.id && data.region_id &&
                temp.includes(data.region_id.toString())
              ) {
                temp = temp.filter(
                  (rt) => rt !== data.region_id.toString()
                );
              }
            });
            temp = temp.filter(
              (rt) => rt !== itemData.id
            );
          }
        }
      }
      setData({ ...data, selectedRegionTerritory: temp });
      setTreeData(!treeData);
      setErrRegionOrTerri(false);
      setErr_homeRegion(false);
    }
  };
  const handleJob = (args) => {
    const { itemData } = args;
    setData({ ...data, job: { id: itemData.id, value: itemData.name } });
    if (itemData.id === 1) {
      setExecutive(true);
    } else {
      setExecutive(false);
    }
    setErrJob(false);
  };
  const handleHomeRegion = (args) => {
    const { itemData } = args;
    setData({ ...data, home_region: itemData ? { id: itemData.id, name: itemData.name } : {} });
    setErr_homeRegion(false);
  };
  const handleManager = (args) => {
    const { itemData } = args;
    setData({
      ...data,
      reporting_manager: {
        id: itemData && itemData.id,
        value: itemData && itemData.full_name,
      },
    });
    setErrManager(false);
  };
  const findPositions = (first, second) => {
    const indicies = [];
    first &&
      first.forEach((element, index) => {
        if (second && second.includes(element)) {
          indicies.push(index);
        }
      });
    return indicies;
  };
  let selected = findPositions(
    props.roles && props.roles.map((val) => val.id),
    chipData && chipData.map((val) => val.value)
  );
  const handleStatus = (args) => {
    const { itemData } = args;
    setStatus({
      id: itemData.id,
      value: itemData.text,
      status: itemData.status,
    });
  };

  const handleCompanyVehicle = (args) => {
    const { itemData } = args;
    setHasCompanyVehicle(itemData.id);
  };

  const getRegionNameById = (data, id) => {
    let regionName = "";
    data && data.forEach((d) => {
      if (d.id == id) {
        regionName = d.name;
      }
    });
    return regionName;
  };

  const created = (args) => {
    const { editData = {}, bc = [] } = props;
    const { territory = [] } = editData;
    const allTerritories = bc.filter((b) => b.region_id);
    let count = 0;
    allTerritories.forEach((terr1) => {
      territory.forEach((terr2) => {
        if (terr1.id == terr2.id) {
          count++;
        }
      });
    });
    if (count === allTerritories.length) {
      dropDownTree.selectAll(true);
    }
  };

  const handlePersonalPhone = (value) => {
    setData({
      ...data,
      personal_phone_number: phoneFormat(value),
    });
  };

  const handleCPhone = (value) => {
    setErrPhone(false);
    setData({
      ...data,
      phone_number: phoneFormat(value),
    });
  };

  function open(options = []) {
    //Bind nodeChecking event of the TreeView instance.
    dropDownTree.treeObj.nodeChecking = (args) => {
      console.log(args.data[0], options, dropDownTree);
      //Check whether the node is un-selected.
      if (args.action == 'uncheck' && args.data[0] && home_region && Object.keys(home_region).length) {
        let cancelEvent = false;
        if (args.isInteracted) {
          if (args.data[0].parentID && args.data[0].parentID == home_region.id) {
            const selected = dropDownTree.currentValue;
            const selectedChildren = selected.filter(sel => sel.includes(":") && sel.split(":")[1] == args.data[0].parentID);
            // const allChildrenByParentID = options.filter(opt => opt.region_id == args.data[0].parentID);

            if (selectedChildren.length <= 1) {
              cancelEvent = true;
            }
          } else {
            cancelEvent = args.data[0].id == home_region.id;
          }
        } else {
          if (args.data[0].id == home_region.id || args.data[0].parentID == home_region.id) {
            args.cancel = true;
            toast(
              (t) => (
                <ToastContent type="error" message="The region you’re retracting is the home region for the phleb. Please change the Home Region before you retract the region" close={() => toast.remove()} />
              ),
              {
                duration: 4000,
              }
            );
          }
        }

        if (cancelEvent) {
          //Cancel the selection.
          args.cancel = true;
          toast(
            (t) => (
              <ToastContent type="error" message="The region you’re retracting is the home region for the phleb. Please change the Home Region before you retract the region" close={() => toast.remove()} />
            ),
            {
              duration: 4000,
            }
          );
        }
      }
    };
  }

  const handleExcelExport = (e, data) => {
    e.preventDefault();
    const modifiedData = [...data];
    const modifiedHeaders = renameHeaders(modifiedData);
    const ws = XLSX.utils.json_to_sheet(modifiedHeaders);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${'User-AuditLogs'}.xlsx`);
  }

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    const escapedInput = escapeRegExp(userInput);
    const regex = new RegExp(`(${escapedInput})`);
    setSearchText(escapedInput);
  };

  const handleChangeTab = (val) => {
    setActiveTab(val);
    setSearchText('');
  }

  const onFocus = (event) => {
    event.target.setAttribute('autocomplete', 'none');
  }

  const renameHeaders = (originalData) => {
    return originalData.map((item) => ({
      'Date/Time': item.datetimeString,
      'Changed By': item.changedByString,
      'Change': item.activity,
    }));
  };

  const clearSearchInput = () => {
    setSearchText('')
    if (searchRef && searchRef.current) {
      searchRef.current.value = "";
    }
  }

  const headers = [
    { label: "Date/ Time", key: "datetimeString" },
    { label: "Changed By", key: "changedByString" },
    { label: "Change", key: "activity" }
  ];

  // grouping territories by region
  const allRegionsTerritories = {};
  const regionTerritoryObject = {};

  bc.forEach((data) => {
    if (data.region_id) {
      if (!allRegionsTerritories[data.region_id]) {
        allRegionsTerritories[data.region_id] = [data.id];
      } else {
        allRegionsTerritories[data.region_id].push(data.id);
      }
    }
  });

  territory.forEach((data) => {
    if (data.regionId) {
      if (!regionTerritoryObject[data.regionId]) {
        regionTerritoryObject[data.regionId] = [data];
      } else {
        regionTerritoryObject[data.regionId].push(data);
      }
    }
  });

  let finalRegTerr = { ...regionTerritoryObject };

  Object.keys(regionTerritoryObject).forEach((reg) => {
    const allTerritories = allRegionsTerritories[reg] || [];
    const selectedTerritories = regionTerritoryObject[reg] || [];
    let terrCount = 0;
    selectedTerritories.forEach((terr) => {
      if (allTerritories.includes(terr.id)) {
        terrCount++;
      }
    });
    if (terrCount === allTerritories.length) {
      finalRegTerr[reg] = [
        {
          territory_name: "All",
          region_name: getRegionNameById(bc, reg) || "",
          region_id: reg,
        },
      ];
    }
  });

  const options = [];
  if (bc) {
    bc.forEach(d => {
      if (d.hasTrue) {
        const hasChildren = bc.some(d2 => d2.region_id == d.id);
        if (hasChildren) {
          options.push(d)
        }
      } else {
        options.push(d);
      }
    })
  }
  let regionOptions = options ? options.filter(opt => opt.hasTrue) : [];
  // if (selectedRegionTerritory && selectedRegionTerritory.length) {
  //   let selectedRegionsOutOfTerritories = [];
  //   selectedRegionTerritory.forEach(rTerr => {
  //     if (rTerr.toString().includes(":")) {
  //       const tempReg = rTerr.split(":")[1];
  //       !selectedRegionsOutOfTerritories.some(val => val == tempReg) && selectedRegionsOutOfTerritories.push(tempReg);
  //     } else {
  //       !selectedRegionsOutOfTerritories.some(val => val == rTerr) && selectedRegionsOutOfTerritories.push(rTerr);
  //     }
  //   })
  //   regionOptions.forEach(opt => {
  //     if (!selectedRegionsOutOfTerritories.some(val => val == opt.id)) {
  //       regionOptions = regionOptions.filter(opt2 => opt2.id != opt.id)
  //     }
  //   })
  // } else {
  //   regionOptions = [];
  // }
  let auditLogs = [];
  audit_histories && audit_histories.length > 0 && audit_histories.forEach((history) => {
    const {
      user = {},
      activity,
      datetime = "",
      type,
    } = history;
    const datetimeString = moment(datetime).format("MM/DD/YYYY HH:mm:ss");
    const changedByString = `${user
      ? `${user.name}${user.username ? ` (${user.username})` : ""
      }` || ""
      : ""}
    ${type
        ? ` (${type})`
        : activity === "Order Updated"
          ? ` (DESKTOP)`
          : ""}`
    if ((!searchText || activity.toLowerCase().search(searchText.toLowerCase()) !== -1 ||
      datetimeString.toLowerCase().search(searchText.toLowerCase()) !== -1 || changedByString.toLowerCase().search(searchText.toLowerCase()) !== -1)) {
      auditLogs.push({
        ...history,
        activity, datetimeString, changedByString
      })
    }
  });

  return (
    <div>
      <div className={"ad-loading-wrapper " + (updateReportingManagerLoader ? "d-block" : "d-none")}>
        <span className={"loader ad-loader " + (updateReportingManagerLoader ? "d-block" : "d-none")}>
          <span className="visually-hidden">loader icon</span>
        </span>
      </div>
      <Modal
        show={props.show}
        onHide={() => handleClose()}
        className="ad-adduser-modal"
        backdrop="static"
        centered
        enforceFocus={false}
        data-testid="modal-profile"
      >
        <div
          className={"ad-loading-wrapper " + (userObjLoading ? "d-block" : "d-none")}
        >
          <span
            className={"loader ad-loader " + (userObjLoading ? "d-block" : "d-none")}
          >
            <span className="visually-hidden">loader icon</span>
          </span>
        </div>
        <Modal.Header closeButton>
          {page === "profile" ? (
            <Modal.Title className="headline4">Profile</Modal.Title>
          ) : (
            <React.Fragment>
              <Modal.Title className="headline4"> Edit User</Modal.Title>
              {props.error && (
                <p className="error-message-alert">{props.error}</p>
              )}
            </React.Fragment>
          )}
        </Modal.Header>
        <Modal.Body className={activeTab === 'audit' ? 'audit-modal' : ''}>
          <div className="ad-headerbottom-wrapper">
            <Tabs className="ad-navtab help-nav-tab" activeKey={activeTab} onSelect={(k) => handleChangeTab(k)} >
              <Tab
                eventKey={'info'}
                title="User Information"
                className="stat-order-wrapper"
              ></Tab>
              <Tab
                eventKey={'audit'}
                title="Audit Logs"
                className="stat-order-wrapper"
              ></Tab>
            </Tabs>
          </div>
          {activeTab === 'info' && <form className="aha-modalform-wrapper">
            <div
              className={
                "aha-modalform-group " + (err_first ? "error-message" : "")
              }
            >
              <label className="caption" htmlFor="ad-firstname">
                First Name
              </label>
              <input
                type="text"
                id="ad-firstname"
                placeholder="First Name"
                data-testid="firstname"
                onChange={(e) => {
                  setErrFirst(false);
                  setData({ ...data, first_name: e.target.value });
                }}
                value={data && data.first_name}
                disabled={page === "profile" ? true : false}
              />
              {err_first && (
                <p data-testid="error-text-id" className="error-message-text">
                  Selection Required
                </p>
              )}
            </div>
            <div className="aha-modalform-group">
              <label className="caption" htmlFor="ad-initial">
                Middle Initial/Name (Optional)
              </label>
              <input
                type="text"
                id="ad-initial"
                data-testid="middlename"
                placeholder="Middle Initial/Name (Optional)"
                onChange={(e) => {
                  setData({ ...data, middle_initial: e.target.value });
                }}
                value={data && data.middle_initial}
                disabled={page === "profile" ? true : false}
              />
            </div>
            <div
              className={
                "aha-modalform-group " + (err_last ? "error-message" : "")
              }
            >
              <label className="caption" htmlFor="ad-lastname">
                Last Name
              </label>
              <input
                type="text"
                id="ad-lastname"
                placeholder="Last Name"
                data-testid="lastname"
                onChange={(e) => {
                  setErrLast(false);
                  setData({ ...data, last_name: e.target.value });
                }}
                value={data && data.last_name}
                disabled={page === "profile" ? true : false}
              />
              {err_last && (
                <p data-testid="error-text-id" className="error-message-text">
                  Selection Required
                </p>
              )}
            </div>
            <div
              className={
                "aha-modalform-group mr-0 " + (err_emp ? "error-message" : "")
              }
            >
              <label className="caption" htmlFor="ad-empid">
                Employee ID
              </label>
              <input
                type="text"
                id="ad-empid"
                placeholder="Employee ID"
                data-testid="employeeId"
                onChange={(e) => {
                  setErrEmp(false);
                  setData({ ...data, employee_id: e.target.value });
                }}
                value={data && data.employee_id}
                disabled={page === "profile" ? true : false}
              />
              {err_emp && (
                <p data-testid="error-text-id" className="error-message-text">
                  Selection Required
                </p>
              )}
            </div>
            <div
              className={
                "aha-modalform-group " + (err_username ? "error-message" : "")
              }
            >
              <label className="caption" htmlFor="ad-username">
                Username
              </label>
              <input
                type="text"
                id="ad-username"
                placeholder="Username"
                data-testid="username"
                onChange={(e) => {
                  setErrUsername(false);
                  setData({ ...data, user_name: e.target.value });
                }}
                value={data && data.user_name}
                disabled={true}
              />
              {err_username && (
                <p data-testid="error-text-id" className="error-message-text">
                  Selection Required
                </p>
              )}
            </div>
            <div
              data-testid="error-text-id"
              className={
                "aha-modalform-group " +
                (err_email
                  ? (type === "both" || type === "desktop") && "error-message"
                  : "")
              }
            >
              <label className="caption" htmlFor="ad-email">
                Email{" "}
                {type === "both" ? "" : type === "desktop" ? "" : "(Optional)"}
              </label>
              <input
                type="text"
                id="ad-email"
                data-testid="email"
                placeholder={
                  "Email " +
                  (type === "both"
                    ? ""
                    : type === "desktop"
                      ? ""
                      : "(Optional)")
                }
                onChange={(e) => {
                  setErrEmail(false);
                  setData({ ...data, email_id: e.target.value });
                }}
                value={data && data.email_id}
                disabled={page === "profile" ? true : false}
              />
              {err_email
                ? (type === "both" || type === "desktop") && (
                  <p className="error-message-text">Selection Required</p>
                )
                : null}
            </div>
            <div className="aha-modalform-group">
              <label className="caption" htmlFor="ad-per-phone">
                Personal Phone (Optional)
              </label>
              <input
                type="text"
                name="p-phone"
                id="ad-per-phone"
                data-testid="phone"
                placeholder="Personal Phone (Optional)"
                onChange={(e) => {
                  handlePersonalPhone(e.target.value);
                }}
                value={data && data.personal_phone_number}
                disabled={page === "profile" ? true : false}
              />
            </div>
            <div
              data-testid="error-text-id"
              className={
                "aha-modalform-group mr-0 " +
                (err_phone
                  ? (type === "both" || type === "mobile") && "error-message"
                  : "")
              }
            >
              <label className="caption" htmlFor="ad-comp-phone">
                Company Phone{" "}
                {type === "both" ? "" : type === "mobile" ? "" : "(Optional)"}
              </label>
              <input
                type="text"
                name="c-phone"
                id="ad-comp-phone"
                data-testid="cphone"
                onChange={(e) => {
                  handleCPhone(e.target.value);
                }}
                value={data && data.phone_number}
                placeholder={
                  "Company Phone " +
                  (type === "both" ? "" : type === "mobile" ? "" : "(Optional)")
                }
                disabled={page === "profile" ? true : false}
              />
              {err_phone
                ? (type === "both" || type === "mobile") && (
                  <p className="error-message-text">Selection Required</p>
                )
                : null}
            </div>

            <div className="aha-modalform-group2">
              <div
                data-testid="error-text-id"
                className={
                  "aha-modalform-group " + (err_job ? "error-message" : "")
                }
              >
                <label className="caption" htmlFor="job-title">
                  Job Title
                </label>
                <DropDownListComponent
                  id="job-title"
                  cssClass="modal-popup-dropdown"
                  data-testid="job"
                  dataSource={props && props.job}
                  fields={{ text: "name" }}
                  change={handleJob}
                  placeholder="Job Title"
                  allowFiltering={true}
                  filterBarPlaceholder="Search Job Title"
                  value={
                    data &&
                    data.job &&
                    data.job.value
                  }
                  enabled={page === "edit" ? true : false}
                />
                {err_job && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>

              <div
                className={
                  "aha-modalform-group " + (err_manager ? "error-message" : "")
                }
              >
                {!executive && (
                  <>
                    <label className="caption" htmlFor="reporting-manager">
                      Reporting Manager
                    </label>

                    <DropDownListComponent
                      id="reporting-manager"
                      data-testid="manager"
                      cssClass="modal-popup-dropdown"
                      dataSource={props ? props.userList ? props.userList.filter(user => user.status !== "Inactive") : [] : []}
                      fields={{ text: "full_name" }}
                      change={handleManager}
                      placeholder="Reporting Manager"
                      allowFiltering={true}
                      filterBarPlaceholder="Search Reporting Manager"
                      value={
                        data &&
                        data.reporting_manager &&
                        data.reporting_manager.value
                      }
                      enabled={page === "edit" ? true : false}
                    />

                    {err_manager && (
                      <p data-testid="error-text-id" className="error-message-text">
                        Selection Required
                      </p>
                    )}
                  </>
                )}
              </div>

              {page === "edit" && (
                <>
                  {" "}
                  <div
                    data-testid="error-text-id"
                    className={
                      "aha-modalform-group  rt-dropdown-wrapper " +
                      (err_regionOrTerri ? "error-message" : "")
                    }
                  >
                    <label className="caption" htmlFor="region-territories">
                      Regions/Territories
                    </label>
                    <DropDownTreeComponent
                      treeSettings={{ autoCheck: true }}
                      data-testid="region"
                      id="region-territories"
                      cssClass="rt-dropdown-dialog edit-popup"
                      fields={{
                        dataSource: options,
                        value: "id",
                        parentValue: "region_id",
                        text: "name",
                        hasChildren: "hasTrue"
                      }}
                      mode={"Delimiter"}
                      showSelectAll={true}
                      value={uniqueArray}
                      showClearButton={false}
                      showCheckBox={true}
                      filterBarPlaceholder="Search Regions/Territories"
                      placeholder="Select Regions/Territories"
                      popupHeight="200px"
                      select={val => handleRT(val, options)}
                      allowMultiSelection={true}
                      ref={(dropdown) => {
                        dropDownTree = dropdown;
                      }}
                      created={created.bind(this)}
                    // open={() => open(options)}
                    />
                    {err_regionOrTerri && (
                      <p data-testid="error-text-id" className="error-message-text">
                        Selection Required
                      </p>
                    )}
                  </div>
                </>
              )}
              <div
                data-testid="error-text-id"
                className={
                  "aha-modalform-group  rt-dropdown-wrapper " +
                  (err_homeRegion ? "error-message" : "")
                }
              >
                <label className="caption d-inline-flex align-items-center" htmlFor="home-region">
                  <span className="pe-2">Home Region </span>
                  <OverlayTrigger
                    overlay={<Tooltip
                      id="tooltip-disabled"
                      className="service-location-tooltip"
                    >
                      <p>This field is used for Payroll purposes only and not scheduling</p>

                    </Tooltip>} >
                    <span className="info-icon  font-size-14"></span>
                  </OverlayTrigger>
                </label>
                <DropDownListComponent
                  id="home-region"
                  cssClass="modal-popup-dropdown"
                  data-testid="home-region"
                  dataSource={regionOptions}
                  fields={{ text: "name" }}
                  change={handleHomeRegion}
                  placeholder="Home Region"
                  allowFiltering={true}
                  filterBarPlaceholder="Search Home Region"
                  value={data && data.home_region && (data.home_region.name || "")}
                  enabled={page === "edit" ? true : false}
                  showClearButton

                />
                {err_homeRegion && (
                  <p data-testid="error-text-id" className="error-message-text">
                    Selection Required
                  </p>
                )}
              </div>
            </div>
          </form>}

          {activeTab === 'info' && page === "profile" && (
            <div className="region-territory-wrapper">
              <p className="caption">Region/Territory</p>
              <div className="rt-view">
                {Object.keys(finalRegTerr).map((reg) => {
                  const selectedTerritories = finalRegTerr[reg] || [];
                  return (
                    <span>
                      <p className="body2">
                        Region: {getRegionNameById(props.bc, reg)}
                      </p>
                      <p className="body2">
                        Territories:{" "}
                        {selectedTerritories.map((terr, index) => {
                          return `${terr.territory_name}${index === selectedTerritories.length - 1 ? "" : ", "
                            }`;
                        })}
                      </p>
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
          )}

          {activeTab === 'info' && <div className="access-status-wrapper">
            {page === "edit" ? (
              <div className="chipsetcomponent-wrapper">
                <p className="headline6 mb-15">Access Group(s)</p>
                <div className="chipsetcomponent-roles">
                  <ChipListComponent
                    id="chip-default-edituser"
                    data-testid="access"
                    selection="Multiple"
                    selectedChips={selected}
                    click={handleList}
                    enabled={page === "edit" ? true : false}
                  >
                    <ChipsDirective>
                      {props &&
                        props.roles &&
                        props.roles.map((val, i) => {
                          return (
                            <ChipDirective
                              key={i}
                              text={val.name}
                              cssClass="e-primary"
                              value={{ value: val.id, type: val.type }}
                              index={val.id}
                            ></ChipDirective>
                          );
                        })}
                    </ChipsDirective>
                  </ChipListComponent>
                </div>
                {accessGroupError && (
                  <p className="error-message-text">Selection Required</p>
                )}
              </div>
            ) : (
              <div className="chipsetcomponent-wrapper profile-screen">
                <p className="headline6 mb-15">Access Group(s)</p>
                {props &&
                  props.editData &&
                  props.editData.roles &&
                  props.editData.roles.map((val, i) => {
                    return (
                      <span className="access-group-text" key={i}>
                        <span className="access-group-icon"></span>
                        <span className="access-title">{val.role_name} </span>
                      </span>
                    );
                  })}
              </div>
            )}
            <div>
              {page === "profile" ? (
                <div className="statuscomponent-wrapper">
                  <p className="headline6 mb-15">Status</p>

                  <div className="status-block profile-screen">
                    <div className="user-status-wrapper">
                      <ChipListComponent id="chip-default">
                        <ChipsDirective>
                          <ChipDirective
                            text={
                              props && props.editData && props.editData.status
                            }
                            cssClass={
                              props.editData ? props.editData.status === "Active"
                                ? "e-user-active"
                                : props.editData.status === "Invited"
                                  ? "e-user-invited"
                                  : props.editData.status === "Locked" &&
                                  "e-user-locked"
                                : ""
                            }
                          ></ChipDirective>
                        </ChipsDirective>
                      </ChipListComponent>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="statuscomponent-wrapper">
                  <p className="headline6 mb-15">Status</p>

                  <div className="status-block edit-screen">
                    <div className="aha-modalform-group">
                      <label htmlFor="status">Status</label>
                      <DropDownListComponent
                        id="status"
                        data-testid="status"
                        dataSource={status}
                        fields={{ text: "text", value: "id" }}
                        change={handleStatus}
                        select={handleStatus}
                        placeholder="Status"
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="statuscomponent-wrapper">

                <div className="status-block edit-screen mt-2">
                  <div className="aha-modalform-group">
                    <label htmlFor="has-company-vehicle">Has Company Vehicle</label>
                    <DropDownListComponent
                      id="has-company-vehicle"
                      data-testid="has-company-vehicle"
                      dataSource={hasCompanyVehicleOptions}
                      fields={{ text: "text", value: "id" }}
                      change={handleCompanyVehicle}
                      select={handleCompanyVehicle}
                      placeholder="Has Company Vehicle"
                      enabled={page === "edit"}
                      value={hasCompanyVehicle}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>}

          {activeTab === 'audit' && <form className="auditlog-wrapper">
            <div className="auditlog-top">
              <div
                className={
                  "search-box"
                }
              >
                <input
                  type="text"
                  id="ad-search"
                  placeholder="Search"
                  data-testid="search"
                  onChange={(e) => handleInputChange(e)}
                  onFocus={onFocus}
                  ref={searchRef}
                // value={searchText}
                />
                {searchText && <span className="default-clear-btn" onClick={e => clearSearchInput()}><span className="visually-hidden">Clear Icon</span></span>}
                <span className="search-icon"><span className="visually-hidden">Search Icon</span></span>
              </div>
              <div className="export-excel-btn">
                <button disabled={!auditLogs.length} onClick={(e) => handleExcelExport(e, auditLogs)}>
                  <span className="e-excelexport"></span>Excel Export
                </button>
              </div>
            </div>
            <div
              className={
                "auditlog-list"
              }
            >
              {audit_histories && audit_histories.length > 0 &&
                <Table responsive borderless className="audit-log-table">
                  <thead>
                    <tr>
                      {headers.map((header, index) => {
                        return (
                          <th key={index}>{header.label}</th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {auditLogs.map((history, index) => {
                      const {
                        changedByString = "",
                        activity = "",
                        datetimeString = "",
                        abbreviation
                      } = history;
                      return ((!searchText || activity.toLowerCase().search(searchText.toLowerCase()) !== -1 ||
                        datetimeString.toLowerCase().search(searchText.toLowerCase()) !== -1 || changedByString.toLowerCase().search(searchText.toLowerCase()) !== -1) &&
                        <tr key={index}>
                          <td>
                            {datetimeString} {abbreviation || ""}
                          </td>
                          <td>
                            {changedByString}
                          </td>
                          <td>{activity}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>}
              {auditLogs.length == 0 && <p>No logs.</p>}
            </div>

          </form>}

        </Modal.Body>
        <Modal.Footer>
          <Button
            data-testid="button-cancel"
            className="primary-btn primary-btn-outline outline"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          {activeTab === 'info' ? page === "profile" ? (
            update && (
              <Button
                className="primary-btn primary-btn-outline outline"
                data-testid="button-submit"
                onClick={() => {
                  handleShow();
                }}
                disabled={update ? false : true}
              >
                Edit User
              </Button>
            )
          ) : (
            // <ProgressButtonComponent
            //   content="Edit User"
            //   enableProgress={true}
            //   spinSettings={{ position: "Center" }}
            //   animationSettings={{ effect: "SlideLeft" }}
            //   cssClass="primary-btn primary-btn-outline ad-progress-btn"
            //   onClick={() => {
            //     handleShow();
            //   }}
            // ></ProgressButtonComponent>
            // <Button
            //   className="primary-btn primary-btn-outline"
            //   onClick={() => {
            //     handleSave();
            //   }}
            // >
            //   Save
            // </Button>
            <ProgressButtonComponent
              content="Save"
              data-testid="button-save"
              enableProgress={updateLoading}
              spinSettings={{ position: "Center" }}
              duration={updateLoading ? "3000" : "300"}
              animationSettings={{ effect: "SlideLeft" }}
              cssClass="primary-btn primary-btn-outline ad-progress-btn"
              onClick={() => {
                handleSave();
              }}
              disabled={!isFormChanged}
            ></ProgressButtonComponent>
          ) : null}
        </Modal.Footer>
      </Modal>
      <StatusModal
        modalType={statusModalType}
        show={statusModalType.value || false}
        hideModal={() => setStatusModalType({ value: false })}
        title={statusModalType.title}
        subtitle={statusModalType.content}
        confirmUpdate={() => handleSave(true)}
        additionalClass="user-status-modal-wrapper"
      />
      <UpdateReporteesWarningModal
        handleAssignReportingManager={() => handleAssignReportingManager(editData.id)}
        userReporteesState={userReporteesState}
        setUserReporteesState={setUserReporteesState}
      />
    </div>
  );
}
