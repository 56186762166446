import * as React from "react";
import ahaApi, { refreshToken } from "../../app/services/aha-api";
import { ADMIN_USER_GUIDE, logout } from "../../app/utils/Constants";
import { CommonHeaderRightSection } from "../../components/commons/CommonHeaderRightSection";
import { getProfile, profileList } from "../landingPage/profile/operations/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import AWS from "aws-sdk";
import Mark from 'mark.js';
import { getFilter } from "../landingPage/addUser/operations/filterSlice";

export const UserGuide = () => {
  const dispatch = useDispatch();
  const profile = useSelector(profileList);
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [color, setColor] = React.useState("");
  const [userGuildeHtmlContent, setHtmlContent] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");
  const iframeRef = React.useRef(null);
  const markInstanceRef = React.useRef(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [occurrencesCount, setOccurrencesCount] = React.useState(0);

  React.useEffect(() => {
    if (profile && profile.status === "SUCCESS") {
      if (profile && profile.content) {
        const userPermission =
          profile &&
          profile.content &&
          profile.content.permission &&
          profile.content.permission.filter((val, i) => {
            return val.page === "users";
          });
        const uPer =
          userPermission && userPermission[0] && userPermission[0].access;

        const filter_list =
          uPer && uPer.find((item) => item === "filter-list") === "filter-list"
            ? true
            : false;
        localStorage.setItem(
          "userDetail",
          JSON.stringify(profile && profile.content)
        );
        if (filter_list) {
          dispatch(getFilter());
        }
        setName(profile && profile.content && profile.content.name);
        setColor(profile && profile.content && profile.content.icon_color_code);
        fetchUserGuide();
      }
    }
  }, [profile]);

  React.useEffect(() => {
    dispatch(getProfile());
  }, [])

  React.useEffect(() => {
    markInstanceRef.current && markInstanceRef.current.unmark();

    if (searchQuery.trim() !== '') {
      const iframe = iframeRef.current;
      if (!iframe) return;

      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      markInstanceRef.current = new Mark(iframeDocument.body);
      markInstanceRef.current.mark(searchQuery, {
        separateWordSearch: false,
        done: () => {
          const occurrences = iframeDocument.body.querySelectorAll("mark");
          setOccurrencesCount(occurrences.length);
          if (occurrences.length > 0) {
            occurrences[currentIndex].style.background = "orange";
            // occurrences[currentIndex].classList.add("orange-highlight");
            occurrences[currentIndex].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          } else {
            setCurrentIndex(0);
          }
        },
        exclude: ['.sb-avatar__text *']
      });
    } else {
      setOccurrencesCount(0);
    }
  }, [searchQuery, currentIndex]);

  React.useEffect(() => {
    const occurrences = document.querySelectorAll("mark");
    occurrences.forEach((element, index) => {
      if (index === currentIndex) {
        element.style.background = "orange";
        // element.classList.add("orange-highlight");
      } else {
        element.style.background = "";
        // element.classList.remove("orange-highlight");
      }
    });
  }, [currentIndex]);

  const fetchUserGuide = async () => {
    setLoading(true);
    try {
      const response = await ahaApi.Account.getUserGuide(ADMIN_USER_GUIDE);
      const { content = {} } = response;
      await getPresignedUrl(content);
    } catch (err) {
      setLoading(false);
      console.log(err, "error");
    }
  };

  const getPresignedUrl = async (file) => {
    const creds = await refreshToken();
    const myBucket = file.bucketName;
    const myKey = file.fileUrl;
    const signedUrlExpireSeconds = 60 * 60 * 24;

    let s3 = new AWS.S3({
      accessKeyId: creds.accessKeyId,
      signatureVersion: "v4",
      region: "us-east-1",
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
    });

    try {
      if (s3) {
        let sessionTokenTime =
          (window.localStorage.getItem("userData") &&
            JSON.parse(window.localStorage.getItem("userData"))
              .session_token_expiration) ||
          "";

        if (
          sessionTokenTime &&
          Date.parse(new Date(sessionTokenTime)) <= Date.parse(new Date())
        ) {
          await getPresignedUrl(file);
        } else {
          const userGuildeHtmlContent = s3.getSignedUrl("getObject", {
            Bucket: myBucket,
            Key: myKey,
            Expires: signedUrlExpireSeconds,
          });
          console.log(userGuildeHtmlContent);
          const htmlContentResponse = await fetch(userGuildeHtmlContent, {
            headers: {
              'Content-Type': 'text/html; charset=utf-8'
            }
          });
          console.log(htmlContentResponse);

          const htmlContent = await htmlContentResponse.text();

          const imageUrls = htmlContent.match(/src="(.*?)"/g).map(match => {
            return match.slice(5, -1);
          });

          const preSignedImageUrls = await Promise.all(imageUrls.map(async imageUrl => {
            const imageName = imageUrl.split('/').pop();
            const imagePath = myKey.substr(0, myKey.lastIndexOf("/"))
            const imageUrlSigned = await s3.getSignedUrlPromise("getObject", {
              Bucket: myBucket,
              Key: `${imagePath}/images/${imageName}`,
              Expires: signedUrlExpireSeconds,
            });
            return { originalUrl: imageUrl, signedUrl: imageUrlSigned };
          }));

          let modifiedHtmlContent = htmlContent;
          preSignedImageUrls.forEach(({ originalUrl, signedUrl }) => {
            const regex = new RegExp(`(<img[^>]*?\\s+src\\s*=\\s*["'])${originalUrl.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}(["'])`, 'g');

            modifiedHtmlContent = modifiedHtmlContent.replace(regex, (match, p1, p2) => {
              return `${p1}${signedUrl}${p2}`;
            });
          });
          modifiedHtmlContent = addTargetToLinks(modifiedHtmlContent);
          setHtmlContent(modifiedHtmlContent);
          addSmoothScrollingToLinks();
        }
      }
      // setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err, err.message, s3);
      // await getPresignedUrl(file, creds);
    }
  };


  const addSmoothScrollingToLinks = () => {
    const iframe = document.querySelector('.tracker-wrapper iframe');

    const smoothScrollToSection = (targetId) => {
      const targetElement = iframe.contentDocument.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    };

    iframe.onload = function () {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

      iframeDocument.addEventListener('click', function (event) {
        const target = event.target.closest('a[href^="#"]');
        if (target) {
          event.preventDefault();
          const targetId = target.getAttribute('href').substring(1);
          smoothScrollToSection(targetId);
        }
      });
    };
  };

  const addTargetToLinks = (htmlContent) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;

    const anchorTags = tempElement.querySelectorAll('a');
    anchorTags.forEach((anchorTag) => {
      anchorTag.setAttribute('target', '_top');
    });
    return tempElement.innerHTML;
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleIframeLoad = (e) => {
    console.log(e, 'loaded');
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Trigger search action
      handleNext();
    }
  };

  const handleNext = () => {
    if (occurrencesCount > 0) {
      setCurrentIndex((prevIndex) => {
        if (prevIndex < occurrencesCount - 1) {
          return prevIndex + 1;
        } else {
          return 0;
        }
      });
    }
  };
  const clearSearchField = () => {
    setSearchQuery("");
    setCurrentIndex(0);
    setOccurrencesCount(0);
  };
  return (
    <React.Fragment>
      <div
        className={"ad-loading-wrapper " + (loading ? "d-block" : "d-none")}
      >
        <span
          className={"loader ad-loader " + (loading ? "d-block" : "d-none")}
        >
          <span className="visually-hidden">loader icon</span>
        </span>
      </div>
      <CommonHeaderRightSection
        logout={logout}
        color={color}
        name={name}
        title="User Guide"
        className="d-flex"
      />
      <div className="d-flex ac-chat-list-header justify-content-start">
        <div className="ac-ksearch-wrapper scheduler-tabs-wrapper ug-search-wrapper">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchInputChange}
            onKeyPress={handleKeyPress}
            className="search-box"
          />
          {occurrencesCount > 0 ? (
            <span className="ac-no-of-chat font-size-10">
              {currentIndex + 1} of {occurrencesCount}
            </span>
          ) : searchQuery && (
            <span className="ac-no-of-chat font-size-10">
              0 of 0
            </span>
          )}
        </div>
        <button
          className="d-inline-block roboto-medium font-size-14 add-dropoff-title with-border-right"
          onClick={handleNext}
        >
          Next
        </button>
        <button
          className="d-inline-block roboto-medium font-size-14 add-dropoff-title"
          onClick={() => clearSearchField()}
        >
          Clear
        </button>
      </div>
      <div className="tracker-wrapper user-guide-wrapper">
        {userGuildeHtmlContent && <iframe
          ref={iframeRef}
          srcDoc={userGuildeHtmlContent}
          title="User Guide"
          width="100%"
          onLoad={handleIframeLoad}
        />}
      </div>
    </React.Fragment>
  );
};