import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { phoneFormat } from "../../../../app/utils/testUtils";
import ahaApi from "../../../../app/services/aha-api";

const initialState = {
  value: [],
  loading: false,
};

export const getCareDxDropSiteList = createAsyncThunk(
  "caredxdropsite/getCareDxDropSiteList",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.getCareDxDropSiteList(data)
      .then((res) => {
        let result = res ? (res.content || []) : [];
        result = result.map(re=> {
          let timingNote = "";
          if (re.drop_site_timings && re.drop_site_timings.length) {
            re.drop_site_timings.forEach(timing => {
              if (!timing.days && !timing.start_time && !timing.end_time && timing.note) {
                timingNote = timing.note;
              }
            });
          }
          return {
            ...re,
            drop_site_name: re.drop_site,
            full_address: re.street_address1 ? `${re.street_address1 || ""}${re.street_address2 ? ` ${re.street_address2}` : ""}` : "-",
            city_string: re.city || "-",
            zip_string: re.zip || "-",
            state_name_string: re.state ? re.state.state_name || "-" : "-",
            time_zone_name_string: re.timezone ? re.timezone.timezone_name || "-" : "-",
            latitude_string: re.latitude ? re.latitude : "-",
            longitude_string: re.longitude ? re.longitude : "-",
            site_contact_phone_number_string: re.phone_number 
            ? `${phoneFormat(re.phone_number.toString())} ${re.phone_number_ext && re.phone_number_ext !== null ? ` Ext. ${re.phone_number_ext}` : ""}` : "-",
            site_contact_phone_number_ext_string: re.phone_number_ext || "-",
            notes_string: re.appointment_information || "-",
            timing_note_string: timingNote
        }});
        res.content = result;
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const getCareDxDropSiteListSlice = createSlice({
  name: "caredxdropsite",
  initialState,
  reducers: {
    updateGetCareDxDropSiteList(state, action) {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCareDxDropSiteList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCareDxDropSiteList.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getCareDxDropSiteList.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const careDxDropSiteListResponse = (state) => state.getCareDxDropSiteList.value;
export const careDxDropSiteListLoader = (state) => state.getCareDxDropSiteList.loading;
export const { updateGetCareDxDropSiteList } = getCareDxDropSiteListSlice.actions;

export default getCareDxDropSiteListSlice.reducer;
