import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";


const initialState = {
  value: {},
  loading: false,
};

export const forceAcknowledgeOrder = createAsyncThunk(
  "statOrders/forceAcknowledgeOrder",
  async (id, { rejectWithValue }) => {
    const response = await ahaApi.Account.forceAcknowledge(id, {is_admin: true})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const forceAcknowledgeOrderSlice = createSlice({
  name: "forceAcknowledgeOrder",
  initialState,
  reducers: {
    clearForceAcknowledgeResponse(state, action) {
      state.value = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forceAcknowledgeOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(forceAcknowledgeOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(forceAcknowledgeOrder.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const forceAcknowledgeOrderResponse = (state) => state.forceAcknowledgeOrderState.value;
export const forceAcknowledgeOrderLoading = (state) => state.forceAcknowledgeOrderState.loading;
export const { clearForceAcknowledgeResponse } = forceAcknowledgeOrderSlice.actions;
export default forceAcknowledgeOrderSlice.reducer;
